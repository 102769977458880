import * as React from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ToastContentContainer } from "@atman/design-system";
import cogoToast, { CTOptions, CTReturn } from "cogo-toast";

export interface ToastOptions extends Omit<CTOptions, "heading"> {
    subtitle?: string;
    wrapInContainer?: boolean;
}

export class ToastProvider {
    private static readonly baseOptions: CTOptions = {
        position: "top-center",
        bar: {
            size: "0",
        },
        hideAfter: 5,
    };

    private static buildOptions = (
        icon: IconProp,
        specificOptions?: CTOptions,
        iconProps?: Partial<FontAwesomeIconProps>,
    ) =>
        ({
            ...ToastProvider.baseOptions,
            renderIcon: () => <FontAwesomeIcon icon={icon} {...iconProps} />,
            ...specificOptions,
        } as CTOptions);

    static success = (content: React.ReactNode | string, options?: ToastOptions): CTReturn => {
        const { wrapInContainer = true, subtitle, ...ctOptions } = options ?? {};

        const cogoResult = cogoToast.success(
            !wrapInContainer ? (
                content
            ) : (
                <ToastContentContainer
                    onHide={() => cogoResult.hide?.()}
                    title={content as string}
                    subtitle={subtitle}
                />
            ),
            ToastProvider.buildOptions(["far", "check-circle"], ctOptions),
        );

        return cogoResult;
    };

    static info = (content: React.ReactNode | string, options?: ToastOptions): CTReturn => {
        const { wrapInContainer = true, subtitle, ...ctOptions } = options ?? {};

        const cogoResult = cogoToast.info(
            !wrapInContainer ? (
                content
            ) : (
                <ToastContentContainer
                    onHide={() => cogoResult.hide?.()}
                    title={content as string}
                    subtitle={subtitle}
                />
            ),
            ToastProvider.buildOptions(["far", "info-circle"], ctOptions),
        );

        return cogoResult;
    };

    static loading = (content: React.ReactNode | string, options?: ToastOptions): CTReturn => {
        const { wrapInContainer = true, subtitle, ...ctOptions } = options ?? {};

        const cogoResult = cogoToast.loading(
            !wrapInContainer ? (
                content
            ) : (
                <ToastContentContainer
                    onHide={() => cogoResult.hide?.()}
                    title={content as string}
                    subtitle={subtitle}
                />
            ),
            ToastProvider.buildOptions(["far", "spinner-third"], ctOptions, { spin: true }),
        );

        return cogoResult;
    };

    static warn = (content: React.ReactNode | string, options?: ToastOptions): CTReturn => {
        const { wrapInContainer = true, subtitle, ...ctOptions } = options ?? {};

        const cogoResult = cogoToast.warn(
            !wrapInContainer ? (
                content
            ) : (
                <ToastContentContainer
                    onHide={() => cogoResult.hide?.()}
                    title={content as string}
                    subtitle={subtitle}
                />
            ),
            ToastProvider.buildOptions(["far", "exclamation-circle"], ctOptions),
        );

        return cogoResult;
    };

    static error = (content: React.ReactNode | string, options?: ToastOptions): CTReturn => {
        const { wrapInContainer = true, subtitle, ...ctOptions } = options ?? {};

        const cogoResult = cogoToast.error(
            !wrapInContainer ? (
                content
            ) : (
                <ToastContentContainer
                    onHide={() => cogoResult.hide?.()}
                    title={content as string}
                    subtitle={subtitle}
                />
            ),
            ToastProvider.buildOptions(["far", "times-circle"], ctOptions),
        );

        return cogoResult;
    };
}
