import "./index.less";
import { AtInput } from "../../../../AtInput";
import { AtSimpleSelect } from "../../../../AtSimpleSelect";
import { IAppliedFilter } from "..";
import { IAtTableFilter } from "../../../types";
import { IBaseEntity } from "@atman/core";
import { t } from "@lingui/macro";
import React, { ReactElement, useEffect, useMemo, useState } from "react";

export enum MatchTypeOptionsEnum {
    Contains = "contains",
    DoesNotContains = "doesNotContains",
}

export type MatchType = Dictionary<MatchTypeOptionsEnum, string>;

interface IAtTableFilterFreeTextProps<T extends IBaseEntity> extends IAtTableFilter<T> {
    appliedFilterValue: IAppliedFilter<T> | null;
    setFilterValue: (columnKey: string, value: string, matchType?: MatchType) => void;
}

export function AtTableFreeTextFilter<T extends IBaseEntity>(props: IAtTableFilterFreeTextProps<T>): ReactElement {
    const { column, appliedFilterValue, setFilterValue, ...otherProps } = props;

    const matchTypes: MatchType = {
        contains: t({ id: "global.filterComparators.contains" }),
        doesNotContains: t({ id: "global.filterComparators.notContains" }),
    };

    useEffect(() => {
        if (!appliedFilterValue) {
            setSelectedMatchType(MatchTypeOptionsEnum.Contains);
        }
    }, [appliedFilterValue]);

    const [selectedMatchType, setSelectedMatchType] = useState<string>(
        appliedFilterValue?.matchType ?? MatchTypeOptionsEnum.Contains,
    );

    const handleTextChanger = (e) => {
        setFilterValue(column.key, e.target.value, selectedMatchType);
    };

    const inputValue = useMemo(() => (appliedFilterValue?.value as string) ?? "", [appliedFilterValue]);

    return (
        <div className="AtTableFreeTextFilter" {...otherProps}>
            <AtSimpleSelect
                options={Object.keys(matchTypes)}
                value={selectedMatchType}
                getOptionLabel={(option) => matchTypes[option]}
                onChange={(value) => {
                    if (!value) {
                        return;
                    }

                    setSelectedMatchType(value);
                    setFilterValue(column.key, inputValue, value);
                }}
                isMulti={false}
            />

            <AtInput fieldName={`field_${column.key}`} type="text" onChange={handleTextChanger} value={inputValue} />
        </div>
    );
}
