import "./index.less";
import { AtSubtitle, AtTitleSubtitle, DefaultComponentProps, DefaultSizes, HeadingType, XL } from "../../../internal";
import React from "react";
import cn from "classnames";

export interface IIconTitleSubtitleProps extends DefaultComponentProps {
    icon?: JSX.Element;
    title?: string;
    secondaryTitle?: string;
    subtitle?: string;
    description?: string;
    separator?: boolean;
    reverseTitles?: boolean;
    size?: DefaultSizes | XL;
    headerSize?: HeadingType;
    helpCenterLink?: JSX.Element;
}

export const IconTitleSubtitle: React.FC<IIconTitleSubtitleProps> = (props) => {
    const {
        icon,
        title,
        secondaryTitle,
        subtitle,
        description,
        size = "md",
        reverseTitles,
        separator,
        headerSize,
        helpCenterLink,
        ...otherProps
    } = props;

    return (
        <div className="IconTitleSubtitle" {...otherProps}>
            {icon && <div className={cn("icon-container", { separator })}>{icon}</div>}
            <div>
                {secondaryTitle && <AtSubtitle subtitle={secondaryTitle} />}
                <AtTitleSubtitle
                    headerSize={headerSize}
                    className={cn({ separator })}
                    title={title ?? "-"}
                    subtitle={subtitle}
                    size={size}
                    description={description}
                    reverseTitles={reverseTitles}
                    noMargin
                />
                {helpCenterLink ?? <div className="rightSection">{helpCenterLink}</div>}
            </div>
        </div>
    );
};
