import { Colors } from "../index";

const colors = [
    Colors.atmanOrange,
    Colors.cognitiveAssessmentColor,
    Colors.personalityAssessmentColor,
    Colors.preferenceAssessmentColor,
    Colors.tripleBottomLineAssessmentColor,
];

const lineColor = "#BFDEFF";

const sansSerif = "'Quicksand', 'Domine', sans-serif";
const letterSpacing = "normal";
const fontSize = 10;

// Layout
const padding = 8;
const baseProps = {
    width: 350,
    height: 350,
    padding: 50,
};

// * Labels
const baseLabelStyles = {
    fontFamily: sansSerif,
    fontWeight: 500,
    fontSize,
    letterSpacing,
    padding,
    fill: Colors.greyShades.shade2,
    stroke: "transparent",
    strokeWidth: 0,
};

const centeredLabelStyles = {
    textAnchor: "middle",
    ...baseLabelStyles,
};

// Strokes
// const strokeDasharray = "10, 5";
const strokeLinecap = "round";
const strokeLinejoin = "round";

// Put it all together...
export const AtVictoryTheme = {
    area: {
        style: {
            data: {
                fill: Colors.contrastColor,
            },
            labels: centeredLabelStyles,
        },
        ...baseProps,
    },
    axis: {
        style: {
            axis: {
                fill: "transparent",
                stroke: Colors.contrastColor,
                strokeWidth: 0,
                strokeLinecap,
                strokeLinejoin,
            },
            axisLabel: {
                // @ts-ignore
                padding,
                // @ts-ignore
                stroke: "transparent",
                ...centeredLabelStyles,
            },
            grid: {
                fill: "none",
                stroke: Colors.contrastColor,
                // strokeDasharray,
                // strokeLinecap,
                // strokeLinejoin,
                pointerEvents: "painted",
            },
            ticks: {
                fill: "transparent",
                size: 0,
                stroke: Colors.contrastColor,
                strokeWidth: 1,
                strokeLinecap,
                strokeLinejoin,
            },
            tickLabels: {
                // @ts-ignore
                fill: Colors.greyBase,
                // @ts-ignore
                padding: 10,
                ...baseLabelStyles,
            },
        },
        ...baseProps,
    },
    bar: {
        style: {
            data: {
                fill: Colors.greyShades.shade5,
                padding,
                strokeWidth: 0,
            },
            labels: baseLabelStyles,
        },
        ...baseProps,
    },
    boxplot: {
        style: {
            max: {
                padding,
                stroke: Colors.greyShades.shade5,
                strokeWidth: 1,
            },
            maxLabels: baseLabelStyles,
            median: {
                padding,
                stroke: Colors.greyShades.shade5,
                strokeWidth: 1,
            },
            medianLabels: baseLabelStyles,
            min: {
                padding,
                stroke: Colors.greyShades.shade5,
                strokeWidth: 1,
            },
            minLabels: baseLabelStyles,
            q1: {
                padding,
                fill: Colors.greyShades.shade5,
            },
            q1Labels: baseLabelStyles,
            q3: {
                padding,
                fill: Colors.greyShades.shade5,
            },
            q3Labels: baseLabelStyles,
        },
        boxWidth: 20,
        ...baseProps,
    },
    candlestick: {
        style: {
            data: {
                stroke: Colors.greyShades.shade5,
            },
            labels: centeredLabelStyles,
        },
        candleColors: {
            positive: "#ffffff",
            negative: Colors.greyShades.shade5,
        },
        ...baseProps,
    },
    chart: baseProps,
    errorbar: {
        borderWidth: 8,
        style: {
            data: {
                fill: "transparent",
                opacity: 1,
                stroke: Colors.greyShades.shade5,
                strokeWidth: 2,
            },
            labels: centeredLabelStyles,
        },
        ...baseProps,
    },
    group: {
        colorScale: colors,
        ...baseProps,
    },
    legend: {
        colorScale: colors,
        gutter: 10,
        orientation: "vertical",
        titleOrientation: "top",
        style: {
            data: {
                type: "circle",
            },
            labels: baseLabelStyles,
            title: {
                // @ts-ignore
                padding: 5,
                ...baseLabelStyles,
            },
        },
    },
    line: {
        style: {
            data: {
                fill: "transparent",
                opacity: 1,
                stroke: lineColor,
                strokeWidth: 2,
            },
            labels: centeredLabelStyles,
        },
        ...baseProps,
    },
    pie: {
        colorScale: colors,
        style: {
            data: {
                padding,
                stroke: Colors.greyShades.shade7,
                strokeWidth: 1,
            },
            labels: {
                // @ts-ignore
                padding: 20,
                ...baseLabelStyles,
            },
        },
        ...baseProps,
    },
    scatter: {
        style: {
            data: {
                fill: "white",
                opacity: 1,
                stroke: lineColor,
                strokeWidth: 1,
            },
            labels: centeredLabelStyles,
        },
        ...baseProps,
    },
    stack: {
        colorScale: colors,
        ...baseProps,
    },
    tooltip: {
        style: {
            // @ts-ignore
            padding: 5,
            pointerEvents: "none",
            ...centeredLabelStyles,
        },
        flyout: {
            stroke: Colors.contrastColor,
            strokeWidth: 1,
            fill: "white",
            pointerEvents: "none",
        },
        cornerRadius: 5,
        pointerLength: 10,
    },
    voronoi: {
        style: {
            data: {
                fill: "transparent",
                stroke: "transparent",
                strokeWidth: 0,
            },
            labels: {
                // @ts-ignore
                padding: 5,
                pointerEvents: "none",
                ...centeredLabelStyles,
            },
            flyout: {
                stroke: Colors.contrastColor,
                strokeWidth: 1,
                fill: "white",
                pointerEvents: "none",
            },
        },
        ...baseProps,
    },
};
