import { ApiManager, ReactAppSettings } from "../libs";
import { BaseApi } from "../base";
import qs from "qs";

export class ReportsApi extends BaseApi {
    public static async getReport(params: IPrintReportParameters): Promise<void> {
        const apiCall = ApiManager.get(ReactAppSettings.appUrls.print, {
            responseType: "blob",
            timeout: 300000,
            params: params,
            paramsSerializer: (params) => qs.stringify(params),
        });

        this.downloadFile(params.pdfFileName, apiCall);
    }
}

export interface IPrintReportParameters {
    urls: string[];
    pdfFileName: string;
    leftTitle: string;
    rightTitle: string;
    leftSubtitle?: string;
    coverPageCount?: number;
    languageCode?: string;
    // showPageNumbers?: boolean;
}
