import "./UpdateCard.less";
import * as React from "react";
import { Amex, Mastercard, Visa } from "../../static/creditCardBrands";
import { BaseForm, BaseModal, CustomTextInput, FormGroupSection, ModalButtons } from "../../components";
import { GlobalStores, IStripeCustomerCard, ModalStore, ProductBalanceStore } from "@atman/business";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import autobind from "autobind-decorator";
import moment from "moment";

export interface IUpdateCardProps {
    modalStore?: ModalStore;
    productBalanceStore?: ProductBalanceStore;
}

@inject(GlobalStores.modalStore, GlobalStores.productBalanceStore)
@observer
export class UpdateCard extends BaseForm<IUpdateCardProps, {}> {
    @observable public label: string = "";
    @observable public expirationMonth: string = "";
    @observable public expirationYear: string = "";

    @computed
    get card(): IStripeCustomerCard | undefined {
        return this.props.productBalanceStore!.customerCards.find(
            (x) => x.cardId === this.props.modalStore!.childProps!.id,
        );
    }

    componentDidMount() {
        this.loadCard();
    }

    @action.bound
    loadCard = () => {
        if (!this.card) {
            return;
        }

        this.label = this.card.label;
        this.expirationMonth = this.card.expMonth.toString();
        this.expirationYear = this.card.expYear.toString().slice(2, 4);
    };

    @autobind
    async _onSave() {
        if (!this.card) {
            return;
        }

        await this.props.productBalanceStore!.updateCustomerCard(this.card.cardId, {
            ...this.card,
            expMonth: Number(this.expirationMonth),
            expYear: Number(`20${this.expirationYear}`),
            label: this.label,
        });

        this.props.modalStore!.toggleModal();
    }

    validateForm(): boolean {
        const expMonth = Number(this.expirationMonth);
        const expYear = Number(this.expirationYear);

        const expMonthIsValid = expMonth >= 1 && expMonth <= 12;
        const expYearIsValid = expYear >= 1 && expYear <= 99;
        const labelIsValid = !!this.label;

        return expMonthIsValid && expYearIsValid && labelIsValid;
    }

    render() {
        const { productBalanceStore } = this.props;

        if (!this.card) {
            return null;
        }

        let logoForBrand;

        switch (this.card.brand) {
            case "Visa":
                logoForBrand = Visa;
                break;
            case "MasterCard":
                logoForBrand = Mastercard;
                break;
            case "American Express":
                logoForBrand = Amex;
                break;
        }

        const now = moment();

        return (
            <BaseModal modalTitle={"global.updateCardInformation".localize()} id={"UpdateCard"}>
                <FormGroupSection
                    sectionTitle={`${"global.cardEndingWith".localize()} ${this.card.last4Digits}`}
                    id={"card-information-section"}
                    rightHeaderContent={
                        <span className="card-containers">
                            <img src={logoForBrand} height={32} alt={this.card.brand} />
                        </span>
                    }
                >
                    <div className="fields-container">
                        <CustomTextInput
                            fieldName={"label"}
                            value={this.label}
                            onChange={this.onTextFieldChange}
                            placeholder={"global.business"}
                            label={"global.cardLabel".localize()}
                        />
                        <CustomTextInput
                            fieldName={"expirationMonth"}
                            value={this.expirationMonth}
                            onChange={this.onTextFieldChange}
                            maxLength={2}
                            placeholder={now.add(1, "month").format("MM")}
                        />
                        <CustomTextInput
                            fieldName={"expirationYear"}
                            value={this.expirationYear}
                            onChange={this.onTextFieldChange}
                            maxLength={2}
                            placeholder={now.format("YY")}
                        />
                    </div>
                    <ModalButtons
                        saveAction={this._onSave}
                        saveLabel={"global.updateCard".localize()}
                        isLoading={productBalanceStore!.isLoading}
                    />
                </FormGroupSection>
            </BaseModal>
        );
    }
}
