import { CompDevApplication } from "../StructureModels";
import { CompDevCompetencyGroup, CompetencySource } from "../CompDevEnums";
import { CompDevCompetencyResult, ICompDevCompetencyResult } from "./CompDevCompetencyResult";
import { CompDevStrategicObjectiveResult } from "./CompDevStrategicObjectiveResult";
import { CompDevSubCompetenciesStrategicObjectiveResult } from "./CompDevSubCompetenciesStrategicObjectiveResult";
import { IApplicationLeveragePlanAction } from "../../IApplicationLeveragePlanAction";
import { IObjectiveWithCompetency } from "../IObjectiveWithCompetency";

export const competencyGroupsToExcludeFromActionPlans: CompDevCompetencyGroup[] = [
    CompDevCompetencyGroup.Others,
    CompDevCompetencyGroup.Performance,
];

export interface ICompetencyDevelopmentProcessResultsData {
    readonly competencies: ICompDevCompetencyResult[];
}

export class CompetencyDevelopmentProcessResultsData {
    public readonly competencies: CompDevCompetencyResult[];

    constructor(json: ICompetencyDevelopmentProcessResultsData) {
        this.competencies = json.competencies.map((x) => new CompDevCompetencyResult(x));
    }

    getCompetenciesForSources(...sources: CompetencySource[]): CompDevCompetencyResult[] {
        return this.competencies.filter((x) => x.competencySources.some((y) => sources.includes(y)));
    }

    getCompetenciesForGroup(group: CompDevCompetencyGroup): CompDevCompetencyResult[] {
        return this.competencies.filter((x) => x.competencyGroup === group);
    }

    getGlobalScore(): number | undefined {
        const competenciesWithScores = this.competencies.filter(
            (x) => (x.subCompetenciesReviewAverage ?? x.subCompetenciesAverage) !== undefined,
        );

        if (!competenciesWithScores.any()) {
            return undefined;
        }

        const sum = competenciesWithScores.sum((x) => x.subCompetenciesReviewAverage ?? x.subCompetenciesAverage!);
        return sum / competenciesWithScores.length;
    }

    getCompetenciesToLeverage(): CompDevCompetencyResult[] {
        return this.competencies.filter(
            (x) =>
                !competencyGroupsToExcludeFromActionPlans.includes(x.competencyGroup) &&
                x.strategicObjectives.some((s) => s.isSelected),
        );
    }

    getCompetenciesToImprove(): CompDevCompetencyResult[] {
        return this.competencies.filter(
            (x) =>
                !competencyGroupsToExcludeFromActionPlans.includes(x.competencyGroup) &&
                x.subCompetencies.some((subComp) =>
                    subComp.strategicObjectives.some(
                        (strategicObjective) =>
                            strategicObjective.isSelected && strategicObjective.selectedApplicationIds.length,
                    ),
                ),
        );
    }

    getObjectivesToLeverage(): IObjectiveWithCompetency<CompDevStrategicObjectiveResult>[] {
        return this.getCompetenciesToLeverage().reduce((p, c) => {
            const selectedStrategicObjectives = c.strategicObjectives.filter((x) => x.isSelected);

            return [
                ...p,
                ...selectedStrategicObjectives.map((x) => ({
                    competency: c,
                    data: x,
                })),
            ];
        }, []);
    }

    getApplicationsToLeverage(): IApplicationLeveragePlanAction[] {
        return this.getObjectivesToLeverage().reduce((accumulator, current) => {
            const results = [
                ...current.data.applications.filter((application) =>
                    current.data.selectedApplicationIds.includes(application.id),
                ),
            ].map((x) => ({
                ...x,
                competency: {
                    id: current.competency.id,
                    code: current.competency.code,
                    name: current.competency.name,
                    description: current.competency.description,
                },
                strategicObjective: {
                    id: current.data.id,
                    code: current.data.code,
                    name: current.data.name,
                    description: current.data.description,
                    type: current.data.type,
                    learningResourceNotes: current.data.learningResourceNotes,
                    learningResources: current.competency.learningResources.filter((learningResource) =>
                        current.data.selectedLearningResourceIds.includes(learningResource.id),
                    ),
                    applicationNotes: current.data.applicationNotes,
                },
            }));
            return [...accumulator, ...results];
        }, []);
    }
    getObjectivesToImprove(): IObjectiveWithCompetency<CompDevSubCompetenciesStrategicObjectiveResult>[] {
        return this.getCompetenciesToImprove().reduce((accumulator, currentValue) => {
            const selectedObjectives = [...currentValue.subCompetencies].flatMap((x) =>
                x.strategicObjectives
                    .filter((objective) => objective.selectedApplicationIds.length)
                    .map((strategicObjective: CompDevSubCompetenciesStrategicObjectiveResult) => {
                        return {
                            subCompetency: x,
                            data: {
                                ...strategicObjective,
                                applications: [
                                    ...strategicObjective.applications.filter((application: CompDevApplication) =>
                                        strategicObjective.selectedApplicationIds.includes(application.id),
                                    ),
                                ] as CompDevApplication[],
                            } as CompDevSubCompetenciesStrategicObjectiveResult,
                        };
                    }),
            );

            return [
                ...accumulator,
                ...selectedObjectives.map(({ data, subCompetency }) => ({
                    competency: currentValue,
                    subCompetency,
                    data,
                })),
            ];
        }, []);
    }
}
