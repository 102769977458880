import "./index.less";
import { AtButton, AtLink, AtTitle, LoadingIndicator } from "@atman/design-system";
import { AuthStore } from "../../../data/stores/AuthStore";
import { BaseStepComponent, CustomValidatedTextInput } from "@atman/ui-kit";
import { CookieProvider, Cookies, Validator, getEnumValues } from "@atman/business";
import { Form } from "reactstrap";
import { LoginAppStores } from "../../../../index";
import { RouteComponentProps, withRouter } from "react-router";
import { SystemStateInfo } from "../../../data/models/SystemState";
import { SystemStateStore } from "../../../data/stores/SystemStateStore";
import { Trans, t } from "@lingui/macro";
import { UnavailableSystem } from "../../../global/components/UnavailableSystem/UnavailableSystem";
import { action, autorun, computed, observable, reaction, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import ProductSelector, { AtmanCoPlatformsEnum } from "../../../global/components/ProductSelector";
import React from "react";
import qs from "qs";

export interface IHelpCenterLoginProps extends RouteComponentProps {
    authStore?: AuthStore;
    systemStateStore?: SystemStateStore;
}

@inject<LoginAppStores, any, any, any>((stores) => ({
    authStore: stores.authStore,
    systemStateStore: stores.systemStateStore,
}))
@observer
export class HelpCenterLoginImpl extends BaseStepComponent<IHelpCenterLoginProps, {}> {
    @observable selectedPlatform?: AtmanCoPlatformsEnum = AtmanCoPlatformsEnum.ProNA;
    @observable email: string = "";
    @observable password: string = "";
    @observable helpCenterReturnUrl?: string;
    @observable loggedInUsername?: string;

    @observable systemStateInfo: { available: boolean | undefined; activeState?: SystemStateInfo } = {
        available: undefined,
    };

    @computed private get _systemStateInfo(): { available: boolean | undefined; activeState?: SystemStateInfo } {
        const { systemStateStore } = this.props;

        if (this.selectedPlatform === undefined) {
            return { available: false };
        }

        return systemStateStore!.getStateForPlatform(this.selectedPlatform);
    }

    constructor(props) {
        super(props);

        autorun(() => {
            this.systemStateInfo = this._systemStateInfo;
        });
    }

    selectedPlatformCookieReactionDisposer = reaction(
        () => ({ platform: this.selectedPlatform, available: this.systemStateInfo.available }),
        () => {
            this.setPlatformCookie();

            if (!this.systemStateInfo.available) {
                return;
            }

            if (!this.props.systemStateStore!.hasLoadedSystemState) {
                return;
            }

            this.goToStep(1);
            this.verifyRefreshToken();
        },
    );

    setPlatformCookie = () => {
        if (this.selectedPlatform === undefined || isNaN(this.selectedPlatform)) {
            return;
        }

        CookieProvider.set(Cookies.LOGIN_APP_PLATFORM, this.selectedPlatform);
    };

    componentDidMount() {
        this.loadPlatformFromCookies();
        this.loadPlatformFromQueryParams();
        this.loadClientIdAndReturnUrlFromQueryParams();
    }

    componentWillUnmount() {
        this.selectedPlatformCookieReactionDisposer();
    }

    @action loadPlatformFromCookies = () => {
        const platformCookie = CookieProvider.get<AtmanCoPlatformsEnum | undefined>(Cookies.LOGIN_APP_PLATFORM);

        const platformCookieValue = Number(platformCookie);

        if (
            !isNaN(platformCookieValue) &&
            getEnumValues<AtmanCoPlatformsEnum>(AtmanCoPlatformsEnum).includes(platformCookieValue)
        ) {
            this.selectedPlatform = platformCookieValue;
        }
    };

    @action loadPlatformFromQueryParams = () => {
        const {
            location: { search },
        } = this.props;

        const query = qs.parse(search, { ignoreQueryPrefix: true }) as { platform?: string };

        if (!query.platform) {
            return;
        }

        if (
            Object.keys(AtmanCoPlatformsEnum)
                .filter((x) => isNaN(Number(x)))
                .includes(query.platform)
        ) {
            this.selectedPlatform = AtmanCoPlatformsEnum[query.platform];
        }
    };

    @action loadClientIdAndReturnUrlFromQueryParams = () => {
        const {
            location: { search },
            authStore,
        } = this.props;

        const query = qs.parse(search, { ignoreQueryPrefix: true }) as { clientId?: string; return_to?: string };

        authStore!.setClientId(query.clientId);
        this.helpCenterReturnUrl = query.return_to;
    };

    protected goToForgotPasswordPage = () => {
        const { history } = this.props;

        history.push("/Unauth/ForgotPassword", {
            fromHelpCenterLogin: true,
            selectedPlatform: this.selectedPlatform,
            email: this.email,
        });
    };

    protected validateEmail(): boolean {
        const {} = this.props;

        const fieldName = "email";

        this.clearErrorForField(fieldName);

        if (!this.email) {
            this.errors.push({
                name: "ValidationError",
                message: t({ id: "global.errors.email.empty", message: `Email is empty` }),
                fieldName,
            });

            return false;
        }

        if (!Validator.validateEmail(this.email)) {
            this.errors.push({
                name: "ValidationError",
                message: t({ id: "global.errors.email.invalid", message: `Email is invalid` }),
                fieldName,
            });

            return false;
        }

        return !this.getErrorsForField(fieldName).any();
    }

    protected validatePassword(): boolean {
        const {} = this.props;

        const fieldName = "password";

        this.clearErrorForField(fieldName);

        if (!this.password) {
            this.errors.push({
                name: "ValidationError",
                message: t({ id: "global.errors.password.empty", message: `Password is empty` }),
                fieldName,
            });
        }

        return !this.getErrorsForField(fieldName).any();
    }

    @action protected loginSilently = async () => {
        const { authStore } = this.props;

        try {
            this.setIsLoading(true);
            await authStore!.loginSilentlyToHelpCenter(this.helpCenterReturnUrl, { platform: this.selectedPlatform });
        } catch (e) {
            console.log(e);

            this.goToStep(2);
            this.setIsLoading(false);
        }
    };

    @action protected verifyRefreshToken = async () => {
        const { authStore } = this.props;

        try {
            this.setIsLoading(true);

            const result = await authStore!.verifyRefreshToken({ platform: this.selectedPlatform });

            runInAction(() => {
                this.loggedInUsername = result.user;
            });

            this.setIsLoading(false);
        } catch (e) {
            this.goToStep(2);
            this.setIsLoading(false);
        }
    };

    @action protected loginToHelpCenter = async (e: React.FormEvent<HTMLFormElement>) => {
        const { authStore } = this.props;

        e.preventDefault();

        const emailIsValid = this.validateEmail();
        const passwordIsValid = this.validatePassword();

        if (!emailIsValid || !passwordIsValid) {
            return;
        }

        this.setIsLoading(true);

        try {
            await authStore!.loginToHelpCenter(this.email, this.password, this.helpCenterReturnUrl, {
                platform: this.selectedPlatform,
            });
        } catch (e) {
            const error = e?.errors?.firstOrDefault();

            this.errors.push({
                name: error?.code ?? "ValidationError",
                message:
                    error?.message ?? t({ id: "global.errors.credentials.invalid", message: `Invalid Credentials` }),
                fieldName: "password",
            });
            this.setIsLoading(false);
        }
    };

    @action onSelectedPlatformChange = (platform: AtmanCoPlatformsEnum) => {
        if (platform !== this.selectedPlatform) {
            this.restartStepForm();
        }

        this.selectedPlatform = platform;
    };

    public restartStepForm = () => {
        this.goToStep(0);
    };

    render() {
        const { systemStateStore } = this.props;

        return (
            <div id="HelpCenterLogin">
                <AtTitle
                    headingType={1}
                    title={
                        this.selectedPlatform === undefined ? (
                            <Trans id={"loginApp.loginForm.selectAPlatform"}>Please select a platform...</Trans>
                        ) : (
                            <Trans id={"loginApp.helpCenterLoginForm.title"}>Log in to the help center</Trans>
                        )
                    }
                />

                <ProductSelector
                    onSelectedPlatformChange={this.onSelectedPlatformChange}
                    selectedPlatform={this.selectedPlatform}
                    disableSelection={this.isLoading}
                />
                {this.selectedPlatform !== undefined ? (
                    <Form onSubmit={this.loginToHelpCenter}>
                        {this.currentStep === 0 ? (
                            <>
                                {!systemStateStore!.hasLoadedSystemState ? (
                                    <LoadingIndicator
                                        size={"md"}
                                        label={t({
                                            id: "loginApp.loginForm.loadingSystemStatus",
                                            message: "Making sure the system is available...",
                                        })}
                                    />
                                ) : !this.systemStateInfo.available ? (
                                    <UnavailableSystem activeSystemState={this.systemStateInfo.activeState!} />
                                ) : null}
                            </>
                        ) : null}
                        {this.currentStep === 1 ? (
                            <>
                                {this.isLoading ? (
                                    <LoadingIndicator
                                        size={"md"}
                                        label={
                                            this.loggedInUsername
                                                ? t({
                                                      id: "loginApp.helpCenterLoginForm.redirectToHelpCenter",
                                                      message: `Redirecting you to the help center...`,
                                                  })
                                                : t({
                                                      id: "loginApp.loginForm.checkingLoginInformation",
                                                      message: `Checking your login information...`,
                                                  })
                                        }
                                    />
                                ) : (
                                    <div className={"login-silently-container"}>
                                        <AtButton id={"login-silently-btn"} onClick={this.loginSilently} fullWidth>
                                            <Trans
                                                id={"loginApp.loginForm.loginSilentlyButtonLabel"}
                                            >{`Continue as ${this.loggedInUsername}`}</Trans>
                                        </AtButton>
                                        <AtLink id={"login-with-other-account-btn"} onClick={() => this.goToStep(2)}>
                                            <Trans id={"loginApp.loginForm.loginWithAnotherAccount"}>
                                                {"Log in with another account"}
                                            </Trans>
                                        </AtLink>
                                    </div>
                                )}
                            </>
                        ) : null}
                        {this.currentStep === 2 ? (
                            <>
                                <CustomValidatedTextInput
                                    fieldName={"email"}
                                    value={this.email}
                                    onChange={this.onTextFieldChange}
                                    type={"email"}
                                    label={t({ id: "global.inputs.labels.loginEmail", message: `Login Email` })}
                                    validationErrors={this.errors}
                                />
                                <CustomValidatedTextInput
                                    fieldName={"password"}
                                    value={this.password}
                                    onChange={this.onTextFieldChange}
                                    type={"password"}
                                    validationErrors={this.errors}
                                    autoComplete={"password"}
                                    containerClassName={"password-input"}
                                    label={t({ id: "global.inputs.labels.password", message: `Password` })}
                                />
                                <AtButton
                                    type={"submit"}
                                    color={"primary"}
                                    size={"xl"}
                                    fullWidth
                                    isLoading={this.isLoading}
                                >
                                    <Trans id={"global.buttons.labels.logIn"}>Log In</Trans>
                                </AtButton>
                                <AtLink onClick={this.goToForgotPasswordPage}>
                                    <Trans id={"loginApp.global.buttons.labels.forgotPassword"}>Forgot Password?</Trans>
                                </AtLink>
                            </>
                        ) : null}
                    </Form>
                ) : null}
            </div>
        );
    }
}

export const HelpCenterLogin = withRouter(HelpCenterLoginImpl);

export interface IZendeskParams {
    brandId: number;
    localeId: number;
    returnTo: string;
    timestamp: number;
}
