import "./index.less";
import * as React from "react";
import { IProductConsumptionModeScheduleEntryModel } from "@atman/business";
import { Progress } from "reactstrap";

export interface IConsumptionRateProgressProps {
    scheduleEntry?: IProductConsumptionModeScheduleEntryModel;
    profilesCount: number;
    size?: "sm" | "md" | "xl";
}

export const ConsumptionRateProgress: React.FC<IConsumptionRateProgressProps> = (
    props: IConsumptionRateProgressProps,
) => {
    const { profilesCount, scheduleEntry, size = "md" } = props;

    if (!scheduleEntry) {
        return <div className={`ConsumptionRateProgress size-${size}`}>{"unset".localize()}</div>;
    }

    let percentage = profilesCount / scheduleEntry.expectedConsumption;
    percentage = !isNaN(percentage) && isFinite(percentage) && percentage !== 0 ? percentage : 0;

    return (
        <div className={`ConsumptionRateProgress size-${size}`}>
            <span className="percentage">{`${percentage.toFixed(0)}%`}</span>
            <span className="progress-bar-container">
                <Progress value={percentage} color={"muted"} />
            </span>
        </div>
    );
};
