export class ConfigurationProvider {
    public static get currentEnvironment(): Environment {
        const value = this.getValue("REACT_APP_ENVIRONMENT", true, process.env.REACT_APP_ENVIRONMENT);

        if (!value || !Object.keys(Environment).includes(value)) {
            return Environment.Local;
        }

        return Environment[value];
    }

    public static get currentVersion(): string {
        return this.getValue("REACT_APP_VERSION", false, process.env.REACT_APP_VERSION)!;
    }

    public static get basePath(): string {
        return this.getValue("REACT_APP_BASE_PATH", false, process.env.REACT_APP_BASE_PATH)!;
    }

    public static get appName(): string {
        return this.getValue("REACT_APP_NAME", false, process.env.REACT_APP_NAME)!;
    }

    public static get controllerRoot(): string {
        return this.getValue("REACT_APP_CONTROLLER_ROOT", false, process.env.REACT_APP_CONTROLLER_ROOT)!;
    }

    private static getValue(
        key: string,
        resolveFromWindow: boolean = false,
        defaultValue: string | undefined = undefined,
    ) {
        let value = process.env[key] ?? defaultValue;

        if (resolveFromWindow && process.env.REACT_APP_ENVIRONMENT !== 'local' && process.env.NODE_ENV === "production") {
            value = (window as any).env[key];
        }

        return value;
    }
}

export enum Environment {
    Local,
    Integration,
    Sandbox,
    Prod,
}
