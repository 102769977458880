import "./index.less";
import { ApiError } from "@atman/core";
import { AtTabCard } from "@atman/design-system";
import { BaseForm } from "../BaseForm";
import { CardSaveButtons } from "../CardSaveButtons";
import { Col, Row } from "reactstrap";
import { CustomTextInput } from "../CustomTextInput";
import { CustomValidatedTextInput } from "../CustomValidatedTextInput";
import { GlobalStores, UserInfoStore } from "@atman/business";
import { PasswordRule } from "./components/PasswordRule";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import autobind from "autobind-decorator";

export interface IChangePasswordFormProps {
    userInfoStore?: UserInfoStore;
}

@inject(GlobalStores.userInfoStore)
@observer
export class ChangePasswordForm extends BaseForm<IChangePasswordFormProps, {}> {
    @observable private validationErrors: ApiError[] = [];
    @observable public currentPassword: string = "";
    @observable public newPassword: string = "";
    @observable public confirmNewPassword: string = "";

    @action.bound
    resetPasswordFields = () => {
        this.currentPassword = "";
        this.newPassword = "";
        this.confirmNewPassword = "";
    };

    @autobind
    async _onSave() {
        await this.props.userInfoStore!.changePassword(this.currentPassword, this.newPassword, this.confirmNewPassword);
    }

    validateForm(): boolean {
        return (
            !!this.currentPassword &&
            this.newPassword === this.confirmNewPassword &&
            this.newPassword.length >= 8 &&
            /.*[A-Z].*/.test(this.newPassword) &&
            /.*[a-z].*/.test(this.newPassword) &&
            /.*\d.*/.test(this.newPassword)
        );
    }

    @action.bound
    handleConfirmPasswordOnBlur = () => {
        if (!!this.newPassword && !!this.confirmNewPassword && this.newPassword !== this.confirmNewPassword) {
            this.validationErrors = [
                {
                    code: "ValidationError",
                    fieldName: "confirmNewPassword",
                    message: "global.confirmNewPasswordDifferentErrorText".localize(),
                    resourceIdentifier: "password",
                    debugInfo: "",
                    name: "confirmNewPasswordDifferentError",
                },
            ];
        } else {
            this.validationErrors = [];
        }
    };

    render() {
        const { userInfoStore } = this.props;

        if (userInfoStore!.isSsoAuthenticated) {
            return null;
        }

        return (
            <AtTabCard cardTitle={"global.changeYourPassword".localize()} id="ChangePasswordForm">
                <form className="inputs">
                    <CustomTextInput
                        fieldName={"currentPassword"}
                        value={this.currentPassword}
                        onChange={this.onTextFieldChange}
                        type={"password"}
                    />
                    <CustomTextInput
                        fieldName={"newPassword"}
                        value={this.newPassword}
                        onChange={this.onTextFieldChange}
                        type={"password"}
                        onBlur={this.handleConfirmPasswordOnBlur}
                    />
                    <div className="password-rules">
                        <Row noGutters>
                            <Col xs={12} sm={6}>
                                <PasswordRule passwordValue={this.newPassword} ruleType={"lowercaseChar"} />
                            </Col>
                            <Col xs={12} sm={6}>
                                <PasswordRule passwordValue={this.newPassword} ruleType={"uppercaseChar"} />
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col xs={12} sm={6}>
                                <PasswordRule passwordValue={this.newPassword} ruleType={"numberChar"} />
                            </Col>
                            <Col xs={12} sm={6}>
                                <PasswordRule passwordValue={this.newPassword} ruleType={"minLength"} />
                            </Col>
                        </Row>
                    </div>
                    <CustomValidatedTextInput
                        fieldName={"confirmNewPassword"}
                        value={this.confirmNewPassword}
                        onChange={this.onTextFieldChange}
                        placeholder={""}
                        validationErrors={this.validationErrors}
                        type={"password"}
                        onBlur={this.handleConfirmPasswordOnBlur}
                    />
                </form>
                <CardSaveButtons
                    saveAction={this._onSave}
                    cancelAction={this.resetPasswordFields}
                    isLoading={userInfoStore!.isLoading}
                    disabledSaveButton={!this.validateForm()}
                />
            </AtTabCard>
        );
    }
}
