import {
    BaseCompDevObjectiveElementResult,
    IBaseCompDevObjectiveElementResult,
} from "./BaseCompDevObjectiveElementResult";
import { CompetencyDevelopmentLeveragingObjectiveType } from "../CompDevEnums";

export interface ICompDevStrategicObjectiveResult extends IBaseCompDevObjectiveElementResult {
    readonly type: CompetencyDevelopmentLeveragingObjectiveType;
}

export class CompDevStrategicObjectiveResult extends BaseCompDevObjectiveElementResult {
    public readonly type: CompetencyDevelopmentLeveragingObjectiveType;

    constructor(json: ICompDevStrategicObjectiveResult) {
        super(json);

        this.type = json.type;
    }
}
