export interface ICustomFlag {
    readonly identifier: string;
    readonly localizedNames: Record<string, string>;
    readonly localizedDescriptions: Record<string, string>;
    readonly negativeImpact: number;
    readonly rulesNeededToApply: number;
    readonly negativeCorrectionRules: INegativeCorrectionRule[];
}

export interface INegativeCorrectionRule {
    readonly dimension: string;
    readonly operator: string;
    readonly limitValue: number;
}

export class CustomFlag implements ICustomFlag {
    public readonly identifier: string;
    public readonly localizedNames: Record<string, string>;
    public readonly localizedDescriptions: Record<string, string>;
    public readonly negativeImpact: number;
    public readonly rulesNeededToApply: number;
    public readonly negativeCorrectionRules: INegativeCorrectionRule[];

    constructor(json: ICustomFlag) {
        this.identifier = json.identifier;
        this.localizedNames = json.localizedNames;
        this.localizedDescriptions = json.localizedDescriptions;
        this.negativeImpact = json.negativeImpact;
        this.rulesNeededToApply = json.rulesNeededToApply;
        this.negativeCorrectionRules = json.negativeCorrectionRules;
    }
}
