import "./index.less";
import * as React from "react";
import { AtCheckboxBase } from "@atman/design-system";
import { GlobalStores, ModalStore, ModalTypes } from "@atman/business";
import { IBootstrapTableRemote, IExpandRow, IRowEvents, ISelectRow, ISortElement } from "./components/Types";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
// @ts-ignore
import { BaseColumn } from "./components/BaseColumn";
import BootstrapTable from "react-bootstrap-table-next";

export interface IBaseItemListProps<TEntity> {
    tableColumns: BaseColumn[];
    tableRows: any[];
    modalType?: ModalTypes;
    modalStore?: ModalStore;
    selectRow?: boolean | ISelectRow<TEntity>;
    expandRowContent?: (row: TEntity) => React.ReactNode;
    deleteSelectedItems?: (items: string[]) => void;
    onClickOverride?: ((e: any, row: TEntity, rowIndex: number) => void) | null;
    defaultSorted?: ISortElement[];
    remote?: IBootstrapTableRemote;
    onTableChange?: (type, { data, sortOrder, sortField }) => void;
    rowStyle?: React.CSSProperties | ((row: TEntity, rowIndex: number) => React.CSSProperties);
    rowClasses?: string | ((row: TEntity, rowIndex: number) => string);
    disableClickActionForRow?: (row: TEntity, rowIndex: number) => boolean;
    keyFieldOverride?: string;
}
// todo: @ma remove if not used anymore because AtTable is now used
@inject(GlobalStores.modalStore)
@observer
export class BaseItemList<TEntity> extends React.Component<IBaseItemListProps<TEntity>, {}> {
    @observable public selectedItemIds: string[] = [];

    public get selectRow(): ISelectRow<TEntity> | undefined {
        if (!this.props.selectRow) {
            return undefined;
        }

        if (typeof this.props.selectRow === "boolean") {
            return {
                mode: "checkbox",
                selected: this.selectedItemIds,
                onSelect: this.handleOnSelect,
                onSelectAll: this.handleOnSelectAll,
                selectionHeaderRenderer: ({ indeterminate, checked }) => (
                    <AtCheckboxBase indeterminate={indeterminate} checked={checked} size={"sm"} id={"all"} />
                ),
                selectionRenderer: ({ checked }) => <AtCheckboxBase checked={checked} size={"sm"} />,
                headerColumnStyle: () => ({
                    width: 40,
                }),
                selectColumnStyle: () => ({
                    width: 40,
                }),
            };
        }

        return {
            selectionHeaderRenderer: ({ indeterminate, checked }) => (
                <AtCheckboxBase indeterminate={indeterminate} checked={checked} size={"sm"} />
            ),
            selectionRenderer: ({ checked }) => <AtCheckboxBase checked={checked} size={"sm"} />,
            headerColumnStyle: () => {
                return {
                    width: 40,
                };
            },
            selectColumnStyle: () => {
                return {
                    width: 40,
                };
            },
            ...this.props.selectRow,
        };
    }

    public get expandRow(): IExpandRow<TEntity> | undefined {
        if (this.props.expandRowContent) {
            return {
                renderer: this.props.expandRowContent,
            };
        }

        return undefined;
    }

    public get rowEvents(): IRowEvents<TEntity> | undefined {
        if (this.props.expandRowContent) {
            return undefined;
        }

        return {
            onClick: (e: any, row: TEntity, rowIndex: number) => {
                if (this.props.disableClickActionForRow?.(row, rowIndex)) {
                    return null;
                }

                if (this.props.onClickOverride !== undefined) {
                    return this.props.onClickOverride?.(e, row, rowIndex);
                }

                if (!this.props.modalType) {
                    return;
                }

                if (e.target.type === "checkbox") {
                    return;
                }

                if (e.target.children[0] && e.target.children[0].type === "checkbox") {
                    return;
                }

                this.props.modalStore!.openModal(this.props.modalType, { defaultData: row });
            },
        };
    }

    public get rowClasses(): string | ((row: TEntity, rowIndex: number) => string) {
        const { rowClasses, onClickOverride, disableClickActionForRow } = this.props;

        return (row, rowIndex) => {
            const customClasses = typeof rowClasses === "function" ? rowClasses(row, rowIndex) : rowClasses;

            let tableRowClasses = "";

            if (onClickOverride === null || disableClickActionForRow?.(row, rowIndex) === true) {
                tableRowClasses = "no-click-action";
            }

            if (customClasses) {
                tableRowClasses += ` ${customClasses}`;
            }

            return tableRowClasses;
        };
    }

    @action
    public handleOnSelect = (row: any, isSelected: boolean) => {
        if (isSelected) {
            this.selectedItemIds.push(row.id);
        } else {
            const index = this.selectedItemIds.findIndex((x) => x === row.id);

            if (index >= 0) {
                this.selectedItemIds.splice(index, 1);
            }
        }
    };

    @action
    public handleOnSelectAll = (isSelected: boolean, rows: any[]) => {
        if (isSelected) {
            this.selectedItemIds = rows.map((x) => x.id);
        } else {
            this.selectedItemIds = [];
        }
    };

    render() {
        const { tableColumns, tableRows, defaultSorted, remote, onTableChange, keyFieldOverride, rowStyle } =
            this.props;

        return (
            <div className="BaseItemList">
                <BootstrapTable
                    keyField={keyFieldOverride ?? "id"}
                    data={tableRows}
                    columns={tableColumns}
                    selectRow={this.selectRow}
                    rowEvents={this.rowEvents}
                    expandRow={this.expandRow}
                    bordered={false}
                    defaultSorted={defaultSorted}
                    remote={remote}
                    onTableChange={onTableChange}
                    bootstrap4
                    rowStyle={rowStyle}
                    rowClasses={this.rowClasses}
                />
            </div>
        );
    }
}

export { BaseColumn };
export * from "./components/BaseFormatters";
export * from "./components/Types";
