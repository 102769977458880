import * as H from "history";
import * as React from "react";
import { IRouteWithSubRoutesProps } from "./RouteWithSubRoutes";
import { Role } from "../../types";
import { UrlFormatter } from "../../libs";
import { match } from "react-router";
import { pathToRegexp } from "path-to-regexp";

export type RoleMatchType = "anyOf" | "allOf";

export interface IInjectedBreadcrumbProps<TState = {}, TMatchParams = {}> {
    component: React.ReactNode;
    location: H.Location<TState>;
    match: match<TMatchParams>;
}

export interface IRouteConfigEntry {
    path: string;
    // name: (matchParam: string) => string;
    component: React.ComponentType;
    breadcrumb?: ((props: IInjectedBreadcrumbProps) => React.ReactNode) | React.ReactNode;
    subRoutes?: IRouteConfigEntry[];
    defaultSubRoute?: string;
    // matchParamName?: string;
    requiredRoles?: Role[];
    requiredRolesMatchType?: RoleMatchType;
    onlyShowInDevEnvironment?: boolean;
    // showInBreadcrumbs?: boolean;
    enabled?: boolean;
    default?: boolean;
    exactMatch?: boolean;
}

export class RouteConfigEntry implements IRouteConfigEntry {
    public readonly fullPath: string;
    public path: string;
    public component: React.ComponentType;
    public breadcrumb?: ((props: IInjectedBreadcrumbProps) => React.ReactNode) | React.ReactNode;
    public subRoutes?: RouteConfigEntry[];
    public defaultSubRoute?: string;
    public requiredRoles?: Role[];
    public requiredRolesMatchType?: RoleMatchType = "allOf";
    public onlyShowInDevEnvironment?: boolean = false;
    public enabled?: boolean = true;
    public default?: boolean = false;
    public exactMatch?: boolean;

    constructor(
        entry: IRouteConfigEntry,
        parentPath?: string,
        parentRequiredRoles: Role[] = [],
        parentRequiredRolesMatchType?: RoleMatchType,
    ) {
        this.fullPath = parentPath ? `${parentPath}${entry.path}` : entry.path;
        this.path = entry.path;
        this.component = entry.component;
        this.breadcrumb = entry.breadcrumb;
        this.defaultSubRoute = entry.defaultSubRoute;
        this.requiredRoles = [...parentRequiredRoles, ...(entry.requiredRoles ?? [])].distinct();
        this.requiredRolesMatchType =
            entry.requiredRolesMatchType ?? parentRequiredRolesMatchType ?? this.requiredRolesMatchType;
        this.onlyShowInDevEnvironment = entry.onlyShowInDevEnvironment ?? this.onlyShowInDevEnvironment;
        this.enabled = entry.enabled ?? this.enabled;
        this.default = entry.default ?? this.default;
        this.exactMatch = entry.exactMatch;
        this.subRoutes = entry.subRoutes?.map(
            (x) => new RouteConfigEntry(x, this.fullPath, this.requiredRoles, this.requiredRolesMatchType),
        );
    }

    get regexp(): RegExp {
        return pathToRegexp(this.fullPath, undefined, {
            start: true,
            end: false,
        });
    }

    get routeWithSubRoutesProps(): IRouteWithSubRoutesProps {
        return {
            path: this.fullPath,
            Component: this.component,
            subRoutes: this.subRoutes,
            exact: this.exactMatch,
        };
    }

    getHref(matchParams: Dictionary<string, string>): string {
        let href: string = this.fullPath;

        // if (this.matchParamName) {
        //     for (const key of Object.keys(matchParams)) {
        //         href = href.replace(`:${key}`, matchParams[key]);
        //     }
        // }

        if (this.defaultSubRoute) {
            href += this.defaultSubRoute;
        }

        return UrlFormatter.formatReactPath(href);
    }

    isActiveRoute(currentPath: string) {
        return this.regexp.exec(currentPath);
    }
}
