import "./TransactionAffectedBalanceBadge.less";
import * as React from "react";
import { Badge } from "reactstrap";
import { ITransactionProductModel, ProductCodeProEnum } from "@atman/business";

export interface ITransactionAffectedBalanceBadgeProps {
    product: ITransactionProductModel;
}

export const TransactionAffectedBalanceBadge: React.FC<ITransactionAffectedBalanceBadgeProps> = (props) => {
    const { product } = props;

    return (
        <Badge className="TransactionAffectedBalanceBadge">
            <span className="quantity">{product.quantity}</span>{" "}
            <span className="name">
                {`global.productCodes.${ProductCodeProEnum[product.productCode].toCamel()}`.localize()}
            </span>
        </Badge>
    );
};
