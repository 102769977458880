import "./index.less";
import { AtSubtitle } from "../../atoms/AtSubtitle";
import { AtTitle } from "../../atoms/AtTitle";
import { MaxNumberCounter } from "../../atoms/MaxNumberCounter";
import { observer } from "mobx-react";
import React from "react";

export interface IInputHeaderProps {
    label?: string;
    description?: string;
    requirementType?: InputRequirementType;
    currentInputLength?: number;
    maxInputLength?: number;
    requirementText?: string;
}

export const InputHeader: React.FC<IInputHeaderProps> = observer((props) => {
    const {
        label,
        description,
        requirementType = InputRequirementType.Required,
        currentInputLength,
        maxInputLength,
        requirementText,
        ...otherProps
    } = props;

    return (
        <div className="InputHeader" {...otherProps}>
            <div className="header">
                <AtTitle headingType={5} title={label} />
                {requirementType === InputRequirementType.Optional && requirementText && (
                    <span className={"requirement-label"}>({requirementText})</span>
                )}
            </div>
            {(description || maxInputLength !== undefined) && (
                <div className="header-sub-content">
                    {description && <AtSubtitle size="lg" subtitle={description} />}
                    {maxInputLength !== undefined && (
                        <MaxNumberCounter
                            currentNumber={currentInputLength !== undefined ? currentInputLength : 0}
                            maxNumber={maxInputLength}
                        />
                    )}
                </div>
            )}
        </div>
    );
});

export enum InputRequirementType {
    Required,
    Optional,
}
