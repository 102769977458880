import { FontWeight } from "../../../internal";

/**
 * This class serves as a Static Library to access design-system typography values in Typescript
 * TODO: Consider using webpack-loader to get the values directly from index.less file to avoid having to maintain both
 */
export class Typography {
    public static readonly fontSizes = {
        xs: 10,
        sm: 12,
        md: 14,
        lg: 16,
        xl: 18,
    };

    public static readonly fontWeights: { [weight in FontWeight]: number } = {
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
    };

    public static readonly lineHeight = 1.2;

    public static readonly fontFamily = "Quicksand";

    /**
     * Returns the css style value of the font size specified in pixel.
     *
     * @param size - the pixel size
     * @returns The css style font-size value of `size` in px
     */
    public static getFontSizePropValue(size: keyof typeof Typography.fontSizes): string {
        if (!size || !Typography.fontSizes.hasOwnProperty(size)) {
            console.warn(`[Typography] Could not recognize size: ${size}. Defaulting to 'md'`);
            return `${Typography.fontSizes.md}px`;
        }

        return `${Typography.fontSizes[size]}px`;
    }

    public static getFontWeightPropValue(weight: FontWeight): number {
        return this.fontWeights[weight];
    }
}
