import * as React from "react";
import { CustomAlert } from "../CustomAlert";
import { CustomDeleteAlertContent } from "./CustomDeleteAlertContent";
import { ItemType } from "@atman/business";
import { t } from "@lingui/macro";

export class CustomDeleteAlert extends CustomAlert {
    constructor(itemType: ItemType, itemLabel: string, deleteAction: () => void, htmlOverride?: React.ReactNode) {
        super(
            deleteAction,
            t({ id: "global.alerts.delete.title.prefix" }),
            htmlOverride ? htmlOverride : <CustomDeleteAlertContent itemType={itemType} itemLabel={itemLabel} />,
            {
                confirmButtonProps: { color: "danger" },
            },
        );

        this.confirmButtonText = t({ id: "global.buttons.labels.delete" });
    }
}
