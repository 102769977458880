import "./index.less";
import { AtContainer, ComponentPadding, IIconTitleSubtitleProps, IconTitleSubtitle, Sizing } from "../../../internal";
import React from "react";
import cn from "classnames";

export interface IReportHeaderProps extends IIconTitleSubtitleProps {
    rightContent: JSX.Element;
    secondaryTitle?: string;
    className?: string;
    padding?: null | ComponentPadding;
}

export const ReportHeader: React.FC<IReportHeaderProps> = (props: IReportHeaderProps) => {
    const {
        icon,
        title,
        description,
        rightContent,
        className,
        secondaryTitle,
        headerSize,
        size,
        padding = Sizing.paddings.md,
        reverseTitles,
        ...otherProps
    } = props;

    if (title === undefined) {
        return null;
    }

    return (
        <AtContainer className={cn("ReportHeader", className)} padding={padding} {...otherProps}>
            <IconTitleSubtitle
                icon={icon}
                title={title}
                secondaryTitle={secondaryTitle}
                description={description}
                headerSize={headerSize}
                size={size}
                reverseTitles={reverseTitles}
                helpCenterLink={rightContent}
            />
        </AtContainer>
    );
};
