import {
    AssessmentAuthMethod,
    ClientType,
    IBillingInformation,
    IProductConsumptionModeScheduleEntryModel,
    PlatformUseEnum,
    ProRestrictionType,
    ProductCodeProEnum,
} from "../types";
import { BaseModel, IBaseInput, IBaseModel } from "../base/BaseModel";
import { IMapEntries } from "mobx";
import { ReactAppSettings } from "../libs";
import { ReactText } from "react";
import { UsageModel } from "./UsageModel";

export interface IClientInput extends IBaseInput {
    name: string;
    email: string;
    communicationLanguageCode: string;
    billingInformation: IBillingInformation;
    consumptionSettings: IClientConsumptionSettings;
    generalSettings: IClientGeneralSettings;
    marketingInfo: IClientMarketingInfo;
    initialProductConsumptionModeScheduleEntry: IProductConsumptionModeScheduleEntryModel;
    stylesGroupOverrides: IMapEntries<string, string[]>;
    features?: IClientToggledFeatures;
}

export interface IClient extends IBaseModel {
    readonly consumptionAggregateRootId: string;
    readonly consumptionModelId: string;
    readonly name: string;
    readonly email: string;
    readonly partnerId: string;
    readonly communicationLanguageCode: string;
    readonly dateCreation: string;
    readonly consumptionSettings: IClientConsumptionSettings;
    readonly profilesCount: number;
    readonly lastSeen: string;
    readonly billingInformation: IBillingInformation;
    readonly generalSettings?: IClientGeneralSettings;
    readonly marketingInfo: IClientMarketingInfo;
    readonly usageModel: UsageModel;
    readonly transferableProducts: ProductCodeProEnum[];
    readonly features: IClientToggledFeatures;
    readonly competencyDevelopmentSettings: IClientCompetencyDevelopmentSettings;
    readonly evaluationSettings: IClientEvaluationSettings;
    readonly stylesGroupOverrides: IMapEntries<string, string[]>;
    readonly cultureId?: string;
    readonly cultureName?: string;
    readonly partnerIsInternal: boolean;
}

export class Client extends BaseModel implements IBaseInput {
    public consumptionAggregateRootId: string;
    public consumptionModelId: string;
    public name: string;
    public email: string;
    public partnerId: string;
    public communicationLanguageCode: string;
    public dateCreation: string;
    public consumptionSettings: IClientConsumptionSettings;
    public profilesCount: number;
    public lastSeen: string;
    public billingInformation: IBillingInformation;
    public generalSettings?: IClientGeneralSettings;
    public marketingInfo: IClientMarketingInfo;
    public usageModel: UsageModel;
    public transferableProducts: ProductCodeProEnum[];
    public features: IClientToggledFeatures;
    public competencyDevelopmentSettings: IClientCompetencyDevelopmentSettings;
    public evaluationSettings: IClientEvaluationSettings;
    public stylesGroupOverrides: Map<string, string[]>;
    public cultureId?: string;
    public cultureName?: string;
    public readonly partnerIsInternal: boolean;

    constructor(json: IClient) {
        super(json);

        this.consumptionAggregateRootId = json.consumptionAggregateRootId;
        this.consumptionModelId = json.consumptionModelId;
        this.name = json.name;
        this.email = json.email;
        this.partnerId = json.partnerId;
        this.communicationLanguageCode = json.communicationLanguageCode;
        this.dateCreation = json.dateCreation;
        this.consumptionSettings = json.consumptionSettings;
        this.profilesCount = json.profilesCount;
        this.lastSeen = json.lastSeen;
        this.billingInformation = json.billingInformation;
        this.generalSettings = json.generalSettings;
        this.marketingInfo = json.marketingInfo;
        this.usageModel = new UsageModel(json.usageModel);
        this.transferableProducts = json.transferableProducts;
        this.features = json.features;
        this.competencyDevelopmentSettings = json.competencyDevelopmentSettings;
        this.evaluationSettings = json.evaluationSettings;
        this.stylesGroupOverrides = json.stylesGroupOverrides
            ? new Map<string, string[]>(json.stylesGroupOverrides)
            : new Map<string, string[]>();
        this.cultureId = json.cultureId;
        this.cultureName = json.cultureName;
        this.partnerIsInternal = json.partnerIsInternal;
    }

    public get stylesGroupOverrideMode(): string {
        const groupModels = new Map(ReactAppSettings.appModel.personalityStylesGroupModels);

        const personalityStylesGroupOverride = this.stylesGroupOverrides.get(
            ReactAppSettings.appModel.personalityStylesGroupId,
        )!;

        const [groupModelKey] = [...groupModels.entries()].find(([_, modelIds]) =>
            modelIds.every((x) => personalityStylesGroupOverride.includes(x)),
        )!;

        return groupModelKey;
    }
}

export interface IClientConsumptionSettings {
    restrictionType: ProRestrictionType;
    discountRate: number;
}

export interface IClientGeneralSettings {
    ownerId: string;
    platformUses: PlatformUseEnum[];
}

export interface IClientMarketingInfo {
    clientType: ClientType;
    employeesCount: ReactText;
    expectedAnnualHiresCount: ReactText;
    expectedInterviewPerHire: ReactText;
    expectedAnnualGrowth: ReactText;
    turnoverRate: ReactText;
}

export interface IClientToggledFeatures {
    quickLinks: boolean;
    socioDemo: boolean;
    hideSocioDemoSensitiveQuestions: boolean;
    competencyDevelopment: boolean;
    allowSso: boolean;
    virtualCoach: boolean;
    customTermsConditions: boolean;
}

export interface ICustomTermsAndConditions {
    title: Map<string, string>;
    description: Map<string, string>;
}

export interface IOrganizationCustomTermsConditions {
    isActivated: boolean;
    customTermsConditions: ICustomTermsAndConditions;
    lastModified: string;
    lastModifiedBy: string;
}

export type TermsAndConditionsLangageCode = "en" | "fr" | "es" | "it" | "pt" | "de";

export interface IClientCompetencyDevelopmentSettings {
    enabled: boolean;
    optionalManagerAssessment: boolean;
    optionalSelfAssessment: boolean;
}

export interface IClientEvaluationSettings {
    assessmentAuthMethodOverride: AssessmentAuthMethod | null;
}

export interface IUpdateClientCompetencyDevelopmentSettings {
    isManagerAssessmentOptional: boolean;
    isSelfAssessmentOptional: boolean;
}
