import { Borders } from "../../../../../../styles/mixins/borders";
import { Sizing } from "../../../../../../styles/variables/sizing";

const thumbSize = Sizing.pixels.md;

const renderTrackFirstDiv = {
    padding: `0 ${thumbSize / 2}px`,
    height: Sizing.pixels.lg,
    display: "flex",
    width: "100%",
};

const renderTrackSecondDiv = {
    height: "8px",
    width: "100%",
    borderRadius: "4px",
    opacity: 1,
    backgroundColor: "#F5F5F5",
    alignSelf: "center",
};

const renderThumbFirstDiv = {
    height: thumbSize,
    width: thumbSize,
    opacity: 1,
    borderRadius: "50%",
    border: Borders.getBorderValue("input"),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 1px 4px #00000014",
};

const renderThumbSecondDiv = {
    height: "8px",
    width: "3px",
    opacity: 1,
    background: "#595959 0% 0% no-repeat padding-box",
};

const draggedThumbBackgroundColor = "#595959";
const thumbBackgroundColor = "#595959";

const styles = {
    renderTrackFirstDiv,
    renderTrackSecondDiv,
    renderThumbSecondDiv,
    renderThumbFirstDiv,
    thumbBackgroundColor,
    draggedThumbBackgroundColor,
};

export default styles;
