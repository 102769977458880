import { BasePlatformRootStore } from "../../base";
import { IRouteConfigEntry, RouteConfigEntry } from "./RouteConfigEntry";
import { ReactAppSettings } from "../../libs";

export class RouteProvider {
    // @ts-ignore
    private readonly rootStore: BasePlatformRootStore;
    private readonly _routes: RouteConfigEntry[] = [];

    public static readonly defaultRouteEntries = {
        account: "/Account",
        billing: "/Billing",
        settings: "/Settings",
    };

    public static get defaultAccountRouteSettings(): Omit<IRouteConfigEntry, "component"> {
        return {
            path: `${ReactAppSettings.basePath}${RouteProvider.defaultRouteEntries.account}`,
            breadcrumb: () => "global.account".localize(),
            exactMatch: false,
            subRoutes: [
                {
                    path: `${ReactAppSettings.basePath}${RouteProvider.defaultRouteEntries.account}/:activeView`,
                    breadcrumb: null,
                    component: () => null,
                },
            ],
        };
    }

    get routes(): RouteConfigEntry[] {
        return this._routes;
    }

    constructor(routes: IRouteConfigEntry[], includeDefaultRoutes: boolean = true) {
        const routesToRegister = routes;

        // if (includeDefaultRoutes) {
        //     routesToRegister = [
        //         ...routesToRegister,
        //         ...RouteProvider.defaultAccountRoute
        //     ];
        // }

        this._routes = routesToRegister.map((x) => new RouteConfigEntry(x));
    }
}
