import { Browser } from "../types";
import { BrowserHelper } from "./BrowserHelper";
import moment from "moment";

export class FileHelper {
    public static downloadFileData(data: BlobPart, fileName: string) {
        const blob = new Blob([data]);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        if (BrowserHelper.isBrowser(Browser.IE)) {
            navigator.msSaveBlob(blob, fileName);
        } else {
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    public static removeInvalidFileNameChars(fileName: string): string {
        return fileName.replace(/[~"#%&*:<>?/\\{|}]+/g, "");
    }

    public static getFileNameFriendlyDate(languageCode: string) {
        return moment().locale(languageCode).format("L").replace(/[/]+/g, "-");
    }

    public static getFileNameFromEmail(email: string) {
        return email.replace("@", "_at_").replace(".", "_");
    }
}
