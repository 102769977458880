import "./index.less";
import {
    AppScope,
    CompetencyProfile,
    ContentStore,
    GlobalStores,
    IAppContext,
    LocalizationStore,
    SeniorityLevel,
    getEnumValues,
} from "@atman/business";
import { AtLink, AtSelect, FormInputGroup, IReactSelectOptionObject, LocalizedTextInput } from "@atman/design-system";
import { AtSimpleSelect } from "../../../../../../design-system/lib/components/molecules/AtSimpleSelect";
import { FieldError } from "@atman/core";
import { Form } from "reactstrap";
import { Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../../../contexts";
import React, { useMemo, useState } from "react";

export type CompetencySetType = "culture" | "job";

export interface ICompetencySetInformationFormProps extends IAppContext {
    type: CompetencySetType;
    id: string;
    localizedNames: Map<string, string>;
    localizedDescriptions: Map<string, string>;
    onLocalizedNameChange: (locale: string, name: string) => void;
    onLocalizedDescriptionChange: (locale: string, description: string) => void;
    onAutoCompleteChange: <T>(options: T, fieldName?: string, enumValue?: boolean) => void;
    onFormSubStepSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    errors: FieldError[];
    departmentId?: IReactSelectOptionObject;
    profile?: CompetencyProfile | null;
    seniorityLevel?: SeniorityLevel | null;
    contentStore?: ContentStore;
    localizationStore?: LocalizationStore;
}

const CompetencySetInformationFormImpl: React.FC<ICompetencySetInformationFormProps> = inject(
    GlobalStores.contentStore,
    GlobalStores.localizationStore,
)(
    observer((props) => {
        const {
            onFormSubStepSubmit,
            errors,
            type,
            departmentId,
            id,
            localizedNames,
            localizedDescriptions,
            scope,
            onLocalizedNameChange,
            onLocalizedDescriptionChange,
            seniorityLevel,
            contentStore,
            localizationStore,
            onAutoCompleteChange,
            profile,
        } = props;

        const NAME_INPUT_MAX_LENGTH: number = 80;

        const [showOptionalLanguages, setShowOptionalLanguages] = useState<boolean>(false);
        const [showDescription, setShowDescription] = useState<boolean>(false);
        const [showDescriptionInOptionalLanguages, setShowDescriptionOptionalLanguages] = useState<boolean>(false);

        const shouldShowSeniorityLevelInput = useMemo(() => {
            return type === "job" && scope === AppScope.Client;
        }, [type, scope]);

        const shouldShowDepartmentInput = useMemo(() => {
            return scope === AppScope.Client;
        }, [scope]);

        const shouldShowProfileInput = useMemo(() => {
            return type === "job" && scope === AppScope.Supplier;
        }, [type, scope]);

        const shouldShowSegmentationFormSection = useMemo(() => {
            return shouldShowDepartmentInput || shouldShowProfileInput || shouldShowSeniorityLevelInput;
        }, [shouldShowDepartmentInput, shouldShowProfileInput, shouldShowSeniorityLevelInput]);

        const optionalLanguages = useMemo(() => {
            return localizationStore?.availableLanguages.filter(
                (x) => !localizationStore.requiredLanguages.includes(x.shortName),
            );
        }, [localizationStore]);

        return (
            <Form id={id} onSubmit={onFormSubStepSubmit} className="CompetencySetInformationForm">
                <div className="set-name-section">
                    {localizationStore?.requiredLanguages.map((requiredLanguage) => (
                        <LocalizedTextInput
                            key={requiredLanguage}
                            labelKey={type === "job" ? "global.jobName" : "global.cultureName"}
                            value={localizedNames.get(requiredLanguage) ?? ""}
                            currentLocale={requiredLanguage}
                            fieldName={`localizedName-${requiredLanguage}`}
                            validationErrors={errors}
                            onChange={(e) => {
                                onLocalizedNameChange(requiredLanguage, e.target.value);
                            }}
                            maxLength={NAME_INPUT_MAX_LENGTH}
                        />
                    ))}
                    {optionalLanguages?.length ?? 0 > 0 ? (
                        showOptionalLanguages ? (
                            optionalLanguages?.map((optionalLanguage) => (
                                <LocalizedTextInput
                                    key={optionalLanguage.shortName}
                                    labelKey={type === "job" ? "global.jobName" : "global.cultureName"}
                                    value={localizedNames.get(optionalLanguage.shortName) ?? ""}
                                    currentLocale={optionalLanguage.shortName}
                                    fieldName={`localizedName-${optionalLanguage.shortName}`}
                                    validationErrors={errors}
                                    onChange={(e) => {
                                        onLocalizedNameChange(optionalLanguage.shortName, e.target.value);
                                    }}
                                    maxLength={NAME_INPUT_MAX_LENGTH}
                                />
                            ))
                        ) : (
                            <AtLink
                                color="secondary"
                                onClick={() => {
                                    setShowOptionalLanguages(true);
                                }}
                                underlined
                            >
                                <Trans id="global.translateInOtherLanguages" />
                            </AtLink>
                        )
                    ) : null}
                </div>
                {showDescription ? (
                    <div className="set-description-section">
                        {localizationStore?.requiredLanguages.map((requiredLanguage) => (
                            <LocalizedTextInput
                                key={requiredLanguage}
                                labelKey={type === "job" ? "global.jobDescription" : "global.cultureDescription"}
                                value={localizedDescriptions.get(requiredLanguage) ?? ""}
                                currentLocale={requiredLanguage}
                                fieldName={`localizedDescription-${requiredLanguage}`}
                                type="textarea"
                                rows={3}
                                validationErrors={errors}
                                onChange={(e) => {
                                    onLocalizedDescriptionChange(requiredLanguage, e.target.value);
                                }}
                            />
                        ))}
                        {showDescriptionInOptionalLanguages ? (
                            optionalLanguages?.map((optionalLanguage) => (
                                <LocalizedTextInput
                                    key={optionalLanguage.shortName}
                                    labelKey={type === "job" ? "global.jobDescription" : "global.cultureDescription"}
                                    value={localizedDescriptions.get(optionalLanguage.shortName) ?? ""}
                                    currentLocale={optionalLanguage.shortName}
                                    fieldName={`localizedDescription-${optionalLanguage.shortName}`}
                                    type="textarea"
                                    rows={3}
                                    validationErrors={errors}
                                    onChange={(e) => {
                                        onLocalizedDescriptionChange(optionalLanguage.shortName, e.target.value);
                                    }}
                                />
                            ))
                        ) : (
                            <AtLink
                                color="secondary"
                                onClick={() => setShowDescriptionOptionalLanguages(true)}
                                underlined
                            >
                                <Trans id="global.translateInOtherLanguages" />
                            </AtLink>
                        )}
                    </div>
                ) : (
                    <AtLink
                        color="secondary"
                        onClick={() => {
                            setShowDescription(true);
                        }}
                        underlined
                        className={"add-description-link"}
                    >
                        <Trans id="global.entityWizard.informations.addDescription">
                            + Add a description (optional)
                        </Trans>
                    </AtLink>
                )}
                {shouldShowSegmentationFormSection && (
                    <>
                        {shouldShowDepartmentInput && (
                            <FormInputGroup
                                label={"global.department".localize()}
                                description={
                                    type === "job"
                                        ? "global.jobDepartment.description".localize()
                                        : "global.cultureDepartment.description".localize()
                                }
                            >
                                <AtSelect
                                    name={"departmentId"}
                                    options={contentStore!.departments
                                        .sort((a, b) => {
                                            // TODO: Remove this -- handle it at the back-end level
                                            // Move 'Other' option to the bottom of the list.
                                            const otherOptionId: string = "D08C74AA-7029-4331-B60F-BF6FBE1E4498";

                                            if (a.id.toUpperCase() === otherOptionId) {
                                                return 1;
                                            }

                                            return a.name.localeCompare(b.name);
                                        })
                                        .map(
                                            (x) =>
                                                ({
                                                    value: x.id,
                                                    label: x.name,
                                                } as IReactSelectOptionObject),
                                        )}
                                    value={departmentId ?? null}
                                    onChange={(o) => onAutoCompleteChange(o, "departmentId")}
                                    validationErrors={errors.filter((x) => x.fieldName === "departmentId")}
                                />
                            </FormInputGroup>
                        )}
                        {shouldShowSeniorityLevelInput && (
                            <FormInputGroup
                                label={"global.seniorityLevel".localize()}
                                description={"global.seniorityLevel.description".localize()}
                            >
                                <AtSimpleSelect
                                    value={seniorityLevel!}
                                    onChange={(o) => onAutoCompleteChange(o, "seniorityLevel", true)}
                                    options={getEnumValues<SeniorityLevel>(SeniorityLevel)}
                                    getOptionLabel={(x) =>
                                        `global.seniorityLevels.${SeniorityLevel[x].toCamel()}`.localize()
                                    }
                                    name="seniorityLevel"
                                    validationErrors={errors.filter((x) => x.fieldName === "seniorityLevel")}
                                />
                            </FormInputGroup>
                        )}
                        {shouldShowProfileInput && (
                            <FormInputGroup
                                label={"global.profile".localize()}
                                description={"global.profile.description".localize()}
                            >
                                <AtSimpleSelect
                                    name="profile"
                                    options={getEnumValues<CompetencyProfile>(CompetencyProfile)}
                                    getOptionLabel={(x) =>
                                        `global.competencyProfiles.${CompetencyProfile[x].toCamel()}`.localize()
                                    }
                                    value={profile!}
                                    onChange={(o) => onAutoCompleteChange(o, "profile")}
                                    validationErrors={errors.filter((x) => x.fieldName === "profile")}
                                />
                            </FormInputGroup>
                        )}
                    </>
                )}
            </Form>
        );
    }),
);

export const CompetencySetInformationForm = withAppContext(CompetencySetInformationFormImpl);
