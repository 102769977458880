import "./BaseOverviewCardItem.less";
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Label } from "reactstrap";

export interface IBaseOverviewCardItemProps {
    cardTitle: string;
    icon: IconProp;
    className?: string;
    id?: string;
}

export const BaseOverviewCardItem: React.FC<IBaseOverviewCardItemProps> = (props) => {
    const { cardTitle, icon, className, id, children } = props;

    return (
        <div className={`BaseOverviewCardItem ${className ? className : ""}`} id={id}>
            <div className="icon-container">
                <FontAwesomeIcon icon={icon} size={"lg"} />
            </div>
            <div className="info-container">
                <Label>{cardTitle}</Label>
                <div className="content">{children}</div>
            </div>
        </div>
    );
};
