import { BaseTag, IBaseTag, IBaseTagInput } from "./BaseTag";

export interface IWorkspaceInput extends IBaseTagInput {}

export interface IWorkspace extends IBaseTag {}

export class Workspace extends BaseTag implements IWorkspace {
    constructor(json?: IWorkspace) {
        super(json);
    }
}
