import "./index.less";
import * as React from "react";
import { AtStepperStep } from "./components/AtStepperStep";
import { IWizardStepDetails } from "../AtWizard";
import { action, autorun, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";

export interface IAtStepperProps {
    currentStep: number;
    steps: IStepperStep[];
    allowExpansionOnAllSteps?: boolean;
}

@inject()
@observer
export class AtStepper extends React.Component<IAtStepperProps, {}> {
    @observable private stepDetails: IStepperStepDetails[];
    @observable private expandedStep: number | undefined = this.props.currentStep;

    constructor(props) {
        super(props);

        autorun(() => {
            this.stepDetails = this._stepDetails;
        });
    }

    @computed private get _stepDetails(): IStepperStepDetails[] {
        const { steps } = this.props;

        return steps.map(
            (x, i) =>
                ({
                    ...x,
                    number: i + 1,
                } as IWizardStepDetails),
        );
    }

    @action toggleStep = (step: number) => {
        if (this.expandedStep === step) {
            this.expandedStep = undefined;
        } else {
            this.expandedStep = step;
        }
    };

    render() {
        const { currentStep, allowExpansionOnAllSteps = false } = this.props;

        return (
            <div className="AtStepper">
                {this.stepDetails.map((x) => (
                    <AtStepperStep
                        {...x}
                        currentStep={currentStep}
                        expandedStep={this.expandedStep}
                        toggleStep={this.toggleStep}
                        allowExpansionOnAllSteps={allowExpansionOnAllSteps}
                        key={x.number}
                    />
                ))}
            </div>
        );
    }
}

export interface IStepperStep {
    title: string;
    description?: string;
    content?: React.ReactNode;
    additionalHeaderContent?: React.ReactNode;
}

export interface IStepperStepDetails extends IStepperStep {
    number: number;
}
