import "./index.less";
import * as React from "react";
import { CustomInput } from "reactstrap";

export interface IAtRadioButtonBaseProps {
    name?: string;
    value?: string | ReadonlyArray<string> | number;
    label?: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    id?: string;
    disabled?: boolean;
}

export const AtRadioButtonBase: React.FC<IAtRadioButtonBaseProps> = (props) => {
    const { name, value, checked, disabled, onChange, label, id, ...otherProps } = props;

    return (
        <CustomInput
            className="AtRadioButtonBase"
            type="radio"
            name={name}
            id={id}
            value={value}
            checked={checked}
            onChange={onChange}
            label={label}
            disabled={disabled}
            {...otherProps}
        />
    );
};
