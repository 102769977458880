import "./index.less";
import { AtMenuButton } from "../../../../organisms/AtMenuButton";
import { useUniqueId } from "@atman/core";
import React from "react";
import cn from "classnames";

export interface ITableItemButtonsProps {
    menuContent?: JSX.Element;
    mainButton?: JSX.Element;
    secondaryButton?: JSX.Element;
    size?: "md" | "xs" | "sm" | "lg" | "xl";
    className?: string;
}

export const TableItemButtons: React.FC<ITableItemButtonsProps> = (props: ITableItemButtonsProps) => {
    const { mainButton, menuContent, className, secondaryButton } = props;
    const { uniqueId } = useUniqueId();

    return (
        <div className={cn("TableItemButtons", className)}>
            {secondaryButton && (
                <div className="secondaryButton" key={uniqueId}>
                    {secondaryButton}
                </div>
            )}

            {mainButton ? (
                mainButton
            ) : (
                <AtMenuButton key={uniqueId} buttonClassName="color-ghost" size="md" direction="left">
                    {menuContent}
                </AtMenuButton>
            )}
        </div>
    );
};
