import "./AccountSecurity.less";
import { AtButton, AtTabCard } from "@atman/design-system";
import { BaseForm } from "../../../components/BaseForm";
import { GlobalStores, ITokenSession, ToastProvider, TokensApi, UserInfoStore } from "@atman/business";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import React from "react";
import SessionsList from "./components/SessionsList";

export interface IAccountSecurityProps {
    userInfoStore?: UserInfoStore;
}

@inject(GlobalStores.userInfoStore)
@observer
class AccountSecurity extends BaseForm<IAccountSecurityProps, {}> {
    @observable public sessions: ITokenSession[] = [];
    @observable private loadingStates: Map<string, boolean> = new Map<string, boolean>();

    componentDidMount() {
        this.loadSessions();
    }

    loadSessions = async () => {
        this.setIsLoading(true);

        this.sessions = await TokensApi.getSessions();

        this.setIsLoading(false);
    };

    logoutEverywhere = () => {
        const { userInfoStore } = this.props;

        this.setIsLoading(true);

        userInfoStore!.logoutFromAllDevices();
    };

    logoutFromOtherDevices = async () => {
        const { userInfoStore } = this.props;

        for (const session of this.sessions) {
            this.loadingStates.set(session.id, true);
        }

        await userInfoStore!.logoutFromOtherDevices();
        await this.loadSessions();

        this.loadingStates.clear();

        ToastProvider.success("global.successfullyLoggedOutOtherSessions".localize());
    };

    logoutFromCurrentDevice = () => {
        const { userInfoStore } = this.props;

        this.setIsLoading(true);

        userInfoStore!.logout();
    };

    render() {
        const {} = this.props;

        return (
            <div id="AccountSecurity">
                <AtTabCard
                    cardTitle={"global.loginSessions".localize()}
                    rightHeaderContent={
                        <div className="buttons-container">
                            <AtButton
                                color={"secondary"}
                                size={"md"}
                                onClick={this.loadSessions}
                                icon={["far", "sync"]}
                            />
                            <AtButton color={"secondary"} size={"md"} onClick={this.logoutFromOtherDevices}>
                                {"global.signOutAllOtherSessions".localize()}
                            </AtButton>
                            <AtButton color={"danger"} size={"md"} onClick={this.logoutEverywhere}>
                                {"global.signOutEverywhere".localize()}
                            </AtButton>
                        </div>
                    }
                >
                    <SessionsList
                        sessions={this.sessions}
                        reloadSessions={this.loadSessions}
                        loadingStates={this.loadingStates}
                    />
                </AtTabCard>
            </div>
        );
    }
}

export default AccountSecurity;
