import {
    DEFAULT_FULLPIE_COMPACT_WIDTH,
    DEFAULT_FULLPIE_REGULAR_WIDTH,
    DEFAULT_HALFPIE_COMPACT_WIDTH,
    DEFAULT_HALFPIE_REGULAR_WIDTH,
} from "./constants";
import { ScoreInfoSizing } from "..";
import { inject, observer } from "mobx-react";
import React from "react";

export interface IScoreSVGContainerProps {
    containerWidth?: number; // Try using the sizing props before using this one in order to keep it standard.
    sizing?: ScoreInfoSizing;
    isHalfHeight?: boolean;
    isThreeQuater?: boolean;
    viewBoxWidth?: number;
}

@inject()
@observer
export class ScoreSVGContainer extends React.Component<IScoreSVGContainerProps> {
    render() {
        const { children, sizing, containerWidth, isHalfHeight, isThreeQuater, viewBoxWidth = 400 } = this.props;

        let viewBoxHeight = viewBoxWidth;

        let width = 0;
        if (isHalfHeight)
            width = sizing == ScoreInfoSizing.compact ? DEFAULT_HALFPIE_COMPACT_WIDTH : DEFAULT_HALFPIE_REGULAR_WIDTH;
        else
            width = containerWidth
                ? containerWidth
                : sizing === ScoreInfoSizing.regular
                ? DEFAULT_FULLPIE_REGULAR_WIDTH
                : DEFAULT_FULLPIE_COMPACT_WIDTH;

        let height = width;

        if (isHalfHeight) {
            viewBoxHeight = viewBoxHeight / 2;
            height = height / 2;
        }

        if (isThreeQuater) {
            height = 100;
        }

        return (
            <div style={{ width: width, height: height }}>
                <svg viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`} height={height} width={width}>
                    {children}
                </svg>
            </div>
        );
    }
}
