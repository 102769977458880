import { BaseFit, IBaseFit } from "./BaseFit";
import { IJobFitContent, JobFitContent } from "./JobFitContent";
import { ProcessState } from "../../types";
import { observable } from "mobx";

export interface IJobFit extends IBaseFit<IJobFitContent> {
    readonly processState: ProcessState;
    readonly jobDomainId?: string;
    readonly jobTypeId?: string;
}

export class JobFit extends BaseFit<IJobFitContent> implements IJobFit {
    @observable public processState: ProcessState;
    public content: JobFitContent;

    public jobDomainId: string | undefined;
    public jobTypeId: string | undefined;

    constructor(json: IJobFit) {
        super(json);

        this.content = new JobFitContent(json.content);
        this.processState = json.processState;
        this.jobDomainId = json.jobDomainId;
        this.jobTypeId = json.jobTypeId;
    }
}
