import "./index.less";
import * as React from "react";
import { CSSProperties } from "react";
import { ColorProperty } from "csstype";
import { Colors } from "../../../../../styles/variables/colors";
import {
    DistributionViewMode,
    FitLevelEnum,
    IDistributionEntry,
    IPersonScore,
    IScorePositionInfo,
    PrintModeEnum,
    ScaleType,
} from "@atman/business";
import { InitialsAvatar } from "@atman/design-system";
import { computed } from "mobx";
import { observer } from "mobx-react";

export interface IScoreContainerProps {
    scaleType: ScaleType;
    positionKey: number;
    positionInfo: IScorePositionInfo;
    distributionEntry?: IDistributionEntry;
    showDistribution?: boolean;
    showSeparator: boolean;
    distributionViewMode?: DistributionViewMode;
    isDistributionAverage?: boolean;
    scoreInfos?: IPersonScore[];
    isGradient?: boolean;
    colorMapping?: Map<number, ColorProperty>;
    colorMappingFunc?: (value: number) => ColorProperty;
    teamName?: string;
    printMode?: PrintModeEnum;
    isInFit?: boolean;
    fitLevel?: FitLevelEnum;
    useGreenBackgroundColor?: boolean;
    forceShowScoreCount?: boolean;
}

@observer
class ScoreContainer extends React.Component<IScoreContainerProps, {}> {
    public static defaultProps = {
        colorMapping: Colors.STANINE_SCORE_TO_GRADIENT_MAPPING,
        isGradient: false,
    };

    @computed get styles(): CSSProperties {
        const { positionInfo } = this.props;

        return {
            left: `${positionInfo.left}%`,
            width: `${positionInfo.width}%`,
        } as CSSProperties;
    }

    getStyleForScore(score: number): React.CSSProperties {
        const { isGradient, printMode, isInFit, fitLevel } = this.props;

        if (isGradient && printMode === undefined) {
            return {
                backgroundImage: this.getColorGradientForScore(score),
            };
        } else if (printMode !== undefined && isInFit) {
            return {
                backgroundColor: this.getColorForFitLevel(fitLevel),
            };
        } else {
            return {
                backgroundColor: this.getColorForScore(score),
            };
        }
    }

    getColorGradientForScore(score: number): string {
        const { colorMapping, colorMappingFunc } = this.props;

        const startColor = colorMappingFunc ? colorMappingFunc(1) : colorMapping!.get(1);

        const endColor = colorMappingFunc ? colorMappingFunc(score) : colorMapping!.get(score);

        return `linear-gradient(to right, ${startColor},${endColor} 85%)`;
    }

    getColorForScore(score: number): string | undefined {
        const { colorMapping, colorMappingFunc, printMode, useGreenBackgroundColor = false } = this.props;

        // gradient color change for print #3517
        if (printMode !== undefined && useGreenBackgroundColor) {
            return Colors.reportGreen;
        }

        return colorMappingFunc ? colorMappingFunc(score) : colorMapping!.get(score);
    }

    getColorForFitLevel(fitLevel: FitLevelEnum | undefined): string {
        switch (fitLevel) {
            case FitLevelEnum.VeryGood:
                return Colors.greenBase;
            case FitLevelEnum.GoodFit:
                return Colors.yellowShades.shade4;
            case FitLevelEnum.BeVigilent:
                return Colors.redShades.shade4;
            default:
                return Colors.greyShades.shade4;
        }
    }

    renderDistributionCount(): JSX.Element | null {
        const { distributionEntry, showDistribution, distributionViewMode, forceShowScoreCount = false } = this.props;

        if (!distributionEntry || (!showDistribution && !forceShowScoreCount) || distributionViewMode !== "numbers") {
            return null;
        }

        return (
            <div className="distribution-count">{distributionEntry?.count > 0 ? distributionEntry?.count : "-"}</div>
        );
    }

    renderDistributionAverage(): JSX.Element | null {
        const { distributionEntry, showDistribution, distributionViewMode, isDistributionAverage, teamName } =
            this.props;

        if (!distributionEntry || !showDistribution || distributionViewMode !== "average" || !isDistributionAverage) {
            return null;
        }

        return (
            <InitialsAvatar
                text={teamName ?? "global.itemTypes.team".localize()}
                size={"xs"}
                textColor={"white"}
                bgColor={"#BB52F0" /* Colors.greyColors.lighter2 */}
            />
        );
    }

    render(): JSX.Element {
        const { scoreInfos, scaleType, distributionViewMode, showSeparator, printMode } = this.props;

        let classes: string = `ScoreContainer scale-type-${ScaleType[scaleType].toCamel()}`;

        if (showSeparator) {
            classes += " separator";
        }

        return (
            <div className={classes} style={this.styles}>
                {scoreInfos?.any()
                    ? scoreInfos?.map((x) => {
                          const key = `${x.candidateProId}-${x.score}`;
                          if (scaleType === ScaleType.Unipolar) {
                              return (
                                  <div
                                      key={`${key}-scale`}
                                      className="score-indicator"
                                      style={this.getStyleForScore(x.score)}
                                  />
                              );
                          }

                          if (!distributionViewMode || distributionViewMode === "average") {
                              return (
                                  <InitialsAvatar
                                      key={`${key}-avatar`}
                                      text={x.candidateDisplayName}
                                      bgColor={x.color}
                                      textColor={x.textColor}
                                      size={"xs"}
                                      printMode={printMode}
                                  />
                              );
                          }

                          return (
                              <div
                                  key={`${key}-scoreindicator`}
                                  className="score-indicator"
                                  style={{ backgroundColor: x?.color, color: x.textColor }}
                              >
                                  {this.renderDistributionCount()}
                              </div>
                          );
                      })
                    : this.renderDistributionCount()}
                {this.renderDistributionAverage()}
            </div>
        );
    }
}

export { ScoreContainer };
