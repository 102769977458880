import "./SessionsList.less";
import * as React from "react";
import { AtLink } from "@atman/design-system";
import { BaseComponent } from "../../../../components/BaseComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ITokenSession, ToastProvider, TokenSessionState, TokensApi } from "@atman/business";
import { Table } from "reactstrap";
import { UAParser } from "ua-parser-js";
import { action, autorun, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";

export interface ISessionsListProps {
    sessions: ITokenSession[];
    loadingStates: Map<string, boolean>;
    reloadSessions: () => Promise<void>;
}

@inject()
@observer
class SessionsList extends BaseComponent<ISessionsListProps> {
    @observable public sessions: IDetailedTokenSession[] = [];

    constructor(props) {
        super(props);

        autorun(() => {
            this.sessions = this._sessions;
        });
    }

    @computed
    private get _sessions(): IDetailedTokenSession[] {
        const { sessions } = this.props;

        const mappedSessions = sessions.map((x) => {
            const uaParser = new UAParser(x.userAgent);

            return {
                id: x.id,
                location: x.location,
                ip: x.ip,
                createdOn: x.createdOn,
                state: x.state,
                browserInfo: uaParser.getBrowser(),
                osInfo: uaParser.getOS(),
            } as IDetailedTokenSession;
        });

        return this.sortArrayByNumberProp(mappedSessions, (x) => moment.parseZone(x.createdOn).unix(), "desc");
    }

    @action logoutFromDevice = async (id: string) => {
        const { reloadSessions, loadingStates } = this.props;

        loadingStates.set(id, true);
        await TokensApi.logoutFromDevice(id);
        await reloadSessions();

        loadingStates.delete(id);
        ToastProvider.success("global.successfullyLoggedOutSession".localize());
    };

    render() {
        const { loadingStates } = this.props;

        return (
            <div id="SessionsList">
                <div className="list-container">
                    <Table striped>
                        <thead>
                            <tr>
                                <th>{"global.location".localize()}</th>
                                <th>{"global.ipAddress".localize()}</th>
                                <th>{"global.device".localize()}</th>
                                <th>{"global.created".localize()}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.sessions.map((x) => (
                                <tr key={x.id}>
                                    <td>{x.location}</td>
                                    <td>{["::1", "127.0.0.1"].includes(x.ip) ? "localhost" : x.ip}</td>
                                    <td>{`${x.osInfo.name} ${x.osInfo.version} / ${x.browserInfo.name}`}</td>
                                    <td>{moment.parseZone(x.createdOn).fromNow()}</td>
                                    <td>
                                        {loadingStates.get(x.id) ? (
                                            <FontAwesomeIcon icon={["far", "spinner-third"]} spin />
                                        ) : x.state === TokenSessionState.Active ? (
                                            <AtLink onClick={() => this.logoutFromDevice(x.id)}>
                                                {"global.signOut".localize()}
                                            </AtLink>
                                        ) : x.state === TokenSessionState.Current ? (
                                            "global.currentSession".localize()
                                        ) : (
                                            TokenSessionState[x.state].toCamel().localize()
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

export interface IDetailedTokenSession {
    id: string;
    location: string;
    ip: string;
    createdOn: string;
    browserInfo: IUAParser.IBrowser;
    osInfo: IUAParser.IOS;
    state: TokenSessionState;
}

export default SessionsList;
