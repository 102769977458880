import "./index.less";
import {
    AppScope,
    Client,
    ClientStore,
    GlobalStores,
    IAppContext,
    Partner,
    PartnerStore,
    UnhandledScopeError,
} from "@atman/business";
import { AtTabCard } from "@atman/design-system";
import { BaseForm } from "../BaseForm";
import { CardSaveButtons } from "../CardSaveButtons";
import { CustomTextInput } from "../CustomTextInput";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";
import React from "react";
import autobind from "autobind-decorator";

export interface IEntityNameFormProps extends IAppContext {
    entity: Client | Partner;
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
}

@inject(GlobalStores.clientStore, GlobalStores.partnerStore)
@observer
class EntityNameFormComp extends BaseForm<IEntityNameFormProps, {}> {
    @observable public name: string = "";

    componentDidMount(): void {
        this.resetName();
    }

    @action.bound
    resetName = () => {
        this.name = this.props.entity.name;
    };

    @autobind
    async _onSave(): Promise<any> {
        const { entity, scope, clientStore, partnerStore } = this.props;

        this.setIsLoading(true);

        switch (scope) {
            case AppScope.Partner:
                await clientStore!.updateClientName(entity.id, this.name);
                break;
            case AppScope.Supplier:
                await partnerStore!.updatePartnerName(entity.id, this.name);
                break;
            default:
                this.setIsLoading(false);
                throw new UnhandledScopeError(scope);
        }

        this.setIsLoading(false);
    }

    validateForm(): boolean {
        return this.name.length > 0;
    }

    render() {
        const {} = this.props;

        return (
            <AtTabCard cardTitle={"global.name".localize()} id={"EntityNameForm"} className={"entity-name-form"}>
                <div className="inputs">
                    <CustomTextInput fieldName={"name"} value={this.name} onChange={this.onTextFieldChange} />
                </div>
                <CardSaveButtons
                    saveAction={this._onSave}
                    cancelAction={this.resetName}
                    isLoading={this.isLoading}
                    disabledSaveButton={!this.validateForm()}
                />
            </AtTabCard>
        );
    }
}

const EntityNameForm = withAppContext(EntityNameFormComp);

export { EntityNameForm };
