import "./UserFormModalContentCompDev.less";
import * as React from "react";
import {
    AtCheckboxBase,
    AtContainer,
    AtIcon,
    AtInput,
    AtSelect,
    AtText,
    AtTitle,
    IReactSelectOptionObject,
} from "@atman/design-system";
import { AtTempModal } from "@atman/design-system/lib/components/molecules/AtTable/component/AtTempModal";
import {
    ErrorHandler,
    GlobalStores,
    IAppContext,
    IUserInput,
    LocalizationStore,
    ModalStore,
    Role,
    User,
    UserInfoStore,
    UserStore,
} from "@atman/business";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useState } from "react";

export interface IUserFormModalContentCompDevProps extends IAppContext {
    teamId: string;
    isModalHidden: boolean;
    setIsModalHidden: (visibility: boolean) => void;
    onUserCreated: (user: User) => void;
    userInfoStore?: UserInfoStore;
    userStore?: UserStore;
    modalStore?: ModalStore;
    localizationStore?: LocalizationStore;
}

export const UserFormModalContentCompDev: React.FC<IUserFormModalContentCompDevProps> = inject(
    GlobalStores.userInfoStore,
    GlobalStores.modalStore,
    GlobalStores.userStore,
    GlobalStores.localizationStore,
)(
    observer((props) => {
        const { localizationStore, userStore, userInfoStore, teamId, isModalHidden, setIsModalHidden, onUserCreated } =
            props;

        const [isLoading, setIsLoading] = useState<boolean>(false);
        const [firstName, setFirstName] = React.useState<string>("");
        const [lastName, setLastName] = React.useState<string>("");
        const [email, setEmail] = React.useState<string>("");
        const [communicationEmail, setCommunicationEmail] = React.useState<string>("");
        const [languageCode, setLanguageCode] = React.useState<IReactSelectOptionObject>({
            value: localizationStore!.currentLocale.cultureInfo,
            label: localizationStore!.currentLocale.shortDisplay,
        });
        const [notifyUser, setNotifyUser] = React.useState<boolean>(false);

        const languageOptions = localizationStore!.availableLanguages.map((x) => ({
            value: x.name,
            label: x.shortDisplay,
        }));

        const onHandleFirstNameChange = (e) => {
            const { value } = e.target;

            setFirstName(value);
        };

        const onHandleLastNameChange = (e) => {
            const { value } = e.target;

            setLastName(value);
        };

        const onHandleEmailChange = (e) => {
            const { value } = e.target;

            setEmail(value);
        };

        const onHandleCommunicationEmailChange = (e) => {
            const { value } = e.target;

            setCommunicationEmail(value);
        };

        const onSelectLanguageChange = (option: IReactSelectOptionObject) => {
            if (option) {
                setLanguageCode(option);
            }
        };

        const handleSendInvitation = () => {
            setNotifyUser(!notifyUser);
        };

        const onSave = async () => {
            const userInfo: IUserInput & { notifyUser?: boolean } = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                communicationEmail: communicationEmail,
                languageCode: languageCode.value,
                baseRole: Role[Role.UserClient],
                additionalRoles: [Role[Role.TeamManager]],
                canViewRestrictedTags: false,
                isRestrictedToAssignedWorkspaces: false,
                forceTeamManagerAssignment: true,
                workspaces: [],
                teams: [teamId],
                clientId: userInfoStore!.clientId,
                partnerProId: userInfoStore!.partnerId,
                notifyUser: notifyUser,
            };

            try {
                setIsLoading(true);

                await userStore!.createUser(userInfo);

                setIsModalHidden(true);

                const user = userStore!.users.find((x) => x.email === email)!;

                onUserCreated(user);
            } catch (e) {
                ErrorHandler.handleError(e);
            } finally {
                setIsLoading(false);
            }
        };

        return (
            <AtTempModal
                className="UserFormModalContentCompDev"
                title={t({ id: "global.userCreationModal" })}
                isModalHidden={isModalHidden}
                setIsModalHidden={setIsModalHidden}
                secondaryActionLabel={t({ id: "global.cancel" })}
                secondaryAction={() => setIsModalHidden(true)}
                mainActionLabel={t({ id: "global.createUser" })}
                mainAction={() => onSave()}
                mainActionIsLoading={isLoading}
            >
                <AtContainer>
                    <div className="first-and-last-name">
                        <div className="firstNameInput">
                            <AtTitle className="inputTitle" title={t({ id: "global.firstName" })} />
                            <AtInput
                                fieldName={""}
                                value={firstName}
                                onChange={onHandleFirstNameChange}
                                placeholder="John"
                            />
                        </div>

                        <div className="lastNameInput">
                            <AtTitle className="inputTitle" title={t({ id: "global.lastName" })} />
                            <AtInput
                                fieldName={""}
                                value={lastName}
                                onChange={onHandleLastNameChange}
                                placeholder="Doe"
                            />
                        </div>
                    </div>

                    <div className="emails-and-language">
                        <AtTitle className="inputTitle" title={t({ id: "global.email" })} />
                        <AtInput
                            fieldName={""}
                            value={email}
                            onChange={onHandleEmailChange}
                            placeholder="johndoe@acmecompany.com"
                        />

                        <AtTitle className="inputTitle" title={t({ id: "global.communicationEmail" })} />
                        <AtInput
                            fieldName={""}
                            value={communicationEmail}
                            onChange={onHandleCommunicationEmailChange}
                            placeholder="johndoe@acmecompany.com"
                        />

                        <AtSelect
                            name="languageCode"
                            label={"global.communicationLanguage".localize()}
                            options={languageOptions}
                            value={languageCode}
                            onChange={onSelectLanguageChange}
                        />
                    </div>

                    <div className="highlight-and-checkbox">
                        <div className="highlight">
                            <div className="icon-and-title">
                                <AtIcon icon={["fas", "info-circle"]} />
                                <AtTitle
                                    className="blueText"
                                    title={t({ id: "clientApp.compDevModal.blueText" })}
                                    headingType={5}
                                />
                            </div>
                            <div className="highlight-text-info">
                                <AtText>{t({ id: "clientApp.compDevModal.highlightedText.information" })}</AtText>
                            </div>
                        </div>

                        <div className="checkbox-title-desc">
                            <div className="checkbox-and-text">
                                <AtCheckboxBase checked={notifyUser} onChange={handleSendInvitation} id="sendInvite" />
                                <AtTitle title={t({ id: "clientApp.compDevModal.checkbox.title" })} headingType={6} />
                            </div>
                            <div className="highlight-text-info">
                                <AtText>{t({ id: "clientApp.compDevModal.checkbox.information" })}</AtText>
                            </div>
                        </div>
                    </div>
                </AtContainer>
            </AtTempModal>
        );
    }),
);
