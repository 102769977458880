import "./index.less";
import * as React from "react";
import { Elements } from "react-stripe-elements";
import { Modal } from "reactstrap";
import { ModalStore, ModalTypes } from "@atman/business";
import { computed } from "mobx";
import autobind from "autobind-decorator";

export interface IBaseModalContainerProps {
    modalStore?: ModalStore;
}

export abstract class BaseModalContainer<TProps> extends React.Component<IBaseModalContainerProps & TProps, {}> {
    protected abstract modalTypesMapping: Dictionary<ModalTypes, React.ReactNode>;

    @computed
    get backdropSettings(): IModalBackdropSettings {
        const {} = this.props;

        // const backdropSettings: IModalBackdropSettings = {
        //     backdrop: true,
        //     keyboard: true,
        // };

        const noBackdropSettings: IModalBackdropSettings = {
            keyboard: false,
            backdrop: "static",
        };

        // @ts-ignore
        // switch (modalStore!.modalType) {
        //     case ModalTypes.AddCredits:
        //         return backdropSettings;
        //     default:
        //         return noBackdropSettings;
        // }

        return noBackdropSettings;
    }

    renderStripeElementsContainer(modal: React.ReactNode): React.ReactNode {
        return (
            <Elements fonts={[{ cssSrc: "https://fonts.googleapis.com/css?family=Quicksand:400,500,700" }]}>
                {modal}
            </Elements>
        );
    }

    @autobind
    renderBody() {
        const { modalStore } = this.props;

        // @ts-ignore
        const modalType: ModalTypes = modalStore!.modalType;

        if (modalType !== undefined && this.modalTypesMapping.hasOwnProperty(modalType)) {
            return this.modalTypesMapping[modalType];
        }

        return null;
    }

    renderBase(id?: string) {
        const { modalStore } = this.props;

        const showModal: boolean = modalStore!.showModal;
        const toggleModal: () => void = modalStore!.toggleModal;

        return (
            <Modal
                isOpen={showModal}
                toggle={toggleModal}
                modalClassName="BaseModalContainer modal-right-pane"
                wrapClassName="modal-wrapper"
                contentClassName="modal-content"
                backdropClassName="modal-backdrop"
                id={id}
                {...this.backdropSettings}
            >
                {this.renderBody()}
            </Modal>
        );
    }
}

export interface IModalBackdropSettings {
    backdrop?: boolean | "static";
    keyboard?: boolean;
}
