import { t } from "@lingui/macro";

class ApiError extends Error {
    code: string;
    resourceIdentifier: string;
    fieldName: string;
    debugInfo: string;
    data?: any;
}

class FieldError extends Error {
    fieldName: string;

    constructor(fieldName: string, message?: string) {
        super(message);

        this.fieldName = fieldName;
    }
}

class ValidationError extends FieldError {
    constructor(fieldName: string, message: string) {
        super(fieldName, message);

        this.name = "ValidationError";
    }
}

class LocalizedTextValidationError extends ValidationError {
    constructor(
        fieldName: string,
        invalidLanguages: string[],
        messageFormatter?: (invalidLanguageString: string) => string,
    ) {
        const invalidLanguagesString: string = invalidLanguages.reduce(
            (prev, curr, i, array) => `${prev}${curr}${i + 1 < array.length ? ", " : ""}`,
            "",
        );

        console.log(invalidLanguagesString);

        const message = messageFormatter
            ? messageFormatter(invalidLanguagesString)
            : t({
                  id: "global.localizedTextForm.errors.missingRequiredLanguage",
                  message: `This field is missing the following required languages: ${invalidLanguagesString}`,
              });

        super(fieldName, message);
    }
}

export { ApiError, FieldError, ValidationError, LocalizedTextValidationError };
