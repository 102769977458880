import { ApiError } from "@atman/core";
import { BasePlatformRootStore } from "./BasePlatformRootStore";
import { ToastProvider } from "../libs";
import { action, computed, observable, reaction, runInAction, toJS } from "mobx";

export abstract class BaseStore {
    public readonly rootStore: BasePlatformRootStore;

    public constructor(rootStore: BasePlatformRootStore) {
        this.rootStore = rootStore;
    }

    // MARK: Observable Properties
    @observable public isLoading: boolean = false;
    @observable public apiErrors: ApiError[] = [];

    // MARK: Error Handling
    @action
    resetApiErrors = () => {
        if (this.hasErrored) {
            this.apiErrors = [];
        }
    };

    @computed
    get hasErrored() {
        return this.apiErrors.length > 0;
    }

    @computed
    get validationErrors() {
        return this.apiErrors.filter((x) => x.code === "ValidationError");
    }

    saveError = (...errors: ApiError[]) => {
        return runInAction(() => {
            this.apiErrors.push(...errors);
            this.isLoading = false;
        });
    };

    errorLogger = reaction(
        () => this.apiErrors.map((x) => x.code),
        (codes) => console.log("ApiErrors: ", toJS(this.apiErrors)),
    );

    @action.bound
    tryCatch = async <T>(
        action: () => T,
        errorCallback?: (error: ApiError | ApiError[]) => any,
        inModal: boolean = false,
    ): Promise<T | undefined> => {
        this.isLoading = true;
        this.resetApiErrors();

        try {
            const result = await action();

            runInAction(() => {
                this.isLoading = false;
            });

            return result;
        } catch (err) {
            if (err.errors && Array.isArray(err.errors) && err.errors.any()) {
                const errors = err.errors as ApiError[];

                errors.forEach((x) => {
                    this.saveError(x);
                });

                if (errorCallback) {
                    // TODO @cvincent - 2019-09-24: Review the errorCallback system to allow multiple errors
                    return errorCallback(errors.firstOrDefault());
                } else {
                    ToastProvider.error(errors.firstOrDefault()!.message);
                }
            } else if (err instanceof ApiError) {
                this.saveError(err);

                if (errorCallback) {
                    return errorCallback(err);
                } else {
                    ToastProvider.error(err.message);
                }
            } else {
                ToastProvider.error(err.message);
            }

            return;
        }
    };
}
