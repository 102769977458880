import * as React from "react";
import { FieldError } from "@atman/core";
import { action, observable } from "mobx";
import { computedFn } from "mobx-utils";

export interface IBaseComponentProps {}

export type SortDirection = "asc" | "desc";

export abstract class BaseComponent<TProps, TState = {}> extends React.Component<IBaseComponentProps & TProps, TState> {
    @observable protected errors: FieldError[] = [];

    constructor(props: IBaseComponentProps & TProps) {
        super(props);
    }

    protected getErrorsForField: (fieldName: string) => FieldError[] = computedFn((fieldName: string) => {
        return this.errors.filter((x) => x.fieldName === fieldName);
    });

    protected isNullOrUndefined: (object: any) => boolean = computedFn((object: any) => {
        return object === null || object === undefined;
    });

    protected isStringNullEmptyOrUndefined: (text?: string) => boolean = computedFn((text?: string) => {
        return text === "" || text === null || text === undefined;
    });

    @action.bound
    protected clearErrors() {
        this.errors = [];
    }

    @action.bound
    protected clearErrorForField(fieldName: string) {
        const index = this.errors.findIndex((x) => x.fieldName === fieldName);

        if (index >= 0) {
            this.errors.splice(index, 1);
        }
    }

    @action.bound
    protected onTextFieldChange<T = HTMLInputElement>(event: React.FormEvent<T>) {
        const eventTarget = (event as any).target;

        if (this.hasOwnProperty(eventTarget.id)) {
            this.clearErrorForField(eventTarget.id);

            this[eventTarget.id] = eventTarget.value;
        }
    }

    @action.bound
    protected onRadioChange<T extends HTMLInputElement>(event: React.FormEvent<T>) {
        if (this.hasOwnProperty(event.currentTarget.id)) {
            this.clearErrorForField(event.currentTarget.id);

            this[event.currentTarget.id] = !isNaN(Number(event.currentTarget.value))
                ? Number(event.currentTarget.value)
                : event.currentTarget.value;
        } else if (this.hasOwnProperty(event.currentTarget.name)) {
            this.clearErrorForField(event.currentTarget.name);

            this[event.currentTarget.name] = !isNaN(Number(event.currentTarget.value))
                ? Number(event.currentTarget.value)
                : event.currentTarget.value;
        }
    }

    @action.bound
    protected onCheckboxChange<T = HTMLInputElement>(event: React.FormEvent<T>) {
        const eventTarget = (event as any).target;

        if (this.hasOwnProperty(eventTarget.id)) {
            this.clearErrorForField(eventTarget.id);

            this[eventTarget.id] = !this[eventTarget.id];
        } else if (this.hasOwnProperty(eventTarget.name)) {
            this.clearErrorForField(eventTarget.name);

            this[eventTarget.name] = !this[eventTarget.name];
        }
    }

    @action.bound
    protected onCheckboxesChange<T = HTMLInputElement>(event: React.FormEvent<T>) {
        const eventTarget = (event as any).target;

        if (this.hasOwnProperty(eventTarget.name)) {
            const value = !isNaN(eventTarget.value) ? Number(eventTarget.value) : eventTarget.value;
            const index = this[eventTarget.name].indexOf(value);

            if (index >= 0) {
                this[eventTarget.name].splice(index, 1);
            } else {
                this[eventTarget.name].push(value);
            }
        }
    }

    @action.bound
    protected onAutoCompleteChange<T>(options: T, fieldName?: string, enumValue?: boolean) {
        if (!fieldName) {
            console.error("A fieldName prop wasn't provided, therefore the handler could not be triggered");
            return;
        }

        if (this.hasOwnProperty(fieldName)) {
            this.clearErrorForField(fieldName);

            this[fieldName] = enumValue ? Number(options) : options;
        }
    }

    @action.bound
    protected onEnterKey<T = Element>(event: React.KeyboardEvent, action: () => void) {
        if (event.key === "Enter") {
            return action();
        }
    }

    protected sortArrayByStringProp<T>(array: T[], keySelector: (item: T) => string, order: SortDirection = "asc") {
        return array.sort((a: T, b: T) => {
            const varA = keySelector(a).toUpperCase();
            const varB = keySelector(b).toUpperCase();

            const comparison = varA.localeCompare(varB);

            return order === "desc" ? comparison * -1 : comparison;
        });
    }

    protected sortArrayByNumberProp<T>(array: T[], keySelector: (item: T) => number, order: SortDirection = "asc") {
        return array.sort((a: T, b: T) => {
            const varA = keySelector(a);
            const varB = keySelector(b);

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }

            return order === "desc" ? comparison * -1 : comparison;
        });
    }

    protected simpleArraysSequenceEquals<T>(array1: T[], array2: T[]) {
        const a = array1.slice().sort();
        const b = array2.slice().sort();

        if (a === b) return true;

        if (this.isNullOrUndefined(a) || this.isNullOrUndefined(b)) return false;

        if (a.length !== b.length) return false;

        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) return false;
        }

        return true;
    }
}
