import "./index.less";
import { AtButtonTabs, IAtButtonTabsButtonLabel, IAtButtonTabsProps } from "../../molecules/AtButtonTabs";
import { AtContainer } from "../../atoms/AtContainer";
import { ICandidateFileWithId } from "@atman/business";
import { Sizing } from "../../../internal";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import cn from "classnames";

export interface IAtTabsContent extends IAtButtonTabsButtonLabel {
    content: React.ReactNode;
    key?: string;
}

export interface IAtTabsProps {
    className?: string;
    tabs: IAtTabsContent[];
    defaultTab?: string;
    wrapper?: boolean;
    atButtonTabsProps?: Pick<IAtButtonTabsProps, "fitContent">;
    tabChangeHook?: (currentTabId: string | number) => void;
    isInRapportTab?: boolean;
    isShareBtnEnable?: boolean;
    openModalToSendEmailInBulk?: (selectedId: string[], allReport: ICandidateFileWithId) => void;
    areTabsHiden?: boolean;
    selector?: ReactElement;
}

export const AtTabs: React.FC<IAtTabsProps> = (props: IAtTabsProps) => {
    const {
        tabs,
        className,
        defaultTab,
        wrapper,
        atButtonTabsProps,
        tabChangeHook,
        areTabsHiden,
        selector,

        ...otherProps
    } = props;

    const [activePanel, setActivePanel] = useState<string | undefined>(defaultTab ?? tabs[0].id);

    useEffect(() => {
        if (defaultTab) {
            setActivePanel(defaultTab);
        }
    }, [defaultTab]);

    const onTabClick = (selectedId: string) => {
        if (tabChangeHook) {
            tabChangeHook(selectedId);
        }

        if (selectedId !== undefined) {
            setActivePanel(selectedId);
        }
    };

    const divWrap = (content: React.ReactNode) => <div className="content contentTable">{content}</div>;
    const atContainerWrap = (content: React.ReactNode) => (
        <AtContainer padding={Sizing.paddings.md}>{content}</AtContainer>
    );

    const displayedContent = useMemo(
        () => tabs.find((panel) => panel.id === activePanel)?.content,
        [activePanel, tabs],
    );

    return (
        <div className={cn("AtTabs", className, { wrapper })} {...otherProps}>
            {!areTabsHiden && (
                <div className="buttonTabsWrapper">
                    <AtButtonTabs
                        buttons={tabs.map((tab) => ({
                            id: tab.id,
                            buttonContent: tab.buttonContent,
                            buttonProps: tab.buttonProps,
                        }))}
                        onClick={onTabClick}
                        activeButton={activePanel}
                        border={false}
                        {...atButtonTabsProps}
                    />

                    {selector}
                </div>
            )}

            {wrapper ? atContainerWrap(displayedContent) : divWrap(displayedContent)}
        </div>
    );
};
