import { BaseStore } from "../base/BaseStore";
import { CandidateStore } from "./CandidateStore";
import { CompetencyDevelopmentCampaignsApi } from "../apis/CompetencyDevelopmentCampaignsApi";
import { CultureStore } from "./CultureStore";
import { ICreateTeamParams } from "../types";
import { ITeamCompetencyDevelopmentSettings, ITeamInput, Team } from "../models";
import { TeamsApi } from "../apis";
import { ToastProvider } from "../libs";
import { action, autorun, computed, observable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";

export class TeamStore extends BaseStore {
    @observable public teams: Team[] = [];

    @action
    loadTeams = async () => {
        await this.tryCatch(async () => {
            const teams = await TeamsApi.getTeams();

            runInAction(() => {
                this.teams = teams;
                this.isLoading = false;
            });
        });
    };

    getTeamById: (id: string) => Team | undefined = computedFn((id: string) => {
        return this.teams.find((x) => x.id === id);
    });

    getTeamIndexById: (id: string) => number = computedFn((id: string) => {
        return this.teams.findIndex((x) => x.id === id);
    });

    @action
    validateTeamName = async (name: string) => {
        return await this.tryCatch(
            async () => {
                await TeamsApi.validateTeamName(name);

                return true;
            },
            () => false,
            true,
        );
    };

    @action
    createTeam = async (team: ICreateTeamParams) => {
        await this.tryCatch(
            async () => {
                const result = await TeamsApi.createTeam(team);

                runInAction(() => {
                    this.teams.push(result);
                });

                ToastProvider.success("clientApp.teamCreationSuccessMessage".localize());
            },
            undefined,
            true,
        );
    };

    @action
    updateTeam = async (id: string, input: ITeamInput) => {
        const index = this.getTeamIndexById(id);

        if (index >= 0) {
            await this.tryCatch(
                async () => {
                    const result = await TeamsApi.updateTeam(id, input);

                    runInAction(() => {
                        this.teams[index].name = result.name;
                        this.teams[index].purpose = result.purpose;
                        this.teams[index].restricted = result.restricted;
                        this.teams[index].ownerIds = result.ownerIds;
                        this.teams[index].leads = result.leads;
                        this.teams[index].updatedAt = result.updatedAt;
                    });

                    ToastProvider.success("clientApp.teamUpdateSuccessMessage".localize());
                },
                undefined,
                true,
            );
        }
    };

    @action
    updateTeamInformation = async (id: string, newName: string, newPurpose: string) => {
        const index = this.getTeamIndexById(id);

        if (index >= 0) {
            await this.tryCatch(
                async () => {
                    await TeamsApi.updateTeamInformation(id, newName, newPurpose);

                    runInAction(() => {
                        this.teams[index].name = newName;
                        this.teams[index].purpose = newPurpose;
                    });

                    ToastProvider.success("clientApp.teamInformationUpdateSuccessMessage".localize());
                },
                () => false,
            );
        }
    };

    @action
    updateTeamRestrictions = async (id: string, restricted: boolean, newOwnerIds: string[]) => {
        const index = this.getTeamIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await TeamsApi.updateTeamRestrictions(id, restricted, newOwnerIds);

                runInAction(() => {
                    this.teams[index].restricted = restricted;
                    this.teams[index].ownerIds = newOwnerIds;
                });

                ToastProvider.success("clientApp.teamRestrictionsUpdateSuccessMessage".localize());
            });
        }
    };

    @action
    changeTeamName = async (id: string, name: string) => {
        const index = this.getTeamIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await TeamsApi.changeTeamName(id, name);

                runInAction(() => {
                    this.teams[index].name = name;
                });

                ToastProvider.success("clientApp.teamUpdateSuccessMessage".localize());
            });
        }
    };

    @action
    changeTeamPurpose = async (id: string, purpose: string) => {
        const index = this.getTeamIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await TeamsApi.changeTeamPurpose(id, purpose);

                runInAction(() => {
                    this.teams[index].purpose = purpose;
                });

                ToastProvider.success("clientApp.teamUpdateSuccessMessage".localize());
            });
        }
    };

    @action
    changeTeamOwners = async (id: string, newOwners: string[]) => {
        const index = this.getTeamIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                const result = await TeamsApi.changeTeamOwners(id, newOwners);

                runInAction(() => {
                    this.teams[index].ownerIds = result.ownerIds;
                    this.teams[index].updatedAt = result.updatedAt;
                });

                ToastProvider.success("clientApp.teamUpdateSuccessMessage".localize());
            });
        }
    };

    @action
    changeTeamMembers = async (id: string, newMembers: string[], tags?: string[]) => {
        const index = this.getTeamIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                const result = await TeamsApi.changeTeamMembers(id, newMembers, tags);

                runInAction(() => {
                    this.teams[index].members = result.members;
                    this.teams[index].updatedAt = result.updatedAt;
                });

                ToastProvider.success("clientApp.teamUpdateSuccessMessage".localize());

                ((this.rootStore as any).candidateStore as CandidateStore).updateCandidatesTeam(
                    newMembers,
                    id,
                    this.teams[index].name,
                );
            });
        }
    };

    @action
    changeTeamLeads = async (id: string, newLeads: string[]) => {
        const index = this.getTeamIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                const result = await TeamsApi.changeTeamLeads(id, newLeads);

                runInAction(() => {
                    this.teams[index].leads = result.leads;
                    this.teams[index].updatedAt = result.updatedAt;
                });

                ToastProvider.success("clientApp.teamUpdateSuccessMessage".localize());
            });
        }
    };

    @action
    switchMemberToLead = async (id: string, candidateProId: string) => {
        const index = this.getTeamIndexById(id);

        if (index >= 0) {
            const memberIndex = this.teams[index].members?.findIndex((x) => x.candidateProId === candidateProId) ?? -1;

            if (memberIndex < 0) {
                ToastProvider.error("clientApp.candidateNotFoundInTeamMembers".localize());
                return;
            }

            await this.tryCatch(async () => {
                await TeamsApi.switchMemberToLead(id, candidateProId);

                runInAction(() => {
                    this.teams[index].leads?.push(this.teams[index].members![memberIndex]);
                    this.teams[index].members?.splice(memberIndex, 1);
                });

                ToastProvider.success("clientApp.teamMemberSwitchedToLeadSuccessfully".localize());
            });
        }
    };

    @action
    switchLeadToMember = async (id: string, candidateProId: string) => {
        const index = this.getTeamIndexById(id);

        if (index >= 0) {
            const leadIndex = this.teams[index].leads?.findIndex((x) => x.candidateProId === candidateProId) ?? -1;

            if (leadIndex < 0) {
                ToastProvider.error("clientApp.candidateNotFoundInTeamLeads".localize());
                return;
            }

            await this.tryCatch(async () => {
                await TeamsApi.switchLeadToMember(id, candidateProId);

                runInAction(() => {
                    this.teams[index].members?.push(this.teams[index].leads![leadIndex]);
                    this.teams[index].leads?.splice(leadIndex, 1);
                });

                ToastProvider.success("clientApp.teamLeadSwitchedToLeadSuccessfully".localize());
            });
        }
    };

    @action
    deleteTeam = async (id: string) => {
        const index = this.getTeamIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await TeamsApi.deleteTeam(id);

                runInAction(() => {
                    this.teams.splice(index, 1);
                });

                ToastProvider.success("clientApp.teamDeletionSuccessMessage".localize());
            });
        }
    };

    @action
    restrictTeam = async (id: string) => {
        const index = this.getTeamIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await TeamsApi.restrictTeam(id);

                runInAction(() => {
                    this.teams[index].restricted = true;
                });

                ToastProvider.success("teamRestrictionSuccessMessage".localize());
            });
        }
    };

    @action
    unrestrictTeam = async (id: string) => {
        const index = this.getTeamIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await TeamsApi.unrestrictTeam(id);

                runInAction(() => {
                    this.teams[index].restricted = false;
                });

                ToastProvider.success("teamUnrestrictionSuccessMessage".localize());
            });
        }
    };

    @action
    removeTeamMember = async (teamId: string, candidateProId: string) => {
        const index = this.getTeamIndexById(teamId);

        if (index >= 0) {
            await this.tryCatch(async () => {
                if (this.teams[index].members?.some((x) => x.candidateProId === candidateProId)) {
                    const newTeamMemberIds = this.teams[index].members
                        ?.filter((x) => x.candidateProId !== candidateProId)
                        .map((x) => x.candidateProId);

                    if (!newTeamMemberIds) {
                        return;
                    }

                    await this.changeTeamMembers(teamId, newTeamMemberIds);
                } else if (this.teams[index].leads?.some((x) => x.candidateProId === candidateProId)) {
                    const newTeamLeadIds = this.teams[index].leads
                        ?.filter((x) => x.candidateProId !== candidateProId)
                        .map((x) => x.candidateProId);

                    if (!newTeamLeadIds) {
                        return;
                    }

                    await this.changeTeamLeads(teamId, newTeamLeadIds);
                }
            });
        }
    };

    @action
    assignTeamCulture = async (teamId: string, cultureId: string) => {
        const index = this.getTeamIndexById(teamId);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await TeamsApi.assignCulture(teamId, cultureId);

                runInAction(() => {
                    // MARK: Culture TeamsCount Update
                    const cultureStore = (this.rootStore as any)?.cultureStore as CultureStore;
                    const cultureIndex = cultureStore?.findItemIndexById(cultureId);
                    const cultureName = cultureStore?.items?.[cultureIndex]?.name ?? "unknownCultureName".localize();

                    if (cultureIndex >= 0) {
                        cultureStore.items[cultureIndex].teamsCount++;
                    }

                    // MARK: Team CultureId/CultureName Update
                    this.teams[index].cultureId = cultureId;
                    this.teams[index].cultureName = cultureName;

                    ToastProvider.success("clientApp.cultureAssignedToTeamSuccessfully".localize());
                });
            });
        }
    };

    @action
    unassignTeamCulture = async (teamId: string, cultureId: string) => {
        const index = this.getTeamIndexById(teamId);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await TeamsApi.unassignCulture(teamId, cultureId);

                runInAction(() => {
                    // MARK: Culture TeamsCount Update
                    const cultureStore = (this.rootStore as any)?.cultureStore as CultureStore;
                    const cultureIndex = cultureStore?.findItemIndexById(cultureId);

                    if (cultureIndex >= 0) {
                        cultureStore.items[cultureIndex].teamsCount--;
                    }

                    // MARK: Team CultureId/CultureName Update
                    this.teams[index].cultureId = undefined;
                    this.teams[index].cultureName = undefined;

                    ToastProvider.success("clientApp.cultureUnassignedFromTeamSuccessfully".localize());
                });
            });
        }
    };

    @action
    assignTeamManager = async (teamId: string, managerUserId: string) => {
        const index = this.getTeamIndexById(teamId);

        if (index >= 0) {
            await this.tryCatch(async () => {
                const { managerName } = await TeamsApi.assignManager(teamId, managerUserId);

                runInAction(() => {
                    this.teams[index].managerId = managerUserId;
                    this.teams[index].managerName = managerName;
                });
            });
        }
    };

    @action
    updateTeamCultures = (updatedTeamIds: string[], cultureId: string, cultureName: string) => {
        for (const team of this.teams.filter((x) => x.cultureId === cultureId && !updatedTeamIds.includes(x.id))) {
            team.cultureId = undefined;
            team.cultureName = undefined;
        }

        for (const id of updatedTeamIds) {
            const itemIndex = this.getTeamIndexById(id);

            if (itemIndex >= 0) {
                this.teams[itemIndex].cultureId = cultureId;
                this.teams[itemIndex].cultureName = cultureName;
            }
        }
    };

    @action
    startCompetencyDevelopmentCampaign = async (teamId: string) => {
        const index = this.getTeamIndexById(teamId);

        if (index >= 0) {
            await this.tryCatch(async () => {
                const { campaignId } = await CompetencyDevelopmentCampaignsApi.startCampaign(teamId);

                runInAction(() => {
                    this.teams[index].activeCampaignId = campaignId;
                });
            });
        }
    };

    @action
    updateTeamCompetencyDevelopmentSettings = async (
        teamId: string,
        teamCompetencyDevelopmentSettings: ITeamCompetencyDevelopmentSettings,
    ): Promise<void> => {
        const team = this.getTeamById(teamId);

        if (team) {
            await this.tryCatch(async () => {
                await TeamsApi.updateTeamCompetencyDevelopmentSettings(teamId, teamCompetencyDevelopmentSettings);

                ToastProvider.success("clientApp.teamCompetencyDevelopmentSettingsUpdatedSuccessfully".localize());

                runInAction(() => {
                    team.optionalManagerAssessment = teamCompetencyDevelopmentSettings.isManagerAssessmentOptional;
                    team.optionalSelfAssessment = teamCompetencyDevelopmentSettings.isSelfAssessmentOptional;
                    team.useClientCultureInCompetencyDevelopment = teamCompetencyDevelopmentSettings.useClientCulture;
                    team.competencyDevelopmentFlowStyle = teamCompetencyDevelopmentSettings.flowStyle;
                    team.thirdPartyEvaluatorName = teamCompetencyDevelopmentSettings.thirdPartyEvaluatorName;
                    team.thirdPartyEvaluatorEmail = teamCompetencyDevelopmentSettings.thirdPartyEvaluatorEmail;
                });
            });
        }
    };

    @action
    updateTeamManager = (teamId: string, userId: string, userName: string) => {
        const team = this.getTeamById(teamId);

        if (team) {
            team.managerId = userId;
            team.managerName = userName;
        }
    };

    @computed
    get teamsCount() {
        return this.teams.length;
    }

    @computed
    get validationErrors() {
        return this.apiErrors.filter((x) => x.code === "ValidationError");
    }

    @computed
    get hasErrored() {
        return this.apiErrors.length > 0;
    }

    autorunner = autorun(() => {});
}
