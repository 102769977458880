import "./index.less";
import { Colors, DefaultComponentProps, Sizing } from "../../../internal";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import cn from "classnames";

export type InnerIconProps = Partial<Omit<FontAwesomeIconProps, "icon" | "size" | "color">>;

export interface IAtIconProps extends DefaultComponentProps {
    icon: IconProp;
    size?: SizeProp;
    color?: string;
    bgColor?: string;
    borderRadius?: number;
    bordered?: boolean;
    borderColor?: string;
    extraPadding?: boolean;
    onClick?: () => void;
    noFixedSizing?: boolean;
    upSize?: boolean;
    innerIconProps?: InnerIconProps;
    spin?: boolean;
    title?: string;
}

export const AtIcon: React.FC<IAtIconProps> = (props: IAtIconProps) => {
    const {
        icon,
        size = "1x",
        color = Colors.greyBase,
        bgColor,
        borderRadius = Sizing.pixels.xxs,
        bordered = false,
        borderColor = Colors.greyShades.shade9,
        extraPadding = false,
        noFixedSizing = false,
        upSize = false,
        className,
        onClick,
        innerIconProps,
        spin,
        title,
        ...otherProps
    } = props;

    const style: React.CSSProperties = {
        backgroundColor: bgColor,
        borderRadius: borderRadius,
        border: bordered ? `1px solid ${borderColor}` : undefined,
    };

    return (
        <div
            className={cn("AtIcon", className, `icon-${size}`, {
                "extra-padding": extraPadding,
                "no-fixed-sizing": noFixedSizing,
                upSize: upSize,
            })}
            style={style}
            onClick={onClick}
            title={title}
            {...otherProps}
        >
            <FontAwesomeIcon icon={icon} size={size} color={color} {...innerIconProps} spin={spin} />
        </div>
    );
};
