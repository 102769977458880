import "./index.less";
import * as React from "react";
import { AtContainer, AtTitle } from "@atman/design-system";
import { AtIconLabel } from "@atman/design-system/lib/components/molecules/AtIconLabel";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";

export interface ICompDevTabHeaderProps {
    title: string;
    subTitle?: string;
    countCompetencies?: number;
    countObjectives?: number;
    countApplications?: number;
}

export const CompDevTabHeader: React.FC<ICompDevTabHeaderProps> = observer((props) => {
    const { title, subTitle, countCompetencies, countObjectives, countApplications } = props;

    return (
        <AtContainer className="CompDevTabHeader">
            <div className="left-section">
                <AtTitle headingType={3} title={title} />
                {subTitle && <div className="sub-title">{subTitle}</div>}
            </div>
            {
                <div className="ActionPlaneSubHeader">
                    {countCompetencies !== undefined && (
                        <div className="countItem">
                            <AtIconLabel
                                icon={{
                                    iconName: "star",
                                    prefix: "fas",
                                }}
                                label={t({
                                    id: "global.competenciesCount",
                                    values: { count: countCompetencies },
                                })}
                            />
                        </div>
                    )}

                    {countObjectives !== undefined && (
                        <div className="countItem">
                            <AtIconLabel
                                icon={{
                                    prefix: "fas",
                                    iconName: "bullseye",
                                }}
                                label={t({
                                    id: "global.objectivesCount",
                                    values: { count: countObjectives },
                                })}
                            />
                        </div>
                    )}
                    {countApplications !== undefined && (
                        <div className="countItem">
                            <AtIconLabel
                                icon={{
                                    prefix: "fas",
                                    iconName: "bolt",
                                }}
                                label={t({
                                    id: "global.applicationsCount",
                                    values: { count: countApplications },
                                })}
                            />
                        </div>
                    )}
                </div>
            }
        </AtContainer>
    );
});
