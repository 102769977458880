import "./ActionPlanTablesRowContentApplication.less";
import * as React from "react";
import { AtButton, AtContainer, AtSubtitle, AtTitle } from "@atman/design-system";
import {
    CompetencyDevelopmentLeveragingObjectiveType,
    ICompetencyDevelopmentProcessAssessmentData,
    PrintModeEnum,
} from "@atman/business";
import { IApplicationLeveragePlanAction } from "@atman/business/lib/models/IApplicationLeveragePlanAction";
import { LearningResourceInline } from "../../LearningResourceInline";
import { t } from "@lingui/macro";
import AtNotes from "../../../AtNotes";
import AtPrintNotes from "../../../AtPrintNotes";

export interface IActionPlanTablesRowContentApplicationProps {
    application: IApplicationLeveragePlanAction;
    processAssessmentData?: ICompetencyDevelopmentProcessAssessmentData;
    openAndMonitorTabFunc?: (url: string) => void;
    printMode?: PrintModeEnum;
}

export const ActionPlanTablesRowContentApplication: React.FC<IActionPlanTablesRowContentApplicationProps> = (props) => {
    const { application, processAssessmentData, openAndMonitorTabFunc, printMode } = props;

    const onClickEditButton = () => {
        if (openAndMonitorTabFunc && processAssessmentData) {
            openAndMonitorTabFunc(processAssessmentData.url);
        }
    };

    const applicationTypeName =
        application?.strategicObjective.type !== undefined && application?.strategicObjective.type !== null
            ? t({
                  id: `competencyDevelopment.objectiveType.${CompetencyDevelopmentLeveragingObjectiveType[
                      application.strategicObjective.type
                  ].toCamel()}`,
              })
            : t({ id: "competencyDevelopment.global.objective" });

    return (
        <div className="ActionPlanTablesRowContentApplication">
            <div className="actionPlanTablesRowContentApplicationSectionMain">
                {(printMode === undefined && (
                    <div>
                        <AtTitle title={t({ id: "competencyDevelopment.global.objective" })} />
                        <AtTitle title={application.strategicObjective.description} headingType={3} />
                    </div>
                )) || (
                    <div>
                        <AtTitle title={t({ id: "competencyDevelopment.actionPlan.improvementSection.action" })} />
                        <AtTitle title={application.description} headingType={3} />
                    </div>
                )}

                {printMode === undefined && (
                    <AtButton color="secondary" onClick={onClickEditButton}>
                        {t({ id: "global.editCardInformation" })}
                    </AtButton>
                )}
            </div>

            {printMode !== undefined && (
                <div className="print-only-description-action-plan">
                    <div className="competency-wrapper">
                        <AtSubtitle
                            size="sm"
                            upWeight
                            subtitle={t({ id: "competencyDevelopment.global.competency" })}
                        />

                        <AtTitle headingType={4} title={application?.competency.name} />
                    </div>
                    <div className="type-wrapper">
                        <AtSubtitle size="sm" upWeight subtitle={t({ id: "global.type" })} />

                        <AtTitle headingType={4} title={applicationTypeName} />
                    </div>
                </div>
            )}

            {(application?.strategicObjective?.learningResources ?? []).length > 0 && (
                <div className="actionPlanTablesRowContentApplicationSectionLearnings">
                    <AtTitle title={t({ id: "competencyDevelopment.actionPlan.resourcesToHelpYou" })} />
                    <AtContainer className="actionPlanLearningResources">
                        {application.strategicObjective.learningResources?.map((learningResource) => (
                            <LearningResourceInline
                                className="actionPlanLearningResourceItem"
                                key={`learningResource-improve-id-${learningResource.id}`}
                                learningResource={learningResource}
                            />
                        ))}
                    </AtContainer>
                    {!!`${application?.strategicObjective?.learningResourceNotes}`.trim() &&
                        application?.strategicObjective?.learningResourceNotes && (
                            <div className="application-learning-ressources-notes-display">
                                <AtTitle
                                    headingType={6}
                                    title={t({ id: "competencyDevelopment.select.learningResources.notesTitle" })}
                                />
                                {(printMode === undefined && (
                                    <AtNotes notes={application?.strategicObjective?.learningResourceNotes} />
                                )) || (
                                    <AtPrintNotes
                                        prefixKey="leverage-learningResource"
                                        notes={`${application?.strategicObjective?.applicationNotes}`}
                                    />
                                )}
                            </div>
                        )}
                </div>
            )}

            {!!`${application.strategicObjective.applicationNotes}`.trim() &&
                application.strategicObjective.applicationNotes && (
                    <div className="actionPlanTablesRowContentApplicationSectionNotes">
                        <AtTitle
                            title={t({
                                id: "competencyDevelopment.applicationNotes.notesAndComments",
                            })}
                        />
                        {(printMode === undefined && (
                            <AtNotes notes={application.strategicObjective.applicationNotes} />
                        )) || (
                            <AtPrintNotes
                                prefixKey="leverage-application"
                                notes={application.strategicObjective.applicationNotes}
                            />
                        )}
                    </div>
                )}
        </div>
    );
};
