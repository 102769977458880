import "./AccountDropdown.less";
import * as React from "react";
import { AtPersonAvatar, IAtPersonAvatarProps } from "@atman/design-system";
import { BaseResponsiveComponent } from "../../BaseResponsiveComponent";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { GlobalStores, RouteProvider, UrlFormatter, UserInfoStore } from "@atman/business";
import { RouteComponentProps, withRouter } from "react-router";
import { action, computed, observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";

export interface IAccountDropdownProps extends RouteComponentProps {
    showRole?: boolean;
    userInfoStore?: UserInfoStore;
}

@inject(GlobalStores.userInfoStore)
@observer
class AccountDropdownImpl extends BaseResponsiveComponent<IAccountDropdownProps> {
    static defaultProps = {
        showRole: true,
    };

    protected get rootElementId(): string | undefined {
        return "AccountDropdown";
    }

    private dropdownTimeout: number;
    @observable public dropdownIsOpen: boolean = false;

    @computed get userInfo(): IAtPersonAvatarProps {
        const { userInfoStore } = this.props;

        return {
            firstName: userInfoStore!.firstName,
            lastName: userInfoStore!.lastName,
            email: userInfoStore!.email,
        };
    }

    @action toggleDropdown = () => {};

    @action openDropdown = () => {
        window.clearTimeout(this.dropdownTimeout);

        this.dropdownIsOpen = true;
    };

    @action closeDropdown = () => {
        this.dropdownTimeout = window.setTimeout(() => {
            runInAction(() => {
                this.dropdownIsOpen = false;
            });
        }, 500);
    };

    openAccountPage = (e: React.SyntheticEvent) => {
        const { history } = this.props;

        e.preventDefault();

        history.push(UrlFormatter.formatReactPath(RouteProvider.defaultRouteEntries.account));
    };

    renderAll() {
        const { userInfoStore, showRole } = this.props;

        return (
            <Dropdown
                isOpen={this.dropdownIsOpen}
                toggle={this.toggleDropdown}
                direction={"down"}
                className={"account-dropdown"}
                onMouseEnter={this.openDropdown}
                onMouseLeave={this.closeDropdown}
            >
                <DropdownToggle
                    className="account-dropdown-toggle"
                    tag={"span"}
                    data-toggle={"dropdown"}
                    aria-expanded={this.dropdownIsOpen}
                >
                    <AtPersonAvatar
                        firstName={this.userInfo.firstName}
                        lastName={this.userInfo.lastName}
                        email={this.userInfo.email}
                        size="lg"
                    />
                </DropdownToggle>
                <DropdownMenu
                    modifiers={{
                        offset: {
                            enabled: true,
                            offset: `-250px, 12px`,
                        },
                    }}
                    style={{ width: 320 }}
                >
                    <div className="header">
                        <AtPersonAvatar
                            firstName={this.userInfo.firstName}
                            lastName={this.userInfo.lastName}
                            email={this.userInfo.email}
                            size="lg"
                        />
                        <div className="info-container">
                            <div className="display-name">{`${this.userInfo.firstName} ${this.userInfo.lastName}`}</div>
                            {showRole && (
                                <div className="role">
                                    {`global.role.${userInfoStore!.highestRole.toCamel()}`.localize()}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="links-container">
                        <DropdownItem onClick={this.openAccountPage}>{"global.yourAccount".localize()}</DropdownItem>
                        {/*  TODO: Add Terms & Policies  */}
                        <DropdownItem onClick={userInfoStore!.logout}>{"global.signOut".localize()}</DropdownItem>
                    </div>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export const AccountDropdown = withRouter(AccountDropdownImpl);
