import {
    CompetencyDevelopmentAssessmentStatus,
    CompetencyDevelopmentAssessmentType,
    CompetencyDevelopmentLeveragingObjectiveType,
} from "./CompetencyDevelopment/CompDevEnums";
import { IMapEntries, observable } from "mobx";

export interface IAssessmentStructure {
    readonly title: string;
    readonly description: string;
    readonly instructions: string;
    readonly status: CompetencyDevelopmentAssessmentStatus;
    readonly stepInfos: IAssessmentStepInfo[];
}

export interface IAssessmentStepInfo {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly rank: number;
    readonly tags: string[];
    readonly scales: string[];
    readonly score?: number;
    readonly objectives?: IAssessmentStepInfoObjective[]; // TODO: Find a more generic term
    readonly statements?: IAssessmentStatementInfo[];
    readonly statementGroups?: IAssessmentStatementGroupInfo[];
    readonly customHeaderPrompt?: string;
}

export interface IAssessmentStepInfoObjective {
    readonly id: string;
    readonly name: string;
    readonly rank: number;
    readonly type: CompetencyDevelopmentLeveragingObjectiveType;
}

export interface IAssessmentStatementGroupInfo {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly objective: string; // TODO: Find a more generic term
    readonly rank: number;
    readonly statements: IAssessmentStatementInfo[];
}

export interface IAssessmentStatementInfo {
    readonly id: string;
    readonly label: string;
    readonly rank: number;
    readonly value?: number;
    readonly values?: IMapEntries<CompetencyDevelopmentAssessmentType, number | null>;
}

export class AssessmentStructure {
    @observable public readonly title: string;
    @observable public readonly description: string;
    @observable public readonly instructions: string;
    @observable public status: CompetencyDevelopmentAssessmentStatus;
    @observable public readonly stepInfos: AssessmentStepInfo[];

    constructor(json: IAssessmentStructure) {
        this.title = json.title;
        this.description = json.description;
        this.instructions = json.instructions;
        this.status = json.status;
        this.stepInfos = json.stepInfos.map((x) => new AssessmentStepInfo(x));
    }
}

export class AssessmentStepInfo {
    @observable public readonly id: string;
    @observable public readonly name: string;
    @observable public readonly description: string;
    @observable public readonly rank: number;
    @observable public readonly tags: string[];
    @observable public readonly scales: string[];
    @observable public readonly score?: number;
    @observable public readonly statements?: AssessmentStatementInfo[];
    @observable public readonly statementGroups?: AssessmentStatementGroupInfo[];
    @observable public readonly customHeaderPrompt?: string;

    constructor(json: IAssessmentStepInfo) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.rank = json.rank;
        this.tags = json.tags;
        this.scales = json.scales;
        this.score = json.score;
        this.customHeaderPrompt = json.customHeaderPrompt;

        if (!json.statements && !json.statementGroups) {
            console.error(
                `Could not find any statement groups or statements for AssessmentStepInfo with ID: ${this.id}`,
            );
        }

        this.statements = json.statements?.map((x) => new AssessmentStatementInfo(x, this.rank.toString()));
        this.statementGroups = json.statementGroups?.map((x) => new AssessmentStatementGroupInfo(x, this.rank));
    }
}

export class AssessmentStatementGroupInfo {
    @observable public readonly id: string;
    @observable public readonly name: string;
    @observable public readonly description: string;
    @observable public readonly rank: number;
    @observable public readonly hierarchicalRank: string;
    @observable public readonly statements: AssessmentStatementInfo[];

    constructor(json: IAssessmentStatementGroupInfo, parentRank: number) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.rank = json.rank;
        this.hierarchicalRank = `${parentRank}.${this.rank}`;
        this.statements = json.statements.map((x) => new AssessmentStatementInfo(x, this.hierarchicalRank));
    }
}

export class AssessmentStatementInfo {
    @observable public readonly id: string;
    @observable public readonly label: string;
    @observable public readonly rank: number;
    @observable public readonly hierarchicalRank: string;
    @observable public readonly value?: number;

    constructor(json: IAssessmentStatementInfo, parentRank: string) {
        this.id = json.id;
        this.label = json.label;
        this.rank = json.rank;
        this.hierarchicalRank = `${parentRank}.${this.rank}`;
        this.value = json.value;
    }
}
