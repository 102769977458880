import "./index.less";
import { AtButton, IAtButtonProps } from "../AtButton";
import { useUniqueId } from "@atman/core";
import React from "react";

export interface IAtButtonListProps {
    buttons: IAtButtonProps[];
    menuButtons?: React.ReactNode;
}

export const AtButtonList: React.FC<IAtButtonListProps> = (props) => {
    const { buttons, menuButtons } = props;
    const { uniqueId } = useUniqueId();

    return (
        <div className="AtButtonList">
            {buttons.map((button) => (
                <AtButton {...button} key={`at-list-btn-${uniqueId}`} />
            ))}
            {menuButtons ?? null}
        </div>
    );
};
