import * as React from "react";
import { CSSProperties } from "react";

export interface IBaseColumn<TRow, TValue> {
    dataField: string;
    text: string;
    sort: boolean;
    align: string;
    headerAlign: string;
    style?: unknown;
    headerStyle?: unknown;
    formatExtraData?: unknown;
    formatter?: (cell: TValue, row: TRow, rowIndex: number) => React.ReactNode;
}

export abstract class BaseColumn<TRow = any, TValue = any> implements IBaseColumn<TRow, TValue> {
    protected constructor(formatExtraData?: unknown) {
        this.formatExtraData = formatExtraData;
    }

    public dataField: string = "";
    public text: string = "";
    public sort: boolean = true;
    public align: "left" | "center" | "right" = "left";
    public headerAlign: "left" | "center" | "right" = "left";
    public style?: CSSProperties;
    public headerStyle?: CSSProperties;
    public formatExtraData?: unknown;
    public formatter?: (cell: TValue, row: TRow, rowIndex: number) => React.ReactNode;
    public sortValue?: (cell: TValue, row: TRow) => string | number;
    public classes?: string | ((cell: TValue, row: TRow, rowIndex: number, colIndex: number) => string);

    protected setAsButtonColumn() {
        this.text = "";
        this.sort = false;
        this.align = "right";
        this.headerAlign = "right";
        this.style = {
            justifyContent: "flex-end",
        };
    }
}
