import "./StrategicObjectiveModalContent.less";
import { Alert } from "reactstrap";
import {
    AtButton,
    AtCheckableCard,
    AtIcon,
    AtModal,
    AtParagraph,
    AtText,
    AtTitle,
    AtTitleSubtitle,
    AtTooltip,
} from "@atman/design-system";
import { AverageScoreDisplay, CustomTextInput } from "../../components";
import { CompDevCompetencyResult, CompDevSubCompetencyResult, ISelectedObjectiveToImproveData } from "@atman/business";
import { CompDevSubCompetenciesStrategicObjectiveResult } from "@atman/business/lib/models/CompetencyDevelopment/ResultModels/CompDevSubCompetenciesStrategicObjectiveResult";
import { Trans, t } from "@lingui/macro";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";

export interface IStrategicObjectiveInfoFormProps {
    openModal: boolean;
    setOpenModal: (value: string | null) => void;
    strategicObjective: CompDevSubCompetenciesStrategicObjectiveResult;
    selectedObjectiveData: ISelectedObjectiveToImproveData;
    competency: CompDevCompetencyResult;
    subCompetency: CompDevSubCompetencyResult;
    saveStrategicObjectiveApplications: (
        strategicObjectiveId: string,
        applicationIds: string[],
        applicationNote: string,
    ) => void;
}

export const StrategicObjectiveModalContent: FunctionComponent<IStrategicObjectiveInfoFormProps> = observer(
    (props: IStrategicObjectiveInfoFormProps) => {
        const {
            competency,
            subCompetency,
            openModal,
            setOpenModal,
            strategicObjective,
            selectedObjectiveData,
            saveStrategicObjectiveApplications,
        } = props;
        const { description, explanation, applications, reflection } = strategicObjective;

        const [temporarySelectedApplicationIds, setTemporarySelectedApplicationIds] = useState<string[]>([]);
        const [temporaryApplicationNote, setTemporaryApplicationNote] = useState<string>("");
        const [showStrategicObjectiveNote, setShowStrategicObjectiveNote] = useState(false);

        const onApplicationChange = (applicationId: string) => {
            if (temporarySelectedApplicationIds.includes(applicationId)) {
                setTemporarySelectedApplicationIds(
                    temporarySelectedApplicationIds.filter((id) => id !== applicationId),
                );
            } else {
                setTemporarySelectedApplicationIds([...temporarySelectedApplicationIds, applicationId]);
            }
        };

        const resetState = () => {
            setTemporarySelectedApplicationIds(selectedObjectiveData.selectedApplicationIds);
            setTemporaryApplicationNote(selectedObjectiveData.applicationNotes);

            if (selectedObjectiveData.applicationNotes) {
                setShowStrategicObjectiveNote(true);
            } else {
                setShowStrategicObjectiveNote(false);
            }
        };

        useEffect(() => {
            resetState();
        }, [selectedObjectiveData, strategicObjective]);

        const closeModal = () => {
            setOpenModal(null);
            resetState();
        };

        return (
            <AtModal
                className="strategicObjectiveInfoForm"
                open={openModal}
                setOpen={(isOpen) => setOpenModal(isOpen ? null : strategicObjective.id)}
                title={description}
                actions={[
                    {
                        label: t({ id: "global.cancel" }),
                        clickHandler: closeModal,
                        color: "secondary",
                    },
                    {
                        label: t({ id: "global.continue" }),
                        color: "primary",
                        clickHandler: () => {
                            saveStrategicObjectiveApplications(
                                strategicObjective.id,
                                temporarySelectedApplicationIds,
                                temporaryApplicationNote,
                            );
                        },
                        prefixIcon: ["fas", "check-circle"],
                        className: "mainAction",
                    },
                ]}
            >
                <div className="strategicObjectiveInfoFormContent">
                    <div className="competencyContainer">
                        <section className="competencySection">
                            <AtText weight="medium" size="sm">
                                <Trans id="competencyDevelopment.global.competency">Competency</Trans>
                            </AtText>
                            <div className="scoreSection">
                                <AverageScoreDisplay
                                    reviewScore={competency.subCompetenciesReviewAverage}
                                    averageScore={competency.subCompetenciesAverage}
                                />
                                <AtText className="text" id="modal-competencyTitle">
                                    {competency.name}
                                </AtText>
                                <AtTooltip target="modal-competencyTitle">{competency.description}</AtTooltip>
                            </div>
                        </section>
                        <section className="competencySection">
                            <AtText weight="medium" size="sm">
                                <Trans id="competencyDevelopment.global.subcompetency">SubCompetency</Trans>
                            </AtText>
                            <div className="scoreSection">
                                <AverageScoreDisplay
                                    reviewScore={subCompetency.observableBehaviorsReviewAverage}
                                    averageScore={subCompetency.observableBehaviorsAverage}
                                    averageScoreDisplayMode="light"
                                />
                                <AtText className="text" id="modal-subcompetencyTitle">
                                    {subCompetency.name}
                                </AtText>
                                <AtTooltip target="modal-subcompetencyTitle">{competency.description}</AtTooltip>
                            </div>
                        </section>
                    </div>
                    {(explanation || reflection) && (
                        <div className="explanation">
                            {explanation && (
                                <AtTitleSubtitle
                                    title={t({ id: "competencyDevelopment.global.explanation" })}
                                    subtitle={explanation}
                                />
                            )}
                            {reflection && (
                                <div>
                                    <AtTitle
                                        title={t({ id: "competencyDevelopment.global.reflection" })}
                                        headingType={4}
                                    />
                                    <Alert color="success">{reflection}</Alert>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="applicationsSelection">
                        <section className="applicationsHeader">
                            <AtIcon icon={["fas", "bolt"]} />
                            <AtText weight="semibold">
                                <Trans id={"competencyDevelopment.global.addActionsPlan"}>
                                    Ajouter les actions à votre plan
                                </Trans>
                            </AtText>
                        </section>
                        <section className="applications">
                            {applications.map((application, index: number) => {
                                const topContent: JSX.Element = (
                                    <div onClick={() => onApplicationChange(application.id)}>
                                        {application.description?.split("\n").map((d) => (
                                            <AtParagraph key={`paragraph-${d}`}>{d}</AtParagraph>
                                        ))}
                                    </div>
                                );

                                return (
                                    <AtCheckableCard
                                        className={
                                            !index
                                                ? "rounded-top"
                                                : index + 1 === applications.length
                                                ? "rounded-bottom"
                                                : ""
                                        }
                                        onCheckboxClick={() => onApplicationChange(application.id)}
                                        key={application.id}
                                        id={application.id}
                                        checked={temporarySelectedApplicationIds.includes(application.id)}
                                        topContent={topContent}
                                        borderRadius={0}
                                    />
                                );
                            })}
                        </section>
                    </div>
                    <div className="notes-input-container">
                        {showStrategicObjectiveNote ? (
                            <CustomTextInput
                                className="note-text-input"
                                fieldName={"applicationNotes"}
                                label={t({
                                    id: "competencyDevelopment.applicationNotes.notesAndComments",
                                    message: "Notes and comments",
                                })}
                                placeholder={t({
                                    id: "competencyDevelopment.applicationNotes.placeholder",
                                    message: "Add a note and customize an objective if needed.",
                                })}
                                value={temporaryApplicationNote}
                                onChange={(e) => setTemporaryApplicationNote(e.currentTarget.value)}
                                type={"textarea"}
                                rows={3}
                                hideTextAreaCorner
                            />
                        ) : (
                            <AtButton
                                color={"secondary"}
                                size={"md"}
                                icon={["far", "comment-dots"]}
                                onClick={() => setShowStrategicObjectiveNote(true)}
                            >
                                <Trans id={"competencyDevelopment.applicationNotes.addNotes"}>Add notes</Trans>
                            </AtButton>
                        )}
                    </div>
                </div>
            </AtModal>
        );
    },
);
