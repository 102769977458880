import { IPenaltyResult } from "../types";

export enum ScoreType { // Matches server side ScoreType
    Percentage,
    Stanine,
}

export enum ScoreDisplayType {
    Stanine,
    StanineHalfCircle,
    Percentage,
}

export enum TileType {
    Competencies,
    Job,
    Potential,
    CompatibilityFit,
    Culture,
}

export interface IAssessmentResultScore {
    score: number;
    penaltiesApplied?: IPenaltyResult[];
    scoreType: ScoreType;
}

export interface ITileScoreData {
    assessmentId: string;
    scores: Map<TileType, number>;
}

export interface ITileScoreDataForTeams extends ITileScoreData {
    isLead?: boolean;
}

export function ScoreDisplayTypeFromScoreType(type?: ScoreType): ScoreDisplayType | undefined {
    return type !== undefined
        ? type === ScoreType.Percentage
            ? ScoreDisplayType.Percentage
            : ScoreDisplayType.StanineHalfCircle
        : undefined;
}
