import "./index.less";
import { IAtTableFilter } from "../../../types";
import { IBaseEntity } from "@atman/core";
import { RadioInputGroup } from "../../../../../../internal";
import { t } from "@lingui/macro";
import React, { ReactElement, useEffect, useState } from "react";

interface IAtTableFilterBooleanProps<T extends IBaseEntity> extends IAtTableFilter<T> {
    subtitle?: string;
    appliedFilterValue: boolean | null | string;
    setFilterValue: (columnKey: string, value: string | boolean | null) => void;
}
export function AtTableBooleanFilter<T extends IBaseEntity>(props: IAtTableFilterBooleanProps<T>): ReactElement {
    const { appliedFilterValue, setFilterValue, column, ...otherProps } = props;

    const trueLabel = column.filter?.trueLabel ?? "true";
    const falseLabel = column.filter?.falseLabel ?? "false";

    const hasOrIs = column.filter?.isUser != undefined ? t({ id: "global.has" }) : t({ id: "global.is" });

    const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);

    useEffect(() => {
        switch (appliedFilterValue) {
            case true:
                setSelectedOption("true");
                break;

            case false:
                setSelectedOption("false");
                break;

            default:
                setSelectedOption(undefined);
        }
    }, [appliedFilterValue]);

    const handleRadioChange = <T extends HTMLInputElement>(event: React.FormEvent<T>) => {
        switch (event.currentTarget.value) {
            case "true":
                setSelectedOption("true");
                setFilterValue(column.key, true);
                break;

            case "false":
                setSelectedOption("false");
                setFilterValue(column.key, false);
                break;

            default:
                break;
        }
    };

    return (
        <div className="AtTableBooleanFilter">
            <RadioInputGroup
                {...otherProps}
                style={{ textAlign: "left" }}
                radioInputProps={[
                    {
                        fieldName: `${column.key}_selectedOption`,
                        id: `${column.key}_selectedOption_true`,
                        value: "true",
                        checked: selectedOption === "true",
                        descriptionSize: "md",
                        label: `${hasOrIs} ${trueLabel.toLocaleLowerCase()}`,
                        labelSize: "md",
                        onChange: handleRadioChange,
                    },
                    {
                        fieldName: `${column.key}_selectedOption`,
                        id: `${column.key}_selectedOption_false`,
                        value: "false",
                        checked: selectedOption === "false",
                        label: `${hasOrIs} ${falseLabel.toLocaleLowerCase()}`,
                        labelSize: "md",
                        onChange: handleRadioChange,
                    },
                ]}
            />
        </div>
    );
}
