import "./index.less";
import * as React from "react";
import { AtButton } from "@atman/design-system";
import { AtmanCoPlatformsEnum } from "../../../global/components/ProductSelector";
import { AuthStore } from "../../../data/stores/AuthStore";
import { BaseForm } from "@atman/ui-kit/lib/components/BaseForm";
import { Col, Form, Row } from "reactstrap";
import { CustomTextInput } from "@atman/ui-kit";
import { CustomValidatedTextInput } from "@atman/ui-kit/lib/components/CustomValidatedTextInput";
import { LoginAppStores, history } from "../../../../index";
import { PasswordRule } from "@atman/ui-kit/lib/components/ChangePasswordForm/components/PasswordRule";
import { Trans, t } from "@lingui/macro";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import LoginComponentHeader from "../../../global/components/LoginComponentHeader";

export interface IChangePasswordProps {
    platform: AtmanCoPlatformsEnum;
    authStore?: AuthStore;
}

@inject<LoginAppStores, any, any, any>((stores) => ({ authStore: stores.authStore }))
@observer
export class ChangePassword extends BaseForm<IChangePasswordProps> {
    @observable public newPassword: string = "";

    @observable public newPasswordConfirm: string = "";

    validateForm(): boolean {
        const {} = this.props;

        const newPasswordFieldName = "newPassword";

        this.clearErrors();

        if (this.newPassword.length < 8) {
            this.errors.push({
                name: "ValidationError",
                message: t({
                    id: "global.errors.password.minLength",
                    message: "Your password must have at least 8 characters",
                }),
                fieldName: newPasswordFieldName,
            });
            return false;
        }

        if (!/.*[A-Z].*/.test(this.newPassword)) {
            this.errors.push({
                name: "ValidationError",
                message: t({
                    id: "global.errors.password.uppercaseChar",
                    message: "Your password must contain at least one uppercase character",
                }),
                fieldName: newPasswordFieldName,
            });

            return false;
        }

        if (!/.*[a-z].*/.test(this.newPassword)) {
            this.errors.push({
                name: "ValidationError",
                message: t({
                    id: "global.errors.password.lowercaseChar",
                    message: "Your password must contain at least one lowercase character",
                }),
                fieldName: newPasswordFieldName,
            });

            return false;
        }

        if (!/.*\d.*/.test(this.newPassword)) {
            this.errors.push({
                name: "ValidationError",
                message: t({
                    id: "global.errors.password.numberChar",
                    message: "Your password must contain at least one number",
                }),
                fieldName: newPasswordFieldName,
            });

            return false;
        }

        if (this.newPassword !== this.newPasswordConfirm) {
            this.errors.push({
                name: "ValidationError",
                message: t({
                    id: "global.errors.password.doesNotMatchConfirm",
                    message: "The provided passwords do not match",
                }),
                fieldName: "newPasswordConfirm",
            });

            return false;
        }

        return !this.errors.some((x) => x.fieldName === newPasswordFieldName || x.fieldName === "newPasswordConfirm");
    }

    @action protected changePassword = async (e: React.FormEvent<HTMLFormElement>) => {
        const { authStore, platform } = this.props;

        e.preventDefault();

        if (!this.validateForm()) {
            return;
        }

        this.setIsLoading(true);

        try {
            await authStore!.changePassword(
                this.newPassword,
                (history.location?.state as any)?.fromHelpCenterLogin ?? false,
                { platform },
            );
            this.setIsLoading(false);
        } catch (error) {
            this.setIsLoading(false);
            console.error(error);
        }
    };

    render() {
        const { authStore } = this.props;

        return (
            <div id="ChangePassword">
                <LoginComponentHeader
                    title={t({ id: "loginApp.changePasswordForm.title", message: "Change your password" })}
                    icon={["far", "key"]}
                />
                <Form onSubmit={this.changePassword}>
                    <div className="password-rules">
                        <Row noGutters>
                            <Col xs={12} sm={6}>
                                <PasswordRule
                                    passwordValue={this.newPassword}
                                    ruleType="lowercaseChar"
                                    ruleLabelOverride={t({
                                        id: "global.passwordRules.lowercaseChar",
                                        message: "Lowercase character",
                                    })}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <PasswordRule
                                    passwordValue={this.newPassword}
                                    ruleType="uppercaseChar"
                                    ruleLabelOverride={t({
                                        id: "global.passwordRules.uppercaseChar",
                                        message: "Uppercase character",
                                    })}
                                />
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col xs={12} sm={6}>
                                <PasswordRule
                                    passwordValue={this.newPassword}
                                    ruleType="numberChar"
                                    ruleLabelOverride={t({
                                        id: "global.passwordRules.numberChar",
                                        message: "A number",
                                    })}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <PasswordRule
                                    passwordValue={this.newPassword}
                                    ruleType="minLength"
                                    ruleLabelOverride={t({
                                        id: "global.passwordRules.minLength",
                                        message: "At least 8 characters",
                                    })}
                                />
                            </Col>
                        </Row>
                    </div>
                    <CustomTextInput
                        fieldName="username"
                        value={authStore!.username}
                        onChange={() => null}
                        disabled
                        label={t({ id: "global.inputs.labels.username", message: "Username" })}
                    />
                    <CustomValidatedTextInput
                        fieldName="newPassword"
                        value={this.newPassword}
                        onChange={this.onTextFieldChange}
                        type="password"
                        validationErrors={this.errors}
                        autoComplete="new-password"
                        label={t({ id: "global.inputs.labels.newPassword", message: "New Password" })}
                    />
                    <CustomValidatedTextInput
                        fieldName="newPasswordConfirm"
                        value={this.newPasswordConfirm}
                        onChange={this.onTextFieldChange}
                        type="password"
                        validationErrors={this.errors}
                        autoComplete="new-password"
                        label={t({
                            id: "global.inputs.labels.newPasswordConfirm",
                            message: "New Password Confirmation",
                        })}
                    />
                    <AtButton size="lg" isLoading={this.isLoading} type="submit" fullWidth>
                        <Trans id="global.buttons.labels.confirm">Confirm</Trans>
                    </AtButton>
                </Form>
            </div>
        );
    }
}

export default ChangePassword;
