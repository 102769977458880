import "./index.less";
import * as React from "react";
import { CSSProperties } from "react";
import { CustomInput, FormGroup, Label } from "reactstrap";

export interface ICustomStandaloneRadioInputProps {
    fieldName: string;
    id: string;
    value: any;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    checked: boolean;
    onClick?: (event: React.FormEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    style?: CSSProperties;
}

export const CustomStandaloneRadioInput: React.FC<ICustomStandaloneRadioInputProps> = (props) => {
    const { fieldName, value, ...otherProps } = props;

    return (
        <FormGroup className={`CustomStandaloneRadioInput ${fieldName}-input`} check>
            <Label check>
                <CustomInput type={"radio"} name={fieldName} value={value} {...otherProps} />
            </Label>
        </FormGroup>
    );
};
