import "./index.less";
import * as React from "react";
import { AtButton, AtSelect, IReactSelectOptionObject } from "@atman/design-system";
import { AtmanCoPlatformsEnum } from "../../../global/components/ProductSelector";
import { AuthStore } from "../../../data/stores/AuthStore";
import { BaseForm } from "@atman/ui-kit";
import { Form } from "reactstrap";
import { LoginAppStores } from "../../../../index";
import { Trans, t } from "@lingui/macro";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { withI18n, withI18nProps } from "@lingui/react";
import LoginComponentHeader from "../../../global/components/LoginComponentHeader";

export interface IChooseClientProps extends withI18nProps {
    platform: AtmanCoPlatformsEnum;
    authStore?: AuthStore;
}

@inject<LoginAppStores, any, any, any>((stores) => ({ authStore: stores.authStore }))
@observer
class ChooseClient extends BaseForm<IChooseClientProps, {}> {
    @observable clientOptions: IReactSelectOptionObject[] = [];
    @observable selectedClientId: IReactSelectOptionObject | null = null;

    componentDidMount() {
        this.loadClients();
    }

    @action protected loadClients = async () => {
        const { authStore, platform } = this.props;

        const clients = await authStore!.getAllowedPasswordClients({ platform });

        this.clientOptions = clients.map((x) => ({ value: x.id, label: x.name }));
    };

    protected validateClientId(): boolean {
        const {} = this.props;

        const fieldName = "selectedClientId";

        this.clearErrorForField(fieldName);

        if (!this.selectedClientId?.value) {
            this.errors.push({
                name: "ValidationError",
                message: t({ id: "global.errors.clients.noneSelected", message: `No client has been selected` }),
                fieldName,
            });
        }

        return !this.getErrorsForField(fieldName).any();
    }

    @action protected chooseClient = async (e: React.FormEvent<HTMLFormElement>) => {
        const { authStore, platform } = this.props;

        e.preventDefault();

        if (!this.validateClientId()) {
            return;
        }

        this.setIsLoading(true);

        try {
            await authStore!.chooseClient(this.selectedClientId!.value, { platform });
        } catch (error) {
            this.setIsLoading(false);
            console.log(error);
        }
    };

    render() {
        const {} = this.props;

        return (
            <div id="ChooseClient">
                <LoginComponentHeader
                    icon={["far", "sitemap"]}
                    title={t({ id: "loginApp.chooseClientForm.title", message: `Choose your client` })}
                />
                <Form onSubmit={this.chooseClient}>
                    <AtSelect
                        label={t({ id: "global.selects.labels.clients", message: `Clients` })}
                        options={this.clientOptions.slice()}
                        value={this.selectedClientId}
                        onChange={(o) => this.onAutoCompleteChange(o, "selectedClientId")}
                        name={"selectedClientId"}
                        placeholder={t({
                            id: "global.inputs.placeholders.chooseClient",
                            message: `Select a client from the list`,
                        })}
                        validationErrors={this.errors}
                    />
                    <AtButton
                        type={"submit"}
                        fullWidth
                        isLoading={this.isLoading}
                        disabled={!this.selectedClientId?.value}
                    >
                        <Trans id={"global.buttons.labels.manageClient"}>Manage Client</Trans>
                    </AtButton>
                </Form>
            </div>
        );
    }
}

export default withI18n()(ChooseClient);
