import "./index.less";
import {
    AtBadge,
    AtCheckableCard,
    AtContainer,
    AtSelect,
    AtTitle,
    IReactSelectOptionObject,
    Sizing,
} from "@atman/design-system";
import { BaseForm } from "../BaseForm";
import {
    CompDevCompetency,
    CompDevCompetencyGroup,
    CompetencyGroupEnum,
    ContentStore,
    GlobalStores,
    getEnumValues,
} from "@atman/business";
import { CompetencyGroupFormSection, ICompetencyBadge } from "./components/CompetencyGroupFormSection";
import { CustomTextInput } from "../CustomTextInput";
import { Trans, t } from "@lingui/macro";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import cn from "classnames";

export interface ICompetencySelectionFormProps {
    suggestedCompetencyIds?: string[];
    showSearch?: boolean;
    expandGroupsOnMount?: boolean | "first";
    selectedCompetencyIds?: string[];
    preselectedCompetencyIds?: string[];
    onCompetencyIdSelection?: (id: string) => void;
    contentStore?: ContentStore;
    competencyBadges?: ICompetencyBadge[];
    fullWidth?: boolean;
}

@inject(GlobalStores.contentStore)
@observer
export class CompetencySelectionForm extends BaseForm<ICompetencySelectionFormProps> {
    @observable private searchString: string = "";
    @observable private selectedFilters: IReactSelectOptionObject<CompDevCompetencyGroup>[] = [];

    @observable private _selectedCompetencyIds: string[] = [];
    @computed public get selectedCompetencyIds(): string[] {
        return this._selectedCompetencyIds;
    }

    componentDidMount(): void {
        if (!this.props.contentStore!.hasLoadedCompetencies) {
            this.props.contentStore!.loadCompetencies();
        }

        if (!this.props.selectedCompetencyIds) {
            this.loadPreselectedCompetencyIds();
        }
    }

    @computed private get filteredCompetencyGroups(): {
        group: CompDevCompetencyGroup;
        competencies: CompDevCompetency[];
    }[] {
        const groups = getEnumValues<CompDevCompetencyGroup>(CompDevCompetencyGroup).filter(
            (x) => this.selectedFilters.length === 0 || this.selectedFilters.some((y) => y.value === x),
        );

        return groups
            .map((x) => ({
                group: x,
                competencies: this.getFilteredCompetenciesForGroup(x),
            }))
            .filter((x) => x.competencies.any());
    }

    private getFilteredCompetenciesForGroup(group: CompDevCompetencyGroup): CompDevCompetency[] {
        const { contentStore } = this.props;

        return (
            contentStore?.competencies.filter((x) => {
                const matchesGroup = x.competencyGroup === group;
                const matchesString = !this.searchString
                    ? true
                    : x.name.toUpperCase().includes(this.searchString.toUpperCase()) ||
                      x.description.toUpperCase().includes(this.searchString.toUpperCase());

                return matchesGroup && matchesString;
            }) ?? []
        );
    }

    @action private loadPreselectedCompetencyIds = () => {
        const { suggestedCompetencyIds = [], preselectedCompetencyIds = [] } = this.props;

        this._selectedCompetencyIds = [...suggestedCompetencyIds, ...preselectedCompetencyIds];
    };

    @action private onCompetencyIdSelection = (id: string) => {
        const index = this._selectedCompetencyIds.indexOf(id);

        if (index === -1) {
            this._selectedCompetencyIds.push(id);
        } else {
            this._selectedCompetencyIds.splice(index, 1);
        }
    };

    @action.bound
    private onFiltersChange(selectedFilters: IReactSelectOptionObject<CompDevCompetencyGroup>[]) {
        this.selectedFilters = selectedFilters || [];
    }

    private filterOptions: IReactSelectOptionObject<CompDevCompetencyGroup>[] = getEnumValues<CompDevCompetencyGroup>(
        CompDevCompetencyGroup,
    ).map((x) => ({
        label: t({ id: `global.competencyGroups.${CompDevCompetencyGroup[x].toCamel()}` }),
        value: x,
    }));

    render(): JSX.Element {
        const {
            showSearch = true,
            expandGroupsOnMount = false,
            suggestedCompetencyIds,
            selectedCompetencyIds,
            competencyBadges,
            onCompetencyIdSelection,
            contentStore,
            fullWidth,
        } = this.props;

        const suggestedCompetencies =
            contentStore?.competencies.filter((x) => suggestedCompetencyIds?.includes(x.id)) ?? [];

        return (
            <div className={cn("CompetencySelectionForm", { fullWidth: fullWidth })}>
                {suggestedCompetencies.length > 0 && (
                    <AtContainer className="suggestedCompetencies" padding={[Sizing.pixels.md, Sizing.pixels.lg]}>
                        <AtTitle title={t({ id: "global.recommendedCompetencies" })} headingType={4} />

                        <div className="checkableCardsContainer">
                            {suggestedCompetencies.map((suggestedCompetency) => (
                                <AtCheckableCard
                                    key={suggestedCompetency.name}
                                    id={suggestedCompetency.name}
                                    padding={[Sizing.pixels.xs, Sizing.pixels.sm]}
                                    checked={selectedCompetencyIds?.includes(suggestedCompetency.id) ?? false}
                                    onContainerClick={() => onCompetencyIdSelection?.(suggestedCompetency.id)}
                                    topContent={
                                        <div className="checkableCardContent">
                                            <AtTitle
                                                title={suggestedCompetency.name}
                                                htmlTitle={suggestedCompetency.name}
                                                headingType={5}
                                                noMargin
                                            />
                                            {/**TODO Simon 2021-07-28: AtBadge - Separate size prop into two "text-size" & "padding" props */}
                                            <AtBadge
                                                color="secondary"
                                                badgeStyle="fill-light"
                                                size="lg"
                                                bordered={false}
                                            >
                                                {`global.competencyGroups.${CompetencyGroupEnum[
                                                    suggestedCompetency.competencyGroup
                                                ].toCamel()}`.localize()}
                                            </AtBadge>
                                            <AtBadge color="warning" badgeStyle="fill-medium" size="lg" bordered>
                                                <Trans id={"global.recommended.f"} />
                                            </AtBadge>
                                        </div>
                                    }
                                />
                            ))}
                        </div>
                    </AtContainer>
                )}
                {showSearch && (
                    <div className="search">
                        <CustomTextInput
                            leftIcon={["far", "search"]}
                            fieldName={"searchString"}
                            value={this.searchString}
                            onChange={this.onTextFieldChange}
                            placeholder={"global.search".localize()}
                            hideLabel
                        />
                        <AtSelect
                            options={this.filterOptions}
                            value={this.selectedFilters.slice()}
                            onChange={this.onFiltersChange}
                            placeholder={t({ id: "global.filters" })}
                            isMulti
                            // TODO: Support this in component
                            // maxNumberOfValuesToDisplay={1}
                            closeMenuOnSelect={false}
                        />
                    </div>
                )}

                <div
                    className={cn("competency-groups-container", {
                        isSearching: !!this.searchString || !!this.selectedFilters.length,
                    })}
                >
                    {this.filteredCompetencyGroups.map((x, i) => (
                        <CompetencyGroupFormSection
                            className={cn({ fullWidth: fullWidth })}
                            competencyGroup={x.group}
                            isSearching={!!this.searchString || !!this.selectedFilters.length}
                            selectedCompetencyIds={selectedCompetencyIds ?? this._selectedCompetencyIds}
                            suggestedCompetencyIds={suggestedCompetencyIds}
                            onCompetencyIdSelection={onCompetencyIdSelection ?? this.onCompetencyIdSelection}
                            filteredCompetenciesForGroup={x.competencies}
                            expandOnMount={expandGroupsOnMount === "first" ? i === 0 : expandGroupsOnMount}
                            competencyBadges={competencyBadges}
                            key={x.group}
                        />
                    ))}
                </div>
            </div>
        );
    }
}
