import { Banner } from "@atman/design-system";
import { Colors } from "../../styles";
import { GlobalStores, LocalizationStore, getHelpCenterUrl } from "@atman/business";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { inject, observer } from "mobx-react";
import React from "react";

export type ColorStatus = "primary" | "success" | "warning" | "error" | "action";

export interface IHelpCenterBannerLinkProps {
    text: string;
    icon: IconProp;
    status: ColorStatus;
    articleId: string;
    localizationStore?: LocalizationStore;
}

export const HelpCenterBannerLink: React.FC<IHelpCenterBannerLinkProps> = inject(GlobalStores.localizationStore)(
    observer((props) => {
        const { text, articleId, icon, status, localizationStore } = props;

        const onLinkClick = async () => {
            const helpCenterUrl = await getHelpCenterUrl(
                `/articles/${articleId}`,
                localizationStore!.currentFullLocale,
            );
            window.open(helpCenterUrl, "_blank");
        };

        const getBgColor = (): Colors => {
            switch (status) {
                case "primary":
                    return Colors.primaryBg;
                case "success":
                    return Colors.greenBg;
                case "warning":
                    return Colors.yellowBg;
                case "error":
                    return Colors.redBg;
                default:
                    return Colors.primaryBg;
            }
        };

        const getTextColor = (): Colors => {
            switch (status) {
                case "primary":
                    return Colors.primaryColor;
                case "success":
                    return Colors.successColor;
                case "warning":
                    return Colors.warningColor;
                case "error":
                    return Colors.errorColor;
                default:
                    return Colors.primaryColor;
            }
        };

        return (
            <div className="HelpCenterBannerLink">
                <Banner
                    text={text}
                    icon={icon}
                    btnIcon={["far", "arrow-alt-square-right"]}
                    bgColor={getBgColor().toString()}
                    textColor={getTextColor().toString()}
                    onClickHandler={onLinkClick}
                />
            </div>
        );
    }),
);
