import { Amex, Mastercard, Visa } from "../../static";
import { AtTableColumn, TableItemButtons, TableItemTitle } from "@atman/design-system";
import { DeleteConfirmationAlert } from "..";
import { IStripeCustomerCard, ModalStore, ModalTypes, ProductBalanceStore } from "@atman/business";
import { MatchTypeOptionsEnum } from "@atman/design-system/lib/components/molecules/AtTable/component/filtering/AtTableFreeTextFilter";
import { t } from "@lingui/macro";
import DropdownItem from "reactstrap/lib/DropdownItem";
import React from "react";

const getPaymentMethodsListColumns = (
    modalStore: ModalStore,
    productBalanceStore: ProductBalanceStore,
): AtTableColumn<IStripeCustomerCard>[] => [
    {
        key: "name",
        fieldName: "name",
        align: "left",
        label: t({ id: "global.name" }),
        displayOverSize: "xs",
        width: "fit-content",
        filter: {
            type: "freeText",
        },
        onFilter: (value, record, matchType) => {
            const regex = new RegExp(`.*${value}.*`, "i");
            const searchRecord = record.brand;

            if (matchType === MatchTypeOptionsEnum.DoesNotContains) {
                const regexNotContains = new RegExp(`^((?!${value}).)*$`, "i");
                return regexNotContains.test(`${searchRecord}`);
            }

            return regex.test(`${searchRecord}`);
        },
        render: (name: string, record: IStripeCustomerCard) => {
            let brandLogoUrl: string = "";
            switch (record.brand) {
                case "Visa":
                    brandLogoUrl = Visa;
                    break;
                case "MasterCard":
                    brandLogoUrl = Mastercard;
                    break;
                case "American Express":
                    brandLogoUrl = Amex;
                    break;
            }

            return (
                <TableItemTitle
                    customLeftContent={<img height={24} src={brandLogoUrl} alt={record.brand} className="card-logo" />}
                    title={name}
                    maxWidth={200}
                />
            );
        },
    },
    {
        key: "last4Digits",
        fieldName: "last4Digits",
        align: "left",
        label: t({ id: "global.member" }),
        displayOverSize: "xs",
        width: 200,
        filter: {
            type: "freeText",
        },
        onFilter: (value, record, matchType) => {
            const regex = new RegExp(`.*${value}.*`, "i");
            const searchRecord = `${record.label} (**** **** **** ${record.last4Digits})`;

            if (matchType === MatchTypeOptionsEnum.DoesNotContains) {
                const regexNotContains = new RegExp(`^((?!${value}).)*$`, "i");
                return regexNotContains.test(`${searchRecord}`);
            }

            return regex.test(`${searchRecord}`);
        },
        render: (last4Digits: string, record: IStripeCustomerCard) => {
            const cardPresentationLabel = `${record.label} (**** **** **** ${last4Digits})`;
            return <TableItemTitle title={cardPresentationLabel} />;
        },
    },
    {
        key: "expMonth",
        fieldName: "expMonth",
        align: "left",
        label: t({ id: "global.expires" }),
        displayOverSize: "xs",
        width: 200,
        filter: {
            type: "freeText",
        },

        onFilter: (value, record, matchType) => {
            const regex = new RegExp(`.*${value}.*`, "i");
            const searchRecord = `${record.expMonth}/${record.expYear}`;

            if (matchType === MatchTypeOptionsEnum.DoesNotContains) {
                const regexNotContains = new RegExp(`^((?!${value}).)*$`, "i");
                return regexNotContains.test(`${searchRecord}`);
            }

            return regex.test(`${searchRecord}`);
        },
        render: (expMonth: string, record: IStripeCustomerCard) => {
            const expirationText = `${expMonth}/${record.expYear}`;
            return <TableItemTitle title={expirationText} />;
        },
    },
    {
        key: "id",
        fieldName: "cardId",
        displayOverSize: "sm",
        width: 140,
        render: (cardId: string) => {
            const openEditCardModal = () => modalStore!.openModal(ModalTypes.UpdateCard, { id: cardId });

            const openDeleteCardDialog = () =>
                new DeleteConfirmationAlert(
                    () => productBalanceStore!.deleteCustomerCard(cardId),
                    "global.deleteCustomerCardConfirmationText".localize(),
                ).fire();

            return (
                <TableItemButtons
                    menuContent={
                        <>
                            <DropdownItem onClick={openEditCardModal}>
                                {t({ id: "global.editCardInformation" })}
                            </DropdownItem>
                            <DropdownItem onClick={openDeleteCardDialog}>
                                {t({ id: "global.buttons.labels.delete" })}
                            </DropdownItem>
                        </>
                    }
                />
            );
        },
    },
];

export default getPaymentMethodsListColumns;
