import * as React from "react";
import { AtIcon, IAtIconProps } from "../../atoms/AtIcon";
import { GroupBase, ValueContainerProps, components } from "react-select";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { OptionTypeBase } from "../../../types";
import { Sizing } from "../../../styles/variables/sizing";

export interface IAtSelectIconValueContainerProps<
    TOption extends OptionTypeBase,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> extends ValueContainerProps<TOption, IsMulti, TGroup> {
    icon: IconProp;
    iconProps?: Omit<Partial<IAtIconProps>, "icon" | "style">;
}

/**
 * AtSelectIconValueContainer - Select Option w/ Checkmark Component
 * Category: Molecule
 *
 * @param props {IAtSelectIconValueContainerProps}
 **/
export const AtSelectIconValueContainer = <
    TOption extends OptionTypeBase,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(
    props: React.PropsWithChildren<IAtSelectIconValueContainerProps<TOption, IsMulti, TGroup>>,
): JSX.Element => {
    const { children, icon, iconProps = {}, ...otherProps } = props;

    return (
        <components.ValueContainer<TOption, IsMulti, TGroup> {...otherProps}>
            <AtIcon icon={icon} {...iconProps} style={{ marginRight: Sizing.pixels.xs }} />
            {children}
        </components.ValueContainer>
    );
};
