import "./index.less";
import * as React from "react";
import { AtButton, AtTitle, IAtButtonProps } from "@atman/design-system";
import { AtCustomizableProperty, IAtCustomizablePropertyProps } from "./components/AtCustomizableProperty";
import { AtMenuButton } from "@atman/design-system";
import { Colors } from "../../..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Label, Progress, UncontrolledTooltip } from "reactstrap";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import { v4 } from "uuid";

export interface IAtCustomizableCardProps {
    title: string;
    subtitle?: string;
    ctaLabel?: React.ReactNode;
    ctaAction?: () => void;
    ctaProps?: Partial<IAtButtonProps>;
    menuActions?: React.ReactNode;
    icon?: IconProp | React.ReactNode;
    completionInfo?: ICompletionInfo;
    topProperties?: IAtCustomizablePropertyProps[];
    bottomProperties?: IAtCustomizablePropertyProps[];
    additionalProperties?: IAtCustomizablePropertyProps[];
    inverseTitleAndSubtitle?: boolean;
    className?: string;
    id?: string;
}

@inject()
@observer
export class AtCustomizableCard extends React.Component<IAtCustomizableCardProps, {}> {
    private readonly additionalInformationIconId: string;

    constructor(props) {
        super(props);

        this.additionalInformationIconId = v4();
    }

    @computed get completionPercentageString(): string {
        const { completionInfo } = this.props;

        const percentage = (completionInfo!.completionValue / completionInfo!.completionTotal) * 100;

        if (isNaN(percentage)) {
            return `N/A`;
        }

        return `${percentage.toFixed(0)} %`;
    }

    render() {
        const {
            className,
            id,
            title,
            subtitle,
            menuActions,
            icon,
            completionInfo,
            topProperties,
            bottomProperties,
            additionalProperties,
            ctaAction,
            ctaLabel = "view",
            ctaProps,
            inverseTitleAndSubtitle = false,
            ...otherProps
        } = this.props;

        return (
            <div className={`AtCustomizableCard ${className ?? ""}`} id={id} {...otherProps}>
                <div className="top-section">
                    {(icon || menuActions) && (
                        <div className={`header ${icon ? "has-icon" : ""}`}>
                            {icon && (
                                <>
                                    {React.isValidElement(icon) ? (
                                        icon
                                    ) : (
                                        <div className="icon-container">
                                            <FontAwesomeIcon icon={icon as IconProp} />
                                        </div>
                                    )}
                                </>
                            )}
                            {menuActions && (
                                <AtMenuButton size={"md"} borderType={"hover"}>
                                    {menuActions}
                                </AtMenuButton>
                            )}
                        </div>
                    )}
                    <div className="titles-container">
                        {subtitle && inverseTitleAndSubtitle && <div className="subtitle">{subtitle}</div>}
                        <AtTitle className="title" headingType={2} title={title} />
                        {subtitle && !inverseTitleAndSubtitle && <div className="subtitle">{subtitle}</div>}
                    </div>
                    {topProperties && (
                        <div className="top-properties-container properties-container">
                            {topProperties?.map((x, i) => (
                                <AtCustomizableProperty key={`custom-card-top-prop-${i}`} {...x} />
                            ))}
                        </div>
                    )}
                    {completionInfo && (
                        <div className="completion-info-container">
                            <AtCustomizableProperty
                                label={"completion"}
                                value={
                                    <div className="completion-rate-label">
                                        {(
                                            ["percentage", "percentageAndFraction"] as CompletionProgressDisplay[]
                                        ).includes(completionInfo?.progressDisplay) && (
                                            <span className="percentage">{this.completionPercentageString}</span>
                                        )}
                                        {(
                                            ["fraction", "percentageAndFraction"] as CompletionProgressDisplay[]
                                        ).includes(completionInfo?.progressDisplay) && (
                                            <span className="fraction">
                                                {completionInfo?.progressDisplay === "percentageAndFraction" && " ("}
                                                {`${completionInfo!.completionValue}/${
                                                    completionInfo!.completionTotal
                                                }`}
                                                {completionInfo?.progressDisplay === "percentageAndFraction" && ")"}
                                            </span>
                                        )}
                                    </div>
                                }
                            />
                            <Progress
                                value={completionInfo?.completionValue}
                                max={completionInfo?.completionTotal}
                                color={"muted"}
                            />
                        </div>
                    )}
                    {bottomProperties && (
                        <div className="bottom-properties-container properties-container">
                            {bottomProperties?.map((x, i) => (
                                <AtCustomizableProperty key={`custom-card-bottom-prop-${i}`} {...x} />
                            ))}
                        </div>
                    )}
                    {additionalProperties?.any() && (
                        <div className="additional-properties-container">
                            <AtCustomizableProperty
                                label={"global.information".localize()}
                                value={
                                    <>
                                        <div className="icon-container" id={`id-${this.additionalInformationIconId}`}>
                                            <FontAwesomeIcon
                                                id={``}
                                                icon={["far", "info-circle"]}
                                                color={Colors.actionColor}
                                            />
                                        </div>
                                        <UncontrolledTooltip
                                            target={`id-${this.additionalInformationIconId}`}
                                            placement={"auto"}
                                        >
                                            <div className={"additional-properties-tooltip-content"}>
                                                <Label className={"header-label"}>{"global.about".localize()}</Label>
                                                <div className="properties-list">
                                                    {additionalProperties?.map((x, i) => (
                                                        <div
                                                            className={"property-item-container"}
                                                            key={`custom-card-additional-prop-${i}`}
                                                        >
                                                            {x.label !== undefined ? (
                                                                <Label className={"property-label"}>{x.label}</Label>
                                                            ) : null}
                                                            <div className="property-value">{x.value}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </UncontrolledTooltip>
                                    </>
                                }
                            />
                        </div>
                    )}
                </div>
                {ctaAction && (
                    <AtButton
                        className={"cta-button"}
                        size={"lg"}
                        color={"secondary"}
                        onClick={ctaAction}
                        fullWidth
                        {...ctaProps}
                    >
                        {ctaLabel}
                    </AtButton>
                )}
            </div>
        );
    }
}

export type CompletionProgressDisplay = "percentage" | "fraction" | "percentageAndFraction";

export interface ICompletionInfo {
    completionValue: number;
    completionTotal: number;
    // color: ColorProperty;
    progressDisplay: CompletionProgressDisplay;
}

export { IAtCustomizablePropertyProps, AtCustomizableProperty };
