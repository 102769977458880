export interface IBaseCompetencyElement {
    readonly id: string;
    readonly code: string;
    readonly name: string;
    readonly description: string;
    readonly rank: number;
}

export abstract class BaseCompetencyElement implements IBaseCompetencyElement {
    public readonly id: string;
    public readonly code: string;
    public readonly name: string;
    public readonly description: string;
    public readonly rank: number;

    protected constructor(json: IBaseCompetencyElement) {
        this.id = json.id;
        this.code = json.code;
        this.name = json.name;
        this.description = json.description;
        this.rank = json.rank;
    }
}
