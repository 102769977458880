import "./index.less";
import { ApplySort, AtTableColumn, AtTableItemKeyTypes, AtTableSort, AtTableSortOrder } from "../types";
import { AtContainer } from "../../AtContainer";
import { AtIcon } from "../../AtIcon";
import { AtTableHeader } from "./parts/AtTableHeader";
import { AtTableRow, AtTableRowHeight } from "./parts/AtTableRow";
import { AtTableRowCard } from "./parts/AtTableRowCard";
import { AtTableRowsSkeleton } from "../../../molecules/AtTable/component/skeleton/AtTableRowsSkeleton";
import { AtTitleSubtitle } from "../../../molecules/AtTitleSubtitle";
import { AtTooltip } from "../../AtTooltip";
import { IBaseEntity } from "@atman/core";
import { getTableColumnsValueTypes } from "../itemKeyTypes";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import { v4 } from "uuid";
import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";

export type CustomHandleSort<T> = (sortKey: keyof T, sortOrder: AtTableSortOrder) => void;
export type CardContent<T extends IBaseEntity> = (record: T, index?: number) => React.ReactElement | string | null;
export type DisplayType = "table" | "cards";

export interface IAtTableBaseProps<T extends IBaseEntity> {
    columns: AtTableColumn<T>[];
    items: T[];
    displayType?: DisplayType;
    enableCardDisplay?: boolean;
    rowKey?: string;
    onRowClick?: (event: React.MouseEvent<HTMLTableRowElement>, record: T) => void;
    expandRowContent?: (record: T) => React.ReactNode;
    disableClickActionForRow?: (record: T, rowIndex: number) => boolean;
    itemKeyTypes?: AtTableItemKeyTypes;
    rowHeight?: AtTableRowHeight;
    isLoading?: boolean;
    customHandleSort?: CustomHandleSort<T>;
    customEmptyState?: JSX.Element;
    isTableEmpty?: boolean;
    hasCheckbox?: boolean;
    handleCheckboxChange?: (row: T) => void;
    handleAllCheckboxChange?: () => void;
    isAllChecked?: boolean;
    listIdsForCheckbox?: string[];
    listOfDisabledIds?: string[];
    className?: string;
    cardContentRender?: CardContent<T>;
    showSearchBar?: boolean;
    disabledCheckboxTooltipText?: string;
    showChevronDownIcon?: boolean;
    hideCheckboxHeader?: boolean;
    enableSelectAllToggle?: boolean;
}

function AtTableBaseRaw<T extends IBaseEntity>(props: IAtTableBaseProps<T>) {
    const {
        rowKey = v4(),
        displayType = "table",
        columns,
        items,
        onRowClick,
        expandRowContent,
        disableClickActionForRow,
        itemKeyTypes = getTableColumnsValueTypes(columns, items),
        rowHeight,
        isLoading = false,
        customHandleSort,
        customEmptyState,
        isTableEmpty,
        hasCheckbox = false,
        handleCheckboxChange,
        handleAllCheckboxChange,
        isAllChecked,
        listIdsForCheckbox,
        listOfDisabledIds,
        className,
        cardContentRender,
        disabledCheckboxTooltipText,
        showChevronDownIcon = false,
        enableSelectAllToggle,
        hideCheckboxHeader,
        ...otherProps
    } = props;

    const itemsOriginalSort = [...items];
    const [displayedItems, setDisplayedItems] = useState<T[]>([]);
    const [sortKey, setSortKey] = useState<undefined | string>(undefined);
    const [sortOrder, setSortOrder] = useState<AtTableSortOrder>("none");
    const [lastSortFunction, setLastSortFunction] = useState<AtTableSort<T> | undefined>(() => undefined);
    const isEmpty = displayedItems?.length === 0;

    const emptyTableTitle = useMemo((): string | undefined => {
        if (isTableEmpty) {
            return t({ id: "global.emptyTableTitle" });
        } else if (displayedItems.length === 0) {
            return t({ id: "global.emptyTableResultTitle" });
        }

        return undefined;
    }, [displayedItems.length, isTableEmpty]);

    const emptyTableDescription = useMemo(
        () =>
            isTableEmpty ? t({ id: "global.emptyTableDescription" }) : t({ id: "global.emptyTableResultDescription" }),
        [isTableEmpty],
    );

    useEffect(() => {
        if (sortOrder === "asc") {
            setDisplayedItems([...items].sort(lastSortFunction));
        } else if (sortOrder === "desc") {
            setDisplayedItems([...items].sort(lastSortFunction).reverse());
        } else {
            setDisplayedItems([...items]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    const applySort: ApplySort<T> = (key: string, sort: AtTableSort<T>) => {
        setLastSortFunction(() => sort);
        // Same Column Sort
        if (key === sortKey) {
            // it was ASC, now it's DESC
            if (sortOrder === "asc") {
                setSortOrder("desc");
                if (customHandleSort && key) {
                    customHandleSort(key, "desc");
                } else {
                    setDisplayedItems([...displayedItems].sort(sort).reverse());
                }
                // it was DESC, now it's NONE
            } else if (sortOrder === "desc") {
                setSortKey(undefined);
                setSortOrder("none");
                if (customHandleSort && key) {
                    customHandleSort(key, "none");
                } else {
                    setDisplayedItems(itemsOriginalSort);
                }
            }
        } else {
            setSortKey(key);
            setSortOrder("asc");
            if (customHandleSort && key) {
                customHandleSort(key, "asc");
            } else {
                setDisplayedItems([...displayedItems].sort(sort));
            }
        }
    };

    return (
        <div
            className={cn("AtTableBase", className, `displayType_${displayType}`, {
                isClickable: onRowClick !== undefined || expandRowContent !== undefined,
                isEmpty: isEmpty,
            })}
            {...otherProps}
        >
            {displayType === "table" ? (
                <table>
                    <AtTableHeader<T>
                        columns={columns}
                        itemKeyTypes={itemKeyTypes}
                        applySort={applySort}
                        sortKey={sortKey}
                        sortOrder={sortOrder}
                        hasCheckbox={hasCheckbox}
                        handleAllCheckboxChange={handleAllCheckboxChange}
                        isSelectAllToggled={isAllChecked}
                        listIdsForCheckbox={listIdsForCheckbox}
                        listOfDisabledIds={listOfDisabledIds}
                        enableSelectAllToggle={enableSelectAllToggle}
                        showChevronDownIcon={showChevronDownIcon}
                        hideCheckboxHeader={hideCheckboxHeader}
                    />

                    {isLoading ? (
                        <tbody className={cn("table-body")}>
                            <AtTableRowsSkeleton
                                height={rowHeight}
                                numberOfLines={10}
                                numberOfColumns={columns.length}
                            />
                        </tbody>
                    ) : isEmpty ? (
                        <tbody className={cn("table-body")}>
                            {customEmptyState ? (
                                <div className="emptyState">{customEmptyState}</div>
                            ) : (
                                <AtContainer className="emptyState">
                                    {emptyTableTitle && (
                                        <>
                                            <span className="icon-container">
                                                <AtIcon icon={["far", "empty-set"]} size="lg" />
                                            </span>
                                            <AtTitleSubtitle title={emptyTableTitle} subtitle={emptyTableDescription} />
                                        </>
                                    )}
                                </AtContainer>
                            )}
                        </tbody>
                    ) : (
                        <tbody className={cn("table-body")}>
                            {displayedItems?.map((displayedItem, index) => {
                                const isClickActionDisabled = disableClickActionForRow?.(displayedItem, index) === true;
                                const hasHoverAction = !isClickActionDisabled && !!onRowClick;

                                return (
                                    <React.Fragment key={displayedItem.id}>
                                        <AtTableRow
                                            className={cn({ hasHoverAction: hasHoverAction })}
                                            rowKey={displayedItem.id ?? rowKey}
                                            columns={columns}
                                            item={displayedItem}
                                            rowHeight={rowHeight}
                                            hasCheckbox={hasCheckbox}
                                            listIdsForCheckbox={listIdsForCheckbox}
                                            listOfDisabledIds={listOfDisabledIds}
                                            handleCheckboxChange={handleCheckboxChange}
                                            onRowClick={onRowClick}
                                            hasHoverAction={hasHoverAction}
                                            expandRowContent={expandRowContent}
                                            showChevronDownIcon={showChevronDownIcon}
                                        />

                                        {hasCheckbox &&
                                            disabledCheckboxTooltipText &&
                                            (listOfDisabledIds ?? []).some((i) => displayedItem.id === i) && (
                                                <AtTooltip displayMode="dark" target={displayedItem.id ?? rowKey}>
                                                    {disabledCheckboxTooltipText}
                                                </AtTooltip>
                                            )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    )}
                </table>
            ) : (
                <>
                    {displayedItems?.map((displayedItem) =>
                        cardContentRender ? (
                            <React.Fragment key={displayedItem.id}>{cardContentRender(displayedItem)}</React.Fragment>
                        ) : (
                            <AtTableRowCard<T>
                                key={displayedItem.id}
                                rowKey={rowKey}
                                columns={columns}
                                item={displayedItem}
                            />
                        ),
                    )}
                </>
            )}
        </div>
    );
}

export const AtTableBase = observer(AtTableBaseRaw);
