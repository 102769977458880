import "./index.less";
import * as React from "react";
import { AtCheckboxBase, AtSwitch, AtTitleSubtitle, DefaultSizes, XL } from "../../../internal";

export interface IAtCheckboxTitleSubtitleProps {
    id: string;
    title: string;
    subtitle?: string;
    size?: DefaultSizes | XL;
    isTextClickable?: boolean;
    checkboxPosition?: "left" | "right";
    useSwitch?: boolean;
    disabled?: boolean;
    value?: any;
    checked: boolean;
    onChange: (event?: React.FormEvent<HTMLInputElement>) => void;
}

export const AtCheckboxTitleSubtitle: React.FC<IAtCheckboxTitleSubtitleProps> = (props) => {
    const {
        id,
        title,
        subtitle,
        size,
        isTextClickable = true,
        disabled = false,
        value,
        checked,
        checkboxPosition = "left",
        useSwitch = false,
        onChange,
        ...otherProps
    } = props;

    const getClasses: () => string = () => {
        let classes: string = `AtCheckboxTitleSubtitle checkbox-${checkboxPosition}`;

        if (isTextClickable) {
            classes += " text-clickable";
        }

        return classes;
    };

    const getCheckbox: () => JSX.Element = () => {
        if (useSwitch) {
            return <AtSwitch id={id} checked={checked} onChange={onChange} disabled={disabled} />;
        }

        return <AtCheckboxBase id={id} value={value} checked={checked} onChange={onChange} disabled={disabled} />;
    };

    const changeViaText = () => {
        const checkbox: HTMLInputElement = document.getElementById(id) as HTMLInputElement;

        if (checkbox) {
            checkbox.click();
        }
    };

    return (
        <div className={getClasses()} {...otherProps}>
            {checkboxPosition === "left" && getCheckbox()}
            <AtTitleSubtitle
                title={title}
                subtitle={subtitle}
                size={size}
                onClick={isTextClickable ? changeViaText : undefined}
            />
            {checkboxPosition === "right" && getCheckbox()}
        </div>
    );
};
