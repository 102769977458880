import "./index.less";
import * as React from "react";
import { DefaultComponentProps, ExtraAndDefaultSizes, Typography } from "../../../internal";
import Label from "reactstrap/lib/Label";
import cn from "classnames";

export interface IAtLabelProps extends DefaultComponentProps {
    size?: ExtraAndDefaultSizes;
}

/**
 * AtLabel - Label Component
 * Category: Atom
 *
 * @param props {IAtLabelProps}
 **/
export const AtLabel: React.FC<IAtLabelProps> = (props) => {
    const { size = "md", className, id, style, children } = props;

    const getStyles = () => {
        const styles = {
            fontSize: Typography.getFontSizePropValue(size),
            lineHeight: Typography.lineHeight,
        };

        return {
            ...styles,
            ...style,
        };
    };

    return (
        <Label className={cn("AtLabel", className)} style={getStyles()} id={id}>
            {children}
        </Label>
    );
};
