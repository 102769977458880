import { ItemType, SearchAllCategory } from "../../types";

export const searchAllCategoryToItemTypeMap: Map<SearchAllCategory, ItemType> = new Map([
    [SearchAllCategory.Candidate, ItemType.Candidate],
    [SearchAllCategory.Client, ItemType.Client],
    [SearchAllCategory.Partner, ItemType.Partner],
    [SearchAllCategory.Team, ItemType.Team],
]);

export const itemTypeToSearchAllCategoryMap: Map<ItemType, SearchAllCategory> = new Map<ItemType, SearchAllCategory>();

for (const [k, v] of searchAllCategoryToItemTypeMap.entries()) {
    itemTypeToSearchAllCategoryMap.set(v, k);
}
