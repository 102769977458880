import "./index.less";
import { AtIcon } from "../../atoms/AtIcon";
import { AtSubtitle } from "../../atoms/AtSubtitle";
import { DefaultSizes } from "../../../types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";

export interface IAtIconLabelProps {
    label: string;
    icon: IconProp;
    size?: DefaultSizes;
    color?: string;
    hideIcon?: boolean;
}

export const AtIconLabel: React.FC<IAtIconLabelProps> = (props) => {
    const { icon, size, label, color = "#D6D6D6", hideIcon, ...otherProps } = props;

    return (
        <div className="AtIconLabel" {...otherProps}>
            {(!hideIcon && <AtIcon icon={icon} color={color} />) || null}
            <AtSubtitle size={size} subtitle={label} upWeight />
        </div>
    );
};
