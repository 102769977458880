import "./OrderSummaryItem.less";
import * as React from "react";
import {
    AppScope,
    CartStore,
    CurrencyEnum,
    GlobalStores,
    ISummaryProductItem,
    LocalizationStore,
} from "@atman/business";
import { AtBadge } from "@atman/design-system";
import { Col, Row } from "reactstrap";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";

export interface IOrderSummaryItemProps {
    scope: AppScope;
    summaryItem: ISummaryProductItem;
    customPackage: boolean;
    localizationStore?: LocalizationStore;
    cartStore?: CartStore;
}

@inject(GlobalStores.localizationStore, GlobalStores.cartStore)
@observer
export class OrderSummaryItem extends React.Component<IOrderSummaryItemProps> {
    @computed
    get numberFormatter(): Intl.NumberFormat {
        return new Intl.NumberFormat(this.props.localizationStore!.currentShortLocale, {
            style: "currency",
            currency: CurrencyEnum[this.props.cartStore!.billingCurrency],
        });
    }

    render() {
        const { scope, summaryItem } = this.props;

        const discountRate = summaryItem.discountRate || 0;
        const totalDiscountedPrice = summaryItem.price * ((100 - discountRate) / 100);

        return (
            <Row className="OrderSummaryItem" noGutters>
                <Col className="product-name" sm={scope === AppScope.Partner ? 5 : 7}>
                    {/*<ProductInfo productCode={summaryItem.productCode} />*/}
                    <span className="label">{summaryItem.label}</span>
                </Col>
                <Col className="quantity" sm={2}>
                    {summaryItem.isCustomPackage ? summaryItem.quantity : 1}
                </Col>
                {scope === AppScope.Partner ? (
                    <Col className="discount" sm={2}>
                        {discountRate > 0 ? (
                            <AtBadge color={"success"} size={"md"} style={{ display: "inline-block" }}>
                                {`-${discountRate}%`}
                            </AtBadge>
                        ) : null}
                    </Col>
                ) : null}
                <Col className="price-per-unit" sm={1}>
                    {discountRate > 0 ? (
                        <div className="regular-price">
                            {this.numberFormatter.format(summaryItem.price / summaryItem.quantity)}
                        </div>
                    ) : null}
                    <div className="discounted-price">
                        {this.numberFormatter.format(totalDiscountedPrice / summaryItem.quantity)}
                    </div>
                </Col>
                <Col className="price price-col" sm={2}>
                    {this.numberFormatter.format(totalDiscountedPrice)}
                </Col>
            </Row>
        );
    }
}
