import { BaseApi, DataType } from "../base";
import {
    EmailNotificationFrequencyEnum,
    HttpMethodEnum,
    IAssignableRoles,
    IUserNotificationSettings,
    NotificationType,
    Role,
    SearchAllItemResultModel,
} from "../types";
import { IUserInput, User } from "../models";
import { ReactAppSettings } from "../libs";

export class UsersApi extends BaseApi {
    static async getUsers() {
        return this.sendApiRequestWithResult<null, User[]>(HttpMethodEnum.GET, ReactAppSettings.appUrls.getUsers);
    }

    public static async getUser(id: string) {
        return this.sendApiRequestWithResult<null, User>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getUser,
            null,
            id,
        );
    }

    static async createUser(input: IUserInput & { notifyUser?: boolean }) {
        return this.sendApiRequestWithResult<IUserInput, User>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.createUser,
            input,
        );
    }

    static async updateUser(id: string, input: IUserInput) {
        // user['emailConfirm'] = user.email;
        // user['communicationEmailConfirm'] = user.communicationEmail;

        return this.sendApiRequestWithResult<IUserInput, User>(
            HttpMethodEnum.PUT,
            ReactAppSettings.appUrls.updateUser,
            input,
            id,
        );
    }

    static async updateUserClientsDelegation(
        id: string,
        automaticClientDelegationDisabled: boolean,
        clientsDelegationOverride: string[],
    ) {
        const data = {
            automaticClientDelegationDisabled,
            clientsDelegationOverride,
        };

        return this.sendApiRequestWithResult<typeof data>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateUserClientsDelegation,
            data,
            id,
        );
    }

    static async deactivateUser(id: string) {
        return this.sendApiRequest(HttpMethodEnum.POST, ReactAppSettings.appUrls.deactivateUser, undefined, id);
    }

    static async reactivateUser(id: string) {
        return this.sendApiRequest(HttpMethodEnum.POST, ReactAppSettings.appUrls.reactivateUser, undefined, id);
    }

    static async deleteUser(id: string, clientId?: string) {
        const data = {
            clientId,
        };

        return this.sendApiRequest(
            HttpMethodEnum.DELETE,
            ReactAppSettings.appUrls.deleteUser,
            data,
            id,
            DataType.QueryString,
        );
    }

    static async getAssignableRoles(partnerId?: string, clientId?: string) {
        const data = {
            partnerId,
            clientId,
        };

        return this.sendApiRequestWithResult<typeof data, IAssignableRoles>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getAssignableRoles,
            data,
            undefined,
            DataType.QueryString,
        );
    }

    static async searchPartnerClientUsers(searchTerm: string, excludedClientId?: string) {
        const data = {
            searchTerm,
            excludedClientId,
        };

        return this.sendApiRequestWithResult<typeof data, SearchAllItemResultModel[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.searchPartnerClientUsers,
            data,
            undefined,
            DataType.QueryString,
        );
    }

    static async addAdminFromExistingUser(id: string, clientId: string, baseRole: Role, additionalRoles: Role[]) {
        return this.sendApiRequestWithResult<any, undefined>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.addAdminFromExistingUser,
            {
                clientId,
                baseRole: Role[baseRole],
                additionalRoles: additionalRoles.map((x) => Role[x]),
            },
            id,
        );
    }

    static async getUserNotificationSettings(id: string) {
        return this.sendApiRequestWithResult<undefined, IUserNotificationSettings>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getUserNotificationSettings,
            undefined,
            id,
        );
    }

    static async updateEmailNotificationSettings(
        id: string,
        settings: Dictionary<NotificationType, EmailNotificationFrequencyEnum>,
    ) {
        return this.sendApiRequestWithResult<Dictionary<NotificationType, EmailNotificationFrequencyEnum>, undefined>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateEmailNotificationSettings,
            settings,
            id,
        );
    }
}
