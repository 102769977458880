import "./ErrorFallback.less";
import { AtButton, AtSubtitle, AtTabCard } from "@atman/design-system";
import { AtLabelledComponent } from "../../AtLabelledComponent";
import { AtLabelledComponentList } from "../../AtLabelledComponentList";
import { IntercomApi, ReactAppSettings } from "@atman/business";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import React from "react";

export interface IErrorFallbackProps {
    error: Error;
    componentStack: string | null;
    eventId: string | null;
    resetError(): void;
}

export const ErrorFallback: React.FC<IErrorFallbackProps> = observer((props) => {
    const { error, eventId, resetError } = props;

    const contactSupportByChat = () => IntercomApi.showNewMessage();

    const contactSupportByEmail = () => {
        const emailWindow = window.open("mailto:support@atmanco.com");
        emailWindow?.close();
    };

    const reloadPage = () => window.location.reload();

    return (
        <AtTabCard className="ErrorFallback" cardTitle={t({ id: "global.errors.browser.unknown" })}>
            <AtSubtitle
                className="main-subtitle"
                subtitle={t({
                    id: "global.errors.browser.subtitle",
                    message:
                        "We are sorry for the inconvenience. If you contact our support team, feel free to provide them with the following information to help them better identity the issue.",
                })}
                size={"xl"}
            />
            <AtLabelledComponentList>
                <AtLabelledComponent label={t({ id: "global.errors.details.labels.eventId", message: "Event ID" })}>
                    {eventId}
                </AtLabelledComponent>
                {error?.name && (
                    <AtLabelledComponent
                        label={t({ id: "global.errors.details.labels.errorName", message: "Error Name" })}
                    >
                        {error.name}
                    </AtLabelledComponent>
                )}
            </AtLabelledComponentList>
            <AtSubtitle
                className="actions-subtitle"
                subtitle={t({
                    id: "global.errors.browser.actions.text",
                    message: "Use the buttons below to contact support, try and clear the error, or reload the page.",
                })}
                size={"xl"}
            />
            <div className="buttons-container">
                {!ReactAppSettings?.viewBag?.baseInfo?.userInfo?.intercomUserBlacklisted && (
                    <AtButton onClick={contactSupportByChat}>
                        {t({ id: "global.errors.browser.buttonLabels.liveChat", message: "Live Chat Support" })}
                    </AtButton>
                )}
                <AtButton onClick={contactSupportByEmail}>
                    {t({ id: "global.errors.browser.buttonLabels.email", message: "Email Support" })}
                </AtButton>
                <AtButton onClick={resetError} color={"secondary"}>
                    {t({ id: "global.errors.browser.buttonLabels.clearError", message: "Clear Error" })}
                </AtButton>
                <AtButton onClick={reloadPage} color={"secondary"}>
                    {t({ id: "global.errors.browser.buttonLabels.reloadPage", message: "Reload Page" })}
                </AtButton>
            </div>
        </AtTabCard>
    );
});
