import { BaseModel, IBaseModel } from "../base/BaseModel";

export interface IShortClient extends IBaseModel {
    readonly name: string;
}

export class ShortClient extends BaseModel implements IShortClient {
    public readonly name: string;

    constructor(json: IShortClient) {
        super(json);

        this.name = json.name;
    }
}
