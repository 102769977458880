import "./index.less";
import * as React from "react";
import { AtButton, AtSelect, IReactSelectOptionObject } from "@atman/design-system";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Label } from "reactstrap";
import { SelectInstance, StylesConfig } from "react-select";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import AtEditableElement from "./components/AtEditableElement";

export interface IEditableElementInfo {
    value: string;
    label: string;
    icon?: IconProp;
}

export interface IAtEditableElementListProps {
    elements: IEditableElementInfo[];
    selectOptions: IReactSelectOptionObject[];
    addAction: (id: string) => void;
    removeAction: (id: string) => void;
    customAddActionLabel?: string;
    listLabel?: string;
}

@inject()
@observer
export class AtEditableElementList extends React.Component<IAtEditableElementListProps, {}> {
    protected readonly autoCompleteFieldRef: React.RefObject<SelectInstance<IReactSelectOptionObject, false>>;
    @observable public showSelectField: boolean = false;

    constructor(props) {
        super(props);

        this.autoCompleteFieldRef = React.createRef();
    }

    @computed get inputStyles(): StylesConfig<IReactSelectOptionObject, false> {
        return {
            control: (base) => ({
                ...base,
                padding: "1px 8px",
                fontSize: 13,
                lineHeight: "16px",
                minHeight: 30,
            }),
            valueContainer: (base) => ({
                ...base,
                padding: "0 8px",
            }),
            dropdownIndicator: (base) => ({
                ...base,
                padding: 0,
            }),
            option: (base) => ({
                ...base,
                fontSize: 13,
            }),
            noOptionsMessage: (base) => ({
                ...base,
                fontSize: 13,
            }),
        };
    }

    @action switchToSelectField = () => {
        this.showSelectField = true;
    };

    @action switchToButton = () => {
        this.showSelectField = false;
    };

    @action addElement = async (option: IReactSelectOptionObject) => {
        const { addAction } = this.props;

        await addAction(option.value);
        this.switchToButton();
    };

    componentDidUpdate(
        prevProps: Readonly<IAtEditableElementListProps>,
        prevState: Readonly<{}>,
        snapshot?: any,
    ): void {
        if (this.showSelectField) {
            this.autoCompleteFieldRef.current?.focus();
            this.autoCompleteFieldRef.current?.onMenuOpen();
        }
    }

    render() {
        const { elements = [], selectOptions, removeAction, customAddActionLabel, listLabel } = this.props;

        return (
            <div className="AtEditableElementList">
                {listLabel && <Label>{listLabel}</Label>}
                <div className="elements-list">
                    {elements?.map((elementInfos, i) => (
                        <AtEditableElement
                            key={`editable-element-${i}`}
                            {...elementInfos}
                            removeAction={removeAction}
                        />
                    ))}
                    <div className="add-container">
                        {this.showSelectField ? (
                            <AtSelect
                                options={selectOptions}
                                value={null}
                                onChange={this.addElement}
                                innerRef={this.autoCompleteFieldRef}
                                onBlur={this.switchToButton}
                                additionalStyles={this.inputStyles}
                            />
                        ) : (
                            <AtButton
                                icon={["far", "plus"]}
                                iconProps={{ highlightIcon: true }}
                                onClick={this.switchToSelectField}
                                color={"secondary"}
                                size={"md"}
                            >
                                {customAddActionLabel ?? "global.add".localize()}
                            </AtButton>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
