import "./ActionPlanTablesRow.less";
import * as React from "react";

import { AtCollapse, AtIcon } from "@atman/design-system";
import {
    CompDevSubCompetenciesStrategicObjectiveResult,
    CompetencyDevelopmentLeveragingObjectiveType,
    IObjectiveWithCompetency,
    PrintModeEnum,
} from "@atman/business";
import { CompetencyDevelopmentActionPlanColumnType, isActionPlanColumnDisplayed } from "../../ResultTableContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IApplicationLeveragePlanAction } from "@atman/business/lib/models/IApplicationLeveragePlanAction";
import { t } from "@lingui/macro";

export type ActionPlanObjectiveType = "leverage" | "improve";

export interface IActionPlanTablesRowProps {
    columns: CompetencyDevelopmentActionPlanColumnType[];
    type: ActionPlanObjectiveType;
    objective?: IObjectiveWithCompetency<CompDevSubCompetenciesStrategicObjectiveResult>;
    application?: IApplicationLeveragePlanAction;
    children: JSX.Element;
    printMode?: PrintModeEnum;
}

export const ActionPlanTablesRow: React.FC<IActionPlanTablesRowProps> = (props) => {
    const { columns, type, objective, application, children, printMode } = props;

    return (
        <div className="ActionPlanTablesRow">
            <AtCollapse
                className="actionPlanCollapseRow"
                renderChevron={false}
                toggleRowSizeHorizontal="md"
                defaultOpen={printMode !== undefined}
                toggleRowContent={
                    <div className="actionPlanCollapseRow-content">
                        {type === "improve" ? (
                            isActionPlanColumnDisplayed("objective", columns) && (
                                <div className="icon-desc-row-section">
                                    <AtIcon icon={["far", "check-circle"]} color="#D6D6D6" />
                                    {objective?.data.description}
                                </div>
                            )
                        ) : (
                            <div className="icon-desc-row-section">
                                <AtIcon icon={["far", "check-circle"]} color="#D6D6D6" />
                                {application?.description}
                            </div>
                        )}

                        {type === "improve"
                            ? isActionPlanColumnDisplayed("competencies", columns) && (
                                  <div className="actionPlan-tablesRow-competency">{objective?.competency.name}</div>
                              )
                            : isActionPlanColumnDisplayed("competencies", columns) && (
                                  <div className="actionPlan-tablesRow-competency">{application?.competency.name}</div>
                              )}
                        {type === "improve"
                            ? isActionPlanColumnDisplayed("subCompetencies", columns) && (
                                  <div className="actionPlan-tablesRow-subComp-type">
                                      {objective?.subCompetency?.name}
                                  </div>
                              )
                            : isActionPlanColumnDisplayed("type", columns) && (
                                  <div className="actionPlan-tablesRow-subComp-type">
                                      {application?.strategicObjective.type !== undefined &&
                                      application?.strategicObjective.type !== null
                                          ? t({
                                                id: `competencyDevelopment.objectiveType.${CompetencyDevelopmentLeveragingObjectiveType[
                                                    application.strategicObjective.type
                                                ].toCamel()}`,
                                            })
                                          : t({ id: "competencyDevelopment.global.objective" })}
                                  </div>
                              )}

                        {type === "improve"
                            ? isActionPlanColumnDisplayed("actionsCount", columns) && (
                                  <div className="actionPlan-tablesRow-actionsCount">
                                      <FontAwesomeIcon icon={["fas", "bolt"]} color="#D6D6D6" />
                                      {`${objective?.data.applications.length}`}
                                  </div>
                              )
                            : null}

                        {isActionPlanColumnDisplayed("iconCol", columns) && (
                            <div className="actionPlan-tablesRow-iconCol">
                                <FontAwesomeIcon icon={["far", "chevron-down"]} />
                            </div>
                        )}
                    </div>
                }
                collapseContentOffset={printMode === undefined}
            >
                {children}
            </AtCollapse>
        </div>
    );
};
