import "./index.less";
import * as React from "react";
import { AppContextInfo } from "../../../AppContextInfo";
import {
    AppScope,
    ErrorHandler,
    GlobalStores,
    IAppContext,
    ItemType,
    ReactAppSettings,
    Role,
    SearchAllItemResultModel,
    SearchApi,
    UserInfoStore,
} from "@atman/business";
import { BaseForm } from "../../../BaseForm";
import { CustomTextInput } from "../../../CustomTextInput";
import { Label, ModalBody } from "reactstrap";
import { LoadingIndicator } from "@atman/design-system";
import { SearchResultEntry } from "../SearchResultEntry";
import { action, computed, observable, reaction, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import { itemTypeToAppContextSearchScopeMap } from "@atman/business/lib/libs/mappers/AppContextSearchScopeToItemType";
import { withAppContext } from "../../../../contexts";

export type SwitchAppSearchFilter = ItemType.Client | ItemType.Partner;

export interface ISwitchAppModalBodyProps extends IAppContext {
    userInfoStore?: UserInfoStore;
}

@inject(GlobalStores.userInfoStore)
@observer
class SwitchAppModalBody extends BaseForm<ISwitchAppModalBodyProps, {}> {
    @observable private searchString: string = "";
    @observable private currentSearchFilter: SwitchAppSearchFilter = ItemType.Client;
    @observable private typingTimeout?: number;
    @observable private suggestions: SearchAllItemResultModel[] = [];

    constructor(props) {
        super(props);

        reaction(
            () => this.searchString,
            () => this.loadSuggestions(),
        );

        reaction(
            () => this.currentSearchFilter,
            () => this.loadSuggestions(),
        );
    }

    componentDidMount() {
        this.loadSuggestions();
    }

    @computed private get availableSearchFilters(): SwitchAppSearchFilter[] {
        const { userInfoStore } = this.props;

        if (userInfoStore!.hasRole(Role.AdminSupplier)) {
            return [ItemType.Client, ItemType.Partner];
        }

        return [ItemType.Client];
    }

    @action private loadSuggestions = () => {
        this.isLoading = true;

        try {
            if (this.typingTimeout) {
                window.clearTimeout(this.typingTimeout);
            }

            this.typingTimeout = window.setTimeout(
                async () => {
                    const result = await SearchApi.getAvailableAppContexts(
                        itemTypeToAppContextSearchScopeMap.get(this.currentSearchFilter)!,
                        this.searchString,
                    );

                    runInAction(() => {
                        this.suggestions = result;
                        this.isLoading = false;
                    });
                },
                this.searchString.length > 0 ? 200 : 0,
            );
        } catch (e) {
            this.isLoading = false;

            ErrorHandler.handleError(e);
        }
    };

    @action private onFilterChange = (e: React.SyntheticEvent<HTMLDivElement>, filterValue: SwitchAppSearchFilter) => {
        e.preventDefault();

        this.currentSearchFilter = filterValue;
    };

    render() {
        const { userInfoStore, scope } = this.props;

        let backToRootLabel: string = "";
        let backToRootHref: string = "";
        let shouldShowBackToRoot: boolean = false;

        if (userInfoStore!.hasRole(Role.AdminSupplier)) {
            backToRootLabel = "global.backToSupplier".localize();
            backToRootHref = ReactAppSettings.getSupplierAppBaseUrl(userInfoStore!.supplierId);
            shouldShowBackToRoot = scope !== AppScope.Supplier;
        } else if (userInfoStore!.hasOneOfRoles([Role.AdminPartner, Role.UserPartner, Role.AdminClientDelegated])) {
            backToRootLabel = "global.backToPartner".localize();
            backToRootHref = ReactAppSettings.getPartnerAppBaseUrl(userInfoStore!.partnerId);
            shouldShowBackToRoot = scope !== AppScope.Partner;
        }

        return (
            <ModalBody id="SwitchAppModalBody">
                <div className="header-section">
                    <div className="connected-to-container">
                        <Label>{"global.currentlyConnectedTo".localize()}</Label>
                        <div className={"context-info-container"}>
                            <AppContextInfo />
                            {shouldShowBackToRoot && <a href={backToRootHref}>{backToRootLabel}</a>}
                        </div>
                    </div>
                    <div className="search-input-container">
                        <CustomTextInput
                            leftIcon={["far", "search"]}
                            fieldName={"searchString"}
                            value={this.searchString}
                            onChange={this.onTextFieldChange}
                            placeholder={"global.search".localize()}
                            hideLabel
                        />
                    </div>
                    <div className="filters-container">
                        {this.availableSearchFilters.map((x) => (
                            <div
                                key={`switch-app-modal-filter-${x}`}
                                className="filter-element"
                                onClick={(e) => this.onFilterChange(e, x)}
                            >
                                {`global.itemTypes.${ItemType[x].toCamel()}`.localize()}
                                <div className={`active-indicator ${this.currentSearchFilter === x ? "active" : ""}`} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="search-results-section">
                    {this.isLoading ? (
                        <div className={"loading-indicator-container"}>
                            <LoadingIndicator />
                        </div>
                    ) : (
                        this.suggestions.map((x) => <SearchResultEntry item={x} key={x.id} inSwitchAppContext />)
                    )}
                </div>
            </ModalBody>
        );
    }
}

export default withAppContext(SwitchAppModalBody);
