import { BaseApi } from "../base";
import { CompetencyGroupEnum, CompetencyProfile, HttpMethodEnum } from "../types";
import {
    IAssessmentResultScore,
    IAtmanAssessmentDocumentStructure,
    ICultureFitRecommendation,
    IDistributionData,
    IInterpretationData,
    IJobFitRecommendation,
    ScoreType,
} from "../models";
import { ReactAppSettings } from "../libs";

export class AssessmentsResultsApi extends BaseApi {
    // MARK: Distribution Calls
    public static async getDistributionData(assessmentIds: string[]): Promise<IDistributionData> {
        const data = { assessmentIds };

        return await this.sendApiRequestWithResult<typeof data, IDistributionData>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.getDistributionData,
            data,
        );
    }

    public static async getCompetencyProfileScores(
        assessmentIds: string[],
        competencyProfile?: CompetencyProfile,
        competencyGroup?: CompetencyGroupEnum,
    ): Promise<Dictionary<string, IAssessmentResultScore>> {
        const data = {
            competencyProfile,
            competencyGroup,
            assessmentIds,
        };

        return await this.sendApiRequestWithResult<typeof data, Dictionary<string, IAssessmentResultScore>>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.getCompetencyProfileScores,
            data,
        );
    }

    // MARK: JobFit Calls
    public static async getJobFitRecommendation(
        jobFitId: string,
        assessmentId: string,
    ): Promise<IJobFitRecommendation> {
        const data = {
            jobFitId,
            assessmentId,
        };

        return await this.sendApiRequestWithResult<typeof data, IJobFitRecommendation>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getJobFitRecommendation,
            data,
        );
    }

    public static async getJobFitScores(
        jobFitId: string,
        assessmentIds: string[],
    ): Promise<Dictionary<string, IAssessmentResultScore>> {
        const data = {
            jobFitId,
            assessmentIds,
        };

        return await this.sendApiRequestWithResult<typeof data, Dictionary<string, IAssessmentResultScore>>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.getJobFitScores,
            data,
        );
    }

    // MARK: CultureFit Calls
    public static async getCultureFitRecommendation(
        cultureFitId: string,
        assessmentId: string,
    ): Promise<ICultureFitRecommendation> {
        const data = {
            cultureFitId,
            assessmentId,
        };

        return await this.sendApiRequestWithResult<typeof data, ICultureFitRecommendation>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCultureFitRecommendation,
            data,
        );
    }

    public static async getCultureFitScores(
        cultureFitId: string,
        assessmentIds: string[],
    ): Promise<Dictionary<string, IAssessmentResultScore>> {
        const data = {
            cultureFitId,
            assessmentIds,
        };

        return await this.sendApiRequestWithResult<typeof data, Dictionary<string, IAssessmentResultScore>>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.getCultureFitScores,
            data,
        );
    }

    // MARK: Potential Calls
    public static async getPotentialRecommendation(
        potentialId: string,
        assessmentIds: string[],
    ): Promise<Dictionary<string, ICultureFitRecommendation>> {
        const data = {
            potentialId,
            assessmentIds,
        };

        return await this.sendApiRequestWithResult<typeof data, Dictionary<string, ICultureFitRecommendation>>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getPotentialRecommendation,
            data,
        );
    }

    public static async getPotentialScores(
        potentialId: string,
        assessmentIds: string[],
    ): Promise<Dictionary<string, IAssessmentResultScore>> {
        const data = {
            potentialId,
            assessmentIds,
        };

        return await this.sendApiRequestWithResult<typeof data, Dictionary<string, IAssessmentResultScore>>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.getPotentialScores,
            data,
        );
    }

    // MARK: Manager Fit / Compatibility Fit Calls
    public static async getCompatibilityFitScores(
        comparedAssessmentId: string,
        assessmentIds: string[],
    ): Promise<Dictionary<string, IAssessmentResultScore>> {
        const data = {
            comparedAssessmentId,
            assessmentIds,
        };

        return await this.sendApiRequestWithResult<typeof data, Dictionary<string, IAssessmentResultScore>>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.getCompatibilityFitScores,
            data,
        );
    }

    public static async getCompatibilityFitResult(
        assessmentId: string,
        otherAssessmentId: string,
    ): Promise<IAtmanAssessmentDocumentStructure> {
        const data = {
            assessmentId,
            otherAssessmentId,
        };

        return await this.sendApiRequestWithResult<typeof data, IAtmanAssessmentDocumentStructure>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getAtCompatibilityFitResult,
            data,
        );
    }

    // MARK: Assessments Results Data Calls
    public static async getInterpretationData(assessmentId: string): Promise<Dictionary<string, IInterpretationData>> {
        const data = {
            assessmentId,
        };

        return await this.sendApiRequestWithResult<typeof data, Dictionary<string, IInterpretationData>>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getInterpretationData,
            data,
        );
    }

    public static async getRawScores(assessmentIds: string[]): Promise<Dictionary<string, Dictionary<string, number>>> {
        const data = { assessmentIds };

        return await this.sendApiRequestWithResult<typeof data, Dictionary<string, Dictionary<string, number>>>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.getRawScores,
            data,
        );
    }
}

export class AssessmentsResultsApiMock {
    private static _dimensionAndCompetencyKeysToPopulate = [
        "numerique",
        "deduction",
        "verbal",
        "analogique",
        "direction",
        "spatial",
        "aptitudeCognitiveGenerale",
        "conceptuel",
        "confort",
        "tradition",
        "distanceEmotionnelle",
        "organisation",
        "engagement",
        "competition",
        "defis",
        "affirmation",
        "droiture",
        "relationsSociales",
        "exuberance",
        "resistancePhysique",
        "toleranceEmotionnelle",
        "endurance",
        "desirabiliteSociale",
        "support",
        "risques",
        "performance",
        "valorisationMonetaire",
        "developpementProfessionnel",
        "innovation",
        "liberte",
        "prestige",
        "equilibre",
        "teletravail",
        "commission",
        "social",
        "ecologique",
        "developpementDurable",
        "capaciteConclureVente",
        "liberteVente",
        "supportVente",
        "performanceVente",
        "developpementProfessionnelVente",
        "equilibreVente",
        "risqueVente",
        "valorisationMonetaireVente",
        "commissionVente",
        "adaptabiliteGestion",
        "adaptabiliteVente",
        "ambitionVente",
        "analyseEtTraitementInformationVente",
        "appelsDeProspectionVente",
        "capaciteInfluenceGestion",
        "capaciteNegociationGestion",
        "capaciteNegociationVente",
        "collaborationAvecEquipeVente",
        "communicationInterpersonnelleGestion",
        "communicationInterpersonnelleVente",
        "confianceEnSoiVente",
        "courageManagerialGestion",
        "creativiteGestion",
        "creativiteVente",
        "efficaciteReseautageVente",
        "ethiqueVente",
        "gestionConflitsGestion",
        "gestionInnovationGestion",
        "gestionStressGestion",
        "gestionStressVente",
        "mobilisationEquipeGestion",
        "obtenirInformationVente",
        "organisationEtDocumentationDuCRMVente",
        "orientationVersActionGestion",
        "orientationVersActionVente",
        "orientationVersResultatsGestion",
        "orientationVersResultatsVente",
        "ouvertureAuCoachingVente",
        "perseveranceVente",
        "planificationStrategiqueVente",
        "priorisationDesVentes",
        "rigueurEtRespectDesProceduresVente",
        "sensPolitiqueVente",
        "serviceClienteleVente",
        "suiviDesClientsVente",
        "visionStrategiqueGestion",
        "visionStrategiqueVente",
        "planificationStrategiqueGestion",
        "ethiqueGestion",
        "rigueurGestion",
        "ambitionGestion",
        "perseveranceGestion",
        "confianceEnSoiGestion",
        "efficaciteEnReseautageGestion",
        "sensPolitiqueGestion",
        "ouvertureAuCoachingGestion",
        "resolutionDeProblemesGestion",
        "toleranceAmbiguiteGestion",
        "organisationSuiviGestion",
        "gestionPrioritesGestion",
        "imputabiliteGestion",
        "entrepreneurshipGestion",
        "priseDecisionGestion",
        "leadershipequipeGestion",
        "delegationGestion",
        "capaciteInformerGestion",
        "developpementpotentielGestion",
        "visionStrategiqueProfessionnel",
        "resolutionProblemesProfessionnel",
        "obtentionInformationProfessionnel",
        "analyseInformationProfessionnel",
        "toleranceAmbiguiteProfessionnel",
        "creativiteProfessionnel",
        "adaptabiliteProfessionnel",
        "planificationStrategiqueProfessionnel",
        "gestionProjetsProfessionnel",
        "organisationSuiviProfessionnel",
        "gestionPrioritesProfessionnel",
        "rigueurProfessionnel",
        "ethiqueProfessionnel",
        "imputabiliteProfessionnel",
        "efficaciteDistanceProfessionnel",
        "ambitionProfessionnel",
        "perseveranceProfessionnel",
        "orientationActionProfessionnel",
        "orientationResultatsProfessionnel",
        "confianceSoiProfessionnel",
        "gestionStressProfessionnel",
        "ouvertureCoachingProfessionnel",
        "sensPolitiqueProfessionnel",
        "capaciteInfluenceProfessionnel",
        "capaciteInformerProfessionnel",
        "aisanceReseautageProfessionnel",
        "communicationInterpersonnelleProfessionnel",
        "serviceClienteleProfessionnel",
        "travailEquipeProfessionnel",
        "presentationGroupesProfessionnel",
        "adaptabiliteGestionConfort",
        "adaptabiliteGestionTradition",
        "souciDuDetailGestion",
        "reglesEtProceduresGestion",
        "controleConformiteGestion",
        "gestionStressEtEmotionsGestion",
        "comprehensionDesAutresGestion",
        "maitriseDeSoiGestion",
        "analyseProduitsEtClientsVente",
        "gestionStressEtEmotionsVente",
        "comprehensionDesAutresVentes",
        "maitriseDeSoiVente",
        "apprentissageProfessionnel",
        "souciDuDetailProfessionnel",
        "reglesEtProceduresProfessionnel",
        "controleConformiteProfessionnel",
        "gestionStressEtEmotionsProfessionnel",
        "comprehensionDesAutresProfessionnel",
        "maitriseDeSoiProfessionnel",
        "resolutionDeProblemesTechnicien",
        "obtenirInformationTechnicien",
        "analyseInformationTechnicien",
        "apprentissageTechnicien",
        "toleranceAmbiguiteTechnicien",
        "adaptabiliteTechnicien",
        "gestionDeProjetTechnicien",
        "organisationEtSuiviTechnicien",
        "gestionDesPrioritesTechnicien",
        "souciDuDetailTechnicien",
        "reglesEtProceduresTechnicien",
        "fiabiliteTechnicien",
        "imputabiliteTechnicien",
        "evaluationConformiteTechnicien",
        "efficaciteADistanceTechnicien",
        "perseveranceTechnicien",
        "orientationVersActionTechnicien",
        "orientationVersResultatsTechnicien",
        "confianceEnSoiTechnicien",
        "gestionDuStressEtEmotionsTechnicien",
        "ouvertureAuCoachingTechnicien",
        "capaciteInfluenceTechnicien",
        "capaciteInformerTechnicien",
        "comprehensionDesAutresTechnicien",
        "maitriseDeSoiTechnicien",
        "aisanceAvecPublicTechnicien",
        "communicationInterpersonnelleTechnicien",
        "serviceClienteleTechnicien",
        "travailEquipeTechnicien",
        "visionStrategiqueGenerique",
        "resolutionProblemesGenerique",
        "obtentionInformationGenerique",
        "analyseInformationGenerique",
        "apprentissageGenerique",
        "toleranceAmbiguiteGenerique",
        "creativiteGenerique",
        "adaptabiliteGenerique",
        "planificationStrategiqueGenerique",
        "gestionDeProjetGenerique",
        "organisationEtSuiviGenerique",
        "gestionDesPrioritesGenerique",
        "souciDuDetailGenerique",
        "reglesEtProceduresGenerique",
        "ethiqueGenerique",
        "fiabiliteGenerique",
        "imputabiliteGenerique",
        "evaluationConformiteGenerique",
        "controleConformiteGenerique",
        "efficaciteADistanceGenerique",
        "ambitionGenerique",
        "perseveranceGenerique",
        "entrepreneurshipGenerique",
        "orientationVersActionGenerique",
        "orientationVersResultatsGeneriques",
        "confianceEnSoiGenerique",
        "gestionStressEtEmotionsGenerique",
        "ouvertureAuCoachingGenerique",
        "sensPolitiqueGenerique",
        "courageManagerialGenerique",
        "capaciteInfluenceGenerique",
        "priseDecisionGenerique",
        "appelsProspectionGenerique",
        "capaciteNegociationGenerique",
        "gestionInnovationGenerique",
        "leadershipEquipeGenerique",
        "delegationGenerique",
        "capaciteInformerGenerique",
        "comprehensionDesAutresGenerique",
        "matriseDeSoiGenerique",
        "efficaciteEnReseautageGenerique",
        "aisanceEnReseautageGenerique",
        "aisanceAvecPublicGenerique",
        "communicationInterpersonnelleGenerique",
        "serviceClienteleGenerique",
        "mobilisationEquipeGenerique",
        "developpmentPotentielGenerique",
        "gestionConflitsGenerique",
        "travailEquipeGenerique",
        "presentationGroupesGenerique",
    ];

    private static getRandomIntFromRange = (min: number, max: number) => {
        return Math.floor(Math.random() * (Math.floor(max + 1) - Math.ceil(min))) + 1;
    };

    private static getRandomStanineAverage = () => {
        return Math.floor(Math.random() * (90 - 10) + 10) / 10;
    };

    private static getRandomPercentage = () => {
        return AssessmentsResultsApiMock.getRandomIntFromRange(0, 99);
    };

    public static async getDistributionData(assessmentIds: string[]): Promise<IDistributionData> {
        return new Promise((resolve, reject) => {
            process.nextTick(() => {
                const getRandomIntFrom1To9 = () => Math.floor(Math.random() * (Math.floor(10) - Math.ceil(1))) + 1;

                const jsonData = {
                    availableCompetencyProfiles: [
                        CompetencyProfile.Technician,
                        CompetencyProfile.Professional,
                        CompetencyProfile.Sales,
                        CompetencyProfile.Management,
                        CompetencyProfile.Generic,
                    ],
                    nbCompletedTests: assessmentIds.length,
                    nbRequestedTests: assessmentIds.length,
                    nbTestsNotRequired: 0,
                    dimensionsAverageDict: {},
                    dimensionsDict: {},
                } as IDistributionData;

                const baseDistributionDataSet = [
                    {
                        scoreKey: 1,
                        count: 1,
                        isRange: true,
                        assessmentIds: [assessmentIds[0]],
                    },
                    {
                        scoreKey: 2,
                        count: 1,
                        isRange: true,
                        assessmentIds: [assessmentIds[2]],
                    },
                    {
                        scoreKey: 3,
                        count: 2,
                        isRange: true,
                        assessmentIds: [assessmentIds[3], assessmentIds[4]],
                    },
                    {
                        scoreKey: 4,
                        count: 1,
                        isRange: false,
                        assessmentIds: [assessmentIds[5]],
                    },
                    {
                        scoreKey: 5,
                        count: 1,
                        isRange: false,
                        assessmentIds: [assessmentIds[6]],
                    },
                    {
                        scoreKey: 6,
                        count: 0,
                        isRange: false,
                        assessmentIds: [],
                    },
                    {
                        scoreKey: 7,
                        count: 0,
                        isRange: false,
                        assessmentIds: [],
                    },
                    {
                        scoreKey: 8,
                        count: 0,
                        isRange: false,
                        assessmentIds: [],
                    },
                    {
                        scoreKey: 9,
                        count: 1,
                        isRange: false,
                        assessmentIds: [assessmentIds[7]],
                    },
                ];

                for (const key of this._dimensionAndCompetencyKeysToPopulate) {
                    jsonData.dimensionsAverageDict[key] = getRandomIntFrom1To9();
                    jsonData.dimensionsDict[key] = baseDistributionDataSet;
                }

                resolve(jsonData);
            });
        });
    }

    public static async getCompetencyProfileScores(
        competencyProfile: CompetencyProfile,
        assessmentIds: string[],
    ): Promise<Dictionary<string, number>> {
        return new Promise((resolve, reject) => {
            process.nextTick(() => {
                const jsonData = {};

                assessmentIds.forEach((x) => {
                    jsonData[x] = this.getRandomStanineAverage();
                });

                resolve(jsonData);
            });
        });
    }

    public static async getJobFitRecommendation(jobFitId: string, assessmentId: string) {
        // : Promise<IJobFitRecommendation> {
    }

    public static async getJobFitScores(
        jobFitId: string,
        assessmentIds: string[],
    ): Promise<Dictionary<string, IAssessmentResultScore>> {
        return new Promise((resolve, reject) => {
            process.nextTick(() => {
                const jsonData = {};

                assessmentIds.forEach((x) => {
                    jsonData[x] = {
                        score: this.getRandomPercentage(),
                        penaltiesApplied: [],
                        scoreType: ScoreType.Percentage,
                    };
                });

                resolve(jsonData);
            });
        });
    }

    public static async getCultureFitRecommendation(cultureFitId: string, assessmentId: string) {
        // : Promise<ICultureFitRecommendation> {
    }

    public static async getCultureFitScores(
        cultureFitId: string,
        assessmentIds: string[],
    ): Promise<Dictionary<string, number>> {
        return new Promise((resolve, reject) => {
            process.nextTick(() => {
                const jsonData = {};

                assessmentIds.forEach((x) => {
                    jsonData[x] = this.getRandomPercentage();
                });

                resolve(jsonData);
            });
        });
    }

    public static async getPotentialRecommendation(potentialId: string, assessmentId: string) {
        // : Promise<ICultureFitRecommendation> {
    }

    public static async getPotentialScores(
        potentialId: string,
        assessmentIds: string[],
    ): Promise<Dictionary<string, number>> {
        return new Promise((resolve, reject) => {
            process.nextTick(() => {
                const jsonData = {};

                assessmentIds.forEach((x) => {
                    jsonData[x] = this.getRandomPercentage();
                });

                resolve(jsonData);
            });
        });
    }

    public static async getCompatibilityFitScores(
        comparedAssessmentId: string,
        assessmentIds: string[],
    ): Promise<Dictionary<string, number>> {
        return new Promise((resolve, reject) => {
            process.nextTick(() => {
                const jsonData = {};

                assessmentIds.forEach((x) => {
                    jsonData[x] = this.getRandomPercentage();
                });

                resolve(jsonData);
            });
        });
    }

    public static async getInterpretationData(
        assessmentId: string,
        languageCode: string,
    ): Promise<Dictionary<string, IInterpretationData>> {
        return new Promise((resolve, reject) => {
            process.nextTick(() => {
                const jsonData: Dictionary<string, IInterpretationData> = {};

                this._dimensionAndCompetencyKeysToPopulate.forEach((x) => {
                    jsonData[x] = {
                        interpretation:
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus rhoncus augue nibh, ut convallis nisl consectetur ut. Quisque sollicitudin diam eget enim ullamcorper, in faucibus nibh ultrices. Phasellus lacus nulla, gravida vitae purus nec, tincidunt mattis mauris. Proin porta justo a purus porta dapibus. Fusce est lacus, sagittis non turpis in, malesuada sagittis eros.",
                        advices: [
                            "Donec a porttitor urna. Duis volutpat tincidunt ex, a dictum diam elementum in. Integer laoreet risus ac lectus interdum semper.",
                            "Nam et ligula tempor, interdum nibh a, eleifend nisl. In porttitor erat quis ante porta viverra. Donec in auctor diam. In hac habitasse platea dictumst.",
                        ],
                        elements: [
                            "Cras volutpat massa at lectus congue, et tincidunt neque consectetur. Vivamus malesuada auctor sem, eu finibus nisi luctus vitae.",
                            "Nulla vehicula bibendum consequat. Vestibulum massa libero, elementum facilisis mattis non, placerat sed sem. Nam vitae volutpat dui. Morbi sed sem ut elit faucibus consequat.",
                        ],
                        pros: [
                            "Curabitur a ante lacinia, ornare neque vitae, lacinia massa. Nunc convallis eu massa a faucibus. Mauris gravida eget elit in luctus.",
                            "Donec varius, ante sodales aliquet pulvinar, nisl urna venenatis massa, eget consequat quam dui a mi. Donec et velit aliquam, gravida lectus ac, porttitor magna.",
                        ],
                    };
                });

                resolve(jsonData);
            });
        });
    }
}
