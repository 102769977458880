import "./EntityInformation.less";
import * as React from "react";
import { AppScope, Client, Partner, UnhandledScopeError } from "@atman/business";
import { ClientAccountInformationForm } from "./components/ClientAccountInformationForm";
import { EntityNameForm } from "../../../components";
import { PartnerAccountInformationForm } from "./components/PartnerAccountInformationForm";
import { observer } from "mobx-react";
import { useAppContext } from "../../../contexts";

export interface IEntityInformationProps {
    entity: Client | Partner;
}

export const EntityInformation: React.FC<IEntityInformationProps> = observer((props) => {
    const { entity } = props;

    const { scope } = useAppContext();

    const renderContent = () => {
        switch (scope) {
            case AppScope.Partner:
                return <ClientAccountInformationForm entity={entity as Client} />;
            case AppScope.Supplier:
                return <PartnerAccountInformationForm entity={entity as Partner} />;
            default:
                throw new UnhandledScopeError(scope);
        }
    };

    return (
        <div id="EntityInformation">
            <EntityNameForm entity={entity} />
            {renderContent()}
        </div>
    );
});
