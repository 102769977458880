import "./index.less";
import * as React from "react";
import { CustomInput, Label } from "reactstrap";

export interface IExpandableRadioInputProps {
    internalKey: string;
    value: any;
    checked: boolean;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    labelKey?: string;
    customLabelContent?: React.ReactNode;
    className?: string;
    inputId?: string;
    disabledExpand?: boolean;
}

export const ExpandableRadioInput: React.FC<IExpandableRadioInputProps> = (props) => {
    const {
        internalKey,
        labelKey,
        value,
        checked,
        onChange,
        className,
        inputId,
        customLabelContent,
        disabledExpand,
        children,
    } = props;

    return (
        <div className={`ExpandableRadioInput ${checked ? "active" : ""} ${className ?? ""}`}>
            <Label className={"input-label"}>
                <CustomInput
                    type={"radio"}
                    name={internalKey}
                    id={inputId || internalKey}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                />
                {labelKey ? <span className="label">{labelKey.localize()}</span> : customLabelContent}
            </Label>
            {checked && children && !disabledExpand ? <div className="content">{children}</div> : null}
        </div>
    );
};
