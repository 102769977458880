import "./index.less";
import { AtTable, AtTableFilterForExternal, CustomHandleFilter, IAtTableProps } from "../../molecules/AtTable";
import { AtTableLoader } from "../../atoms/AtTableBase/component/parts/AtTableLoader";
import { AtTableSortOrder, CustomHandleSort, IAtTableBaseProps } from "../../atoms/AtTableBase";
import { IAppliedFilter } from "../../molecules/AtTable/component/filtering";
import { IBaseEntity, useDebounce } from "@atman/core";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";

export type LoadDataWithOffset<T> = (
    start: number,
    end: number,
    filters?: AtTableFilterForExternal<T>[],
    sort?: { sortKey: keyof T; sortOrder: AtTableSortOrder } | undefined,
) => Promise<T[]>;

export interface IAtTableInfiniteProps<T extends IBaseEntity>
    extends Omit<IAtTableBaseProps<T>, "itemKeyTypes" | "items">,
        Pick<IAtTableProps<T>, "showFilters"> {
    loadDataWithOffset: LoadDataWithOffset<T>;
    fetchSize: number;
    clearFilters?: () => void;
    externalyDefinedFilter?: AtTableFilterForExternal<T>[];
}

function AtTableInfiniteRaw<T extends IBaseEntity>(props: IAtTableInfiniteProps<T>) {
    const { loadDataWithOffset, fetchSize = 60, showSearchBar, externalyDefinedFilter, rowKey, ...otherProps } = props;

    const [isAtBottom, setIsAtBottom] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isNewLinesLoading, setIsNewLinesLoading] = useState(true);
    const [isBackendResultEmpty, setIsBackendResultEmpty] = useState(false);
    const [watchScroll, setWatchScroll] = useState(true);

    const [previousNumberOfAlreadyLoaded, setPreviousNumberOfAlreadyLoaded] = useState(0);
    const [numberOfStepsLoaded, setNumberOfStepsLoaded] = useState(0);
    const [displayedItems, setDisplayItems] = useState<T[]>([]);

    const [appliedFilters, setAppliedFilters] =
        useState<AtTableFilterForExternal<T>[] | undefined>(externalyDefinedFilter);
    const [sortKey, setSortKey] = useState<keyof T | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<AtTableSortOrder | undefined>(undefined);

    useEffect(() => {
        if (externalyDefinedFilter) {
            setAppliedFilters(externalyDefinedFilter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const debounceTime: number = useMemo(() => {
        if (appliedFilters && appliedFilters.length) {
            if (appliedFilters[0].isFromSearchBar) {
                return 800;
            }
        }

        return 0;
    }, [appliedFilters]);

    const debouncedSearchTerm: AtTableFilterForExternal<T>[] | undefined = useDebounce<
        AtTableFilterForExternal<T>[] | undefined
    >(appliedFilters ?? undefined, debounceTime);

    useEffect(() => {
        handleScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const numberOfAlreadyLoaded = useMemo(() => {
        return numberOfStepsLoaded * fetchSize;
    }, [fetchSize, numberOfStepsLoaded]);

    const resetLoad = () => {
        setIsLoading(false);
        setIsNewLinesLoading(false);
    };

    const loadData = async (from?: number, to?: number) => {
        // Force reload after filters removal

        if (from !== undefined && to !== undefined) {
            setIsLoading(true);

            const newItems = await loadDataWithOffset(from, to);

            if (newItems.length > 0) {
                setDisplayItems(newItems);
            }

            resetLoad();
        } else {
            setPreviousNumberOfAlreadyLoaded(displayedItems.length);

            if (numberOfStepsLoaded === 0 || previousNumberOfAlreadyLoaded !== displayedItems.length) {
                if (numberOfStepsLoaded === 0) {
                    setIsLoading(true);
                } else {
                    setIsNewLinesLoading(true);
                }

                const start = numberOfStepsLoaded === 0 ? 0 : numberOfAlreadyLoaded;
                const newItems = await loadDataWithOffset(start, fetchSize, appliedFilters);

                setDisplayItems((previousFilteredItems) => [...previousFilteredItems, ...newItems]);
                setNumberOfStepsLoaded((previousNumberOfSteps) => previousNumberOfSteps + 1);

                resetLoad();
            }
        }
    };

    const refreshDataAsync = async () => {
        setWatchScroll(false);
        setNumberOfStepsLoaded(0);
        setDisplayItems([]);

        setNumberOfStepsLoaded(1);
        setWatchScroll(true);
    };

    useEffect(() => {
        const getItems = async (
            appliedFilters: AtTableFilterForExternal<T>[] | undefined,
            sortOrderForExternal:
                | {
                      sortKey: keyof T;
                      sortOrder: AtTableSortOrder;
                  }
                | undefined,
        ) => {
            const newItems = await loadDataWithOffset(0, numberOfAlreadyLoaded, appliedFilters, sortOrderForExternal);
            if (newItems.length > 0) {
                setIsBackendResultEmpty(false);
            } else {
                setIsBackendResultEmpty(true);
            }

            setDisplayItems(newItems);
            resetLoad();
        };
        if (numberOfStepsLoaded > 0) {
            setIsLoading(true);

            const sortOrderForExternal = sortKey && sortOrder ? { sortKey, sortOrder } : undefined;

            if (debouncedSearchTerm || sortOrderForExternal) {
                getItems(debouncedSearchTerm, sortOrderForExternal);
            } else {
                if (isBackendResultEmpty) {
                    resetLoad();
                    return;
                }
                loadData(0, numberOfAlreadyLoaded);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm, sortKey, sortOrder]);

    const handleScroll = async (e?: any) => {
        if (!watchScroll) {
            return;
        }

        const bottom = e !== undefined && e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50;

        if (bottom || e === undefined) {
            setIsAtBottom(true);
            await loadData();
        } else {
            if (isAtBottom) {
                setIsAtBottom(false);
            }

            if (isLoading) {
                resetLoad();
            }
        }
    };

    const customHandleFilter: CustomHandleFilter<T> = (filters) => {
        setAppliedFilters(filters);
    };

    const customHandleSort: CustomHandleSort<T> = (sortKey, sortOrder) => {
        setSortKey(sortKey);
        setSortOrder(sortOrder);
    };

    const displaySecondaryLoader = isNewLinesLoading && numberOfStepsLoaded > 0;

    const filterForExternalToAppliedFiltersDto = (
        filters: AtTableFilterForExternal<T>[] | undefined,
    ): IAppliedFilter<T>[] | undefined => {
        if (filters) {
            return filters.map((filter) => {
                const { fieldName, value } = filter;

                return {
                    key: fieldName,
                    value: value,
                };
            });
        }
        return undefined;
    };

    const defaultAppliedFilters = filterForExternalToAppliedFiltersDto(externalyDefinedFilter);

    return (
        <div className={cn("AtTableInfinite")} onScroll={handleScroll}>
            <AtTable<T>
                items={displayedItems}
                isLoading={numberOfStepsLoaded === 0 || isLoading}
                customHandleSort={customHandleSort}
                customHandleFilter={customHandleFilter}
                refreshTable={refreshDataAsync}
                {...otherProps}
                showSearchBar={showSearchBar}
                defaultAppliedFilters={defaultAppliedFilters}
            />

            {displaySecondaryLoader && <AtTableLoader />}
        </div>
    );
}

export const AtTableInfinite = observer(AtTableInfiniteRaw);
