import "./index.less";
import * as React from "react";
import { AssessmentType, ProductCodeProEnum } from "@atman/business";
import { ProductIcon } from "../ProductIcon";

export interface IProductInfoProps {
    productCode?: ProductCodeProEnum;
    assessmentType?: AssessmentType;
    productCategory?: string;
}

export const ProductInfo: React.FC<IProductInfoProps> = (props: IProductInfoProps) => {
    const { productCategory = "AtmanPro", productCode, assessmentType } = props;

    let productName: string = "";
    let productIcon: React.ReactNode;

    if (productCode !== undefined) {
        productName = `global.productCodes.${ProductCodeProEnum[productCode].toCamel()}`.localize();
        productIcon = <ProductIcon productCode={productCode} />;
    } else if (assessmentType !== undefined) {
        productName = `psychometrics.assessments.${(AssessmentType[assessmentType] as string).toCamel()}`.localize();
        productIcon = <ProductIcon assessmentType={assessmentType} />;
    }

    return (
        <div className="ProductInfo">
            {productIcon}
            <div className="product-info">
                <div className="product-group">{productCategory}</div>
                <div className="product-name">{productName}</div>
            </div>
        </div>
    );
};
