import "./index.less";
import * as React from "react";
import { AccountDropdown } from "./components/AccountDropdown";
import { AppScope, GlobalStores, IAppContext, ReactAppSettings, TokensApi, UserInfoStore } from "@atman/business";
import { AtBalance } from "./components/AtBalance";
import { AtButton, AtMenuButton, IAtButtonProps } from "@atman/design-system";
import { AtGlobalSearch } from "../AtGlobalSearch";
import { AtQuickSearch } from "./components/AtQuickSearch";
import { AtmanLogoIcon } from "../../static";
import { BaseBreadcrumbs, IBreadcrumbData } from "./components/BaseBreadcrumbs";
import { BaseResponsiveComponent, Default } from "../BaseResponsiveComponent";
import { BlockedBalanceMarker } from "./components/BlockedBalanceMarker";
import { DropdownItem, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { UnlimitedBalanceMarker } from "./components/UnlimitedBalanceMarker";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";

export interface IAtHeaderProps extends IAppContext {
    breadcrumbComponent: React.ReactNode;
    buttonsProps?: IAtButtonProps[];
    additionalHelpDropdownItems?: React.ReactNode[];
    onMenuButtonClick?: () => void;
    showSearch?: boolean;
    showChatButton?: boolean;
    showAccountRole?: boolean;
    useAuthenticationForHelpCenter?: boolean;
    userInfoStore?: UserInfoStore;
}

@inject(GlobalStores.userInfoStore)
@observer
class AtHeaderImpl extends BaseResponsiveComponent<IAtHeaderProps> {
    static defaultProps = {
        showSearch: true,
        showChatButton: true,
        useAuthenticationForHelpCenter: true,
        showAccountRole: true,
    };

    protected get rootElementId(): string | undefined {
        return "AtHeader";
    }

    private goToHelpCenter = async () => {
        const zendeskAccessToken = await TokensApi.requestZendeskAccessToken();

        window.open(
            ReactAppSettings.appUrls.helpCenterSignInUrl.replace("{jwt}", zendeskAccessToken.zendeskAccessToken),
            "_blank",
        );
    };

    private openHelpCenter = () => {
        window.open(ReactAppSettings.appUrls.helpCenterUrl, "_blank", "noreferrer");
    };

    protected renderDefault() {
        const {
            scope,
            buttonsProps,
            additionalHelpDropdownItems,
            userInfoStore,
            breadcrumbComponent,
            showSearch,
            showChatButton,
            showAccountRole,
            useAuthenticationForHelpCenter,
        } = this.props;

        const buttons = buttonsProps?.map((x, i) => <AtButton {...x} key={i} />) ?? null;

        return (
            <>
                <div className="left-section">
                    {showSearch && <AtGlobalSearch />}
                    {breadcrumbComponent}
                </div>
                <div className="right-section">
                    {scope !== AppScope.Supplier && userInfoStore!.isAdminClientOrHigher && <AtBalance />}
                    {buttons}
                    <AtMenuButton
                        customIcon={["far", "question-circle"]}
                        className={"help-dropdown"}
                        buttonColorOverride={"ghost"}
                    >
                        {additionalHelpDropdownItems}
                        <DropdownItem
                            onClick={useAuthenticationForHelpCenter ? this.goToHelpCenter : this.openHelpCenter}
                        >
                            {"global.helpCenter".localize()}
                        </DropdownItem>
                    </AtMenuButton>
                    {showChatButton && !userInfoStore!.intercomUserBlacklisted ? (
                        <div className="chat-button-container">
                            <AtButton
                                icon={["far", "comments"] as IconProp}
                                onClick={() => null}
                                color={"ghost"}
                                id={"chatButton"}
                                size={"lg"}
                            />
                            <Default>
                                <UncontrolledTooltip
                                    className="sidebar-tooltip"
                                    placement="bottom"
                                    delay={{ show: 100, hide: 0 }}
                                    target="chatButton"
                                >
                                    {"global.chatButton.tooltip".localize()}
                                </UncontrolledTooltip>
                            </Default>
                            {userInfoStore!.intercomUnreadCount > 0 ? (
                                <span className="unread-count">{userInfoStore!.intercomUnreadCount}</span>
                            ) : null}
                        </div>
                    ) : null}
                    <AccountDropdown showRole={showAccountRole} />
                </div>
            </>
        );
    }

    protected renderMobile() {
        const { onMenuButtonClick } = this.props;

        return (
            <>
                <div className="left-section">
                    <div className="icon-container" onClick={onMenuButtonClick}>
                        <FontAwesomeIcon icon={["far", "bars"] as IconProp} />
                    </div>
                </div>
                <div className="right-section">
                    <img width={24} src={AtmanLogoIcon} className="markLogo" />
                </div>
            </>
        );
    }
}

const AtHeader = withAppContext(AtHeaderImpl);

export {
    AtHeader,
    AtBalance,
    AtQuickSearch,
    BlockedBalanceMarker,
    UnlimitedBalanceMarker,
    BaseBreadcrumbs,
    IBreadcrumbData,
};
