import { useEffect, useState } from "react";

export const useBannerStyleSvg = (locale: string, uniqueIdentifiableKey: string, size: "medium" | "large") => {
    const [svg, setSvg] = useState<string>("");

    const folderPath = size === "medium" ? "stylesMdSvg" : size === "large" ? "stylesLgSvg" : "";

    const loadSvg = async () => {
        const svg = await import(
            /* webpackMode: "lazy", webpackChunkName: "style-report-style-[index]" */
            `../components/ReportPageHeader/${folderPath}/${locale}/${uniqueIdentifiableKey}.svg`
        );

        setSvg(svg.default);
    };

    useEffect(() => {
        loadSvg().catch(console.error);
    }, [uniqueIdentifiableKey]);

    return svg;
};
