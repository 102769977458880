import "./index.less";
import * as React from "react";
import { AtTable, AtTableColumn, IAtButtonProps, TableItemHeading } from "@atman/design-system";
import { BaseForm } from "../../components/BaseForm";
import {
    ContentStore,
    Culture,
    CultureStore,
    GlobalStores,
    ModalStore,
    ScopedOrganizationStore,
    UrlFormatter,
} from "@atman/business";

import { CultureCard } from "./components/CultureCard";
import { IRoutedAppContext, withRoutedAppContext } from "../../contexts";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { autorun, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import getCulturesTableColumns from "./CulturesContainerColumns";

export interface ICulturesContainerProps extends IRoutedAppContext {
    cultureStore?: CultureStore;
    scopedOrganizationStore?: ScopedOrganizationStore;
    contentStore?: ContentStore;
    modalStore?: ModalStore;
    icon?: IconProp;
}

@inject(
    GlobalStores.cultureStore,
    GlobalStores.scopedOrganizationStore,
    GlobalStores.contentStore,
    GlobalStores.modalStore,
)
@observer
class CulturesContainerImpl extends BaseForm<ICulturesContainerProps, {}> {
    @observable private searchString: string = "";
    @observable private filteredCultures: Culture[] = [];

    constructor(props) {
        super(props);

        autorun(() => {
            this.filteredCultures = this._filteredCultures;
        });
    }

    @computed private get _filteredCultures(): Culture[] {
        const { cultureStore } = this.props;

        if (!this.searchString) {
            return cultureStore!.items;
        }

        return cultureStore!.items.filter(
            (x) =>
                x.name?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                x.description?.toLowerCase().includes(this.searchString.toLowerCase()),
        );
    }

    private editCulture = (id: string) => {
        const { history } = this.props;

        history.push(UrlFormatter.formatReactPath(`/Settings/Cultures/${id}`));
    };

    render() {
        const { history, scope, cultureStore, scopedOrganizationStore, modalStore, icon } = this.props;

        const duplicateAction = (id: string) =>
            history.push(UrlFormatter.formatReactPath("/Settings/Cultures/Create"), { duplicatedId: id });

        const columns: AtTableColumn<Culture>[] = getCulturesTableColumns(
            scope,
            cultureStore,
            modalStore,
            scopedOrganizationStore,
            this.editCulture,
            duplicateAction,
            this.filteredCultures,
            this.isMobile,
        );

        const buttons: IAtButtonProps[] = [
            {
                children: t({ id: "global.personalizeYourCulture" }),
                onClick: () =>
                    history.push(UrlFormatter.formatReactPath("/Settings/Cultures/Create"), { forOrgCulture: true }),
                color: "secondary",
            },
            {
                children: t({ id: "global.createCulture" }),
                onClick: () => history.push(UrlFormatter.formatReactPath("/Settings/Cultures/Create")),
                color: "primary",
            },
        ];

        return (
            <div id="CulturesContainer">
                <TableItemHeading
                    title={t({ id: "global.cultures" })}
                    description={t({ id: "global.organizationCulture.description" })}
                    buttons={buttons}
                    icon={icon}
                />

                <div className="cards-container">
                    <AtTable<Culture>
                        enableCardDisplay
                        columns={columns}
                        items={this.filteredCultures}
                        cardContentRender={(record) => <CultureCard culture={record} key={record.id} />}
                        refreshTable={cultureStore!.loadItems}
                        isLoading={cultureStore!.isLoading}
                    />
                </div>
            </div>
        );
    }
}

export const CulturesContainer = withRoutedAppContext(CulturesContainerImpl);
