import "./index.less";
import * as React from "react";
import { LoadingIndicator } from "@atman/design-system";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";

export interface ISsoRedirectContainerProps {}

export const SsoRedirectContainer: React.FC<ISsoRedirectContainerProps> = observer((props) => {
    const {} = props;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { search } = useLocation();
    const history = useHistory();
    const {
        params: { orgId },
    } = useRouteMatch<{ orgId: string }>();

    useEffect(() => {
        const params = new URLSearchParams(search);

        const samlLoginResponseParam = params.get("samlLoginResponse");

        if (!samlLoginResponseParam) {
            console.error("Did not receive a samlLoginResponse param in the query");
            setIsLoading(false);
            history.push(`Employee/SignIn/Sso/${orgId}`);
            return;
        }

        const { redirectTo } = JSON.parse(samlLoginResponseParam) as { redirectTo: string };

        if (!redirectTo) {
            console.error("Did not receive redirect to parameter");
            setIsLoading(false);
            history.push(`Employee/SignIn/Sso/${orgId}`);
            return;
        }

        window.location.href = redirectTo;
    }, []);

    return (
        <div id="SsoRedirectContainer">
            {isLoading ? (
                <LoadingIndicator
                    size={"xl"}
                    label={t({
                        id: "loginApp.employee.signIn.ssoRedirect.loadingMessage",
                        message: "Redirecting you to your evaluation...",
                    })}
                />
            ) : null}
        </div>
    );
});
