import "./index.less";
import * as React from "react";
import { AtCard, AtValueColor, Sizing, ValueColorRenderMode } from "@atman/design-system";
import { AverageScoreDisplayWithLabel } from "../../../../..";
import { BaseResponsiveComponent } from "../../../../../../BaseResponsiveComponent";
import { Colors } from "../../../../../../../styles/variables/colors";
import { CompDevSubCompetencyResult, LikertValue, PrintModeEnum, ScaleHelper, getRoundedScore } from "@atman/business";
import { ObservableBehaviorsTable } from "./components/ObservableBehaviorsTable";
import { action, autorun, computed, observable, runInAction } from "mobx";
import { observer } from "mobx-react";

export interface ISubCompetencyContainerProps {
    subCompetencyDetails: CompDevSubCompetencyResult[];
    printMode?: PrintModeEnum;
}

@observer
export class SubCompetencyContainer extends BaseResponsiveComponent<ISubCompetencyContainerProps> {
    @observable private selectedSubCompetencyId: string | undefined;

    @observable private selectedSubCompetency: CompDevSubCompetencyResult;

    constructor(props: ISubCompetencyContainerProps) {
        super(props);

        autorun(() => {
            this.selectedSubCompetency = this._selectedSubCompetency;
        });
    }

    componentDidMount() {
        const { subCompetencyDetails } = this.props;

        runInAction(() => (this.selectedSubCompetencyId = subCompetencyDetails.firstOrDefault()?.id));

        super.componentDidMount();
    }

    @computed private get _selectedSubCompetency(): CompDevSubCompetencyResult {
        const { subCompetencyDetails } = this.props;

        return (
            subCompetencyDetails.find((x) => x.id === this.selectedSubCompetencyId) ??
            subCompetencyDetails.firstOrDefault()
        );
    }

    @action private onSubCompetencySelection = (subCompetencyId: string) => {
        this.selectedSubCompetencyId = subCompetencyId;
    };

    renderPrintMode(): JSX.Element {
        const { subCompetencyDetails, printMode } = this.props;

        const subCompetencies = subCompetencyDetails?.map((x, i) => {
            const scoreInterpretation: LikertValue | undefined = ScaleHelper.interpretLikertAvgScore(
                x.observableBehaviorsReviewAverage ?? x.observableBehaviorsAverage ?? 0,
            );
            const borderColor =
                scoreInterpretation !== undefined ? Colors.likertScaleColors.get(scoreInterpretation) : undefined;

            return (
                <div className="sub-competency" key={`sub-competency-${i}`}>
                    <div className="sub-competency-average-score">
                        <AtValueColor
                            value={getRoundedScore(x.observableBehaviorsReviewAverage ?? x.observableBehaviorsAverage)}
                            renderMode={ValueColorRenderMode.Background}
                            bgColor={Colors.white}
                            borderColor={borderColor}
                        />
                        <span>{x.name}</span>
                    </div>
                    <ObservableBehaviorsTable observableBehaviors={x.observableBehaviors} printMode={printMode} />
                </div>
            );
        });

        return (
            <div className="SubCompetencyContainer">
                <div className="sub-competency-header">
                    <div className="sub-competency-header-title">
                        {"competencyDevelopment.subCompetencies".localize()}
                    </div>
                </div>
                <div className="sub-competencies-container">{subCompetencies}</div>
            </div>
        );
    }

    renderAll() {
        const { subCompetencyDetails, printMode } = this.props;

        if (printMode === PrintModeEnum.Detailed) {
            return this.renderPrintMode();
        }

        return (
            <div className="SubCompetencyContainer">
                <div className="sub-competency-header">
                    <div className="sub-competency-header-title">
                        {"competencyDevelopment.subCompetencies".localize()}
                    </div>
                    <div className="sub-competency-header-buttons">
                        {subCompetencyDetails?.map((x) => {
                            const borderColor =
                                this.selectedSubCompetencyId === x.id ? Colors.primaryBase : Colors.greyShades.shade7;

                            return (
                                <AtCard
                                    key={x.code}
                                    title={x.name}
                                    onClick={() => this.onSubCompetencySelection(x.id)}
                                    borderColor={borderColor}
                                    backgroundColor={Colors.white}
                                    borderRadius={Sizing.pixels.xxs}
                                    padding={Sizing.paddings.xxs}
                                >
                                    <AverageScoreDisplayWithLabel
                                        averageScore={
                                            x.observableBehaviorsReviewAverage ?? x.observableBehaviorsAverage
                                        }
                                        averageScoreDisplayMode={"light"}
                                        label={x.name}
                                    />
                                </AtCard>
                            );
                        })}
                    </div>
                </div>
                <ObservableBehaviorsTable
                    observableBehaviors={this.selectedSubCompetency?.observableBehaviors}
                    printMode={printMode}
                />
            </div>
        );
    }
}
