import "./index.less";
import * as React from "react";
import { LegendSection } from "..";
import { LikertScoreDisplay } from "../..";

export interface ILikertLegendSectionProps {
    hideTitle?: boolean;
}

export const LikertLegendSection: React.FC<ILikertLegendSectionProps> = (props) => {
    const { hideTitle = false } = props;

    return (
        <LegendSection title={"global.general.terms.legend".localize()} hideTitle={hideTitle}>
            <LikertScoreDisplay score={1} scoreLabel={`${"global.likert.valueLabels.1".localize()} (1.00 - 1.50)`} />
            <LikertScoreDisplay score={2} scoreLabel={`${"global.likert.valueLabels.2".localize()} (1.51 - 2.50)`} />
            <LikertScoreDisplay score={3} scoreLabel={`${"global.likert.valueLabels.3".localize()} (2.51 - 3.50)`} />
            <LikertScoreDisplay score={4} scoreLabel={`${"global.likert.valueLabels.4".localize()} (3.51 - 4.50)`} />
            <LikertScoreDisplay score={5} scoreLabel={`${"global.likert.valueLabels.5".localize()} (4.51 - 5.00)`} />
        </LegendSection>
    );
};
