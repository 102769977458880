import React from "react";
import SidePanelElementSkeleton from "../../../../../SidePanelElement/SidePanelElementSkeleton";

export const SidePanelSkeleton: React.FC = () => {
    return (
        <div className="SidePanelSkeleton">
            <SidePanelElementSkeleton />
            <SidePanelElementSkeleton />
            <SidePanelElementSkeleton />
            <SidePanelElementSkeleton />
            <SidePanelElementSkeleton />
        </div>
    );
};
