import "./index.less";
import { AtButton } from "../../molecules/AtButton";
import { AtButtonColors, HeadingType } from "../../../internal";
import { AtIcon } from "../../atoms/AtIcon";
import { AtTitle } from "../../atoms/AtTitle";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React from "react";
import cn from "classnames";

interface IAtModalProps {
    className?: string;
    children: JSX.Element;
    title?: string;
    titleAlignment?: "left" | "center" | "right";
    open: boolean;
    // closeFromOutsideModal?: boolean;
    // fixFooterToBottomModal?: boolean;
    setOpen: (visibility: boolean) => void;
    // className: string;
    actions?: {
        label: string;
        prefixIcon?: IconProp;
        clickHandler?: () => void;
        color?: string;
        className?: string;
        isLoading?: boolean;
        disabled?: boolean;
    }[];
    displayCrossButton?: boolean;
    headerHeadingType?: HeadingType;
    size?: string;
}

export const AtModal: React.FC<IAtModalProps> = (props) => {
    const {
        className,
        title,
        open,
        setOpen,
        children,
        actions,
        titleAlignment = "left",
        displayCrossButton = false,
        headerHeadingType = 1,
        size = "md",
    } = props;

    return (
        <Modal className={cn("AtModal", className)} isOpen={open} toggle={() => setOpen(!open)} size={size}>
            <div className="AtModalContainerContent">
                <ModalHeader className="AtModalHeader">
                    {title && (
                        <div className="title">
                            <AtTitle
                                title={title}
                                style={{ textAlign: titleAlignment }}
                                headingType={headerHeadingType}
                                noMargin
                            />
                        </div>
                    )}
                    {displayCrossButton && (
                        <AtButton
                            className="closeButton"
                            iconProps={{
                                size: "lg",
                            }}
                            icon={["fal", "times"]}
                            outline
                            color={"ghost"}
                            onClick={() => setOpen(!open)}
                        />
                    )}
                </ModalHeader>
                <ModalBody className="AtModalBody">{children}</ModalBody>
            </div>

            {actions?.length && (
                <ModalFooter className="AtModalFooter">
                    {actions.map(({ prefixIcon, color, label, clickHandler, className, isLoading, disabled }) => (
                        <AtButton
                            key={label}
                            color={color as AtButtonColors}
                            onClick={clickHandler}
                            className={className}
                            isLoading={isLoading}
                            disabled={disabled}
                        >
                            {prefixIcon && <AtIcon icon={prefixIcon} color="#fff" />}
                            {label}
                        </AtButton>
                    ))}
                </ModalFooter>
            )}
        </Modal>
    );
};
