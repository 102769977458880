import { BaseCompDevObjectiveElement, IBaseCompDevObjectiveElement } from "./BaseCompDevObjectiveElement";
import { CompetencyDevelopmentLeveragingObjectiveType } from "../CompDevEnums";

export interface ICompDevStrategicObjective extends IBaseCompDevObjectiveElement {
    readonly type: CompetencyDevelopmentLeveragingObjectiveType;
}

export class CompDevStrategicObjective extends BaseCompDevObjectiveElement implements ICompDevStrategicObjective {
    public readonly type: CompetencyDevelopmentLeveragingObjectiveType;

    constructor(json: ICompDevStrategicObjective) {
        super(json);

        this.type = json.type;
    }
}
