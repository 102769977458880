import "./index.less";
import { AtParagraph, AtText } from "@atman/design-system";

import { AtStyleSvgs } from "../AtStyleSvgs";
import { AtmanIcon } from "../AtmanIcon";
import { IAtStyleDefinition } from "@atman/business";
import { Progress } from "reactstrap";
import { t } from "@lingui/macro";
import React from "react";
import cn from "classnames";

export interface IDominantStyleResultRowProps {
    styleDefinition: IAtStyleDefinition;
    numberOfMember: number;
    numberOfMemberOfDominantStyle: number;
    isInPrintMode?: boolean;
}
export const DominantStyleResultRow: React.FC<IDominantStyleResultRowProps> = (props) => {
    const {
        styleDefinition,
        numberOfMember,
        isInPrintMode = false,
        numberOfMemberOfDominantStyle,
        ...otherProps
    } = props;

    const barWidthPercentage = (numberOfMember * 100) / (numberOfMemberOfDominantStyle + 1);

    return (
        <div className={cn("DominantStyleResultRow", styleDefinition.uniqueIdentifiableKey)} {...otherProps}>
            <div className="topContent">
                <div className="leftContent">
                    {!isInPrintMode && (
                        <AtmanIcon color={styleDefinition.color} id={`atman-icon-style-${styleDefinition.id}`} />
                    )}
                    <AtStyleSvgs uniqueIdentifiableKey={styleDefinition.uniqueIdentifiableKey} />
                </div>

                <AtText>
                    <span className="number" style={{ color: isInPrintMode ? styleDefinition.color : "" }}>
                        {numberOfMember}
                    </span>
                    <span className="members">{t({ id: "global.members" })}</span>
                </AtText>
            </div>

            {isInPrintMode && <AtParagraph>{styleDefinition.narrative}</AtParagraph>}

            <Progress multi className="scoreBar">
                <div
                    className="progress-bar"
                    style={{
                        backgroundColor: styleDefinition.color,
                        width: `${barWidthPercentage}%`,
                    }}
                    aria-valuenow={numberOfMember}
                    aria-valuemin={0}
                    aria-valuemax={numberOfMemberOfDominantStyle + 1}
                />
            </Progress>
        </div>
    );
};
