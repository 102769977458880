import "./index.less";
import * as React from "react";
import { AuthStore } from "../../../data/stores/AuthStore";
import { ConfirmCodeView } from "./ConfirmCode";
import { FieldError, parseError, useStep } from "@atman/core";
import { LoginAppStores } from "../../../../index";
import { RequestCodeView } from "./RequestCode";
import { Validator } from "@atman/business";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useEffect, useState } from "react";
import { useErrorHandling } from "@atman/ui-kit";
import { useLocation } from "react-router";

export interface IMfaSignInContainerProps {
    authStore?: AuthStore;
}

export const MfaSignInContainer: React.FC<IMfaSignInContainerProps> = inject<LoginAppStores, any, any, any>(
    (stores) => ({
        authStore: stores.authStore,
    }),
)(
    observer((props) => {
        const { authStore } = props;

        const { search } = useLocation();

        const [email, setEmail] = useState<string>("");
        const [code, setCode] = useState<string>("");
        const [isLoading, setIsLoading] = useState<boolean>(false);

        const { index, navigation } = useStep({ steps: 2 });
        const { errors, clearErrorsForField, addErrors } = useErrorHandling();

        useEffect(() => {
            const query = new URLSearchParams(search);

            if (query.has("email")) {
                setEmail(decodeURIComponent(query.get("email") ?? ""));
            }
        }, []);

        const requestCode = async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            clearErrorsForField("email");

            if (!email) {
                addErrors(new FieldError("email", t({ id: "global.errors.email.empty" })));
                return;
            }

            if (!Validator.validateEmail(email)) {
                addErrors(new FieldError("email", t({ id: "global.errors.email.invalid" })));
                return;
            }

            try {
                setIsLoading(true);
                await authStore!.requestMfaCodeForCandidate(email);

                navigation.next();
            } catch (e) {
                addErrors(...parseError(e));
            }

            setIsLoading(false);
        };

        const confirmCode = async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            clearErrorsForField("code");

            if (code.length !== 6) {
                addErrors(
                    new FieldError("code", t({ id: "global.errors.code.invalid", message: "The code is invalid." })),
                );
                return;
            }

            try {
                setIsLoading(true);
                await authStore!.confirmMfaCodeForCandidate(email, code);
            } catch (e) {
                addErrors(...parseError(e));
            }

            setIsLoading(false);
        };

        return (
            <div id="MfaSignInContainer">
                {index === 0 && (
                    <RequestCodeView
                        email={email}
                        setEmail={(e) => setEmail(e.currentTarget.value)}
                        errors={errors}
                        onSubmit={requestCode}
                        isLoading={isLoading}
                    />
                )}
                {index === 1 && (
                    <ConfirmCodeView
                        code={code}
                        setCode={(e) => setCode(e.currentTarget.value)}
                        errors={errors}
                        onSubmit={confirmCode}
                        isLoading={isLoading}
                    />
                )}
            </div>
        );
    }),
);
