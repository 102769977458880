import "./index.less";
import { AtParagraph, AtTitle, Typography } from "../../../internal";
import { DefaultComponentProps, ExtraAndDefaultSizes, FontWeight, HeadingType } from "@atman/design-system/lib/types";
import React from "react";

export interface IAtTextListProps extends DefaultComponentProps {
    title?: string;
    headingType?: HeadingType;
    texts: string[];
    textSize?: ExtraAndDefaultSizes;
    textWeight?: FontWeight;
    lineHeight?: ExtraAndDefaultSizes;
    ordered?: boolean;
}

export const AtTextList: React.FC<IAtTextListProps> = (props) => {
    const {
        title,
        headingType = 5,
        texts,
        textSize = "md",
        textWeight = "medium",
        lineHeight = "sm",
        ordered = false,
        className,
        ...others
    } = props;

    let classes: string = "AtTextList";

    if (className) {
        classes += ` ${className}`;
    }

    const List = (ordered ? "ol" : "ul") as keyof JSX.IntrinsicElements;

    return (
        <div className={classes} {...others}>
            {title && <AtTitle title={title} headingType={headingType} />}
            <List>
                {texts.map((x, i) => (
                    <li
                        key={i}
                        style={{
                            fontSize: Typography.getFontSizePropValue(textSize),
                            fontWeight: Typography.getFontWeightPropValue(textWeight),
                        }}
                    >
                        <AtParagraph size={textSize} weight={textWeight} lineHeight={lineHeight}>
                            {x}
                        </AtParagraph>
                    </li>
                ))}
            </List>
        </div>
    );
};
