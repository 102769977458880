import "./index.less";
import { BaseResponsiveComponent } from "../BaseResponsiveComponent";
import { DefaultComponentProps } from "@atman/design-system";
import { SidePanelItemDefinition } from "../SidePanelElement/SidePanelStructure";
import { action, autorun, computed, observable } from "mobx";
import { observer } from "mobx-react";
import React, { createRef } from "react";
import SidePanelContainer from "./components/SidePanelContainer";
import cn from "classnames";

export interface IMainContentProps {
    content: React.ReactNode;
    contentHeader?: React.ReactNode;
    contentClassName?: string;
    id?: string;
}

export type TeamDevelopmentActiveViews = "Onboarding" | "Overview" | string;

export type SidePanelHandleSidePanelChange = (
    activeSidePanel?: SidePanelItemDefinition | TeamDevelopmentActiveViews,
) => void;

export interface IAtSidePanelLayoutProps extends DefaultComponentProps {
    mainContentProps: IMainContentProps;
    sidePanels: SidePanelItemDefinition[];
    handleSidePanelChange?: SidePanelHandleSidePanelChange;
    defaultActiveSidePanelId?: string;
    redirectActiveSidePanelId?: string;
    isSetByPageRedirect?: boolean;
    scrollable?: boolean;
    openDefaultSidePanelOnMobile?: boolean;
    externalSideElementHighlightId?: string;
}

@observer
export class AtSidePanelLayout extends BaseResponsiveComponent<IAtSidePanelLayoutProps> {
    public mainContentContainerRef: React.RefObject<HTMLDivElement>;
    public mainContentViewRef: React.RefObject<HTMLDivElement>;
    public mainContentHeaderRef: React.RefObject<HTMLDivElement>;

    constructor(props: IAtSidePanelLayoutProps) {
        super(props);

        this.mainContentContainerRef = createRef();
        this.mainContentViewRef = createRef();
        this.mainContentHeaderRef = createRef();

        autorun(() => {
            this.flattenedSidePanels = this._flattenedSidePanels;
        });

        autorun(() => {
            this.activeSidePanel = this._activeSidePanel;
        });
    }

    @observable private flattenedSidePanels: SidePanelItemDefinition[];
    @observable private activeSidePanel: SidePanelItemDefinition | undefined;

    @observable private activeSidePanelId: string | undefined = this.props.defaultActiveSidePanelId;

    @observable private showSidePanel: boolean = true;

    @observable private activePanelSetByPageRedirect = this.props.isSetByPageRedirect ?? false;

    @computed private get _flattenedSidePanels(): SidePanelItemDefinition[] {
        const { sidePanels } = this.props;

        const travel = (item: SidePanelItemDefinition) => {
            const itemList: SidePanelItemDefinition[] = [];

            itemList.push(...[...item.subElements].flatMap(([_, v]) => v.flatMap((x) => travel(x))));

            itemList.push(item);

            return itemList;
        };

        return sidePanels.flatMap((x) => travel(x));
    }

    @computed private get _activeSidePanel(): SidePanelItemDefinition | undefined {
        return this.flattenedSidePanels.find((x) => x.id === this.activeSidePanelId);
    }

    @action private handleActivePanelChange = (activeSidePanel: SidePanelItemDefinition | undefined) => {
        if (activeSidePanel) {
            if (activeSidePanel.customClickHandler) {
                activeSidePanel.customClickHandler();
            } else if (
                !activeSidePanel.root ||
                (!activeSidePanel.root?.customBackendSearch && !activeSidePanel.isSubElementNavigationFrozen)
            ) {
                this.setActiveSidePanel(activeSidePanel);
            }

            if (
                activeSidePanel.subElements.size === 0 &&
                !activeSidePanel.customBackendSearch &&
                !activeSidePanel.customClickHandler
            ) {
                this.props.handleSidePanelChange?.(activeSidePanel);
            }
        } else {
            this.setActiveSidePanel(undefined);
            this.props.handleSidePanelChange?.(undefined);
        }

        this.activePanelSetByPageRedirect = false;
    };

    @action private setActiveSidePanel = (activeSidePanel: SidePanelItemDefinition | undefined) => {
        this.activeSidePanelId = activeSidePanel ? activeSidePanel.id : undefined;
    };

    private setRedirectionActiveSidePanel = (activeSidePanelId: string, sidePanels: SidePanelItemDefinition[]) => {
        const sidePanelToRedirect = sidePanels.find((sidePanel) => sidePanel.id === activeSidePanelId);
        if (sidePanelToRedirect) {
            this.setActiveSidePanel(sidePanelToRedirect);
        }
    };

    componentDidMount(): void {
        const { openDefaultSidePanelOnMobile = true, redirectActiveSidePanelId, sidePanels } = this.props;
        if (this.isDefault || (this.isMobile && openDefaultSidePanelOnMobile)) {
            if (redirectActiveSidePanelId) {
                this.setRedirectionActiveSidePanel(redirectActiveSidePanelId, sidePanels);
            }
        }

        super.componentDidMount();
    }

    componentDidUpdate(): void {
        const { redirectActiveSidePanelId, sidePanels, isSetByPageRedirect } = this.props;

        if (isSetByPageRedirect) {
            this.activePanelSetByPageRedirect = isSetByPageRedirect;
        }

        if (redirectActiveSidePanelId && this.activePanelSetByPageRedirect) {
            this.setRedirectionActiveSidePanel(redirectActiveSidePanelId, sidePanels);
        }
    }

    @action public hideSidePanelInMobile = (): void => {
        if (!this.isDefault) {
            this.showSidePanel = false;
        }
    };

    render(): JSX.Element {
        const {
            mainContentProps: { contentClassName, contentHeader, id: contentId, content },
            sidePanels,
            defaultActiveSidePanelId,
            className,
            externalSideElementHighlightId,
            ...otherProps
        } = this.props;

        // Remove non DOM valid props
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { handleSidePanelChange, scrollable, openDefaultSidePanelOnMobile, children, ...otherHtmlProps } =
            otherProps;

        return (
            <div className={cn("AtSidePanelLayout", className)} {...otherHtmlProps}>
                {sidePanels && sidePanels.length > 0 && this.showSidePanel && (
                    <SidePanelContainer
                        sidePanelItemDefinitions={sidePanels}
                        activeSidePanel={this.activeSidePanel}
                        handleActivePanelChange={this.handleActivePanelChange}
                        defaultActiveSidePanelId={defaultActiveSidePanelId}
                        externalSideElementHighlightId={externalSideElementHighlightId}
                    />
                )}

                <div className="main-content" id={contentId} ref={this.mainContentContainerRef}>
                    {contentHeader && (
                        <div className={cn("header")} ref={this.mainContentHeaderRef}>
                            {contentHeader}
                        </div>
                    )}

                    <div
                        className={cn("content", { contentClassName: contentClassName, "no-header": !contentHeader })}
                        ref={this.mainContentViewRef}
                    >
                        {content}
                    </div>
                </div>
            </div>
        );
    }
}
