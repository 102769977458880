import "./index.less";
import { AtSelect, IReactSelectOptionObject } from "@atman/design-system";
import { Col, Row } from "reactstrap";
import { CustomTextInput, CustomValidatedTextInput, FormGroupSection } from "../../../../components";
import { GlobalStores, LocalizationStore, UserStore } from "@atman/business";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import React from "react";

export type IUserBaseInformationFormGroupSection = {
    firstName: string;
    lastName: string;
    email: string;
    communicationEmail?: string;
    ssoNameId?: string;
    languageCode: IReactSelectOptionObject<string>;
    disableEmail?: boolean;
    showCommunicationEmail?: boolean;
    showSsoNameId?: boolean;
    onTextFieldChange: (event: React.FormEvent) => void;
    onAutoCompleteChange: (options: any, fieldName?: string, enumValue?: boolean) => void;
    userStore?: UserStore;
    localizationStore?: LocalizationStore;
};

export const UserBaseInformationFormGroupSection: React.FC<IUserBaseInformationFormGroupSection> = inject(
    GlobalStores.userStore,
    GlobalStores.localizationStore,
)(
    observer((props) => {
        const {
            firstName,
            lastName,
            email,
            communicationEmail,
            languageCode,
            disableEmail = false,
            showSsoNameId,
            ssoNameId,
            showCommunicationEmail = true,
            onTextFieldChange,
            onAutoCompleteChange,
            userStore,
            localizationStore,
        } = props;

        const languageOptions = localizationStore!.availableLanguages.map((x) => ({
            value: x.name,
            label: x.shortDisplay,
        }));

        return (
            <FormGroupSection sectionTitle={"global.userInformation".localize()} id="user-information-form">
                <Row>
                    <Col md={6}>
                        <CustomTextInput
                            fieldName="firstName"
                            value={firstName}
                            onChange={onTextFieldChange}
                            placeholder={"global.firstNamePlaceholder".localize()}
                        />
                    </Col>
                    <Col md={6}>
                        <CustomTextInput
                            fieldName="lastName"
                            value={lastName}
                            onChange={onTextFieldChange}
                            placeholder={"global.lastNamePlaceholder".localize()}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <CustomValidatedTextInput
                            fieldName="email"
                            value={email}
                            onChange={onTextFieldChange}
                            placeholder={"global.loginEmailPlaceholder".localize()}
                            validationErrors={userStore!.validationErrors}
                            label={"global.loginEmail".localize()}
                            disabled={disableEmail}
                            formText={"global.loginEmailHelpText".localize()}
                        />
                    </Col>
                </Row>
                {showCommunicationEmail && (
                    <Row>
                        <Col md={12}>
                            <CustomValidatedTextInput
                                fieldName="communicationEmail"
                                value={communicationEmail}
                                onChange={onTextFieldChange}
                                placeholder={"global.communicationEmailPlaceholder".localize()}
                                validationErrors={userStore!.validationErrors}
                                formText={"global.communicationEmailHelpText".localize()}
                            />
                        </Col>
                    </Row>
                )}
                {showSsoNameId && (
                    <Row>
                        <Col md={12}>
                            <CustomValidatedTextInput
                                fieldName="ssoNameId"
                                value={ssoNameId}
                                onChange={onTextFieldChange}
                                label={"global.sso.ssoNameId".localize()}
                                placeholder={t({ id: "global.sso.ssoNameIdPlaceholder" })}
                                validationErrors={userStore!.validationErrors}
                                formText={t({ id: "global.sso.ssoNameIdHelpText" })}
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col md={6}>
                        <AtSelect
                            name="languageCode"
                            label={"global.communicationLanguage".localize()}
                            options={languageOptions}
                            value={languageCode}
                            onChange={(o) => onAutoCompleteChange(o, "languageCode")}
                        />
                    </Col>
                </Row>
            </FormGroupSection>
        );
    }),
);
