import "./index.less";
import * as React from "react";
import { CSSProperties } from "react";
import { IScorePositionInfo } from "@atman/business";

export interface IScoreRangeHighlightProps {
    positionInfo: IScorePositionInfo;
}

export const ScoreRangeHighlight: React.FC<IScoreRangeHighlightProps> = (props) => {
    const { positionInfo } = props;

    const styles = {
        left: `calc(${positionInfo.left}% + 2px)`,
        width: `calc(${positionInfo.width}% - 4px)`,
    } as CSSProperties;

    return <div className="ScoreRangeHighlight" style={styles} />;
};
