import "./index.less";
import { AtIcon, DefaultComponentProps, DefaultSizes, XS } from "@atman/design-system";
import { useUniqueId } from "@atman/core";
import React, { useMemo } from "react";
import cn from "classnames";

export type AtmanIconColor = "purple" | "yellow" | "orange" | "green" | "blue";

export interface IAtmanIconProps extends DefaultComponentProps {
    color?: AtmanIconColor | string;
    isStared?: boolean;
    size?: DefaultSizes | XS;
}

export const AtmanIcon: React.FC<IAtmanIconProps> = (props: IAtmanIconProps) => {
    const { color = "#f08752", className, id, isStared, size = "md", ...otherProps } = props;

    const colors = useMemo(() => {
        return (
            <>
                <stop offset="100%" style={{ stopColor: color }} />
            </>
        );
    }, [color]);

    const secondaryIcon = useMemo(() => {
        if (isStared) {
            return <AtIcon icon={"star"} color="#CCC60A" borderRadius={50} size="xs" />;
        }
        return undefined;
    }, [isStared]);

    const { uniqueId } = useUniqueId(id);

    return (
        <div className={cn("AtmanIcon", { [`size-${size}`]: size }, className)} {...otherProps}>
            {secondaryIcon && <div className="secondaryIcon">{secondaryIcon}</div>}
            <svg
                className="atmanIconSvg"
                version="1.1"
                id={`${uniqueId}_svg`}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 592 602"
            >
                <g>
                    <linearGradient
                        id={uniqueId}
                        gradientUnits="userSpaceOnUse"
                        x1="525.4176"
                        y1="11.8408"
                        x2="186.836"
                        y2="598.2813"
                        gradientTransform="matrix(1 0 0 -1 0 606)"
                    >
                        {colors}
                    </linearGradient>
                    <path
                        className="gradient2"
                        style={{ fill: `url(#${uniqueId})` }}
                        d="M485.1,1H226.8C169,1,126.4,45.6,121.1,101.1c-1.7,18.1,0.4,36.4-1.2,54.6c-1.4,18.3-6.3,36.1-14.5,52.5
                        c-16.3,32.2-40,66.1-73.3,82.6c-2.4,1.2-31.4,9.9-31.4,8.4v4.8c0-1.2,28.3,7.8,30.7,9.1c35,16.7,65.4,56,78.3,91.8
                        c5.5,15.3,10.7,34.5,10.7,50.9c0,11.4,0,22.7,0.1,34.1c0,45.8,24.8,89.3,69.4,105.1c11.8,4.1,24.3,6.2,36.8,6.3h258.3
                        c58.7,0,106.2-47.5,106.2-106.2V107.2C591.3,48.6,543.8,1,485.1,1C485.1,1,485.1,1,485.1,1z"
                    />
                    <path
                        className="st2"
                        d="M350.7,491.3c-92.3-1-140.3-88.3-140.7-89.1c-5.4-9.7-1.8-22,7.9-27.4c0,0,0.1,0,0.1-0.1
                        c9.8-5.4,22-1.8,27.4,8c0,0,0,0,0,0c0.4,0.7,38.7,68,106.4,68.2c68-0.3,106-67.5,106.3-68.2c5.4-9.8,17.7-13.3,27.5-7.9
                        c9.8,5.4,13.3,17.7,7.9,27.5l0,0c-1.9,3.6-48.8,88-140.4,89.1c-0.3,0-0.6,0.1-0.9,0.1C352.2,491.4,351.2,491.4,350.7,491.3z"
                    />
                </g>
            </svg>
        </div>
    );
};
