import "./index.less";
import { AtButton, AtContainer, AtIcon, AtTitle } from "../../../../../internal";
import React from "react";
import cn from "classnames";

interface IAtTempModalProps {
    title?: string;
    titleAlignment?: "left" | "center" | "right";
    isModalHidden: boolean;
    closeFromOutsideModal?: boolean;
    fixFooterToBottomModal?: boolean;
    setIsModalHidden: (visibility: boolean) => void;
    mainActionLabel?: string;
    mainAction?: () => void;
    mainActionIsLoading?: boolean;
    secondaryActionLabel?: string;
    secondaryAction?: () => void;
    secondaryActionIsLoading?: boolean;
    tertiaryActionLabel?: string;
    tertiaryAction?: () => void;
    tertiaryActionIsLoading?: boolean;
    className: string;
}

export const AtTempModal: React.FC<IAtTempModalProps> = (props) => {
    const {
        title,
        setIsModalHidden,
        isModalHidden,
        children,
        mainActionLabel,
        secondaryActionLabel,
        tertiaryActionLabel,
        mainAction,
        secondaryAction,
        tertiaryAction,
        mainActionIsLoading,
        secondaryActionIsLoading,
        tertiaryActionIsLoading,
        className,
        titleAlignment = "left",
        closeFromOutsideModal = false,
        fixFooterToBottomModal = false,
        ...otherProps
    } = props;

    const displayFooter = mainActionLabel || secondaryActionLabel || tertiaryActionLabel;

    const onClickOverlay = () => {
        if (closeFromOutsideModal && secondaryAction) {
            secondaryAction();
        }
    };

    const onClickCrossButton = () => {
        // TODO a check that
        if (secondaryAction) {
            secondaryAction();
        } else {
            setIsModalHidden(true);
        }
    };

    return !isModalHidden ? (
        <>
            <div className="overlay" onClick={onClickOverlay} />
            <AtContainer className={cn("AtTempModal", className)} {...otherProps}>
                {title && (
                    <div className="title">
                        <AtTitle title={title} style={{ textAlign: titleAlignment }} headingType={3} noMargin />
                    </div>
                )}
                <AtIcon className="close" icon={["fal", "times"]} size="sm" onClick={onClickCrossButton} />

                {children}

                {displayFooter && (
                    <div className="footer">
                        <AtButton color="secondary" onClick={secondaryAction} isLoading={secondaryActionIsLoading}>
                            {secondaryActionLabel}
                        </AtButton>
                        {!!tertiaryAction && !!tertiaryActionLabel && (
                            <AtButton color="ghost" onClick={tertiaryAction} isLoading={tertiaryActionIsLoading}>
                                {tertiaryActionLabel}
                            </AtButton>
                        )}
                        <AtButton className="mainAction" onClick={mainAction} isLoading={mainActionIsLoading}>
                            {mainActionLabel}
                        </AtButton>
                    </div>
                )}
            </AtContainer>
        </>
    ) : null;
};
