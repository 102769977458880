import { AppScope, IContactInformation, IDashboardFilters, ITransactionLogFilters, TransactionType } from "../types";
import { AtTableFilterForExternal, AtTableSortOrder, DateRange } from "@atman/design-system";
import { BasePlatformRootStore } from "../base/BasePlatformRootStore";
import { BaseStore } from "../base/BaseStore";
import { ClientsApi, PartnersApi, SuppliersApi } from "../apis";
import { Transaction } from "../models";
import { UnhandledScopeError } from "../base";
import { action, autorun, computed, observable, runInAction } from "mobx";
import moment from "moment";

export class TransactionStore extends BaseStore {
    protected readonly scope: AppScope;
    @observable public transactions: Transaction[] = [];
    @observable public managersContactInformation: IContactInformation;
    @observable public transactionLogFilters: ITransactionLogFilters = {};

    constructor(rootStore: BasePlatformRootStore) {
        super(rootStore);
        this.scope = rootStore.scope;
    }

    @action
    getManagersContactInformation = async () => {
        await this.tryCatch(async () => {
            let contactInformation: IContactInformation;

            switch (this.scope) {
                case AppScope.Client:
                    if (this.rootStore.userInfoStore.clientId) {
                        contactInformation = await ClientsApi.getManagerContactInfo(
                            this.rootStore.userInfoStore.clientId,
                        );
                    }
                    break;
                case AppScope.Partner:
                    if (this.rootStore.userInfoStore.partnerId) {
                        contactInformation = await PartnersApi.getManagerContactInfo(
                            this.rootStore.userInfoStore.partnerId,
                        );
                    }
                    break;
            }

            runInAction(() => {
                this.managersContactInformation = contactInformation;
            });
        });
    };

    @action resetFilters = (): void => {
        this.transactionLogFilters = {};
    };

    @action setTransactionLogFilters = (filters: AtTableFilterForExternal<Transaction>[]): void => {
        this.transactionLogFilters = {};

        if (filters.length > 0) {
            for (let i = 0; i < filters.length; i++) {
                const { value, type, fieldName } = filters[i];
                if (type === "dropdown") {
                    if (fieldName === "transactionType") {
                        const transactionType =
                            Number(value) === TransactionType.Purchase ? TransactionType.Sale : Number(value);

                        this.transactionLogFilters = {
                            ...this.transactionLogFilters,
                            transactionType: TransactionType[TransactionType[transactionType]],
                        };
                    }

                    if (fieldName === "otherPartyOwnerName") {
                        const filterValue: string = value as string;

                        this.transactionLogFilters = {
                            ...this.transactionLogFilters,
                            otherPartyOwnerId: filterValue,
                        };
                    }
                }

                if (type === "multipleSelect") {
                    if (fieldName === "transactionType") {
                        if (value) {
                            const transactionTypes = (value as string[]).map((value) =>
                                Number(value) === TransactionType.Purchase ? TransactionType.Sale : Number(value),
                            );

                            this.transactionLogFilters = {
                                ...this.transactionLogFilters,
                                transactionTypes: transactionTypes,
                            };
                        }
                    }
                }

                if (type === "date") {
                    const { from, to } = value as DateRange;

                    this.transactionLogFilters = {
                        ...this.transactionLogFilters,
                        startDate: from ? moment(from).startOf("D").toISOString(true) : undefined,
                        endDate: value ? moment(to).startOf("D").toISOString(true) : undefined,
                    };
                }

                if (type === "freeText") {
                    const filterValue: string = value as string;
                    if (filterValue) {
                        if (fieldName === "otherPartyOwnerName") {
                            this.transactionLogFilters = {
                                ...this.transactionLogFilters,
                                otherPartyOwnerId: filterValue,
                            };
                        }
                    }
                }
            }
        }
    };

    @action
    searchTransactionLog = async (
        offset: number = 0,
        fetchSize: number = 100,
        filters?: AtTableFilterForExternal<Transaction>[],
        sortDirection?: { sortKey; sortOrder: AtTableSortOrder },
    ): Promise<Transaction[]> => {
        const sortOrder: "asc" | "desc" | undefined = sortDirection
            ? (sortDirection.sortOrder as "asc" | "desc" | undefined)
            : undefined;

        if (filters) {
            this.setTransactionLogFilters(filters);
        }

        return this.getTransactions(offset, fetchSize, sortOrder);
    };

    @action getTransactions = async (
        offset: number = 0,
        fetchSize: number = 100,
        sortOrder?: "asc" | "desc" | undefined,
    ): Promise<Transaction[]> => {
        switch (this.scope) {
            case AppScope.Client:
                if (this.rootStore.userInfoStore.clientId) {
                    return await ClientsApi.getClientTransactions(
                        this.rootStore.userInfoStore.clientId,
                        this.transactionLogFilters,
                        fetchSize,
                        offset,
                        sortOrder,
                    );
                }
                break;

            case AppScope.Partner:
                if (this.rootStore.userInfoStore.partnerId) {
                    return await PartnersApi.getPartnerTransactions(
                        this.rootStore.userInfoStore.partnerId,
                        this.transactionLogFilters,
                        fetchSize,
                        offset,
                        sortOrder,
                    );
                }
                break;

            case AppScope.Supplier:
                if (this.rootStore.userInfoStore.supplierId) {
                    return await SuppliersApi.getSupplierTransactions(
                        this.rootStore.userInfoStore.supplierId,
                        this.transactionLogFilters,
                        fetchSize,
                        offset,
                        sortOrder,
                    );
                }
                break;
        }

        return new Promise(() => []);
    };

    @action
    downloadTransactionLog = async (filters?: ITransactionLogFilters) => {
        switch (this.scope) {
            case AppScope.Client:
                if (this.rootStore.userInfoStore.clientId) {
                    await ClientsApi.getClientTransactionsExcel(this.rootStore.userInfoStore.clientId, filters);
                }
                break;
            case AppScope.Partner:
                if (this.rootStore.userInfoStore.partnerId) {
                    await PartnersApi.getPartnerTransactionsExcel(this.rootStore.userInfoStore.partnerId, filters);
                }
                break;
            case AppScope.Supplier:
                if (this.rootStore.userInfoStore.supplierId) {
                    await SuppliersApi.getSupplierTransactionsExcel(this.rootStore.userInfoStore.supplierId, filters);
                }
                break;
        }
    };

    @action
    loadTransactionLog = async () => {
        await this.tryCatch(async () => {
            let transactions: Transaction[];

            switch (this.scope) {
                case AppScope.Client:
                    if (this.rootStore.userInfoStore.clientId) {
                        transactions = await ClientsApi.getClientTransactions(this.rootStore.userInfoStore.clientId);
                    }
                    break;
                case AppScope.Partner:
                    if (this.rootStore.userInfoStore.partnerId) {
                        transactions = await PartnersApi.getPartnerTransactions(this.rootStore.userInfoStore.partnerId);
                    }
                    break;
                case AppScope.Supplier:
                    if (this.rootStore.userInfoStore.supplierId) {
                        transactions = await SuppliersApi.getSupplierTransactions(
                            this.rootStore.userInfoStore.supplierId,
                        );
                    }
                    break;
            }

            runInAction(() => {
                this.transactions = transactions;
                this.isLoading = false;
            });
        });
    };

    @action
    getDashboardElements = async (filters?: IDashboardFilters) => {
        return await this.tryCatch(async () => {
            switch (this.scope) {
                case AppScope.Partner:
                case AppScope.Supplier:
                    return await PartnersApi.getDashboardElements(filters);
                default:
                    throw new UnhandledScopeError(this.scope);
            }
        });
    };

    @action
    getUserById = (id: string) => {
        return this.transactions.find((x) => x.id === id);
    };

    @action
    getUserIndexById = (id: string) => {
        return this.transactions.findIndex((x) => x.id === id);
    };

    @computed
    get transactionsCount() {
        return this.transactions.length;
    }

    autorunner = autorun(() => {});
}
