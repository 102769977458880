import { BaseFitContent, IBaseFitContent } from "./BaseFitContent";
import { FitDimensionInfo, IFitDimensionInfo } from "./elements";

export interface IGenericFitContent extends IBaseFitContent {
    readonly preferencesDimensions: IFitDimensionInfo[];
    readonly tripleBottomLineDimensions: IFitDimensionInfo[];
    readonly learningModeDimensions: IFitDimensionInfo[];
    readonly isInverted: boolean;
}

export class GenericFitContent extends BaseFitContent implements IGenericFitContent {
    public readonly preferencesDimensions: FitDimensionInfo[] = [];
    public readonly tripleBottomLineDimensions: FitDimensionInfo[] = [];
    public readonly learningModeDimensions: FitDimensionInfo[] = [];
    public readonly isInverted: boolean;

    constructor(json: IGenericFitContent) {
        super(json);

        this.preferencesDimensions = json.preferencesDimensions.map((x) => new FitDimensionInfo(x)) ?? [];
        this.tripleBottomLineDimensions = json.tripleBottomLineDimensions.map((x) => new FitDimensionInfo(x)) ?? [];
        this.learningModeDimensions = json.learningModeDimensions.map((x) => new FitDimensionInfo(x)) ?? [];
        this.isInverted = json.isInverted;
    }
}
