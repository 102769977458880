import "./CustomDeleteAlertContent.less";
import * as React from "react";
import { Badge, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { ItemType } from "@atman/business";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";

export interface ICustomDeleteAlertContentProps {
    itemType: ItemType;
    itemLabel: string;
}

@inject()
@observer
export class CustomDeleteAlertContent extends React.Component<ICustomDeleteAlertContentProps, {}> {
    @computed
    get icon(): IconProp {
        const { itemType } = this.props;

        let iconName: IconName;

        switch (itemType) {
            case ItemType.Candidate:
                iconName = "user-circle";
                break;
            case ItemType.User:
                iconName = "user";
                break;
            case ItemType.Workspace:
                iconName = "warehouse";
                break;
            case ItemType.Tag:
                iconName = "tag";
                break;
            case ItemType.Team:
                iconName = "users";
                break;
            case ItemType.QuickLink:
                iconName = "link";
                break;
            case ItemType.Client:
                iconName = "building";
                break;
            case ItemType.Partner:
                iconName = "sitemap";
                break;
            case ItemType.PerformanceEvaluation:
                iconName = "clipboard-list-check";
                break;
            case ItemType.JobFit:
                iconName = "bullseye-arrow";
                break;
            case ItemType.Job:
                iconName = "id-badge";
                break;
            case ItemType.Culture:
                iconName = "theater-masks";
                break;
            default:
                throw new Error(`Unhandled Item Type: ${itemType}`);
        }

        return ["far", iconName] as IconProp;
    }

    render() {
        const { itemLabel } = this.props;

        return (
            <div id="CustomDeleteAlertContent">
                <Badge className="item-card">
                    <FontAwesomeIcon icon={this.icon} size={"lg"} />
                    <Label>{itemLabel}</Label>
                </Badge>
                <Label className={"alert-content"}>
                    {t({
                        id: "global.alerts.delete.content",
                        message: "You are about to delete this element and you won't be able to revert this operation.",
                    })}
                </Label>
            </div>
        );
    }
}
