import { observable } from "mobx";

export interface ISimpleDisplayModel {
    readonly id: string;
    readonly name: string;
    readonly description: string;
}

export class SimpleDisplayModel implements ISimpleDisplayModel {
    public id: string;
    @observable public name: string;
    @observable public description: string;

    constructor(json: ISimpleDisplayModel) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
    }
}
