import "./index.less";
import { AtBadge, AtParagraph } from "@atman/design-system";
import { CompDevApplication, CompetencyDevelopmentLeveragingObjectiveType, PrintModeEnum } from "@atman/business";
import { observer } from "mobx-react";
import React from "react";

export interface IObjectiveApplicationProps {
    application: CompDevApplication;
    printMode?: PrintModeEnum;
}

export const ObjectiveApplication: React.FC<IObjectiveApplicationProps> = observer((props) => {
    const { application } = props;

    // TODO: Review Objective Type Display
    const hasObjective = application.objectiveType !== undefined && application.objectiveType !== null;

    return (
        <div className="ObjectiveApplication">
            <div className="content">
                {/*<div>{application.name}</div>*/}
                {hasObjective && (
                    <AtBadge size={"sm"} color={"success"} badgeStyle={"outline"}>
                        {`competencyDevelopment.objectiveType.${CompetencyDevelopmentLeveragingObjectiveType[
                            application.objectiveType!
                        ].toCamel()}`.localize()}
                    </AtBadge>
                )}
                <div className={"content-description"}>
                    {application.description?.split("\n").map((x) => (
                        <AtParagraph key={x}>{x}</AtParagraph>
                    ))}
                </div>
            </div>
        </div>
    );
});
