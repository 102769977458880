import "./UnlimitedBalanceMarker.less";
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Label } from "reactstrap";

export interface IUnlimitedBalanceMarkerProps {}

export const UnlimitedBalanceMarker: React.FC<IUnlimitedBalanceMarkerProps> = (props) => {
    const {} = props;

    return (
        <div className="UnlimitedBalanceMarker">
            <Label>{"global.unlimitedBalance".localize()}</Label>
            <FontAwesomeIcon icon={["far", "infinity"] as IconProp} />
        </div>
    );
};
