import { AtTabs, IAtTabsContent } from "@atman/design-system";
import { IMapEntry } from "mobx";
import { SidePanelItemDefinition } from "../../../../../SidePanelElement/SidePanelStructure";
import { SidePanelNavigation } from "../../../SidePanelNavigation";
import { SidePanelSearch } from "../../../SidePanelSearch";
import { SidePanelSearchType, SlidingDirection } from "../../index";
import { SidePanelSorter } from "../SidePanelSorter";
import { SidePanelSubElementsRenderer } from "../SidePanelSubElementsRenderer";
import { SortDirection } from "../../../../../BaseComponent";
import { getSubElementsWithSearchApplied } from "../utils/getSubElementsWithSearchApplied";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useDebounce } from "@atman/core";
import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";

export interface ISidePanelRendererWithTabsProps {
    sidePanelToRender: SidePanelItemDefinition;
    appliedFilter: string | undefined;
    setAppliedFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
    sortDirection: SortDirection;
    setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>;
    sortType: SidePanelSearchType;
    setSortType: React.Dispatch<React.SetStateAction<SidePanelSearchType>>;
    activeSidePanel: SidePanelItemDefinition;
    handlePanelChangeWithAnimation: (activeSidePanel: SidePanelItemDefinition) => void;
    slidingDirection: SlidingDirection;
    isBackButtonDisabled: boolean;
    handleBackButtonNavigation: () => void;
    externalSideElementHighlightId?: string;
}

export const SidePanelRendererWithTabs: React.FC<ISidePanelRendererWithTabsProps> = observer(
    (props: ISidePanelRendererWithTabsProps) => {
        const {
            sidePanelToRender,
            appliedFilter,
            setAppliedFilter,
            sortDirection,
            setSortDirection,
            sortType,
            setSortType,
            activeSidePanel,
            handlePanelChangeWithAnimation,
            slidingDirection,
            isBackButtonDisabled,
            handleBackButtonNavigation,
            externalSideElementHighlightId,
            ...otherProps
        } = props;

        const subElements = sidePanelToRender.subElements;

        const [elementsToDisplay, setElementsToDisplay] = useState<Map<string, SidePanelItemDefinition[]>>(subElements);

        const debouncedSearchTerm: string | undefined = useDebounce<string | undefined>(
            appliedFilter ?? undefined,
            500,
        );

        const tabs: IAtTabsContent[] | undefined = useMemo(() => {
            if (!elementsToDisplay) {
                return undefined;
            }

            return [...elementsToDisplay].map(([title, subElements]) => ({
                id: title,
                buttonContent: title,
                buttonProps: { size: "md" },
                content: (
                    <>
                        {sidePanelToRender.isSearchable && (
                            <SidePanelSearch
                                appliedFilter={appliedFilter}
                                setAppliedFilter={setAppliedFilter}
                                placeholder={t({ id: "clientApp.sidePanelSearch.placeholder" })}
                            />
                        )}

                        <div className="sorters">
                            {sidePanelToRender.sort.displaySort && (
                                <SidePanelSorter
                                    sortLabel={sidePanelToRender.sort.sortLabel!}
                                    setSortDirection={setSortDirection}
                                    sortDirection={sortDirection}
                                    sortType={SidePanelSearchType.Primary}
                                    appliedSortType={sortType}
                                    setSortType={setSortType}
                                    currentTab={title}
                                />
                            )}

                            {sidePanelToRender.secondarySort.displaySort && (
                                <SidePanelSorter
                                    sortLabel={sidePanelToRender.secondarySort.sortLabel!}
                                    setSortDirection={setSortDirection}
                                    sortDirection={sortDirection}
                                    sortType={SidePanelSearchType.Secondary}
                                    appliedSortType={sortType}
                                    setSortType={setSortType}
                                    currentTab={title}
                                />
                            )}
                        </div>

                        <SidePanelSubElementsRenderer
                            isLoading={sidePanelToRender.isLoading}
                            subElements={subElements}
                            activeSidePanelId={activeSidePanel.id}
                            handlePanelChangeWithAnimation={handlePanelChangeWithAnimation}
                            externalSideElementHighlightId={externalSideElementHighlightId}
                        />
                    </>
                ),
            }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [elementsToDisplay, activeSidePanel, sortDirection]);

        const firstDisplayedTab = tabs && tabs[0].id;

        const [currentTabId, setCurrentTabId] = useState<string | undefined>(firstDisplayedTab);

        const onTabChange = (tabId: string) => {
            setCurrentTabId(tabId);
        };

        useEffect(() => {
            const fetchData = async () => {
                const allSubElements = await Promise.all(
                    [...subElements].map(async ([_title, subSubElements]) => {
                        const subSubElementsFound = await getSubElementsWithSearchApplied(
                            subSubElements,
                            debouncedSearchTerm,
                            sortDirection,
                            sortType,
                            sidePanelToRender,
                            currentTabId,
                        );

                        return [_title, subSubElementsFound.flat()] as IMapEntry<string, SidePanelItemDefinition[]>;
                    }),
                );

                setElementsToDisplay(new Map<string, SidePanelItemDefinition[]>(allSubElements));
            };

            fetchData().catch(console.error);
        }, [currentTabId, debouncedSearchTerm, sidePanelToRender, sortDirection, sortType, subElements]);

        return (
            <div className="SidePanelContainer" {...otherProps}>
                <div
                    className={cn("animate__animated", "animate-wrapper", {
                        animate__fadeInRight: slidingDirection === "forward",
                        animate__fadeInLeft: slidingDirection === "backwards",
                    })}
                >
                    <SidePanelNavigation
                        activeSidePanel={sidePanelToRender}
                        isBackButtonDisabled={isBackButtonDisabled}
                        handleBackButton={handleBackButtonNavigation}
                    />

                    {tabs && (
                        <AtTabs
                            className="tabsRenderedPadding"
                            tabs={tabs}
                            tabChangeHook={onTabChange}
                            defaultTab={firstDisplayedTab}
                        />
                    )}
                </div>
            </div>
        );
    },
);
