import "./index.less";
import { DefaultComponentProps } from "../../../internal";
import { Label } from "reactstrap";
import React from "react";
import cn from "classnames";

export interface IAtBaseCardProps extends DefaultComponentProps {
    disabled?: boolean;
    id?: string;
    removeLabelWrapper?: boolean;
}

export const AtBaseCard: React.FC<IAtBaseCardProps> = (props) => {
    const { className, children, disabled = false, removeLabelWrapper = false, ...otherProps } = props;

    return (
        <div className={cn("AtBaseCard", className, { disabled: disabled })} {...otherProps}>
            {!removeLabelWrapper ? <Label disabled={disabled}>{children}</Label> : children}
        </div>
    );
};
