import { ReactAppSettings } from "./ReactAppSettings";

export enum LogTypes {
    INFO,
    DEBUG,
    ERROR,
}

export class Logger {
    static log(message: string | Error | any, logType: LogTypes = LogTypes.DEBUG) {
        if (ReactAppSettings.viewBag.isInDevEnvironment) {
            console.log(`${LogTypes[logType]}:`, message);
        }
    }
}
