import { BaseCompetencySet, IBaseCompetencySet, IBaseCompetencySetInput } from "./BaseCompetencySet";
import { observable } from "mobx";

export interface ICultureInput extends IBaseCompetencySetInput {
    readonly cultureType: CultureType;
    readonly departmentId?: string;
}

export interface ICulture extends IBaseCompetencySet {
    readonly cultureType: CultureType;
    readonly departmentId?: string;
    readonly teamsCount: number;
}

export class Culture extends BaseCompetencySet {
    @observable public cultureType: CultureType;
    @observable public departmentId?: string;
    @observable public teamsCount: number;

    constructor(json?: ICulture) {
        super(json);

        if (!json) {
            return;
        }

        this.cultureType = json.cultureType;
        this.departmentId = json.departmentId;
        this.teamsCount = json.teamsCount;
    }
}

export enum CultureType {
    None = 0,
    Enterprise = 1 << 0,
    Team = 1 << 1,
    EnterpriseAndTeam = Enterprise | Team,
}
