import "./DashboardGraph.less";
import { DateGraphFrequency, IDateGraph, ProductCodeProEnum } from "@atman/business";
import { inject, observer } from "mobx-react";
import React from "react";
// @ts-ignore
import * as moment from "moment";
import { AtButton, AtTabCard } from "@atman/design-system";
import { AtVictoryTheme } from "../../../styles/components/AtVictoryTheme";
import { ButtonGroup } from "reactstrap";
import { Colors } from "../../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    VictoryAxis,
    VictoryChart,
    VictoryLabel,
    VictoryLine,
    VictoryScatter,
    VictoryTooltip,
    VictoryVoronoiContainer,
} from "victory";
import { VictoryClipContainer } from "victory";
import { action, computed, observable } from "mobx";
import autobind from "autobind-decorator";

export interface IDashboardGraphProps {
    data?: Dictionary<DateGraphFrequency, IDateGraph[]>;
    filterDaysCount: number;
}

@inject()
@observer
export class DashboardGraph extends React.Component<IDashboardGraphProps, {}> {
    @observable public frequency: DateGraphFrequency = DateGraphFrequency.Weekly;
    @observable public displayAllProductsData: boolean = true;

    componentDidUpdate(prevProps: Readonly<IDashboardGraphProps>, prevState: Readonly<{}>, snapshot?: any): void {
        this.updateFrequencyForPeriod();
    }

    private updateFrequencyForPeriod = () => {
        const { filterDaysCount } = this.props;

        switch (this.frequency) {
            case DateGraphFrequency.Daily:
                if (filterDaysCount > 92) {
                    this.frequency = DateGraphFrequency.Weekly;
                } else if (filterDaysCount > 365) {
                    this.frequency = DateGraphFrequency.Monthly;
                }
                break;
            case DateGraphFrequency.Weekly:
                if (filterDaysCount < 14) {
                    this.frequency = DateGraphFrequency.Daily;
                } else if (filterDaysCount > 365) {
                    this.frequency = DateGraphFrequency.Monthly;
                }
                break;
            case DateGraphFrequency.Monthly:
                if (filterDaysCount < 14) {
                    this.frequency = DateGraphFrequency.Daily;
                } else if (filterDaysCount < 60) {
                    this.frequency = DateGraphFrequency.Weekly;
                }
                break;
        }
    };

    @computed
    get mappedData() {
        const { data } = this.props;

        const dataForFrequency = data![this.frequency] as IDateGraph[];

        switch (this.frequency) {
            case DateGraphFrequency.Daily:
                return dataForFrequency.map(({ startDate, endDate, ...otherProps }) => ({
                    tooltipLabel: moment.parseZone(startDate).startOf("day").format("MMM DD"),
                    x: moment.parseZone(startDate).startOf("day").toDate(),
                    ...otherProps,
                }));
            case DateGraphFrequency.Weekly:
                return dataForFrequency.map(({ startDate, endDate, ...otherProps }) => ({
                    tooltipLabel: `${moment.parseZone(startDate).format("MMM DD")} - ${moment
                        .parseZone(endDate)
                        .format("MMM DD")}`,
                    x: moment.parseZone(startDate).toDate(),
                    ...otherProps,
                }));
            case DateGraphFrequency.Monthly:
                return dataForFrequency.map(({ startDate, ...otherProps }) => ({
                    tooltipLabel: moment.parseZone(startDate).format("MMMM YYYY"),
                    x: moment.parseZone(startDate).toDate(),
                    ...otherProps,
                }));
        }
    }

    @autobind
    private getDataSetForProduct(productCode: ProductCodeProEnum | "total", labels: boolean = true) {
        return this.mappedData.map((x) => ({
            ...x,
            l: labels,
        }));
    }

    @action.bound
    updateFrequency = (event: React.FormEvent<HTMLButtonElement>) => {
        this.frequency = Number((event.target as any).value);
    };

    @action.bound
    updateDisplayAllProductsData = (event: React.FormEvent<HTMLButtonElement>) => {
        this.displayAllProductsData = (event.target as any).value === "true";
    };

    @autobind
    renderCustomAtTabCardContent() {
        const { filterDaysCount } = this.props;

        return (
            <div className={"filters-section"}>
                <ButtonGroup className={"all-products-filter"}>
                    <AtButton
                        size={"md"}
                        color={this.displayAllProductsData ? "primary" : "secondary"}
                        onClick={this.updateDisplayAllProductsData}
                        value={true.toString()}
                        className={this.displayAllProductsData ? "selected" : ""}
                    >
                        {"global.all".localize()}
                    </AtButton>
                    <AtButton
                        size={"md"}
                        color={!this.displayAllProductsData ? "primary" : "secondary"}
                        onClick={this.updateDisplayAllProductsData}
                        value={false.toString()}
                        className={!this.displayAllProductsData ? "selected" : ""}
                    >
                        {"global.total".localize()}
                    </AtButton>
                </ButtonGroup>
                <ButtonGroup className={"frequency-filter"}>
                    <AtButton
                        size={"md"}
                        color={this.frequency === DateGraphFrequency.Daily ? "primary" : "secondary"}
                        onClick={this.updateFrequency}
                        value={DateGraphFrequency.Daily}
                        disabled={filterDaysCount > 92}
                        className={this.frequency === DateGraphFrequency.Daily ? "selected" : ""}
                    >
                        {`global.frequencies.${DateGraphFrequency[DateGraphFrequency.Daily].toCamel()}`.localize()}
                    </AtButton>
                    <AtButton
                        size={"md"}
                        color={this.frequency === DateGraphFrequency.Weekly ? "primary" : "secondary"}
                        onClick={this.updateFrequency}
                        value={DateGraphFrequency.Weekly}
                        disabled={filterDaysCount < 14 || filterDaysCount > 365}
                        className={this.frequency === DateGraphFrequency.Weekly ? "selected" : ""}
                    >
                        {`global.frequencies.${DateGraphFrequency[DateGraphFrequency.Weekly].toCamel()}`.localize()}
                    </AtButton>
                    <AtButton
                        size={"md"}
                        color={this.frequency === DateGraphFrequency.Monthly ? "primary" : "secondary"}
                        onClick={this.updateFrequency}
                        value={DateGraphFrequency.Monthly}
                        disabled={filterDaysCount < 60}
                        className={this.frequency === DateGraphFrequency.Monthly ? "selected" : ""}
                    >
                        {`global.frequencies.${DateGraphFrequency[DateGraphFrequency.Monthly].toCamel()}`.localize()}
                    </AtButton>
                </ButtonGroup>
            </div>
        );
    }

    render() {
        const { data } = this.props;

        if (!data) {
            return (
                <AtTabCard id={"DashboardGraph"} cardTitle={"global.graph".localize()} className={"loading"}>
                    <FontAwesomeIcon icon={["far", "spinner"] as IconProp} spin />
                </AtTabCard>
            );
        }

        const dataSetIsEmpty = !this.mappedData.some((x) => x.count > 0);
        const tickValuesForEmptyDataSet = dataSetIsEmpty ? { tickValues: [0, 1], maxDomain: { y: 1 } } : {};

        const victoryTooltip = (
            <VictoryTooltip
                flyoutStyle={AtVictoryTheme.tooltip.flyout}
                // @ts-ignore
                style={AtVictoryTheme.tooltip.style}
                constrainToVisibleArea
                labelComponent={<VictoryLabel className={"tooltip-label"} />}
            />
        );
        const labelFormatMethod = ({
            datum: {
                tooltipLabel,
                l,
                total,
                integral,
                personalityTest,
                cognitiveTest,
                preferencesTest,
                tripleBottomLineTest,
            },
        }) => {
            if (!l) {
                return null;
            }

            return `${tooltipLabel}
            ${"global.total".localize()}: ${total}
            ${`global.productCodes.${ProductCodeProEnum[
                ProductCodeProEnum.Integral
            ].toCamel()}`.localize()}: ${integral}
            ${`global.productCodes.${ProductCodeProEnum[
                ProductCodeProEnum.CognitiveTest
            ].toCamel()}`.localize()}: ${cognitiveTest}
            ${`global.productCodes.${ProductCodeProEnum[
                ProductCodeProEnum.PersonalityTest
            ].toCamel()}`.localize()}: ${personalityTest}
            ${`global.productCodes.${ProductCodeProEnum[
                ProductCodeProEnum.PreferencesTest
            ].toCamel()}`.localize()}: ${preferencesTest}
            ${`global.productCodes.${ProductCodeProEnum[
                ProductCodeProEnum.TripleBottomLineTest
            ].toCamel()}`.localize()}: ${tripleBottomLineTest}`;
        };

        const victoryVoronoiContainer = (
            <VictoryVoronoiContainer
                voronoiDimension={"x"}
                height={300}
                width={900}
                voronoiPadding={1}
                // @ts-ignore
                labels={labelFormatMethod}
                labelComponent={victoryTooltip}
                voronoiBlacklist={[
                    "scatter_total",
                    "scatter_Integral",
                    "scatter_CognitiveTest",
                    "scatter_PersonalityTest",
                    "scatter_PreferencesTest",
                    "scatter_TripleBottomLineTest",
                ]}
            />
        );
        const victoryClipContainer = <VictoryClipContainer clipHeight={305} clipPadding={{ top: 2 }} />;

        const victoryScatterTotal = (
            <VictoryScatter
                y={(x) => x.total}
                name={`scatter_total`}
                key={"total"}
                data={this.getDataSetForProduct("total", false)}
            />
        );
        const victoryScatterIntegral = (
            <VictoryScatter
                y={(x) => x.integral}
                name={`scatter_Integral`}
                key={ProductCodeProEnum.Integral}
                data={this.getDataSetForProduct(ProductCodeProEnum.Integral, false)}
                style={{ data: { stroke: Colors.atmanOrange } }}
            />
        );
        const victoryScatterCognitive = (
            <VictoryScatter
                y={(x) => x.cognitiveTest}
                name={`scatter_CognitiveTest`}
                key={ProductCodeProEnum.CognitiveTest}
                data={this.getDataSetForProduct(ProductCodeProEnum.CognitiveTest, false)}
                style={{ data: { stroke: Colors.cognitiveAssessmentColor } }}
            />
        );
        const victoryScatterPersonality = (
            <VictoryScatter
                y={(x) => x.personalityTest}
                name={`scatter_PersonalityTest`}
                key={ProductCodeProEnum.PersonalityTest}
                data={this.getDataSetForProduct(ProductCodeProEnum.PersonalityTest, false)}
                style={{ data: { stroke: Colors.personalityAssessmentColor } }}
            />
        );
        const victoryScatterPreferences = (
            <VictoryScatter
                y={(x) => x.preferencesTest}
                name={`scatter_PreferencesTest`}
                key={ProductCodeProEnum.PreferencesTest}
                data={this.getDataSetForProduct(ProductCodeProEnum.PreferencesTest, false)}
                style={{ data: { stroke: Colors.preferenceAssessmentColor } }}
            />
        );
        const victoryScatterTripleBottomLine = (
            <VictoryScatter
                y={(x) => x.tripleBottomLineTest}
                name={`scatter_TripleBottomLineTest`}
                key={ProductCodeProEnum.TripleBottomLineTest}
                data={this.getDataSetForProduct(ProductCodeProEnum.TripleBottomLineTest, false)}
                style={{ data: { stroke: Colors.tripleBottomLineAssessmentColor } }}
            />
        );

        const chart = (
            <VictoryChart
                height={300}
                width={900}
                scale={{ x: "time", y: "linear" }}
                containerComponent={victoryVoronoiContainer}
                // @ts-ignore
                theme={AtVictoryTheme}
            >
                <VictoryAxis dependentAxis {...tickValuesForEmptyDataSet} />
                <VictoryAxis
                    style={{
                        grid: {
                            strokeWidth: 0,
                        },
                        axis: {
                            strokeWidth: 1,
                        },
                    }}
                />
                <VictoryLine
                    y={(x) => x.total}
                    interpolation={"monotoneX"}
                    data={this.getDataSetForProduct("total")}
                    groupComponent={victoryClipContainer}
                />
                {this.displayAllProductsData
                    ? [
                          <VictoryLine
                              y={(x) => x.tripleBottomLineTest}
                              key={ProductCodeProEnum.TripleBottomLineTest}
                              interpolation={"monotoneX"}
                              data={this.getDataSetForProduct(ProductCodeProEnum.TripleBottomLineTest, false)}
                              groupComponent={victoryClipContainer}
                              style={{ data: { stroke: Colors.tripleBottomLineAssessmentColor } }}
                          />,
                          <VictoryLine
                              y={(x) => x.preferencesTest}
                              key={ProductCodeProEnum.PreferencesTest}
                              interpolation={"monotoneX"}
                              data={this.getDataSetForProduct(ProductCodeProEnum.PreferencesTest, false)}
                              groupComponent={victoryClipContainer}
                              style={{ data: { stroke: Colors.preferenceAssessmentColor } }}
                          />,
                          <VictoryLine
                              y={(x) => x.personalityTest}
                              key={ProductCodeProEnum.PersonalityTest}
                              interpolation={"monotoneX"}
                              data={this.getDataSetForProduct(ProductCodeProEnum.PersonalityTest, false)}
                              groupComponent={victoryClipContainer}
                              style={{ data: { stroke: Colors.personalityAssessmentColor } }}
                          />,
                          <VictoryLine
                              y={(x) => x.cognitiveTest}
                              key={ProductCodeProEnum.CognitiveTest}
                              interpolation={"monotoneX"}
                              data={this.getDataSetForProduct(ProductCodeProEnum.CognitiveTest, false)}
                              groupComponent={victoryClipContainer}
                              style={{ data: { stroke: Colors.cognitiveAssessmentColor } }}
                          />,
                          <VictoryLine
                              y={(x) => x.integral}
                              key={ProductCodeProEnum.Integral}
                              interpolation={"monotoneX"}
                              data={this.getDataSetForProduct(ProductCodeProEnum.Integral, false)}
                              groupComponent={victoryClipContainer}
                              style={{ data: { stroke: Colors.atmanOrange } }}
                          />,
                      ]
                    : null}
                {victoryScatterTotal}
                {this.displayAllProductsData
                    ? [
                          victoryScatterTripleBottomLine,
                          victoryScatterPreferences,
                          victoryScatterPersonality,
                          victoryScatterCognitive,
                          victoryScatterIntegral,
                      ]
                    : null}
            </VictoryChart>
        );

        return (
            <AtTabCard
                id="DashboardGraph"
                cardTitle={"global.graph".localize()}
                rightHeaderContent={this.renderCustomAtTabCardContent()}
            >
                {chart}
            </AtTabCard>
        );
    }
}
