import "./index.less";
import * as React from "react";
import { Collapse, CollapseProps } from "reactstrap";
import { DefaultComponentPropKeys, DefaultComponentProps, DefaultSizes, Sizing, XS } from "../../../internal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaddingProperty } from "csstype";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { observer } from "mobx-react";
import { useDetectDevice } from "@atman/core";

export interface IAtCollapseProps extends DefaultComponentProps {
    toggleRowContent: React.ReactNode;
    toggleRowSize?: XS | DefaultSizes | "ignore";
    toggleRowSizeHorizontal?: XS | DefaultSizes | "ignore";
    toggleRowClassName?: string;
    toggleRowId?: string;
    toggleRowStyle?: React.CSSProperties;
    toggleRowProps?: Omit<React.HTMLProps<HTMLDivElement>, DefaultComponentPropKeys | "onClick">;

    renderChevron?: boolean;
    chevronSize?: SizeProp;

    isOpen?: boolean;
    toggleCollapse?: (event?: React.MouseEvent<HTMLDivElement>) => void;
    defaultOpen?: boolean;
    disabled?: boolean;

    collapseClassName?: string;
    collapseId?: string;
    collapseStyle?: React.CSSProperties;
    collapseProps?: Omit<React.Component<CollapseProps>, DefaultComponentPropKeys | "isOpen" | "children">;

    collapseContentOffset?: boolean;

    onOpened?: () => void;
    onClosed?: () => void;
    onEntering?: () => void;
    onEntered?: () => void;
    onExit?: () => void;
    onExiting?: () => void;
    onExited?: () => void;
}

export const AtCollapse: React.FC<IAtCollapseProps> = observer((props) => {
    const {
        toggleRowContent,
        toggleRowSize = "md",
        toggleRowSizeHorizontal = "xl",
        toggleRowClassName,
        toggleRowId,
        toggleRowStyle,
        toggleRowProps,
        renderChevron = true,
        chevronSize = "sm",
        isOpen,
        toggleCollapse,
        defaultOpen,
        disabled = false,
        collapseClassName,
        collapseId,
        collapseStyle,
        collapseProps,
        collapseContentOffset = false,
        onEntering,
        onEntered,
        onExit,
        onExiting,
        onExited,
        children,
        id,
        className,
        style,
        ...otherProps
    } = props;

    let isCollapseOpen: boolean;
    let toggleCollapseState: (event?: React.MouseEvent<HTMLDivElement>) => void;

    if (toggleCollapse === undefined || isOpen === undefined) {
        const [_isOpen, _setIsOpen] = React.useState(defaultOpen ?? false);
        isCollapseOpen = _isOpen;
        toggleCollapseState = () => {
            if (disabled) return;
            _setIsOpen(!isCollapseOpen);
        };
    } else {
        isCollapseOpen = isOpen;
        toggleCollapseState = (event) => {
            if (disabled) return;
            toggleCollapse(event);
        };
    }

    const [collapseClassStateIsOpen, setCollapseClassStateIsOpen] = React.useState(defaultOpen ?? isOpen ?? false);
    const { isMobile } = useDetectDevice();

    const _onEntering = () => {
        setCollapseClassStateIsOpen(true);

        onEntering?.();
    };

    const _onExited = () => {
        setCollapseClassStateIsOpen(false);

        onExited?.();
    };

    const getContainerClassNames = () => {
        let containerClassNames = "AtCollapse";

        if (className) {
            containerClassNames += ` ${className}`;
        }

        if (collapseClassStateIsOpen) {
            containerClassNames += " is-expanded";
        }

        if (collapseContentOffset) {
            containerClassNames += " left-collapse-content-offset";
        }

        return containerClassNames;
    };

    const getToggleRowClassNames = () => {
        let toggleRowClassNames = "AtCollapse-toggle-row";

        if (toggleRowClassName) {
            toggleRowClassNames += ` ${toggleRowClassName}`;
        }

        if (collapseClassStateIsOpen) {
            toggleRowClassNames += " is-expanded";
        }

        if (disabled) {
            toggleRowClassNames += " disabled";
        }

        return toggleRowClassNames;
    };

    const getCollapseClassNames = () => {
        let collapseClassNames = "AtCollapse-collapse-content";

        if (collapseClassName) {
            collapseClassNames += ` ${collapseClassName}`;
        }

        return collapseClassNames;
    };

    const getToggleRowStyles = () => {
        let paddingVertical: PaddingProperty<any>;
        let paddingHorizontal: PaddingProperty<any>;

        switch (toggleRowSize) {
            case "ignore":
                paddingVertical = undefined;
                break;
            case "xs":
                paddingVertical = Sizing.getPixelsPropValue("xs");
                break;
            case "sm":
                paddingVertical = `${Sizing.pixels.xs + Sizing.pixels.xxs}px`;
                break;
            case "md":
                paddingVertical = Sizing.getPixelsPropValue("sm");
                break;
            case "lg":
                paddingVertical = Sizing.getPixelsPropValue("md");
                break;
        }

        switch (toggleRowSizeHorizontal) {
            case "ignore":
                paddingHorizontal = undefined;
                break;
            case "xs":
                paddingHorizontal = Sizing.getPixelsPropValue("xs");
                break;
            case "sm":
                paddingHorizontal = Sizing.getPixelsPropValue("sm");
                break;
            case "md":
                paddingHorizontal = Sizing.getPixelsPropValue("md");
                break;
            case "lg":
                paddingHorizontal = Sizing.getPixelsPropValue("lg");
                break;
            case "xl":
                paddingHorizontal = Sizing.getPixelsPropValue("xl");
                break;
        }

        return {
            padding: `${paddingVertical} ${paddingHorizontal}`,
            ...toggleRowStyle,
        };
    };

    const getToggleRowMobileStyles = () => {
        let paddingValues: PaddingProperty<any>;

        switch (toggleRowSize) {
            case "ignore":
                paddingValues = undefined;
                break;
            case "xs":
                paddingValues = `${Sizing.getPixelsPropValue("xs")} ${Sizing.getPixelsPropValue("sm")}`;
                break;
            case "sm":
                paddingValues = `${Sizing.pixels.xs + Sizing.pixels.xxs}px ${Sizing.getPixelsPropValue("sm")}`;
                break;
            case "md":
                paddingValues = `${Sizing.getPixelsPropValue("sm")} ${Sizing.getPixelsPropValue("sm")}`;
                break;
            case "lg":
                paddingValues = `${Sizing.getPixelsPropValue("md")} ${Sizing.getPixelsPropValue("sm")}`;
                break;
        }

        return {
            padding: paddingValues,
            ...toggleRowStyle,
        };
    };

    return (
        <div className={getContainerClassNames()} id={id} style={style} {...otherProps}>
            <div
                className={getToggleRowClassNames()}
                id={toggleRowId}
                style={isMobile ? getToggleRowMobileStyles() : getToggleRowStyles()}
                onClick={toggleCollapseState}
                {...toggleRowProps}
            >
                {renderChevron && (
                    <FontAwesomeIcon
                        className={"rendered-chevron"}
                        icon={["far", isCollapseOpen ? "chevron-up" : "chevron-down"]}
                        size={chevronSize}
                    />
                )}
                {toggleRowContent}
            </div>
            <Collapse
                isOpen={isCollapseOpen}
                disabled={disabled}
                className={getCollapseClassNames()}
                id={collapseId}
                style={collapseStyle}
                onEntering={_onEntering}
                onEntered={onEntered}
                onExit={onExit}
                onExiting={onExiting}
                onExited={_onExited}
                {...collapseProps}
            >
                <div className="content">{children}</div>
            </Collapse>
        </div>
    );
});
