import { IObservableMapInitialValues } from "mobx/lib/types/observablemap";
import { ObservableMap } from "mobx";

export class QueryableObservableMap<K, V> extends ObservableMap<K, V> {
    getByPredicate: (pred: (key: K) => boolean) => V | undefined = (pred: (key: K) => boolean) => {
        for (const key of this.keys()) {
            if (pred(key)) {
                return this.get(key);
            }
        }

        return undefined;
    };

    clearByPredicate: (pred: (key: K) => boolean) => void = (pred: (key: K) => boolean) => {
        for (const key of this.keys()) {
            if (pred(key)) {
                this.delete(key);
            }
        }
    };

    set(key: K, value: V): this {
        if (typeof key === "object") {
            const predicate: Array<(key: K) => boolean> = [];

            for (const objKey of Object.keys(key)) {
                predicate.push((x) => x[objKey] === key[objKey]);
            }

            if (this.clearByPredicate) {
                this.clearByPredicate((x) => predicate.every((p) => p(x)));
            }
        }

        return super.set(key, value);
    }

    constructor(initialData?: IObservableMapInitialValues<K, V>) {
        super(initialData);
    }

    static fromDictionary<K, V>(object: Dictionary<K, V>) {
        return new QueryableObservableMap<K, V>(Object.keys(object).map((x) => [x as unknown as K, object[x]]));
    }
}
