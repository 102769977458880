import { BootstrapSizes, ResponsivenessHelper } from "@atman/core";

export function isColumnHidden(
    displayOverSize: BootstrapSizes | undefined,
    hideOverSize: BootstrapSizes | undefined,
): boolean {
    if (displayOverSize) {
        return !ResponsivenessHelper.isSizeOrBigger(displayOverSize);
    }

    if (hideOverSize) {
        return ResponsivenessHelper.isSizeOrBigger(hideOverSize);
    }

    return false;
}
