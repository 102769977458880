import "./index.less";
import * as React from "react";
import { BaseResponsiveComponent } from "../BaseResponsiveComponent";
import { ContainerSize, IPanelItemProps, PanelItem, PanelSize } from "./components/PanelItem";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";

export interface IAtMultiPanelLayoutProps {
    panelItems: IPanelItemProps[];
    scrollable?: boolean;
    className?: string;
    id?: string;
}

@inject()
@observer
export class AtMultiPanelLayout extends BaseResponsiveComponent<IAtMultiPanelLayoutProps> {
    @computed protected get rootElementClassName(): string | undefined {
        const { className } = this.props;

        return `AtMultiPanelLayout ${className ?? ""}`;
    }

    @computed protected get rootElementId(): string | undefined {
        return this.props.id;
    }

    @computed protected get rootElementStyle(): React.CSSProperties {
        const { scrollable = true } = this.props;

        if (scrollable) {
            return {};
        }

        return {
            overflowY: "hidden",
        };
    }

    validatePanelItems() {
        const { panelItems } = this.props;

        if (panelItems.length === 0) {
            console.warn("No Panel Items were provided to this component.");
            return false;
            // } else if (panelItems.length === 3) {
            //     console.warn('This component does not yet support 3 panels.');
            //     return false;
        } else if (panelItems.length > 3) {
            console.warn("This component does not support more than 3 panels.");
            return false;
        }

        if (!panelItems.some((x) => x.panelSize === "auto")) {
            console.warn("This component expects to receive at least 1 auto-sized panel.");
            return false;
        }

        return true;
    }

    renderAll() {
        const { panelItems } = this.props;

        const validationResult = this.validatePanelItems();

        if (!validationResult) {
            return null;
        }

        return (
            <>
                {panelItems.map((props, i) => (
                    <PanelItem {...props} key={i} />
                ))}
            </>
        );
    }
}

export { PanelItem, PanelSize, ContainerSize, IPanelItemProps };
