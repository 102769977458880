import "./AddCredits.less";
import * as React from "react";
import {
    AddCreditsCart,
    CartStore,
    CheckoutProcessEnum,
    GlobalStores,
    IAppContext,
    IProductPriceCalculationService,
    ModalStore,
    ProductBalanceStore,
    ProductCodeProEnum,
    ProductPriceCalculationService,
    PurchaseOptionEnum,
    ReactAppSettings,
    ScopedOrganizationStore,
    ToastProvider,
} from "@atman/business";
import { Amex, Mastercard, Visa } from "../../static/creditCardBrands";
import { AtBaseSelectableCard, FormGroupSection, OrderSummary } from "../../components";
import { AtCheckboxBase, AtLink, AtSubtitle, AtTitle, AtTitleSubtitle, LoadingIndicator } from "@atman/design-system";
import { BaseForm, IBaseFormProps } from "../../components/BaseForm";
import { BaseModal } from "../../components/BaseModal";
import { BillingModalHelpMessage } from "../components/BillingModalHelpMessage";
import { CreditsBalanceSelector } from "../components/CreditsBalanceSelector";
import { CustomTextInput } from "../../components/CustomTextInput";
import { ExpandableRadioInput } from "../../components/ExpandableRadioInput";
import { Label } from "reactstrap";
import { PackageBundleItem } from "../components/PackageBundleItem";
import { ReactStripeElements, injectStripe } from "react-stripe-elements";
import { StripeCreditCardForm } from "../../components/StripeElements/StripeCreditCardForm";
import { action, computed, observable, reaction, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";
import autobind from "autobind-decorator";

type CustomId = "custom";

export interface IAddCreditsProps extends IAppContext, ReactStripeElements.InjectedStripeProps {
    modalStore?: ModalStore;
    productBalanceStore?: ProductBalanceStore;
    cartStore?: CartStore;
    scopedOrganizationStore?: ScopedOrganizationStore;
}

@inject(
    GlobalStores.modalStore,
    GlobalStores.productBalanceStore,
    GlobalStores.cartStore,
    GlobalStores.scopedOrganizationStore,
)
@observer
class AddCreditsComp extends BaseForm<IAddCreditsProps, {}> {
    private readonly newCardValue: string = "newCard";
    private readonly customId: CustomId = "custom";
    private readonly productPriceCalculationService: IProductPriceCalculationService;
    @observable public selectedProductDefinitionCode: ProductCodeProEnum | CustomId;
    @observable public selectedPackageSku: string = "";
    @observable public checkoutProcess: CheckoutProcessEnum;
    @observable public zipCode: string = "";
    @observable public saveCard: boolean = false;
    @observable public cardLabel: string = "";
    @observable public selectedCustomerCardId: string = "newCard";
    @observable public isProcessingStripeTransaction: boolean = false;

    constructor(props: IBaseFormProps & IAddCreditsProps) {
        super(props);

        const allowCreditCardBilling = props.productBalanceStore!.purchaseOptions.some(
            (x) => x === PurchaseOptionEnum.CreditCard,
        );

        this.checkoutProcess = allowCreditCardBilling ? CheckoutProcessEnum.CreditCard : CheckoutProcessEnum.PayLater;
        this.selectedCustomerCardId =
            props.productBalanceStore!.customerCards.length > 0
                ? props.productBalanceStore!.customerCards[0].cardId
                : this.newCardValue;
        this.productPriceCalculationService = new ProductPriceCalculationService(
            props.productBalanceStore!,
            props.scope,
            this.userAccountAddress,
        );
    }

    @computed
    get userAccountAddress() {
        const { scopedOrganizationStore } = this.props;

        const address = scopedOrganizationStore!.scopedOrganization?.billingInformation?.address;

        if (!address || !address.country) {
            throw new Error("Couldn't find a country set for the customer");
        }

        const country = ReactAppSettings.appModel.countries.find(
            (x) => x.code.toUpperCase() === address!.country.toUpperCase(),
        );

        if (!country) {
            throw new Error(`Couldn't find the country matching the code: ${address!.country}`);
        }

        if (country.states.any() && !address.state) {
            throw new Error("Couldn't find a state set for the customer");
        }

        return address;
    }

    selectedPackageSkuReaction = reaction(
        () => this.selectedPackageSku,
        () => this.updateCart(),
    );

    checkoutProcessReaction = reaction(
        () => this.checkoutProcess,
        (value) => this.updateCheckoutProcess(),
    );

    private updateCart() {
        const cart = this.props.cartStore!.appCarts["addCredits"] as AddCreditsCart;

        let updatedBalance: Dictionary<ProductCodeProEnum, number> = {};

        if (this.selectedPackageSku !== this.customId) {
            const skuDetails = this.props.productBalanceStore!.availableProductSkus.find(
                (x) => x.sku === this.selectedPackageSku,
            );

            if (!skuDetails) {
                return;
            }

            updatedBalance = {
                [skuDetails.productCode]: Number(skuDetails.sku.split("-")[1]),
            };
        }

        this.props.cartStore!.setCart("addCredits", {
            ...cart,
            balance: updatedBalance,
            selectedPackageSku: this.selectedPackageSku,
            checkoutProcess: this.checkoutProcess,
        } as AddCreditsCart);
    }

    private updateCheckoutProcess() {
        const cart = this.props.cartStore!.appCarts["addCredits"] as AddCreditsCart;

        this.props.cartStore!.setCart("addCredits", {
            ...cart,
            checkoutProcess: this.checkoutProcess,
        } as AddCreditsCart);
    }

    componentDidMount(): void {
        if (this.props.productBalanceStore!.availableProductDefinitions.any()) {
            const defaultProductDefinition = this.props.productBalanceStore!.availableProductDefinitions[0];

            this.selectedProductDefinitionCode = defaultProductDefinition.productCode;
            this.selectedPackageSku = defaultProductDefinition.skus[0].sku;
        }
    }

    @autobind
    async processTransaction(totalAmount: number) {
        let errorResult: any;

        switch (this.checkoutProcess) {
            case CheckoutProcessEnum.CreditCard:
                if (this.selectedCustomerCardId && this.selectedCustomerCardId !== this.newCardValue) {
                    await this.props.cartStore!.processAddCreditsByCreditCardTransaction(
                        this.selectedCustomerCardId,
                        totalAmount,
                        this.cardLabel,
                    );
                } else {
                    runInAction(() => {
                        this.isProcessingStripeTransaction = true;
                    });

                    const { token } = await this.props.stripe!.createToken({ address_zip: this.zipCode });

                    if (!token) {
                        ToastProvider.error("stripeTokenCreationErrorMessage".localize());

                        runInAction(() => {
                            this.isProcessingStripeTransaction = false;
                        });

                        return;
                    }

                    await this.props.cartStore!.processAddCreditsByCreditCardTransaction(
                        token.id,
                        totalAmount,
                        this.cardLabel,
                    );

                    runInAction(() => {
                        this.isProcessingStripeTransaction = false;
                    });
                }
                break;
            case CheckoutProcessEnum.PayLater:
                errorResult = await this.props.cartStore!.processAddCreditsByInvoicingTransaction(totalAmount);
                break;
            default:
                throw new Error(`Unhandled CheckoutProcess ${this.checkoutProcess}`);
        }

        if (!this.props.cartStore!.hasErrored) {
            this.props.modalStore!.toggleModal();
        }

        if (errorResult && typeof errorResult === "string") {
            return errorResult;
        }

        return undefined;
    }

    @autobind
    async _onSave() {
        // deprecated
    }

    validateForm(): boolean {
        return false;
    }

    @action.bound
    onSelectedPackageSkuChanged = (event: React.FormEvent<HTMLInputElement>) => {
        this.selectedPackageSku = (event.target as any).value;
    };

    @action.bound
    onCheckoutProcessChanged = (event: React.FormEvent<HTMLInputElement>) => {
        const checkoutProcess = Number((event.target as any).value) as CheckoutProcessEnum;
        this.checkoutProcess = checkoutProcess;

        if (checkoutProcess === CheckoutProcessEnum.PayLater) {
            this.selectedCustomerCardId = "";
        }
    };

    @action.bound
    onCustomerCardChanged = (event: React.FormEvent<HTMLInputElement>) => {
        this.selectedCustomerCardId = (event.target as any).value;
        this.checkoutProcess = CheckoutProcessEnum.CreditCard;
    };

    @action.bound
    onAddNewCardClick = () => {
        this.selectedCustomerCardId = "";
    };

    @action.bound
    onSaveLaterCheckboxCheck = () => {
        this.saveCard = !this.saveCard;
    };

    @action
    onProductDefinitionCodeChange = (event: React.FormEvent<HTMLInputElement>) => {
        const { productBalanceStore } = this.props;

        if (event.currentTarget.value === this.customId) {
            this.setToCustomPackage();
            return;
        }

        this.selectedProductDefinitionCode = Number(event.currentTarget.value);
        const productDefinition = productBalanceStore!.availableProductDefinitions.find(
            (x) => x.productCode === this.selectedProductDefinitionCode,
        );

        this.selectedPackageSku = productDefinition?.skus[0].sku ?? "";
    };

    @action
    setToCustomPackage = () => {
        this.selectedProductDefinitionCode = this.customId;
        this.selectedPackageSku = this.customId;
    };

    renderProductDefinitionSelectionSection() {
        const { productBalanceStore } = this.props;

        const bundleProductDefinitions = productBalanceStore!.availableProductDefinitions.filter((x) => x.isBundle);
        const flexBasis = `${(1 / (bundleProductDefinitions.length + 1)) * 100}%`;

        return (
            <>
                {bundleProductDefinitions.map((x) => (
                    <AtBaseSelectableCard
                        key={`product-definition-${x.productCode}`}
                        inputName={"product-definition-selection"}
                        inputId={`${x.productCode}-package`}
                        inputValue={x.productCode}
                        onChange={this.onProductDefinitionCodeChange}
                        active={this.selectedProductDefinitionCode === x.productCode}
                        className="BundleProductItem"
                        style={{ flexBasis }}
                    >
                        <AtTitleSubtitle
                            title={`global.productCodes.${ProductCodeProEnum[x.productCode].toCamel()}`.localize()}
                            subtitle={`global.productCodes.${ProductCodeProEnum[
                                x.productCode
                            ].toCamel()}.description`.localize()}
                        />
                    </AtBaseSelectableCard>
                ))}
                <AtBaseSelectableCard
                    inputName={"product-definition-selection"}
                    inputId={`${this.customId}-package`}
                    inputValue={this.customId}
                    onChange={this.onProductDefinitionCodeChange}
                    active={this.selectedProductDefinitionCode === this.customId}
                    className="BundleProductItem"
                    style={{ flexBasis }}
                >
                    <AtTitleSubtitle
                        title={"global.customPackage".localize()}
                        subtitle={"global.customPackage.description".localize()}
                    />
                </AtBaseSelectableCard>
            </>
        );
    }

    renderBundleSelectionSection() {
        const { productBalanceStore } = this.props;

        if (!productBalanceStore!.availableProductDefinitions.any()) {
            return <LoadingIndicator />;
        }

        const selectedProductDefinition =
            this.selectedProductDefinitionCode === this.customId
                ? undefined
                : productBalanceStore!.availableProductDefinitions.find(
                      (x) => x.productCode === this.selectedProductDefinitionCode,
                  );

        return (
            <FormGroupSection
                sectionTitle={"global.selectYourBundle".localize()}
                icon={["far", "shopping-cart"]}
                id={"select-bundle-step"}
            >
                <div className="bundle-products-section">
                    <AtSubtitle
                        className="section-description"
                        subtitle={"global.addProducts.bundleSelection.description".localize()}
                    />
                    <div className="bundle-products-container">{this.renderProductDefinitionSelectionSection()}</div>
                </div>
                <div className="content-section">
                    {selectedProductDefinition ? (
                        <>
                            <AtTitle
                                className={"section-title"}
                                title={"global.addProducts.contentSection.prebuiltBundle.description".localize()}
                                headingType={4}
                            />
                            <div className="product-packages-container">
                                {ReactAppSettings.appModel.defaultBundleQuantities.map((x, i) => {
                                    const matchingSku = selectedProductDefinition.skus.find((p) => p.quantity === x);

                                    if (!matchingSku) {
                                        return null;
                                    }

                                    const flexBasis = `${
                                        (1 / ReactAppSettings.appModel.defaultBundleQuantities.length) * 100
                                    }%`;

                                    const { skuDetails, discountRate } =
                                        this.productPriceCalculationService.getPackageProductAndVolumeDiscountRate(
                                            matchingSku.sku,
                                        );

                                    return (
                                        <PackageBundleItem
                                            skuDetails={skuDetails}
                                            discountRate={discountRate}
                                            checked={
                                                this.selectedPackageSku
                                                    ? this.selectedPackageSku === skuDetails.sku
                                                    : i === 0
                                            }
                                            onChange={this.onSelectedPackageSkuChanged}
                                            style={{ flexBasis }}
                                            key={i}
                                        />
                                    );
                                })}
                            </div>
                            <div className="customize-bundle-link-container">
                                <AtLink
                                    className="customize-bundle-link"
                                    onClick={() => this.setToCustomPackage()}
                                    color={"secondary"}
                                >
                                    {"global.addProducts.customizeYourBundle".localize()}
                                </AtLink>
                            </div>
                        </>
                    ) : (
                        <>
                            <AtTitle
                                className={"section-title"}
                                title={"global.addProducts.contentSection.customBundle.title".localize()}
                                headingType={4}
                            />
                            <div className="custom-bundle-item-container">
                                <CreditsBalanceSelector
                                    cartKey={"addCredits"}
                                    currentBalance={productBalanceStore!.balance}
                                />
                            </div>
                        </>
                    )}
                </div>
            </FormGroupSection>
        );
    }

    getLogoForBrand = (brand: string) => {
        let logoForBrand;

        switch (brand) {
            case "Visa":
                logoForBrand = Visa;
                break;
            case "MasterCard":
                logoForBrand = Mastercard;
                break;
            case "American Express":
                logoForBrand = Amex;
                break;
        }

        return logoForBrand;
    };

    renderPaymentMethodsSection() {
        const { productBalanceStore } = this.props;

        const allowCreditCardBilling = productBalanceStore!.purchaseOptions.some(
            (x) => x === PurchaseOptionEnum.CreditCard,
        );
        const allowInvoiceBilling = productBalanceStore!.purchaseOptions.some((x) => x === PurchaseOptionEnum.Invoice);

        return (
            <FormGroupSection
                sectionTitle={"global.paymentMethod".localize()}
                icon={["far", "credit-card-front"]}
                id={"payment-method-step"}
            >
                {allowCreditCardBilling && (
                    <>
                        {productBalanceStore!.customerCards.map((x, i) => (
                            <ExpandableRadioInput
                                internalKey={x.cardId}
                                key={i}
                                value={x.cardId}
                                checked={this.selectedCustomerCardId === x.cardId}
                                onChange={this.onCustomerCardChanged}
                                customLabelContent={
                                    <span className="label credit-card-label">
                                        <img
                                            height={32}
                                            src={this.getLogoForBrand(x.brand)}
                                            alt={x.brand}
                                            className={"card-logo"}
                                        />
                                        {`${x.label ?? "global.unknown".localize()} (**** **** **** ${x.last4Digits})`}
                                    </span>
                                }
                            />
                        ))}
                        <ExpandableRadioInput
                            internalKey={"newCard"}
                            value={this.newCardValue}
                            checked={this.selectedCustomerCardId === this.newCardValue}
                            onChange={this.onCustomerCardChanged}
                            labelKey={"global.addNewCard"}
                        >
                            <div className="credit-card-form">
                                <StripeCreditCardForm
                                    zipCodeValue={this.zipCode}
                                    onZipCodeChange={this.onTextFieldChange}
                                />
                                <Label check className={"save-card-checkbox-label"}>
                                    <AtCheckboxBase checked={this.saveCard} onChange={this.onSaveLaterCheckboxCheck} />{" "}
                                    {"global.saveCardCheckbox".localize()}
                                </Label>
                                {this.saveCard ? (
                                    <div className="label-field-container">
                                        <CustomTextInput
                                            fieldName={"cardLabel"}
                                            value={this.cardLabel}
                                            onChange={this.onTextFieldChange}
                                            placeholder={"global.business".localize()}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </ExpandableRadioInput>
                    </>
                )}
                {allowInvoiceBilling && (
                    <ExpandableRadioInput
                        internalKey={"payLaterByInvoice"}
                        labelKey={"global.payLaterByInvoice"}
                        value={CheckoutProcessEnum.PayLater}
                        checked={this.checkoutProcess === CheckoutProcessEnum.PayLater}
                        onChange={this.onCheckoutProcessChanged}
                    >
                        <div className="text-summary">{"global.payLaterByInvoiceTextSummary".localize()}</div>
                    </ExpandableRadioInput>
                )}
            </FormGroupSection>
        );
    }

    render() {
        const {} = this.props;

        return (
            <BaseModal modalTitle={"global.addProducts".localize()} id={"AddCredits"}>
                <BillingModalHelpMessage />
                {this.renderBundleSelectionSection()}
                {this.renderPaymentMethodsSection()}
                <FormGroupSection
                    sectionTitle={"global.orderSummary".localize()}
                    icon={["far", "receipt"]}
                    id={"order-summary-section"}
                >
                    <OrderSummary
                        onSave={this.processTransaction}
                        isProcessingStripeTransaction={this.isProcessingStripeTransaction}
                    />
                </FormGroupSection>
            </BaseModal>
        );
    }
}

const AddCredits = withAppContext(injectStripe(AddCreditsComp));

export { AddCredits };
