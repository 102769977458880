import "./index.less";
import { AtTabCard } from "@atman/design-system";
import { CardSaveButtons } from "../CardSaveButtons";
import { CustomTextInput } from "../CustomTextInput";
import { GlobalStores, IPartnerMarketingInfo, IndustryEnum, PartnerStore } from "@atman/business";
import { action, observable, toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import autobind from "autobind-decorator";

export interface IPartnerMarketingInfoFormProps {
    ownerId: string;
    marketingInfo: IPartnerMarketingInfo;
    partnerStore?: PartnerStore;
}

@inject(GlobalStores.partnerStore)
@observer
export class PartnerMarketingInfoForm extends React.Component<IPartnerMarketingInfoFormProps, {}> {
    @observable public marketingInfo: IPartnerMarketingInfo = {
        turnoverRate: 0,
        expectedInterviewPerHire: 0,
        expectedAnnualHiresCount: 0,
        expectedAnnualGrowth: 0,
        employeesCount: 0,
        marketingType: IndustryEnum.Reseller,
        platformUses: [],
    };

    componentDidMount(): void {
        this.resetMarketingInfo();
    }

    @autobind
    async saveMarketingInfo() {
        await this.props.partnerStore!.updatePartnerMarketingInfo(this.props.ownerId, {
            ...this.props.marketingInfo,
            ...this.marketingInfo,
        });
    }

    @action.bound
    resetMarketingInfo = () => {
        if (this.props.marketingInfo) {
            this.marketingInfo = this.props.marketingInfo;
        } else {
            this.marketingInfo = {
                turnoverRate: 0,
                expectedInterviewPerHire: 0,
                expectedAnnualHiresCount: 0,
                expectedAnnualGrowth: 0,
                employeesCount: 0,
                marketingType: IndustryEnum.Reseller,
                platformUses: [],
            };
        }
    };

    @action.bound
    onMarketingInfoChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as any;

        if (toJS(this.marketingInfo).hasOwnProperty(target.id)) {
            this.marketingInfo[target.id] = target.value;
        }
    };

    render() {
        const { partnerStore } = this.props;

        return (
            <AtTabCard
                cardTitle={"global.marketingInfo".localize()}
                id={"PartnerMarketingInfoForm"}
                className={"additional-information-form"}
            >
                <div className="inputs">
                    <CustomTextInput
                        fieldName={"employeesCount"}
                        label={"global.employeesCount".localize()}
                        value={this.marketingInfo.employeesCount}
                        onChange={this.onMarketingInfoChange}
                    />
                    <CustomTextInput
                        fieldName={"expectedAnnualHiresCount"}
                        label={"global.expectedAnnualHiresCount".localize()}
                        value={this.marketingInfo.expectedAnnualHiresCount}
                        onChange={this.onMarketingInfoChange}
                    />
                    <CustomTextInput
                        fieldName={"expectedInterviewPerHire"}
                        label={"global.expectedInterviewPerHire".localize()}
                        value={this.marketingInfo.expectedInterviewPerHire}
                        onChange={this.onMarketingInfoChange}
                    />
                    <CustomTextInput
                        fieldName={"expectedAnnualGrowth"}
                        label={"global.expectedAnnualGrowth".localize()}
                        value={this.marketingInfo.expectedAnnualGrowth}
                        onChange={this.onMarketingInfoChange}
                    />
                    <CustomTextInput
                        fieldName={"turnoverRate"}
                        label={"global.turnoverRate".localize()}
                        value={this.marketingInfo.turnoverRate}
                        onChange={this.onMarketingInfoChange}
                    />
                </div>
                <CardSaveButtons
                    saveAction={this.saveMarketingInfo}
                    cancelAction={this.resetMarketingInfo}
                    isLoading={partnerStore!.isLoading}
                />
            </AtTabCard>
        );
    }
}
