import "./AtSubScaleNavigationItem.less";
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ReactNode } from "react";

export interface IAtSubScaleNavigationItemProps {
    selected: boolean;
    icon: IconProp;
    onClick?: React.MouseEventHandler<any>;
    children: ReactNode;
}

export const AtSubScaleNavigationItem: React.FC<IAtSubScaleNavigationItemProps> = (props) => {
    const { selected, icon, children, onClick } = props;

    return (
        <div className="AtSubScaleNavigationItem">
            <div className={`item-container ${selected ? "selected" : ""}`} onClick={onClick}>
                <div className="item-icon-container">
                    <FontAwesomeIcon icon={icon} />
                </div>
                <span>{children}</span>
            </div>
        </div>
    );
};
