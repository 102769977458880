import "./index.less";
import { AtContainer, Sizing } from "@atman/design-system";
import { IAtNewTabNavigationItem, TabNavItem } from "../..";
import { observer } from "mobx-react";
import React from "react";
import cn from "classnames";

export interface ITabNav {
    isContainerized: boolean;
    authorizedNavigationItems: IAtNewTabNavigationItem[];
    url: string;
    headerView?: JSX.Element;
    isHiddenTabs?: boolean;
}

export const TabNav: React.FC<ITabNav> = observer((props: ITabNav) => {
    const { headerView, authorizedNavigationItems, url, isContainerized, isHiddenTabs = false } = props;

    const content = (
        <>
            {headerView && <div className={cn("header-view", isHiddenTabs ? "hideTabs" : "")}>{headerView}</div>}
            {isHiddenTabs !== true ? (
                <div className="filters-header">
                    {authorizedNavigationItems.map(({ name, href, ...otherProps }, i) => (
                        <TabNavItem name={name} key={i} href={`${url}${href}`} {...otherProps} />
                    ))}
                </div>
            ) : null}
        </>
    );

    const className = cn("TabNav", { isContainerized: isContainerized });

    if (isContainerized) {
        return (
            <AtContainer padding={[Sizing.pixels.sm, Sizing.pixels.md, 0, Sizing.pixels.md]} className={className}>
                {content}
            </AtContainer>
        );
    } else {
        return <div className={cn(className, "standalone")}>{content}</div>;
    }
});
