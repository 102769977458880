import "./index.less";
import * as React from "react";
import { ReportType } from "@atman/business";

export interface IAtSubScaleNavigationProps {
    navigationItems: React.ReactNode[] | React.ReactNode;
    children: React.ReactNode;
    reportType?: ReportType;
}

export const AtSubScaleNavigation: React.FC<IAtSubScaleNavigationProps> = (props) => {
    const { navigationItems, children, reportType } = props;

    return (
        <div className="AtSubScaleNavigation">
            {reportType === ReportType.TeamDistribution || reportType === ReportType.Competencies ? null : (
                <div className="filters-header">{navigationItems}</div>
            )}

            <div className="content">{children}</div>
        </div>
    );
};
