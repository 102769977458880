import { AtBadgeColor } from "@atman/design-system";
import {
    CompetencyDevelopmentAssessmentStatus,
    CompetencyDevelopmentAssessmentType,
    CompetencyDevelopmentProcessData,
    CompetencyDevelopmentProcessStatus,
    ICompetencyDevelopmentProcessAssessmentData,
} from "@atman/business";
import { t } from "@lingui/macro";

export type ProcessStatusType =
    // Not Started
    | undefined
    | CompetencyDevelopmentProcessStatus.Started
    | "InProgress"
    | CompetencyDevelopmentProcessStatus.Completed
    | CompetencyDevelopmentProcessStatus.Cancelled
    | CompetencyDevelopmentProcessStatus.Closed;

const checkProcessIsInProgress = (
    assessmentsData: Map<CompetencyDevelopmentAssessmentType, ICompetencyDevelopmentProcessAssessmentData>,
): boolean => {
    const isInProgress = Array.from(assessmentsData, ([_, assessment]) => ({ ...assessment })).some(
        (assessment) => assessment?.status > CompetencyDevelopmentAssessmentStatus.NotStarted && assessment.dateStarted,
    );
    return isInProgress;
};
export const computeProcessStatus = (process?: CompetencyDevelopmentProcessData): ProcessStatusType => {
    switch (process?.status) {
        case CompetencyDevelopmentProcessStatus.Started:
            return checkProcessIsInProgress(process?.assessmentsData) ? "InProgress" : process?.status;
        default:
            return process?.status;
    }
};

export const computeProcessStatusFormEmployee = (status?: CompetencyDevelopmentProcessStatus): ProcessStatusType => {
    return status;
};

export const getColorLabelForProcessStatus = (status: ProcessStatusType): { label: string; color: AtBadgeColor } => {
    switch (status) {
        case CompetencyDevelopmentProcessStatus.Started:
            return {
                label: t({
                    id: `competencyDevelopment.assessment.status.started`,
                    message: "Started",
                }),
                color: "primary",
            };
        case "InProgress":
            return {
                label: t({
                    id: `competencyDevelopment.assessment.status.inProgress`,
                    message: "In progress",
                }),
                color: "warning",
            };

        case CompetencyDevelopmentProcessStatus.Completed:
            return {
                label: t({
                    id: `competencyDevelopment.assessment.status.completed`,
                    message: "Completed",
                }),
                color: "success",
            };

        case CompetencyDevelopmentProcessStatus.Cancelled:
            return {
                label: t({
                    id: `competencyDevelopment.assessment.status.cancelled`,
                    message: "Cancelled",
                }),
                color: "danger",
            };

        default:
            return {
                label: t({
                    id: `competencyDevelopment.assessment.status.notStarted`,
                    message: "Not started",
                }),
                color: "ghost",
            };
    }
};
