import "./index.less";
import * as React from "react";
import { AtLink, IAtLinkProps } from "../../../internal";
import { PropsWithChildren } from "react";
import { observer } from "mobx-react";

export interface IAtAsyncLinkProps<TAsyncActionResult = any> extends Omit<IAtLinkProps, "isLoading" | "onClick"> {
    asyncAction: () => Promise<TAsyncActionResult>;
    callbackAction: (result: TAsyncActionResult) => void;
}

/**
 * AtAsyncLink - Link Component - Async version
 * Category: Molecule
 *
 * @param props {IAtAsyncLinkProps}
 **/
export const AtAsyncLink = observer(
    <TAsyncActionResult extends unknown>(props: PropsWithChildren<IAtAsyncLinkProps<TAsyncActionResult>>) => {
        const { asyncAction, callbackAction, className, children, ...otherProps } = props;

        const [isLoading, setIsLoading] = React.useState(false);

        const onClick = async () => {
            setIsLoading(true);

            const result = await asyncAction();

            setIsLoading(false);

            callbackAction(result);
        };

        let classNames = "AtAsyncLink";

        if (className) {
            classNames += ` ${className}`;
        }

        return (
            <AtLink className={classNames} {...otherProps} onClick={onClick} isLoading={isLoading}>
                {children}
            </AtLink>
        );
    },
);
