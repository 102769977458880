import "./EntityWizardOverview.less";
import { AtButton, AtIcon, AtIconLabelTitle, AtTitle } from "@atman/design-system";
import { Colors } from "../../../styles";
import { IAtWizardSidePanelProps } from "../..";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ItemType, itemTypeToIcon } from "@atman/business";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import React from "react";
import moment from "moment";

export interface IEntityWizardOverviewProps extends Partial<IAtWizardSidePanelProps> {
    itemType: ItemType;
    entityName: string | undefined;
    overviewContent: React.ReactNode;
    buttonLabel?: string;
    onSubmit?: () => Promise<void>;
    updatedAt?: string;
}

export const EntityWizardOverview: React.FC<IEntityWizardOverviewProps> = observer((props) => {
    const { itemType, entityName, overviewContent, updatedAt, buttonLabel, onSubmit, isLastStep } = props;

    let icon: IconProp | undefined = itemTypeToIcon.get(itemType);

    if (!icon) {
        icon = "question-circle";
    }

    return (
        <div className="EntityWizardOverview">
            <div className="top-content">
                <div className="header">
                    <AtTitle
                        title={t({
                            id: `global.entityWizard.itemTypes.${ItemType[itemType].toCamel()}.overview`,
                        })}
                        headingType={2}
                    />
                    <AtIconLabelTitle
                        icon={
                            <AtIcon
                                icon={icon}
                                color={Colors.atmanOrange}
                                bgColor={Colors.atmanOrangeFaded}
                                size="2x"
                            />
                        }
                        label={`${t({
                            id: `global.entityWizard.updatedOn`,
                        })} ${moment(updatedAt).format("D MMM YYYY")}`}
                        title={entityName ?? t({ id: "global.unknown" })}
                        size="sm"
                    />
                </div>
                <div className="content">{overviewContent}</div>
            </div>
            <div className="footer">
                <AtButton fullWidth onClick={onSubmit} disabled={!isLastStep}>
                    {buttonLabel ?? t({ id: "global.save" })}
                </AtButton>
            </div>
        </div>
    );
});
