// This is an objective interface
import { observable } from "mobx";

export interface ISelectedObjectiveData {
    selectedLearningResourceIds: string[];
    selectedApplicationIds: string[];
    learningResourceNotes: string;
    applicationNotes: string;
}

export interface ISelectedObjectiveToImproveData {
    selectedApplicationIds: string[];
    applicationNotes: string;
}

export interface ISelectedSubCompetencyToImproveData {
    selectedLearningResourceIds: string[];
    learningResourceNotes: string;
    selectedObjectivesToImprove: Record<string, ISelectedObjectiveToImproveData>;
}

export class SelectedSubCompetencyToImproveData {
    @observable public learningResourceNotes: string;
    @observable public selectedLearningResourceIds: string[];
    @observable public selectedObjectivesToImprove: Map<string, ISelectedObjectiveToImproveData>;

    constructor(json: ISelectedSubCompetencyToImproveData) {
        this.learningResourceNotes = json.learningResourceNotes;
        this.selectedLearningResourceIds = json.selectedLearningResourceIds;
        this.selectedObjectivesToImprove = new Map<string, ISelectedObjectiveToImproveData>(
            Object.keys(json.selectedObjectivesToImprove).map((x) => [x, json.selectedObjectivesToImprove[x]]),
        );
    }
}
