import "./index.less";
import * as React from "react";
import { AtButton, AtContainer, AtTitle, AtTitleSubtitle, Colors, TextCopyToClipboard } from "@atman/design-system";
import { BaseForm, CardSaveButtons, CustomTextInput } from "../";
import {
    GlobalStores,
    ISamlInformation,
    ScopedOrganizationStore,
    SingleSignOnApi,
    ToastProvider,
} from "@atman/business";
import { action, observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";

export interface ISsoConfigurationFormProps {
    scopedOrganizationStore?: ScopedOrganizationStore;
}

@inject(GlobalStores.scopedOrganizationStore)
@observer
export class SsoConfigurationForm extends BaseForm<ISsoConfigurationFormProps> {
    @observable private providerName: string;
    @observable private samlEndpointUrl: string;
    @observable private certificate: string;

    @observable private samlInformation: ISamlInformation;

    componentDidMount() {
        super.componentDidMount();

        this.resetSamlConfig();
        this.loadSamlInformation();
    }

    @action private resetSamlConfig = () => {
        const { scopedOrganizationStore } = this.props;

        if (!scopedOrganizationStore!.scopedOrganizationAuthConfig) {
            return;
        }

        this.providerName = scopedOrganizationStore!.scopedOrganizationAuthConfig.samlConfiguration?.providerName ?? "";
        this.samlEndpointUrl =
            scopedOrganizationStore!.scopedOrganizationAuthConfig.samlConfiguration?.samlEndpointUrl ?? "";
        this.certificate = scopedOrganizationStore!.scopedOrganizationAuthConfig.samlConfiguration?.certificate ?? "";
    };

    @action private loadSamlInformation = async () => {
        const result = await SingleSignOnApi.getSamlInformation();

        if (result) {
            runInAction(() => {
                this.samlInformation = result;
            });
        }
    };

    private downloadMetadataFile = () => {
        SingleSignOnApi.getSamlMetadataFile();
    };

    private validateSamlConfigForm = (): boolean => {
        return !!this.providerName && !!this.samlEndpointUrl && !!this.certificate;
    };

    @action private onSamlConfigSave = async () => {
        const { scopedOrganizationStore } = this.props;

        if (!this.validateSamlConfigForm()) {
            return;
        }

        this.setIsLoading(true);

        await scopedOrganizationStore!.updateSamlConfiguration({
            providerName: this.providerName,
            samlEndpointUrl: this.samlEndpointUrl,
            certificate: this.certificate,
        });

        this.setIsLoading(false);
    };

    render() {
        const {} = this.props;

        const onTextCopied: () => void = () => {
            ToastProvider.success("global.textCopySuccessMessage".localize());
        };

        return (
            <div id="SsoConfigurationForm">
                <AtContainer
                    id="SamlConfigurationForm"
                    header={
                        <AtTitleSubtitle
                            title={"global.sso.configurationForm.updateSsoConfiguration".localize()}
                            subtitle={"global.sso.configurationForm.updateSsoConfiguration.description".localize()}
                            size="xl"
                        />
                    }
                >
                    <div className="sso-form">
                        <CustomTextInput
                            fieldName="providerName"
                            value={this.providerName}
                            label={"global.sso.configurationForm.providerName".localize()}
                            placeholder={"global.sso.configurationForm.providerName.placeholder".localize()}
                            onChange={this.onTextFieldChange}
                            formText={"global.sso.configurationForm.providerName.infoText".localize()}
                        />
                        <CustomTextInput
                            fieldName="samlEndpointUrl"
                            value={this.samlEndpointUrl}
                            label={"global.sso.configurationForm.samlEndpointUrl".localize()}
                            placeholder={"global.sso.configurationForm.samlEndpointUrl.placeholder".localize()}
                            onChange={this.onTextFieldChange}
                            formText={"global.sso.configurationForm.samlEndpointUrl.infoText".localize()}
                        />
                        <CustomTextInput
                            fieldName="certificate"
                            value={this.certificate}
                            label={"global.sso.configurationForm.certificate".localize()}
                            placeholder={"global.sso.configurationForm.certificate.placeholder".localize()}
                            onChange={this.onTextFieldChange}
                            formText={"global.sso.configurationForm.certificate.infoText".localize()}
                            type="textarea"
                        />
                    </div>
                    <div className="information-fields text-left">
                        <div className="metadata-export">
                            <AtTitle title={"global.sso.samlMetadata".localize()} headingType={4} />
                            <AtButton
                                icon={["far", "arrow-to-bottom"]}
                                iconProps={{ color: Colors.primaryBase }}
                                color="secondary"
                                size="lg"
                                onClick={this.downloadMetadataFile}
                            >
                                {"global.sso.downloadMetadata".localize()}
                            </AtButton>
                        </div>
                        <TextCopyToClipboard
                            label={"global.sso.ssoEntityId".localize()}
                            text={this.samlInformation?.ssoEntityId ?? ""}
                            onCopy={onTextCopied}
                        />
                        <TextCopyToClipboard
                            label={"global.loginUrl".localize()}
                            text={this.samlInformation?.loginUrl ?? ""}
                            onCopy={onTextCopied}
                        />
                        <TextCopyToClipboard
                            label={"global.logoutUrl".localize()}
                            text={this.samlInformation?.logoutUrl ?? ""}
                            onCopy={onTextCopied}
                        />
                        <TextCopyToClipboard
                            label={"global.replyUrl.user".localize()}
                            iconTooltipContent={"global.replyUrl.user.tooltipContent".localize()}
                            text={this.samlInformation?.userReplyUrl ?? ""}
                            onCopy={onTextCopied}
                        />
                        <TextCopyToClipboard
                            label={"global.replyUrl.candidate".localize()}
                            iconTooltipContent={"global.replyUrl.candidate.tooltipContent".localize()}
                            text={this.samlInformation?.candidateReplyUrl ?? ""}
                            onCopy={onTextCopied}
                        />
                    </div>
                    <CardSaveButtons
                        saveAction={this.onSamlConfigSave}
                        cancelAction={this.resetSamlConfig}
                        disabledSaveButton={!this.validateSamlConfigForm()}
                        isLoading={this.isLoading}
                    />
                </AtContainer>
            </div>
        );
    }
}
