import "./AccountDetails.less";
import * as React from "react";
import { CandidatePersonalInformationForm } from "../../../components/CandidatePersonalInformationForm";
import { ChangePasswordForm } from "../../../components/ChangePasswordForm";
import { Col, Container, Row } from "reactstrap";
import { UserContext } from "@atman/business";
import { UserPersonalInformationForm } from "../../../components/UserPersonalInformationForm";
import { observer } from "mobx-react";

export interface IAccountDetailsProps {
    userContext: UserContext;
}

@observer
export class AccountDetails extends React.Component<IAccountDetailsProps, {}> {
    render() {
        const { userContext } = this.props;

        return (
            <div id="AccountDetails">
                <Container style={{ maxWidth: "inherit", padding: 0 }}>
                    <Row noGutters>
                        <Col sm={12} xl={6} id={"personal-information-column"}>
                            {userContext === "user" ? (
                                <UserPersonalInformationForm />
                            ) : (
                                <CandidatePersonalInformationForm />
                            )}
                        </Col>
                        {userContext === "user" && (
                            <Col sm={12} xl={6} id={"change-password-column"}>
                                <ChangePasswordForm />
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>
        );
    }
}
