import "./index.less";
import * as React from "react";
import { AtTitle, InitialsAvatar } from "@atman/design-system";
import { CompetencyDevelopmentColumnType, IPersonInfo, getSelectionProps } from "../../../../../..";
import { ITableHeaderProps, SortDirection, TableHeader } from "../../../../../../../..";
import { observer } from "mobx-react";

export interface IEmployeeNoteTableHeaderColProps {
    employeeInfo: IPersonInfo;
    sortElement?: CompetencyDevelopmentColumnType;
    sortDirection?: SortDirection;
    onSortChange?: (element: CompetencyDevelopmentColumnType) => void;
}

export const EmployeeNoteTableHeaderCol: React.FC<IEmployeeNoteTableHeaderColProps> = observer((props) => {
    const { employeeInfo, sortElement, sortDirection, onSortChange } = props;

    const selectionProps: Pick<ITableHeaderProps, "sortDirection" | "isActive" | "onClick"> | undefined =
        getSelectionProps("employeeScore", sortElement, sortDirection, onSortChange);

    return (
        <TableHeader
            className="employee-note-col"
            {...selectionProps}
            tooltipContent={
                <div style={{ textAlign: "center" }}>
                    <AtTitle headingType={4} title={employeeInfo.displayName} />
                    <div className="textSizeMedium">{"competencyDevelopment.global.employee".localize()}</div>
                </div>
            }
        >
            <InitialsAvatar size="md" text={employeeInfo.displayName} bgColor="#F08752" textColor="#fff" />
        </TableHeader>
    );
});
