import "./index.less";
import * as React from "react";
import { CompetencyDevelopmentColumnType, getSelectionProps } from "../../../../../..";
import { ITableHeaderProps, SortDirection, TableHeader } from "../../../../../../../..";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";

export interface IAverageNoteScaleTableHeaderColProps {
    sortElement?: CompetencyDevelopmentColumnType;
    sortDirection?: SortDirection;
    onSortChange?: (element: CompetencyDevelopmentColumnType) => void;
}

export const AverageNoteScaleTableHeaderCol: React.FC<IAverageNoteScaleTableHeaderColProps> = observer((props) => {
    const { sortElement, sortDirection, onSortChange } = props;

    const selectionProps: Pick<ITableHeaderProps, "sortDirection" | "isActive" | "onClick"> | undefined =
        getSelectionProps("averageScoreScale", sortElement, sortDirection, onSortChange);

    return (
        <TableHeader
            className="average-note-scale-col"
            {...selectionProps}
            tooltipContent={
                <div className="textSizeMedium">{t({ id: "competencyDevelopment.review.tooltips.averageScore" })}</div>
            }
        >
            {"competencyDevelopment.review.averageScoreScale".localize()}
        </TableHeader>
    );
});
