import { BasePlatformRootStore, BaseStore } from "../../base";
import { IEmployeePsychometrics } from "./models";
import { action, observable, runInAction } from "mobx";
import EmployeePsychometricsApi, { IEmployeePsychometricsApi } from "./EmployeePsychometricsApi";

export class EmployeePsychometricsStore extends BaseStore {
    private _api: IEmployeePsychometricsApi;

    constructor(rootStore: BasePlatformRootStore) {
        super(rootStore);

        this._api = new EmployeePsychometricsApi();
    }

    @observable public hasLoadedEmployeePsychometrics: boolean = false;
    @observable public employeePsychometrics: IEmployeePsychometrics | undefined = undefined;

    @action loadEmployeePsychometrics = async (): Promise<void> => {
        const result = await this._api.getEmployeePsychometrics();

        runInAction(() => {
            this.employeePsychometrics = result;
            this.hasLoadedEmployeePsychometrics = true;
        });
    };
}
