import "./index.less";
import * as React from "react";
import { AtTitle } from "@atman/design-system";
import { HelpCenterInfoLink } from "..";

export interface IAtPageHeaderProps {
    label: string;
    description?: string;
    helpCenterArticleId?: string;
    helpCenterUrlLabel?: string;
}

export const AtPageHeader: React.FC<IAtPageHeaderProps> = (props) => {
    const { label, description, helpCenterArticleId, helpCenterUrlLabel, ...otherProps } = props;

    return (
        <div className="AtPageHeader" {...otherProps}>
            <AtTitle className="title" headingType={1} title={label} />
            {description && <div className="description">{description}</div>}
            {helpCenterArticleId && helpCenterUrlLabel && (
                <HelpCenterInfoLink title={helpCenterUrlLabel} href={`articles/${helpCenterArticleId}`} />
            )}
        </div>
    );
};
