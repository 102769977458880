import "./index.less";
import * as React from "react";
import { AtButton, AtCheckboxBase } from "@atman/design-system";
import { AtmanCoPlatformsEnum } from "../../../global/components/ProductSelector";
import { AuthStore } from "../../../data/stores/AuthStore";
import { BaseForm } from "@atman/ui-kit";
import { Form, Label } from "reactstrap";
import { LocalizationStore, UrlFormatter } from "@atman/business";
import { LoginAppStores } from "../../../../index";
import { Trans, t } from "@lingui/macro";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import LoginComponentHeader from "../../../global/components/LoginComponentHeader";

export interface ICookiePolicyProps {
    platform: AtmanCoPlatformsEnum;
    authStore?: AuthStore;
    localizationStore?: LocalizationStore;
}

@inject<LoginAppStores, any, any, any>((stores) => ({
    authStore: stores.authStore,
    localizationStore: stores.localizationStore,
}))
@observer
class CookiePolicy extends BaseForm<ICookiePolicyProps, {}> {
    @observable isAccepted: boolean = false;

    openPDF = () => {
        const { localizationStore } = this.props;

        window.open(
            UrlFormatter.getBlobFileUrl("ServiceLicenseAgreement", localizationStore!.currentShortLocale),
            "_blank",
        );
    };

    @action protected acceptCookiePolicy = async (e: React.FormEvent<HTMLFormElement>) => {
        const { authStore, platform } = this.props;

        e.preventDefault();

        try {
            this.setIsLoading(true);
            await authStore!.acceptCookiePolicy({ platform });
            this.setIsLoading(false);
        } catch (error) {
            this.setIsLoading(false);
            console.log(error);
        }
    };

    render() {
        const {} = this.props;

        return (
            <div id="CookiePolicy">
                <LoginComponentHeader
                    title={t({ id: "loginApp.cookiePolicyForm.title", message: `Updated Terms of Use` })}
                    icon={["far", "shield-alt"]}
                />
                <Form onSubmit={this.acceptCookiePolicy}>
                    <div className="disclaimer-text">
                        <Trans id={"loginApp.cookiePolicyForm.disclaimerText"}>
                            In the spirit of alignment, we’ve adjusted our terms to better reflect the best interests of
                            both our customers and AtmanCo. You can view our new Terms of Use by clicking on the button
                            below.
                        </Trans>
                    </div>
                    <AtButton
                        id={"open-pdf-btn"}
                        onClick={this.openPDF}
                        color={"secondary"}
                        icon={["far", "file-pdf"]}
                        fullWidth
                    >
                        <Trans id={"global.buttons.labels.openPdf"}>Open PDF</Trans>
                    </AtButton>
                    <Label className={"agreement-checkbox-container"}>
                        <AtCheckboxBase
                            checked={this.isAccepted}
                            onChange={this.onCheckboxChange}
                            value={this.isAccepted}
                            fieldName={"isAccepted"}
                            id={"agreement-checkbox"}
                        />
                        <span className="label">
                            <Trans id={"loginApp.cookiePolicyForm.agreementCheckboxLabel"}>
                                I agree with the terms and conditions
                            </Trans>
                        </span>
                    </Label>
                    <AtButton type={"submit"} fullWidth isLoading={this.isLoading} disabled={!this.isAccepted}>
                        <Trans id={"global.buttons.labels.confirm"}>Confirm</Trans>
                    </AtButton>
                </Form>
            </div>
        );
    }
}

export default CookiePolicy;
