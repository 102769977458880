import "./index.less";
import { DefaultComponentProps, ExtraAndDefaultSizes, Typography } from "../../../internal";
import FormText from "reactstrap/lib/FormText";
import React from "react";

export interface IAtSubLabelProps extends DefaultComponentProps {
    size?: ExtraAndDefaultSizes;
}

/**
 * AtSubLabel - Sub Label Component
 * Category: Atom
 *
 * @param props {IAtSubLabelProps}
 **/
export const AtSubLabel: React.FC<IAtSubLabelProps> = (props) => {
    const { size = "md", className, id, style, children } = props;

    const getClassNames = () => {
        let classNames = "AtSubLabel";

        if (className) {
            classNames += ` ${className}`;
        }

        return classNames;
    };

    const getStyles = () => {
        const styles = {
            fontSize: Typography.getFontSizePropValue(size),
            lineHeight: Typography.lineHeight,
        };

        return {
            ...styles,
            ...style,
        };
    };

    return (
        <FormText className={getClassNames()} style={getStyles()} id={id} color={"muted"}>
            {children}
        </FormText>
    );
};
