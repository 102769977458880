import { ColorProperty } from "csstype";

export const mix = (color1: ColorProperty, color2: ColorProperty, weight: number = 50) => {
    function d2h(d) {
        return d.toString(16);
    } // convert a decimal value to hex
    function h2d(h) {
        return parseInt(h, 16);
    } // convert a hex value to decimal

    color1 = color1.replace(/#/g, "");
    color2 = color2.replace(/#/g, "");

    let color = "#";

    for (let i = 0; i <= 5; i += 2) {
        // loop through each of the 3 hex pairs—red, green, and blue
        const v1 = h2d(color1.substr(i, 2)); // extract the current pairs
        const v2 = h2d(color2.substr(i, 2));

        // combine the current pairs from each source color, according to the specified weight
        let val = d2h(Math.round(v2 + (v1 - v2) * (weight / 100.0)));

        while (val.length < 2) {
            val = "0" + val;
        } // prepend a '0' if val results in a single digit

        color += val; // concatenate val to our new color string
    }

    return color.toUpperCase();
};

export const tint = (color: ColorProperty, weight: number = 50) => mix("#FFFFFF", color, weight);

// Functions from @material-ui/core
/**
 * Returns a number whose value is limited to the given range.
 *
 * @param {number} value The value to be clamped
 * @param {number} min The lower boundary of the output range
 * @param {number} max The upper boundary of the output range
 * @returns {number} A number in the range [min, max]
 */
function clamp(value: number, min?: number, max?: number): number {
    min = min ?? 0;
    max = max ?? 1;

    if (process.env.NODE_ENV !== "production") {
        if (value < min || value > max) {
            console.error(
                "Material-UI: The value provided "
                    .concat(value.toString(), " is out of range [")
                    .concat(min.toString(), ", ")
                    .concat(max.toString(), "]."),
            );
        }
    }

    return Math.min(Math.max(min, value), max);
}

/**
 * Converts a color from CSS hex format to CSS rgb format.
 *
 * @param {string} color - Hex color, i.e. #nnn or #nnnnnn
 * @returns {string} A CSS rgb color string
 */
function hexToRgb(color: string): string {
    color = color.substr(1);
    const re = new RegExp(".{1,".concat(color.length >= 6 ? "2" : "1", "}"), "g");
    let colors = color.match(re);

    if (colors && colors[0].length === 1) {
        colors = colors.map(function (n) {
            return n + n;
        });
    }

    return colors
        ? "rgb".concat(colors.length === 4 ? "a" : "", "(").concat(
              colors
                  .map(function (n, index) {
                      return index < 3 ? parseInt(n, 16) : Math.round((parseInt(n, 16) / 255) * 1000) / 1000;
                  })
                  .join(", "),
              ")",
          )
        : "";
}

type DecomposedColor = { type: string; values: number[] };
/**
 * Returns an object with the type and values of a color.
 *
 * Note: Does not support rgb % values.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @returns {object} - A MUI color object: {type: string, values: number[]}
 */
function decomposeColor(color: string | DecomposedColor): DecomposedColor {
    // Idempotent
    if (typeof color === "object") {
        return color;
    }

    if (color.charAt(0) === "#") {
        return decomposeColor(hexToRgb(color));
    }

    const marker = color.indexOf("(");
    const type = color.substring(0, marker);

    if (["rgb", "rgba", "hsl", "hsla"].indexOf(type) === -1) {
        throw new Error(
            "Material-UI: Unsupported `".concat(
                color,
                "` color.\nWe support the following formats: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla().",
            ),
        );
    }

    const values = color.substring(marker + 1, color.length - 1).split(",");
    const floatValues = values.map(function (value) {
        return parseFloat(value);
    });

    return {
        type: type,
        values: floatValues,
    };
}
/**
 * Converts a color object with type and values to a string.
 *
 * @param {object} color - Decomposed color
 * @param {string} color.type - One of: 'rgb', 'rgba', 'hsl', 'hsla'
 * @param {array} color.values - [n,n,n] or [n,n,n,n]
 * @returns {string} A CSS color string
 */

function recomposeColor(color: DecomposedColor): string {
    const type = color.type;
    let values: Array<string | number> = color.values;

    if (type.indexOf("rgb") !== -1) {
        // Only convert the first 3 values to int (i.e. not alpha)
        values = values.map(function (n, i) {
            return i < 3 ? parseInt(n.toString(), 10) : n;
        });
    } else if (type.indexOf("hsl") !== -1) {
        values[1] = "".concat(values[1].toString(), "%");
        values[2] = "".concat(values[2].toString(), "%");
    }

    return "".concat(type, "(").concat(values.join(", "), ")");
}

/**
 * Set the absolute transparency of a color.
 * Any existing alpha values are overwritten.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @param {number} value - value to set the alpha channel to in the range 0 -1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */
export function fade(color: string, value: number): string {
    const decomposedColor = decomposeColor(color);
    value = clamp(value);

    if (decomposedColor.type === "rgb" || decomposedColor.type === "hsl") {
        decomposedColor.type += "a";
    }

    decomposedColor.values[3] = value;
    return recomposeColor(decomposedColor);
}
