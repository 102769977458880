import "./index.less";
import * as React from "react";
import { GlobalStores, ILocalizedLikertStatement, LocalizationStore, Localizer } from "@atman/business";
import { LikertEvaluation } from "../LikertEvaluation";
import { inject, observer } from "mobx-react";

export interface ILikertEvaluationPreviewProps {
    competencies: ILocalizedLikertStatement[];
    languageCode: string;
    localizationStore?: LocalizationStore;
}

@inject(GlobalStores.localizationStore)
@observer
export class LikertEvaluationPreview extends React.Component<ILikertEvaluationPreviewProps, {}> {
    render() {
        const { competencies, languageCode, localizationStore } = this.props;

        return (
            <div className="LikertEvaluationPreview">
                <LikertEvaluation
                    title={Localizer.localizeForLanguage("global.competenciesEvaluation", languageCode)}
                    description={Localizer.localizeForLanguage("global.competenciesSectionHeaderLabel", languageCode)}
                    stepNumber={2}
                    elements={competencies}
                    onChange={() => {}}
                    answers={[]}
                    userLanguage={languageCode || localizationStore!.currentShortLocale}
                    disabled
                />
            </div>
        );
    }
}
