import "./index.less";
import * as React from "react";
import { AtCheckboxBase, AtCollapse, AtIcon } from "@atman/design-system";
import {
    CompDevStrategicObjectiveResult,
    CompDevSubCompetencyResult,
    CompetencyDevelopmentLeveragingObjectiveType,
} from "@atman/business";
import { CompetencyDevelopmentColumnType, IPersonInfo } from "../../../../..";
import { Default, Mobile } from "../../../../../../..";
import { DetailedScoreColumns } from "../../TableRow/components/DetailedScoreColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";

export interface IObjectiveSubRow {
    objective: CompDevStrategicObjectiveResult | CompDevSubCompetencyResult;
    columns: CompetencyDevelopmentColumnType[];
    isSelected?: boolean;
    inError?: boolean;
    onSelect?: () => void;
    disabledSelect?: boolean;
    managerInfo?: IPersonInfo;
    employeeInfo?: IPersonInfo;
    hiddenColumns?: CompetencyDevelopmentColumnType[];
    showCheckIcon?: boolean;
}

export const ObjectiveSubRow: React.FC<IObjectiveSubRow> = observer((props) => {
    const {
        objective,
        disabledSelect,
        isSelected,
        inError,
        onSelect,
        managerInfo,
        employeeInfo,
        columns,
        hiddenColumns,
        children,
        showCheckIcon = false,
    } = props;

    const [isExpanded, setIsExpanded] = React.useState(false);

    const getToggleRowContent = () => {
        const rowText: string =
            objective instanceof CompDevStrategicObjectiveResult
                ? objective.type !== undefined && objective.type !== null
                    ? t({
                          id: `competencyDevelopment.objectiveType.${CompetencyDevelopmentLeveragingObjectiveType[
                              objective.type
                          ].toCamel()}`,
                      })
                    : t({ id: "competencyDevelopment.global.objective" })
                : objective.name;

        return (
            <>
                <div className="competency-col content-col d-flex align-items-center justify-content-start">
                    <Mobile>
                        <div className="chevron-container">
                            <FontAwesomeIcon icon={["far", isExpanded ? "chevron-up" : "chevron-down"]} />
                        </div>
                        {onSelect && isSelected !== undefined && (
                            <AtCheckboxBase
                                checked={isSelected}
                                onChange={onSelect}
                                disabled={disabledSelect}
                                fieldName="selectedObjectives"
                                id={objective.id}
                            />
                        )}
                    </Mobile>
                    <Default>
                        {onSelect && isSelected !== undefined && (
                            <AtCheckboxBase
                                checked={isSelected}
                                onChange={onSelect}
                                disabled={disabledSelect}
                                fieldName="selectedObjectives"
                                id={objective.id}
                            />
                        )}
                        {showCheckIcon && (
                            <AtIcon
                                icon={[isSelected ? "fas" : "far", "check-circle"]}
                                color={isSelected ? "#3366FF" : "#D6D6D6"}
                            />
                        )}
                        <div className="chevron-container">
                            <FontAwesomeIcon icon={["far", isExpanded ? "chevron-up" : "chevron-down"]} />
                        </div>
                    </Default>
                    <span className="objective-name">{rowText}</span>
                </div>
                {objective instanceof CompDevSubCompetencyResult && managerInfo && employeeInfo && (
                    <DetailedScoreColumns
                        reviewScore={objective.observableBehaviorsReviewAverage}
                        averageScore={objective.observableBehaviorsAverage!}
                        employeeScore={objective.observableBehaviorsEmployeeAverage}
                        managerScore={objective.observableBehaviorsManagerAverage}
                        columns={columns}
                        averageScoreDisplayMode="light"
                        managerInfo={managerInfo}
                        employeeInfo={employeeInfo}
                        hiddenColumns={hiddenColumns}
                    />
                )}
            </>
        );
    };

    return (
        <AtCollapse
            className="ObjectiveSubRow"
            isOpen={isExpanded}
            toggleRowContent={getToggleRowContent()}
            toggleCollapse={() => {
                setIsExpanded(!isExpanded);
            }}
            toggleRowClassName={inError ? "in-error" : undefined}
            toggleRowSize="xs"
            toggleRowSizeHorizontal="lg"
            renderChevron={false}
            collapseContentOffset={true}
        >
            {children}
        </AtCollapse>
    );
});
