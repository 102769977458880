import "./index.less";
import { AcceptOrDenyRequestAccess } from "./AcceptOrDenyRequestAccess";
import { AtLink, AtTitle } from "@atman/design-system";
import { AtmanLogoFullDark } from "@atman/ui-kit/lib/static";
import { AuthApi } from "../../api/AuthApi";
import { AuthStore } from "../../data/stores/AuthStore";
import { Col, Row } from "reactstrap";
import { Colors } from "@atman/ui-kit";
import { HelpCenterLogin } from "./HelpCenterLogin";
import { INewsBoxContent } from "../../data/models/NewsBoxContent";
import { LocalizationStore, UrlFormatter } from "@atman/business";
import { Login } from "./Login";
import { LoginAppStores } from "../../../index";
import { LoginFlowSwitcher } from "../../global/components/LoginFlowSwitcher";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { ResetPasswordView } from "./ResetPassword";
import { Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { observable, runInAction } from "mobx";
import ForgotPassword from "./ForgotPassword";
import React from "react";

export interface IUnauthenticatedContainerProps extends RouteComponentProps {
    authStore?: AuthStore;
    localizationStore?: LocalizationStore;
}

@inject<LoginAppStores, any, any, any>((stores) => ({
    authStore: stores.authStore,
    localizationStore: stores.localizationStore,
    systemStateStore: stores.systemStateStore,
}))
@observer
class UnauthenticatedContainer extends React.Component<IUnauthenticatedContainerProps, {}> {
    private readonly _authApi: AuthApi = new AuthApi();

    @observable newsBoxContent?: INewsBoxContent;

    componentDidMount() {
        this.loadNewsBoxContent();
    }

    loadNewsBoxContent = async () => {
        const { localizationStore } = this.props;

        const result = await this._authApi.getNewsBoxContent(localizationStore!.currentShortLocale);

        runInAction(() => {
            this.newsBoxContent = result;
        });
    };

    render() {
        const { location, localizationStore } = this.props;

        let environmentTitle = "";

        switch (process.env.REACT_APP_ENVIRONMENT) {
            case "local":
                environmentTitle = "LOCAL";
                break;
            case "integration":
                environmentTitle = "QA";
                break;
        }

        return (
            <div id="UnauthenticatedContainer">
                <Row>
                    <Col xl={7} md={12} className={"content-column"}>
                        <LoginFlowSwitcher />
                        <div className="header">
                            <img height={32} src={AtmanLogoFullDark} />
                            {environmentTitle && (
                                <AtTitle
                                    headingType={2}
                                    title={environmentTitle}
                                    className={"environment-title"}
                                    style={{ color: Colors.greyShades.shade2 }}
                                />
                            )}
                        </div>
                        <div className="content">
                            <Switch>
                                <Route path={"/Unauth/Login"} component={Login} />
                                <Route path={"/Unauth/LoginToHelpCenter"} component={HelpCenterLogin} />
                                <Route path={"/Unauth/ForgotPassword"} component={ForgotPassword} />
                                <Route path={"/Unauth/ResetPassword"} component={ResetPasswordView} />
                                <Route path={"/Unauth/RequestCandidateAccess"} component={AcceptOrDenyRequestAccess} />
                                <Redirect from={"/Unauth"} to={{ ...location, pathname: "/Unauth/Login" }} />
                            </Switch>
                        </div>
                        <div className="footer">
                            <div className={"policy-links-container"}>
                                <Trans id={"global.general.copyright.line1"}>
                                    atmanco.com -{" "}
                                    <AtLink
                                        size={"sm"}
                                        href={UrlFormatter.getBlobFileUrl(
                                            "ServiceLicenseAgreement",
                                            localizationStore!.currentShortLocale,
                                        )}
                                        target={"_blank"}
                                    >
                                        Terms and Conditions
                                    </AtLink>
                                </Trans>
                            </div>
                            <div>
                                <Trans id={"global.general.copyright.line2"}>
                                    ©2020 AtmanCo, Inc. All Rights Reserved.
                                </Trans>
                            </div>
                        </div>
                    </Col>
                    <Col
                        xl={5}
                        className={"news-box-column d-none d-xl-block"}
                        style={{ backgroundImage: `url("${this.newsBoxContent?.imageUrl}")` }}
                    >
                        <div className="overlay">
                            {this.newsBoxContent && (
                                <div className={"content"}>
                                    <AtTitle
                                        className={"news-box-title"}
                                        headingType={1}
                                        title={this.newsBoxContent.title}
                                    />
                                    <div className="news-box-subtitle">{this.newsBoxContent.subtitle}</div>
                                    <AtLink
                                        className={"news-box-link"}
                                        href={this.newsBoxContent.linkUrl}
                                        target={"_blank"}
                                    >
                                        {this.newsBoxContent.linkText}
                                    </AtLink>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default UnauthenticatedContainer;
