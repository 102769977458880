import "./index.less";
import { AtTitle, DefaultSizes } from "@atman/design-system";
import { Colors } from "@atman/ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { observer } from "mobx-react";
import React from "react";

export interface ILoginComponentHeaderProps {
    title: string;
    icon: IconProp;
    size?: DefaultSizes;
}

const LoginComponentHeader: React.FC<ILoginComponentHeaderProps> = (props) => {
    const { title, icon, size = "md" } = props;

    let iconSize: SizeProp;

    switch (size) {
        case "sm":
            iconSize = "1x";
            break;
        case "md":
            iconSize = "lg";
            break;
        case "lg":
            iconSize = "2x";
            break;
    }

    const titleSize = size !== "sm" ? 1 : 3;

    return (
        <div className={`LoginComponentHeader size-${size}`}>
            <div className="icon-container">
                <FontAwesomeIcon icon={icon} color={Colors.atmanOrange} size={iconSize} />
            </div>
            <AtTitle headingType={titleSize} title={title} />
        </div>
    );
};

export default observer(LoginComponentHeader);
