import "./index.less";
import * as React from "react";
import { AtTitle } from "@atman/design-system";
import { ContentStore, GlobalStores } from "@atman/business";
import { UncontrolledTooltip } from "reactstrap";
import { inject, observer } from "mobx-react";

export interface ICompetencySetPreviewProps {
    competencySetName: string;
    competencyContainerId: string;
    competencyIds: string[];
    contentStore?: ContentStore;
}

export const CompetencySetPreview: React.FC<ICompetencySetPreviewProps> = inject(GlobalStores.contentStore)(
    observer((props) => {
        const { competencyIds, competencyContainerId, competencySetName, contentStore } = props;

        return (
            <UncontrolledTooltip target={competencyContainerId} className="CompetencySetPreview" placement={"bottom"}>
                <div className="sub-header">{"global.includedCompetencies".localize()}</div>

                <AtTitle headingType={3} className="header" title={competencySetName} />
                <ul>
                    {contentStore!.competencies
                        .filter((x) => competencyIds.includes(x.id))
                        .map((x) => (
                            <li className="competency-preview-entry" key={x.id}>
                                {x.name}
                            </li>
                        ))}
                </ul>
            </UncontrolledTooltip>
        );
    }),
);
