import { ConfirmationAlert } from "./ConfirmationAlert";

export class DeleteConfirmationAlert extends ConfirmationAlert {
    constructor(confirmAction: () => void, text: string) {
        super(
            confirmAction,
            text,
            "global.deleteConfirmationTitle".localize(),
            "global.deleteConfirmationButtonText".localize(),
        );
    }
}
