import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { action, observable } from "mobx";
import { t } from "@lingui/macro";

export type MapSubElements = Map<string, SidePanelItemDefinition[]>;

type LoadSubElementsFunction = (
    this: SidePanelItemDefinition,
    from: number,
    to: number,
    searchString?: string,
) => Promise<MapSubElements>;

export type SidePanelCustomSearch = (a: any, b: any) => number;

export type SortLabel = string | Map<string, string>;
export interface SidePanelSort {
    sortLabel?: SortLabel;
    displaySort?: boolean;
    customSortValue?: any;
    customSortFn?: SidePanelCustomSearch;
}

export type SecondarySidePanelSort = Omit<SidePanelSort, "displaySort">;

type CustomHoverHandler = (state: "enter" | "leave") => void;

export interface ISidePanelItemDefinition {
    id: string;
    title: string;
    alternativeTitle?: string;
    description?: string;
    sort?: SidePanelSort;
    secondarySort?: SecondarySidePanelSort;
    label?: string;
    searchTerms?: string[];
    customClickHandler?: () => void;
    customHoverHandler?: CustomHoverHandler;
    onBackButtonClick?: () => void;
    subElements: Map<string, ISidePanelItemDefinition[]>; // If only 1 entry - render without tabs
    customBackendSearch?: LoadSubElementsFunction;
    numberOfLoadedElements?: number;
    valueMapper?: (id: string) => any;
    customRightSidePanelRenderer?: (() => React.ReactNode) | undefined;
    customRightToggleLabel?: string;
    customLeftSidePanelRenderer?: (() => React.ReactNode) | undefined;
    isSubElementNavigationFrozen?: boolean; // This tag exists so when element are flattened for the search, the sidePanel don't change.
    isSearchable: boolean;
    isSubElementsSearchable?: boolean;
    resetSearchOnClick?: boolean;
    isClickedAtLoad?: boolean;
    isDisabled?: boolean;
    isSticky?: boolean;
    isSearchLimitedAtFirstChildren?: boolean;
    isLoading?: boolean;
    icon?: IconProp;
    isStarred?: boolean;
}

export type SidePanelBackendSearch = (
    numberOfElementToLoad: number,
    searchString?: string | undefined,
) => Promise<MapSubElements | undefined>;

export class SidePanelItemDefinition {
    public readonly id: string;
    public readonly title: string;
    public readonly alternativeTitle: string | undefined;
    public readonly description: string;
    public readonly sort: SidePanelSort = { sortLabel: t({ id: "global.sort" }) };
    public readonly secondarySort: SidePanelSort = {};
    public readonly label: string | undefined;
    public readonly searchTerms: string[];
    @observable public subElements: MapSubElements;
    @observable public numberOfLoadedElements: number = 0;
    public readonly customBackendSearch: LoadSubElementsFunction | undefined = undefined;
    public readonly valueMapper?: ((id: string) => any) | undefined;
    public readonly isSubElementNavigationFrozen: boolean = false;
    public readonly isSearchable: boolean = true;
    public readonly isSubElementsSearchable: boolean = true;
    public readonly isLoading: boolean = false;
    public readonly resetSearchOnClick: boolean = false;
    public readonly isClickedAtLoad: boolean = false;
    public readonly isDisabled: boolean = false;
    public readonly isSticky: boolean = false;
    public readonly isSearchLimitedAtFirstChildren: boolean = false;
    public readonly icon?: IconProp = undefined;
    public readonly isStarred: boolean = false;

    public readonly customClickHandler?: () => void | undefined = undefined;
    public readonly customHoverHandler?: CustomHoverHandler;
    public readonly onBackButtonClick?: () => void | undefined = undefined;
    public readonly customRightSidePanelRenderer?: (() => React.ReactNode) | undefined;
    public readonly customRightToggleLabel: string | undefined;
    public readonly customLeftSidePanelRenderer?: (() => React.ReactNode) | undefined;

    private readonly _root: SidePanelItemDefinition | null = null;

    public get root(): SidePanelItemDefinition | null {
        return this._root;
    }

    constructor(sidePanelDefinition: ISidePanelItemDefinition, root: SidePanelItemDefinition | null = null) {
        const {
            id,
            title,
            alternativeTitle = undefined,
            description = "",
            isSubElementNavigationFrozen = false,
            isSearchable = true,
            isSubElementsSearchable = true,
            isLoading = false,
            label,
            searchTerms,
            customClickHandler,
            customHoverHandler,
            onBackButtonClick,
            icon,
            isStarred = false,
            resetSearchOnClick = false,
            isClickedAtLoad = false,
            isDisabled = false,
            isSticky = false,
            isSearchLimitedAtFirstChildren = false,
            subElements,
            customRightSidePanelRenderer,
            customRightToggleLabel,
            customLeftSidePanelRenderer,
            customBackendSearch,
            numberOfLoadedElements,
        } = sidePanelDefinition;

        const { sort, secondarySort } = sidePanelDefinition;

        this.id = id;
        this.title = title;
        this.alternativeTitle = alternativeTitle;
        this.description = description;
        this.sort.sortLabel = sort?.sortLabel || t({ id: "global.sort" });
        this.sort.displaySort = sort?.displaySort || true;
        this.sort.customSortValue = sort?.customSortValue;
        this.sort.customSortFn = sort?.customSortFn;
        this.secondarySort.sortLabel = secondarySort?.sortLabel;
        this.secondarySort.customSortValue = secondarySort?.customSortValue;
        this.secondarySort.customSortFn = secondarySort?.customSortFn;
        this.label = label;
        this.searchTerms = searchTerms ?? [];
        this.customClickHandler = customClickHandler;
        this.customHoverHandler = customHoverHandler;
        this.onBackButtonClick = onBackButtonClick;
        this.isSubElementNavigationFrozen = isSubElementNavigationFrozen;
        this.isSearchable = isSearchable;
        this.isSubElementsSearchable = isSubElementsSearchable;
        this.isLoading = isLoading;
        this.icon = icon;
        this.isStarred = isStarred;
        this.resetSearchOnClick = resetSearchOnClick;
        this.isClickedAtLoad = isClickedAtLoad;
        this.isDisabled = isDisabled;
        this.isSticky = isSticky;
        this.isSearchLimitedAtFirstChildren = isSearchLimitedAtFirstChildren;
        this.valueMapper = sidePanelDefinition.valueMapper;
        this.customRightSidePanelRenderer = customRightSidePanelRenderer;
        this.customRightToggleLabel = customRightToggleLabel;
        this.customLeftSidePanelRenderer = customLeftSidePanelRenderer;

        if (customBackendSearch) {
            this.customBackendSearch = customBackendSearch;
        }

        if (numberOfLoadedElements) {
            this.numberOfLoadedElements = numberOfLoadedElements;
        }

        this._root = root;

        this.subElements = new Map<string, SidePanelItemDefinition[]>(
            [...subElements.entries()].map(([k, v]) => [k, v.map((x) => new SidePanelItemDefinition(x, this))]),
        );
    }

    @action public backendSearch: SidePanelBackendSearch = async (
        numberOfElementToLoad: number,
        searchString?: string,
    ) => {
        if (this.customBackendSearch) {
            const subElementsResult = await this.customBackendSearch(
                this.numberOfLoadedElements,
                numberOfElementToLoad,
                searchString,
            );

            this.subElements = subElementsResult;

            return subElementsResult;
        }

        return;
    };

    public getTopLevelRoot = (): SidePanelItemDefinition => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let activeItem: SidePanelItemDefinition | null = this;

        while (!!activeItem?.root) {
            activeItem = activeItem.root;
        }

        return activeItem;
    };
}
