import "./index.less";
import * as React from "react";
import { AppScope, GlobalStores, UserInfoStore } from "@atman/business";
import { inject, observer } from "mobx-react";
import { useAppContext } from "../../contexts";
import { AtTitle } from "@atman/design-system";

export interface IHomeHeaderProps {
    pageTitle: string;
    rightHeaderContent?: React.ReactNode;
    userInfoStore?: UserInfoStore;
}

export const PageHeader: React.FC<IHomeHeaderProps> = inject(GlobalStores.userInfoStore)(
    observer((props) => {
        const { userInfoStore, pageTitle, rightHeaderContent } = props;

        const { scope } = useAppContext();

        let entityName: string = "";

        switch (scope) {
            case AppScope.Client:
                entityName = userInfoStore!.clientName;
                break;
            case AppScope.Partner:
                entityName = userInfoStore!.partnerName;
                break;
            case AppScope.Supplier:
                entityName = "AtmanCo"; // userInfoStore!.supplierName;
        }

        return (
            <div className="PageHeader">
                <div className="info-container">
                    <div className="entity-name">{entityName}</div>
                    <AtTitle headingType={1} className="page-title" title={pageTitle} />
                </div>
                <div className="right-container">{rightHeaderContent}</div>
            </div>
        );
    }),
);
