import "./index.less";
import {
    AtLabel,
    AtSelectBase,
    AtSubLabel,
    IAtSelectBaseProps,
    ISelectSharedProps,
    OptionTypeBase,
} from "../../../internal";
import { AtSelectCheckmarkOption } from "../SelectSharedComponents/AtSelectCheckmarkOption";
import { AtSelectIconValueContainer } from "../SelectSharedComponents/AtSelectIconValueContainer";
import { GroupBase, SelectComponentsConfig } from "react-select";
import { getSelectStylesWithIcon } from "../../../shared/selects/getSelectStyles";
import FormGroup from "reactstrap/lib/FormGroup";
import FormText from "reactstrap/lib/FormText";
import React from "react";
import cn from "classnames";

export interface IAtSelectProps<
    TOption extends OptionTypeBase,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> extends IAtSelectBaseProps<TOption, IsMulti, TGroup>,
        ISelectSharedProps<TOption, IsMulti, TGroup> {}

/**
 * AtSelect - Select Component
 * Category: Molecule
 *
 * @param props {IAtSelectProps}
 **/
export const AtSelect = <
    TOption extends OptionTypeBase,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(
    props: React.PropsWithChildren<IAtSelectProps<TOption, IsMulti, TGroup>>,
): JSX.Element => {
    const {
        className,
        style,
        id,
        innerDefaultComponentProps,
        additionalStyles,
        label,
        subLabel,
        validationErrors,
        icon,
        iconProps,
        ...otherProps
    } = props;

    const selectComponents: SelectComponentsConfig<TOption, IsMulti, TGroup> = {
        Option: AtSelectCheckmarkOption,
    };

    if (icon) {
        selectComponents["ValueContainer"] = (props) => (
            <AtSelectIconValueContainer {...props} icon={icon} iconProps={iconProps} />
        );
    }

    // TODO: Max Number of Items Containers (?)
    const getAdditionalStyles = () => {
        if (icon) {
            return getSelectStylesWithIcon(additionalStyles);
        }

        if (additionalStyles) {
            return additionalStyles;
        }

        return undefined;
    };

    return (
        <FormGroup className={cn("AtSelect", className)} style={style} id={id}>
            {label && <AtLabel>{label}</AtLabel>}
            {subLabel && <AtSubLabel>{subLabel}</AtSubLabel>}
            <AtSelectBase<TOption, IsMulti, TGroup>
                components={selectComponents}
                additionalStyles={getAdditionalStyles()}
                {...otherProps}
                {...(innerDefaultComponentProps ?? {})}
            />
            {validationErrors?.map((x) => (
                <FormText key={x.fieldName} color={"danger"}>
                    {x.message}
                </FormText>
            ))}
        </FormGroup>
    );
};
