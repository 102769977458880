import "./index.less";
import * as React from "react";
import { AtButton } from "@atman/design-system";
import { IWizardStepDetails } from "../../index";
import { SubStepProgressionComponent } from "./components/SubStepProgressionComponent";

export interface IAtWizardFooterProps {
    currentSubStep: number;
    goBack: () => void;
    goNext: () => void;
    isLastStep: boolean;
    isFirstStep: boolean;
    onSubmit?: () => void;
    currentStepDetails?: IWizardStepDetails;
    formatSubStepProgressLabel?: (current: number, total: number) => React.ReactNode;
    cancelAction?: () => void;
    isLoading?: boolean;
    hasSidePanel: boolean;
}

export const AtWizardFooter: React.FC<IAtWizardFooterProps> = (props) => {
    const {
        currentSubStep,
        currentStepDetails,
        goBack,
        goNext,
        formatSubStepProgressLabel,
        isFirstStep,
        isLastStep,
        onSubmit,
        cancelAction,
        isLoading,
        hasSidePanel,
    } = props;

    if (!currentStepDetails) {
        return null;
    }

    return (
        <div className="AtWizardFooter">
            <div className="content">
                <div className="back-btn-container">
                    <AtButton
                        color={"secondary"}
                        onClick={isFirstStep ? cancelAction : goBack}
                        isLoading={isLoading}
                        disabled={isFirstStep && !cancelAction}
                    >
                        {isFirstStep && cancelAction
                            ? "global.buttons.labels.cancel".localize()
                            : "global.back".localize()}
                    </AtButton>
                </div>
                <div className="sub-step-progress-container">
                    {currentStepDetails.subSteps?.any() && (
                        <SubStepProgressionComponent
                            currentSubStep={currentSubStep}
                            totalSubStepsCount={currentStepDetails.subSteps?.length}
                            formatSubStepProgressLabel={formatSubStepProgressLabel}
                        />
                    )}
                </div>
                {(!hasSidePanel || !isLastStep) && (
                    <div className="next-btn-container">
                        <AtButton color={"primary"} onClick={isLastStep ? onSubmit : goNext} isLoading={isLoading}>
                            {isLastStep ? "global.save".localize() : "global.next".localize()}
                        </AtButton>
                    </div>
                )}
            </div>
        </div>
    );
};
