import "./index.less";
import { AtCheckboxBase, DefaultComponentProps } from "@atman/design-system";
import { AtTableColumn } from "../../../types";
import { AtTableRowCell } from "../AtTableRowCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IBaseEntity } from "@atman/core";
import { observer } from "mobx-react";
import React, { useState } from "react";
import cn from "classnames";

export type AtTableRowHeight = "sm" | "md" | "lg";

export interface IAtTableRowProps<T extends IBaseEntity> extends DefaultComponentProps {
    rowKey: string;
    columns: AtTableColumn<T>[];
    item: T;
    hasCheckbox: boolean;
    handleCheckboxChange?: (row: T) => void;
    listIdsForCheckbox?: string[];
    listOfDisabledIds?: string[];
    onRowClick?: (event: React.MouseEvent<HTMLTableRowElement>, record: T) => void;
    rowHeight?: AtTableRowHeight;
    expandRowContent?: (record: T) => React.ReactNode;
    hasHoverAction?: boolean;
    showChevronDownIcon?: boolean;
}

function AtTableRowRaw<T extends IBaseEntity>(props: IAtTableRowProps<T>) {
    const {
        rowKey,
        columns,
        item,
        onRowClick,
        className,
        hasCheckbox,
        handleCheckboxChange,
        listIdsForCheckbox,
        rowHeight,
        expandRowContent,
        hasHoverAction,
        listOfDisabledIds = [],
        showChevronDownIcon = false,
        ...otherProps
    } = props;

    const [isHovered, setIsHovered] = useState(false);
    const [isRowExpended, setisRowExpended] = useState<boolean>(false);

    const onRowClickHandler = (event: React.MouseEvent<HTMLTableRowElement>, item: T) => {
        if (expandRowContent) {
            setisRowExpended((previousState) => !previousState);
        }

        if (hasHoverAction && !expandRowContent) {
            onRowClick?.(event, item);
        }
    };

    return (
        <>
            <tr
                className={cn("AtTableRow", className, { isHovered: isHovered })}
                {...otherProps}
                onClick={(event) => onRowClickHandler(event, item)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {showChevronDownIcon && (
                    <td className="AtTableRowCell iconDropdown">
                        <FontAwesomeIcon icon={["far", "chevron-down"]} />
                    </td>
                )}
                {hasCheckbox && listIdsForCheckbox && handleCheckboxChange && (
                    <td className="AtTableRowCell checkbox">
                        <AtCheckboxBase
                            id={rowKey}
                            key={`${rowKey}_checkbox`}
                            checked={listIdsForCheckbox.some((i) => item.id === i)}
                            disabled={listOfDisabledIds.some((i) => item.id === i)}
                            onChange={() => handleCheckboxChange(item)}
                            size="sm"
                        />
                    </td>
                )}
                {columns.map((column) => (
                    <AtTableRowCell<T>
                        height={rowHeight}
                        key={`${item[rowKey]}_${column.key}`}
                        column={column}
                        item={item}
                    />
                ))}
            </tr>
            {isRowExpended && expandRowContent && expandRowContent(item)}
        </>
    );
}

export const AtTableRow = observer(AtTableRowRaw);
