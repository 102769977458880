import "./index.less";
import * as React from "react";
import { AverageScoreDisplay, IAverageScoreDisplayProps } from "../AverageScoreDisplay";
import { observer } from "mobx-react";

export interface IAverageScoreDisplayWithLabelProps extends IAverageScoreDisplayProps {
    label: string;
    title?: string;
}

export const AverageScoreDisplayWithLabel: React.FC<IAverageScoreDisplayWithLabelProps> = observer((props) => {
    const { label, title, ...otherProps } = props;

    return (
        <div className="AverageScoreDisplayWithLabel d-flex align-items-center" title={title}>
            <AverageScoreDisplay {...otherProps} />
            <span>{label}</span>
        </div>
    );
});
