import { ApiManager, ReactAppSettings, ToastProvider } from "../libs";
import {
    AssessmentType,
    CandidateFilterObject,
    CandidateFiltersMatchType,
    HttpMethodEnum,
    ICandidateCountByStateResult,
    ICandidateFilter,
    IImportCandidateParams,
    IImportedCandidate,
    IInviteCandidateParams,
} from "../types";
import { BaseApi, DataType } from "../base";
import {
    Candidate,
    ICandidateFile,
    ICandidateInput,
    IDownloadRawScoresFileModel,
    IReportsToSendModel,
    IRequestAllFilesModel,
    IRequestFileModel,
} from "../models";

export class CandidatesApi extends BaseApi {
    public static async searchCandidates(
        filters: ICandidateFilter[] = [],
        matchType: CandidateFiltersMatchType = CandidateFiltersMatchType.All,
        offset = 0,
        fetchSize = 100,
        sortField?: string,
        sortDirection?: string,
    ) {
        if (!filters) {
            filters = [];
        }

        const data = {
            filters: filters.map((x) => ({
                Comparator: x.comparator,
                FieldName: CandidateFilterObject[x.object],
                FieldValue: x.value,
            })),
            matchType,
            fetchSize,
            offset,
            sortField,
            sortDirection,
        };

        return await this.sendApiRequestWithResult<typeof data, Candidate[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.searchCandidates,
            data,
            undefined,
            DataType.QueryString,
        );
        // .then(result => {
        //     return {
        //         candidates: result.candidates as Candidate[],
        //         counts: {
        //             completed: result.completedCount as number,
        //             inProgress: result.inProgressCount as number,
        //             pending: result.pendingCount as number,
        //             total: result.totalCount as number
        //         },
        //         offset: offset
        //     };
        // })
        // .catch(error => {
        //     return {
        //         candidates: [] as Candidate[],
        //         counts: {
        //             completed: 0,
        //             inProgress: 0,
        //             pending: 0,
        //             total: 0
        //         },
        //         offset: 0
        //     }
        // })
    }

    public static async getCandidateCounts() {
        return await this.sendApiRequestWithResult<null, ICandidateCountByStateResult>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCandidateCounts,
        );
    }

    public static async getCandidate(id: string) {
        return await this.sendApiRequestWithResult<null, Candidate>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCandidate,
            undefined,
            id,
        );
    }

    public static async updateCandidateInfo(id: string, input: ICandidateInput) {
        return await this.sendApiRequestWithResult<ICandidateInput, null>(
            HttpMethodEnum.PUT,
            ReactAppSettings.appUrls.updateCandidateInfo,
            input,
            id,
        );
    }

    public static async deleteCandidate(id: string) {
        return await this.sendApiRequest(HttpMethodEnum.POST, ReactAppSettings.appUrls.deleteCandidate, null, id);
    }

    public static async updateProfilePicture(id: string, fileBlob: Blob) {
        const formData = new FormData();
        formData.append("image.jpeg", fileBlob);

        return await this.sendApiRequestWithResult<FormData, { uri: string }>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateCandidateProfilePicture,
            formData,
            id,
        );
    }

    public static async removeProfilePicture(id: string) {
        return await this.sendApiRequest(
            HttpMethodEnum.DELETE,
            ReactAppSettings.appUrls.removeCandidateProfilePicture,
            null,
            id,
        );
    }

    public static async addAssessments(id: string, testAtmanProId: string, assessmentTypes: AssessmentType[]) {
        return await this.sendApiRequestWithResult<any, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.addAssessments,
            {
                testAtmanProId,
                selectedAssessments: assessmentTypes,
            },
            id,
        );
    }

    public static async getCompletedCandidates() {
        const data = {
            fetchSize: 10,
        };

        return await this.sendApiRequestWithResult<typeof data, Candidate[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCompletedCandidates,
            data,
            undefined,
            DataType.QueryString,
        );
    }

    public static async getPendingCandidates() {
        const data = {
            fetchSize: 10,
        };

        return await this.sendApiRequestWithResult<typeof data, Candidate[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getPendingCandidates,
            data,
            undefined,
            DataType.QueryString,
        );
    }

    public static async resendInvitationEmailToCandidate(assessmentId: string) {
        return await this.sendApiRequestWithResult<{ testAtmanProId: string }, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.resendEmail,
            { testAtmanProId: assessmentId },
        );
    }

    public static async validateInviteCandidateEmails(emails: string[]) {
        return await this.sendApiRequestWithResult<{ emails: string[] }, any>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.validateInviteCandidateEmails,
            { emails },
        );
    }

    public static async sendAssessmentInvitations(model: IInviteCandidateParams) {
        return await this.sendApiRequestWithResult<any, Candidate[]>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.inviteCandidate,
            model,
        );
    }

    public static async downloadCandidatesData() {
        return await this.sendApiRequest(HttpMethodEnum.GET, ReactAppSettings.appUrls.downloadCandidatesData);
    }

    public static async downloadTemplateFile() {
        const apiCall = ApiManager.get(ReactAppSettings.appUrls.downloadImportTemplate, {
            responseType: "blob",
        });

        this.downloadFile("template.xlsx", apiCall);
    }

    public static async uploadTemplateFile(file: File) {
        const formData = new FormData();
        formData.append(file.name, file);

        return await this.sendApiRequestWithResult<FormData, IImportedCandidate[]>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.uploadImportTemplate,
            formData,
        );
    }

    public static async confirmImport(model: IImportCandidateParams) {
        return await this.sendApiRequestWithResult<any, Candidate[]>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.confirmImport,
            model,
            undefined,
            DataType.Json,
            { timeout: 300000 },
        );
    }

    public static async sendBatchEmail(candidateIds: string[], communicationLanguage?: string) {
        return await this.sendApiRequestWithResult<{ candidateIds: string[]; communicationLanguage?: string }, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.sendBatchEmail,
            { candidateIds, communicationLanguage },
        );
    }

    public static async updateCandidateNotificationUserIds(id: string, selectedNotificationUserIds: string[]) {
        const data = {
            data: selectedNotificationUserIds,
        };

        return await this.sendApiRequestWithResult<typeof data, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateCandidateNotificationUserIds,
            data,
            id,
        );
    }

    // MARK: Internal API Calls for AtmanCo Employees only

    public static async downloadEvalAndGoFile(assessmentIds: string[]) {
        const apiCall = ApiManager.post(
            ReactAppSettings.appUrls.downloadEvalAndGoFile,
            {
                testAtmanProIds: assessmentIds,
            },
            {
                responseType: "blob",
            },
        );

        this.downloadFile("evalAndGo.csv", apiCall);
    }

    public static async downloadSocioDemoFile(assessmentIds: string[]) {
        const apiCall = ApiManager.post(
            ReactAppSettings.appUrls.downloadSocioDemoFile,
            {
                testAtmanProIds: assessmentIds,
            },
            {
                responseType: "blob",
            },
        );

        return this.downloadFile("sociodemo.xlsx", apiCall);
    }

    public static async downloadRawScores(model: IDownloadRawScoresFileModel) {
        const apiCall = ApiManager.post(ReactAppSettings.appUrls.downloadRawScores, model, { responseType: "blob" });

        return this.downloadFile("raw_scores.xlsx", apiCall);
    }

    public static async reExecuteAnalyzer(assessmentIds: string[]) {
        return this.sendApiRequestWithResult<{ testAtmanProIds: string[] }, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.reExecuteAnalyzer,
            { testAtmanProIds: assessmentIds },
        ).then(() => {
            ToastProvider.success("clientApp.reExecuteAnalyzerSuccessMessage".localize());
        });
    }

    public static async reExecuteAnalyzerAll() {
        return this.sendApiRequestWithResult<null, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.reExecuteAnalyzerAll,
        ).then(() => {
            ToastProvider.success("clientApp.reExecuteAnalyzerSuccessMessage".localize());
        });
    }

    public static async reTestFromCurrentTest(assessmentId: string) {
        return this.sendApiRequestWithResult<{ testAtmanProId: string }, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.reTestFromCurrentTest,
            { testAtmanProId: assessmentId },
        ).then(() => {
            ToastProvider.success("clientApp.reTestFromCurrentTestSuccessMessage".localize());
        });
    }

    public static async getAvailableFiles(id: string) {
        return this.sendApiRequestWithResult<null, ICandidateFile[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCandidateAvailableFiles,
            null,
            id,
        );
    }

    public static async requestFileGeneration(id: string, model: IRequestFileModel) {
        return this.sendApiRequest<IRequestFileModel>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.requestCandidateFileGeneration,
            model,
            id,
        );
    }

    public static async requestAllFileGeneration(id: string, model: IRequestAllFilesModel) {
        return this.sendApiRequest<IRequestAllFilesModel>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.requestAllFilesGeneration,
            model,
            id,
        );
    }

    public static async downloadCandidateFile(id: string, fileId: string, fileName: string) {
        const data = {
            fileId,
        };

        const apiCall = this.sendApiRequestWithResult<typeof data, any>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.downloadCandidateFile,
            data,
            id,
            DataType.QueryString,
            {
                responseType: "blob",
            },
        );

        this.downloadFile(fileName, apiCall);
    }

    public static async sendReportByEmail(
        id: string,
        destinationEmail: string,
        fileId?: string,
        fileGenerationRequest?: IRequestFileModel,
    ) {
        const data = {
            fileId,
            destinationEmail,
            fileGenerationRequest,
        };

        return this.sendApiRequestWithResult<typeof data, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.sendCandidateReportByEmail,
            data,
            id,
        )
            .then((_) => null)
            .catch((_) => true);
    }

    public static async sendMultipleReportByEmail(
        id: string,
        destinationEmail: string,
        reportsToSend?: IReportsToSendModel[],
    ) {
        const data = {
            destinationEmail,
            reportsToSend,
        };

        return this.sendApiRequestWithResult<typeof data, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.sendCandidateReportsByEmail,
            data,
            id,
        )
            .then((_) => null)
            .catch((_) => true);
    }

    public static async assignJob(id: string, jobId: string) {
        const data = {
            data: jobId,
        };

        return this.sendApiRequestWithResult<typeof data, void>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.assignJobToCandidate,
            data,
            id,
        );
    }

    public static async unassignJob(id: string, jobId: string) {
        const data = {
            data: jobId,
        };

        return this.sendApiRequestWithResult<typeof data, void>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.unassignJobFromCandidate,
            data,
            id,
        );
    }

    public static duplicateCandidates(candidateProIds: string[], targetClientId: string, useIntegral: boolean) {
        const data = {
            candidateProIds,
            targetClientId,
            useIntegral,
        };

        return this.sendApiRequestWithResult<typeof data, void>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.duplicateProCandidates,
            data,
        );
    }

    public static requestAccessToCandidates(candidateProEmails: string[]) {
        const data = {
            candidateProEmails,
        };

        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.requestAccessToCandidates,
            data,
        );
    }
}
