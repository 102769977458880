import "./index.less";
import * as React from "react";
import moment from "moment";

import {
    AppScope,
    ClientStore,
    GlobalStores,
    IOrganizationCustomTermsConditions,
    LocalizationStore,
    PartnerStore,
    Role,
    ToastProvider,
    UserInfoStore,
} from "@atman/business";
import {
    AtContainer,
    AtIcon,
    AtSwitch,
    AtText,
    AtTitleSubtitle,
    AtTooltip,
    IconTitleSubtitle,
} from "@atman/design-system";
import { CardSaveButtons } from "../CardSaveButtons";
import { CollapsibleInputTextarea } from "../CollapsibleInputTextarea";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useEffect, useMemo, useState } from "react";
import { useIsMount, withAppContext } from "../../..";

export interface ICustomConsentProps {
    scope: AppScope;
    organizationId: string;
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
    userInfoStore?: UserInfoStore;
    localizationStore?: LocalizationStore;
}

const CustomConsent: React.FC<ICustomConsentProps> = inject(
    GlobalStores.clientStore,
    GlobalStores.partnerStore,
    GlobalStores.userInfoStore,
    GlobalStores.localizationStore,
)(
    observer((props) => {
        const { scope, clientStore, partnerStore, userInfoStore, localizationStore, organizationId } = props;
        const [customConsentInfo, setCustomConsentInfo] = useState<IOrganizationCustomTermsConditions>();

        const [checked, setChecked] = useState<boolean | undefined>(customConsentInfo?.isActivated);
        const [isCheckedFromTheToggle, setIsCheckedFromTheToggle] = useState<boolean>(false);
        const [isLoading, setIsLoading] = useState<boolean>(false);

        const isMounted = useIsMount();

        const assignCustomConsentInfo = (customConsent: IOrganizationCustomTermsConditions) => {
            setChecked(customConsent.isActivated);
            setIsCheckedFromTheToggle(false);

            const consentTitleMap = new Map<string, string>(customConsent.customTermsConditions.title);
            const consentDescriptionMap = new Map<string, string>(customConsent.customTermsConditions.description);

            setCustomConsentInfo({
                ...customConsent,
                customTermsConditions: {
                    title: consentTitleMap,
                    description: consentDescriptionMap,
                },
            });
        };

        const fetchData = async () => {
            setIsLoading(true);

            if (scope === AppScope.Client) {
                const customConsent = await clientStore!.getOrganizationCustomTermsConditions();
                if (customConsent) {
                    assignCustomConsentInfo(customConsent);
                }
            }

            if (scope === AppScope.Partner) {
                const customConsent = await partnerStore!.getOrganizationCustomTermsConditions();
                if (customConsent) {
                    assignCustomConsentInfo(customConsent);
                }
            }

            setIsLoading(false);
        };

        const canEdit = userInfoStore!.hasRole(Role.AdminSupplier);

        useEffect(() => {
            if (isMounted) {
                return;
            }

            if (isCheckedFromTheToggle && canEdit) {
                activateOrDeactivateCustomConsent();
            }
            return;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [checked]);

        useEffect(() => {
            fetchData();

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [scope]);

        const handleToggleChange = (_e) => {
            setChecked((prev) => !prev);
            setIsCheckedFromTheToggle(true);
        };

        const activateOrDeactivateCustomConsent = async () => {
            if (!canEdit) {
                return;
            }

            setIsLoading(true);

            if (scope === AppScope.Client) {
                checked
                    ? await clientStore!.activateClientCustomTermsConditions(organizationId)
                    : await clientStore!.deactivateClientCustomTermsConditions(organizationId);
            }

            if (scope === AppScope.Partner) {
                checked
                    ? await partnerStore!.activatePartnerCustomTermsConditions(organizationId)
                    : await partnerStore!.deactivatePartnerCustomTermsConditions(organizationId);
            }

            setIsLoading(false);
        };

        const isAllFieldsFilled = useMemo(() => {
            if (customConsentInfo) {
                return [
                    ...customConsentInfo.customTermsConditions.title.values(),
                    ...customConsentInfo.customTermsConditions.description.values(),
                ].every((value) => value.length > 0);
            } else {
                return false;
            }
        }, [customConsentInfo]);

        console.log("isAllFieldsFilled", isAllFieldsFilled);

        const lastModified = useMemo(() => {
            if (customConsentInfo) {
                return customConsentInfo.lastModified;
            } else {
                return "";
            }
        }, [customConsentInfo?.lastModified]);

        const onSave = async () => {
            if (!canEdit) {
                return;
            }

            setIsLoading(true);

            if (scope === AppScope.Client) {
                await clientStore!.updateOrganizationCustomTermsConditions(customConsentInfo!.customTermsConditions);
            }

            if (scope === AppScope.Partner) {
                await partnerStore!.updateOrganizationCustomTermsConditions(customConsentInfo!.customTermsConditions);
            }

            setIsLoading(false);
        };

        const resetForm = () => {
            if (!canEdit) {
                return;
            }

            fetchData();
            ToastProvider.success(t({ id: "global.cancelCustomConsentMessage" }));
        };

        return (
            <div className="CustomConsent">
                <AtContainer className="customConsent-header">
                    <IconTitleSubtitle
                        icon={<AtIcon icon={["far", "file-times"]} size="lg" extraPadding />}
                        title={t({ id: "global.CustomConsent.headerTitle" })}
                        subtitle={t({ id: "global.CustomConsent.headerSubtitle" })}
                        size="lg"
                    />
                </AtContainer>

                <AtContainer className="customConsent-body">
                    <div className="customContent-bodyHeading">
                        <AtTitleSubtitle
                            title={t({ id: "global.CustomConsent.mainContainerTitle" })}
                            subtitle={t({ id: "global.CustomConsent.mainContainerSubtitle" })}
                        />

                        <div id="switchContainer">
                            {checked !== undefined && (
                                <AtSwitch
                                    disabled={!canEdit || !isAllFieldsFilled || isLoading === true}
                                    checked={checked}
                                    onChange={handleToggleChange}
                                    id="customContent-switchId"
                                />
                            )}
                        </div>

                        {!isAllFieldsFilled && (
                            <AtTooltip displayMode="dark" target="switchContainer">
                                {t({ id: "global.customTermsConditionsTooltipMessage" })}
                            </AtTooltip>
                        )}
                    </div>

                    <div className="customContent-highlighted-text">
                        <AtText>{t({ id: "global.customTermsConditionsHighlightedText" })}</AtText>
                    </div>

                    {customConsentInfo && (
                        <div className="customConsent-collapsibles">
                            {localizationStore!.availableAssessmentLanguages.map((culture) => (
                                <CollapsibleInputTextarea
                                    key={culture.shortName}
                                    titleValue={
                                        customConsentInfo.customTermsConditions.title.get(culture.shortName) ?? ""
                                    }
                                    textAreaValue={
                                        customConsentInfo.customTermsConditions.description.get(culture.shortName) ?? ""
                                    }
                                    setCustomConsentChange={setCustomConsentInfo}
                                    languageCode={culture.shortName}
                                    disabled={!canEdit}
                                />
                            ))}
                        </div>
                    )}
                    <div className="customConsent-footer">
                        <CardSaveButtons
                            isLoading={isLoading}
                            saveAction={onSave}
                            cancelAction={resetForm}
                            disabledSaveButton={!canEdit}
                            disabledCancelButton={!canEdit}
                        />

                        {customConsentInfo && customConsentInfo.lastModifiedBy && customConsentInfo.lastModified && (
                            <div className="customConsent-footer-lastModified">
                                <AtText>{`${t({
                                    id: "global.customTermsConditionsLastModified",
                                })} ${customConsentInfo?.lastModifiedBy} ${t({ id: "global.on" })} ${moment(
                                    lastModified,
                                ).format("MMMM Do YYYY")}`}</AtText>
                            </div>
                        )}
                    </div>
                </AtContainer>
            </div>
        );
    }),
);

export default withAppContext(CustomConsent);
