import "./index.less";
import * as React from "react";
import { AtScoreReviewIndicator, AverageScoreDisplay } from "../AtCompetencyDevelopmentComponents";
import { CompDevCompetencyResult, CompDevSubCompetencyResult } from "@atman/business";
import { observer } from "mobx-react";

export interface ICompetencyResultLabelProps {
    competency: CompDevCompetencyResult | CompDevSubCompetencyResult;
    showReviewIndicator?: boolean;
}
export const CompetencyResultLabel: React.FC<ICompetencyResultLabelProps> = observer((props) => {
    const { competency, showReviewIndicator = false } = props;

    const score = unifyCompetencyScore(competency);

    return (
        <div className="CompetencyResultLabel">
            <div className="score">
                <AverageScoreDisplay
                    reviewScore={score.reviewAverage}
                    averageScore={score.average!}
                    averageScoreDisplayMode={competency instanceof CompDevSubCompetencyResult ? "light" : "solid"}
                />
                {score.reviewAverage && showReviewIndicator && (
                    <AtScoreReviewIndicator score={score.average!} scoreDisplayMode={"average"} />
                )}
            </div>
            <div className="name">{competency.name}</div>
        </div>
    );
});

function unifyCompetencyScore(competency: CompDevCompetencyResult | CompDevSubCompetencyResult): {
    reviewAverage: number | undefined;
    average: number | undefined;
} {
    if (competency instanceof CompDevSubCompetencyResult) {
        return {
            reviewAverage: competency.observableBehaviorsReviewAverage,
            average: competency.observableBehaviorsAverage,
        };
    } else {
        return {
            reviewAverage: competency.subCompetenciesReviewAverage,
            average: competency.subCompetenciesAverage,
        };
    }
}
