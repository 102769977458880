import { AtIcon, OptionTypeBase } from "../../../..";
import { Colors } from "../../../styles/variables/colors";
import { GroupBase, OptionProps, components } from "react-select";
import React, { PropsWithChildren } from "react";

export interface IAtSelectCheckmarkOptionProps<
    TOption extends OptionTypeBase,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> extends OptionProps<TOption, IsMulti, TGroup> {}

export const AtSelectCheckmarkOption = <
    TOption extends OptionTypeBase,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(
    props: PropsWithChildren<IAtSelectCheckmarkOptionProps<TOption, IsMulti, TGroup>>,
) => {
    const { children, ...otherProps } = props;

    return (
        <components.Option<TOption, IsMulti, TGroup> {...otherProps}>
            {children}
            {props.isSelected && (
                <AtIcon
                    icon={["far", "check"]}
                    style={{ justifySelf: "flex-end", margin: "-8px 0" }}
                    color={Colors.primaryBase}
                />
            )}
        </components.Option>
    );
};
