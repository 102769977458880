import "./index.less";
import * as React from "react";
import { AtWizardIconHeader, CompetencySelectionForm } from "../../../../../components";
import { BLANK_VALUE } from "../CompetencySetTemplateStep";
import { CompetencySetType } from "../../CompetencySetInformationForm";
import { Culture, Job } from "@atman/business";
import { observer } from "mobx-react";

export interface ICompetencySetSelectionStep {
    competencySetType: CompetencySetType;
    templates: (Job | Culture)[];
    createdFromId: string | undefined;
    selectedCompetencyIds: string[];
    onCompetencyIdSelection: (id: string) => void;
    matchedSetId: string | undefined;
}

export const CompetencySetSelectionStep: React.FC<ICompetencySetSelectionStep> = observer((props) => {
    const {
        competencySetType,
        templates,
        createdFromId,
        selectedCompetencyIds,
        onCompetencyIdSelection,
        matchedSetId,
    } = props;

    return (
        <div id="CompetencySetSelectionStep">
            <AtWizardIconHeader
                icon={competencySetType === "job" ? "id-badge" : "theater-masks"}
                title={"global.selectCompetencies".localize()}
                description={"global.competencySetWizard.competencySelection.description".localize()}
            />
            <CompetencySelectionForm
                suggestedCompetencyIds={templates.find((x) => x.id === createdFromId)?.competencyIds ?? []}
                selectedCompetencyIds={selectedCompetencyIds}
                onCompetencyIdSelection={onCompetencyIdSelection}
                expandGroupsOnMount={!matchedSetId && createdFromId === BLANK_VALUE ? "first" : undefined}
            />
        </div>
    );
});
