import "./index.less";
import {
    AtCompetenciesAverageScoresGroup,
    DistributionPieValue,
    IPersonInfo,
    ResultTableContainer,
    ScoreInfo,
    ScoreInfoSizing,
} from "../../";
import {
    AtContainer,
    AtTabs,
    AtTitle,
    IAtTabsContent,
    LabelValueContainer,
    LabelValueItem,
} from "@atman/design-system";
import { Colors } from "../../../styles/variables/colors";
import {
    CompDevCompetencyResult,
    CompetencyDevelopmentProcessData,
    CompetencyDevelopmentProcessResultsData,
    CompetencySource,
    GlobalStores,
    IShortCandidate,
    LIKERT_AVG_INTERPRETATION_RANGES,
    LikertValue,
    PrintModeEnum,
    RangeHelper,
    ScaleHelper,
    ScoreDisplayType,
    Team,
    UserStore,
} from "@atman/business";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import React from "react";
import cn from "classnames";

enum AppraisalViewMode {
    Summary = 0,
    Detailed = 1,
}

export interface ITeamDevelopmentEmployeeAppraisalProps {
    team: Team;
    candidateInfo: IShortCandidate;
    candidateProcessData: CompetencyDevelopmentProcessData;
    candidateProcessResultsData: CompetencyDevelopmentProcessResultsData;
    printMode?: PrintModeEnum;
    userStore?: UserStore;
    className?: string;
}

export const TeamDevelopmentEmployeeAppraisal: React.FC<ITeamDevelopmentEmployeeAppraisalProps> = inject(
    GlobalStores.userInfoStore,
    GlobalStores.userStore,
)(
    observer((props) => {
        const {
            team,
            candidateInfo,
            candidateProcessData,
            className,
            candidateProcessResultsData,
            printMode,
            userStore,
        } = props;

        const [employeeInfo, setEmployeeInfo] = React.useState<IPersonInfo>();
        const [managerInfo, setManagerInfo] = React.useState<IPersonInfo>();

        const distributionData: DistributionPieValue[] = React.useMemo(() => {
            let distributionData: DistributionPieValue[] | undefined;

            const distributionValue = ScaleHelper.countCompetenciesPerLikertValue(
                candidateProcessResultsData.competencies,
            );
            let hasScore = false;

            for (const [_, value] of distributionValue) {
                if (value > 0) {
                    hasScore = true;
                    break;
                }
            }

            if (hasScore) {
                distributionData = [
                    LikertValue.StronglyDisagree,
                    LikertValue.Disagree,
                    LikertValue.NeitherAgreeNorDisagree,
                    LikertValue.Agree,
                    LikertValue.StronglyAgree,
                ].map((lVal) => ({
                    label: "",
                    value: distributionValue.get(lVal)!,
                    color: Colors.likertScaleColors.get(lVal)!,
                }));
            } else {
                // Else, empty. We show a gray circle.
                distributionData = [
                    {
                        label: "",
                        value: 1,
                        color: Colors.greyShades.shade9,
                    },
                ];
            }

            return distributionData ? distributionData : [];
        }, [candidateProcessResultsData.competencies]);

        React.useEffect(() => {
            const manager = userStore?.getUserById(team.managerId!);

            setEmployeeInfo({
                firstName: candidateInfo.firstName,
                lastName: candidateInfo.lastName,
                displayName: `${candidateInfo.firstName} ${candidateInfo.lastName}`.trim(),
                email: candidateInfo.email,
                pictureUri: candidateInfo.profilePictureUri,
            });

            setManagerInfo({
                firstName: manager?.firstName ?? "",
                lastName: manager?.lastName ?? "",
                displayName: `${manager?.firstName} ${manager?.lastName}`.trim(),
                email: manager?.email ?? "",
            });
        }, []);

        const competencyLabelValueItems = React.useMemo(
            () => getCompetencyLabelValueItems(candidateProcessResultsData.competencies),
            [candidateProcessResultsData.competencies],
        );

        const subCompetencyLabelValueItems = React.useMemo(
            () => getSubCompetencyLabelValueItems(candidateProcessResultsData.competencies),
            [candidateProcessResultsData.competencies],
        );

        const discretionalCompetencies = candidateProcessResultsData.getCompetenciesForSources(
            CompetencySource.Specific,
        );
        const jobCompetencies = candidateProcessResultsData.getCompetenciesForSources(CompetencySource.Job);
        const teamCultureCompetencies = candidateProcessResultsData.getCompetenciesForSources(CompetencySource.Team);
        const enterpriseCultureCompetencies = candidateProcessResultsData.getCompetenciesForSources(
            CompetencySource.Org,
        );

        const arrowUpIconProps: FontAwesomeIconProps = {
            icon: "arrow-circle-up",
            color: Colors.greyShades.shade4,
        };

        const arrowDownIconProps: FontAwesomeIconProps = {
            icon: "arrow-circle-down",
            color: Colors.greyShades.shade4,
        };

        const appraisalViewTabs: IAtTabsContent[] = [
            {
                id: AppraisalViewMode.Summary.toString(),
                buttonContent: t({ id: "competencyDevelopment.appraisal.summaryView" }),
                content: (
                    <ResultTableContainer
                        processResultsData={candidateProcessResultsData}
                        dataType="competency"
                        displayType="summary"
                        columns={["competencyName", "averageScoreScale"]}
                        showColumnFilter={false}
                        printMode={printMode}
                    />
                ),
            },
            {
                id: AppraisalViewMode.Detailed.toString(),
                buttonContent: t({ id: "competencyDevelopment.appraisal.detailedView" }),
                content: (
                    <ResultTableContainer
                        processResultsData={candidateProcessResultsData}
                        dataType="competency"
                        displayType="detailed"
                        columns={["competencyName", "averageScore", "employeeScore", "managerScore", "spread"]}
                        managerInfo={managerInfo}
                        employeeInfo={employeeInfo}
                        isReadOnly
                        showRank={false}
                        showColumnFilter={false}
                        printMode={printMode}
                    />
                ),
            },
        ];

        const tabsContent: IAtTabsContent[] = [
            {
                id: "competency",
                buttonContent: t({ id: "competencyDevelopment.appraisal.navigation.competency" }),
                content: (
                    <>
                        <div className="Appraisal-row">
                            <LabelValueContainer
                                limit={3}
                                iconProps={arrowUpIconProps}
                                title={t({ id: "competencyDevelopment.appraisal.competencyBestResults" })}
                                items={competencyLabelValueItems}
                                sortDirection="desc"
                            />

                            <LabelValueContainer
                                limit={3}
                                iconProps={arrowDownIconProps}
                                title={t({ id: "competencyDevelopment.appraisal.competencyWorstResults" })}
                                items={competencyLabelValueItems}
                                sortDirection="asc"
                            />
                        </div>
                        <AtContainer className="appraisalViewTabs">
                            <AtTabs tabs={appraisalViewTabs} />
                        </AtContainer>
                    </>
                ),
            },
            {
                id: "subCompetency",
                buttonContent: t({ id: "competencyDevelopment.appraisal.navigation.subCompetency" }),
                content: (
                    <>
                        <div className="Appraisal-row">
                            <LabelValueContainer
                                limit={3}
                                iconProps={arrowUpIconProps}
                                title={t({ id: "competencyDevelopment.appraisal.subCompetencyBestResults" })}
                                items={subCompetencyLabelValueItems}
                                sortDirection="desc"
                            />

                            <LabelValueContainer
                                limit={3}
                                iconProps={arrowDownIconProps}
                                title={t({ id: "competencyDevelopment.appraisal.subCompetencyWorstResults" })}
                                items={subCompetencyLabelValueItems}
                                sortDirection="asc"
                            />
                        </div>
                        <AtContainer className="table-section">
                            <ResultTableContainer
                                processResultsData={candidateProcessResultsData}
                                dataType="subCompetency"
                                displayType="detailed"
                                columns={["competencyName", "averageScore", "employeeScore", "managerScore", "spread"]}
                                managerInfo={managerInfo}
                                employeeInfo={employeeInfo}
                                showColumnFilter={false}
                                showLegend={false}
                                printMode={printMode}
                            />
                        </AtContainer>
                    </>
                ),
            },
            {
                id: "summary",
                buttonContent: t({ id: "competencyDevelopment.appraisal.navigation.summary" }),
                content: (
                    <>
                        <AtContainer className="Appraisal-summary">
                            <div className="score-container">
                                <ScoreInfo
                                    scoreDisplayType={ScoreDisplayType.StanineHalfCircle}
                                    distributionPieData={distributionData}
                                    score={candidateProcessResultsData.getGlobalScore()}
                                    denominator={5}
                                    sizing={ScoreInfoSizing.regular}
                                    showDenominator
                                />
                            </div>
                            <div className="appraisal-info-container">
                                <AtTitle headingType={1} title={t({ id: "competencyDevelopment.appraisal.title" })} />
                                <div className="manager-label">{t({ id: "global.manager" })}</div>
                                <div className="manager-name">{team.managerName}</div>
                            </div>
                        </AtContainer>
                        <AtContainer className="Appraisal-summary-result">
                            <div className="result-header">
                                <AtTitle
                                    headingType={2}
                                    title={t({ id: "competencyDevelopment.appraisal.resultByCategoryTitle" })}
                                />
                                <p>{t({ id: "competencyDevelopment.appraisal.resultByCategoryDescription" })}</p>
                            </div>

                            <AtCompetenciesAverageScoresGroup
                                candidateProcessData={candidateProcessData}
                                discretionalCompetencies={discretionalCompetencies}
                                jobCompetencies={jobCompetencies}
                                teamCultureCompetencies={teamCultureCompetencies}
                                enterpriseCultureCompetencies={enterpriseCultureCompetencies}
                            />
                        </AtContainer>
                    </>
                ),
            },
        ];

        const classes = cn("TeamDevelopmentEmployeeAppraisal", className, { printMode: PrintModeEnum[printMode!] });

        const renderDefault = () => {
            return (
                <div className={classes}>
                    <AtTabs tabs={tabsContent} wrapper />
                </div>
            );
        };

        const renderPrint = () => {
            return (
                <div className={classes}>
                    {printMode === PrintModeEnum.Summary && (
                        <>
                            <AtContainer className="Appraisal-summary">
                                <div className="score-container">
                                    <ScoreInfo
                                        scoreDisplayType={ScoreDisplayType.StanineHalfCircle}
                                        distributionPieData={distributionData}
                                        score={candidateProcessResultsData.getGlobalScore()}
                                        denominator={5}
                                        sizing={ScoreInfoSizing.regular}
                                        showDenominator
                                    />
                                </div>
                                <div className="appraisal-info-container">
                                    <AtTitle
                                        headingType={1}
                                        title={t({ id: "competencyDevelopment.appraisal.title" })}
                                    />
                                    <div className="manager-label">{t({ id: "global.manager" })}</div>
                                    <div className="manager-name">{team.managerName}</div>
                                </div>
                            </AtContainer>
                            <AtContainer className="Appraisal-summary-result">
                                <div className="result-header">
                                    <AtTitle
                                        headingType={2}
                                        title={t({ id: "competencyDevelopment.appraisal.resultByCategoryTitle" })}
                                    />
                                    <p>{t({ id: "competencyDevelopment.appraisal.resultByCategoryDescription" })}</p>
                                </div>

                                <AtCompetenciesAverageScoresGroup
                                    candidateProcessData={candidateProcessData}
                                    discretionalCompetencies={discretionalCompetencies}
                                    jobCompetencies={jobCompetencies}
                                    teamCultureCompetencies={teamCultureCompetencies}
                                    enterpriseCultureCompetencies={enterpriseCultureCompetencies}
                                />
                            </AtContainer>
                        </>
                    )}
                    <AtTitle
                        title={t({
                            id: "competencyDevelopment.appraisal.print.assessedCompetencies",
                            message: "Assessed competencies",
                        })}
                        headingType={2}
                    />
                    <ResultTableContainer
                        processResultsData={candidateProcessResultsData}
                        dataType="competency"
                        displayType="summary"
                        columns={["competencyName", "averageScoreScale"]}
                        showColumnFilter={false}
                        printMode={printMode}
                    />
                </div>
            );
        };

        return printMode !== undefined ? renderPrint() : renderDefault();
    }),
);

const getCompetencyLabelValueItems = (competencies: CompDevCompetencyResult[]) => {
    return [...competencies]
        .filter((x) => (x.subCompetenciesReviewAverage ?? x.subCompetenciesAverage) !== undefined)
        .map((x) => {
            const value = x.subCompetenciesReviewAverage ?? x.subCompetenciesAverage;
            const likertValue = RangeHelper.getRangeMapValue(LIKERT_AVG_INTERPRETATION_RANGES, value);
            const colorGroup = Colors.getLikertScoreColorGroup(likertValue);

            return {
                title: x.name,
                value: value,
                textColor: colorGroup?.textColor,
                backgroundColor: colorGroup?.backgroundColor,
                borderColor: colorGroup?.borderColor,
            } as LabelValueItem;
        });
};

const getSubCompetencyLabelValueItems = (competencies: CompDevCompetencyResult[]) => {
    return [...competencies].flatMap((x) => {
        return x.subCompetencies
            .filter((s) => (s.observableBehaviorsReviewAverage ?? s.observableBehaviorsAverage) !== undefined)
            .map((y) => {
                const value = y.observableBehaviorsReviewAverage ?? y.observableBehaviorsAverage;
                const likertValue = RangeHelper.getRangeMapValue(LIKERT_AVG_INTERPRETATION_RANGES, value);

                return {
                    title: y.name,
                    value: value,
                    badgeText: x.name,
                    borderColor: Colors.getLikertScoreColorGroup(likertValue)?.borderColor,
                    backgroundColor: "white",
                } as LabelValueItem;
            });
    });
};
