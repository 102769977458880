import "./index.less";
import { AnalysisTileEnum, FitLevelEnum } from "@atman/business";
import { AtFilter } from "@atman/design-system";
import { ViewFilterType } from "../";
import { t } from "@lingui/macro";
import React, { useMemo } from "react";

export interface IFitLevelFilteringProps {
    fitLevelCountMap: Map<FitLevelEnum, number>;
    onLegendItemClick: (fitLevel: FitLevelEnum | "showAll") => void;
    currentViewFilter?: ViewFilterType | -1;
    tile: AnalysisTileEnum;
}

export const FitLevelFiltering: React.FC<IFitLevelFilteringProps> = (props) => {
    const { fitLevelCountMap, onLegendItemClick, currentViewFilter, tile } = props;

    const total = [...fitLevelCountMap.entries()].sum(([_fitLevel, number]) => number);

    const competenciesLabelMapping: Dictionary<FitLevelEnum, string> = useMemo(
        () => ({
            [FitLevelEnum.VeryGood]: "naturalTalent",
            [FitLevelEnum.GoodFit]: "good",
            [FitLevelEnum.BeVigilent]: "lessNatural",
        }),
        [],
    );

    return (
        <div className="FitLevelFiltering">
            <AtFilter
                key={"showAll"}
                fitLevel={"showAll"}
                active={"showAll" === currentViewFilter}
                count={total}
                onFilterClick={onLegendItemClick}
                label={t({ id: "global.fitLevels.showAll" })}
                isCompetency={tile === AnalysisTileEnum.Competencies}
            />

            {[...fitLevelCountMap].map(([fitLevel, count]) => {
                const fitLevelEnumString = FitLevelEnum[fitLevel];

                const fitLevelLabel =
                    tile === AnalysisTileEnum.Competencies ? competenciesLabelMapping[fitLevel] : fitLevelEnumString;

                return (
                    <AtFilter
                        key={fitLevel}
                        fitLevel={fitLevel}
                        fitLevelEnumString={fitLevelEnumString}
                        active={fitLevel === currentViewFilter}
                        count={count}
                        onFilterClick={onLegendItemClick}
                        label={t({ id: `global.fitLevels.${fitLevelLabel.toCamel()}` })}
                        isCompetency={tile === AnalysisTileEnum.Competencies}
                    />
                );
            })}
        </div>
    );
};
