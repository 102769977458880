import { BaseModel, IBaseModel } from "../../base";
import { IBaseFitContent } from "./BaseFitContent";
import { IShortClient, ShortClient } from "../ShortClient";
import { IShortPartner, ShortPartner } from "../ShortPartner";
import { PublishableDocumentState } from "../../types";
import { observable } from "mobx";

export interface IBaseFit<TContent extends IBaseFitContent> extends IBaseModel {
    readonly id: string;
    readonly identifier: string;
    readonly uniqueIdentifiableKey: string;
    readonly localizationKeys: IFitLocalizationKeys;
    readonly label: string;
    readonly content: TContent;
    readonly state: PublishableDocumentState;
    readonly isGeneric: boolean;
    readonly allowedClientsList: IShortClient[];
    readonly allowedPartnersList: IShortPartner[];
    readonly version: number;
    readonly createdAt?: string;
    readonly createdBy?: string;
    readonly updatedAt?: string;
    readonly updatedBy?: string;
}

export abstract class BaseFit<TContent extends IBaseFitContent> extends BaseModel implements IBaseFit<TContent> {
    public readonly identifier: string;
    public readonly uniqueIdentifiableKey: string;
    public readonly localizationKeys: IFitLocalizationKeys;
    @observable public label: string;
    @observable public content: TContent;
    @observable public state: PublishableDocumentState;
    @observable public version: number;
    @observable public isGeneric: boolean;
    @observable public allowedClientsList: ShortClient[];
    @observable public allowedPartnersList: ShortPartner[];
    public readonly createdBy?: string;
    public readonly updatedBy: string | undefined;

    protected constructor(json: IBaseFit<TContent>) {
        super(json);

        this.identifier = json.identifier;
        this.uniqueIdentifiableKey = json.uniqueIdentifiableKey;
        this.localizationKeys = json.localizationKeys;
        this.label = json.label;
        this.state = json.state;
        this.isGeneric = json.isGeneric;
        this.allowedClientsList = json.allowedClientsList?.map((x) => new ShortClient(x)) ?? [];
        this.allowedPartnersList = json.allowedPartnersList?.map((x) => new ShortPartner(x)) ?? [];
        this.version = json.version;
        this.createdBy = json.createdBy;
        this.updatedBy = json.updatedBy;
    }

    public get publicLabels(): Record<string, string> | undefined {
        return this.content.localizedNames;
    }
}

export interface IFitLocalizationKeys {
    readonly title: string;
    readonly description: string;
    readonly fitPrefix: string;
}
