import * as React from "react";
import { VictoryPie } from "victory";
import { observer } from "mobx-react";

export type ChartDataEntry = {
    id: string;
    label?: string;
    value: number;
    color: string;
    uniqueIdentifiableKey: string;
    description?: string;
};

export interface IAtPieChartProps {
    data: ChartDataEntry[];
}

export const AtPieChart: React.FC<IAtPieChartProps> = observer((props) => {
    const { data } = props;

    return (
        <svg width={400} height={400}>
            <VictoryPie
                data={data}
                colorScale={data.map((x) => x.color)}
                y={(x) => x.value}
                labels={(x) => null}
                standalone={false}
            />
        </svg>
    );
});
