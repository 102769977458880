import "./index.less";
import * as React from "react";
import {
    AppScope,
    GlobalStores,
    ModalStore,
    ModalTypes,
    ProductCodeProEnum,
    UrlFormatter,
    UsageModel,
} from "@atman/business";
import { AtButton, AtLink, AtTitle } from "@atman/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IRoutedAppContext, withRoutedAppContext } from "../../contexts";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ProductBalanceElement } from "../ProductBalanceElement";
import { UnlimitedBalanceMarker } from "../AtHeader/components/UnlimitedBalanceMarker";
import { inject, observer } from "mobx-react";

export interface IProductBalanceViewProps extends IRoutedAppContext {
    ownerId: string;
    usageModel: UsageModel;
    scopeOverride?: AppScope;
    modalStore?: ModalStore;
}

@inject(GlobalStores.modalStore)
@observer
class ProductBalanceViewComp extends React.Component<IProductBalanceViewProps, {}> {
    openAttributeCredits = () => {
        this.props.modalStore!.openModal(ModalTypes.AttributeCredits, { recipientId: this.props.ownerId });
    };

    goToBalanceHistory = () => {
        this.props.history.push(UrlFormatter.formatReactPath("/Transactions"), {
            ownerId: this.props.ownerId,
        });
    };

    render() {
        const { usageModel, scope, scopeOverride } = this.props;

        let headerTitle: string = "";

        const scopeForComponent = scopeOverride !== undefined ? scopeOverride : scope;

        switch (scopeForComponent) {
            case AppScope.Client:
                headerTitle = "partnerApp.clientBalance".localize();
                break;
            case AppScope.Partner:
                headerTitle = "supplierApp.partnerBalance".localize();
                break;
            case AppScope.Supplier:
                headerTitle = "supplierApp.supplierBalance".localize();
                break;
        }

        const productBalanceElements = Object.keys(ProductCodeProEnum)
            .filter((x) => !isNaN(Number(x)))
            .map((x, i) => {
                const balanceValue = usageModel.productBalance[x];
                const disabled =
                    usageModel.productRestriction &&
                    usageModel.productRestriction.length > 0 &&
                    !usageModel.productRestriction.some((restriction) => restriction === Number(x));

                return (
                    <ProductBalanceElement
                        productCode={Number(x)}
                        balanceValue={balanceValue}
                        key={i}
                        disabled={disabled}
                    />
                );
            });

        const usageModelClass = new UsageModel(usageModel);

        return (
            <div id="ProductBalanceView">
                <div className="header">
                    <span className="icon-container fa-layers fa-fw">
                        <FontAwesomeIcon icon={["far", "wallet"] as IconProp} />
                    </span>
                    <AtTitle headingType={4} title={headerTitle} />
                </div>
                <div className="content">
                    {usageModelClass.isUnlimited ? (
                        <UnlimitedBalanceMarker />
                    ) : (
                        [
                            <div className="products" key={0}>
                                {productBalanceElements}
                            </div>,
                            <AtButton color={"primary"} size={"lg"} onClick={this.openAttributeCredits} key={1}>
                                {"global.attributeProducts".localize()}
                            </AtButton>,
                            <AtLink
                                color={"secondary"}
                                className="view-balance-history-link"
                                onClick={this.goToBalanceHistory}
                                key={2}
                            >
                                {"global.viewBalanceHistory".localize()}
                            </AtLink>,
                        ]
                    )}
                </div>
            </div>
        );
    }
}

const ProductBalanceView = withRoutedAppContext(ProductBalanceViewComp);

export { ProductBalanceView };
