import "./index.less";
import { ApplySort, AtTableColumn, AtTableItemKeyType, AtTableSortOrder } from "../../../types";
import { AtIcon, AtTitle } from "../../../../../../internal";
import { IBaseEntity } from "@atman/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { generateDefaultSortByType } from "../../sort/generateDefaultSortByType";
import { isColumnHidden } from "../ColumnsDisplayHelper";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import cn from "classnames";

export interface IAtTableHeaderCellProps<T extends IBaseEntity> {
    column: AtTableColumn<T>;
    itemKeyType: AtTableItemKeyType;
    applySort: ApplySort<T>;
    sortKey: string | undefined;
    sortOrder: AtTableSortOrder;
}

function AtTableHeaderCellRaw<T extends IBaseEntity>(props: IAtTableHeaderCellProps<T>) {
    const { column, applySort, sortKey, sortOrder, itemKeyType, ...otherProps } = props;

    const { key, fieldName, label, width, align, sort, filter, displayOverSize, hideOverSize, hideColumn } = column;

    const isSorted = useMemo(() => sortKey === column.key, [column.key, sortKey]);
    const isHidden = isColumnHidden(displayOverSize, hideOverSize);

    const columnSort = useMemo(() => {
        if (typeof sort === "function") {
            return sort;
        } else if (typeof sort === "boolean" && sort === true) {
            return generateDefaultSortByType<T>(fieldName, itemKeyType);
        } else {
            return null;
        }
    }, [fieldName, itemKeyType, sort]);

    const clickOnSort = () => {
        if (columnSort) {
            applySort(key, columnSort);
        }
    };

    const sortIcon: IconProp = useMemo(() => {
        if (isSorted) {
            if (sortOrder === "asc") {
                return "sort-up";
            } else if (sortOrder === "desc") {
                return "sort-down";
            }
        }

        return "sort";
    }, [isSorted, sortOrder]);

    return (
        <th
            className={cn("AtTableHeaderCell", {
                isHidden: isHidden || hideColumn,
                hasIcon: !!sort || !!filter,
                sort: !!sort,
                isSorted: isSorted,
                [align || ""]: align,
            })}
            style={{ width }}
            {...otherProps}
        >
            <div className="headerCellContainer">
                {label && <AtTitle title={label} headingType={6} noMargin />}
                {sort && <AtIcon className="action sort" icon={sortIcon} onClick={clickOnSort} />}
            </div>
        </th>
    );
}

export const AtTableHeaderCell = observer(AtTableHeaderCellRaw);
