import "./LikertScales.less";
import * as React from "react";
import { CustomStandaloneRadioInput } from "../../CustomStandaloneRadioInput";
import { Default, Mobile } from "../../BaseResponsiveComponent";
import { IScaleValueHighlight, ScaleCell } from "./ScaleCell";
import { Label } from "reactstrap";
import { RadioFormInput } from "@atman/design-system";
import { observer } from "mobx-react";

export interface ILikertScalesProps {
    scaleKey: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onClick?: (e: React.FormEvent<HTMLInputElement>) => void;
    scales?: string[];
    currentValue?: number;
    disabled?: boolean;
    highlightedScores?: Array<IScaleValueHighlight>; // Will place colored dots over the desired values
}

export const LikertScales: React.FC<ILikertScalesProps> = observer((props) => {
    const { scaleKey, onChange, onClick, currentValue, disabled, scales, highlightedScores = [] } = props;

    let scaleLabels: string[];

    if (scales) {
        scaleLabels = scales;
    } else {
        scaleLabels = [
            "global.likertLegendLabel.1".localize(),
            "global.likertLegendLabel.2".localize(),
            "global.likertLegendLabel.3".localize(),
            "global.likertLegendLabel.4".localize(),
            "global.likertLegendLabel.5".localize(),
        ];
    }

    const scaleValues: number[] = scaleLabels.map((_, i) => i + 1);

    return (
        <div className="LikertScales">
            {scaleValues.map((x, i) => {
                const highlights = highlightedScores.length > 0 ? GetScoreHighlights(x, highlightedScores) : undefined; // When undefined, it removes the indicator reserved space on the scale.

                const isSelected: boolean = currentValue === x;
                const inputId: string = `${scaleKey}-${x}`;

                return (
                    <React.Fragment key={x}>
                        <Default>
                            <ScaleCell highlights={highlights}>
                                <Label className={"radio-container"} key={x}>
                                    <CustomStandaloneRadioInput
                                        fieldName={scaleKey}
                                        id={inputId}
                                        value={x}
                                        checked={isSelected}
                                        onChange={onChange}
                                        onClick={onClick}
                                        disabled={disabled}
                                        style={{
                                            border: highlightedScores?.find((s) => s.score === x)
                                                ? `2px solid ${highlightedScores?.find((s) => s.score === x)?.color}`
                                                : undefined,
                                        }}
                                    />
                                </Label>
                            </ScaleCell>
                        </Default>
                        <Mobile>
                            <ScaleCell highlights={highlights} selected={isSelected} inputId={inputId}>
                                <RadioFormInput
                                    fieldName={scaleKey}
                                    id={inputId}
                                    value={x}
                                    valueText={scaleLabels[i]}
                                    onChange={onChange}
                                    checked={isSelected}
                                    onClick={onClick}
                                    disabled={disabled}
                                    className={isSelected ? "checked" : ""}
                                    style={{
                                        border: highlightedScores?.find((s) => s.score === x)
                                            ? `2px solid ${highlightedScores?.find((s) => s.score === x)?.color}`
                                            : undefined,
                                    }}
                                />
                            </ScaleCell>
                        </Mobile>
                    </React.Fragment>
                );
            })}
        </div>
    );
});

function GetScoreHighlights(score: number, allHighlights: Array<IScaleValueHighlight>): Array<IScaleValueHighlight> {
    return allHighlights.filter((h) => h.score === score);
}
