import "./index.less";
import {
    AtCollapsibleCard,
    AtContainerList,
    AtIcon,
    AtImageCard,
    AtParagraph,
    AtTitle,
    Colors,
    DefaultComponentProps,
    Sizing,
} from "@atman/design-system";
import { AtmanIcon } from "../AtmanIcon";
import { GlobalStores, IAtStyleDefinition, LocalizationStore } from "@atman/business";
import { StylesContext } from "../AtStylesTabView";
import { t } from "@lingui/macro";
import React, { useMemo } from "react";

import { SummaryCard } from "../SummaryCard";
import { inject, observer } from "mobx-react";
import { useBannerStyleSvg } from "../../..";
import cn from "classnames";

export interface IAtStyleView extends DefaultComponentProps {
    styleDefinition: IAtStyleDefinition;
    context: StylesContext;
    localizationStore?: LocalizationStore;
}

export const AtStyleView: React.FC<IAtStyleView> = inject(GlobalStores.localizationStore)(
    observer((props) => {
        const {
            styleDefinition: {
                name,
                narrative,
                color,
                formalDescription,
                keywords,
                traits,
                individualEncouragedBehaviors,
                teamEncouragedBehaviors,
                individualDiscouragedBehaviors,
                teamDiscouragedBehaviors,
                uniqueIdentifiableKey,
            },
            context,
            className,
            localizationStore,

            ...otherProps
        } = props;

        const locale = localizationStore!.currentLocale.shortName;
        const largeSvg = useBannerStyleSvg(locale, uniqueIdentifiableKey, "large");

        const encouragedBehaviors = useMemo(() => {
            switch (context) {
                case "profile":
                    return individualEncouragedBehaviors;
                case "team":
                    return teamEncouragedBehaviors;
            }
        }, [context, individualEncouragedBehaviors, teamEncouragedBehaviors]);

        const discouragedBehaviors = useMemo(() => {
            switch (context) {
                case "profile":
                    return individualDiscouragedBehaviors;
                case "team":
                    return teamDiscouragedBehaviors;
            }
        }, [context, individualDiscouragedBehaviors, teamDiscouragedBehaviors]);

        const doIcon = <AtIcon icon={["fas", "check-circle"]} color={Colors.greyShades.shade5} />;
        const dontIcon = <AtIcon icon={["fas", "times-circle"]} color={Colors.greyShades.shade5} />;

        const renderToggleRowContent = () => {
            return (
                <div className="rowContent">
                    <AtTitle
                        headingType={2}
                        style={{ textAlign: "left" }}
                        title={t({
                            id: "clientApp.atmanStyles.behaviorTitle",
                            message: `Behaviors of the ${name} style`,
                            values: { name },
                        })}
                    />
                    {context === "profile" && <AtParagraph>{narrative}</AtParagraph>}
                </div>
            );
        };

        return (
            <div className={cn("AtStyleView", className)} {...otherProps}>
                <section className="summaryCardSection">
                    <SummaryCard
                        summaryLogo={<AtmanIcon color={color} size="lg" />}
                        tags={keywords}
                        title={t({
                            id: "clientApp.atmanStyles.styleTitle",
                            message: `Characteristics of the style ${name}`,
                            values: { name },
                        })}
                        qualityTitle={t({
                            id: "clientApp.atmanStyles.styleQualitiesTitle",
                            message: `Qualities of the style ${name}`,
                            values: { name },
                        })}
                        description={formalDescription}
                        color={color}
                        context={context}
                        bannerSvg={largeSvg}
                        stylesNarrative={narrative}
                    />
                </section>

                <section className="atCollapsibleCardBehaviorSection">
                    <AtCollapsibleCard
                        className="AtCollapsibleCardBehavior"
                        bodyPadding={Sizing.paddings.collapsibleHeader}
                        customLeftHeaderContent={renderToggleRowContent()}
                        defaultCollapseOpenState={true}
                        hideChevron
                        disabled
                    >
                        <div className="imageContainer">
                            {traits.map((trait) => (
                                <AtImageCard key={trait.label} text={trait.label} imageUrl={trait.pictureUri} />
                            ))}
                        </div>
                    </AtCollapsibleCard>
                </section>

                <section className="AtContainerListSection">
                    <AtContainerList
                        className="flexItems leftContainer"
                        icon={doIcon}
                        title={t({
                            id: `clientApp.atmanStyles.encouragedBehaviors.${context}.title`,
                            values: { name },
                        })}
                        elements={encouragedBehaviors}
                    />
                    <AtContainerList
                        className="flexItems rightContainer"
                        icon={dontIcon}
                        title={t({
                            id: `clientApp.atmanStyles.discouragedBehaviors.${context}.title`,
                            values: { name },
                        })}
                        elements={discouragedBehaviors}
                    />
                </section>
            </div>
        );
    }),
);
