import "./index.less";
import * as React from "react";
import { Col, FormFeedback, Row } from "reactstrap";
import {
    DefaultComponentProps,
    FormInputGroup,
    IInputHeaderProps,
    IRadioFormInputProps,
    RadioFormInput,
} from "../../../internal";
import { FieldError } from "@atman/core";
import { observer } from "mobx-react";

export interface IRadioInputGroupProps extends DefaultComponentProps, IInputHeaderProps {
    radioInputProps: IRadioFormInputProps[];
    errors?: FieldError[];
}

export const RadioInputGroup: React.FC<IRadioInputGroupProps> = observer((props) => {
    const {
        radioInputProps,
        label,
        description,
        requirementType,
        requirementText,
        className,
        id,
        style,
        errors,
        ...otherProps
    } = props;

    let radioGroupClassName = "RadioInputGroup";

    if (className) {
        radioGroupClassName += ` ${className}`;
    }

    let numberOfColumns: number;

    if (radioInputProps.length > 12) {
        numberOfColumns = 4;
    } else if (radioInputProps.length > 8) {
        numberOfColumns = 3;
    } else if (radioInputProps.length > 4) {
        numberOfColumns = 2;
    } else {
        numberOfColumns = 1;
    }

    const arrayOfInputs: IRadioFormInputProps[][] = [];

    const chunk = Math.ceil(radioInputProps.length / numberOfColumns);
    for (let i = 0; i < radioInputProps.length; i += chunk) {
        arrayOfInputs.push(radioInputProps.slice(i, i + chunk));
    }

    return (
        <FormInputGroup
            label={label}
            description={description}
            requirementType={requirementType}
            requirementText={requirementText}
            className={radioGroupClassName}
            id={id}
            style={style}
            {...otherProps}
        >
            <Row>
                {arrayOfInputs.map((x, i) => (
                    <Col
                        className={"radio-input-container-col"}
                        key={`radio-input-container-${i}`}
                        xl={true}
                        lg={true}
                        md={4}
                        sm={6}
                        xs={12}
                    >
                        {x.map((props, ii) => (
                            <RadioFormInput
                                key={`radio-input-${ii}`}
                                {...props}
                                invalid={errors && errors.length >= 0}
                            />
                        ))}
                    </Col>
                ))}
            </Row>
            {errors?.map((x, i) => (
                <FormFeedback key={`form-feedback-${i}`}>{x.message}</FormFeedback>
            ))}
        </FormInputGroup>
    );
});
