import "./index.less";
import { AtBadge } from "../../atoms/AtBadge";
import { AtCheckboxBase } from "../../atoms/AtCheckboxBase";
import { AtContainer } from "../../atoms/AtContainer";
import { AtIcon } from "../../atoms/AtIcon";
import { AtRadioButtonBase } from "../../atoms/AtRadioButtonBase";
import { AtTitleSubtitle } from "../../molecules/AtTitleSubtitle";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { MouseEvent } from "react";
import cn from "classnames";

export interface IAtCardSelectProps {
    title: string;
    subtitle?: string;
    icon: IconProp;
    id: string;
    checked?: boolean;
    labelBadge?: string;
    onCheckboxClick?: () => void;
    isCheckBox?: boolean;
    value?: string | number;
    onRadioChange?: (value: string | number) => void;
    onClickCardSelect?: () => void;
}

export const AtCardSelect: React.FC<IAtCardSelectProps> = (props) => {
    const {
        title,
        subtitle,
        icon,
        labelBadge,
        onCheckboxClick,
        isCheckBox = false,
        id,
        checked,
        value,
        onRadioChange,
        onClickCardSelect,
        ...otherProps
    } = props;

    const handleRadioButtonClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        if (!onRadioChange) {
            return;
        }
        onRadioChange(value);
    };

    const handleOnClickCardSelect = (event: MouseEvent<HTMLDivElement>) => {
        if (onClickCardSelect) {
            event.preventDefault();
            onClickCardSelect();
        }
    };
    return (
        <AtContainer className={cn("AtCardSelect")} {...otherProps} onClick={handleOnClickCardSelect}>
            <div className="cardSelect-title-desc-icon-container">
                <div className="icon-container">
                    <AtIcon size="lg" icon={icon} />
                </div>

                <div className="cardSelect-title-desc-badge-container">
                    <AtTitleSubtitle title={title} subtitle={subtitle} size={"sm"} />

                    {labelBadge && (
                        <AtBadge customColor="#EFEFEF" pill>
                            {labelBadge}
                        </AtBadge>
                    )}
                </div>
            </div>

            <div className="checkers">
                {isCheckBox ? (
                    <AtCheckboxBase
                        checked={checked !== undefined ? checked : false} // TODO: check box need other type
                        onChange={onCheckboxClick}
                        id={id}
                    />
                ) : (
                    <AtRadioButtonBase
                        checked={checked !== undefined && checked === true}
                        onChange={handleRadioButtonClick}
                        id={id}
                        value={value}
                    />
                )}
            </div>
        </AtContainer>
    );
};
