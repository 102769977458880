import "./ToggleableNotificationEntry.less";
import * as React from "react";
import { AtSwitch } from "@atman/design-system";
import { Label } from "reactstrap";
import { NotificationType } from "@atman/business";
import { inject, observer } from "mobx-react";

export interface IToggleableNotificationEntryProps {
    type: NotificationType;
    checked: boolean;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

@inject()
@observer
export class ToggleableNotificationEntry extends React.Component<IToggleableNotificationEntryProps, {}> {
    render() {
        const { type, checked, onChange } = this.props;

        return (
            <div className="ToggleableNotificationEntry">
                <Label>{`global.notificationTypes.${NotificationType[type].toCamel()}`.localize()}</Label>
                <AtSwitch checked={checked} onChange={onChange} id={NotificationType[type]} />
            </div>
        );
    }
}
