import * as Jwt from "jsonwebtoken";
import * as Sentry from "@sentry/react";
import { ApiManager, BrowserHelper, ReactAppSettings, StorageProvider, ToastProvider } from "../libs";
import {
    AppScope,
    Browser,
    EmailNotificationFrequencyEnum,
    IAuthTokenPayload,
    IUserNotificationSettings,
    ItemType,
    NotificationType,
    Role,
    RolesLevel,
    UserContext,
} from "../types";
import { BasePlatformRootStore } from "../base/BasePlatformRootStore";
import { BaseStore } from "../base/BaseStore";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { PreferencesApi, SecurityApi, TokensApi, UsersApi } from "../apis";
import { RoleMatchType } from "../kernel";
import { Tag, Team } from "../models";
import { action, autorun, computed, observable, reaction, runInAction } from "mobx";
import { computedFn } from "mobx-utils";

const LOGOUT_KEY_IN_STORAGE = "LOGOUT_TRIGGERED";

export class UserInfoStore extends BaseStore {
    private refreshAccessTokenTimeout: Nullable<number> = null;

    protected readonly localeHandleMethod: (locale: string) => void;
    protected readonly jwtTokenHandleMethod: (jwtToken: string) => void;

    @observable public intercomUnreadCount: number = 0;
    @observable public accessToken: string = "";
    @observable public accessTokenExpiresAt?: number | null = null;
    @observable public firstName: string = ReactAppSettings.viewBag.baseInfo.userInfo.firstName;
    @observable public lastName: string = ReactAppSettings.viewBag.baseInfo.userInfo.lastName;
    @observable public fullName: string = ReactAppSettings.viewBag.baseInfo.userInfo.fullName;
    @observable public email: string = ReactAppSettings.viewBag.baseInfo.userInfo.email;
    @observable public communicationEmail: string = ReactAppSettings.viewBag.baseInfo.userInfo.communicationEmail;
    @observable public ssoNameId?: string = ReactAppSettings.viewBag.baseInfo.ssoNameId;
    @observable public languageCode: string = ReactAppSettings.viewBag.baseInfo.userInfo.languageCode;
    @observable public roles: string[] = ReactAppSettings.viewBag.baseInfo.userInfo.roles;
    @observable public rolesByLevel: Map<RolesLevel, Role[]> = new Map<RolesLevel, Role[]>(
        Object.keys(ReactAppSettings.viewBag.scope.rolesByLevel).map((key: RolesLevel) => [
            key,
            (ReactAppSettings.viewBag.scope.rolesByLevel[key] as string[]).map((role) => Role[role]),
        ]),
    );
    @observable public highestRole: string = ReactAppSettings.viewBag.baseInfo.highestRole;
    @observable public userId: string = ReactAppSettings.viewBag.baseInfo.userInfo.userId;
    @observable public clientId: string = ReactAppSettings.viewBag.baseInfo.clientId;
    @observable public clientName: string = ReactAppSettings.viewBag.baseInfo.clientName;
    @observable public partnerId: string = ReactAppSettings.viewBag.baseInfo.partnerProId;
    @observable public partnerName: string = ReactAppSettings.viewBag.baseInfo.partnerName;
    @observable public supplierId: string = ReactAppSettings.viewBag.baseInfo.userInfo.supplierId;
    @observable public restrictToAssignedWorkspaces: boolean =
        ReactAppSettings.viewBag.baseInfo.userInfo.restrictToAssignedWorkspaces;
    @observable public restrictToAllowedTags: boolean =
        ReactAppSettings.viewBag.baseInfo.userInfo.restrictToAllowedTags;
    @observable public automaticClientDelegationEnabled: boolean | undefined = undefined;
    @observable public isNotReseller: boolean = ReactAppSettings.viewBag.baseInfo.isNotReseller;
    @observable public isSsoAuthenticated: boolean = false;
    @observable public isMultiClientUser: boolean = ReactAppSettings.viewBag.scope.isMultiClientUser;
    @observable public intercomUserBlacklisted: boolean =
        ReactAppSettings.viewBag.baseInfo.userInfo.intercomUserBlacklisted;
    @observable public notificationSettings: IUserNotificationSettings = {
        emailNotificationSettings: [],
        inAppNotificationSettings: [],
        clientId: this.clientId,
        partnerId: this.partnerId,
        userId: this.userId,
    };

    public get userContext(): UserContext {
        if (this.hasRole(Role.Candidate)) {
            return "candidate";
        } else {
            return "user";
        }
    }

    constructor(rootStore: BasePlatformRootStore, jwtTokenHandleMethod: (jwt: string) => void) {
        super(rootStore);
        this.jwtTokenHandleMethod = jwtTokenHandleMethod;

        window.addEventListener("storage", (e) => {
            if (e.key === LOGOUT_KEY_IN_STORAGE && Boolean(e.newValue)) {
                StorageProvider.setItem(LOGOUT_KEY_IN_STORAGE, false);
                this.logout(null, true);
            }
        });
    }

    hasRole = computedFn((role: Role) => {
        let rolesForLevel: Role[] = [];

        switch (this.rootStore.scope) {
            case AppScope.Client:
            case AppScope.Manager:
            case AppScope.Employee:
                rolesForLevel = this.rolesByLevel.get("client") ?? [];
                break;
            case AppScope.Partner:
                rolesForLevel = this.rolesByLevel.get("partner") ?? [];
                break;
            case AppScope.Supplier:
                rolesForLevel = this.rolesByLevel.get("supplier") ?? [];
                break;
        }

        return rolesForLevel.includes(role);
    });

    hasOneOfRoles = computedFn((roles: Role[]) => {
        return roles.map((x) => this.hasRole(x)).some((x) => x);
    });

    hasAllRoles = computedFn((roles: Role[]) => {
        return roles.map((x) => this.hasRole(x)).every((x) => x);
    });

    matchRoles = computedFn((roles: Role[], matchType: RoleMatchType = "allOf") => {
        const roleMatchResults = roles.map((x) => this.hasRole(x));

        switch (matchType) {
            case "anyOf":
                return roleMatchResults.some((x) => x);
            case "allOf":
            default:
                return roleMatchResults.every((x) => x);
        }
    });

    @action
    updateUserInfoFromTokenPayload = (tokenPayload: IAuthTokenPayload) => {
        this.roles = tokenPayload.roles;
        this.highestRole = tokenPayload.highestRole;
        this.languageCode = tokenPayload.languageCode;
        this.restrictToAllowedTags = tokenPayload.restrictToAllowedTags;
        this.restrictToAssignedWorkspaces = tokenPayload.restrictToAssignedWorkspaces;
        this.automaticClientDelegationEnabled = tokenPayload.automaticClientDelegationEnabled;
        this.isNotReseller = tokenPayload.isNotReseller;
        this.isSsoAuthenticated = tokenPayload.isSsoAuthenticated;
        this.isMultiClientUser = tokenPayload.isMultiClientUser;

        if (tokenPayload.clientId) this.clientId = tokenPayload.clientId;
        if (tokenPayload.clientName) this.clientName = tokenPayload.clientName;
        if (tokenPayload.partnerId) this.partnerId = tokenPayload.partnerProId;
        if (tokenPayload.partnerName) this.partnerName = tokenPayload.partnerName;

        if (this.rootStore.scope !== AppScope.Print) {
            this.rootStore.localizationStore.updateCurrentLocale(tokenPayload.languageCode);
        }

        this.rolesByLevel.clear();
        for (const key of Object.keys(tokenPayload.rolesByLevel)) {
            this.rolesByLevel.set(
                key as RolesLevel,
                tokenPayload.rolesByLevel[key].map((x) => Role[x]),
            );
        }

        const readableRolesByLevel = {};

        for (const [k, v] of this.rolesByLevel.entries()) {
            readableRolesByLevel[k] = v.map((x) => Role[x].toTitle());
        }

        Sentry.setContext("Scope", {
            ClientId: this.clientId,
            PartnerId: tokenPayload.partnerId,
            PartnerProId: tokenPayload.partnerProId,
            SupplierId: this.supplierId,
        });
        Sentry.setContext("Roles", {
            RolesForScope: this.roles,
            RolesByLevel: readableRolesByLevel,
        });
    };

    @action
    logout = (e?: any, skipLocalStorage: boolean = false) => {
        if (!skipLocalStorage && !BrowserHelper.isBrowser(Browser.IE)) {
            this.sendLogoutToLocalStorage();
        }

        window.location.href = ReactAppSettings.appUrls.logout;
    };

    @action
    logoutFromAllDevices = () => {
        this.sendLogoutToLocalStorage();
        window.location.href = ReactAppSettings.appUrls.logoutFromAllDevices;
    };

    private sendLogoutToLocalStorage = () => {
        StorageProvider.setItem(LOGOUT_KEY_IN_STORAGE, true);
    };

    @action
    logoutFromOtherDevices = async () => {
        await this.tryCatch(async () => {
            await TokensApi.logoutFromOtherDevices();

            runInAction(() => {
                this.isLoading = false;
            });
        });
    };

    @action
    requestAccessToken = async () => {
        await this.tryCatch(async () => {
            const result = await TokensApi.requestAccessToken();

            const tokenPayload = this.processAccessTokenResponse(result);

            this.updateUserInfoFromTokenPayload(tokenPayload);

            runInAction(() => {
                this.isLoading = false;
            });
        });
    };

    @action
    refreshAccessToken = async () => {
        await this.tryCatch(async () => {
            const result = await TokensApi.requestAccessToken();

            const tokenPayload = this.processAccessTokenResponse(result);

            this.updateUserInfoFromTokenPayload(tokenPayload);

            runInAction(() => {
                this.isLoading = false;
            });

            // @ts-ignore
            const autoRefreshDisabled = window.ATMAN_DISABLE_AUTOREFRESH;

            if (!ReactAppSettings.viewBag.isInDevEnvironment && !autoRefreshDisabled) {
                await SecurityApi.compareBundleVersions();
            }
        });
    };

    @action
    updateIntercomUnreadCount = (unreadCount: number) => {
        this.intercomUnreadCount = unreadCount;
    };

    @action
    updateUserPreferences = async (
        firstName: string,
        lastName: string,
        communicationEmail: string,
        languageCode: string,
    ) => {
        await this.tryCatch(async () => {
            await PreferencesApi.updatePreferences(firstName, lastName, communicationEmail, languageCode);

            runInAction(() => {
                this.firstName = firstName;
                this.lastName = lastName;
                this.communicationEmail = communicationEmail;
                this.languageCode = languageCode;
                this.isLoading = false;
            });

            ToastProvider.success("global.preferencesUpdateSuccessMessage".localize());
        });
    };

    @action updateCandidatePreferences = async (
        firstName: string,
        lastName: string,
        communicationEmail: string,
        languageCode: string,
    ) => {
        await this.tryCatch(async () => {
            await PreferencesApi.updateCandidatePreferences(firstName, lastName, communicationEmail, languageCode);

            runInAction(() => {
                this.firstName = firstName;
                this.lastName = lastName;
                this.communicationEmail = communicationEmail;
                this.languageCode = languageCode;
                this.isLoading = false;
            });

            ToastProvider.success("global.preferencesUpdateSuccessMessage".localize());
        });
    };

    @action
    changePassword = async (currentPassword: string, newPassword: string, confirmNewPassword: string) => {
        await this.tryCatch(async () => {
            await PreferencesApi.changePassword(currentPassword, newPassword, confirmNewPassword);

            runInAction(() => {
                this.isLoading = false;
            });

            ToastProvider.success("global.passwordChangedSuccessMessage".localize());
        });
    };

    @action
    loadCurrentNotificationSettings = async () => {
        await this.tryCatch(async () => {
            const result = await UsersApi.getUserNotificationSettings(this.userId);

            runInAction(() => {
                this.notificationSettings = result;
            });
        });
    };

    @action
    updateEmailNotificationSettings = async (
        settings: Dictionary<NotificationType, EmailNotificationFrequencyEnum>,
    ) => {
        await this.tryCatch(async () => {
            await UsersApi.updateEmailNotificationSettings(this.userId, settings);

            runInAction(() => {
                this.notificationSettings.emailNotificationSettings = settings;

                ToastProvider.success("global.userEmailNotificationSettingsChangedSuccessMessage".localize());
            });
        });
    };

    hasWriteAccessToResource = computedFn((resourceType: ItemType, resource: Team | Tag) => {
        if (this.hasOneOfRoles([Role.SecurityManager, Role.SupportAdmin, Role.AdminPartner, Role.AdminSupplier])) {
            return true;
        }

        switch (resourceType) {
            case ItemType.Team:
                resource = resource as Team;

                if (resource.ownerIds && resource.ownerIds.find((x) => x === this.userId)) {
                    return true;
                }

                return false;
            case ItemType.Tag:
                resource = resource as Tag;

                if (!resource.isRestricted) {
                    return true;
                }

                if (
                    resource.isRestricted &&
                    resource.restrictedToUsers &&
                    resource.restrictedToUsers.map((x) => x.id).find((x) => x === this.userId)
                ) {
                    return true;
                }

                return false;
            default:
                return false;
        }
    });

    @computed
    get isAuthenticated() {
        return !!this.accessToken;
    }

    @computed
    get isSupportAdminOrAdminSupplier(): boolean {
        return this.hasOneOfRoles([Role.AdminSupplier, Role.SupportAdmin]);
    }

    // Whenever you require Role.SecurityManager, Role.AdminClientDelegated needs to be there as well. Use this property as much as you can.
    @computed
    get isSecurityManagerOrHigher(): boolean {
        return this.hasOneOfRoles([
            Role.SecurityManager,
            Role.AdminClientDelegated,
            Role.UserPartner,
            Role.AdminPartner,
            Role.AdminSupplier,
        ]);
    }

    @computed
    get isAdminClientOrHigher(): boolean {
        return this.hasOneOfRoles([
            Role.AdminClient,
            Role.SecurityManager,
            Role.AdminClientDelegated,
            Role.UserPartner,
            Role.AdminPartner,
            Role.AdminSupplier,
        ]);
    }

    @computed
    get currentAppContext(): { icon: IconProp; name: string } {
        let icon: IconProp;
        let name: string;

        switch (this.rootStore.scope) {
            case AppScope.Client:
                icon = ["far", "building"];
                name = this.clientName;
                break;
            case AppScope.Partner:
                icon = ["far", "city"];
                name = this.partnerName;
                break;
            case AppScope.Supplier:
                icon = ["far", "warehouse"];
                name = "AtmanCo";
                break;
            case AppScope.Manager:
                icon = ["far", "building"];
                name = `[${"global.manager".localize()}] ${this.clientName}`;
                break;
            case AppScope.Assessment:
                icon = ["far", "building"];
                name = "global.competencyDevelopment".localize();
                break;
            case AppScope.Employee:
                icon = ["far", "user"];
                name = "global.employeeApp".localize();
                break;
            case AppScope.Print:
                icon = ["far", "building"];
                name = "printApp".localize();
                break;
        }

        return {
            icon,
            name,
        };
    }

    @computed
    get hasAccessToMultipleApps(): boolean {
        if (this.hasOneOfRoles([Role.AdminSupplier, Role.AdminPartner, Role.UserPartner])) {
            return true;
        }

        return this.isMultiClientUser;
    }

    autorunner = autorun(() => {});

    jwtTokenHandler = reaction(
        () => this.accessToken,
        action((jwtToken: string) => {
            // @ts-ignore
            const autoRefreshDisabled = window.ATMAN_DISABLE_AUTOREFRESH;

            if (!autoRefreshDisabled) {
                this.jwtTokenHandleMethod(jwtToken);
            }
        }),
    );

    getRefreshedAccessToken = async () => {
        await this.refreshAccessToken();

        return this.accessToken;
    };

    @action
    setAccessToken = ({ accessToken, expires }: { accessToken: string; expires?: number | null }) => {
        const tokenPayload = this.processAccessTokenResponse({ accessToken, expires });

        this.updateUserInfoFromTokenPayload(tokenPayload);
    };

    @action
    processAccessTokenResponse = ({ accessToken, expires }: { accessToken: string; expires?: number | null }) => {
        ApiManager.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        runInAction(() => {
            this.accessToken = accessToken;
            this.accessTokenExpiresAt = expires;
        });

        const tokenPayload = Jwt.decode(accessToken, { json: true }) as IAuthTokenPayload;

        if (expires) {
            const refreshTime = expires - Math.floor(expires / 10);

            if (this.refreshAccessTokenTimeout) {
                window.clearTimeout(this.refreshAccessTokenTimeout);
            }

            this.refreshAccessTokenTimeout = window.setTimeout(() => this.refreshAccessToken(), refreshTime);
        }

        return tokenPayload;
    };
}
