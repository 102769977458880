import "./index.less";
import * as React from "react";
import { AtTitle } from "@atman/design-system";
import { Colors } from "../../styles/variables/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalStores, LocalizationStore, getHelpCenterUrl } from "@atman/business";
import { inject, observer } from "mobx-react";

export interface IHelpCenterInfoBlockProps {
    title: string;
    href: string;
    description?: string;
    ctaLabelOverride?: string;
    localizationStore?: LocalizationStore;
}

export const HelpCenterInfoBlock: React.FC<IHelpCenterInfoBlockProps> = inject(GlobalStores.localizationStore)(
    observer((props) => {
        const { title, href, description, ctaLabelOverride, localizationStore } = props;

        const onLinkClick = async () => {
            const helpCenterUrl = await getHelpCenterUrl(href, localizationStore!.currentFullLocale);
            window.open(helpCenterUrl, "_blank");
        };

        return (
            <div className="HelpCenterInfoBlock">
                <div className="icon-container">
                    <FontAwesomeIcon icon={["far", "book"]} color={Colors.primaryBase} />
                </div>
                <div className="content-section">
                    <AtTitle headingType={3} title={title} />

                    {description && <div className="description">{description}</div>}
                </div>
                <a onClick={onLinkClick}>
                    <span>{ctaLabelOverride ?? "global.checkOutOurHelpCenter".localize()}</span>
                    <FontAwesomeIcon icon={["far", "long-arrow-right"]} color={Colors.primaryBase} />
                </a>
            </div>
        );
    }),
);
