import { AppContextSearchScope, ItemType } from "../../types";

export const appContextSearchScopeToItemTypeMap: Map<AppContextSearchScope, ItemType> = new Map<
    AppContextSearchScope,
    ItemType
>([
    [AppContextSearchScope.Client, ItemType.Client],
    [AppContextSearchScope.Partner, ItemType.Partner],
]);

export const itemTypeToAppContextSearchScopeMap: Map<ItemType, AppContextSearchScope> = new Map<
    ItemType,
    AppContextSearchScope
>();

for (const [k, v] of appContextSearchScopeToItemTypeMap.entries()) {
    itemTypeToAppContextSearchScopeMap.set(v, k);
}
