import { BaseModel, IBaseInput, IBaseModel } from "./BaseModel";
import { BaseStore } from "./BaseStore";
import { ConfigurationProvider, Environment } from "../utils";
import { ToastProvider } from "../libs";
import { action, computed, observable, runInAction, toJS } from "mobx";
import { computedFn } from "mobx-utils";
import { t } from "@lingui/macro";

type ModelConstructor<TInterface, TModel> = { new (json?: TInterface): TModel };

export abstract class BaseCrudStore<
    TModelInterface extends IBaseModel,
    TModelClass extends BaseModel,
    TModelInput extends IBaseInput,
> extends BaseStore {
    @observable protected _items: TModelClass[] = [];
    @computed public get items(): TModelClass[] {
        return this._items;
    }
    @computed public get itemsCount() {
        return this._items.length;
    }

    public readonly findItemById = computedFn((id: string) => this._items.find((x) => x.id === id));
    public readonly findItemIndexById = computedFn((id: string) => this._items.findIndex((x) => x.id === id));

    protected abstract readonly _modelCtor: ModelConstructor<TModelInterface, TModelClass>;
    protected get _toastPrefix(): string {
        return this._modelCtor.name.toCamel();
    }

    protected abstract readonly _loadItems: () => Promise<TModelInterface[]>;
    protected abstract readonly _createItem: (input: TModelInput) => Promise<TModelInterface>;
    protected abstract readonly _updateItem: (id: string, input: TModelInput) => Promise<TModelInterface>;
    protected abstract readonly _deleteItem: (id: string) => Promise<void>;

    @action public readonly loadItems = async () => {
        await this.tryCatch(async () => {
            const result = await this._loadItems();

            runInAction(() => {
                this._items = result.map((x) => new this._modelCtor(x));
            });

            if (ConfigurationProvider.currentEnvironment !== Environment.Prod) {
                console.log(`[${Object.getPrototypeOf(this).constructor.name}] Loaded items:`, toJS(this._items));
            }
        });
    };

    @action public readonly createItem = async (input: TModelInput) => {
        return await this.tryCatch(async () => {
            const result = await this._createItem(input);

            runInAction(() => {
                this._items.push(new this._modelCtor(result));
            });

            ToastProvider.success(t({ id: `global.${this._toastPrefix}.create.success` }));

            return result.id;
        });
    };

    @action public readonly updateItem = async (id: string, input: TModelInput) => {
        const index = this.findItemIndexById(id);

        await this.tryCatch(async () => {
            const result = await this._updateItem(id, input);

            runInAction(() => {
                if (index >= 0) {
                    this._items[index] = new this._modelCtor(result);
                }
            });

            ToastProvider.success(t({ id: `global.${this._toastPrefix}.update.success` }));
        });
    };

    @action public readonly deleteItem = async (id: string) => {
        const index = this.findItemIndexById(id);

        await this.tryCatch(async () => {
            await this._deleteItem(id);

            runInAction(() => {
                if (index >= 0) {
                    this._items.splice(index, 1);
                }
            });

            ToastProvider.success(t({ id: `global.${this._toastPrefix}.delete.success` }));
        });
    };
}
