import { DistributionPieValue } from "..";
import { VictoryPie } from "victory";
import { inject, observer } from "mobx-react";
import React from "react";

export interface IPieChartProps {
    distributionPieData?: DistributionPieValue[]; // Data for the DistributionPie mode
    startAngle?: number; // Min angle
    endAngle: number; // Max angle
}

@inject()
@observer
export class PieChart extends React.Component<IPieChartProps> {
    public static defaultProps = { startAngle: 0, endAngle: 360 };

    render() {
        const { distributionPieData, startAngle, endAngle } = this.props;

        if (distributionPieData === undefined) return null;

        const dataSet = distributionPieData.map((val, i) => ({
            x: i + 1,
            y: val.value,
            label: "", // The val.label prop won't fit in the graph area
            active: true,
        }));

        const colors = distributionPieData.map((val) => val.color);

        return (
            <VictoryPie
                animate={true}
                data={dataSet}
                colorScale={colors}
                labels={() => ""}
                standalone={false}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={160}
                cornerRadius={64}
                padding={0}
            />
        );
    }
}
