import { FitElement, IFitElement } from "./FitElement";

export interface IFitCompetencyInfo extends IFitElement {
    readonly competency: string;
}

export class FitCompetencyInfo extends FitElement implements IFitCompetencyInfo {
    public readonly competency: string;

    constructor(json: IFitCompetencyInfo) {
        super(json);

        this.competency = json.competency;
    }
}
