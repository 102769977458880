import "./UserFormModalContent.less";
import * as React from "react";
import {
    AppScope,
    ClientStore,
    ConfigurationProvider,
    GlobalStores,
    IAppContext,
    IAssignableRoles,
    IUserInput,
    LocalizationStore,
    ModalStore,
    Role,
    Team,
    TeamStore,
    UnhandledScopeError,
    User,
    UserInfoStore,
    UserStore,
    Validator,
    WorkspaceStore,
} from "@atman/business";
import {
    AtCheckboxTitleSubtitle,
    AtCollapse,
    AtSelect,
    IReactSelectOptionObject,
    RadioFormInput,
} from "@atman/design-system";
import { BaseForm, BaseModal, FormGroupSection, ModalButtons } from "../../components";
import { ClientDelegationForm } from "../components/ClientDelegationForm";
import { Col, Row } from "reactstrap";
import { UserBaseInformationFormGroupSection } from "./components";
import { action, computed, observable, runInAction } from "mobx";
import { findCultureFromSet } from "@atman/business/lib/stores/LocalizationStore";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";
import autobind from "autobind-decorator";

export interface IUserFormModalContentProps extends IAppContext {
    workspaceStore?: WorkspaceStore;
    userInfoStore?: UserInfoStore;
    userStore?: UserStore;
    modalStore?: ModalStore;
    teamStore?: TeamStore;
    clientStore?: ClientStore;
    localizationStore?: LocalizationStore;
}

const storesToInject: string[] = [
    GlobalStores.workspaceStore,
    GlobalStores.userInfoStore,
    GlobalStores.userStore,
    GlobalStores.modalStore,
    GlobalStores.localizationStore,
];

switch (ConfigurationProvider.appName) {
    case "client":
        storesToInject.push(GlobalStores.teamStore);
        break;
    case "partner":
        storesToInject.push(GlobalStores.clientStore);
        break;
}

@inject(...storesToInject)
@observer
class UserFormModalContentComp extends BaseForm<IUserFormModalContentProps, {}> {
    @observable public firstName: string = "";
    @observable public lastName: string = "";
    @observable public email: string = "";
    @observable public communicationEmail: string = "";
    @observable public languageCode: IReactSelectOptionObject;
    @observable public baseRole: Role;
    @observable public additionalRoles: Role[] = [];
    @observable public assignableRoles: IAssignableRoles<Role> = {
        baseRoles: [],
        additionalRoles: [],
        additionalRolesByBaseRole: {},
    };
    @observable public ssoNameId?: string = "";

    // Client User Options
    @observable public canViewRestrictedTags: boolean = false;
    @observable public isRestrictedToAssignedWorkspaces: boolean = false;
    @observable public workspaces: IReactSelectOptionObject[] = [];
    @observable public teams: IReactSelectOptionObject[] = [];

    // Partner User Options
    @observable public automaticClientDelegation: boolean = false;
    @observable public clientsDelegationOverride: IReactSelectOptionObject[] = [];

    constructor(props: IUserFormModalContentProps) {
        super(props);

        this.automaticClientDelegation = this.props.userInfoStore!.automaticClientDelegationEnabled === true;

        this.languageCode = {
            value: this.props.localizationStore!.currentLocale.cultureInfo,
            label: this.props.localizationStore!.currentLocale.shortDisplay,
        };
    }

    async componentDidMount() {
        await this.loadAssignableRoles();
        this.loadUser();
    }

    @computed
    get user(): User | undefined {
        const { modalStore, userStore } = this.props;

        const id = modalStore!.childProps && (modalStore!.childProps!.id as string);
        const externalUsers = modalStore!.childProps && (modalStore!.childProps!.externalUsers as boolean);

        if (!id) {
            return;
        }

        if (!externalUsers) {
            return userStore!.getUserById(id);
        }

        return userStore!.entityUsers.find((x) => x.id === id);
    }

    @action.bound
    loadAssignableRoles = async () => {
        const { modalStore, userInfoStore, userStore, scope } = this.props;

        const externalUsers = modalStore!.childProps && (modalStore!.childProps!.externalUsers as boolean);
        const clientId =
            (modalStore!.childProps && (modalStore!.childProps!.clientId as string)) ||
            (this.user && this.user.clientId);
        const partnerId =
            (modalStore!.childProps && (modalStore!.childProps!.partnerProId as string)) ||
            (this.user && this.user.partnerProId);

        let result: IAssignableRoles | undefined;

        switch (scope) {
            case AppScope.Client:
                result = await userStore!.getAssignableRoles(userInfoStore!.partnerId, userInfoStore!.clientId);
                break;
            case AppScope.Partner:
                result = await userStore!.getAssignableRoles(
                    userInfoStore!.partnerId,
                    externalUsers ? clientId : undefined,
                );
                break;
            case AppScope.Supplier:
                result = await userStore!.getAssignableRoles(externalUsers ? partnerId : undefined);
                break;
            default:
                throw new UnhandledScopeError(scope);
        }

        if (!result) {
            return;
        }

        const resultInEnum: IAssignableRoles<Role> = {
            baseRoles: result.baseRoles.map((x) => Role[x]),
            additionalRoles: result.additionalRoles.map((x) => Role[x]),
            additionalRolesByBaseRole: {},
        };

        for (const key of Object.keys(result.additionalRolesByBaseRole)) {
            resultInEnum.additionalRolesByBaseRole[key] = result.additionalRolesByBaseRole[key].map((x) => Role[x]);
        }

        runInAction(() => {
            this.assignableRoles = resultInEnum;
            this.baseRole = Role[result!.baseRoles[0]];
        });
    };

    @action.bound
    loadUser = () => {
        const { workspaceStore, teamStore, clientStore, localizationStore } = this.props;

        if (!this.user) {
            return;
        }

        this.firstName = this.user.firstName;
        this.lastName = this.user.lastName;
        this.email = this.user.email;
        this.communicationEmail = this.user.communicationEmail;
        this.baseRole = Role[this.user.baseRole];
        this.additionalRoles = this.user.additionalRoles.map((x) => Role[x]);
        this.canViewRestrictedTags = this.user.canViewRestrictedTags;
        this.isRestrictedToAssignedWorkspaces = this.user.isRestrictedToAssignedWorkspaces;
        this.ssoNameId = this.user.ssoNameId;
        this.automaticClientDelegation = this.user.automaticClientDelegationDisabled === false;

        const culture = findCultureFromSet(localizationStore!.availableLanguages, this.user!.languageCode);

        if (culture) {
            this.languageCode = {
                value: culture.cultureInfo,
                label: culture.shortDisplay,
            };
        }

        if (workspaceStore?.items && workspaceStore.items.length > 0) {
            this.workspaces = workspaceStore.items
                .filter((x) => this.user!.workspaces.find((w) => w === x.id))
                .map((x) => ({ value: x.id, label: x.tagName }));
        }

        if (clientStore?.clients && clientStore.clients.length > 0) {
            this.clientsDelegationOverride = clientStore.clients
                .filter((x) => this.user!.clientsDelegationOverride?.find((c) => c === x.id))
                .map((x) => ({ value: x.id, label: x.name }));
        }

        if (teamStore?.teams && teamStore.teams.length > 0) {
            this.teams = teamStore.teams
                .filter((x) => x.managerId === this.user!.id)
                .map((x) => ({ value: x.id, label: x.name }));
        }
    };

    @autobind
    public async _onSave() {
        const { modalStore, teamStore, userStore } = this.props;

        const mappedWorkspaces = this.workspaces.map((x) => x.value);
        const mappedTeams = this.teams.map((x) => x.value);
        const mappedClients = this.clientsDelegationOverride.map((x) => x.value);
        const externalUsers = modalStore!.childProps && (modalStore!.childProps!.externalUsers as boolean);

        const input: IUserInput = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            communicationEmail: this.communicationEmail,
            languageCode: this.languageCode.value,
            baseRole: Role[this.baseRole],
            additionalRoles: this.additionalRoles.map((x) => Role[Number(x)]),
            canViewRestrictedTags: this.canViewRestrictedTags,
            isRestrictedToAssignedWorkspaces: this.isRestrictedToAssignedWorkspaces,
            workspaces: mappedWorkspaces,
            teams: mappedTeams,
        };

        if (this.user) {
            await userStore!.updateUser(
                this.user.id,
                {
                    ...input,
                    clientId: this.user.clientId,
                    partnerProId: this.user.partnerProId,
                    ssoNameId: this.ssoNameId,
                },
                externalUsers,
            );
        } else {
            await userStore!.createUser(
                {
                    ...input,
                    automaticClientDelegationDisabled: !this.automaticClientDelegation,
                    clientsDelegationOverride: mappedClients,
                    clientId: modalStore!.childProps!.clientId,
                    partnerProId: modalStore!.childProps!.partnerProId,
                    ssoNameId: this.ssoNameId,
                },
                externalUsers,
            );
        }

        if (!userStore!.hasErrored) {
            teamStore?.loadTeams();
            modalStore!.toggleModal();
        }
    }

    @autobind
    validateForm() {
        return (
            !!this.firstName &&
            !!this.lastName &&
            !!this.email &&
            Validator.validateEmail(this.email) &&
            (!this.communicationEmail ||
                (!!this.communicationEmail && Validator.validateEmail(this.communicationEmail)))
        );
    }

    @action.bound
    onAdditionalRolesChange(event: React.FormEvent<HTMLInputElement>) {
        const selectedRole = Number((event.target as any).value) as Role;
        const index = this.additionalRoles.indexOf(selectedRole);

        if (index >= 0) {
            this.additionalRoles.splice(index, 1);

            if (selectedRole === Role.TeamManager) {
                this.teams = [];
            }
        } else {
            this.additionalRoles.push(selectedRole);
        }
    }

    @action.bound
    onSecurityOptionsChange(event: React.FormEvent<HTMLInputElement>) {
        const eventTarget = event.target as any;

        if (this.hasOwnProperty(eventTarget.id)) {
            this[eventTarget.id] = !this[eventTarget.id];
        }
    }

    @action.bound
    onAutomaticClientDelegationChange() {
        this.automaticClientDelegation = !this.automaticClientDelegation;
    }

    mapTeamToSelectOption = (team: Team) => {
        const hasManager = !!team.managerId;

        return {
            value: team.id,
            label: hasManager ? `${team.name} (${"clientApp.managerAlreadyAssigned".localize()})` : team.name,
            isDisabled: hasManager,
        } as IReactSelectOptionObject;
    };

    @action.bound
    handleWorkspacesChange = (options) => {
        this.workspaces = options ?? [];
    };

    @action.bound
    handleTeamsChange = (options) => {
        this.teams = options ?? [];
    };

    @action.bound
    handleClientsChange = (options) => {
        this.clientsDelegationOverride = options ?? [];
    };

    @action.bound
    onBaseRolesChangeViaRadio = (event: React.FormEvent<HTMLInputElement>) => {
        const value = (event as any).target.value;

        this.onBaseRolesChange(value);
    };

    @action.bound
    onBaseRolesChange = (baseRole: Role) => {
        this.baseRole = Number(baseRole);
        this.additionalRoles = [];
        this.workspaces = [];
        this.teams = [];
    };

    isSelectedRole = (role: Role) => {
        return this.baseRole === role;
    };

    render() {
        const { userStore, workspaceStore, teamStore, scope, modalStore, userInfoStore } = this.props;

        return (
            <BaseModal
                modalTitle={this.user ? "global.userUpdateModal".localize() : "global.userCreationModal".localize()}
                id={"UserFormModalContent"}
            >
                <UserBaseInformationFormGroupSection
                    firstName={this.firstName}
                    lastName={this.lastName}
                    email={this.email}
                    communicationEmail={this.communicationEmail}
                    ssoNameId={this.ssoNameId}
                    languageCode={this.languageCode}
                    disableEmail={!!this.user}
                    showSsoNameId={userInfoStore!.hasOneOfRoles([Role.AuthenticationManager, Role.Developer])}
                    onTextFieldChange={this.onTextFieldChange}
                    onAutoCompleteChange={this.onAutoCompleteChange}
                />
                <FormGroupSection sectionTitle={"global.userRoles".localize()} id="user-roles-form">
                    <Row className="base-roles-selection">
                        {this.assignableRoles.baseRoles.map((baseRole, i) => {
                            const additionalRolesForBaseRole =
                                this.assignableRoles.additionalRolesByBaseRole[Role[baseRole].toCamel()];
                            const showAdditionalRolesSection =
                                additionalRolesForBaseRole?.any() ||
                                scope === AppScope.Client || // Client Context -- canViewRestrictedTags or isRestrictedToAssignedWorkspaces
                                (scope === AppScope.Partner && !this.user); // Partner Context -- Delegation Settings only available on create
                            return (
                                <Col sm={12} key={`role-${baseRole}`}>
                                    <AtCollapse
                                        className={this.isSelectedRole(baseRole) ? "selected" : undefined}
                                        toggleRowContent={
                                            <>
                                                <RadioFormInput
                                                    fieldName={`baseRoles-${i}`}
                                                    value={baseRole}
                                                    onChange={this.onBaseRolesChangeViaRadio}
                                                    checked={this.isSelectedRole(baseRole)}
                                                    valueText={`global.role.${Role[
                                                        Number(baseRole)
                                                    ].toCamel()}`.localize()}
                                                    formText={`global.roleDescription.${Role[
                                                        Number(baseRole)
                                                    ].toCamel()}`.localize()}
                                                    labelSize="lg"
                                                    descriptionSize="md"
                                                    key={i}
                                                />
                                            </>
                                        }
                                        toggleRowSize="sm"
                                        toggleRowSizeHorizontal="md"
                                        renderChevron={false}
                                        isOpen={this.isSelectedRole(baseRole)}
                                        toggleCollapse={() => {
                                            this.onBaseRolesChange(baseRole);
                                        }}
                                    >
                                        {showAdditionalRolesSection && (
                                            <div className="additional-roles-selection">
                                                <div className="additional-roles-label">
                                                    {"global.additionalPermissions".localize()}
                                                </div>
                                                {additionalRolesForBaseRole.map((additionalRole, i) => (
                                                    <div
                                                        className="additional-role-container"
                                                        key={`additional-role-${additionalRole}`}
                                                    >
                                                        <AtCheckboxTitleSubtitle
                                                            id={`role-checkbox-${Role[additionalRole]}`}
                                                            title={`global.role.${Role[
                                                                additionalRole
                                                            ].toCamel()}`.localize()}
                                                            subtitle={`global.roleDescription.${Role[
                                                                additionalRole
                                                            ].toCamel()}`.localize()}
                                                            value={additionalRole}
                                                            checked={
                                                                !!this.additionalRoles.find(
                                                                    (role) => role === additionalRole,
                                                                )
                                                            }
                                                            onChange={this.onAdditionalRolesChange}
                                                            key={i}
                                                        />
                                                        {additionalRole === Role.TeamManager &&
                                                            scope === AppScope.Client &&
                                                            teamStore && (
                                                                <AtSelect
                                                                    options={teamStore.teams.map(
                                                                        this.mapTeamToSelectOption,
                                                                    )}
                                                                    value={this.teams.slice()}
                                                                    isMulti
                                                                    closeMenuOnSelect={false}
                                                                    onChange={this.handleTeamsChange}
                                                                    placeholder={"global.itemTypes.team.placeholders.select".localize()}
                                                                    isDisabled={
                                                                        !this.additionalRoles.find(
                                                                            (role) => role === additionalRole,
                                                                        ) || !!this.user
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                ))}
                                                {this.baseRole === Role.AdminClient && scope === AppScope.Client && (
                                                    <>
                                                        <div className="additional-role-container">
                                                            <AtCheckboxTitleSubtitle
                                                                id="canViewRestrictedTags"
                                                                title={"clientApp.canViewRestrictedTags".localize()}
                                                                subtitle={"clientApp.canViewRestrictedTags.description".localize()}
                                                                value={baseRole}
                                                                checked={this.canViewRestrictedTags}
                                                                onChange={this.onSecurityOptionsChange}
                                                                key={i}
                                                            />
                                                        </div>
                                                        <div className="additional-role-container">
                                                            <AtCheckboxTitleSubtitle
                                                                id="isRestrictedToAssignedWorkspaces"
                                                                title={"clientApp.isRestrictedToAssignedWorkspaces".localize()}
                                                                subtitle={"clientApp.isRestrictedToAssignedWorkspaces.description".localize()}
                                                                value={baseRole}
                                                                checked={this.isRestrictedToAssignedWorkspaces}
                                                                onChange={this.onSecurityOptionsChange}
                                                                key={i}
                                                            />
                                                            <AtSelect
                                                                options={workspaceStore!.items.map((x) => ({
                                                                    value: x.id,
                                                                    label: x.tagName,
                                                                }))}
                                                                value={this.workspaces.slice()}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                onChange={this.handleWorkspacesChange}
                                                                placeholder={"global.itemTypes.workspace.placeholders.select".localize()}
                                                                isDisabled={!this.isRestrictedToAssignedWorkspaces}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {scope === AppScope.Partner &&
                                                    !this.user &&
                                                    !modalStore!.childProps!.clientId && (
                                                        <ClientDelegationForm
                                                            automaticClientDelegation={this.automaticClientDelegation}
                                                            clientsDelegationOverride={this.clientsDelegationOverride}
                                                            onAutomaticClientDelegationChange={
                                                                this.onAutomaticClientDelegationChange
                                                            }
                                                            onClientsDelegationOverrideChange={this.handleClientsChange}
                                                            uniqueKey={Role[baseRole]}
                                                            className={"additional-role-container"}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </AtCollapse>
                                </Col>
                            );
                        })}
                    </Row>
                </FormGroupSection>
                <ModalButtons
                    saveAction={this._onSave}
                    saveLabel={this.user ? "global.updateUser".localize() : "global.inviteUser".localize()}
                    isLoading={userStore!.isLoading}
                    disabledSaveButton={!this.validateForm()}
                />
            </BaseModal>
        );
    }
}

const UserFormModalContent = withAppContext(UserFormModalContentComp);

export { UserFormModalContent };
