import "./index.less";
import { ExtraAndDefaultSizes, FontWeight, Typography } from "../../../internal";
import React from "react";
import cn from "classnames";

export interface IAtTextProps {
    size?: ExtraAndDefaultSizes;
    weight?: FontWeight;
    style?: React.CSSProperties;
    children: React.ReactNode;
    paragraph?: boolean;
    className?: string;
    id?: string;
}

export const AtText: React.FC<IAtTextProps> = ({
    size = "md",
    weight = "medium",
    children,
    paragraph,
    className,
    style,
    id,
    ...otherProps
}) => {
    return (
        <span
            className={cn("AtText", `size-${size}`, className, { paragraph: paragraph })}
            style={{
                ...style,
                ...{
                    fontWeight: Typography.getFontWeightPropValue(weight),
                },
            }}
            id={id}
            {...otherProps}
        >
            {children}
        </span>
    );
};
