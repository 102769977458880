export class GlobalStores {
    // NOTE: Add Stores here as `static readonly` properties

    public static readonly userInfoStore = "userInfoStore";
    public static readonly modalStore = "modalStore";
    public static readonly userStore = "userStore";
    public static readonly clientStore = "clientStore";
    public static readonly partnerStore = "partnerStore";
    public static readonly productBalanceStore = "productBalanceStore";
    public static readonly transactionStore = "transactionStore";
    public static readonly cartStore = "cartStore";
    public static readonly workspaceStore = "workspaceStore";
    public static readonly assessmentStore = "assessmentStore";
    public static readonly kpiStore = "kpiStore";
    public static readonly teamStore = "teamStore";
    public static readonly candidateStore = "candidateStore";
    public static readonly jobStore = "jobStore";
    public static readonly cultureStore = "cultureStore";
    public static readonly contentStore = "contentStore";
    public static readonly uiStore = "uiStore";
    public static readonly rootStore = "rootStore";
    public static readonly localizationStore = "localizationStore";
    public static readonly scopedOrganizationStore = "scopedOrganizationStore";
    public static readonly atStylesDefinitionStore = "atStylesDefinitionStore";
}
