import "./FeatureEntry.less";
import * as React from "react";
import { AtSwitch } from "@atman/design-system";
import { FormGroup, Label } from "reactstrap";

export type AtmanFeatures =
    | "quickLinks"
    | "socioDemo"
    | "hideSocioDemoSensitiveQuestions"
    | "competencyDevelopment"
    | "allowSso"
    | "canAllowSsoOnSubOrganizations"
    | "customConsent"
    | "virtualCoach";

export interface IFeatureEntryProps {
    feature: AtmanFeatures;
    checked: boolean;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    id: string;
}

export const FeatureEntry: React.FC<IFeatureEntryProps> = (props) => {
    const { feature, checked, onChange, id } = props;

    return (
        <FormGroup className="FeatureEntry">
            <div className="left-section">
                <Label>{`global.${feature}`.localize()}</Label>
                <div className="description">{`global.${feature}.description`.localize()}</div>
            </div>
            <div className="right-section">
                <AtSwitch checked={checked} onChange={onChange} id={id} />
            </div>
        </FormGroup>
    );
};
