import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ItemType } from "../types";

export const itemTypeToIcon: Map<ItemType, IconProp> = new Map<ItemType, IconProp>([
    [ItemType.Candidate, "user-circle"],
    [ItemType.User, "user"],
    [ItemType.Tag, "tag"],
    [ItemType.Workspace, "sitemap"],
    [ItemType.Team, "users"],
    [ItemType.QuickLink, "link"],
    [ItemType.Client, "building"],
    [ItemType.Partner, "city"],
    [ItemType.PerformanceEvaluation, "clipboard-list-check"],
    [ItemType.JobFit, "bullseye-arrow"],
    [ItemType.Job, "id-badge"],
    [ItemType.Culture, "theater-masks"],
]);
