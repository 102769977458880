import "./index.less";
import { DefaultSizes, XL, XS } from "../../../types";
import React from "react";
import cn from "classnames";

export interface IAtSubtitleProps {
    subtitle: string | React.ReactNode;
    size?: XS | DefaultSizes | XL;
    className?: string;
    upWeight?: boolean;
}

export const AtSubtitle: React.FC<IAtSubtitleProps> = (props) => {
    const { className, subtitle, size = "md", upWeight = false, ...otherProps } = props;

    return (
        <div
            className={cn("AtSubtitle", className, { [`size-${size}`]: size })}
            {...otherProps}
            style={{
                ...(upWeight
                    ? {
                          fontWeight: 600,
                      }
                    : {}),
            }}
        >
            {subtitle}
        </div>
    );
};
