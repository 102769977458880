import { BasePlatformRootStore } from "../base/BasePlatformRootStore";
import { BaseStore } from "../base/BaseStore";
import {
    CompetencyProfile,
    IAddProductConsumptionModeScheduleEntryModel,
    IBillingInformation,
    IProductConsumptionModeScheduleEntryModel,
    ProductCodeProEnum,
} from "../types";
import {
    ICustomTermsAndConditions,
    IOrganizationCustomTermsConditions,
    IPartnerConsumptionSettings,
    IPartnerInput,
    IPartnerMarketingInfo,
    IPartnerToggledFeatures,
    Partner,
} from "../models";
import { PartnersApi } from "../apis";
import { ReactAppSettings, ToastProvider } from "../libs";
import { action, autorun, computed, observable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";

export class PartnerStore extends BaseStore {
    @observable public partners: Partner[] = [];

    constructor(rootStore: BasePlatformRootStore) {
        super(rootStore);
    }

    @action
    loadPartners = async () => {
        await this.tryCatch(async () => {
            const partners = await PartnersApi.getPartners();

            runInAction(() => {
                this.partners = partners.map((x) => new Partner(x));
            });
        });
    };

    getPartnerById: (id: string) => Partner | undefined = computedFn((id: string) => {
        return this.partners.find((x) => x.id.toLowerCase() === id.toLowerCase());
    });

    getPartnerIndexById: (id: string) => number = computedFn((id: string) => {
        return this.partners.findIndex((x) => x.id.toLowerCase() === id.toLowerCase());
    });

    @action
    createPartner = async (partner: IPartnerInput) => {
        return await this.tryCatch(
            async () => {
                const result = await PartnersApi.createPartner(partner);

                runInAction(() => {
                    this.partners.push(new Partner(result));
                });

                ToastProvider.success("supplierApp.partnerCreationSuccessMessage".localize());

                return result;
            },
            undefined,
            true,
        );
    };

    @action
    updatePartnerName = async (id: string, name: string) => {
        const index = this.getPartnerIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await PartnersApi.updatePartnerName(id, name);

                runInAction(() => {
                    this.partners[index].name = name;
                });

                ToastProvider.success("supplierApp.partnerNameChangedSuccessMessage".localize());
            });
        }
    };

    @action
    updatePartnerAdditionalInformation = async (
        id: string,
        marketingInfo: IPartnerMarketingInfo,
        salesRepId?: string,
    ) => {
        const index = this.getPartnerIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await PartnersApi.updatePartnerAdditionalInformation(id, {
                    marketingInfo,
                    salesRepId,
                });

                runInAction(() => {
                    this.partners[index].marketingInfo = marketingInfo;

                    if (salesRepId) {
                        this.partners[index].ownerId = salesRepId;
                    }
                });

                ToastProvider.success("supplierApp.partnerAdditionalInformationChangedSuccessMessage".localize());
            });
        }
    };

    @action
    updatePartnerEmail = async (id: string, email: string) => {
        const index = this.getPartnerIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await PartnersApi.updatePartnerEmail(id, email);

                runInAction(() => {
                    this.partners[index].email = email;
                });

                ToastProvider.success("partnerEmailChangedSuccessMessage".localize());
            });
        }
    };

    @action
    updatePartnerMarketingInfo = async (id: string, marketingInfo: IPartnerMarketingInfo) => {
        const index = this.getPartnerIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await PartnersApi.updatePartnerMarketingInfo(id, marketingInfo);

                runInAction(() => {
                    this.partners[index].marketingInfo = marketingInfo;
                });

                ToastProvider.success("supplierApp.partnerMarketingInfoChangedSuccessMessage".localize());
            });
        }
    };

    @action
    updatePartnerBillingInformation = async (id: string, billingInformation: IBillingInformation) => {
        const index = this.getPartnerIndexById(id);

        if (index >= 0) {
            const country = ReactAppSettings.appModel.countries.find(
                (x) => x.id === billingInformation.address.country,
            );

            if (!country) {
                ToastProvider.error("Selected country is invalid");
                return;
            }

            const state = country.states.find((x) => x.id === billingInformation.address.state);

            if (country.states.any() && !state) {
                ToastProvider.error("Selected state is invalid");
                return;
            }

            await this.tryCatch(async () => {
                await PartnersApi.updatePartnerBillingInformation(id, {
                    address: billingInformation.address.address,
                    address2: billingInformation.address.address2,
                    city: billingInformation.address.city,
                    zipCode: billingInformation.address.zipCode,
                    stateId: billingInformation.address.state,
                    countryId: billingInformation.address.country,
                    phone: billingInformation.address.phone,
                    extension: billingInformation.address.extension,
                    contactName: billingInformation.contactName,
                    contactEmail: billingInformation.email,
                    legalName: billingInformation.legalName,
                    language: billingInformation.language,
                });

                runInAction(() => {
                    this.partners[index].billingInformation = {
                        ...billingInformation,
                        address: {
                            ...billingInformation.address,
                            country: country.code,
                            state: state ? state.code : "",
                        },
                    };
                });

                ToastProvider.success("supplierApp.partnerBillingInformationChangedSuccessMessage".localize());
            });
        }
    };

    @action
    updatePartnerConsumptionSettings = async (id: string, consumptionSettings: IPartnerConsumptionSettings) => {
        const index = this.getPartnerIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await PartnersApi.updatePartnerConsumptionSettings(id, consumptionSettings);

                runInAction(() => {
                    this.partners[index].consumptionSettings = consumptionSettings;
                    this.partners[index].usageModel.restrictionType = consumptionSettings.restrictionType;
                    this.partners[index].usageModel.discountRate = consumptionSettings.discountRate;

                    ToastProvider.success("supplierApp.partnerConsumptionSettingsChangedSuccessMessage".localize());
                });
            });
        }
    };

    @action
    addPartnerConsumptionScheduleEntry = async (id: string, model: IAddProductConsumptionModeScheduleEntryModel) => {
        const index = this.getPartnerIndexById(id);

        if (index >= 0) {
            await this.tryCatch(
                async () => {
                    const result = await PartnersApi.addPartnerConsumptionScheduleEntry(id, model);

                    runInAction(() => {
                        this.partners[index].usageModel.productConsumptionModeSchedule.push(result);
                    });

                    ToastProvider.success("global.consumptionScheduleEntryAddedSuccessMessage".localize());
                },
                undefined,
                true,
            );
        }
    };

    @action
    updatePartnerConsumptionScheduleEntry = async (id: string, entry: IProductConsumptionModeScheduleEntryModel) => {
        const index = this.getPartnerIndexById(id);

        if (index >= 0) {
            const entryIndex = this.partners[index].usageModel.productConsumptionModeSchedule.findIndex(
                (x) => x.entryId === entry.entryId,
            );

            if (entryIndex >= 0) {
                await this.tryCatch(
                    async () => {
                        await PartnersApi.updatePartnerConsumptionScheduleEntry(id, {
                            entryId: entry.entryId,
                            effectiveDate: entry.effectiveDate,
                            endDate: entry.endDate,
                            consumptionMode: entry.consumptionMode,
                            expectedConsumption: entry.expectedConsumption,
                        });

                        runInAction(() => {
                            this.partners[index].usageModel.productConsumptionModeSchedule[entryIndex] = entry;
                        });

                        ToastProvider.success("global.consumptionScheduleEntryUpdatedSuccessMessage".localize());
                    },
                    undefined,
                    true,
                );
            }
        }
    };

    @action
    removePartnerConsumptionScheduleEntry = async (id: string, entryId: string) => {
        const index = this.getPartnerIndexById(id);

        if (index >= 0) {
            const entryIndex = this.partners[index].usageModel.productConsumptionModeSchedule.findIndex(
                (x) => x.entryId === entryId,
            );

            if (entryIndex >= 0) {
                await this.tryCatch(
                    async () => {
                        await PartnersApi.removePartnerConsumptionScheduleEntry(id, { entryId });

                        runInAction(() => {
                            this.partners[index].usageModel.productConsumptionModeSchedule.splice(entryIndex, 1);
                        });

                        ToastProvider.success("global.consumptionScheduleEntryRemovedSuccessMessage".localize());
                    },
                    undefined,
                    true,
                );
            }
        }
    };

    @action
    updateProductRestrictions = async (id: string, productCodes: ProductCodeProEnum[]) => {
        const index = this.getPartnerIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await PartnersApi.updateProductRestrictions(id, productCodes);

                ToastProvider.success("global.productRestrictionsUpdatedSuccessfully".localize());

                runInAction(() => {
                    this.partners[index].usageModel.productRestriction = productCodes;
                });
            });
        }
    };

    @action
    updatePartnerSalesRep = async (id: string, salesRepId: string) => {
        const index = this.getPartnerIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await PartnersApi.updatePartnerSalesRep(id, salesRepId);

                ToastProvider.success("partnerSalesRepUpdatedSuccessfully".localize);

                runInAction(() => {
                    this.partners[index].ownerId = salesRepId;
                });
            });
        }
    };

    @action
    toggleFeatures = async (id: string, features: IPartnerToggledFeatures) => {
        const index = this.getPartnerIndexById(id);

        if (index >= 0) {
            await this.tryCatch(async () => {
                await PartnersApi.toggleFeatures(id, features);

                ToastProvider.success("global.featuresUpdatedSuccessfully".localize());

                runInAction(() => {
                    this.partners[index].features = features;
                    this.isLoading = false;
                });
            });
        }
    };

    @action activatePartnerCustomTermsConditions = async (id: string): Promise<void> => {
        await this.tryCatch(async () => {
            await PartnersApi.activatePartnerCustomTermsConditions(id);
            ToastProvider.success("global.customTermsConditionsActivatedSuccessfully".localize());
        });
    };

    @action deactivatePartnerCustomTermsConditions = async (id: string): Promise<void> => {
        await this.tryCatch(async () => {
            await PartnersApi.deactivatePartnerCustomTermsConditions(id);
            ToastProvider.success("global.customTermsConditionsDeactivatedSuccessfully".localize());
        });
    };

    @action getOrganizationCustomTermsConditions = async (): Promise<
        IOrganizationCustomTermsConditions | undefined
    > => {
        return await this.tryCatch(async () => {
            return await PartnersApi.getOrganizationCustomTermsConditions();
        });
    };

    @action updateOrganizationCustomTermsConditions = async (
        customTermsConditions: ICustomTermsAndConditions,
    ): Promise<void> => {
        await this.tryCatch(async () => {
            await PartnersApi.updateOrganizationCustomTermsConditions(
                [...customTermsConditions.title.entries()],
                [...customTermsConditions.description.entries()],
            );
            ToastProvider.success("global.customTermsConditionsUpdateSuccessfully".localize());
        });
    };

    @action
    updatePerformanceEvaluationMappings = async (id: string, mappings: Dictionary<CompetencyProfile, string>) => {
        await this.tryCatch(async () => {
            await PartnersApi.updateMappings(id, mappings);

            ToastProvider.success("supplierApp.performanceEvaluationMappingsUpdatedSuccessMessage".localize());
        });
    };

    @action
    updatePartnerBalance = (id: string, updatedBalance: Dictionary<ProductCodeProEnum, number>) => {
        const index = this.getPartnerIndexById(id);

        if (index >= 0) {
            for (const key of Object.keys(updatedBalance)) {
                this.partners[index].usageModel.productBalance[Number(key)] += updatedBalance[Number(key)];
            }
        }
    };

    @computed
    get partnersCount() {
        return this.partners.length;
    }

    autorunner = autorun(() => {});
}
