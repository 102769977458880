import { AssessmentDocumentType } from "./AtmanAssessmentDocumentStructure";

export interface ICandidateFileWithId {
    id: string;
    fileId?: string;
    fileType?: FileType;
    assessmentDocumentType?: AssessmentDocumentType;
    creationDate: string;
    candidateId: string;
    testAtmanProId: string;
    fileName: string;
    fileDescription: string;
    filePath: string;
    fileLanguage: string;
    assessmentVersionName: string;
    assessmentScoreLabel: string;
    fileDisplayName: string;
    fileCategoryName: string;
    documentCategoryOrder: CategoryOrder;
    documentOrder: number;
    customReportTemplate: string;
    state: FileState;
    isInError: boolean;
    isCustom?: boolean;
}

export interface ICandidateFile {
    fileId?: string;
    fileType?: FileType;
    assessmentDocumentType?: AssessmentDocumentType;
    creationDate: string;
    candidateId: string;
    testAtmanProId: string;
    fileName: string;
    fileDescription: string;
    filePath: string;
    fileLanguage: string;
    assessmentVersionName: string;
    assessmentScoreLabel: string;
    fileDisplayName: string;
    fileCategoryName: string;
    documentCategoryOrder: CategoryOrder;
    documentOrder: number;
    customReportTemplate: string;
    state: FileState;
    isInError: boolean;
    isCustom?: boolean;
}

export interface IRequestFileModel {
    testAtmanProId: string;
    scoreLabel?: string;
    documentType?: AssessmentDocumentType;
    language: string;
    customReportTemplateName: string;
    existingFileIdToDelete?: string;
}

export interface IReportsToSendModel {
    fileId: string | null;
    fileGenerationRequest: IRequestFileModel | null;
}

export interface IRequestAllFilesModel {
    language: string;
}

export interface IDownloadRawScoresFileModel {
    testAtmanProIds: string[];
    dimensionsOnly: boolean;
}

export enum FileType {
    Docx = 1,
    Pdf = 2,
    Csv = 3,
    Link = 4,
}

export enum FileState {
    Available,
    InProgress,
    Generated,
}

export enum CategoryOrder {
    Assessment = 1,
    Custom = 2,
    Competencies = 3,
    Development = 4,
    InterviewGuides = 99,
}
