import "./index.less";
import { DominantStyleResultRow } from "../DominantStyleResultRow";
import { IAtStyleDefinition } from "@atman/business";
import React from "react";

export interface IDominantStyleRepartitionProps {
    stylesAndCounts: {
        totalCount: number;
        results: Array<{ style: IAtStyleDefinition; count: number }>;
    };
    isInPrintMode?: boolean;
}

export const DominantStyleRepartition: React.FC<IDominantStyleRepartitionProps> = (props) => {
    const { stylesAndCounts, isInPrintMode = false, ...otherProps } = props;

    const numberOfStylesPerColumns = Math.ceil(stylesAndCounts.results.length / 2);
    const leftColumnData = stylesAndCounts.results.slice(0, numberOfStylesPerColumns);
    const rightColumnData = stylesAndCounts.results.slice(numberOfStylesPerColumns);

    return (
        <div className="DominantStyleRepartition" {...otherProps}>
            {isInPrintMode ? (
                <div className="dominant-style-column">
                    {stylesAndCounts.results.map((x) => (
                        <div key={x.style.id} className="styleResultRow">
                            <DominantStyleResultRow
                                numberOfMemberOfDominantStyle={stylesAndCounts.totalCount}
                                numberOfMember={x.count}
                                styleDefinition={x.style}
                                isInPrintMode={isInPrintMode}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <div className={"dominant-style-column"}>
                        {leftColumnData.map((x) => (
                            <div key={x.style.id} className="styleResultRow">
                                <DominantStyleResultRow
                                    numberOfMemberOfDominantStyle={stylesAndCounts.totalCount}
                                    numberOfMember={x.count}
                                    styleDefinition={x.style}
                                    isInPrintMode={isInPrintMode}
                                />
                            </div>
                        ))}
                    </div>
                    <div className={"dominant-style-column"}>
                        {rightColumnData.map((x) => (
                            <div key={x.style.id} className="styleResultRow">
                                <DominantStyleResultRow
                                    numberOfMemberOfDominantStyle={stylesAndCounts.totalCount}
                                    numberOfMember={x.count}
                                    styleDefinition={x.style}
                                    isInPrintMode={isInPrintMode}
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};
