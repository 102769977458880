import "./index.less";
import Input, { InputType } from "reactstrap/lib/Input";
import React from "react";
import cn from "classnames";

export interface IAtInputBaseProps {
    fieldName: string;
    value: string;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    placeholder?: string;
    type?: InputType;
    size?: "small" | "medium" | "large";
    disabled?: boolean;
    required?: boolean;
}

export const AtInputBase: React.FC<IAtInputBaseProps> = (props) => {
    const { fieldName, value, onChange, placeholder, type, size = "medium", disabled, required, ...otherProps } = props;

    return (
        <Input
            className={cn("AtInputBase", size)}
            type={type}
            name={fieldName}
            id={fieldName}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            {...otherProps}
        />
    );
};
