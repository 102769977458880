// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="index.d.ts" />

import { i18n } from "@lingui/core";
import ChangeCase from "change-case";

String.prototype.toCamel = function () {
    return ChangeCase.camel(this.toString());
};

String.prototype.toPascal = function () {
    return ChangeCase.pascal(this.toString());
};

String.prototype.toSnake = function () {
    return ChangeCase.snake(this.toString());
};

String.prototype.toTitle = function () {
    return ChangeCase.title(this.toString());
};

String.prototype.replaceAll = function (
    searchValue: string | RegExp,
    replaceValueOrReplacer: string | ((substring: string, ...args: any[]) => string),
) {
    let currentValue = this as string;

    while (typeof searchValue === "string" ? currentValue.includes(searchValue) : currentValue.match(searchValue)) {
        if (typeof replaceValueOrReplacer === "string") {
            currentValue = currentValue.replace(searchValue, replaceValueOrReplacer);
        } else {
            currentValue = currentValue.replace(searchValue, replaceValueOrReplacer);
        }
    }

    return currentValue;
};

/**
 * @deprecated please use t({ id: string, message?: string }) or <Trans id={id}>{message}</Trans> instead
 */
String.prototype.localize = function () {
    return i18n._(this.toString());
};

/**
 * @deprecated
 */
String.prototype.setVocabularies = function (vocabularies) {
    console.warn("Deprecated method -- this method doesn't do anything anymore.");
};

/**
 * @deprecated
 */
String.prototype.setLanguage = function (lang) {
    console.warn("Deprecated method -- this method doesn't do anything anymore.");
};

Array.prototype.sum = function <T>(exp) {
    return this.reduce((a, b) => a + (exp(b) ?? 0), 0);
};

Array.prototype.any = function <T>(predicate?: (this: void, value: T, index: number, obj: T[]) => value is T) {
    if (!predicate) {
        return this.length > 0;
    }

    const result = this.find(predicate);

    return !!result;
};

Array.prototype.firstOrDefault = function <T, TDefaultValue>(
    predicate?: (this: void, value: T, index: number, obj: T[]) => value is T,
    defaultValue?: TDefaultValue,
) {
    if (this === null || this.length === 0) {
        return;
    }

    if (!predicate) {
        return this[0];
    }

    const result = this.find(predicate);

    if (!result && defaultValue) {
        return defaultValue;
    }

    return result;
};

Array.prototype.lastOrDefault = function <TDefaultValue>(defaultValue?: TDefaultValue) {
    if (this === null || this.length === 0) {
        return;
    }

    const result = this[this.length - 1];

    if (!result && defaultValue) {
        return defaultValue;
    }

    return result;
};

Array.prototype.toDictionary = function <TElement, TKey = any, TValue = any>(
    key: (element: TElement) => TKey,
    value: (element: TElement) => TValue,
) {
    if (this === null || this.length === 0) {
        return {};
    }

    const returnObject: any = {};

    for (const item of this) {
        returnObject[key(item)] = value(item);
    }

    return returnObject;
};

Array.prototype.distinct = function () {
    return this.filter((value, index) => index === this.indexOf(value));
};

Array.prototype.groupBy = function <T, K>(keySelect: (obj: T) => K): Map<K, T[]> {
    return this.reduce((acc, obj) => {
        const key = keySelect(obj);
        if (!acc.has(key)) acc.set(key, new Array<T>());
        acc.get(key)?.push(obj);
        return acc;
    }, new Map<K, Array<T>>());
};

// Object.prototype.toArray = function <TElement>(keyFieldName: string): TElement[] {
//     if (this === null) {
//         return [];
//     }
//
//     return Object.keys(this).map(x => ({
//         ...this[x],
//         [keyFieldName]: x
//     }));
// }
