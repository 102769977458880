import "./ExportResultCompDevCampaign.less";
import * as React from "react";
import {
    AtCardSelect,
    AtCheckboxListItems,
    AtHint,
    AtIcon,
    AtModal,
    AtSelect,
    AtText,
    IReactSelectOptionObject,
} from "@atman/design-system";
import { Colors } from "../../styles";
import {
    CompetencyDevelopmentAssessmentStatus,
    CompetencyDevelopmentAssessmentType,
    CompetencyDevelopmentExtractionType,
    CompetencyDevelopmentProcessData,
    CompetencyDevelopmentProcessStatus,
    GlobalStores,
    IShortCandidate,
    LocalizationStore,
    ReactAppSettings,
} from "@atman/business";
import { Trans } from "@lingui/react";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useState } from "react";

export interface IExportResultCompDevCampaignProps {
    isLoading: boolean;
    members: IShortCandidate[];
    isOpen: boolean;
    setIsOpen: (visibility: boolean) => void;
    localizationStore?: LocalizationStore;
    handleSendExportRequest: (value: {
        type: CompetencyDevelopmentExtractionType;
        languageCode: string;
        candidateIds: string[];
    }) => void;
    processData: Map<string, CompetencyDevelopmentProcessData | undefined>;
}

export const ExportResultCompDevCampaign: React.FC<IExportResultCompDevCampaignProps> = inject(
    GlobalStores.localizationStore,
)(
    observer((props) => {
        const { localizationStore, members, isOpen, setIsOpen, isLoading, handleSendExportRequest, processData } =
            props;

        const CompetencyDevelopmentExtractionTypeOptions: IReactSelectOptionObject<CompetencyDevelopmentExtractionType>[] =
            [
                {
                    value: CompetencyDevelopmentExtractionType.Competencies,
                    label: t({ id: "global.enums.CompetencyDevelopmentExtractionType.Competencies" }),
                    subtitle: t({ id: "competencyDevelopment.export.level.competencies.subtitle" }),
                    isDisabled: false,
                },
                {
                    value: CompetencyDevelopmentExtractionType.SubCompetencies,
                    label: t({ id: "global.enums.CompetencyDevelopmentExtractionType.SubCompetencies" }),
                    subtitle: t({ id: "competencyDevelopment.export.level.subCompetencies.subtitle" }),
                    isDisabled: false,
                },
                {
                    value: CompetencyDevelopmentExtractionType.ObservableBehaviors,
                    label: t({ id: "global.enums.CompetencyDevelopmentExtractionType.ObservableBehaviors" }),
                    subtitle: t({ id: "competencyDevelopment.export.level.observableBehaviors.subtitle" }),
                    isDisabled: false,
                },
            ];

        const [candidateIds, setCandidateIds] = useState<string[]>([]);
        const [type, setType] = useState<CompetencyDevelopmentExtractionType>(
            CompetencyDevelopmentExtractionTypeOptions[0].value,
        );
        const [languageCode, setLanguageCode] = React.useState<IReactSelectOptionObject>({
            value: localizationStore!.currentLocale.cultureInfo,
            label: localizationStore!.currentLocale.shortDisplay,
        });

        const languageOptions = localizationStore!.availableLanguages.map((x) => ({
            value: x.shortName,
            label: x.shortDisplay,
        }));

        const onSelectLanguageChange = (option: IReactSelectOptionObject) => {
            if (option) {
                setLanguageCode(option);
            }
        };

        const onSelectCompetencyDevelopmentChange = (value: number) => {
            setType(CompetencyDevelopmentExtractionTypeOptions[value].value);
        };

        const closeModal = () => {
            setIsOpen(false);
            setType(CompetencyDevelopmentExtractionTypeOptions[0].value);
        };

        const getFullName = (teamMember: IShortCandidate) => {
            return teamMember?.firstName || teamMember?.lastName
                ? `${teamMember?.firstName} ${teamMember?.lastName}`
                : teamMember?.email;
        };

        const onClickMainAction = () => {
            handleSendExportRequest({
                type: type,
                languageCode: languageCode.value,
                candidateIds,
            });
        };

        const checkIfMemberHasExportableResults = (member: IShortCandidate): boolean => {
            const candidateProcessData = processData.get(member.candidateProId);

            if (!candidateProcessData || candidateProcessData.status === CompetencyDevelopmentProcessStatus.Cancelled) {
                return true;
            }

            const assessmentsData = processData.get(member.candidateProId)?.assessmentsData;

            if (!assessmentsData) {
                return true;
            }

            const relevantAssessmentsForCompDevExport = [
                CompetencyDevelopmentAssessmentType.Self,
                CompetencyDevelopmentAssessmentType.Manager,
                CompetencyDevelopmentAssessmentType.Review,
            ];

            const hasAssessmentCompleted = [...assessmentsData.entries()].some(
                ([key, value]) =>
                    relevantAssessmentsForCompDevExport.includes(key) &&
                    value.status === CompetencyDevelopmentAssessmentStatus.Completed,
            );

            return !hasAssessmentCompleted;
        };

        return (
            <AtModal
                size="lg"
                className="ExportResultCompDevCampaign"
                title={t({ id: "clientApp.compDev.tiers.evaluator.exportResultModalTitle" })}
                open={isOpen}
                setOpen={setIsOpen}
                actions={[
                    {
                        label: t({ id: "global.cancel" }),
                        clickHandler: closeModal,
                        color: "secondary",
                    },
                    {
                        label: t({ id: "global.export" }),
                        color: "primary",
                        clickHandler: onClickMainAction,
                        className: "mainAction",
                        isLoading,
                    },
                ]}
                headerHeadingType={4}
                displayCrossButton
            >
                <>
                    <div className="selectors">
                        <AtHint>
                            <div>
                                <AtIcon icon={["fas", "info-circle"]} color={Colors.actionColor} />
                                <AtText>
                                    <Trans
                                        id="competencyDevelopment.export.information"
                                        message=" The data export will be sent to the communication email associated with your user."
                                    />
                                </AtText>
                            </div>
                        </AtHint>

                        <AtText weight="bold">
                            <Trans
                                id="competencyDevelopment.export.level.selectTheExportLevel"
                                message=" Select export level"
                            />
                        </AtText>
                        <div className="radioContainer">
                            {CompetencyDevelopmentExtractionTypeOptions.map((x) => (
                                <AtCardSelect
                                    key={`exportLevel-${x.label}`}
                                    icon={["fal", "stars"]}
                                    title={x.label}
                                    subtitle={x!.subtitle}
                                    id="startProcess-sendEval"
                                    value={x.value}
                                    checked={type === x.value}
                                    onClickCardSelect={() => onSelectCompetencyDevelopmentChange(x.value)}
                                />
                            ))}
                            <AtSelect
                                name="languageCode"
                                label={t({ id: "global.communicationLanguage" })}
                                options={languageOptions.filter((language) =>
                                    (ReactAppSettings.appModel?.allowedLanguageCodesForAssessmentApp ?? []).includes(
                                        language.value,
                                    ),
                                )}
                                value={languageCode}
                                onChange={onSelectLanguageChange}
                            />
                        </div>
                    </div>
                    <AtCheckboxListItems
                        checkAllInMount
                        items={members
                            .map((member) => ({
                                label: getFullName(member),
                                value: member.candidateProId,
                                disable: checkIfMemberHasExportableResults(member),
                            }))
                            .sort((x, y) => Number(x.disable) - Number(y.disable))}
                        selectedItems={candidateIds}
                        onChangeItems={setCandidateIds}
                        parentLabel={t({ id: "competencyDevelopment.export.allMembers" })}
                        parentKey="allMembers"
                    />
                </>
            </AtModal>
        );
    }),
);
