import { AtTableItemKeyType, AtTableSort } from "../../types";
import { IBaseEntity } from "@atman/core";

const sortNumber = <T extends IBaseEntity>(field: keyof T): AtTableSort<T> => {
    return (a: T, b: T) => Number(a[field]) - Number(b[field]);
};
const sortString = <T>(field: keyof T) => {
    return (a: T, b: T) => String(a[field]).localeCompare(String(b[field]));
};
const sortBoolean = <T>(field: keyof T) => {
    return (a: T, b: T) => (Boolean(a[field]) === Boolean(b[field]) ? 0 : Boolean(a[field]) ? -1 : 1);
};

export function generateDefaultSortByType<T extends IBaseEntity>(
    field: keyof T,
    itemKeyType: AtTableItemKeyType,
): AtTableSort<T> | null {
    switch (itemKeyType) {
        case "string":
            return sortString(field);

        case "number":
            return sortNumber(field);

        case "boolean":
            return sortBoolean(field);

        default:
            return null;
    }
}
