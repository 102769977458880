import "./index.less";
import * as React from "react";
import { ApiError, FieldError } from "@atman/core";
import { AtButton, AtContainer, AtSubtitle, AtTitle } from "@atman/design-system";
import { CustomValidatedTextInput } from "@atman/ui-kit";
import { Form } from "reactstrap";
import { Trans, t } from "@lingui/macro";
import { observer } from "mobx-react";

export interface IConfirmCodeViewProps {
    code: string;
    setCode: (e: React.FormEvent<HTMLInputElement>) => void;
    errors: ReadonlyArray<FieldError | ApiError>;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    isLoading: boolean;
}

export const ConfirmCodeView: React.FC<IConfirmCodeViewProps> = observer((props) => {
    const { code, setCode, errors, onSubmit, isLoading } = props;

    return (
        <div id="ConfirmCodeView">
            <AtTitle
                title={t({
                    id: "loginApp.employee.signIn.confirmCodeStep.title",
                    message: "Check your email for a code",
                })}
                headingType={1}
            />
            <AtSubtitle
                subtitle={t({
                    id: "loginApp.employee.signIn.confirmCodeStep.subtitle",
                    message:
                        "We've sent a 6-digit code to your contact email. The code expires shortly, so please enter it soon.",
                })}
                size={"xl"}
            />
            <Form onSubmit={onSubmit}>
                <CustomValidatedTextInput
                    fieldName={"code"}
                    hideLabel
                    value={code}
                    onChange={setCode}
                    validationErrors={errors}
                />
                <AtButton
                    id="code-submit-btn"
                    color="primary"
                    size="xl"
                    fullWidth
                    isLoading={isLoading}
                    type={"submit"}
                >
                    <Trans id="loginApp.employee.signIn.confirmCodeStep.buttonLabel">Sign in</Trans>
                </AtButton>
            </Form>
            <AtContainer className={"info-container"}>
                <Trans id="loginApp.employee.signIn.confirmCodeStep.helpText">
                    Can't find your code? Check your spam folder!
                </Trans>
            </AtContainer>
        </div>
    );
});
