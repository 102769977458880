import { BaseComponent, IBaseComponentProps } from "../BaseComponent";
import { BootstrapSizes, IRange } from "@atman/business";
import { CSSProperties } from "react";
import { Default, Desktop, Mobile, Tablet } from "./MediaQueryComponents";
import { Layouts } from "@atman/core";
import { action, computed, observable } from "mobx";
import { computedFn } from "mobx-utils";
import React from "react";
import cn from "classnames";

export enum LayoutSize {
    Mobile,
    Tablet,
    Desktop,
    Default,
}

export interface IBaseResponsiveComponentProps extends IBaseComponentProps {
    id?: string;
    className?: string;
    style?: CSSProperties;
}

export abstract class BaseResponsiveComponent<TProps, TState = {}> extends BaseComponent<
    IBaseResponsiveComponentProps & TProps,
    TState
> {
    @observable protected width: number = window.innerWidth;
    @observable protected height: number = window.innerHeight;
    @observable protected orientation?: OrientationType = window.screen?.orientation?.type;

    constructor(props: IBaseResponsiveComponentProps & TProps) {
        super(props);
    }

    protected get rootElementClassName(): string | undefined {
        return this.props.className ?? "";
    }

    protected get rootElementId(): string | undefined {
        return this.props.id;
    }

    protected get rootElementStyle(): CSSProperties {
        return this.props.style ?? {};
    }

    protected get containerProps(): React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
        return {};
    }

    protected supportedLayouts: LayoutSize[] = [
        LayoutSize.Mobile,
        LayoutSize.Tablet,
        LayoutSize.Desktop,
        LayoutSize.Default,
    ];

    componentDidMount(): void {
        window.addEventListener("resize", this.handleResize);

        if ("onorientationchange" in window) {
            window.addEventListener("orientationchange", this.handleOrientationChange);
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.handleResize);

        if ("onorientationchange" in window) {
            window.removeEventListener("orientationchange", this.handleOrientationChange);
        }
    }

    supportsLayout: (layout: LayoutSize) => boolean = computedFn((layout: LayoutSize) => {
        return this.supportedLayouts.some((x) => x === layout);
    });

    @action.bound
    protected handleResize = (): void => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    };

    @action.bound
    protected handleOrientationChange = (): void => {
        this.orientation = window.screen?.orientation?.type;
        this.handleResize();
    };

    @computed
    protected get bootstrapSize(): BootstrapSizes | undefined {
        for (const key of Object.keys(Layouts)) {
            const range = Layouts[key] as IRange;

            if (this.width >= range.min && this.width <= range.max) {
                return key as BootstrapSizes;
            }
        }

        return undefined;
    }

    @computed
    protected get isMobile(): boolean {
        return this.width <= Layouts.sm.max;
    }

    @computed
    protected get isTablet(): boolean {
        return this.width >= Layouts.md.min && this.width <= Layouts.md.max;
    }

    @computed
    protected get isDesktop(): boolean {
        return this.width >= Layouts.lg.min;
    }

    @computed
    protected get isDefault(): boolean {
        return this.width >= Layouts.md.min;
    }

    protected renderMobile(): React.ReactNode {
        return null;
    }

    protected renderTablet(): React.ReactNode {
        return null;
    }

    protected renderDesktop(): React.ReactNode {
        return null;
    }

    protected renderDefault(): React.ReactNode {
        return null;
    }

    protected renderAll(): React.ReactNode {
        return null;
    }

    render(): JSX.Element | null {
        const allContent = this.renderAll();

        if (allContent) {
            return (
                <div
                    id={this.rootElementId}
                    className={cn(this.rootElementClassName, {
                        "-device-mobile": this.isMobile,
                        "-device-default": this.isDefault,
                    })}
                    style={this.rootElementStyle}
                    {...this.containerProps}
                >
                    {allContent}
                </div>
            );
        }

        const mobileContent = this.renderMobile();
        const tabletContent = this.renderTablet();
        const desktopContent = this.renderDesktop();
        const defaultContent = this.renderDefault();

        return (
            <>
                <Mobile>
                    {this.supportsLayout(LayoutSize.Mobile) && mobileContent && (
                        <div
                            id={this.rootElementId}
                            className={cn(this.rootElementClassName, "-device-mobile")}
                            style={this.rootElementStyle}
                            {...this.containerProps}
                        >
                            {mobileContent}
                        </div>
                    )}
                </Mobile>
                <Tablet>
                    {this.supportsLayout(LayoutSize.Tablet) && tabletContent && (
                        <div
                            id={this.rootElementId}
                            className={cn(this.rootElementClassName, "-device-tablet")}
                            style={this.rootElementStyle}
                            {...this.containerProps}
                        >
                            {tabletContent}
                        </div>
                    )}
                </Tablet>
                <Desktop>
                    {this.supportsLayout(LayoutSize.Desktop) && desktopContent && (
                        <div
                            id={this.rootElementId}
                            className={cn(this.rootElementClassName, "-device-desktop")}
                            style={this.rootElementStyle}
                            {...this.containerProps}
                        >
                            {desktopContent}
                        </div>
                    )}
                </Desktop>
                <Default>
                    {this.supportsLayout(LayoutSize.Default) && defaultContent && (
                        <div
                            id={this.rootElementId}
                            className={cn(this.rootElementClassName, "-device-default")}
                            style={this.rootElementStyle}
                            {...this.containerProps}
                        >
                            {defaultContent}
                        </div>
                    )}
                </Default>
            </>
        );
    }
}

export { Mobile, Tablet, Desktop, Default };
