import { BaseApi } from "../../base";
import { HttpMethodEnum } from "../../types";
import {
    IAssessmentStructure,
    ICompetencyDevelopmentProcessResultsData,
    ISelectedObjectiveData,
    SelectedSubCompetencyToImproveData,
} from "../../models";
import { IEmployeeDevelopmentProcess } from "./models";
import { IMapEntries } from "mobx";
import { ReactAppSettings } from "../../libs";

export interface IEmployeeDevelopmentApi {
    getCompDevProcesses(): Promise<IEmployeeDevelopmentProcess[]>;
    getCompDevProcess(processId: string): Promise<IEmployeeDevelopmentProcess>;
    getCompDevProcessResultsData(processId: string): Promise<ICompetencyDevelopmentProcessResultsData>;
    getCompDevAssessmentStructure(assessmentId: string): Promise<IAssessmentStructure>;
    startAssessment(assessmentId: string): Promise<void>;
    saveAssessment(
        assessmentId: string,
        competencyId: string,
        observableBehaviorScores: IMapEntries<string, number | undefined>,
        observableBehaviorNotes: IMapEntries<string, string | undefined>,
    ): Promise<{ isCompleted: boolean }>;
    selectToLeverageObjectives(
        assessmentId: string,
        selectedObjectives: IMapEntries<string, IMapEntries<string, ISelectedObjectiveData>>,
    ): Promise<void>;
    selectToImproveObjectives(
        assessmentId: string,
        selectedSubCompetenciesObjectives: IMapEntries<string, IMapEntries<string, SelectedSubCompetencyToImproveData>>,
    ): Promise<void>;
}

export default class EmployeeDevelopmentApi extends BaseApi implements IEmployeeDevelopmentApi {
    getCompDevProcesses(): Promise<IEmployeeDevelopmentProcess[]> {
        return this.sendApiRequest<IEmployeeDevelopmentProcess[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCompDevProcesses,
        );
    }

    getCompDevProcess(processId: string): Promise<IEmployeeDevelopmentProcess> {
        return this.sendApiRequest<IEmployeeDevelopmentProcess>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCompDevProcess,
            undefined,
            processId,
        );
    }

    getCompDevProcessResultsData(processId: string): Promise<ICompetencyDevelopmentProcessResultsData> {
        return this.sendApiRequest<ICompetencyDevelopmentProcessResultsData>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCompDevProcessResultsData,
            undefined,
            processId,
        );
    }

    getCompDevAssessmentStructure(assessmentId: string): Promise<IAssessmentStructure> {
        return this.sendApiRequest<IAssessmentStructure>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCompDevAssessmentStructure,
            undefined,
            assessmentId,
        );
    }

    startAssessment(assessmentId: string): Promise<void> {
        return this.sendApiRequest(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.startCompDevAssessment,
            undefined,
            assessmentId,
        );
    }

    saveAssessment(
        assessmentId: string,
        competencyId: string,
        observableBehaviorScores: IMapEntries<string, number | undefined>,
    ): Promise<{ isCompleted: boolean }> {
        return this.sendApiRequest<{ isCompleted: boolean }>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.saveCompDevAssessment,
            { competencyId, observableBehaviorScores },
            assessmentId,
        );
    }

    selectToLeverageObjectives(
        assessmentId: string,
        selectedObjectives: IMapEntries<string, IMapEntries<string, ISelectedObjectiveData>>,
    ): Promise<void> {
        return this.sendApiRequest(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.selectCompDevObjectives,
            { selectedObjectives },
            assessmentId,
        );
    }

    selectToImproveObjectives(
        assessmentId: string,
        selectedSubCompetenciesObjectives: IMapEntries<string, IMapEntries<string, SelectedSubCompetencyToImproveData>>,
    ): Promise<void> {
        return this.sendApiRequest(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.selectCompDevObjectives,
            { selectedSubCompetenciesObjectives },
            assessmentId,
        );
    }
}
