import "./index.less";
import * as React from "react";
import { BaseForm } from "../BaseForm";
import { BaseModal } from "../BaseModal";
import { action, observable } from "mobx";

export interface IBaseStepComponentProps {}

export abstract class BaseStepComponent<SpecificProps, TEntity> extends BaseForm<
    IBaseStepComponentProps & SpecificProps,
    TEntity
> {
    @observable protected currentStep: number = 0;

    @action.bound
    protected goToStep = (step?: number) => {
        this.currentStep = step !== undefined ? step : this.currentStep + 1;
    };

    @action.bound
    protected goBack = () => {
        this.currentStep -= 1;
    };

    @action.bound
    protected goNext = () => {
        this.currentStep += 1;
    };

    protected renderBaseModal = (modalTitle: string, children: React.ReactNode, id?: string) => {
        return (
            <BaseModal modalTitle={modalTitle} id={id}>
                {children}
            </BaseModal>
        );
    };
}
