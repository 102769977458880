import { Browser } from "../types";
import { BrowserHelper } from "./BrowserHelper";

export class FullscreenLogic {
    static getFullscreenSupported(): boolean {
        let fullscreenEnabled: boolean;

        switch (BrowserHelper.browser) {
            case Browser.IE:
                // @ts-ignore /* IE11 Support */
                fullscreenEnabled = document.msFullscreenEnabled;
                break;
            case Browser.Safari:
                // @ts-ignore /* Safari Support */
                fullscreenEnabled = document.webkitFullscreenEnabled;
                break;
            default:
                fullscreenEnabled = document.fullscreenEnabled;
        }

        return fullscreenEnabled ?? false;
    }

    static isFullScreen(): boolean {
        let fullscreenElement: Element | null;

        switch (BrowserHelper.browser) {
            case Browser.IE:
                // @ts-ignore /* IE11 Support */
                fullscreenElement = document.msFullscreenElement;
                break;
            case Browser.Safari:
                // @ts-ignore /* Safari Support */
                return document.webkitIsFullScreen;
            default:
                fullscreenElement = document.fullscreenElement;
        }

        return fullscreenElement !== null;
    }

    static async openFullscreen() {
        const elem = document.documentElement;
        if (elem != null) {
            if (elem.requestFullscreen) {
                await elem.requestFullscreen();
            } // @ts-ignore
            else if (elem.webkitRequestFullscreen) {
                // @ts-ignore /* Safari */
                elem.webkitRequestFullscreen();
            } // @ts-ignore
            else if (elem.msRequestFullscreen) {
                // @ts-ignore /* IE11 */
                elem.msRequestFullscreen();
            }
        }
    }

    static async closeFullscreen() {
        if (document.exitFullscreen) {
            await document.exitFullscreen();
        } // @ts-ignore
        else if (document.webkitExitFullscreen) {
            // @ts-ignore /* Safari */
            document.webkitExitFullscreen();
        } // @ts-ignore
        else if (document.webkitCancelFullscreen) {
            // @ts-ignore /* Safari */
            document.webkitCancelFullscreen();
        } // @ts-ignore
        else if (document.msExitFullscreen) {
            // @ts-ignore /* IE11 */
            document.msExitFullscreen();
        }
    }
}
