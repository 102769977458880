import * as Platform from "platform";

if (Platform.name === "IE") {
    delete HTMLElement.prototype.contains;

    Node.prototype.contains = function contains(...args) {
        if (!(0 in args)) {
            throw new TypeError("1 argument is required");
        }

        let [node] = args;

        do {
            if (this === node) {
                return true;
            }
        } while ((node = node && node.parentNode));

        return false;
    };
}
