import { ValidationError } from "@atman/core";
import { t } from "@lingui/macro";

export class Validator {
    static validateEmail(email: string) {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"À-ž]+(\.[^<>()\[\]\\.,;:\s@"À-ž]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static validateUrl(url: string) {
        const re =
            /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        return re.test(String(url));
    }

    static validateEmails(emails: string[]) {
        if (!emails) {
            return false;
        }

        for (const email of emails) {
            const result = this.validateEmail(email);

            if (!result) {
                return false;
            }
        }

        return true;
    }

    static validatePassword(password: string, confirmPassword: string): ValidationError[] {
        const errors: ValidationError[] = [];

        const newPasswordFieldName = "newPassword";

        if (password.length < 8) {
            errors.push(
                new ValidationError(
                    newPasswordFieldName,
                    t({
                        id: "global.errors.password.minLength",
                        message: "Your password must have at least 8 characters",
                    }),
                ),
            );
        }

        if (!/.*[A-Z].*/.test(password)) {
            errors.push(
                new ValidationError(
                    newPasswordFieldName,
                    t({
                        id: "global.errors.password.uppercaseChar",
                        message: "Your password must contain at least one uppercase character",
                    }),
                ),
            );
        }

        if (!/.*[a-z].*/.test(password)) {
            errors.push(
                new ValidationError(
                    newPasswordFieldName,
                    t({
                        id: "global.errors.password.lowercaseChar",
                        message: "Your password must contain at least one lowercase character",
                    }),
                ),
            );
        }

        if (!/.*\d.*/.test(password)) {
            errors.push(
                new ValidationError(
                    newPasswordFieldName,
                    t({
                        id: "global.errors.password.numberChar",
                        message: "Your password must contain at least one number",
                    }),
                ),
            );
        }

        if (password !== confirmPassword) {
            errors.push(
                new ValidationError(
                    "newPasswordConfirm",
                    t({
                        id: "global.errors.password.doesNotMatchConfirm",
                        message: "The provided passwords do not match",
                    }),
                ),
            );
        }

        return errors;
    }
}
