import "./index.less";
import { AtButtonBase } from "../../atoms/AtButtonBase";
import { IAtButtonProps } from "../AtButton";
import React from "react";
import cn from "classnames";

export interface IAtButtonTabsButtonLabel {
    id: string;
    buttonContent: React.ReactNode;
    buttonProps?: Omit<IAtButtonProps, "children" | "onClick">;
}

export interface IAtButtonTabsProps {
    fitContent?: boolean;
    className?: string;
    spaceBetween?: number;
    buttons: IAtButtonTabsButtonLabel[];
    activeButton?: string;
    onClick: (id: string) => void;
    round?: boolean;
    border?: boolean;
}

export const AtButtonTabs: React.FC<IAtButtonTabsProps> = ({
    buttons,
    fitContent,
    className,
    spaceBetween,
    onClick,
    activeButton,
    round,
    border = true,
    ...otherProps
}) => {
    const buttonWidth = fitContent ? "fitContent" : `calc((100% / ${buttons.length}))`;

    return (
        <div
            className={cn("AtButtonTabs", className, {
                fitContent,
                fullWidth: !fitContent,
                round: round,
                border: border,
            })}
            {...otherProps}
        >
            {buttons.map((button) => {
                return (
                    <AtButtonBase
                        key={button.id}
                        id={button.id}
                        style={{ width: buttonWidth }}
                        color={activeButton === button.id ? "secondary" : "ghost"}
                        onClick={() => {
                            onClick(button.id);
                        }}
                        {...button.buttonProps}
                    >
                        {button.buttonContent}
                    </AtButtonBase>
                );
            })}
        </div>
    );
};
