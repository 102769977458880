import "./ScaleCell.less";
import * as React from "react";
import { ColorProperty } from "csstype";
import { ScaleDotIndicator } from "./ScaleDotIndicator";
import { observer } from "mobx-react";
import { useDetectDevice } from "@atman/core";

export interface IScaleValueHighlight {
    color: ColorProperty;
    score: number;
}
export interface IScaleCellProps {
    highlights?: Array<IScaleValueHighlight>; // List of highlight indicators. Leave empty [] if no indicator for this cell. Leave undefined if you want to clear the reserved space.
    inputId?: string;
    selected?: boolean;
}
export const ScaleCell: React.FC<IScaleCellProps> = observer((props) => {
    const { children, highlights, inputId, selected = false } = props;
    const { isMobile } = useDetectDevice();

    const renderMobile = () => {
        let classes: string = "ScaleCell d-flex justify-content-start align-items-center";

        if (selected) {
            classes += " selected";
        }

        const clickInput = () => {
            if (!inputId) {
                return;
            }

            const input = document.getElementById(inputId);

            if (input) {
                input.click();
            }
        };

        return (
            <div className={classes} onClick={clickInput}>
                {children}

                {highlights && (
                    <div className="dot-indicator-container d-flex">
                        {highlights?.map((h, i) => (
                            <ScaleDotIndicator key={`scale-value-highlight-${i}`} color={h.color} />
                        ))}
                    </div>
                )}
            </div>
        );
    };

    const renderDefault = () => {
        return (
            <div className={`ScaleCell`}>
                {highlights && (
                    <div className="dot-indicator-container d-flex justify-content-center align-items-center">
                        {highlights?.map((h, i) => (
                            <ScaleDotIndicator key={`scale-value-highlight-${i}`} color={h.color} />
                        ))}
                    </div>
                )}

                {children}
            </div>
        );
    };

    return isMobile ? renderMobile() : renderDefault();
});
