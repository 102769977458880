import "./index.less";
import {
    AnalyzedCandidate,
    AssessmentResultsCalculationService,
    AssessmentType,
    DimensionStructure,
    DistributionViewMode,
    GlobalStores,
    IAssessmentResultsCalculationService,
    IDistributionEntry,
    IInterpretationData,
    IPersonScore,
    IRangePositionInfo,
    PrintModeEnum,
    ReportType,
    ScaleMaxScore,
    ScaleType,
    TeamStore,
} from "@atman/business";
import { AtCompatibilityFitDataView } from "../AtCompatibilityFitDataView";
import { AtContainer, AtParagraph, AtPersonCard, AtText, AtTitle, AtTooltip, Sizing } from "@atman/design-system";
import { AtScaleLegend } from "../AtScaleLegend";
import { AtSubScaleNavigation } from "../AtSubScaleNavigation";
import { AtSubScaleNavigationItem } from "../AtSubScaleNavigation/components/AtSubScaleNavigationItem";
import { BaseResponsiveComponent } from "../../BaseResponsiveComponent";
import { Col, Collapse, Row } from "reactstrap";
import { Colors } from "../../../styles/variables/colors";
import { FitScoreContainer } from "../FitScoreContainer";
import { FitScoreContainerAndLegend } from "../FitScoreContainerAndLegend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InterpretationDataView } from "../InterpretationDataView";
import { RouteComponentProps } from "react-router";
import { STANINE_SCALE_SEPARATORS } from "../../AtGenericScales";
import { ScoreLineContainer } from "./components/ScoreLineContainer";
import { action, autorun, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import { withRouter } from "react-router-dom";
import React from "react";
import cn from "classnames";

export interface IBaseAtScaleProps extends RouteComponentProps<{ id: string }> {
    className?: string;
    scaleType: ScaleType;
    dimension: DimensionStructure;
    assessmentType: AssessmentType;
    maxScore: number;
    minScore?: number;
    scores?: IPersonScore[];
    showLabels?: boolean;
    showDescription?: boolean;
    showFitIndicators?: boolean;
    selected?: boolean;
    onChange?: (event: React.SyntheticEvent<HTMLDivElement>) => void;
    distributionMembers?: AnalyzedCandidate[];
    distributionEntries?: IDistributionEntry[];
    distributionAverage?: number;
    showDistribution?: boolean;
    distributionViewMode?: DistributionViewMode;
    expandedDimension?: string;
    onExpandedDimensionChange?: (dimensionName: string) => void;
    interpretationData?: IInterpretationData;
    showDenominator?: boolean;
    displayCompact?: boolean;
    hideBGRangeWhenFullWidth?: boolean; // Hide a range when it takes 100% of the scale because it might be meaningless.
    printMode?: PrintModeEnum;
    onViewCandidateDetailClick?: (candidateProId: string) => void;
    separators?: Array<number>; // Array of scores where you want to put vertical separators
    teamStore?: TeamStore;
    cognition?: boolean;
    highlightedColor?: string;
    bannerImageUrl?: string;
    useGreenBackgroundColor?: boolean;
    reportType?: ReportType;
    forceShowScoreCount?: boolean;
    forceToHideHighLabel?: boolean;
}

@inject(GlobalStores.teamStore)
@observer
class BaseAtScaleImpl extends BaseResponsiveComponent<IBaseAtScaleProps> {
    private DEFAULT_EMPTY_SCORE: string = "-";
    private resultsCalculationService: IAssessmentResultsCalculationService;

    public static defaultProps = {
        minScore: 1, // Not used a lot for the moment so we at least, set it to 1 which is the default everywhere.
        maxScore: ScaleMaxScore.Stanine,
        hideBGRangeWhenFullWidth: true,
        separators: [],
    };

    @observable public isPanelExpanded: boolean = false;

    @computed get showAdditionalInfoCollapseSection(): boolean {
        const { showDistribution, printMode, showDescription = true } = this.props;

        return (
            (showDistribution || showDescription || this.hasInterpretationData) && printMode !== PrintModeEnum.Summary
        );
    }

    @computed get hasInterpretationData(): boolean {
        const { interpretationData } = this.props;

        return !!(
            interpretationData?.interpretation ||
            interpretationData?.elements.any() ||
            interpretationData?.pros.any() ||
            interpretationData?.advices.any()
        );
    }

    @computed public get isPanelOpen(): boolean {
        const {
            expandedDimension,
            dimension: { enumName },
            printMode,
        } = this.props;

        if (printMode) {
            return printMode === PrintModeEnum.Detailed;
        }

        if (expandedDimension) {
            return expandedDimension === enumName;
        }

        return this.isPanelExpanded;
    }

    @computed get isInTeamView(): boolean {
        const { distributionMembers } = this.props;

        return distributionMembers !== undefined && distributionMembers.length > 0;
    }

    @computed get teamName(): string | undefined {
        const {
            teamStore,
            match: {
                params: { id },
            },
        } = this.props;

        const team = teamStore!.getTeamById(id);

        return team?.name;
    }

    constructor(props: IBaseAtScaleProps) {
        super(props);

        autorun(() => {
            this.resultsCalculationService = new AssessmentResultsCalculationService(
                this.props.dimension,
                this.props.assessmentType,
                this.props.maxScore,
                this.props.scaleType,
            );
        });
    }

    @computed get showMoreLinkText(): string | undefined {
        const { showDistribution, interpretationData } = this.props;

        let text: string | undefined = undefined;

        if (showDistribution) {
            text = this.isPanelOpen ? "closeDistribution" : "viewDistribution";
        }

        if (interpretationData) {
            text = this.isPanelOpen ? "showLessDetails" : "showMoreDetails";
        }

        return text?.localize();
    }

    @action togglePanelExpansion = () => {
        const {
            onExpandedDimensionChange,
            dimension: { enumName },
        } = this.props;

        if (onExpandedDimensionChange) {
            onExpandedDimensionChange(enumName);
            return;
        }

        this.isPanelExpanded = !this.isPanelExpanded;
    };

    renderInlineScore() {
        const { scores, showDenominator = false, maxScore } = this.props;

        let inlineScore: string;

        if (scores && scores[0]?.score) {
            inlineScore = scores[0].score.toString();
        } else {
            inlineScore = this.DEFAULT_EMPTY_SCORE;
        }

        return (
            <Col className={"col-2 inline-score"}>
                {inlineScore}
                {showDenominator && <span className="denominator">/{maxScore}</span>}
            </Col>
        );
    }

    renderTargetIcons() {
        const { dimension } = this.props;

        if (!dimension.weightLabel) {
            return;
        }

        const MAX_TARGETS: number = 3;
        const weightLength: number = dimension.weightLabel.length;
        const icons: JSX.Element[] = [];

        for (let i: number = 0; i < MAX_TARGETS; i++) {
            if (i < weightLength) {
                icons.push(<FontAwesomeIcon key={i} icon={["fal", "bullseye"]} size="xs" color={Colors.greyBase} />);
            } else {
                icons.push(
                    <FontAwesomeIcon key={i} icon={["fal", "bullseye"]} size="xs" color={Colors.greyShades.shade9} />,
                );
            }
        }

        const containerId: string = `importance-level-${dimension.enumName}`;
        return (
            <>
                <div id={containerId} className="target-icons">
                    {icons}
                </div>
                <AtTooltip target={containerId} displayMode={"dark"} size="xs">
                    {"psychometrics.importanceLevel.description".localize()}
                </AtTooltip>
            </>
        );
    }

    handleCandidateClick(candidateProId: string) {
        if (this.props.onViewCandidateDetailClick) {
            this.props.onViewCandidateDetailClick(candidateProId);
        }
    }

    renderAdditionalInfoPanel() {
        const {
            maxScore,
            distributionMembers,
            distributionEntries,
            showDistribution,
            interpretationData,
            dimension,
            scores,
            printMode,
            showDescription = true,
            onViewCandidateDetailClick,
            distributionAverage,
            separators,
            scaleType,
            highlightedColor,
            bannerImageUrl,
            reportType,
        } = this.props;

        if (
            !this.isPanelOpen ||
            (!showDistribution && !showDescription && !interpretationData && !dimension.atCompatibilityFitData)
        ) {
            return null;
        }

        const candidateClickable = !!onViewCandidateDetailClick;

        if (showDistribution) {
            const scoreElementsArray: React.ReactNode[] = [];

            for (let i = 1; i <= maxScore; i++) {
                const scorePositionInfo = this.resultsCalculationService.scorePositionInfo[i];
                const matchingDistributionEntry = distributionEntries?.find((x) => x.scoreKey === i);
                const matchingDistributionMembers = distributionMembers?.filter((x) =>
                    matchingDistributionEntry?.candidateProIds.includes(x.candidateProId),
                );

                scoreElementsArray.push(
                    <div
                        key={`${i}-group`}
                        className={"distribution-people-group"}
                        style={{ left: `${scorePositionInfo.left}%`, width: `calc(${scorePositionInfo.width}% - 4px)` }}
                    >
                        {matchingDistributionMembers?.map((x) => (
                            <div className="atPersonCardWrapper" key={x.assessmentId}>
                                <AtPersonCard
                                    key={x.displayName}
                                    firstName={x.firstName}
                                    lastName={x.lastName}
                                    email={x.email}
                                    pictureUri={x.profilePictureUri}
                                    id={`avatar-${x.assessmentId}`}
                                    isHighlighted={matchingDistributionEntry?.isRange}
                                    isInteractive={candidateClickable}
                                    onClick={() => this.handleCandidateClick(x.candidateProId)}
                                />
                            </div>
                        ))}
                    </div>,
                );
            }

            const navigationItem = (
                <AtSubScaleNavigationItem key={"psychometrics.distribution"} icon={["far", "fist-raised"]} selected>
                    {"psychometrics.distribution".localize()}
                </AtSubScaleNavigationItem>
            );

            const isInFitReport =
                reportType === ReportType.JobFit ||
                reportType === ReportType.CultureFit ||
                reportType === ReportType.PotentialFit;

            return (
                <AtSubScaleNavigation navigationItems={[navigationItem]} reportType={reportType}>
                    <>
                        {this.isInTeamView &&
                            printMode === PrintModeEnum.Detailed &&
                            (reportType === ReportType.TeamDistribution ||
                                reportType === ReportType.Competencies ||
                                isInFitReport) && (
                                <AtScaleLegend
                                    separators={STANINE_SCALE_SEPARATORS}
                                    maxScore={ScaleMaxScore.Stanine}
                                    scaleType={ScaleType.Bipolar}
                                    isInTeamView={this.isInTeamView}
                                    showScaleValues
                                />
                            )}
                        <ScoreLineContainer
                            maxScore={maxScore}
                            scaleType={scaleType}
                            distributionAverage={distributionAverage}
                            distributionEntries={distributionEntries}
                            distributionViewMode={"numbers"}
                            isInverted={dimension.isInverted}
                            scores={scores}
                            separators={separators}
                            showDistribution={showDistribution}
                            teamName={this.teamName}
                        />
                        <div className="distribution-container">{scoreElementsArray}</div>
                    </>
                </AtSubScaleNavigation>
            );
        }

        if (showDescription && dimension.description) {
            return (
                <AtContainer className="description" padding={printMode !== undefined ? undefined : Sizing.paddings.lg}>
                    {printMode ? null : <AtTitle title={t({ id: "psychometrics.definition" })} />}

                    {dimension.description.split(/\\n|<br\/><br\/>|<br>|<br\/>/).map((x) => (
                        <React.Fragment key={x}>
                            <AtParagraph>{x}</AtParagraph>
                            <br />
                        </React.Fragment>
                    ))}
                </AtContainer>
            );
        }

        if (dimension.atCompatibilityFitData) {
            return (
                <AtCompatibilityFitDataView
                    data={dimension.atCompatibilityFitData}
                    scores={scores}
                    showAllTabs={printMode === PrintModeEnum.Detailed}
                    status={<FitScoreContainerAndLegend fitScore={dimension.fitScore!} isInDetailed />}
                />
            );
        }

        if (this.hasInterpretationData) {
            return (
                <InterpretationDataView
                    data={interpretationData!}
                    showAllTabs={printMode === PrintModeEnum.Detailed}
                    printMode={printMode === PrintModeEnum.Detailed || printMode === PrintModeEnum.Summary}
                    highlightedColor={highlightedColor}
                    assessmentType={this.props.assessmentType}
                    bannerImageUrl={bannerImageUrl}
                />
            );
        }

        return null;
    }

    render() {
        const {
            className,
            dimension,
            printMode,
            scaleType,
            assessmentType,
            minScore,
            maxScore,
            showLabels = true,
            scores,
            hideBGRangeWhenFullWidth,
            displayCompact = false,
            distributionAverage,
            distributionEntries,
            separators,
            distributionViewMode,
            showDistribution,
            cognition,
            useGreenBackgroundColor = false,
            forceShowScoreCount = false,
            forceToHideHighLabel = false,
        } = this.props;

        let additionalClassNames = "";

        switch (scaleType) {
            case ScaleType.Unipolar:
                additionalClassNames = "AtUnipolarScale";
                break;
            case ScaleType.Bipolar:
                additionalClassNames = "AtBipolarScale";
                break;
        }

        if (printMode === PrintModeEnum.Detailed) {
            additionalClassNames += " detailed";
        }

        // Generate the range background if applicable.
        let rangePositionInfo: Nullable<IRangePositionInfo> | undefined;
        if (dimension.range != undefined) {
            const isFullRange = dimension.range.min === minScore && dimension.range.max === maxScore;
            if (!hideBGRangeWhenFullWidth || !isFullRange) {
                rangePositionInfo = this.resultsCalculationService.rangePositionInfo;
            }
        }

        const hasHighLabel = scaleType === ScaleType.Bipolar && dimension.highLabel && !forceToHideHighLabel;

        return (
            <div className={cn("BaseAtScale", className, additionalClassNames, { cognition: cognition })}>
                {showLabels && (
                    <Row className="above-labels d-md-none">
                        <Col className={"left-align-label dimension-label-col"}>
                            <AtTitle
                                headingType={4}
                                title={
                                    assessmentType === AssessmentType.ProTripleBottomLineTest
                                        ? dimension.highLabel
                                        : dimension.lowLabel
                                }
                            />
                        </Col>
                        {hasHighLabel && (
                            <Col className={"right-align-label dimension-label-col"}>
                                <AtTitle headingType={4} title={dimension.highLabel} />
                            </Col>
                        )}
                    </Row>
                )}
                <Col sm={12} className={`results-col`} onClick={this.togglePanelExpansion}>
                    <Row className={`scale-container ${displayCompact ? "compact" : ""}`}>
                        {!this.isNullOrUndefined(dimension.fitScore) &&
                            !this.isInTeamView &&
                            printMode === undefined && <FitScoreContainer fitScore={dimension.fitScore!} />}

                        {!this.isNullOrUndefined(dimension.fitScore) &&
                            !this.isInTeamView &&
                            printMode === PrintModeEnum.Summary && (
                                <FitScoreContainerAndLegend fitScore={dimension.fitScore!} />
                            )}

                        {showLabels && (
                            <div
                                className={`BaseAtScaleLabel d-none d-md-flex dimension-label-col ${
                                    hasHighLabel
                                        ? "bipolar-label-col right-align-label"
                                        : "unipolar-label-col left-align-label"
                                }`}
                            >
                                {printMode !== undefined ? (
                                    <AtText size="lg">
                                        {assessmentType === AssessmentType.ProTripleBottomLineTest
                                            ? dimension.highLabel
                                            : dimension.lowLabel}
                                    </AtText>
                                ) : (
                                    <AtTitle
                                        headingType={4}
                                        title={
                                            assessmentType === AssessmentType.ProTripleBottomLineTest
                                                ? dimension.highLabel
                                                : dimension.lowLabel
                                        }
                                    />
                                )}

                                {this.renderTargetIcons()}
                            </div>
                        )}
                        <Col className={"baseAtScale-main-col"}>
                            <Row className={"sub-scale-container"}>
                                {!hasHighLabel && !this.isInTeamView && this.renderInlineScore()}
                                <ScoreLineContainer
                                    maxScore={maxScore}
                                    scaleType={scaleType}
                                    distributionAverage={distributionAverage}
                                    distributionEntries={distributionEntries}
                                    distributionViewMode={distributionViewMode}
                                    isInverted={dimension.isInverted}
                                    rangePositionInfo={rangePositionInfo}
                                    scores={scores}
                                    separators={separators}
                                    showDistribution={showDistribution}
                                    inCol={true}
                                    teamName={this.teamName}
                                    printMode={printMode}
                                    useGreenBackgroundColor={useGreenBackgroundColor}
                                    forceShowScoreCount={forceShowScoreCount}
                                />
                            </Row>
                        </Col>
                        {showLabels && hasHighLabel && (
                            <div className={"bipolar-label-col d-none d-md-flex dimension-label-col left-align-label"}>
                                {printMode !== undefined ? ( //TODO: @ma Maybe we can only use AtText here? Because text md and h4 have the same style rules.
                                    <AtText size="lg">{dimension.highLabel}</AtText>
                                ) : (
                                    <AtTitle headingType={4} title={dimension.highLabel} />
                                )}
                            </div>
                        )}
                    </Row>
                </Col>
                {!cognition && this.showAdditionalInfoCollapseSection && (
                    <>
                        {printMode === PrintModeEnum.Detailed ? (
                            <>{this.renderAdditionalInfoPanel()}</>
                        ) : (
                            <Col sm={12} className="expanded-additional-info-col">
                                <Collapse isOpen={this.isPanelOpen} className={"collapsed-additional-info-panel"}>
                                    <div className={"collapsed-additional-info-panel-content"}>
                                        {this.renderAdditionalInfoPanel()}
                                    </div>
                                </Collapse>
                            </Col>
                        )}
                    </>
                )}
            </div>
        );
    }
}

const BaseAtScale = withRouter(BaseAtScaleImpl);

export { BaseAtScale };
