import "./index.less";
import * as React from "react";
import { CustomInput } from "reactstrap";

export interface IAtSwitchProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    label?: string;
    id?: string;
    disabled?: boolean;
}

export const AtSwitch: React.FC<IAtSwitchProps> = (props) => {
    const { checked, disabled, onChange, label, id, ...otherProps } = props;

    return (
        <CustomInput
            className="AtSwitch"
            type="switch"
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            label={label}
            id={id}
            {...otherProps}
        />
    );
};
