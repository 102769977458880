import { ActionMeta, GroupBase, OnChangeValue, Options, PropsValue, SelectInstance } from "react-select";
import { AtSelect } from "../AtSelect";
import { FormatOptionLabelMeta } from "react-select/base";
import { ISelectBaseSharedProps, ISelectSharedProps, InnerRefType, PrimitiveValueType } from "../../../types";
import { toJS } from "mobx";
import React, { useCallback, useMemo } from "react";
import cn from "classnames";

export interface IAtSimpleSelectProps<
    TOption extends PrimitiveValueType,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
    TValueObject extends { value: TOption } = { value: TOption },
    TValueObjectGroup extends GroupBase<TValueObject> = GroupBase<TValueObject>,
> extends Omit<
            ISelectBaseSharedProps<TOption, IsMulti, TGroup>,
            "components" | "additionalStyles" | "ariaLiveMessages" | "filterOption"
        >,
        ISelectSharedProps<TOption, IsMulti, TGroup> {
    options: ReadonlyArray<TOption | TGroup>;

    innerRef?: InnerRefType<SelectInstance<TValueObject, IsMulti, TValueObjectGroup>>;
}

/**
 * AtSimpleSelect - Select Component
 * Category: Molecule
 *
 * @param props {IAtSimpleSelectProps}
 */
export const AtSimpleSelect = <
    TOption extends PrimitiveValueType,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(
    props: React.PropsWithChildren<IAtSimpleSelectProps<TOption, IsMulti, TGroup>>,
): JSX.Element => {
    const {
        options: _options,
        value: _value,
        defaultValue: _defaultValue,
        onChange: _onChange,
        formatOptionLabel: _formatOptionLabel,
        formatGroupLabel: _formatGroupLabel,
        getOptionValue: _getOptionValue,
        getOptionLabel: _getOptionLabel,
        isOptionSelected: _isOptionSelected,
        isOptionDisabled: _isOptionDisabled,
        className,
        ...otherProps
    } = props;

    const options = useMemo(
        () =>
            _options.map((x) => {
                if (x === null || typeof x !== "object") {
                    return {
                        value: x,
                    } as { value: TOption };
                }

                return {
                    label: x.label,
                    options: x.options.map((o) => ({ value: o })) as unknown as ReadonlyArray<{ value: TOption }>,
                };
            }) as ReadonlyArray<{ value: TOption } | GroupBase<{ value: TOption }>>,
        [_options],
    );

    const value = useMemo(() => convertToValueObject(_value), [_value]);

    const defaultValue = useMemo(
        () => (_defaultValue ? convertToValueObject(_defaultValue) : undefined),
        [_defaultValue],
    );

    const onChange = useCallback(
        (v: OnChangeValue<{ value: TOption }, IsMulti>, action: ActionMeta<{ value: TOption }>) => {
            _onChange(convertFromValueObjectToOnChangeValue<TOption, IsMulti>(v), action as ActionMeta<TOption>);
        },
        [_onChange],
    );

    const formatOptionLabel = useMemo(() => {
        if (!_formatOptionLabel) {
            return undefined;
        }

        return (o: { value: TOption }, labelMeta: FormatOptionLabelMeta<{ value: TOption }>) => {
            return _formatOptionLabel(
                convertFromValueObjectToValue(o),
                labelMeta as unknown as FormatOptionLabelMeta<TOption>,
            );
        };
    }, [_formatOptionLabel]);

    const formatGroupLabel = useMemo(() => {
        if (!_formatGroupLabel) {
            return undefined;
        }

        return (group: GroupBase<{ value: TOption }>) => {
            return _formatGroupLabel({
                label: group.label,
                options: group.options.map((x) => x.value) as readonly TOption[],
            } as TGroup);
        };
    }, [_formatGroupLabel]);

    const getOptionValue = useMemo(() => {
        if (!_getOptionValue) {
            return undefined;
        }

        return (o: { value: TOption }) => {
            return _getOptionValue(o.value);
        };
    }, [_getOptionValue]);

    const getOptionLabel = useMemo(() => {
        if (!_getOptionLabel) {
            return undefined;
        }

        return (o: { value: TOption }) => {
            return _getOptionLabel(o.value);
        };
    }, [_getOptionLabel]);

    const isOptionSelected = useMemo(() => {
        if (!_isOptionSelected) {
            return undefined;
        }

        return (o: { value: TOption }, selectValue: Options<{ value: TOption }>) => {
            return _isOptionSelected(o.value, selectValue.map((x) => x.value) as readonly TOption[]);
        };
    }, [_isOptionSelected]);

    const isOptionDisabled = useMemo(() => {
        if (!_isOptionDisabled) {
            return undefined;
        }

        return (o: { value: TOption }, selectValue: Options<{ value: TOption }>) => {
            return _isOptionDisabled(o.value, selectValue.map((x) => x.value) as readonly TOption[]);
        };
    }, [_isOptionDisabled]);

    return (
        <AtSelect<{ value: TOption }, IsMulti>
            className={cn("AtSimpleSelect", className)}
            options={options}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            formatOptionLabel={formatOptionLabel}
            formatGroupLabel={formatGroupLabel}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            isOptionSelected={isOptionSelected}
            isOptionDisabled={isOptionDisabled}
            {...otherProps}
        />
    );
};

const convertToValueObject = <
    TOption extends PrimitiveValueType,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
    TValueObject extends { value: TOption } = { value: TOption },
>(
    value: PropsValue<TOption>,
): PropsValue<TValueObject> => {
    if (value === null || value === undefined) {
        return null;
    }

    if (!Array.isArray(toJS(value))) {
        return {
            value: value,
        } as TValueObject;
    }

    return (value as TOption[]).map((x) => ({ value: x })) as TValueObject[];
};

const convertFromValueObjectToOnChangeValue = <TOption extends PrimitiveValueType, IsMulti extends boolean = false>(
    valueObject: OnChangeValue<{ value: TOption }, IsMulti>,
): OnChangeValue<TOption, IsMulti> => {
    if (valueObject === null || valueObject === undefined) {
        return null as OnChangeValue<TOption, IsMulti>;
    }

    if (!Array.isArray(toJS(valueObject))) {
        return (valueObject as { value: TOption }).value as OnChangeValue<TOption, IsMulti>;
    }

    return (valueObject as ReadonlyArray<{ value: TOption }>).map((x) => x.value) as unknown as OnChangeValue<
        TOption,
        IsMulti
    >;
};

const convertFromValueObjectToValue = <
    TOption extends PrimitiveValueType,
    IsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
    TValueObject extends { value: TOption } = { value: TOption },
>(
    valueObject: TValueObject,
): TOption => {
    return valueObject?.value as TOption;
};
