import "./index.less";
import { AtButton } from "../../AtButton";
import { AtInput } from "../../AtInput";
import { AtTableBase, DisplayType, IAtTableBaseProps } from "../../../atoms/AtTableBase";
import { AtTableFilterTypes } from "..";
import { AvailableFilterTypes, filtersApplication } from "./filtering/applyFilters";
import { IAppliedFilter, TableFilteringModal } from "./filtering";
import { IBaseEntity, useDebounce } from "@atman/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { MatchType, MatchTypeOptionsEnum } from "./filtering/AtTableFreeTextFilter";
import { getTableColumnsValueTypes } from "../../../atoms/AtTableBase/itemKeyTypes";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";

export type AtTableFilterForExternal<T> = {
    fieldName: keyof T;
    value: AvailableFilterTypes;
    type: AtTableFilterTypes;
    isFromSearchBar: boolean;
    matchType?: MatchType;
};

export type CustomHandleFilter<T> = (filters: AtTableFilterForExternal<T>[] | undefined) => void;

export interface IAtTableProps<T extends IBaseEntity> extends Omit<IAtTableBaseProps<T>, "itemKeyTypes"> {
    defaultAppliedFilters?: IAppliedFilter<T>[] | undefined;
    defaultSearchColumnKey?: string;
    customHandleFilter?: CustomHandleFilter<T>;
    showFilters?: boolean;
    refreshTable?: () => Promise<void>;
    hideCheckboxHeader?: boolean;
}

function AtTableRaw<T extends IBaseEntity>(props: IAtTableProps<T>) {
    const {
        items,
        columns,
        defaultSearchColumnKey: _defaultSearchColumnKey,
        isLoading,
        customHandleSort,
        customHandleFilter,
        showFilters = true,
        enableCardDisplay = false,
        refreshTable,
        showSearchBar = true,
        defaultAppliedFilters,
        disabledCheckboxTooltipText,
        hideCheckboxHeader,
        ...otherProps
    } = props;

    const { displayType } = otherProps;

    const [originalItems, setOriginalItems] = useState<T[]>([...items]);
    const [originalItemsHaveBeenLoaded, setOriginalItemsHaveBeenLoaded] = useState<boolean>(false);
    const isOriginalItemsEmpty = originalItems.length === 0;

    useEffect(() => {
        if (items.length > 0 && !originalItemsHaveBeenLoaded) {
            setOriginalItemsHaveBeenLoaded(true);
            setOriginalItems([...items]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    const itemKeyTypes = useMemo(() => getTableColumnsValueTypes(columns, originalItems), [columns, originalItems]);

    const searchableColumnsForSelect = useMemo(
        () =>
            columns.filter((col) => {
                if (col.filter !== undefined) {
                    const colType = itemKeyTypes[`${col.key}`];
                    return colType === "string" || colType === "number";
                }
                return;
            }),
        [columns, itemKeyTypes],
    );

    const defaultSearchColumnKey = useMemo(() => {
        if (_defaultSearchColumnKey) {
            return _defaultSearchColumnKey;
        }

        if (searchableColumnsForSelect.length > 0) {
            return searchableColumnsForSelect[0].key;
        }

        return;
    }, [_defaultSearchColumnKey, searchableColumnsForSelect]);

    const [finalDisplayItems, setFinalDisplayItems] = useState<T[]>(items);
    const [searchField, setSearchField] = useState<string | undefined>(defaultSearchColumnKey);

    const [appliedFilters, setAppliedFilters] = useState<IAppliedFilter<T>[]>(defaultAppliedFilters ?? []);
    const [isModalHidden, setIsModalHidden] = useState<boolean>(true);
    const [isCardViewActive, setIsCardViewActive] = useState<boolean>(false);
    const [isTableViewActive, setIsTableViewActive] = useState<boolean>(true);
    const [displayTypeValue, setDisplayTypeValue] = useState<DisplayType>(displayType ?? "table");

    const debouncedAppliedFilters: IAppliedFilter<T>[] = useDebounce<IAppliedFilter<T>[]>(appliedFilters, 500);

    const searchValue = useMemo(
        () => appliedFilters.find((f) => f.key === searchField)?.value || undefined,
        [appliedFilters, searchField],
    );

    const filteredItems = useMemo(
        () =>
            items.filter((oneItem) => {
                const matchedFilters = appliedFilters
                    .map((appliedFilter) => {
                        const column = columns.find((col) => col.key === appliedFilter.key);

                        if (column) {
                            const { onFilter } = column;

                            // Custom filter logic
                            if (onFilter) {
                                return onFilter(appliedFilter.value, oneItem, appliedFilter.matchType);
                            }

                            // Default filter logic, based on fieldName type
                            return filtersApplication(
                                column.fieldName,
                                appliedFilter.value,
                                oneItem,
                                column.filter?.type,
                                appliedFilter.matchType,
                            );
                        }

                        return null;
                    })
                    .filter((doesFit) => doesFit).length;

                if (matchedFilters === appliedFilters.length) {
                    return true;
                }

                return false;
            }),
        [appliedFilters, columns, items],
    );

    const applySearch = (newSearchValue: string | undefined) => {
        const alreadySetSearchInFilters = searchValue;
        // Empty search
        if (!newSearchValue) {
            handleSearchClear();
            return;
        }

        // Missing data or same search than before
        if (!searchField || alreadySetSearchInFilters === newSearchValue) {
            return;
        }

        setAppliedFilters((previousState): IAppliedFilter<T>[] => {
            const previousSearchHasChanged = previousState.some((f) => f.key === searchField);
            const previousFilters = previousState.map((f): IAppliedFilter<T> => {
                // Change the value of the already set search filter
                if (f.key === searchField) {
                    return {
                        key: searchField,
                        value: newSearchValue,
                        matchType: MatchTypeOptionsEnum.Contains,
                    };
                }

                return f;
            });

            if (previousSearchHasChanged) {
                return previousFilters;
            } else {
                // Adding new search value and keep the previous filters
                return [
                    ...previousFilters,
                    {
                        key: searchField,
                        value: newSearchValue,
                        matchType: MatchTypeOptionsEnum.Contains,
                    },
                ];
            }
        });
    };

    const rightIcon: IconProp | undefined = useMemo(() => {
        if (searchValue) {
            return ["fas", "times-circle"];
        }
        return;
    }, [searchValue]);

    const toggleFiltersModal = () => setIsModalHidden(!isModalHidden);

    const clearFilters = () => {
        setAppliedFilters([]);
        setIsModalHidden(true);
    };

    const refreshData = async () => {
        if (refreshTable) {
            await refreshTable();
            clearFilters();
        }
    };

    const searchLabel = useMemo(() => columns.find((col) => col.key === searchField)?.label, [columns, searchField]);

    const handleSearchChange = (e) => {
        applySearch(e.target.value);
    };

    const handleSearchClear = () => {
        setAppliedFilters((previousState): IAppliedFilter<T>[] => {
            const res = previousState
                .map((f): IAppliedFilter<T> | undefined => {
                    if (f.key !== searchField) {
                        return f;
                    }

                    return undefined;
                })
                .filter((filter) => filter !== undefined) as IAppliedFilter<T>[];

            return res;
        });
    };

    const handleTableView = () => {
        setDisplayTypeValue("table");
        setIsCardViewActive(false);
        setIsTableViewActive(true);
    };

    const handleCardsView = () => {
        setDisplayTypeValue("cards");
        setIsTableViewActive(false);
        setIsCardViewActive(true);
    };

    useEffect(() => {
        setSearchField(defaultSearchColumnKey);
    }, [defaultSearchColumnKey]);

    /**
     * Apply the filtering to the item lists.
     */
    useEffect(() => {
        if (customHandleFilter) {
            const matchedFilters: (AtTableFilterForExternal<T> | undefined)[] = debouncedAppliedFilters.map(
                (appliedFilter) => {
                    const column = columns.find((col) => col.key === appliedFilter.key);

                    if (column && column.filter) {
                        return {
                            fieldName: column.fieldName,
                            value: appliedFilter.value,
                            type: column.filter.type,
                            isFromSearchBar: false,
                            matchType: appliedFilter.matchType,
                        };
                    }

                    return undefined;
                },
            );

            customHandleFilter(
                matchedFilters.filter((filter) => filter !== undefined) as AtTableFilterForExternal<T>[],
            );
        } else {
            setFinalDisplayItems(filteredItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedAppliedFilters]);

    /**
     * Apply the filtering to the item lists. Based on the results output by the main search
     */
    useEffect(() => {
        if (appliedFilters && appliedFilters.length > 0 && !customHandleFilter) {
            setFinalDisplayItems(filteredItems);
        } else {
            setFinalDisplayItems(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    return (
        <>
            {showFilters && (
                <TableFilteringModal
                    appliedFilters={appliedFilters}
                    itemKeyTypes={itemKeyTypes}
                    columns={columns}
                    isModalHidden={isModalHidden}
                    clearFilters={clearFilters}
                    setAppliedFilters={setAppliedFilters}
                    setIsModalHidden={setIsModalHidden}
                />
            )}

            <div className="AtTable">
                {showFilters && (
                    <div className={cn("topBar", `${showSearchBar === false ? "doNotShowSearchBar" : ""}`)}>
                        {showSearchBar && (
                            <AtInput
                                className="search"
                                leftIcon="search"
                                fieldName="tableMainSearch"
                                placeholder={`${t({
                                    id: "global.search",
                                })} ${searchLabel}`}
                                value={(searchValue as string) || ""}
                                onChange={handleSearchChange}
                                disabled={isLoading || searchableColumnsForSelect.length <= 0}
                                rightIcon={rightIcon}
                                onRightIconClick={handleSearchClear}
                            />
                        )}

                        <AtButton
                            icon={["far", "filter"]}
                            iconProps={{ highlightIcon: appliedFilters.length > 0 }}
                            color="secondary"
                            onClick={toggleFiltersModal}
                            disabled={isLoading}
                        >
                            {t({ id: "global.filters", message: "Filters" })}
                        </AtButton>
                        {refreshTable && (
                            <AtButton
                                icon={["far", "sync"]}
                                color="secondary"
                                onClick={refreshData}
                                isLoading={isLoading}
                            />
                        )}

                        {enableCardDisplay && (
                            <>
                                <AtButton
                                    className={cn("tableViewButton", isCardViewActive ? "active" : "")}
                                    icon={["far", "th-large"]}
                                    iconProps={{ color: "#303030" }}
                                    color="secondary"
                                    onClick={handleCardsView}
                                />
                                <AtButton
                                    icon={["far", "th-list"]}
                                    color="secondary"
                                    iconProps={{ color: "#303030" }}
                                    className={cn("tableViewButton", isTableViewActive ? "active" : "")}
                                    onClick={handleTableView}
                                    disabled={isLoading}
                                />
                            </>
                        )}
                    </div>
                )}

                <AtTableBase<T>
                    customHandleSort={customHandleSort}
                    items={finalDisplayItems}
                    columns={columns}
                    displayType={displayTypeValue}
                    itemKeyTypes={itemKeyTypes}
                    isLoading={isLoading}
                    isTableEmpty={isOriginalItemsEmpty}
                    disabledCheckboxTooltipText={disabledCheckboxTooltipText}
                    {...otherProps}
                    className="AtContainer"
                    hideCheckboxHeader={hideCheckboxHeader}
                />
            </div>
        </>
    );
}

export const AtTable = observer(AtTableRaw);
