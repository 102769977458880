import { CacheItemOptions } from "@aws-amplify/cache/src/types/Cache";
import Cache from "@aws-amplify/cache";

export interface ITypedCacheItemOptions<TValue> extends CacheItemOptions {
    callback?: () => TValue;
}

export class CacheProvider {
    public static CACHE_KEYS = {
        /* @deprecated */
        ASSESSMENT_DOCUMENTS_OLD: "KpiAssessmentDocumentCache",
        ASSESSMENT_DOCUMENTS_OLD_V2: "KpiAssessmentDocumentCache_v2",
        ASSESSMENT_DOCUMENTS_OLD_v3: "KpiAssessmentDocumentCache_v3",
        ASSESSMENT_DOCUMENTS: "KpiAssessmentDocumentCache_v4",
    };

    public static setItem<TValue>(key: string, value: TValue, options?: ITypedCacheItemOptions<TValue>): void {
        return Cache.setItem(key, value, options);
    }

    /** Get item from cache */
    public static getItem<TValue>(key: string, options?: ITypedCacheItemOptions<TValue>): TValue | undefined {
        return Cache.getItem(key, options);
    }

    /** Remove item from cache */
    public static removeItem(key: string): void {
        return Cache.removeItem(key);
    }

    /** Remove all items from cache */
    public static clear(): void {
        return Cache.clear();
    }

    /** Get all keys form cache */
    public static getAllKeys(): string[] | Promise<string[]> {
        return Cache.getAllKeys();
    }

    /** Get current size of the cache */
    public static getCacheCurSize(): number | Promise<number> {
        return Cache.getCacheCurSize();
    }
}
