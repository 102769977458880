import { BaseApi } from "../base";
import { HttpMethodEnum } from "../types";
import { IWorkspaceInput, Workspace } from "../models";
import { ReactAppSettings } from "../libs";

export class WorkspacesApi extends BaseApi {
    static async getWorkspaces() {
        return await this.sendApiRequest<Workspace[]>(HttpMethodEnum.GET, ReactAppSettings.appUrls.getWorkspaces);
    }

    static async getWorkspace(id: string) {
        return await this.sendApiRequest<Workspace>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getWorkspace,
            undefined,
            id,
        );
    }

    static async createWorkspace(input: IWorkspaceInput) {
        return await this.sendApiRequestWithResult<IWorkspaceInput, Workspace>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.createWorkspace,
            input,
        );
    }

    static async updateWorkspace(id: string, input: IWorkspaceInput) {
        return await this.sendApiRequestWithResult<IWorkspaceInput, Workspace>(
            HttpMethodEnum.PUT,
            ReactAppSettings.appUrls.updateWorkspace,
            input,
            id,
        );
    }

    static async deleteWorkspace(id: string) {
        return await this.sendApiRequest(
            HttpMethodEnum.DELETE,
            ReactAppSettings.appUrls.deleteWorkspace,
            undefined,
            id,
        );
    }
}
