import "./index.less";
import * as React from "react";
import { AtSelect, IReactSelectOptionObject } from "@atman/design-system";
import { GlobalStores, LocalizationStore } from "@atman/business";
import { IConfigurablePrintOption, PrintReportParams, printReport } from "../../index";
import { ModalBody } from "reactstrap";
import { ModalButtons } from "../../../ModalButtons";
import { action, isArrayLike, observable, toJS } from "mobx";
import { findCultureFromSet } from "@atman/business/lib/stores/LocalizationStore";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";

export interface IPrintReportCustomizationModalBodyProps {
    reportNamePrefix?: string;
    getPrintReportParams: (params: Map<string, string | string[]>, languageCode: string) => PrintReportParams;
    params: Map<string, string>;
    configurationOptions: IConfigurablePrintOption[];
    toggleModal: () => void;
    availableReportLanguagesOverride?: string[];
    localizationStore?: LocalizationStore;
}

@inject(GlobalStores.localizationStore)
@observer
export class PrintReportCustomizationModalBody extends React.Component<IPrintReportCustomizationModalBodyProps> {
    @observable private reportLanguage: IReactSelectOptionObject;
    @observable private configurationOptionValues: Map<string, IReactSelectOptionObject | IReactSelectOptionObject[]>;

    constructor(props) {
        super(props);

        const isCurrentReportLocaleSupported =
            this.props.availableReportLanguagesOverride?.includes(
                this.props.localizationStore!.currentReportShortLocale,
            ) ?? true;
        const defaultPrintLocale = isCurrentReportLocaleSupported
            ? this.props.localizationStore!.currentReportLocale
            : findCultureFromSet(
                  this.props.localizationStore!.availableReportLanguages,
                  this.props.availableReportLanguagesOverride?.[0] ?? this.props.localizationStore!.currentShortLocale,
              ) ?? this.props.localizationStore!.currentLocale;

        this.reportLanguage = {
            value: defaultPrintLocale.shortName,
            label: defaultPrintLocale.shortDisplay,
        };
        this.configurationOptionValues = new Map<string, IReactSelectOptionObject | IReactSelectOptionObject[]>(
            this.props.configurationOptions.map((x) => {
                if (x.isMulti) {
                    return [x.key, x.options];
                }

                const firstEnabledOption: IReactSelectOptionObject<string> = x.options?.filter((o) => !o.isDisabled)[0];

                return [x.key, { value: firstEnabledOption?.value ?? "", label: firstEnabledOption?.label ?? "" }];
            }),
        );
    }

    @action private onLanguageChange = (option: IReactSelectOptionObject) => {
        this.reportLanguage = option;
    };

    @action private onConfigurationOptionValueChange = (option: any, key: string) => {
        this.configurationOptionValues.set(key, option);
    };

    private print = async () => {
        const { params, getPrintReportParams, reportNamePrefix, toggleModal, localizationStore, configurationOptions } =
            this.props;

        const printParams = new Map<string, string | string[]>(params);

        let reportName: string = reportNamePrefix ? `${reportNamePrefix}-` : "";

        for (const option of configurationOptions) {
            const optionValue = this.configurationOptionValues.get(option.key);

            const isArrayValue = isArrayLike(optionValue);
            const isOptionValueEmpty =
                !optionValue ||
                (isArrayValue
                    ? (optionValue as IReactSelectOptionObject[]).length === 0
                    : !(optionValue as IReactSelectOptionObject).value);

            if (isOptionValueEmpty) {
                continue;
            }

            if (isArrayLike(optionValue)) {
                printParams.set(
                    option.key,
                    optionValue.map((x) => x.value),
                );
            } else {
                printParams.set(option.key, optionValue!.value);
                reportName += optionValue!.label.replaceAll(" ", "_");
            }
        }

        const printReportParams = getPrintReportParams(printParams, this.reportLanguage.value);

        for (const option of configurationOptions.filter((x) => x.excludeFromQueryParams)) {
            if (printParams.has(option.key)) {
                printParams.delete(option.key);
            }
        }

        // TODO: Add custom label
        printReport(printParams, printReportParams, this.reportLanguage.value, reportName);

        localizationStore!.updateCurrentReportLocale(this.reportLanguage.value);
        toggleModal();
    };

    render() {
        const { configurationOptions, toggleModal, availableReportLanguagesOverride, localizationStore } = this.props;

        let languageOptions = localizationStore!.availableReportLanguages.map((x) => ({
            value: x.shortName,
            label: x.shortDisplay,
        }));

        if (availableReportLanguagesOverride) {
            languageOptions = languageOptions.filter((x) => availableReportLanguagesOverride.includes(x.value));
        }

        return (
            <ModalBody id="PrintReportCustomizationModalBody">
                <AtSelect
                    label={t({ id: "global.languageCode" })}
                    options={languageOptions}
                    value={this.reportLanguage}
                    onChange={this.onLanguageChange}
                />
                {configurationOptions.map((x, i) => (
                    <AtSelect
                        key={`configurable-option-${i}`}
                        label={x.label}
                        options={x.options}
                        value={toJS(this.configurationOptionValues.get(x.key)!)}
                        isDisabled={x.options.length <= 1}
                        onChange={(o) => this.onConfigurationOptionValueChange(o, x.key)}
                        isMulti={x.isMulti}
                        closeMenuOnSelect={!x.isMulti}
                    />
                ))}
                <ModalButtons
                    saveAction={this.print}
                    saveLabel={t({ id: "global.print" })}
                    customCancelAction={toggleModal}
                />
            </ModalBody>
        );
    }
}
