import "./CustomSidebarNavItem.less";
import * as React from "react";
import { AtTooltip } from "@atman/design-system";
import { NavLink as BootstrapNavLink, NavItem, NavItemProps } from "reactstrap";
import { Default, Mobile } from "../../BaseResponsiveComponent";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { NavLink, Route } from "react-router-dom";
import { UrlFormatter } from "@atman/business";

export interface ICustomSidebarNavItemProps extends NavItemProps {
    href?: string;
    iconName: IconName;
    label: string;
    uniqueKey: string;
    iconProps?: Partial<Omit<FontAwesomeIconProps, "icon">>;
}

export const CustomSidebarNavItem: React.FC<ICustomSidebarNavItemProps> = (props) => {
    const { href, iconName, label, uniqueKey, className, iconProps = {}, onClick, ...navItemProps } = props;

    let elementClassName = "CustomSidebarNavItem";

    if (className) {
        elementClassName += ` ${className}`;
    }

    const iconContainerId = `icon-container-${uniqueKey}`;
    const navItemContent = (active: boolean = false) => (
        <>
            <span className="icon-container" id={iconContainerId}>
                <FontAwesomeIcon icon={[active ? "fas" : "far", iconName]} {...iconProps} />
            </span>
            <Default>
                <AtTooltip
                    className="sidebar-tooltip"
                    placement="right"
                    delay={{ show: 100, hide: 0 }}
                    target={iconContainerId}
                >
                    {label}
                </AtTooltip>
            </Default>
            <Mobile>
                <div className="label">{label}</div>
            </Mobile>
        </>
    );

    if (!href) {
        return (
            <NavItem className={elementClassName} {...navItemProps}>
                <BootstrapNavLink onClick={onClick}>{navItemContent()}</BootstrapNavLink>
            </NavItem>
        );
    }

    const inAppHref = UrlFormatter.formatReactPath(href);

    return (
        <Route path={inAppHref}>
            {({ match }) => (
                <NavItem className={elementClassName} active={!!match} {...navItemProps}>
                    <NavLink to={inAppHref}>{navItemContent(!!match)}</NavLink>
                </NavItem>
            )}
        </Route>
    );
};
