import "./index.less";
import * as React from "react";
import { AtTitle } from "@atman/design-system";
import { Candidate, Logger, User } from "@atman/business";
import { CandidatePicture } from "../CandidatePicture";
import { inject, observer } from "mobx-react";

export interface IPersonQuickViewProps {
    candidate?: Candidate;
    user?: User;
}

@inject()
@observer
export class PersonQuickView extends React.Component<IPersonQuickViewProps, {}> {
    render() {
        const { candidate, user } = this.props;

        let profilePictureUri: string | undefined = "";
        let name: string = "";
        let email: string = "";

        if (candidate) {
            profilePictureUri = candidate.profilePictureUri;
            name = candidate.displayName;
            email = candidate.email;
        } else if (user) {
            name = `${user.firstName} ${user.lastName}`;
            email = user.email;
        } else {
            Logger.log("No candidate or User were provided to PersonQuickView Component.");
            return;
        }

        return (
            <div id="PersonQuickView">
                <CandidatePicture profilePictureUri={profilePictureUri} />
                <AtTitle headingType={2} title={name} />
                <div className="email">{email}</div>
            </div>
        );
    }
}
