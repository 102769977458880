import "./index.less";
import * as React from "react";
import { AtButton, AtCheckboxBase, AtLink, AtTitle, LoadingIndicator } from "@atman/design-system";
import { AuthApi, IAuthApi, ILoginResponse } from "../../../api/AuthApi";
import { AuthStore } from "../../../data/stores/AuthStore";
import { BaseStepComponent } from "@atman/ui-kit/lib/components/BaseStepComponent";
import { CookieProvider, Cookies, Validator, getEnumValues } from "@atman/business";
import { CustomValidatedTextInput } from "@atman/ui-kit";
import { EmailAccountComponent } from "../../../global/components/EmailAccountComponent";
import { Form, Label } from "reactstrap";
import { IUserSsoConfigurationModel } from "../../../data/models/UserSsoConfigurationModel";
import { LoginAppStores } from "../../../../index";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { SystemStateInfo } from "../../../data/models/SystemState";
import { SystemStateStore } from "../../../data/stores/SystemStateStore";
import { Trans, t } from "@lingui/macro";
import { UnavailableSystem } from "../../../global/components/UnavailableSystem/UnavailableSystem";
import { action, autorun, computed, observable, reaction, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import ProductSelector, { AtmanCoPlatformsEnum } from "../../../global/components/ProductSelector";
import qs from "qs";

export interface ILoginProps
    extends RouteComponentProps<
        {},
        StaticContext,
        {
            selectedPlatform?: AtmanCoPlatformsEnum;
            email?: string;
            skipSilentLogin?: boolean;
        }
    > {
    authStore?: AuthStore;
    systemStateStore?: SystemStateStore;
}

@inject<LoginAppStores, any, any, any>((stores) => ({
    authStore: stores.authStore,
    systemStateStore: stores.systemStateStore,
}))
@observer
export class LoginImpl extends BaseStepComponent<ILoginProps, {}> {
    private readonly _authApi: IAuthApi = new AuthApi();

    @observable selectedPlatform?: AtmanCoPlatformsEnum = AtmanCoPlatformsEnum.ProNA;
    @observable email: string = "";
    @observable password: string = "";
    @observable rememberMe: boolean = false;
    @observable userSsoConfiguration?: IUserSsoConfigurationModel;
    @observable loggedInUsername?: string;
    @observable isLoadingSaml: boolean = false;

    @observable systemStateInfo: { available: boolean | undefined; activeState?: SystemStateInfo } = {
        available: undefined,
    };

    private readonly currentStepDataMapping: Map<number, ILoginStepData> = new Map<number, ILoginStepData>([
        [
            0,
            {
                title: t({
                    id: "loginApp.loginForm.emailStep.title",
                    message: `Let's start with your login email`,
                }),
            },
        ],
        [
            1,
            {
                title: t({
                    id: "loginApp.loginForm.emailStep.title",
                    message: `Let's start with your login email`,
                }),
            },
        ],
        [
            2,
            {
                title: t({
                    id: "loginApp.loginForm.emailStep.title",
                    message: `Let's start with your login email`,
                }),
                action: {
                    label: t({
                        id: "loginApp.loginForm.emailStep.buttonLabel",
                        message: `Next`,
                    }),
                    event: (e) => this.checkEmailForSso(e),
                },
            },
        ],
        [
            3,
            {
                title: t({
                    id: "loginApp.loginForm.ssoStep.title",
                    message: `Enterprise Sign In`,
                }),
                actionsGenerator: () => this.generateSsoLoginActions(),
            },
        ],
        [
            4,
            {
                title: t({
                    id: "loginApp.loginForm.passwordStep.title",
                    message: `Now enter your password`,
                }),
                action: {
                    label: t({ id: "global.buttons.labels.logIn", message: `Log In` }),
                    event: (e) => this.loginWithPassword(e),
                },
            },
        ],
    ]);

    @computed private get _systemStateInfo(): { available: boolean | undefined; activeState?: SystemStateInfo } {
        const { systemStateStore } = this.props;

        if (this.selectedPlatform === undefined) {
            return { available: false };
        }

        return systemStateStore!.getStateForPlatform(this.selectedPlatform);
    }

    constructor(props) {
        super(props);

        autorun(() => {
            this.systemStateInfo = this._systemStateInfo;
        });
    }

    selectedPlatformCookieReactionDisposer = reaction(
        () => ({ platform: this.selectedPlatform, available: this.systemStateInfo.available }),
        () => {
            this.setPlatformCookie();

            if (!this.systemStateInfo.available) {
                return;
            }

            if (!this.props.systemStateStore!.hasLoadedSystemState) {
                return;
            }

            if (!this.props.location?.state?.skipSilentLogin) {
                this.goToStep(1);
                this.verifyRefreshToken();
            } else {
                this.goToStep(2);
                this.props.location.state.skipSilentLogin = false;
            }
        },
    );

    componentDidMount() {
        const {} = this.props;

        this.loadEmailFromLocationState();
        this.loadPlatformFromCookies();
        this.loadDataFromQueryParams();
    }

    componentWillUnmount() {
        this.selectedPlatformCookieReactionDisposer();
    }

    setPlatformCookie = () => {
        if (this.selectedPlatform === undefined || isNaN(this.selectedPlatform)) {
            return;
        }

        CookieProvider.set(Cookies.LOGIN_APP_PLATFORM, this.selectedPlatform);
    };

    @action loadPlatformFromCookies = () => {
        const platformCookie = CookieProvider.get<AtmanCoPlatformsEnum | undefined>(Cookies.LOGIN_APP_PLATFORM);

        const platformCookieValue = Number(platformCookie);

        if (
            !isNaN(platformCookieValue) &&
            getEnumValues<AtmanCoPlatformsEnum>(AtmanCoPlatformsEnum).includes(platformCookieValue)
        ) {
            this.selectedPlatform = platformCookieValue;
        }
    };

    @action setIsLoadingSaml = (isLoadingSaml: boolean) => {
        this.isLoadingSaml = isLoadingSaml;
    };

    @action loadDataFromQueryParams = () => {
        const {
            location: { search },
            authStore,
        } = this.props;

        const query = qs.parse(search, { ignoreQueryPrefix: true }) as {
            platform?: string;
            returnUrl?: string;
            clientId?: string;
            samlLoginResponse?: string;
        };

        authStore!.setClientId(query.clientId);
        authStore!.setReturnUrl(query.returnUrl);

        if (query.platform !== undefined) {
            if (
                Object.keys(AtmanCoPlatformsEnum)
                    .filter((x) => isNaN(Number(x)))
                    .includes(query.platform)
            ) {
                this.selectedPlatform = AtmanCoPlatformsEnum[query.platform];
            }
        }

        if (query.samlLoginResponse !== undefined) {
            const { authStore } = this.props;

            if (query.samlLoginResponse.startsWith("%")) {
                query.samlLoginResponse = decodeURIComponent(query.samlLoginResponse);
            }

            const samlLoginResponse = JSON.parse(query.samlLoginResponse) as ILoginResponse;

            this.setIsLoadingSaml(true);
            authStore?.loginFromSamlLoginResponse(samlLoginResponse, this.selectedPlatform);
        }
    };

    @action loadEmailFromLocationState = () => {
        const { location } = this.props;

        this.email = location?.state?.email ?? "";
    };

    public restartStepForm = () => {
        this.goToStep(0);
    };

    protected goToForgotPasswordPage = () => {
        const { history } = this.props;

        history.push("/Unauth/ForgotPassword", {
            email: this.email,
            selectedPlatform: this.selectedPlatform,
        });
    };

    protected validateEmail(): boolean {
        const {} = this.props;

        const fieldName = "email";

        this.clearErrorForField(fieldName);

        if (!this.email) {
            this.errors.push({
                name: "ValidationError",
                message: t({
                    id: "global.errors.email.empty",
                    message: `Email is empty`,
                }),
                fieldName,
            });

            return false;
        }

        if (!Validator.validateEmail(this.email)) {
            this.errors.push({
                name: "ValidationError",
                message: t({
                    id: "global.errors.email.invalid",
                    message: `Email is invalid`,
                }),
                fieldName,
            });

            return false;
        }

        return !this.getErrorsForField(fieldName).any();
    }

    protected validatePassword(): boolean {
        const {} = this.props;

        const fieldName = "password";

        this.clearErrorForField(fieldName);

        if (!this.password) {
            this.errors.push({
                name: "ValidationError",
                message: t({
                    id: "global.errors.password.empty",
                    message: `Password is empty`,
                }),
                fieldName,
            });
        }

        return !this.getErrorsForField(fieldName).any();
    }

    @action protected loginSilently = async () => {
        const { authStore } = this.props;

        try {
            this.setIsLoading(true);
            await authStore!.loginSilently({ platform: this.selectedPlatform });
        } catch (e) {
            console.log(e);

            this.goToStep(2);
            this.setIsLoading(false);
        }
    };

    @action protected verifyRefreshToken = async () => {
        const { authStore } = this.props;

        try {
            this.setIsLoading(true);

            const result = await authStore!.verifyRefreshToken({
                platform: this.selectedPlatform,
            });

            runInAction(() => {
                this.loggedInUsername = result.user;
                this.rememberMe = result.rememberMe;
            });

            this.setIsLoading(false);
        } catch (result) {
            runInAction(() => {
                this.rememberMe = result.rememberMe;
            });

            this.goToStep(2);
            this.setIsLoading(false);
        }
    };

    @action protected loginWithPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        const { authStore } = this.props;

        e.preventDefault();

        if (!this.validatePassword()) {
            return;
        }

        this.setIsLoading(true);

        try {
            await authStore!.login(this.email, this.password, this.rememberMe, {
                platform: this.selectedPlatform,
            });
        } catch (e) {
            const error = e?.errors?.firstOrDefault();

            this.errors.push({
                name: error?.code ?? "ValidationError",
                message:
                    error?.message ??
                    t({
                        id: "global.errors.credentials.invalid",
                        message: `Invalid Credentials`,
                    }),
                fieldName: "password",
            });

            this.setIsLoading(false);
        }
    };

    @action protected checkEmailForSso = async (e: React.FormEvent<HTMLFormElement>) => {
        const {} = this.props;

        e.preventDefault();

        if (!this.validateEmail()) {
            return;
        }

        this.setIsLoading(true);

        try {
            const result = await this._authApi.getUserSsoConfiguration(this.email, {
                platform: this.selectedPlatform,
            });
            this.setIsLoading(false);

            runInAction(() => {
                this.userSsoConfiguration = result;
            });

            if (this.userSsoConfiguration && this.userSsoConfiguration.userSsoModels.length > 0) {
                this.goToStep(3);
            } else {
                this.goToStep(4);
            }
        } catch (error) {
            this.setIsLoading(false);

            this.errors.push({
                name: error?.code ?? "ServerError",
                message:
                    error?.message ??
                    t({
                        id: "global.errors.server.unknown",
                        message: `Unknown Server Error`,
                    }),
                fieldName: "email",
            });
        }
    };

    protected generateSsoLoginActions = () => {
        return this.userSsoConfiguration!.userSsoModels.map((userSsoResponse) => {
            return {
                label: t({
                    id: "loginApp.loginForm.ssoStep.buttonLabel",
                    message: `Access Enterprise Login - ${userSsoResponse.ssoProviderName}`,
                    values: { 0: userSsoResponse.ssoProviderName },
                }),
                event: (e) => {
                    e.preventDefault();

                    this.setIsLoading(true);
                    window.location.href = userSsoResponse.ssoRedirectUrl;
                },
            } as ILoginAction;
        });
    };

    @action onSelectedPlatformChange = (platform: AtmanCoPlatformsEnum) => {
        const {} = this.props;

        if (platform !== this.selectedPlatform) {
            this.restartStepForm();
        }

        this.selectedPlatform = platform;
    };

    @action onUseLoginPassword = () => {
        this.goToStep(4);
    };

    render() {
        const { systemStateStore } = this.props;

        const currentStepData = this.currentStepDataMapping.get(this.currentStep);

        return (
            <div id="LoginForm">
                <AtTitle
                    headingType={1}
                    title={
                        this.selectedPlatform === undefined ? (
                            <Trans id={"loginApp.loginForm.selectAPlatform"}>Please select a platform...</Trans>
                        ) : (
                            currentStepData?.title
                        )
                    }
                />
                {this.currentStep === 0 || this.currentStep === 1 || this.currentStep === 2 ? (
                    <ProductSelector
                        onSelectedPlatformChange={this.onSelectedPlatformChange}
                        selectedPlatform={this.selectedPlatform}
                        disableSelection={this.isLoading}
                    />
                ) : null}
                {this.selectedPlatform !== undefined ? (
                    <>
                        <Form onSubmit={currentStepData?.action?.event}>
                            {this.currentStep === 0 && (
                                <>
                                    {!systemStateStore!.hasLoadedSystemState ? (
                                        <LoadingIndicator
                                            size={"md"}
                                            label={t({
                                                id: "loginApp.loginForm.loadingSystemStatus",
                                                message: "Making sure the system is available...",
                                            })}
                                        />
                                    ) : !this.systemStateInfo.available ? (
                                        <UnavailableSystem activeSystemState={this.systemStateInfo.activeState!} />
                                    ) : null}
                                </>
                            )}
                            {this.currentStep === 1 ? (
                                <>
                                    {this.isLoading || this.isLoadingSaml ? (
                                        <LoadingIndicator
                                            size={"md"}
                                            label={
                                                this.loggedInUsername
                                                    ? t({
                                                          id: "loginApp.loginForm.redirectToApp",
                                                          message: `Redirecting you to the app...`,
                                                      })
                                                    : t({
                                                          id: "loginApp.loginForm.checkingLoginInformation",
                                                          message: `Checking your login information...`,
                                                      })
                                            }
                                        />
                                    ) : (
                                        <div className={"login-silently-container"}>
                                            <AtButton id={"login-silently-btn"} onClick={this.loginSilently} fullWidth>
                                                <Trans id={"loginApp.loginForm.loginSilentlyButtonLabel"}>
                                                    Continue as {this.loggedInUsername}
                                                </Trans>
                                            </AtButton>
                                            <AtLink
                                                id={"login-with-other-account-btn"}
                                                onClick={() => this.goToStep(2)}
                                            >
                                                <Trans id={"loginApp.loginForm.loginWithAnotherAccount"}>
                                                    Log in with another account
                                                </Trans>
                                            </AtLink>
                                        </div>
                                    )}
                                </>
                            ) : null}
                            {this.currentStep === 2 ? (
                                <>
                                    <div className="info-container">
                                        <Trans id={"loginApp.loginForm.emailStep.loginEmailExplanation"}>
                                            This is the email address used to log into the platform. It may be different
                                            than your actual email address.
                                        </Trans>
                                    </div>
                                    <CustomValidatedTextInput
                                        fieldName={"email"}
                                        value={this.email}
                                        onChange={this.onTextFieldChange}
                                        type={"email"}
                                        label={t({
                                            id: "global.inputs.labels.loginEmail",
                                            message: `Login Email`,
                                        })}
                                        validationErrors={this.errors}
                                        containerClassName={"email-input"}
                                    />
                                </>
                            ) : null}
                            {this.currentStep === 3 ? (
                                <>
                                    <div className="info-container">
                                        {this.userSsoConfiguration?.canLoginWithPassword ? (
                                            <Trans
                                                id={"loginApp.loginForm.ssoStep.ssoExplanationWithPasswordLoginOption"}
                                            >
                                                Your account is configured to use an external enterprise login. Click
                                                below to access login.{" "}
                                                <a href="#" onClick={this.onUseLoginPassword}>
                                                    Click here for email/password login
                                                </a>
                                            </Trans>
                                        ) : (
                                            <Trans id={"loginApp.loginForm.ssoStep.ssoExplanation"}>
                                                Your account is configured to use an external enterprise login. Click
                                                below to access login.
                                            </Trans>
                                        )}
                                    </div>
                                </>
                            ) : null}
                            {this.currentStep === 4 ? (
                                <>
                                    <EmailAccountComponent goBack={() => this.goToStep(2)} email={this.email} />

                                    <div className="password-input-container">
                                        <div className="password-label">
                                            <Trans id={"loginApp.loginForm.passwordStep.inputs.labels.password"}>
                                                Enter your password
                                            </Trans>
                                        </div>
                                        <input type={"hidden"} id={"email"} value={this.email} />
                                        <CustomValidatedTextInput
                                            fieldName={"password"}
                                            hideLabel
                                            value={this.password}
                                            onChange={this.onTextFieldChange}
                                            type={"password"}
                                            validationErrors={this.errors}
                                            autoComplete={"password"}
                                            containerClassName={"password-input"}
                                            autoFocus
                                        />
                                    </div>
                                    <AtLink id={"forgot-password-link"} onClick={this.goToForgotPasswordPage}>
                                        <Trans id={"loginApp.global.buttons.labels.forgotPassword"}>
                                            Forgot Password?
                                        </Trans>
                                    </AtLink>

                                    <Label className={"remember-me-checkbox-container"}>
                                        <AtCheckboxBase
                                            checked={this.rememberMe}
                                            onChange={this.onCheckboxChange}
                                            value={this.rememberMe}
                                            fieldName={"rememberMe"}
                                            id={"remember-me-checkbox"}
                                        />
                                        <span className="label">
                                            <Trans id={"loginApp.loginForm.passwordStep.rememberMe"}>Remember Me</Trans>
                                        </span>
                                    </Label>
                                </>
                            ) : null}
                            {currentStepData?.action && (
                                <AtButton
                                    type={"submit"}
                                    color={"primary"}
                                    size={"xl"}
                                    fullWidth
                                    isLoading={this.isLoading}
                                >
                                    {currentStepData.action.label}
                                </AtButton>
                            )}
                        </Form>
                        {currentStepData?.actionsGenerator &&
                            currentStepData!.actionsGenerator().map((x, i) => (
                                <Form key={`actionForm${i}`} onSubmit={x.event}>
                                    <AtButton
                                        type={"submit"}
                                        className="action-button"
                                        color={"primary"}
                                        size={"md"}
                                        fullWidth
                                        isLoading={this.isLoading}
                                    >
                                        <Trans id={x.label} />
                                    </AtButton>
                                </Form>
                            ))}
                    </>
                ) : null}
            </div>
        );
    }
}

export interface ILoginStepData {
    title?: string;
    action?: ILoginAction;
    actionsGenerator?: () => ILoginAction[];
}

export interface ILoginAction {
    label: string;
    event: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const Login = withRouter(LoginImpl);
