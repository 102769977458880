import "./index.less";
import { ComponentPadding, generatePadding } from "../../../internal";
import React from "react";
import cn from "classnames";

export interface IAtContainerProps extends React.HTMLProps<HTMLDivElement> {
    header?: JSX.Element;
    headerProps?: React.HTMLProps<HTMLDivElement>;
    padding?: null | ComponentPadding;
    dark?: boolean;
    isUnderlined?: boolean;
    headerPadding?: string;
    showHeaderMarginBottom?: boolean;
}

export const AtContainer: React.FC<IAtContainerProps> = (props) => {
    const {
        header,
        children,
        className,
        headerProps,
        padding,
        dark,
        headerPadding,
        isUnderlined = false,
        showHeaderMarginBottom = true,
        style,
        ...divProps
    } = props;

    const { className: headerClassName, ...otherHeaderProps } = headerProps || {};

    const headerStyles = headerPadding
        ? {
              padding: headerPadding,
          }
        : undefined;

    return (
        <div
            className={cn("AtContainer", className, { "dark-bg": dark })}
            style={{ padding: generatePadding(padding), ...style }}
            {...divProps}
        >
            {header && (
                <div
                    className={cn("AtContainer-header", headerClassName, {
                        isUnderlined: isUnderlined,
                        showHeaderMarginBottom: showHeaderMarginBottom,
                    })}
                    style={headerStyles}
                    {...otherHeaderProps}
                >
                    {header}
                </div>
            )}
            {children}
        </div>
    );
};
