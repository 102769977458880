import "./CustomAlertContent.less";
import * as React from "react";
import { AtButton, IAtButtonProps } from "@atman/design-system";
import { ReactSweetAlert } from "sweetalert2-react-content";
import { observer } from "mobx-react";
import { useDetectDevice } from "@atman/core";
import swal from "sweetalert2/dist/sweetalert2";

export interface ICustomAlertContentProps {
    swalInstance: typeof swal & ReactSweetAlert;
    showConfirmButton?: boolean;
    confirmButtonText?: string;
    confirmButtonProps?: Omit<IAtButtonProps, "children" | "isLoading" | "onClick">;
    showCancelButton?: boolean;
    cancelButtonText?: string;
    cancelButtonProps?: Omit<IAtButtonProps, "children" | "isLoading" | "onClick">;
    reverseButtons?: boolean;
    confirmAction?: () => void;
}

export const CustomAlertContent: React.FC<ICustomAlertContentProps> = observer((props) => {
    const {
        showConfirmButton = true,
        confirmButtonText = "global.buttons.labels.confirm".localize(),
        confirmButtonProps,
        showCancelButton = true,
        cancelButtonText = "global.buttons.labels.cancel".localize(),
        cancelButtonProps,
        reverseButtons = true,
        confirmAction,
        swalInstance,
        children,
    } = props;

    const [_isLoading, setIsLoading] = React.useState(false);
    const { isMobile } = useDetectDevice();

    const _confirmAction = async () => {
        setIsLoading(true);

        await confirmAction?.();

        setIsLoading(false);

        swalInstance.close();
    };

    const getButtons: () => React.ReactNode = () => {
        const cancelButton = showCancelButton ? (
            <AtButton
                color={"secondary"}
                size={isMobile ? "xl" : "lg"}
                isLoading={_isLoading}
                onClick={() => swalInstance.close()}
                {...cancelButtonProps}
            >
                {cancelButtonText}
            </AtButton>
        ) : undefined;

        const confirmButton = showConfirmButton ? (
            <AtButton
                color={"primary"}
                size={isMobile ? "xl" : "lg"}
                isLoading={_isLoading}
                onClick={_confirmAction}
                {...confirmButtonProps}
            >
                {confirmButtonText}
            </AtButton>
        ) : undefined;

        if (reverseButtons) {
            return (
                <>
                    {cancelButton}
                    {confirmButton}
                </>
            );
        } else {
            return (
                <>
                    {confirmButton}
                    {cancelButton}
                </>
            );
        }
    };

    return (
        <div className="CustomAlertContent">
            {children}
            <div className="buttons-container">{getButtons()}</div>
        </div>
    );
});
