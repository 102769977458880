import "./index.less";
import * as React from "react";
import { AtWizard, IAtWizardProps, IAtWizardRef } from "../AtWizard";
import { AtmanLogoIcon } from "../../static";
import { DefaultComponentProps } from "@atman/design-system";
import { EntityWizardOverview } from "./components/EntityWizardOverview";
import { ItemType } from "@atman/business";
import { observer } from "mobx-react";

export interface IAtEntityWizardProps
    extends DefaultComponentProps,
        Omit<IAtWizardProps, "uiStore" | "customLeftHeaderContent"> {
    entityType: ItemType;
    entityName: string | undefined;
    entityOverviewContent: React.ReactNode;
    customSubmitButtonLabel?: string;
    wizardDefaultProps?: DefaultComponentProps;
    wizardRef: React.RefObject<IAtWizardRef>;
}

export const AtEntityWizard: React.FC<IAtEntityWizardProps> = observer((props) => {
    const {
        className,
        id,
        style,
        wizardDefaultProps,
        customSubmitButtonLabel,
        entityType,
        entityName,
        entityOverviewContent,
        steps,
        defaultStepValues,
        allowStepClickNavigation = false,
        formatSubStepProgressLabel,
        cancelAction,
        onSubmit,
        wizardRef,
    } = props;

    let classNames = "AtEntityWizard";

    if (className) {
        classNames += ` ${className}`;
    }

    return (
        <div className={classNames} id={id} style={style}>
            <AtWizard
                ref={wizardRef}
                steps={steps}
                defaultStepValues={defaultStepValues}
                allowStepClickNavigation={allowStepClickNavigation}
                formatSubStepProgressLabel={formatSubStepProgressLabel}
                customLeftHeaderContent={<img src={AtmanLogoIcon} width={24} />}
                sidePanelContent={
                    <EntityWizardOverview
                        itemType={entityType}
                        entityName={entityName}
                        overviewContent={entityOverviewContent}
                        buttonLabel={customSubmitButtonLabel}
                        onSubmit={onSubmit}
                    />
                }
                cancelAction={cancelAction}
                onSubmit={onSubmit}
                {...wizardDefaultProps}
            />
        </div>
    );
});
