import "./index.less";
import { AtTabCard, IReactSelectOptionObject } from "@atman/design-system";
import { BaseForm } from "../BaseForm";
import { CardSaveButtons } from "../CardSaveButtons";
import { CultureSelectField } from "../CommonFields";
import { CustomTextInput } from "../CustomTextInput";
import { GlobalStores, LocalizationStore, UserInfoStore, Validator } from "@atman/business";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import autobind from "autobind-decorator";

export interface IUserPersonalInformationFormProps {
    userInfoStore?: UserInfoStore;
    localizationStore?: LocalizationStore;
}

@inject(GlobalStores.userInfoStore, GlobalStores.localizationStore)
@observer
export class UserPersonalInformationForm extends BaseForm<IUserPersonalInformationFormProps, {}> {
    @observable public firstName: string = "";
    @observable public lastName: string = "";
    @observable public loginEmail: string = "";
    @observable public communicationEmail: string = "";
    @observable public languageCode: IReactSelectOptionObject = {
        value: "",
        label: "",
    };

    componentDidMount(): void {
        this.resetPersonalInformation();
    }

    @action.bound
    resetPersonalInformation = () => {
        const { userInfoStore, localizationStore } = this.props;

        this.firstName = userInfoStore!.firstName;
        this.lastName = userInfoStore!.lastName;
        this.loginEmail = userInfoStore!.email;
        this.communicationEmail = userInfoStore!.communicationEmail;

        this.languageCode = {
            value: localizationStore!.currentLocale.cultureInfo,
            label: localizationStore!.currentLocale.shortDisplay,
        };
    };

    @autobind
    async _onSave() {
        await this.props.userInfoStore!.updateUserPreferences(
            this.firstName,
            this.lastName,
            this.communicationEmail,
            this.languageCode.value,
        );
    }

    @action.bound
    onLanguageChange(option: IReactSelectOptionObject) {
        this.languageCode = option;
    }

    validateForm(): boolean {
        return Validator.validateEmail(this.communicationEmail);
    }

    render() {
        const { userInfoStore } = this.props;

        return (
            <AtTabCard cardTitle={"global.updateYourPersonalInformation".localize()} id="PersonalInformationForm">
                <form className="inputs">
                    <CustomTextInput fieldName={"firstName"} value={this.firstName} onChange={this.onTextFieldChange} />
                    <CustomTextInput fieldName={"lastName"} value={this.lastName} onChange={this.onTextFieldChange} />
                    <CustomTextInput
                        fieldName={"loginEmail"}
                        value={this.loginEmail}
                        onChange={this.onTextFieldChange}
                        disabled
                        formText={"global.loginEmailFormText".localize()}
                    />
                    <CustomTextInput
                        fieldName={"communicationEmail"}
                        value={this.communicationEmail}
                        onChange={this.onTextFieldChange}
                        formText={"global.communicationEmailFormText".localize()}
                    />
                    <CultureSelectField
                        cultureValue={this.languageCode}
                        onChange={this.onLanguageChange}
                        labelOverride={"global.preferredLanguage".localize()}
                    />
                </form>
                <CardSaveButtons
                    saveAction={this._onSave}
                    cancelAction={this.resetPersonalInformation}
                    isLoading={userInfoStore!.isLoading}
                    disabledSaveButton={!this.validateForm()}
                />
            </AtTabCard>
        );
    }
}
