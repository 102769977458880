import "./index.less";
import * as React from "react";
import {
    AnalyzedCandidate,
    Candidate,
    IShortCandidate,
    PersonHelper,
    ReactAppSettings,
    UrlFormatter,
} from "@atman/business";
import { AtPersonAvatar } from "@atman/design-system";
import { inject, observer } from "mobx-react";

export interface IPersonLabelProps {
    person?: Candidate | IShortCandidate | AnalyzedCandidate;
    isTeamLead?: boolean;
    showJobNameIsPresent?: boolean;
    active?: boolean;
}

@inject()
@observer
export class PersonLabel extends React.Component<IPersonLabelProps, {}> {
    static defaultProps = {
        active: false,
        isTeamLead: false,
        showJobNameIsPresent: true,
    };

    render() {
        const { person, active, showJobNameIsPresent, isTeamLead } = this.props;

        if (!person) return;

        const jobOrEmail =
            showJobNameIsPresent && ReactAppSettings.viewBag.baseInfo.competencyDevelopmentEnabled
                ? person.jobName ?? "global.noJobAssigned".localize()
                : person.email;

        return (
            <div className={`PersonLabel ${active ? "active" : ""}`}>
                <div className="picture">
                    <AtPersonAvatar
                        size="lg"
                        firstName={person.firstName}
                        lastName={person.lastName}
                        email={person.email}
                        pictureUri={person.profilePictureUri}
                        pictureFormatFunc={UrlFormatter.formatPictureUrlForSize}
                        active={active}
                    />
                </div>
                <div className="person-info">
                    <div className="name">
                        {isTeamLead && <span className={"manager-indicator"}>{"M"}</span>}
                        {PersonHelper.getDisplayName(person)}
                    </div>
                    <div className="sub-text">{jobOrEmail}</div>
                </div>
            </div>
        );
    }
}
