import { BaseCompetencyElement, IBaseCompetencyElement } from "../BaseCompetencyElement";
import { CompDevCompetencyGroup } from "../CompDevEnums";
import { CompDevLearningResource } from "./CompDevLearningResource";
import { CompDevStrategicObjective, ICompDevStrategicObjective } from "./CompDevStrategicObjective";
import { CompDevSubCompetency, ICompDevSubCompetency } from "./CompDevSubCompetency";

export interface ICompDevCompetency extends IBaseCompetencyElement {
    readonly subCompetencies: ICompDevSubCompetency[];
    readonly learningResources: CompDevLearningResource[];
    readonly strategicObjectives: ICompDevStrategicObjective[];
    readonly competencyGroup: CompDevCompetencyGroup;
}

export class CompDevCompetency extends BaseCompetencyElement {
    public readonly subCompetencies: ICompDevSubCompetency[];
    public readonly learningResources: CompDevLearningResource[];
    public readonly strategicObjectives: ICompDevStrategicObjective[];
    public readonly competencyGroup: CompDevCompetencyGroup;

    constructor(json: ICompDevCompetency) {
        super(json);

        this.subCompetencies = json.subCompetencies.map((x) => new CompDevSubCompetency(x, this.rank));
        // TODO fix that
        this.strategicObjectives = (json?.strategicObjectives || []).map((x) => new CompDevStrategicObjective(x));
        this.learningResources = json.learningResources?.map((x) => new CompDevLearningResource(x)) ?? [];
        this.competencyGroup = json.competencyGroup;
    }
}
