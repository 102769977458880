import "./index.less";
import * as React from "react";
import { CompetencyDevelopmentLearningResourceType } from "@atman/business";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { LearningResourceFilter } from "./components/LearningResourceFilter";
import { observer } from "mobx-react";

export interface ILearningResourceFilterContainerProps {
    filters: CompetencyDevelopmentLearningResourceType[];
    selectedFilters: Array<CompetencyDevelopmentLearningResourceType | "all">;
    onFilterClick: (selectedTypes: Array<CompetencyDevelopmentLearningResourceType | "all">) => void;
    addAllFilter?: boolean;
    allowMultipleFilters?: boolean;
    mustHaveFilter?: boolean;
}

// TODO Simon 2021-06-10: This component should be a molecule in the design-system
export const LearningResourceFilterContainer: React.FC<ILearningResourceFilterContainerProps> = observer((props) => {
    const {
        filters,
        selectedFilters,
        onFilterClick,
        addAllFilter = true,
        allowMultipleFilters = true,
        mustHaveFilter = false,
    } = props;

    const handleFilterClick: (filter: CompetencyDevelopmentLearningResourceType | "all") => void = (
        filter: CompetencyDevelopmentLearningResourceType | "all",
    ) => {
        let _selectedFilters: Array<CompetencyDevelopmentLearningResourceType | "all"> = selectedFilters;

        if (filter === "all") {
            _selectedFilters = ["all"];
        } else if (selectedFilters.includes(filter)) {
            _selectedFilters = _selectedFilters.filter((x) => x !== filter && x !== "all");

            if (_selectedFilters.length === 0) {
                if (addAllFilter) {
                    _selectedFilters = ["all"];
                } else if (mustHaveFilter) {
                    _selectedFilters = filters.any() ? [filters[0]] : [];
                }
            }
        } else if (!allowMultipleFilters) {
            _selectedFilters = [filter];
        } else {
            _selectedFilters = [..._selectedFilters.filter((x) => x !== "all"), filter];

            if (addAllFilter && _selectedFilters.sort().join(",") === filters.sort().join(",")) {
                _selectedFilters = ["all"];
            }
        }

        onFilterClick(_selectedFilters);
    };

    const renderFilter = (filter: CompetencyDevelopmentLearningResourceType | "all") => {
        let iconProp: IconProp | undefined;

        switch (filter) {
            case CompetencyDevelopmentLearningResourceType.Article:
                iconProp = ["fal", "browser"];
                break;
            case CompetencyDevelopmentLearningResourceType.Book:
                iconProp = ["fal", "book"];
                break;
            case CompetencyDevelopmentLearningResourceType.Video:
                iconProp = ["fal", "video"];
                break;
            case CompetencyDevelopmentLearningResourceType.Podcast:
                iconProp = ["fal", "podcast"];
                break;
            case CompetencyDevelopmentLearningResourceType.Certification:
                iconProp = ["fal", "certificate"];
                break;
        }

        const labelKey: string = filter === "all" ? "all" : CompetencyDevelopmentLearningResourceType[filter].toCamel();

        return (
            <LearningResourceFilter
                key={filter}
                label={`competencyDevelopment.learningResourceType.${labelKey.toCamel()}`.localize()}
                value={filter}
                iconProp={iconProp}
                isSelected={selectedFilters.includes(filter)}
                onClick={handleFilterClick}
                removePadding
            />
        );
    };

    return (
        <div className="LearningResourceFilterContainer d-flex flex-wrap align-items-start justify-content-start">
            {addAllFilter && renderFilter("all")}
            {filters.map((filter: CompetencyDevelopmentLearningResourceType) => renderFilter(filter))}
        </div>
    );
});
