import "./index.less";
import * as React from "react";
import { Colors } from "../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormGroup, FormText, Input, InputProps, Label, UncontrolledTooltip } from "reactstrap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { action, observable, toJS } from "mobx";
import cn from "classnames";
export interface ICustomTextInputProps extends InputProps {
    fieldName: string;
    value: any;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    formText?: string;
    hideLabel?: boolean;
    inputState?: StandaloneInputState;
    inputErrorMessage?: string;
    leftIcon?: IconProp;
    rows?: number;
    placeholder?: string;
    label?: string;
    rightIcon?: IconProp;
    onRightIconClick?: () => void;
    hideTextAreaCorner?: boolean;
}

export enum StandaloneInputState {
    Standby,
    Loading,
    Success,
    Error,
}

export class CustomTextInput extends React.Component<ICustomTextInputProps, {}> {
    @observable public showInputState: boolean = false;

    @action.bound
    onFocus = () => {
        this.showInputState = false;
    };

    @action.bound
    onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        const { onBlur } = this.props;

        this.showInputState = true;

        if (onBlur) {
            onBlur(event);
        }
    };

    renderInputStateComponent(): JSX.Element | null {
        const { inputState, inputErrorMessage } = this.props;

        if (!this.showInputState || !inputState) {
            return null;
        }

        let children: React.ReactNode;

        switch (inputState) {
            case StandaloneInputState.Success:
                children = <FontAwesomeIcon icon={["far", "check-circle"] as IconProp} color={Colors.successColor} />;
                break;
            case StandaloneInputState.Loading:
                children = <FontAwesomeIcon icon={["far", "spinner"] as IconProp} spin />;
                break;
            case StandaloneInputState.Error:
                children = <FontAwesomeIcon icon={["far", "times-circle"] as IconProp} color={Colors.errorColor} />;
                break;
            default:
                return null;
        }

        return (
            <div className="input-state-container">
                <div id="input-state-icon-container">{children}</div>
                {inputState === StandaloneInputState.Error && (
                    <UncontrolledTooltip target={"input-state-icon-container"} placement={"right"}>
                        {inputErrorMessage}
                    </UncontrolledTooltip>
                )}
            </div>
        );
    }

    render(): JSX.Element {
        const {
            fieldName,
            value,
            onChange,
            placeholder,
            label,
            formText,
            onBlur,
            hideLabel = false,
            type = "text",
            leftIcon,
            rightIcon,
            onRightIconClick,
            rows,
            hideTextAreaCorner = false,
            ...otherProps
        } = this.props;

        let textAreaProps = {};

        if (type === "textarea") {
            textAreaProps = {
                rows: rows ?? 4,
                ...(hideTextAreaCorner
                    ? {
                          style: {
                              resize: "none",
                          },
                      }
                    : {}),
            };
        }

        return (
            <FormGroup className={`CustomTextInput ${fieldName}-input ${leftIcon ? "has-icon" : ""}`}>
                {!hideLabel ? <Label for={fieldName}>{label ?? `global.${fieldName}`.localize()}</Label> : null}
                {this.renderInputStateComponent()}
                {leftIcon && (
                    <div className="left-icon-container">
                        <FontAwesomeIcon icon={toJS(leftIcon)} color={Colors.greyShades.shade4} />
                    </div>
                )}
                <Input
                    type={type}
                    name={fieldName}
                    id={fieldName}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    {...otherProps}
                    {...textAreaProps}
                    onBlur={onBlur ? this.onBlur : undefined}
                    onFocus={onBlur ? this.onFocus : undefined}
                    onClick={onBlur ? this.onFocus : undefined}
                />
                {rightIcon && (
                    <div
                        className={cn("right-icon-container", { hasOnClickEvent: onRightIconClick })}
                        onClick={() => {
                            if (onRightIconClick) {
                                onRightIconClick();
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={toJS(rightIcon)} color={Colors.greyShades.shade4} />
                    </div>
                )}
                {formText ? <FormText>{formText}</FormText> : null}
            </FormGroup>
        );
    }
}
