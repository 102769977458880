import { ApiClient, IApiClient } from "../libs/ApiClient";
import { AtmanCoPlatformsEnum } from "../global/components/ProductSelector";
import { IS_STAGING } from "../../index";
import { backend_urls } from "../App";

export interface IBaseMultiUrlApi {
    setJwtAuthHeader: (jwt: string) => void;
    setBaseUrlFromPlatform: (platform?: AtmanCoPlatformsEnum) => void;
}

export abstract class BaseMultiUrlApi implements IBaseMultiUrlApi {
    protected readonly _apiClient: IApiClient;
    protected _baseUrl: string;

    constructor() {
        this._apiClient = new ApiClient();
    }

    setJwtAuthHeader = (jwt: string) => {
        this._apiClient.setJwtAuthHeader(jwt);
    };

    getStorageBaseUrl = () => {
        return backend_urls?.blobStorage;
    };

    setBaseUrlFromPlatform = (platform: AtmanCoPlatformsEnum = AtmanCoPlatformsEnum.ProNA) => {
        let baseUrl: string;

        switch (platform) {
            case AtmanCoPlatformsEnum.ProNA:
                baseUrl = `${backend_urls?.proNA}/api/v1`;
                break;
            case AtmanCoPlatformsEnum.ProEU:
                baseUrl = `${backend_urls?.proEU}/api/v1`;
                break;
            default:
                throw new Error("Unknown apiEnvironment");
        }

        if (IS_STAGING) {
            baseUrl = baseUrl.replace(/https:\/\/([a-zA-Z\-_]+)\.([a-zA-Z\-_]+)\.com/, `https://$1-staging.$2.com`);
        }

        this._baseUrl = baseUrl;

        this._apiClient.updateBaseUrl(this._baseUrl);
    };
}
