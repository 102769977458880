import * as React from "react";
import { AtValueColor, ValueColorRenderMode } from "@atman/design-system";
import { Colors } from "../../../styles";
import { LIKERT_AVG_INTERPRETATION_RANGES, RangeHelper, getRoundedScore } from "@atman/business";
import { observer } from "mobx-react";

export type AverageScoreDisplayMode = "solid" | "light";

export interface IAverageScoreDisplayProps {
    reviewScore?: number;
    averageScore?: number;
    averageScoreDisplayMode?: AverageScoreDisplayMode;
}

export const AverageScoreDisplay: React.FC<IAverageScoreDisplayProps> = observer((props) => {
    const { reviewScore, averageScore, averageScoreDisplayMode = "solid" } = props;

    let scoreToUse: number | undefined;

    if (reviewScore) {
        scoreToUse = reviewScore;
    } else {
        scoreToUse = averageScore;
    }

    const likertValue = RangeHelper.getRangeMapValue(LIKERT_AVG_INTERPRETATION_RANGES, scoreToUse);

    let textColor: string | undefined, backgroundColor: string | undefined;
    const colorGroup = Colors.getLikertScoreColorGroup(likertValue);

    const borderColor: string | undefined = colorGroup?.borderColor;

    switch (averageScoreDisplayMode) {
        case "solid":
            backgroundColor = colorGroup?.backgroundColor;
            textColor = colorGroup?.textColor;
            break;
        case "light":
            backgroundColor = "white";
            break;
    }

    return (
        <AtValueColor
            value={getRoundedScore(scoreToUse)}
            textColor={textColor}
            bgColor={backgroundColor}
            borderColor={borderColor}
            renderMode={ValueColorRenderMode.Background}
        />
    );
});
