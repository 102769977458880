import "./index.less";
import * as React from "react";
import { DefaultComponentProps, IInputHeaderProps, InputHeader } from "../../../internal";
import { FormGroup } from "reactstrap";
import { observer } from "mobx-react";

export interface IFormInputGroupProps extends DefaultComponentProps, IInputHeaderProps {}

export const FormInputGroup: React.FC<IFormInputGroupProps> = observer((props) => {
    const {
        label,
        description,
        requirementType,
        requirementText,
        currentInputLength,
        maxInputLength,
        className,
        id,
        style,
        children,
        ...otherProps
    } = props;

    let formGroupClassName = "FormInputGroup";

    if (className) {
        formGroupClassName += ` ${className}`;
    }

    return (
        <FormGroup className={formGroupClassName} id={id} style={style} {...otherProps}>
            {label && (
                <InputHeader
                    label={label}
                    description={description}
                    requirementType={requirementType}
                    requirementText={requirementText}
                    currentInputLength={currentInputLength}
                    maxInputLength={maxInputLength}
                />
            )}
            {children}
        </FormGroup>
    );
});
