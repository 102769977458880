import "./SubMenuItem.less";
import * as React from "react";
import { AtPageHeader } from "../../AtPageHeader";
import { IAtSubMenuNavigationItem } from "../index";

export interface ISubMenuItemProps extends IAtSubMenuNavigationItem {}

export const SubMenuItem: React.FC<ISubMenuItemProps> = (props) => {
    const { label, description, helpCenterArticleId, helpCenterUrlLabel, component, showHeaderInPage = true } = props;

    return (
        <div className="SubMenuItem">
            <div className="view-container">
                {showHeaderInPage && (
                    <div className="view-header">
                        <AtPageHeader
                            label={label}
                            description={description}
                            helpCenterArticleId={helpCenterArticleId}
                            helpCenterUrlLabel={helpCenterUrlLabel}
                        />
                    </div>
                )}
                <div className={"view-content"}>{component}</div>
            </div>
        </div>
    );
};
