import "./DashboardContainer.less";
import * as React from "react";
import {
    AppScope,
    ClientStore,
    GlobalStores,
    IDashboardElements,
    LocalizationStore,
    PartnerStore,
    TransactionStore,
    UnhandledScopeError,
} from "@atman/business";
import {
    AtDateRangePickerPopover,
    AtSelect,
    DateRange,
    IDatePreset,
    IReactSelectOptionObject,
} from "@atman/design-system";
import { Col, Row } from "reactstrap";
import { DashboardGraph } from "./components/DashboardGraph";
import { DashboardOwnersList } from "./components/DashboardOwnersList";
import { IRoutedAppContext, withRoutedAppContext } from "../../contexts";
import { PageHeader } from "../../components";
import { ProductUsageDashboard } from "./components/ProductUsageDashboard";
import { StylesConfig } from "react-select";
import { action, computed, observable, reaction, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";

export interface IDashboardContainerProps extends IRoutedAppContext {
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
    transactionStore?: TransactionStore;
    localizationStore?: LocalizationStore;
}

@inject(
    GlobalStores.clientStore,
    GlobalStores.partnerStore,
    GlobalStores.transactionStore,
    GlobalStores.localizationStore,
)
@observer
class DashboardContainerComp extends React.Component<IDashboardContainerProps> {
    @observable public ownerIdFilter?: IReactSelectOptionObject;
    @observable public dashboardElements: IDashboardElements;
    @observable public dateRange: DateRange = {
        from: moment().startOf("quarter").toDate(),
        to: moment().endOf("quarter").toDate(),
    };

    // protected startDateReaction = reaction(() => this.startDate, () => this.refreshData());
    protected endDateReaction = reaction(
        () => this.dateRange.from && this.dateRange.to,
        () => this.refreshData(),
    );

    protected otherPartyOwnerIdFilterReaction = reaction(
        () => this.ownerIdFilter,
        () => this.refreshData(),
    );

    async componentDidMount() {
        await window.setTimeout(async () => {
            await this.refreshData();
        }, 1000);
    }

    @computed
    get otherPartySelectOptions(): IReactSelectOptionObject[] {
        const { scope, clientStore, partnerStore } = this.props;

        switch (scope) {
            case AppScope.Client:
                return [];
            case AppScope.Partner:
                return clientStore!.clients.map((x) => ({ label: x.name, value: x.id }));
            case AppScope.Supplier:
                return partnerStore!.partners.map((x) => ({ label: x.name, value: x.id }));
            default:
                throw new UnhandledScopeError(scope);
        }
    }

    @computed
    get filterDaysCount(): number {
        if (!this.dateRange.from || !this.dateRange.to) {
            return 0;
        }

        return moment(this.dateRange.to).diff(this.dateRange.from, "days");
    }

    @action.bound
    private refreshData = async () => {
        if (!this.dateRange.from || !this.dateRange.to) {
            return;
        }

        const data = await this.props.transactionStore!.getDashboardElements({
            startDate: moment(this.dateRange.from).startOf("D").toISOString(true),
            endDate: moment(this.dateRange.to).startOf("D").toISOString(true),
            ownerId: this.ownerIdFilter ? this.ownerIdFilter.value : undefined,
            sortField: "Total",
            sortDirection: "desc",
        });

        if (!data) {
            return;
        }

        runInAction(() => {
            this.dashboardElements = data;
        });
    };

    @action.bound
    handleExport = () => {
        // TODO
    };

    @action.bound
    handleDatesChanged = (dateRange: DateRange) => {
        this.dateRange = dateRange;
    };

    @action.bound
    onOtherPartyOwnerIdFilterChange = (option: any) => {
        this.ownerIdFilter = option ? option : undefined;
    };

    render() {
        const { localizationStore, scope } = this.props;

        let otherPartyOwnerIdFilterPlaceholder: string = "";

        switch (scope) {
            case AppScope.Partner:
                otherPartyOwnerIdFilterPlaceholder = "global.clientIdFilterPlaceholder".localize();
                break;
            case AppScope.Supplier:
                otherPartyOwnerIdFilterPlaceholder = "supplierApp.partnerIdFilterPlaceholder".localize();
                break;
        }

        const datePresets: IDatePreset[] = [
            {
                labelOverride: "global.thisYear".localize(),
                range: {
                    from: moment().startOf("year"),
                    to: moment().endOf("year"),
                },
            },
            {
                labelOverride: "global.thisQuarter".localize(),
                range: {
                    from: moment().startOf("quarter"),
                    to: moment().endOf("quarter"),
                },
            },
            {
                labelOverride: "global.thisMonth".localize(),
                range: {
                    from: moment().startOf("month"),
                    to: moment().endOf("month"),
                },
            },
            {
                labelOverride: "global.thisWeek".localize(),
                range: {
                    from: moment().startOf("week"),
                    to: moment().endOf("week"),
                },
            },
        ];

        const customReactSelectStyles: StylesConfig<any> = {
            container: (styles) => ({
                ...styles,
                width: 320,
                marginRight: 16,
            }),
            control: (styles) => ({
                ...styles,
                width: 320,
                padding: "2.5px 8px",
            }),
        };

        return (
            <div id="DashboardContainer">
                <PageHeader pageTitle={"global.dashboard".localize()} />
                <div className="dashboard-header">
                    <div className="left-section d-flex flex-column flex-xl-row text-left">
                        <div className="mb-2 mb-xl-0">
                            <AtDateRangePickerPopover
                                range={this.dateRange}
                                setRange={this.handleDatesChanged}
                                datePresets={datePresets}
                                languageCode={localizationStore!.currentShortLocale}
                                fromDate={new Date(2015, 1, 1)}
                            />
                        </div>
                        <div className="d-flex flex-row">
                            <AtSelect
                                options={this.otherPartySelectOptions}
                                value={this.ownerIdFilter}
                                onChange={this.onOtherPartyOwnerIdFilterChange}
                                placeholder={otherPartyOwnerIdFilterPlaceholder.localize()}
                                additionalStyles={customReactSelectStyles}
                                isClearable
                            />
                        </div>
                    </div>
                    <div className="right-section">
                        {/*<AtButton color={"secondary"} size={'sm'} onClick={this.handleExport}>*/}
                        {/*    { 'exportTransactionLog'.localize() }*/}
                        {/*</AtButton>*/}
                    </div>
                </div>
                <Row style={{ height: "100%" }}>
                    <Col sm={9} style={{ height: "100%" }}>
                        <div className="scroll-container">
                            <DashboardGraph
                                data={this.dashboardElements ? this.dashboardElements.dateGraph : undefined}
                                filterDaysCount={this.filterDaysCount}
                            />
                            <DashboardOwnersList
                                data={this.dashboardElements ? this.dashboardElements.ownersUsage : undefined}
                                filterDaysCount={this.filterDaysCount}
                            />
                        </div>
                    </Col>
                    <Col sm={3} style={{ height: "100%" }}>
                        <ProductUsageDashboard
                            data={this.dashboardElements ? this.dashboardElements.productsGraph : undefined}
                            filterDaysCount={this.filterDaysCount}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const DashboardContainer = withRoutedAppContext(DashboardContainerComp);

export { DashboardContainer };
