import "./index.less";
import { AssessmentWizardStepContent } from "./components/AssessmentWizardStepContent/AssessmentWizardStepContent";
import { AtWizard, AtWizardStepHeader, IWizardStep } from "../../../AtWizard";
import { ICompDevEvaluationContext, withCompDevEvaluationContext } from "../../../../contexts";
import { ToastProvider } from "@atman/business";
import { Trans, t } from "@lingui/macro";
import { ValidationError } from "@atman/core";
import { autorun, computed, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";

export interface ICompetencyDevelopmentAssessmentWizardProps extends ICompDevEvaluationContext {}

@observer
export class CompetencyDevelopmentAssessmentWizardImpl extends React.Component<
    ICompetencyDevelopmentAssessmentWizardProps,
    {}
> {
    @observable private wizardSteps: IWizardStep[];
    @observable private observableBehaviorScoreMap: Map<string, Map<string, number | undefined>>;
    @observable private scoreValidationErrors: ValidationError[] = [];

    constructor(props: ICompetencyDevelopmentAssessmentWizardProps) {
        super(props);

        this.observableBehaviorScoreMap = new Map<string, Map<string, number | undefined>>(
            this.props.assessmentStructure!.stepInfos.map((x) => [
                x.id,
                new Map<string, number | undefined>(x.statements!.map((x) => [x.id, x.value])),
            ]),
        );

        autorun(() => {
            this.wizardSteps = this._wizardSteps;
        });
    }

    validateSubStep(competencyId: string): () => boolean {
        return () => {
            this.scoreValidationErrors.length = 0;

            const observableBehaviorScoresForCompetency = this.observableBehaviorScoreMap.get(competencyId);

            if (!observableBehaviorScoresForCompetency) {
                console.error(`Could not find scores for competency id: ${competencyId}`);
                return false;
            }

            for (const [k, v] of observableBehaviorScoresForCompetency.entries()) {
                if (!v) {
                    this.scoreValidationErrors.push({
                        fieldName: k,
                        name: k,
                        message: t({ id: "global.errors.input.empty", message: `This is a required question` }),
                    });
                }
            }

            return [...observableBehaviorScoresForCompetency.values()].every((x) => !!x);
        };
    }

    onSubStepSubmit(competencyId: string): () => Promise<boolean> {
        return async () => {
            const { saveAssessment } = this.props;

            const scores = this.observableBehaviorScoreMap.get(competencyId);

            if (!scores || [...scores.values()].some((x) => !x)) {
                console.error(`Missing scores for competency id: ${competencyId}`);
                return false;
            }

            try {
                // TODO: When notes are supported in Self/Manager App, change new Map() with actual observable value
                await saveAssessment(competencyId, scores, new Map<string, string | undefined>());

                return true;
            } catch (e) {
                console.error(e);

                const errorMessage = e.errors?.firstOrDefault()?.message ?? e.message;

                if (errorMessage) {
                    ToastProvider.error(errorMessage);
                }

                return false;
            }
        };
    }

    @computed private get _wizardSteps(): IWizardStep[] {
        const { assessmentStructure, assessmentType } = this.props;

        return [
            {
                title: t({
                    id: "competencyDevelopment.assessments.wizard.title",
                    message: `Competency Development Assessment`,
                }),
                component: null,
                subSteps: assessmentStructure!.stepInfos.map((x, i) => ({
                    title: x.name,
                    component: (
                        <div className="competency-dev-assessment-substep-container">
                            <AtWizardStepHeader stepNumber={i + 1} title={x.name} description={x.description} />
                            <AssessmentWizardStepContent
                                stepInfo={x}
                                assessmentType={assessmentType}
                                scores={this.observableBehaviorScoreMap.get(x.id)!}
                                onChange={(statementId, score) => this.onScoreChange(x.id, statementId, score)}
                                scoreValidationErrors={this.scoreValidationErrors}
                            />
                        </div>
                    ),
                    validateStep: this.validateSubStep(x.id),
                    additionalOnSubmit: this.onSubStepSubmit(x.id),
                })),
            },
        ];
    }

    onScoreChange = (competencyId: string, statementId: string, score: number): void => {
        const observableBehaviorScoresForCompetency = this.observableBehaviorScoreMap.get(competencyId);

        if (observableBehaviorScoresForCompetency) {
            observableBehaviorScoresForCompetency.set(statementId, score);
        }
    };

    formatSubStepProgressLabel = (current: number, total: number) => {
        return (
            <Trans id={"competencyDevelopment.assessments.wizard.subStepProgressLabel"}>
                {current} out of {total} competencies completed
            </Trans>
        );
    };

    onSubmit = async (): Promise<void> => undefined;

    render(): JSX.Element {
        const { assessmentStructure } = this.props;

        let defaultStepValues: { currentStep: number; currentSubStep: number } | undefined;

        const completedSteps = assessmentStructure!.stepInfos.filter((x) => x.statements!.every((s) => !!s.value));

        if (completedSteps.any()) {
            const lastCompletedStep = completedSteps.lastOrDefault();

            defaultStepValues = {
                currentStep: 1,
                currentSubStep: assessmentStructure!.stepInfos.findIndex((x) => x.id === lastCompletedStep?.id) + 2,
            };
        }

        return (
            <div id="CompetencyDevelopmentAssessmentWizard">
                <AtWizard
                    steps={this.wizardSteps}
                    onSubmit={this.onSubmit}
                    defaultStepValues={defaultStepValues}
                    formatSubStepProgressLabel={this.formatSubStepProgressLabel}
                />
            </div>
        );
    }
}

export const CompetencyDevelopmentAssessmentWizard = withCompDevEvaluationContext(
    CompetencyDevelopmentAssessmentWizardImpl,
);
