import * as React from "react";
import { ConfigurationProvider, Environment } from "../../utils";
import { GlobalStores } from "../../stores";
import { I18nProvider } from "@lingui/react";
import { LocalizationStore } from "../../stores/LocalizationStore";
import { Messages } from "@lingui/core";
import { autorun, observable } from "mobx";
import { en, fr } from "make-plural/plurals";
import { i18n } from "@lingui/core";
import { inject, observer } from "mobx-react";

export interface IStandaloneI18nLoaderProps {
    getAppMessages: (locale: string) => Promise<{ messages: Messages }>;
    localizationStore?: LocalizationStore;
}

@inject(GlobalStores.localizationStore)
@observer
export class StandaloneI18nLoader extends React.Component<IStandaloneI18nLoaderProps, {}> {
    @observable catalogs: Messages = {};

    constructor(props) {
        super(props);

        autorun(() => {
            this.loadDynamicLocales(this.props.localizationStore!.currentShortLocale);
        });
    }

    loadDynamicLocales = async (locale: string) => {
        const { getAppMessages } = this.props;

        const allImports: Promise<{ messages: Messages }>[] = [getAppMessages(locale)];

        if (ConfigurationProvider.currentEnvironment === Environment.Local) {
            allImports.push(...this.getGlobalPackagesPromisesFromLinguiLoader(locale));
        } else {
            allImports.push(...this.getGlobalPackagesPromisesFromCompiledFiles(locale));
        }

        const results = await Promise.all(allImports);

        const messages = results.reduce((prev, curr) => ({ ...prev, ...curr.messages }), {}) as Messages;

        i18n.load(locale, messages);
        i18n.activate(locale);
        i18n.loadLocaleData({
            en: { plurals: en },
            fr: { plurals: fr },
        });
    };

    getGlobalPackagesPromisesFromLinguiLoader = (locale: string) => {
        return [
            import(
                `@lingui/loader?config=${__dirname}/../../../../../lingui.config.js!./../../../../../../../../localization/src/global/messages.${locale}.po`
            ),
        ];
    };

    getGlobalPackagesPromisesFromCompiledFiles = (locale: string) => {
        return [
            import(
                /* webpackMode: "lazy" */
                `../../../../../../../../localization/src/global/messages.${locale}.js`
            ),
        ];
    };

    render() {
        const { children } = this.props;

        return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
    }
}
