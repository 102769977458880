import "./index.less";
import * as React from "react";
import { AtButton, LoadingIndicator } from "@atman/design-system";
import { AtmanCoPlatformsEnum } from "../../../global/components/ProductSelector";
import { AuthStore } from "../../../data/stores/AuthStore";
import { Col, Form, Row } from "reactstrap";
import { CustomValidatedTextInput, useErrorHandling } from "@atman/ui-kit";
import { LoginAppStores } from "../../../../index";
import { PasswordRule } from "@atman/ui-kit/lib/components/ChangePasswordForm/components/PasswordRule";
import { SystemStateStore } from "../../../data/stores/SystemStateStore";
import { ToastProvider, Validator } from "@atman/business";
import { Trans, t } from "@lingui/macro";
import { UnavailableSystem } from "../../../global/components/UnavailableSystem/UnavailableSystem";
import { inject, observer } from "mobx-react";
import { parseError } from "@atman/core";
import { useHistory, useLocation } from "react-router";
import { useMemo, useState } from "react";
import LoginComponentHeader from "../../../global/components/LoginComponentHeader";

export interface IResetPasswordViewProps {
    authStore?: AuthStore;
    systemStateStore?: SystemStateStore;
}

export const ResetPasswordView: React.FC<IResetPasswordViewProps> = inject((stores: LoginAppStores) => ({
    authStore: stores.authStore,
    systemStateStore: stores.systemStateStore,
}))(
    observer((props) => {
        const { authStore, systemStateStore } = props;

        const { errors, addErrors, clearErrors } = useErrorHandling();
        const [newPassword, setNewPassword] = useState<string>("");
        const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
        const [isLoading, setIsLoading] = useState<boolean>(false);

        const history = useHistory();
        const { search } = useLocation();

        const { available, activeState } = useMemo(
            () => systemStateStore!.getStateForPlatform(AtmanCoPlatformsEnum.ProNA),
            [systemStateStore!.systemStateInfos],
        );

        const loginAccessToken = useMemo(() => new URLSearchParams(search).get("token"), [search]);

        if (!loginAccessToken) {
            history.push("/Unauth/Login");
            return null;
        }

        const validateForm = (): boolean => {
            clearErrors();

            const validationErrors = Validator.validatePassword(newPassword, newPasswordConfirm);

            if (!validationErrors.any()) {
                return true;
            }

            addErrors(...validationErrors);
            return false;
        };

        const onSave = async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            if (!validateForm()) {
                return;
            }

            setIsLoading(true);

            try {
                await authStore!.resetPassword(loginAccessToken, newPassword);

                setIsLoading(false);

                ToastProvider.success(
                    t({
                        id: "loginApp.resetPasswordForm.confirmationSuccessMessage",
                        message: "Your password has been reset successfully, please login with your new information.",
                    }),
                );

                history.push("/Unauth/Login");
            } catch (e) {
                console.log(e);
                parseError(e).forEach((x) => ToastProvider.error(x.message));
                setIsLoading(false);
            }
        };

        return (
            <div id="ResetPasswordView">
                <LoginComponentHeader
                    title={t({ id: "loginApp.resetPasswordForm.title", message: "Password Reset" })}
                    icon={["far", "key"]}
                />
                {!systemStateStore!.hasLoadedSystemState ? (
                    <LoadingIndicator
                        size={"md"}
                        label={t({
                            id: "loginApp.loginForm.loadingSystemStatus",
                            message: "Making sure the system is available...",
                        })}
                    />
                ) : available ? (
                    <Form onSubmit={onSave}>
                        <div className="password-rules">
                            <Row noGutters>
                                <Col xs={12} sm={6}>
                                    <PasswordRule
                                        passwordValue={newPassword}
                                        ruleType="lowercaseChar"
                                        ruleLabelOverride={t({
                                            id: "global.passwordRules.lowercaseChar",
                                            message: "Lowercase character",
                                        })}
                                    />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <PasswordRule
                                        passwordValue={newPassword}
                                        ruleType="uppercaseChar"
                                        ruleLabelOverride={t({
                                            id: "global.passwordRules.uppercaseChar",
                                            message: "Uppercase character",
                                        })}
                                    />
                                </Col>
                            </Row>
                            <Row noGutters>
                                <Col xs={12} sm={6}>
                                    <PasswordRule
                                        passwordValue={newPassword}
                                        ruleType="numberChar"
                                        ruleLabelOverride={t({
                                            id: "global.passwordRules.numberChar",
                                            message: "A number",
                                        })}
                                    />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <PasswordRule
                                        passwordValue={newPassword}
                                        ruleType="minLength"
                                        ruleLabelOverride={t({
                                            id: "global.passwordRules.minLength",
                                            message: "At least 8 characters",
                                        })}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <CustomValidatedTextInput
                            fieldName="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.currentTarget.value)}
                            type="password"
                            validationErrors={errors}
                            autoComplete="new-password"
                            label={t({ id: "global.inputs.labels.newPassword", message: "New Password" })}
                        />
                        <CustomValidatedTextInput
                            fieldName="newPasswordConfirm"
                            value={newPasswordConfirm}
                            onChange={(e) => setNewPasswordConfirm(e.currentTarget.value)}
                            type="password"
                            validationErrors={errors}
                            autoComplete="new-password"
                            label={t({
                                id: "global.inputs.labels.newPasswordConfirm",
                                message: "New Password Confirmation",
                            })}
                        />
                        <AtButton size="lg" isLoading={isLoading} type="submit" fullWidth>
                            <Trans id="global.buttons.labels.confirm">Confirm</Trans>
                        </AtButton>
                    </Form>
                ) : (
                    <UnavailableSystem activeSystemState={activeState!} />
                )}
            </div>
        );
    }),
);
