import "./index.less";
import { AtButton, AtContainer, AtTitle, DefaultSizes, IAtButtonProps } from "../../../internal";
import React from "react";
import cn from "classnames";

export interface IAtTabCardProps extends React.HTMLProps<HTMLDivElement> {
    cardTitle: string;
    leftHeaderContent?: React.ReactNode;
    headerButtonProps?: IAtButtonProps[];
    rightHeaderContent?: React.ReactNode;
    paddingSize?: DefaultSizes;
}

export const AtTabCard: React.FC<IAtTabCardProps> = (props) => {
    const {
        cardTitle,
        className,
        id,
        leftHeaderContent,
        headerButtonProps,
        rightHeaderContent,
        paddingSize = "md",
        children,
        ...divProps
    } = props;

    return (
        <AtContainer
            className={cn("AtTabCard", `padding-${paddingSize}`, className)}
            header={
                <>
                    <div className="left-section d-flex align-items-center">
                        {leftHeaderContent}
                        <AtTitle title={cardTitle} headingType={4} noMargin />
                    </div>
                    {rightHeaderContent
                        ? rightHeaderContent
                        : headerButtonProps && (
                              <div className="buttons">
                                  {headerButtonProps
                                      ? headerButtonProps.map((x, i) => <AtButton key={i} {...x} />)
                                      : null}
                              </div>
                          )}
                </>
            }
            id={id}
            {...divProps}
        >
            <div className="content">{children}</div>
        </AtContainer>
    );
};
