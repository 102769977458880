import "./PartnerAccountInformationForm.less";
import { AtCheckbox, AtSelect, AtTabCard, IReactSelectOptionObject } from "@atman/design-system";
import { AtSimpleSelect } from "@atman/design-system/lib/components/molecules/AtSimpleSelect";
import { BaseForm } from "../../../../components/BaseForm";
import { CardSaveButtons } from "../../../../components/CardSaveButtons";
import { Col, Label, Row } from "reactstrap";
import {
    GlobalStores,
    IndustryEnum,
    Partner,
    PartnerStore,
    PlatformUseEnum,
    ReactAppSettings,
    getEnumValues,
} from "@atman/business";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import autobind from "autobind-decorator";

export interface IPartnerAccountInformationFormProps {
    entity: Partner;
    partnerStore?: PartnerStore;
}

@inject(GlobalStores.partnerStore)
@observer
export class PartnerAccountInformationForm extends BaseForm<IPartnerAccountInformationFormProps, {}> {
    @observable public platformUse: PlatformUseEnum[] = [];
    @observable public marketingType: IndustryEnum = IndustryEnum.Reseller;
    @observable public salesRepOwnerId?: IReactSelectOptionObject;

    componentDidMount(): void {
        this.resetAccountInformation();
    }

    @action.bound
    resetAccountInformation = () => {
        const { entity } = this.props;

        if (entity.marketingInfo) {
            this.platformUse = entity.marketingInfo.platformUses || [];
            this.marketingType = entity.marketingInfo.marketingType;
        }

        const salesRep = ReactAppSettings.appModel.availableSalesReps.find((x) => x.id === entity.ownerId);

        if (salesRep) {
            this.salesRepOwnerId = {
                value: salesRep.id,
                label: salesRep.fullName,
            };
        }
    };

    @autobind
    async _onSave() {
        const { partnerStore, entity } = this.props;

        const marketingInfo = {
            ...entity.marketingInfo,
            marketingType: this.marketingType,
            platformUses: this.platformUse,
        };

        await partnerStore!.updatePartnerAdditionalInformation(
            entity.id,
            marketingInfo,
            this.salesRepOwnerId ? this.salesRepOwnerId.value : undefined,
        );
    }

    validateForm(): boolean {
        return true;
    }

    @action.bound
    onPlatformUseClick = (event: React.FormEvent<HTMLInputElement>) => {
        const platformUse = Number((event.target as any).value);
        const index = this.platformUse.indexOf(platformUse);

        if (index >= 0) {
            this.platformUse.splice(index, 1);
        } else {
            this.platformUse.push(platformUse);
        }
    };

    @autobind
    renderCheckboxes() {
        return Object.keys(PlatformUseEnum)
            .filter((x) => !isNaN(Number(x)))
            .map((x, i) => (
                <AtCheckbox
                    id={`${PlatformUseEnum[Number(x)]}-platform-use`}
                    fieldName={"platformUse"}
                    checked={this.platformUse.some((use) => use === Number(x))}
                    onChange={this.onCheckboxesChange}
                    value={Number(x)}
                    key={i}
                >
                    {`global.platformUses.${PlatformUseEnum[Number(x)].toCamel()}`.localize()}
                </AtCheckbox>
            ));
    }

    @action.bound
    onSalesRepChange = (option: any) => {
        this.salesRepOwnerId = option;
    };

    render() {
        const { partnerStore } = this.props;

        const salesRepOptions: IReactSelectOptionObject[] = ReactAppSettings.appModel.availableSalesReps.map((x) => ({
            value: x.id,
            label: x.fullName,
        }));

        return (
            <AtTabCard cardTitle={"global.accountInformation".localize()} id={"PartnerAccountInformationForm"}>
                <Row>
                    <Col sm={6}>
                        <AtSimpleSelect
                            name={"marketingType"}
                            label={"supplierApp.marketingType".localize()}
                            getOptionLabel={(x) => `global.industries.${IndustryEnum[Number(x)].toCamel()}`.localize()}
                            options={getEnumValues<IndustryEnum>(IndustryEnum)}
                            value={this.marketingType}
                            onChange={(o) => this.onAutoCompleteChange(o, "marketingType", true)}
                        />
                    </Col>
                    <Col sm={6}>
                        <AtSelect
                            label={"global.owner".localize()}
                            options={salesRepOptions}
                            value={this.salesRepOwnerId}
                            onChange={this.onSalesRepChange}
                        />
                    </Col>
                    <Col sm={6}>
                        <Label className={"platform-use-label"}>{"global.platformUse".localize()}</Label>
                        <div className="platform-use-container">{this.renderCheckboxes()}</div>
                    </Col>
                </Row>
                <CardSaveButtons
                    saveAction={this._onSave}
                    cancelAction={this.resetAccountInformation}
                    isLoading={partnerStore!.isLoading}
                    disabledSaveButton={!this.validateForm()}
                />
            </AtTabCard>
        );
    }
}
