import "./EntityOverviewContainer.less";
import * as React from "react";
import { AppScope, Client, ClientStore, GlobalStores, Partner, PartnerStore, UserStore } from "@atman/business";
import { AtTabNavigationLayout, BillingInformationForm, ProductBalanceView } from "../../components";
import { EntityInformation } from "./informations/EntityInformation";
import { EntityOverview } from "./overview/EntityOverview";
import { EntityOverviewHeader } from "./components/EntityOverviewHeader";
import { EntitySettingsForm } from "./settings/EntitySettingsForm";
import { EntityUsers } from "./users/EntityUsers";
import { IAtTabNavigationItem } from "../../components/AtTabNavigationLayout";
import { IRoutedAppContext, withRoutedAppContext } from "../../contexts";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";

export interface IEntityOverviewContainerProps extends IRoutedAppContext<{ id: string }> {
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
    userStore?: UserStore;
}

@inject(GlobalStores.clientStore, GlobalStores.partnerStore, GlobalStores.userStore)
@observer
class EntityOverviewContainerComp extends React.Component<IEntityOverviewContainerProps> {
    async componentDidMount() {
        await this.props.userStore!.loadEntityUsers(this.props.match.params.id);
    }

    @computed
    get entity() {
        switch (this.props.scope) {
            case AppScope.Partner:
                return this.props.clientStore!.getClientById(this.props.match.params.id) as Client;
            case AppScope.Supplier:
                return this.props.partnerStore!.getPartnerById(this.props.match.params.id) as Partner;
            default:
                throw new Error(`Unhandled scope for view: ${this.props.scope}`);
        }
    }

    @computed
    get ownerBillingInformation() {
        switch (this.props.scope) {
            case AppScope.Partner:
                const client = this.entity as Client;
                return client.billingInformation;
            case AppScope.Supplier:
                const partner = this.entity as Partner;
                return partner.billingInformation;
            default:
                throw new Error(`Unhandled scope for view: ${this.props.scope}`);
        }
    }

    render() {
        const { match, scope } = this.props;

        if (!this.entity) {
            return null;
        }

        let productBalanceViewScopeOverride: AppScope;

        switch (scope) {
            case AppScope.Partner:
                productBalanceViewScopeOverride = AppScope.Client;
                break;
            case AppScope.Supplier:
                productBalanceViewScopeOverride = AppScope.Partner;
                break;
            default:
                throw new Error(`Unhandled scope for view: ${this.props.scope}`);
        }

        const navigationItems: IAtTabNavigationItem[] = [
            {
                label: "global.overview".localize(),
                iconName: ["far", "address-card"] as IconProp,
                component: <EntityOverview entity={this.entity} />,
            },
            {
                label: "global.information".localize(),
                iconName: ["far", "building"] as IconProp,
                component: <EntityInformation entity={this.entity} />,
            },
            {
                label: "global.users".localize(),
                iconName: ["far", "users"] as IconProp,
                component: <EntityUsers entity={this.entity} />,
            },
            {
                label: "global.billing".localize(),
                iconName: ["far", "address-book"] as IconProp,
                component: (
                    <BillingInformationForm
                        ownerId={match.params.id}
                        currentBillingInformation={this.ownerBillingInformation}
                    />
                ),
            },
            {
                label: "global.settings".localize(),
                iconName: ["far", "cogs"] as IconProp,
                component: <EntitySettingsForm entity={this.entity} />,
                navItemId: "entity-overview-settings-nav-item",
            },
        ];

        return (
            <div id="EntityOverviewContainer">
                <EntityOverviewHeader ownerName={this.entity.name} ownerId={this.entity.id} />
                <div className="page-content flex-column-reverse flex-lg-row">
                    <div className="overview">
                        <AtTabNavigationLayout navigationItems={navigationItems} />
                    </div>
                    <div className="sidebar">
                        <ProductBalanceView
                            usageModel={this.entity.usageModel}
                            ownerId={match.params.id}
                            scopeOverride={productBalanceViewScopeOverride}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const EntityOverviewContainer = withRoutedAppContext(EntityOverviewContainerComp);

export { EntityOverviewContainer };
