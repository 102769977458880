import "./AtEditableElement.less";
import * as React from "react";
import { AtBadge } from "@atman/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface IAtEditableElementProps {
    value: string;
    label: string;
    icon?: IconProp;
    removeAction: (id: string) => void;
}

const AtEditableElement: React.FC<IAtEditableElementProps> = (props) => {
    const { icon, label, value, removeAction } = props;

    return (
        <AtBadge className="AtEditableElement" size={"lg"} badgeStyle={"outline"}>
            {icon && (
                <div className="icon-container">
                    <FontAwesomeIcon icon={icon} size={"sm"} />
                </div>
            )}
            <div className="label">{label}</div>
            <div className="close-icon-container" onClick={() => removeAction(value)}>
                <FontAwesomeIcon icon={["far", "times"]} size={"sm"} />
            </div>
        </AtBadge>
    );
};

export default AtEditableElement;
