import { CheckoutProcessEnum, ProductCodeProEnum } from "../types";

export abstract class BaseCart {
    public type: "purchase" | "sale";
    public balance: Dictionary<ProductCodeProEnum, number>;
    public selectedPackageSku?: string;

    protected constructor(cart: BaseCart) {
        this.type = cart.type;
        this.balance = cart.balance;
        this.selectedPackageSku = cart.selectedPackageSku;
    }
}

export class AttributeCreditsCart extends BaseCart {
    public ownerId: string;

    constructor(cart: AttributeCreditsCart) {
        super(cart);

        this.ownerId = cart.ownerId;
    }

    public get isEmpty(): boolean {
        return !Object.keys(this.balance).some((x) => this.balance[x] > 0);
    }
}

export class AddCreditsCart extends BaseCart {
    public checkoutProcess: CheckoutProcessEnum = CheckoutProcessEnum.PayLater;

    constructor(cart: AddCreditsCart) {
        super(cart);

        this.checkoutProcess = cart.checkoutProcess;
    }

    public get isCustomPackage(): boolean {
        return !this.selectedPackageSku;
    }

    public get isEmpty(): boolean {
        return !Object.keys(this.balance).some((x) => this.balance[x] > 0);
    }
}
