import "./index.less";
import { AtSubtitle, AtTitle, DefaultSizes, HeadingType } from "../../../internal";
import React, { FC, useMemo } from "react";

export interface IAtLabelTitleProps {
    label: string;
    title: string | undefined;
    labelAsSubtitle?: boolean;
    size?: DefaultSizes;
    className?: string;
}

export const AtLabelTitle: FC<IAtLabelTitleProps> = (props) => {
    const { label, title = "-", labelAsSubtitle = false, size = "md", className, ...otherProps } = props;

    const labelSize: DefaultSizes | HeadingType = useMemo(() => {
        if (labelAsSubtitle) {
            if (size === "sm") {
                return "md";
            } else if (size === "md") {
                return "md";
            } else {
                return "lg";
            }
        } else {
            if (size === "sm") {
                return 6;
            } else if (size === "md") {
                return 6;
            } else {
                return 5;
            }
        }
    }, [labelAsSubtitle, size]);

    const titleSize = useMemo(() => {
        if (size === "sm") {
            return 4;
        } else if (size === "md") {
            return 3;
        } else {
            return 2;
        }
    }, [size]);

    return (
        <div className={`AtLabelTitle ${className ?? ""}`} {...otherProps}>
            <span className="label">
                {labelAsSubtitle ? (
                    <AtSubtitle size={labelSize as DefaultSizes} subtitle={label} />
                ) : (
                    <AtTitle headingType={labelSize as HeadingType} title={label} noMargin />
                )}
            </span>
            <span className="title">
                <AtTitle headingType={titleSize} title={title} noMargin />
            </span>
        </div>
    );
};
