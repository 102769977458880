import "./index.less";
import { AtContainer, AtPersonLabel, DefaultSizes, Sizing } from "@atman/design-system";
import { AtPrintButton, PrintReportParams } from "..";
import {
    Candidate,
    FileHelper,
    GlobalStores,
    ReportSubjectType,
    ScopedOrganizationStore,
    Team,
    UrlFormatter,
} from "@atman/business";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import React, { useMemo } from "react";

export interface IAtStylePersonCard {
    groupStyleId: string;
    candidate?: Candidate;
    team?: Team;
    onClick?: () => void;
    size?: DefaultSizes;
    csvDownload?: React.ReactNode;
    scopedOrganizationStore?: ScopedOrganizationStore;
}

export const AtStylePersonCard: React.FC<IAtStylePersonCard> = inject(GlobalStores.scopedOrganizationStore)(
    observer((props) => {
        const { candidate, groupStyleId, team, size, csvDownload, scopedOrganizationStore, ...otherProps } = props;

        const getPrintReportParams = (): PrintReportParams => {
            const leftTitle = "{webPrintReports.reportTitles.styles}";
            const leftSubtitle = `AtmanCo • ${scopedOrganizationStore!.scopedOrganization.name}`;

            if (candidate) {
                return {
                    urls: [
                        UrlFormatter.getReportUrl("PersonalityStyles", ReportSubjectType.Candidate, [
                            groupStyleId,
                            candidate?.id,
                        ]),
                    ],
                    leftTitle,
                    leftSubtitle,
                    rightTitle: candidate.displayName,
                };
            }

            if (team) {
                return {
                    urls: [
                        UrlFormatter.getReportUrl("PersonalityStyles", ReportSubjectType.Team, [
                            groupStyleId,
                            team?.id,
                        ]),
                    ],
                    leftTitle,
                    leftSubtitle,
                    rightTitle: team.name,
                };
            }

            throw new Error("No team or candidate is loaded");
        };

        const reportName = useMemo(() => {
            if (candidate) {
                return `${FileHelper.removeInvalidFileNameChars(
                    candidate.displayName.replaceAll(" ", "_"),
                )}-${"clientApp.atmanStyles.styles".localize()}`;
            }

            if (team) {
                return `${FileHelper.removeInvalidFileNameChars(
                    team.name.replaceAll(" ", "_"),
                )}-${"clientApp.atmanStyles.styles".localize()}`;
            }

            return "";
        }, [candidate, team]);

        const title = useMemo(() => {
            if (candidate) {
                return candidate.firstName;
            }
            if (team) {
                return team.name;
            }
            return undefined;
        }, [candidate, team]);

        const subTitle = useMemo(() => {
            if (candidate) {
                return candidate.jobName;
            }
            if (team) {
                return `${team.members?.length} ${t({ id: "clientApp.members" })}`;
            }
            return undefined;
        }, [candidate, team]);

        const options = useMemo(() => {
            // We offer anonymization just for teams reports
            if (team) {
                return [
                    {
                        label: t({ id: "global.print.options.reportType" }),
                        key: "isAnonymized",
                        options: [
                            { value: "false", label: t({ id: "global.print.options.reportType.complete" }) },
                            { value: "true", label: t({ id: "global.print.options.reportType.anonymized" }) },
                        ],
                    },
                ];
            }

            return undefined;
        }, [team]);

        return (
            <div className="AtStylePersonCard" {...otherProps}>
                <AtContainer padding={Sizing.paddings.sm}>
                    <AtPersonLabel
                        firstName={title}
                        lastName={candidate?.lastName}
                        description={subTitle}
                        pictureUri={candidate?.profilePictureUri}
                        size={size}
                        {...otherProps}
                    />

                    <>
                        {csvDownload}
                        {candidate !== undefined || team !== undefined ? (
                            <AtPrintButton
                                getPrintReportParams={getPrintReportParams}
                                configurableOptions={options}
                                reportNamePrefix={reportName}
                            />
                        ) : null}
                    </>
                </AtContainer>
            </div>
        );
    }),
);
