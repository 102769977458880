import {
    AppScope,
    CurrencyEnum,
    IProductConsumptionModeScheduleEntryModel,
    IStripeCustomerCard,
    ProRestrictionType,
    ProductCodeProEnum,
    ProductConsumptionMode,
    PurchaseOptionEnum,
} from "../types";
import { BasePlatformRootStore } from "../base/BasePlatformRootStore";
import { BaseStore } from "../base/BaseStore";
import { ClientsApi, PartnersApi, SuppliersApi } from "../apis";
import { IProductDefinition, IProductSkuDetails } from "../appLogic/ProductDefinition";
import { Logger, ReactAppSettings, ToastProvider } from "../libs";
import { UnhandledScopeError } from "../base";
import { UsageModel } from "../models";
import { action, computed, observable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";
import moment from "moment";

export class ProductBalanceStore extends BaseStore {
    protected readonly scope: AppScope;

    @observable public usageModelId: string;
    @observable public ownerId: string;
    @observable public balance: Dictionary<ProductCodeProEnum, number>;
    @observable public consumptionModeSchedule: IProductConsumptionModeScheduleEntryModel[];
    @observable public negativeBalanceThreshold: Dictionary<ProductCodeProEnum, number>;
    @observable public productRestriction: ProductCodeProEnum[] = [];
    @observable public restrictionType: ProRestrictionType;
    @observable public discountRate: number;

    @observable public availableProductDefinitions: IProductDefinition[] = [];
    @observable public purchaseOptions: PurchaseOptionEnum[] = ReactAppSettings.appModel.purchaseOptions || [];
    @observable public customerCards: IStripeCustomerCard[] = [];

    @computed public get availableProductSkus(): IProductSkuDetails[] {
        return this.availableProductDefinitions.reduce(
            (previousValue, currentValue) => [...previousValue, ...currentValue.skus],
            [] as IProductSkuDetails[],
        );
    }

    constructor(rootStore: BasePlatformRootStore) {
        super(rootStore);
        this.scope = rootStore.scope;
    }

    @action
    loadUsageModel = async () => {
        await this.tryCatch(async () => {
            let usageModel: UsageModel;

            switch (this.scope) {
                case AppScope.Client:
                    usageModel = await ClientsApi.getUsageModel(this.rootStore.userInfoStore.clientId);
                    break;
                case AppScope.Partner:
                    usageModel = await PartnersApi.getUsageModel(`${this.rootStore.userInfoStore.partnerId}`);
                    break;
                default:
                    return;
            }

            runInAction(() => {
                this.usageModelId = usageModel.id;
                this.ownerId = usageModel.ownerId;
                this.balance = usageModel.productBalance;
                this.consumptionModeSchedule = usageModel.productConsumptionModeSchedule;
                this.negativeBalanceThreshold = usageModel.negativeBalanceThreshold;
                this.productRestriction = usageModel.productRestriction;
                this.restrictionType = usageModel.restrictionType;
                this.discountRate = usageModel.discountRate;
            });
        });
    };

    @action
    public getUsageModelForEntityId = async (entityId: string) => {
        return await this.tryCatch(async () => {
            let usageModel: UsageModel;

            switch (this.scope) {
                case AppScope.Partner:
                    usageModel = await ClientsApi.getUsageModel(entityId);
                    break;
                case AppScope.Supplier:
                    usageModel = await PartnersApi.getUsageModel(entityId);
                    break;
                default:
                    throw new UnhandledScopeError(this.scope);
            }

            return usageModel;
        });
    };

    public getBalanceForProduct: (productCode: ProductCodeProEnum) => number = computedFn(
        (productCode: ProductCodeProEnum) => {
            if (this.isUnlimited) {
                return Infinity;
            }

            if (this.balance && this.balance.hasOwnProperty(productCode)) {
                return this.balance[productCode];
            }

            return 0;
        },
    );

    @action
    public debitProducts = (productCode: ProductCodeProEnum, quantity: number) => {
        if (!this.balance.hasOwnProperty(productCode)) {
            Logger.log(
                `Cannot debit product (${ProductCodeProEnum[productCode]}) because the balance doesn't have any.`,
            );
            return;
        }

        this.balance[productCode] = Number(this.balance[productCode]) - Number(quantity);
    };

    @action
    public creditProducts = (productCode: ProductCodeProEnum, quantity: number) => {
        if (this.balance.hasOwnProperty(productCode)) {
            this.balance[productCode] = Number(this.balance[productCode]) + Number(quantity);
        } else {
            this.balance[productCode] = Number(quantity);
        }
    };

    public loadAvailableProductDefinitions = async () => {
        return await this.tryCatch(async () => {
            let productDefinitions: IProductDefinition[];

            switch (this.scope) {
                case AppScope.Client:
                    productDefinitions = await ClientsApi.getAvailableProductDefinitions(
                        this.rootStore.userInfoStore.clientId,
                    );
                    break;
                case AppScope.Partner:
                    productDefinitions = await PartnersApi.getAvailableProductDefinitions(
                        this.rootStore.userInfoStore.partnerId,
                    );
                    break;
                case AppScope.Supplier:
                    productDefinitions = await SuppliersApi.getAvailableProductDefinitions(
                        this.rootStore.userInfoStore.supplierId,
                    );
                    break;
            }

            runInAction(() => {
                this.availableProductDefinitions = productDefinitions.sort((a, b) => {
                    if (a.isBundle && !b.isBundle) {
                        return -1;
                    }

                    if (!a.isBundle && b.isBundle) {
                        return 1;
                    }

                    if (a.isBundle && b.isBundle) {
                        return 0;
                    }

                    return a.productCode < b.productCode ? -1 : 1;
                });
            });
        });
    };

    @action
    public loadCustomerCards = async () => {
        return await this.tryCatch(async () => {
            let result: { cards: IStripeCustomerCard[]; mostRecentlyUsedCard?: IStripeCustomerCard };

            switch (this.scope) {
                case AppScope.Client:
                    result = await ClientsApi.getStripeCustomer(this.rootStore.userInfoStore.clientId);
                    break;
                case AppScope.Partner:
                    result = await PartnersApi.getStripeCustomer(this.rootStore.userInfoStore.partnerId);
                    break;
                case AppScope.Supplier:
                    result = {
                        cards: [],
                    };
                    break;
            }

            runInAction(() => {
                if (result && result.cards) {
                    this.customerCards = result.cards;
                }
            });
        });
    };

    @action
    public addCustomerCard = async (cardToken: string, cardLabel: string) => {
        return await this.tryCatch(
            async () => {
                let card: IStripeCustomerCard;

                switch (this.scope) {
                    case AppScope.Client:
                        card = await ClientsApi.addCustomerCard(
                            this.rootStore.userInfoStore.clientId,
                            cardToken,
                            cardLabel,
                        );
                        break;
                    case AppScope.Partner:
                        card = await PartnersApi.addCustomerCard(
                            this.rootStore.userInfoStore.partnerId,
                            cardToken,
                            cardLabel,
                        );
                        break;
                }

                ToastProvider.success("global.cardAddedSuccessfully".localize());

                runInAction(() => {
                    this.customerCards.push(card);
                });
            },
            undefined,
            true,
        );
    };

    @action
    public updateCustomerCard = async (cardId: string, card: IStripeCustomerCard) => {
        const index = this.customerCards.findIndex((x) => x.cardId === cardId);

        if (index >= 0) {
            await this.tryCatch(
                async () => {
                    switch (this.scope) {
                        case AppScope.Client:
                            await ClientsApi.updateCustomerCard(this.rootStore.userInfoStore.clientId, card);
                            break;
                        case AppScope.Partner:
                            await PartnersApi.updateCustomerCard(this.rootStore.userInfoStore.partnerId, card);
                            break;
                    }

                    ToastProvider.success("global.cardUpdatedSuccessfully".localize());

                    runInAction(() => {
                        this.customerCards[index] = card;
                    });
                },
                undefined,
                true,
            );
        }
    };

    @action
    public deleteCustomerCard = async (cardId: string) => {
        const index = this.customerCards.findIndex((x) => x.cardId === cardId);

        if (index >= 0) {
            await this.tryCatch(
                async () => {
                    switch (this.scope) {
                        case AppScope.Client:
                            await ClientsApi.deleteCustomerCard(this.rootStore.userInfoStore.clientId, cardId);
                            break;
                        case AppScope.Partner:
                            await PartnersApi.deleteCustomerCard(this.rootStore.userInfoStore.partnerId, cardId);
                            break;
                    }

                    ToastProvider.success("global.cardDeletedSuccessfully".localize());

                    runInAction(() => {
                        this.customerCards.splice(index, 1);
                    });
                },
                undefined,
                true,
            );
        }
    };

    public getProductForCustomPackage: (
        productCode: ProductCodeProEnum,
        quantity: number,
    ) => IProductSkuDetails | undefined = computedFn((productCode: ProductCodeProEnum, quantity: number) => {
        const customPackages = this.availableProductSkus
            .filter((x) => x.sku.includes("CUSTOM") && x.productCode === productCode)
            .sort((a, b) => {
                const customMinA = Number(a.sku.split("-CUSTOM-")[1]);
                const customMinB = Number(b.sku.split("-CUSTOM-")[1]);

                if (customMinA < customMinB) return 1;
                if (customMinB < customMinA) return -1;

                return 0;
            });

        return customPackages.find((x) => {
            const customMin = Number(x.sku.split("-CUSTOM-")[1]);

            return quantity >= customMin;
        });
    });

    public getUnitPriceForCustomPackage: (
        productCode: ProductCodeProEnum,
        quantity: number,
        currency: CurrencyEnum,
    ) => number = computedFn((productCode: ProductCodeProEnum, quantity: number, currency: CurrencyEnum) => {
        return this.getProductForCustomPackage(productCode, quantity)!.prices.find((x) => x.currency === currency)!
            .value;
    });

    @computed
    public get currentScheduleEntry(): IProductConsumptionModeScheduleEntryModel | undefined {
        if (!this.consumptionModeSchedule || this.consumptionModeSchedule.length === 0) {
            return;
        }

        const now = moment();

        const activePeriod = this.consumptionModeSchedule.filter((x) => {
            return (
                now.isAfter(moment.parseZone(x.effectiveDate).startOf("D")) &&
                (!x.endDate || now.isBefore(moment.parseZone(x.endDate)))
            );
        });

        if (activePeriod.length === 0) {
            return;
        }

        return activePeriod[0];
    }

    @computed
    public get isUnlimited(): boolean {
        const activePeriod = this.currentScheduleEntry;

        if (!activePeriod) {
            return false;
        }

        return activePeriod.consumptionMode === ProductConsumptionMode.Unlimited;
    }

    @computed
    public get isBlocked(): boolean {
        const activePeriod = this.currentScheduleEntry;

        if (!activePeriod) {
            return false;
        }

        return activePeriod.consumptionMode === ProductConsumptionMode.Blocked;
    }
}
