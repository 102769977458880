import { useMemo } from "react";
import { v4 } from "uuid";

export const useUniqueId = (id?: string): { uniqueId: string } => {
    const uniqueId = useMemo(() => {
        if (id) {
            return id;
        }

        return `id-${v4()}`;
    }, [id]);

    return { uniqueId };
};
