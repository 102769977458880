import * as qs from "qs";
import { ApiManager, ReactAppSettings, UrlFormatter } from "../libs";
import { BaseApi, DataType } from "../base";
import {
    CompetencyProfile,
    HttpMethodEnum,
    IAddProductConsumptionModeScheduleEntryModel,
    IContactInformation,
    IDashboardElements,
    IDashboardFilters,
    IProductBalanceCreditCardRequestModel,
    IProductBalanceRequestModel,
    IProductConsumptionModeScheduleEntryModel,
    IRemoveProductConsumptionModeScheduleEntryModel,
    ISalesRep,
    IStripeCustomerCard,
    ITransactionLogFilters,
    IUpdateBillingAddressCommand,
    IUpdatePartnerAdditionalInformationModel,
    IUpdateProductConsumptionModeScheduleEntryModel,
    ProductCodeProEnum,
} from "../types";
import { IMapEntries } from "mobx";
import {
    IOrganizationCustomTermsConditions,
    IPartner,
    IPartnerConsumptionSettings,
    IPartnerInput,
    IPartnerMarketingInfo,
    IPartnerToggledFeatures,
    Product,
    ShortPartner,
    Transaction,
    UsageModel,
    User,
} from "../models";
import { IProductDefinition } from "../appLogic/ProductDefinition";

export class PartnersApi extends BaseApi {
    public static async getPartners() {
        return await this.sendApiRequest<IPartner[]>(HttpMethodEnum.GET, ReactAppSettings.appUrls.getPartners);
    }

    public static async getPartner(id: string) {
        return await this.sendApiRequest<IPartner>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getPartner,
            undefined,
            id,
        );
    }

    public static async createPartner(partner: IPartnerInput) {
        return await this.sendApiRequestWithResult<IPartnerInput, IPartner>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.createPartner,
            partner,
        );
    }

    public static async updatePartnerName(id: string, name: string) {
        return await this.sendApiRequestWithResult<{ name: string }, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updatePartnerName,
            { name },
            id,
        );
    }

    public static async updatePartnerAdditionalInformation(
        id: string,
        model: IUpdatePartnerAdditionalInformationModel,
    ) {
        return await this.sendApiRequestWithResult<IUpdatePartnerAdditionalInformationModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updatePartnerAdditionalInformation,
            model,
            id,
        );
    }

    public static async updatePartnerEmail(id: string, email: string) {
        return await this.sendApiRequestWithResult<{ email: string }, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updatePartnerEmail,
            { email },
            id,
        );
    }

    public static async updatePartnerMarketingInfo(id: string, marketingInfo: IPartnerMarketingInfo) {
        return await this.sendApiRequestWithResult<IPartnerMarketingInfo, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updatePartnerMarketingInfo,
            marketingInfo,
            id,
        );
    }

    public static async updatePartnerConsumptionSettings(id: string, consumptionSettings: IPartnerConsumptionSettings) {
        return await this.sendApiRequestWithResult<IPartnerConsumptionSettings, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updatePartnerConsumptionSettings,
            consumptionSettings,
            id,
        );
    }

    public static async updatePartnerBillingInformation(id: string, model: IUpdateBillingAddressCommand) {
        return await this.sendApiRequestWithResult<IUpdateBillingAddressCommand, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updatePartnerBillingInformation,
            model,
            id,
        );
    }

    public static async updatePartnerSalesRep(id: string, salesRepId: string) {
        return await this.sendApiRequestWithResult<{ salesRepId: string }, ISalesRep[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.updatePartnerSalesRep,
            { salesRepId },
            id,
        );
    }

    public static async getPartnerUsers(id: string) {
        return this.sendApiRequestWithResult<null, User[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getPartnerUsers,
            undefined,
            id,
        );
    }

    public static async getPartnerTransactions(
        id: string,
        filters?: ITransactionLogFilters,
        pageSize: number = 100,
        offset: number = 0,
        sortDirection: "asc" | "desc" = "desc",
    ) {
        const data = {
            ...filters,
            pageSize,
            offset,
            sortDirection,
        };

        return await this.sendApiRequestWithResult<typeof data, Transaction[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getPartnerTransactionLog,
            data,
            id,
            DataType.QueryString,
        );
    }

    public static async getPartnerTransactionsExcel(id: string, filters?: ITransactionLogFilters) {
        const apiCall = ApiManager.get(
            UrlFormatter.format(ReactAppSettings.appUrls.getPartnerTransactionLogExcel, id),
            {
                responseType: "blob",
                timeout: 300000,
                params: filters,
                paramsSerializer: (params) => qs.stringify(params),
            },
        );

        this.downloadFile("Transactions.xlsx", apiCall);
    }

    public static async getPartnerCandidatesData(id: string) {
        return await this.sendApiRequest(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.downloadPartnerCandidatesData,
            undefined,
            id,
        );
    }

    public static async updateProductRestrictions(id: string, productCodes: ProductCodeProEnum[]) {
        return await this.sendApiRequestWithResult<ProductCodeProEnum[], null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updatePartnerProductRestrictions,
            productCodes,
            id,
        );
    }

    public static async getUsageModel(id: string) {
        return await this.sendApiRequestWithResult<null, UsageModel>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getPartnerUsageModel,
            undefined,
            id,
        );
    }

    public static async getManagerContactInfo(id: string) {
        return await this.sendApiRequestWithResult<null, IContactInformation>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getPartnerManagerContactInfo,
            undefined,
            id,
        );
    }

    public static async creditProductBalance(id: string, model: IProductBalanceRequestModel) {
        return await this.sendApiRequestWithResult<IProductBalanceRequestModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.creditPartnerProductBalance,
            model,
            id,
        );
    }

    public static async debitProductBalance(id: string, model: IProductBalanceRequestModel) {
        return await this.sendApiRequestWithResult<IProductBalanceRequestModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.debitPartnerProductBalance,
            model,
            id,
        );
    }

    public static async purchaseProductsByInvoicing(id: string, model: IProductBalanceRequestModel) {
        return await this.sendApiRequestWithResult<IProductBalanceRequestModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.purchasePartnerProductsWithInvoicing,
            model,
            id,
        );
    }

    public static async purchaseProductsByCreditCard(id: string, model: IProductBalanceCreditCardRequestModel) {
        return await this.sendApiRequestWithResult<IProductBalanceCreditCardRequestModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.purchasePartnerProductsWithCreditCard,
            model,
            id,
        );
    }

    public static async addPartnerConsumptionScheduleEntry(
        id: string,
        model: IAddProductConsumptionModeScheduleEntryModel,
    ) {
        return await this.sendApiRequestWithResult<
            IAddProductConsumptionModeScheduleEntryModel,
            IProductConsumptionModeScheduleEntryModel
        >(HttpMethodEnum.POST, ReactAppSettings.appUrls.addPartnerConsumptionScheduleEntry, model, id);
    }

    public static async updatePartnerConsumptionScheduleEntry(
        id: string,
        model: IUpdateProductConsumptionModeScheduleEntryModel,
    ) {
        return await this.sendApiRequestWithResult<IUpdateProductConsumptionModeScheduleEntryModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updatePartnerConsumptionScheduleEntry,
            model,
            id,
        );
    }

    public static async removePartnerConsumptionScheduleEntry(
        id: string,
        model: IRemoveProductConsumptionModeScheduleEntryModel,
    ) {
        return await this.sendApiRequestWithResult<IRemoveProductConsumptionModeScheduleEntryModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.removePartnerConsumptionScheduleEntry,
            model,
            id,
        );
    }

    public static async updatePartnerConsumptionScheduleEntryStartDate(
        id: string,
        model: IUpdateProductConsumptionModeScheduleEntryModel,
    ) {
        return await this.sendApiRequestWithResult<IUpdateProductConsumptionModeScheduleEntryModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updatePartnerConsumptionScheduleEntryStartDate,
            model,
            id,
        );
    }

    public static async updatePartnerConsumptionScheduleEntryEndDate(
        id: string,
        model: IUpdateProductConsumptionModeScheduleEntryModel,
    ) {
        return await this.sendApiRequestWithResult<IUpdateProductConsumptionModeScheduleEntryModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updatePartnerConsumptionScheduleEntryEndDate,
            model,
            id,
        );
    }

    public static async updatePartnerConsumptionScheduleEntryMode(
        id: string,
        model: IUpdateProductConsumptionModeScheduleEntryModel,
    ) {
        return await this.sendApiRequestWithResult<IUpdateProductConsumptionModeScheduleEntryModel, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updatePartnerConsumptionScheduleEntryMode,
            model,
            id,
        );
    }

    public static async getAvailableProducts(id: string) {
        return await this.sendApiRequestWithResult<null, Product[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getPartnerAvailableProducts,
            undefined,
            id,
        );
    }

    public static async getAvailableProductDefinitions(id: string) {
        return await this.sendApiRequestWithResult<null, IProductDefinition[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getPartnerAvailableProductDefinitions,
            undefined,
            id,
        );
    }

    public static async getStripeCustomer(id: string) {
        return await this.sendApiRequestWithResult<
            null,
            { cards: IStripeCustomerCard[]; mostRecentlyUsedCard: IStripeCustomerCard }
        >(HttpMethodEnum.GET, ReactAppSettings.appUrls.getPartnerCustomerCards, undefined, id);
    }

    public static async addCustomerCard(id: string, cardToken: string, cardLabel: string) {
        return await this.sendApiRequestWithResult<{ cardToken: string; cardLabel: string }, IStripeCustomerCard>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.addPartnerCustomerCard,
            { cardToken, cardLabel },
            id,
        );
    }

    public static async updateCustomerCard(id: string, card: IStripeCustomerCard) {
        return await this.sendApiRequestWithResult<IStripeCustomerCard, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updatePartnerCustomerCard,
            card,
            id,
        );
    }

    public static async deleteCustomerCard(id: string, cardId: string) {
        return await this.sendApiRequestWithResult<{ data: string }, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.deletePartnerCustomerCard,
            { data: cardId },
            id,
        );
    }

    public static async searchPartners(searchString: string, offset: number = 0, fetchSize: number = 100) {
        const data = {
            searchString: searchString || "",
            offset,
            fetchSize,
        };

        return await this.sendApiRequestWithResult<typeof data, ShortPartner[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.searchPartners,
            data,
            undefined,
            DataType.QueryString,
        );
    }

    public static async toggleFeatures(id: string, features: IPartnerToggledFeatures) {
        return await this.sendApiRequestWithResult<IPartnerToggledFeatures, null>(
            HttpMethodEnum.PUT,
            ReactAppSettings.appUrls.togglePartnerFeatures,
            features,
            id,
        );
    }

    public static async getDashboardElements(filters?: IDashboardFilters) {
        return await this.sendApiRequestWithResult<typeof filters, IDashboardElements>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getUsageDashboardElements,
            filters,
            undefined,
            DataType.QueryString,
        );
    }

    public static async getMappings(id: string) {
        return await this.sendApiRequestWithResult<null, Dictionary<CompetencyProfile, string>>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getPartnerPerformanceEvaluationMappings,
            null,
            id,
        );
    }

    public static async updateMappings(id: string, mappings: Dictionary<CompetencyProfile, string>) {
        const data = {
            statementsVersion: null,
            competencyProfilePerformanceEvaluationTemplateIds: mappings,
        };

        return await this.sendApiRequestWithResult<typeof data, null>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updatePartnerPerformanceEvaluationMappings,
            data,
            id,
        );
    }

    public static async activatePartnerCustomTermsConditions(id: string): Promise<void> {
        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.activatePartnerCustomTermsConditions,
            null,
            id,
        );
    }

    public static async deactivatePartnerCustomTermsConditions(id: string): Promise<void> {
        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.deactivatePartnerCustomTermsConditions,
            null,
            id,
        );
    }

    public static async getOrganizationCustomTermsConditions(): Promise<IOrganizationCustomTermsConditions> {
        return this.sendApiRequestWithResult(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getOrganizationCustomTermsConditions,
            null,
        );
    }

    public static async updateOrganizationCustomTermsConditions(
        titleEntries: IMapEntries<string, string>,
        descriptionEntries: IMapEntries<string, string>,
    ): Promise<void> {
        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateOrganizationCustomTermsConditions,
            {
                title: titleEntries,
                description: descriptionEntries,
            },
        );
    }
}
