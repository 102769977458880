import "./index.less";
import * as React from "react";
import { AtBadge, AtIcon, AtLinkBase, AtText } from "@atman/design-system";
import { CompDevLearningResource, CompetencyDevelopmentLearningResourceType } from "@atman/business";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import cn from "classnames";

export interface ILearningResourceCardProps {
    learningResource: CompDevLearningResource;
    /* parentId?: string;
    checked?: boolean;
    onClick?: () => void; */
    className?: string;
}

export const LearningResourceInline: React.FC<ILearningResourceCardProps> = observer((props) => {
    const { learningResource /*, parentId, checked, onClick */, className = "" } = props;

    let iconProp: IconProp | undefined;

    switch (learningResource.type) {
        case CompetencyDevelopmentLearningResourceType.Article:
            iconProp = ["fal", "browser"];
            break;
        case CompetencyDevelopmentLearningResourceType.Book:
            iconProp = ["fal", "book"];
            break;
        case CompetencyDevelopmentLearningResourceType.Video:
            iconProp = ["fal", "video"];
            break;
        case CompetencyDevelopmentLearningResourceType.Podcast:
            iconProp = ["fal", "podcast"];
            break;
        case CompetencyDevelopmentLearningResourceType.Certification:
            iconProp = ["fal", "certificate"];
            break;
    }

    const topContent: JSX.Element = (
        <AtBadge size="sm" color="secondary" badgeStyle="fill-light">
            {iconProp && <FontAwesomeIcon icon={iconProp} />}
            <span>
                {t({
                    id: `competencyDevelopment.learningResourceType.${CompetencyDevelopmentLearningResourceType[
                        learningResource.type
                    ].toCamel()}`,
                })}
            </span>
        </AtBadge>
    );

    return (
        <div className={cn("LearningResourceInline", className)}>
            <div className="LearningResourceTitleAuthor">
                {(learningResource.url && (
                    <AtLinkBase
                        href={learningResource.url}
                        color="secondary"
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                        title={learningResource.name}
                        underlined
                    >
                        <AtIcon icon={["far", "link"]} />
                        <span>{learningResource.name}</span>
                    </AtLinkBase>
                )) || <AtText weight="semibold">{learningResource.name}</AtText>}

                <AtText weight="regular">{learningResource.authors}</AtText>
            </div>
            <div className="LearningResourceTypeLink">
                <span>{topContent}</span>
            </div>
        </div>
    );
});
