import { ColorProperty } from "csstype";
import { HALFPIE_CORNERRADIUS, HALFPIE_INNERRADIUS, HALFPIE_PADDING } from "./constants";
import { VictoryPie } from "victory";
import { inject, observer } from "mobx-react";
import React from "react";

export interface IAngleBasedPieProps {
    startAngle?: number; // Min value angle
    endAngle: number; // Max value angle
    highlightMode?: boolean; // Makes the pie bolder
    color: ColorProperty; // Background color of the pie
    strokeColor?: ColorProperty; // Border color of the pie
}

@inject()
@observer
export class AngleBasedPie extends React.Component<IAngleBasedPieProps> {
    render() {
        const { startAngle, endAngle, highlightMode, color, strokeColor } = this.props;

        const innerRadius = highlightMode ? HALFPIE_INNERRADIUS - 3 : HALFPIE_INNERRADIUS;
        const padding = highlightMode ? HALFPIE_PADDING - 3 : HALFPIE_PADDING;
        const strokeWidth = highlightMode ? 6 : 0;

        return (
            <VictoryPie
                animate={false}
                data={[{ x: 0, y: 1, label: "", active: false }]}
                labels={() => ""}
                standalone={false}
                innerRadius={innerRadius}
                cornerRadius={HALFPIE_CORNERRADIUS}
                startAngle={startAngle}
                endAngle={endAngle}
                padding={padding}
                style={{
                    data: {
                        fill: () => {
                            return color;
                        },
                        stroke: strokeColor,
                        strokeWidth: strokeWidth,
                    },
                }}
            />
        );
    }
}
