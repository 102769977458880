import { ErrorHandler } from "@atman/business/lib/libs/ErrorHandler";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import qs from "qs";

export interface IApiClient {
    get<TResult = void, TData = any>(url: string, data?: TData, config?: AxiosRequestConfig): Promise<TResult>;
    post<TResult = void, TData = any>(url: string, data?: TData, config?: AxiosRequestConfig): Promise<TResult>;
    put<TResult = void, TData = any>(url: string, data?: TData, config?: AxiosRequestConfig): Promise<TResult>;
    patch<TResult = void, TData = any>(url: string, data?: TData, config?: AxiosRequestConfig): Promise<TResult>;
    delete<TResult = void, TData = any>(url: string, data?: TData, config?: AxiosRequestConfig): Promise<TResult>;
    head<TResult = void, TData = any>(url: string, data?: TData, config?: AxiosRequestConfig): Promise<TResult>;
    options<TResult = void, TData = any>(url: string, data?: TData, config?: AxiosRequestConfig): Promise<TResult>;
    updateBaseUrl(baseUrl: string): void;
    setJwtAuthHeader(jwt: string): void;
}

export class ApiClient implements IApiClient {
    private readonly _axiosInstance: AxiosInstance;

    constructor() {
        this._axiosInstance = axios.create({
            baseURL: "",
            timeout: 120000,
            withCredentials: true,
        });

        this.configureAxiosInstance();
    }

    public get<TResult = void, TData = any>(url: string, data?: TData, config: AxiosRequestConfig = {}) {
        const allConfig: AxiosRequestConfig = {
            ...config,
            params: data,
            paramsSerializer: (params) => qs.stringify(params),
        };

        return this._axiosInstance.get<TResult, TResult>(url, allConfig);
        // .then(result => {
        //     console.log(result);
        //     return result.data;
        // });
        // .catch(error => ErrorHandler.handleError(error));
    }

    public post<TResult = void, TData = any>(url: string, data?: TData, config: AxiosRequestConfig = {}) {
        return this._axiosInstance.post<TResult, TResult>(url, data, config);
        // .then(result => result.data);
        // .catch(error => ErrorHandler.handleError(error));
    }

    public put<TResult = void, TData = any>(url: string, data?: TData, config: AxiosRequestConfig = {}) {
        return this._axiosInstance.put<TResult, TResult>(url, data, config);
        // .then(result => result.data);
        // .catch(error => ErrorHandler.handleError(error));
    }

    public patch<TResult = void, TData = any>(url: string, data?: TData, config: AxiosRequestConfig = {}) {
        return this._axiosInstance.patch<TResult, TResult>(url, data, config);
        // .then(result => result.data);
        // .catch(error => ErrorHandler.handleError(error));
    }

    public delete<TResult = void, TData = any>(url: string, data?: TData, config: AxiosRequestConfig = {}) {
        const allConfig: AxiosRequestConfig = {
            ...config,
            params: data,
            paramsSerializer: (params) => qs.stringify(params),
        };

        return this._axiosInstance.delete<TResult, TResult>(url, allConfig);
        // .then(result => result.data);
        // .catch(error => ErrorHandler.handleError(error));
    }

    public head<TResult = void, TData = any>(url: string, data?: TData, config: AxiosRequestConfig = {}) {
        const allConfig: AxiosRequestConfig = {
            ...config,
            params: data,
            paramsSerializer: (params) => qs.stringify(params),
        };

        return this._axiosInstance.head<TResult, TResult>(url, allConfig);
        // .then(result => result.data);
        // .catch(error => ErrorHandler.handleError(error));
    }

    public options<TResult = void, TData = any>(url: string, data?: TData, config: AxiosRequestConfig = {}) {
        const allConfig: AxiosRequestConfig = {
            ...config,
            params: data,
            paramsSerializer: (params) => qs.stringify(params),
        };

        return this._axiosInstance.options<TResult, TResult>(url, allConfig);
        // .then(result => result.data);
        // .catch(error => ErrorHandler.handleError(error));
    }

    public updateBaseUrl(baseUrl: string) {
        this._axiosInstance.defaults.baseURL = baseUrl;
    }

    public setJwtAuthHeader(jwt: string) {
        this._axiosInstance.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    }

    private configureAxiosInstance() {
        this._axiosInstance.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
        this._axiosInstance.interceptors.response.use(
            (response) => {
                if (process.env.REACT_APP_ENVIRONMENT === "local") {
                    console.log(response);
                }

                switch (response.status) {
                    case 300:
                    case 301:
                    case 302:
                    case 303:
                    case 307:
                    case 308:
                        window.location.href = response.headers.location;
                        break;
                    default:
                        break;
                }

                return response.data;
            },
            (error) => {
                const errorResponse = (error as AxiosError).response;

                if (process.env.REACT_APP_ENVIRONMENT === "local") {
                    console.log(error);
                }

                if (errorResponse) {
                    switch (errorResponse.status) {
                        case 400:
                            // ErrorHandler.handleApiError(error);
                            break;
                        case 401:
                            // window.location.href = `${ReactAppSettings.appUrls.Login}?returnUrl=${window.location.href}`;
                            break;
                        case 403:
                            // ErrorHandler.handleApiError(error);
                            break;
                        case 404:
                            // ErrorHandler.handleApiError(error);
                            break;
                        case 422:
                            break;
                        case 500:
                            // ErrorHandler.handleApiError(error);
                            break;
                        default:
                            ErrorHandler.handleApiError(error);
                            break;
                    }

                    return Promise.reject(errorResponse.data);
                }

                return Promise.reject(error);
            },
        );
    }
}
