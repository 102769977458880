import "./index.less";
import * as React from "react";
import { AtButton, IReactSelectOptionObject } from "@atman/design-system";
import { AtDownChevronMenuButton } from "@atman/design-system";
import { DropdownItem, Modal, ModalHeader } from "reactstrap";
import { FileHelper, GlobalStores, LocalizationStore, ReportsApi, findCultureFromSet } from "@atman/business";
import { IPrintReportParameters } from "@atman/business/lib/apis/ReportsApi";
import { PrintReportCustomizationModalBody } from "./components/PrintReportCustomizationModalBody";
import { Trans, t } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { isArrayLike } from "mobx";

export type PrintReportParams = Omit<IPrintReportParameters, "pdfFileName">;

export interface IAtPrintButtonProps {
    getPrintReportParams: (params: Map<string, string | string[]>, languageCode: string) => PrintReportParams;
    configurableOptions?: IConfigurablePrintOption[];
    reportNamePrefix?: string;
    params?: Map<string, string>;
    defaultOptions?: IDefaultPrintOption[];
    allowCustomization?: boolean;
    availableReportLanguagesOverride?: string[];
    localizationStore?: LocalizationStore;
}

export const AtPrintButton: React.FC<IAtPrintButtonProps> = inject(GlobalStores.localizationStore)(
    observer((props) => {
        const {
            reportNamePrefix,
            getPrintReportParams,
            params = new Map<string, string>(),
            configurableOptions = [],
            defaultOptions = [],
            allowCustomization = true,
            availableReportLanguagesOverride,
            localizationStore,
        } = props;

        const isCurrentReportLocaleSupported =
            availableReportLanguagesOverride?.includes(localizationStore!.currentReportShortLocale) ?? true;
        const defaultPrintLanguage = isCurrentReportLocaleSupported
            ? localizationStore!.currentReportShortLocale
            : findCultureFromSet(
                  localizationStore!.availableReportLanguages,
                  availableReportLanguagesOverride?.[0] ?? localizationStore!.currentShortLocale,
              )?.shortName ?? localizationStore!.currentShortLocale;

        const [modalOpen, setModalOpen] = React.useState(false);
        const toggleModal = () => setModalOpen(!modalOpen);

        const printAction = (option: IDefaultPrintOption) => {
            const printParams = new Map<string, string | string[]>(params);

            for (const [key, value] of option.params) {
                printParams.set(key, value);
            }

            return printReport(
                printParams,
                getPrintReportParams(printParams, defaultPrintLanguage),
                defaultPrintLanguage,
                `${reportNamePrefix}-${option.label}`,
            );
        };

        const modal: JSX.Element = (
            <Modal isOpen={modalOpen} toggle={toggleModal} modalClassName={"centered-modal"}>
                <ModalHeader toggle={toggleModal}>
                    <Trans id={"global.printableReports.customizationModal.title"}>Customize your PDF report</Trans>
                </ModalHeader>
                <PrintReportCustomizationModalBody
                    configurationOptions={configurableOptions}
                    reportNamePrefix={reportNamePrefix}
                    getPrintReportParams={getPrintReportParams}
                    params={params}
                    toggleModal={toggleModal}
                    availableReportLanguagesOverride={availableReportLanguagesOverride}
                />
            </Modal>
        );

        const countTotalOptions: number = allowCustomization ? defaultOptions.length + 1 : defaultOptions.length;
        const printLabel: string = t({ id: "global.print" });

        if (countTotalOptions > 1) {
            return (
                <AtDownChevronMenuButton
                    label={t({ id: "global.print" })}
                    className="AtPrintButton"
                    icon={["fal", "print"]}
                    size="md"
                >
                    {defaultOptions.map((x, i) => (
                        <DropdownItem key={`default-option-${i}`} onClick={() => printAction(x)}>{`${
                            x.label
                        } - ${defaultPrintLanguage.toUpperCase()}`}</DropdownItem>
                    ))}
                    {allowCustomization && (
                        <>
                            <DropdownItem onClick={toggleModal}>
                                <Trans id={"global.customize"}>Customize</Trans>
                            </DropdownItem>
                            {modal}
                        </>
                    )}
                </AtDownChevronMenuButton>
            );
        } else if (countTotalOptions === 1) {
            return (
                <>
                    <AtButton
                        size="lg"
                        color="secondary"
                        onClick={
                            allowCustomization
                                ? toggleModal
                                : () => {
                                      printAction(defaultOptions[0]);
                                  }
                        }
                    >
                        {printLabel}
                    </AtButton>
                    {modal}
                </>
            );
        } else {
            return (
                <AtButton size="lg" color="secondary" disabled>
                    {printLabel}
                </AtButton>
            );
        }
    }),
);

export async function printReport(
    params: Map<string, string | string[]>,
    printReportParams: PrintReportParams,
    languageCode: string,
    reportName: string,
) {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("LanguageOverride", languageCode);

    for (const [key, value] of params) {
        if (!isArrayLike(value)) {
            urlSearchParams.set(key, value);
        } else {
            for (const arrayValue of value) {
                urlSearchParams.append(key, arrayValue);
            }
        }
    }

    const date = FileHelper.getFileNameFriendlyDate(languageCode);
    const fileName = FileHelper.removeInvalidFileNameChars(`${reportName}-${languageCode.toUpperCase()}-${date}.pdf`);

    await ReportsApi.getReport({
        ...printReportParams,
        urls: printReportParams.urls.map((x) => `${x}${x.includes("?") ? "&" : "?"}${urlSearchParams.toString()}`),
        pdfFileName: fileName,
        languageCode: languageCode,
    });
}

export interface IDefaultPrintOption {
    label: string;
    params: Map<string, string>;
}

export interface IConfigurablePrintOption {
    label: string;
    key: string;
    options: IReactSelectOptionObject[];
    excludeFromQueryParams?: boolean;
    isMulti?: boolean;
}
