import {
    AppScope,
    ResponsivenessHelper,
    Role,
    User,
    UserInfoStore,
    UserState,
    UserStore,
    WorkspaceStore,
} from "@atman/business";
import {
    AtLink,
    AtMenuButton,
    AtTableColumn,
    TableItemBadges,
    TableItemLabel,
    TableItemList,
    TableItemTitle,
} from "@atman/design-system";
import { MatchTypeOptionsEnum } from "@atman/design-system/lib/components/molecules/AtTable/component/filtering/AtTableFreeTextFilter";
import { YouBadge } from "..";
import { t } from "@lingui/macro";
import DropdownItem from "reactstrap/lib/DropdownItem";
import React from "react";

const getUserManagementColumns = (
    userInfoStore: UserInfoStore,
    userStore: UserStore,
    workspaceStore: WorkspaceStore,
    onUserEditClick: (e: React.MouseEvent<HTMLElement>, id: string) => void,
    onUserSecondaryClick: (id: string) => void,
    isDelegatedForClient: boolean,
    onUserClientsDelegationUpdateClick: ((e, id: string) => void) | null,
    isInEntityManagementPage: boolean,
    scope: AppScope,
    availableBaseRoles: string[],
    availableAdditionalRoles: string[],
): AtTableColumn<User>[] => {
    const isClientScope = scope === AppScope.Client;
    const isPartnerScope = scope === AppScope.Partner;

    const accessColumns: AtTableColumn<User> = {
        key: "access",
        fieldName: "automaticClientDelegationDisabled",
        align: "left",
        label: t({ id: "global.access" }),
        sort: true,
        displayOverSize: "xxl",
        width: 140,
        filter: {
            type: "boolean",
            trueLabel: t({ id: "partnerApp.manualClientDelegation" }),
            falseLabel: t({ id: "partnerApp.automaticClientDelegation" }),
            isUser: "has",
        },
        render: (automaticClientDelegationDisabled) => {
            return (
                <TableItemLabel
                    maxWidth={140}
                    content={
                        automaticClientDelegationDisabled
                            ? t({ id: "partnerApp.manualClientDelegation" })
                            : t({ id: "partnerApp.automaticClientDelegation" })
                    }
                />
            );
        },
    };

    const workspaceColumns: AtTableColumn<User> = {
        key: "workspaces",
        fieldName: "workspaces",
        align: "left",
        label: t({ id: "clientApp.workspaces" }),
        displayOverSize: "xxl",
        width: 200,
        filter: {
            type: "multipleSelect",
            values: workspaceStore!.items.map((workspace) => ({
                title: workspace.tagName,
                value: workspace.id,
            })),
        },
        render: (workspace: string[]) => {
            if (!workspace || workspace.length === 0) {
                return null;
            }

            const workspaces = workspace.map((id) => workspaceStore!.findItemById(id));
            const tagNames = workspaces.map((workspace) => workspace!.tagName);

            return <TableItemBadges contents={tagNames} />;
        },
    };

    return [
        {
            key: "lastName",
            fieldName: "lastName",
            align: "left",
            label: t({ id: "global.name" }),
            sort: true,
            displayOverSize: "xs",
            width: "fit-content",
            filter: {
                type: "freeText",
            },
            onFilter: (value, record, matchType) => {
                const regex = new RegExp(`.*${value}.*`, "i");
                const searchRecord = `${record.firstName} ${record.lastName}`;

                if (matchType === MatchTypeOptionsEnum.DoesNotContains) {
                    const regexNotContains = new RegExp(`^((?!${value}).)*$`, "i");
                    return regexNotContains.test(`${searchRecord}`);
                }

                return regex.test(`${searchRecord}`);
            },
            render: (lastName: string, record) => {
                let name: string = "";

                if (record.firstName && lastName) {
                    name = `${record.firstName} ${lastName}`;
                } else if (record.firstName && !lastName) {
                    name = record.firstName;
                } else if (!record.firstName && lastName) {
                    name = lastName;
                } else {
                    name = record.email;
                    return <TableItemTitle maxWidth={200} title={name} />;
                }

                return <TableItemTitle maxWidth={200} title={name} subtitle={record.email} />;
            },
        },
        {
            key: "baseRole",
            fieldName: "baseRole",
            align: "left",
            label: t({ id: "global.baseRole" }),
            sort: true,
            filter: {
                type: "multipleSelect",
                values: availableBaseRoles.map((x) => ({
                    title: t({ id: `global.role.${x.toCamel()}` }),
                    value: x,
                })),
            },
            displayOverSize: "lg",
            width: 100,
            render: (baseRole: string) => {
                if (!baseRole) return null;

                return (
                    <TableItemLabel
                        maxWidth={100}
                        className="textWeightMedium"
                        content={t({ id: `global.role.${baseRole.toCamel()}` })}
                    />
                );
            },
        },
        {
            key: "additionalRoles",
            fieldName: "additionalRoles",
            align: "left",
            label: t({ id: "global.authorization" }),
            displayOverSize: "xl",
            width: 240,
            filter: {
                type: "multipleSelect",
                values: availableAdditionalRoles
                    .filter((x) => x !== Role[Role.SupportAdmin])
                    .map((x) => ({
                        title: t({ id: `global.role.${x.toCamel()}` }),
                        value: x,
                    })),
            },
            render: (additionalRoles: string[]) => {
                if (!additionalRoles || additionalRoles.length === 0) {
                    return null;
                }

                const additionalRolesWithLingui = additionalRoles
                    .filter((x) => !!x)
                    .map((additionalRole) => {
                        return t({ id: `global.role.${additionalRole.toCamel()}` });
                    });

                return <TableItemList contents={additionalRolesWithLingui} />;
            },
        },
        ...(ResponsivenessHelper.isBiggerThan(1600) && isClientScope ? [workspaceColumns] : []),
        ...(ResponsivenessHelper.isBiggerThan(1600) && isPartnerScope ? [accessColumns] : []),
        {
            key: "id",
            fieldName: "id",
            align: "right",
            displayOverSize: "xs",
            width: 120,
            render: (id: string, record) => {
                if (id === userInfoStore.userId) {
                    return <YouBadge showTooltip={true} />;
                }

                return (
                    <span className="actions">
                        {isDelegatedForClient && (
                            <AtLink
                                color={"secondary"}
                                onClick={(e) => onUserEditClick(e, id)}
                                style={{ marginRight: 16 }}
                            >
                                {t({ id: "global.edit" })}
                            </AtLink>
                        )}
                        <AtMenuButton size="md">
                            {onUserClientsDelegationUpdateClick && (
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        return onUserClientsDelegationUpdateClick(e, id);
                                    }}
                                >
                                    {t({ id: "partnerApp.updateUserClientsDelegation" })}
                                </DropdownItem>
                            )}
                            {record.state === UserState.Active ? (
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        return userStore.deactivateUser(id, isInEntityManagementPage);
                                    }}
                                >
                                    {t({ id: "global.buttons.labels.deactivate" })}
                                </DropdownItem>
                            ) : (
                                <DropdownItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        return userStore.reactivateUser(id, isInEntityManagementPage);
                                    }}
                                >
                                    {t({ id: "global.buttons.labels.reactivate" })}
                                </DropdownItem>
                            )}
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    return onUserSecondaryClick(id);
                                }}
                            >
                                {t({ id: "global.buttons.labels.delete" })}
                            </DropdownItem>
                        </AtMenuButton>
                    </span>
                );
            },
        },
    ];
};

export default getUserManagementColumns;
