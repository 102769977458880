import "./BlockedBalanceMarker.less";
import * as React from "react";
import { Colors } from "../../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalStores, ModalStore, ModalTypes, TransactionStore } from "@atman/business";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Label, UncontrolledTooltip } from "reactstrap";
import { action } from "mobx";
import { inject, observer } from "mobx-react";

export interface IBlockedBalanceMarkerProps {
    modalStore?: ModalStore;
    transactionStore?: TransactionStore;
}

@inject(GlobalStores.modalStore, GlobalStores.transactionStore)
@observer
export class BlockedBalanceMarker extends React.Component<IBlockedBalanceMarkerProps, {}> {
    @action.bound
    onMarkerClick = () => {
        const { modalStore } = this.props;

        modalStore!.openModal(ModalTypes.RequestCredits);
    };

    render() {
        const { transactionStore } = this.props;

        return (
            <div>
                <div id="BlockedBalanceMarker" onClick={this.onMarkerClick}>
                    <Label>{"global.balance.blocked.label".localize()}</Label>
                    <FontAwesomeIcon icon={["far", "ban"] as IconProp} color={Colors.errorColor} />
                </div>
                <UncontrolledTooltip
                    target={"BlockedBalanceMarker"}
                    placement={"bottom"}
                    delay={{ show: 100, hide: 0 }}
                >
                    {"global.balance.blocked.tooltip"
                        .localize()
                        .replace("{0}", transactionStore!.managersContactInformation?.contactName)}
                </UncontrolledTooltip>
            </div>
        );
    }
}
