import "./index.less";
import { Colors, DefaultSizes, XL } from "../../../..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";

export interface ILoadingIndicatorProps {
    size?: DefaultSizes | XL;
    label?: string;
}

export const LoadingIndicator: React.FC<ILoadingIndicatorProps> = ({ size = "md", label, ...otherProps }) => {
    const iconSize = useMemo(() => {
        switch (size) {
            case "sm":
                return "sm";
            case "md":
                return "lg";
            case "lg":
                return "2x";
            case "xl":
                return "4x";
        }
    }, [size]);

    return (
        <div className={`LoadingIndicator size-${size}`} {...otherProps}>
            <div className="icon-container">
                <FontAwesomeIcon
                    className={"spinner-icon"}
                    icon={["fal", "spinner-third"]}
                    size={iconSize}
                    spin
                    color={Colors.primaryBase}
                />
            </div>
            {label && <div className="label">{label}</div>}
        </div>
    );
};
