import { CompDevCompetency, ICompDevCompetency } from "../StructureModels";
import { CompDevStrategicObjectiveResult, ICompDevStrategicObjectiveResult } from "./CompDevStrategicObjectiveResult";
import { CompDevSubCompetencyResult, ICompDevSubCompetencyResult } from "./CompDevSubCompetencyResult";
import { CompetencyDevelopmentAssessmentType, CompetencySource } from "../CompDevEnums";
import { autorun, computed, observable } from "mobx";

export interface ICompDevCompetencyResult extends ICompDevCompetency {
    readonly subCompetencies: ICompDevSubCompetencyResult[];
    readonly strategicObjectives: ICompDevStrategicObjectiveResult[];
    readonly competencySources: CompetencySource[];
    readonly scales: string[];
    readonly psychometricScore?: number;
}

export class CompDevCompetencyResult extends CompDevCompetency {
    public readonly subCompetencies: CompDevSubCompetencyResult[];
    public readonly strategicObjectives: CompDevStrategicObjectiveResult[];
    public readonly competencySources: CompetencySource[];
    public readonly scales: string[];
    public readonly psychometricScore?: number;

    @observable public subCompetenciesAverage: number | undefined;
    @observable public subCompetenciesManagerAverage: number | undefined;
    @observable public subCompetenciesEmployeeAverage: number | undefined;
    @observable public subCompetenciesReviewAverage: number | undefined;

    constructor(json: ICompDevCompetencyResult) {
        super(json);

        this.subCompetencies = json.subCompetencies.map((x) => new CompDevSubCompetencyResult(x, this.rank));
        // TODO fix that
        this.strategicObjectives = (json?.strategicObjectives || []).map((x) => new CompDevStrategicObjectiveResult(x));
        this.competencySources = json.competencySources;
        this.scales = json.scales;
        this.psychometricScore = json.psychometricScore;

        autorun(() => {
            this.subCompetenciesAverage = this._subCompetenciesAverage;
            this.subCompetenciesManagerAverage = this._subCompetenciesManagerAverage;
            this.subCompetenciesEmployeeAverage = this._subCompetenciesEmployeeAverage;
            this.subCompetenciesReviewAverage = this._subCompetenciesReviewAverage;
        });
    }

    public getCurrentObservableBehaviorScores(): Map<string, number | undefined> {
        return new Map<string, number | undefined>(
            this.subCompetencies
                .reduce((p, c) => [...p, ...c.observableBehaviors], [])
                .map((x) => [x.id, x.assessmentScores!.get(CompetencyDevelopmentAssessmentType.Review) ?? undefined]),
        );
    }

    public getCurrentObservableBehaviorNotes(): Map<string, string | undefined> {
        return new Map<string, string | undefined>(
            this.subCompetencies
                .reduce((p, c) => [...p, ...c.observableBehaviors], [])
                .map((x) => [x.id, x.assessmentNotes!.get(CompetencyDevelopmentAssessmentType.Review) ?? undefined]),
        );
    }

    @computed
    private get _subCompetenciesAverage(): number | undefined {
        const subCompetenciesWithScores = this.subCompetencies.filter(
            (x) => x.observableBehaviorsAverage !== undefined,
        );

        if (!subCompetenciesWithScores.any()) {
            return undefined;
        }

        const sum = subCompetenciesWithScores.sum((x) => x.observableBehaviorsAverage!);
        return sum / subCompetenciesWithScores.length;
    }

    @computed
    private get _subCompetenciesManagerAverage(): number | undefined {
        const subCompetenciesWithScores = this.subCompetencies.filter(
            (x) => x.observableBehaviorsManagerAverage !== undefined,
        );

        if (!subCompetenciesWithScores.any()) {
            return undefined;
        }

        const sum = subCompetenciesWithScores.sum((x) => x.observableBehaviorsManagerAverage!);
        return sum / subCompetenciesWithScores.length;
    }

    @computed
    private get _subCompetenciesEmployeeAverage(): number | undefined {
        const subCompetenciesWithScores = this.subCompetencies.filter(
            (x) => x.observableBehaviorsEmployeeAverage !== undefined,
        );

        if (!subCompetenciesWithScores.any()) {
            return undefined;
        }

        const sum = subCompetenciesWithScores.sum((x) => x.observableBehaviorsEmployeeAverage!);
        return sum / subCompetenciesWithScores.length;
    }

    @computed
    private get _subCompetenciesReviewAverage(): number | undefined {
        if (this.subCompetencies.every((x) => !x.observableBehaviorsReviewAverage)) {
            return undefined;
        }

        const subCompetenciesWithScores = this.subCompetencies.filter(
            (x) => x.observableBehaviorsReviewAverage !== undefined || x.observableBehaviorsAverage !== undefined,
        );

        if (!subCompetenciesWithScores.any()) {
            return undefined;
        }

        const sum = subCompetenciesWithScores.sum(
            (x) => x.observableBehaviorsReviewAverage ?? x.observableBehaviorsAverage!,
        );
        return sum / subCompetenciesWithScores.length;
    }
}
