import "./index.less";
import { AtBadge } from "@atman/design-system";
import { AtButton } from "../AtButton";
import { FitIndicator } from "../../atoms/FitIndicator";
import { FitLevelEnum } from "@atman/business";
import React, { useMemo } from "react";
import cn from "classnames";

export interface IAtFilterProps extends React.HTMLAttributes<HTMLElement> {
    count: number;
    fitLevel: FitLevelEnum | "showAll";
    fitLevelEnumString?: string;
    active: boolean;
    onFilterClick: (fitLevel: FitLevelEnum | "showAll") => void;
    label: string;
    isCompetency: boolean;
}

export const AtFilter: React.FC<IAtFilterProps> = (props: IAtFilterProps) => {
    const { count, fitLevel, isCompetency, fitLevelEnumString, active, onFilterClick, label, ...otherProps } = props;

    const onClickImpl = () => onFilterClick(!active ? fitLevel : "showAll");

    const color = useMemo(() => (active ? "secondary" : "ghost"), [active]);

    return (
        <AtButton
            className={cn("AtFilter", { isActive: active, isCompetency: isCompetency })}
            color={color as any}
            onClick={() => onClickImpl()}
            {...otherProps}
        >
            {fitLevelEnumString && <FitIndicator fitLevelEnumString={fitLevelEnumString} isCompetency={isCompetency} />}
            {label}
            <AtBadge pill>{count}</AtBadge>
        </AtButton>
    );
};
