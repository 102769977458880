import * as React from "react";
import { CompetencyDevelopmentColumnType, getSelectionProps } from "../../../../../..";
import { ITableHeaderProps, SortDirection, TableHeader } from "../../../../../../../..";
import { observer } from "mobx-react";

export interface ICompetencyNameTableHeaderColProps {
    competencyType?: "competency" | "subCompetency";
    sortElement?: CompetencyDevelopmentColumnType;
    sortDirection?: SortDirection;
    onSortChange?: (element: CompetencyDevelopmentColumnType) => void;
}

export const CompetencyNameTableHeaderCol: React.FC<ICompetencyNameTableHeaderColProps> = observer((props) => {
    const { competencyType = "competency", sortElement, sortDirection, onSortChange } = props;

    const selectionProps: Pick<ITableHeaderProps, "sortDirection" | "isActive" | "onClick"> | undefined =
        getSelectionProps("competencyName", sortElement, sortDirection, onSortChange);

    const mainElementLabel: string =
        competencyType === "competency"
            ? "competencyDevelopment.global.competencies".localize()
            : "competencyDevelopment.global.subcompetency".localize();

    return (
        <TableHeader className="competency-col" {...selectionProps}>
            {mainElementLabel}
        </TableHeader>
    );
});
