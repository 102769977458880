import * as React from "react";
import { BaseOverviewCardItem } from "./BaseOverviewCardItem";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface IUsersCountCardItemProps {
    usersCount: number;
}

export const UsersCountCardItem: React.FC<IUsersCountCardItemProps> = (props) => {
    const { usersCount } = props;

    return (
        <BaseOverviewCardItem
            icon={["far", "users"] as IconProp}
            cardTitle={"global.numberOfUsers".localize()}
            className={"UsersCountCardItem"}
        >
            {usersCount}
        </BaseOverviewCardItem>
    );
};
