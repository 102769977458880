import "./EntitySettingsForm.less";
import * as React from "react";
import {
    AppScope,
    Client,
    GlobalStores,
    IAppContext,
    IClientMarketingInfo,
    IPartnerMarketingInfo,
    ModalStore,
    Partner,
    UnhandledScopeError,
} from "@atman/business";
import { ClientMarketingInfoForm } from "../../../components/ClientMarketingInfoForm";
import { ConsumptionRestrictionForm, ConsumptionSchedulingForm, ProductRestrictionForm } from "../../../components";
import { EnabledFeaturesForm } from "../../../components/EnabledFeaturesForm";
import { PartnerMarketingInfoForm } from "../../../components/PartnerMarketingInfoForm";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../../contexts";

export interface IClientSettingsFormProps extends IAppContext {
    entity: Client | Partner;
    modalStore?: ModalStore;
}

@inject(GlobalStores.modalStore)
@observer
class EntitySettingsFormComp extends React.Component<IClientSettingsFormProps, {}> {
    renderMarketingInfoComponent() {
        const { scope, entity } = this.props;

        switch (scope) {
            case AppScope.Partner:
                return (
                    <ClientMarketingInfoForm
                        ownerId={entity.id}
                        marketingInfo={entity.marketingInfo as IClientMarketingInfo}
                    />
                );
            case AppScope.Supplier:
                return (
                    <PartnerMarketingInfoForm
                        ownerId={entity.id}
                        marketingInfo={entity.marketingInfo as IPartnerMarketingInfo}
                    />
                );
            default:
                throw new UnhandledScopeError(scope);
        }
    }

    render() {
        const { entity } = this.props;

        return (
            <div id="ClientSettingsForm">
                <ProductRestrictionForm
                    ownerId={entity.id}
                    productRestriction={entity.usageModel.productRestriction || []}
                />
                <ConsumptionSchedulingForm ownerId={entity.id} usageModel={entity.usageModel} />
                <ConsumptionRestrictionForm ownerId={entity.id} consumptionSettings={entity.consumptionSettings} />
                <EnabledFeaturesForm ownerId={entity.id} />
                {this.renderMarketingInfoComponent()}
            </div>
        );
    }
}

const EntitySettingsForm = withAppContext(EntitySettingsFormComp);

export { EntitySettingsForm };
