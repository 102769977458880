import "./index.less";
import * as React from "react";
import { AssessmentType, ProductCodeProEnum } from "@atman/business";
import { ProductIcon } from "../ProductIcon";
import { inject, observer } from "mobx-react";

export interface IProductBalanceChangeProps {
    productCode?: ProductCodeProEnum;
    assessmentType?: AssessmentType;
    previousBalance: number;
    newBalance: number;
}

@inject()
@observer
export class ProductBalanceChange extends React.Component<IProductBalanceChangeProps, {}> {
    render() {
        const { productCode, assessmentType, previousBalance, newBalance } = this.props;

        return (
            <div className="ProductBalanceChange">
                <ProductIcon productCode={productCode} assessmentType={assessmentType} />
                <div className="old-balance">{previousBalance}</div>
                <div className="new-balance">{newBalance}</div>
            </div>
        );
    }
}
