import { AtTableColumn, AtTableItemKeyTypes } from "./types";
import { IBaseEntity } from "@atman/core";
import { toJS } from "mobx";

export function getTableColumnsValueTypes<T extends IBaseEntity>(
    columns: AtTableColumn<T>[],
    items: T[],
): AtTableItemKeyTypes {
    const keyTypes: AtTableItemKeyTypes = {};

    if (items && items.length > 0) {
        columns.forEach((column) => {
            if (items[0][column.fieldName] === null) {
                /* To force the type to be string instead of object when the value is null
                 The only use case of this is in Contact list displayName can be null so the type is object and
                 it cause issue for the sort
                */
                return (keyTypes[`${column.key}`] = "string");
            }
            return (keyTypes[`${column.key}`] = typeof toJS(items[0][column.fieldName]));
        });
    }

    return keyTypes;
}
