import "./index.less";
import {
    AnalysisTileEnum,
    AnalyzedCandidate,
    AssessmentsResultsApi,
    Candidate,
    IAnalyzedCandidate,
    IPersonScores,
    IShortCandidate,
} from "@atman/business";
import { AtSidePanelLayout } from "../..";
import { BaseForm } from "../../BaseForm";
import { IReactSelectOptionObject } from "@atman/design-system";
import { IReactionDisposer, action, autorun, observable, reaction, runInAction } from "mobx";
import { TeamAnalysisLoadingStateKeys, ViewFilterType } from "../";
import { computedFn } from "mobx-utils";
import { createRef } from "react";

export interface IBaseAssessmentResultsContainerProps {}

export abstract class BaseAssessmentResultsContainer<TProps = {}, TState = {}> extends BaseForm<
    IBaseAssessmentResultsContainerProps & TProps,
    TState
> {
    protected readonly noneOption: IReactSelectOptionObject<any> = { value: "", label: "global.none".localize() };
    protected readonly supportedTiles: AnalysisTileEnum[] = [];
    @observable protected disabledTiles: AnalysisTileEnum[];

    protected abstract get _disabledTiles(): AnalysisTileEnum[];
    protected abstract get assessmentIds(): string[];
    protected abstract get dimensionScores(): IPersonScores[];

    @observable public activeTile: AnalysisTileEnum =
        this.supportedTiles.firstOrDefault() ?? AnalysisTileEnum.Competencies;
    @observable public tileSelectedOptions: Map<AnalysisTileEnum, IReactSelectOptionObject<any>> = new Map<
        AnalysisTileEnum,
        IReactSelectOptionObject<any>
    >();
    @observable public currentViewFilters: Map<AnalysisTileEnum, ViewFilterType> = new Map<
        AnalysisTileEnum,
        ViewFilterType
    >();
    @observable public candidateScoreMap: Map<string, Dictionary<string, number>> = new Map<
        string,
        Dictionary<string, number>
    >();
    @observable public loadingStates: Map<TeamAnalysisLoadingStateKeys, boolean> = new Map<
        TeamAnalysisLoadingStateKeys,
        boolean
    >([
        ["kpiScore", false],
        ["candidateScore", false],
        ["document", false],
        ["distributionData", false],
    ]);

    protected atSidePanelLayoutRef: React.RefObject<AtSidePanelLayout> = createRef();

    activeTileReactionDisposer: IReactionDisposer = reaction(
        () => this.activeTile && this.tileSelectedOptions.get(this.activeTile)?.value,
        () => {
            const activeTileSelectedOption = this.tileSelectedOptions.get(this.activeTile)?.value;

            if (activeTileSelectedOption && activeTileSelectedOption !== this.noneOption.value) {
                this.atSidePanelLayoutRef.current?.hideSidePanelInMobile();
            }
        },
    );

    settingsForTileReactionDisposer: IReactionDisposer = reaction(
        () => this.activeTile,
        () => this.setSettingsForTile(),
    );

    constructor(props) {
        super(props);

        autorun(() => {
            this.disabledTiles = this._disabledTiles;
        });
    }

    componentDidMount(): void {
        this.setInitialValues();
        this.loadCandidateScores();
    }

    componentWillUnmount(): void {
        this.settingsForTileReactionDisposer();
        this.activeTileReactionDisposer();
    }

    populateAnalyzedCandidateData: (candidate: Candidate | IShortCandidate) => AnalyzedCandidate = computedFn(
        (candidate) => {
            const scores = this.candidateScoreMap.get(candidate.assessmentId);

            const json = {
                candidateProId: (candidate as Candidate).id ?? (candidate as IShortCandidate).candidateProId,
                firstName: candidate.firstName,
                lastName: candidate.lastName,
                email: candidate.email,
                candidateState: candidate.candidateState,
                assessmentId: candidate.assessmentId,
                profilePictureUri: candidate.profilePictureUri,
                dimensionScores: scores ?? {},
                jobId: candidate.jobId,
                jobName: candidate.jobName,
                completedAssessmentTypes: candidate.completedAssessmentTypes,
                requestedAssessmentTypes: candidate.requestedAssessmentTypes,
                analyzedAssessmentTypes: candidate.hasOwnProperty("analyzedAssessmentTypes")
                    ? (candidate as Candidate).analyzedAssessmentTypes
                    : undefined,
                competencyEnabled: candidate.hasOwnProperty("competencyEnabled")
                    ? (candidate as Candidate).competencyEnabled
                    : undefined,
            } as IAnalyzedCandidate;

            return new AnalyzedCandidate(json);
        },
    );

    @action setInitialValues = () => {
        this.activeTile = this.supportedTiles.firstOrDefault() ?? AnalysisTileEnum.Competencies;
        this.tileSelectedOptions = new Map<AnalysisTileEnum, IReactSelectOptionObject<any>>(
            this.supportedTiles.map((x) => [x, this.noneOption]),
        );
        this.currentViewFilters = new Map<AnalysisTileEnum, ViewFilterType>(
            this.supportedTiles.map((x) => [x, "showAll"]),
        );
    };

    @action setSettingsForTile = () => {
        switch (this.activeTile) {
            case AnalysisTileEnum.Distribution:
                this.currentViewFilters.set(this.activeTile, "showAll");
                break;
            case AnalysisTileEnum.Competencies:
            case AnalysisTileEnum.JobFit:
            case AnalysisTileEnum.CultureFit:
            case AnalysisTileEnum.Potential:
            case AnalysisTileEnum.CompatibilityFit:
                // TODO @cvincent 2020-03-27: Add logic for KPI Management
                break;
        }
    };

    @action loadCandidateScores = async () => {
        this.loadingStates.set("candidateScore", true);
        this.candidateScoreMap.clear();

        const scores = await AssessmentsResultsApi.getRawScores(this.assessmentIds);

        runInAction(() => {
            this.loadingStates.set("candidateScore", false);

            for (const assessmentId of Object.keys(scores)) {
                this.candidateScoreMap.set(assessmentId, scores[assessmentId]);
            }
        });
    };

    @action onTileChange = (tile: AnalysisTileEnum) => {
        const selectedOption = this.tileSelectedOptions.get(tile);
        if (
            (this.isNullOrUndefined(selectedOption) || this.isStringNullEmptyOrUndefined(selectedOption!.value)) &&
            ![AnalysisTileEnum.Assessments, AnalysisTileEnum.Distribution].includes(tile)
        ) {
            return;
        }

        this.activeTile = tile;
    };

    @action onTileSelectedOptionsChange = (tile: AnalysisTileEnum, option: IReactSelectOptionObject<any>) => {
        this.tileSelectedOptions.set(tile, option);
        this.onTileChange(tile);
        this.scrollContentViewToTop();
    };

    @action onCurrentViewFiltersChange = (tile: AnalysisTileEnum, option: ViewFilterType) => {
        const currentViewFilterForTile = this.currentViewFilters.get(this.activeTile);

        this.currentViewFilters.set(tile, option);

        if (currentViewFilterForTile !== option) {
            this.scrollContentViewToTop();
        }
    };

    scrollContentViewToTop = () => {
        this.atSidePanelLayoutRef.current!.mainContentViewRef.current?.scrollTo({
            behavior: "smooth",
            top: 0,
        });
    };
}
