import { ApiError } from "../types";

export function parseError(e: any): Array<ApiError> {
    if (e.hasOwnProperty("errors") && Array.isArray(e.errors)) {
        return e.errors as ApiError[];
    }

    if ("code" in e && "message" in e && "fieldName" in e && "resourceIdentifier" in e) {
        return [e as ApiError];
    }

    return [];
}
