import {
    AppScope,
    Client,
    Culture,
    CultureStore,
    ItemType,
    ModalStore,
    ScopedOrganizationStore,
} from "@atman/business";
import { AtButton, AtTableColumn, TableItemButtons, TableItemLabel, TableItemTitle } from "@atman/design-system";
import { CustomAlert, CustomDeleteAlert } from "../../components";
import { DropdownItem } from "reactstrap";
import { t } from "@lingui/macro";
import React from "react";

const getCulturesTableColumns = (
    scope: AppScope,
    cultureStore: CultureStore | undefined,
    modalStore: ModalStore | undefined,
    scopedOrganizationStore: ScopedOrganizationStore | undefined,
    editCulture: (id: string) => void,
    duplicateAction: (id: string) => void,
    cultures: Culture[],
    isMobile?: boolean,
): AtTableColumn<Culture>[] => [
    {
        key: "culture",
        fieldName: "name",
        align: "left",
        label: t({ id: "global.name" }),
        sort: true,
        displayOverSize: "xs",
        width: "fit-content",
        filter: {
            type: "freeText",
        },
        render: (name) => {
            return <TableItemTitle maxWidth={200} title={name} />;
        },
    },

    {
        key: "competency",
        fieldName: "competencyIds",
        align: "left",
        label: t({ id: "competencyDevelopment.global.competencies" }),
        sort: (a, b) => a.competencyIds.length - b.competencyIds.length,
        displayOverSize: "lg",
        width: 140,
        filter: {
            type: "numberRange",
            customName: t({ id: "global.numberOfCompetencies" }),
            min: 0,
            max: Math.max(...cultures.map((culture) => culture.competencyIds.length)),
        },
        render: (competencyIds: string[]) => {
            return <TableItemLabel maxWidth={140} content={competencyIds.length.toString()} />;
        },
    },
    {
        key: "clientId",
        fieldName: "clientId",
        align: "right",
        displayOverSize: "sm",
        width: 140,
        render: (_clientId: string, record) => {
            const archiveAction = () =>
                new CustomDeleteAlert(ItemType.Culture, record.name, () => cultureStore!.deleteItem(record.id)).fire();

            const ctaAction = () => editCulture(record.id);

            const isAssignedToOrg = (scopedOrganizationStore!.scopedOrganization as Client)?.cultureId === record.id;
            const isAssignedToTeams = record.teamsCount > 0;

            const assignToOrganization = () => cultureStore!.assignCultureToOrganization(record.id);

            const openFormSubmitConfirmationAlert = () => {
                const finalizeAssessmentAlert = new CustomAlert(
                    assignToOrganization,
                    "clientApp.confirmation.assignToOrganization.title".localize(),
                    "clientApp.confirmation.assignToOrganization".localize(),
                    {},
                    "clientApp.compDevStartProcess.alert.confirmButton".localize(),
                );

                finalizeAssessmentAlert.fire(undefined, isMobile);
            };

            return (
                <TableItemButtons
                    secondaryButton={
                        <AtButton size={"md"} color={"secondary"} onClick={ctaAction}>
                            {t({ id: "global.edit" })}
                        </AtButton>
                    }
                    menuContent={
                        <>
                            <DropdownItem onClick={() => duplicateAction(record.id)}>
                                {"global.buttons.labels.duplicate".localize()}
                            </DropdownItem>
                            {scope === AppScope.Client && (
                                <>
                                    <DropdownItem onClick={openFormSubmitConfirmationAlert} disabled={isAssignedToOrg}>
                                        {"clientApp.assignToOrganization".localize()}
                                    </DropdownItem>
                                </>
                            )}
                            <DropdownItem onClick={archiveAction} disabled={isAssignedToOrg || isAssignedToTeams}>
                                {"global.archive".localize()}
                            </DropdownItem>
                        </>
                    }
                />
            );
        },
    },
];

export default getCulturesTableColumns;
