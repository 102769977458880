import "./index.less";
import { AtSubtitle, AtText, AtTitle, DefaultComponentProps, DefaultSizes, HeadingType, XL } from "../../../internal";
import { XS } from "@atman/design-system";
import React from "react";
import cn from "classnames";

export interface IAtTitleSubtitleProps extends DefaultComponentProps {
    title: string;
    subtitle?: string;
    description?: string;
    size?: DefaultSizes | XL;
    reverseTitles?: boolean;
    onClick?: () => void;
    className?: string;
    headerSize?: HeadingType;
    noMargin?: boolean;
}

export const AtTitleSubtitle: React.FC<IAtTitleSubtitleProps> = (props) => {
    const {
        title,
        subtitle,
        description,
        size = "md",
        reverseTitles: reverse,
        onClick,
        className,
        headerSize,
        noMargin = false,
        ...otherProps
    } = props;

    const getTitleHeadingType: () => HeadingType = () => {
        if (headerSize) {
            return headerSize;
        }

        switch (size) {
            case "sm":
                return 5;
            case "md":
                return 4;
            case "lg":
                return 3;
            case "xl":
                return 2;
        }
    };

    const getSubtitleSize: () => DefaultSizes | XS = () => {
        switch (size) {
            case "sm":
                return "xs";
            case "md":
                return "sm";
            case "lg":
                return "md";
            case "xl":
                return "lg";
        }
    };

    return (
        <div className={cn("AtTitleSubtitle", className)} onClick={onClick} {...otherProps}>
            {reverse && subtitle && <AtSubtitle subtitle={subtitle} size={getSubtitleSize()} />}
            <AtTitle title={title} headingType={getTitleHeadingType()} noMargin={!subtitle || noMargin} />
            {!reverse && subtitle && <AtSubtitle subtitle={subtitle} size={getSubtitleSize()} />}
            {description && <AtText className="description">{description}</AtText>}
        </div>
    );
};
