import "./AddExistingUserForm.less";
import {
    AppScope,
    ClientStore,
    GlobalStores,
    IAppContext,
    IAssignableRoles,
    ModalStore,
    Role,
    UnhandledScopeError,
    UserInfoStore,
    UserStore,
    UsersApi,
} from "@atman/business";
import {
    AtAsyncSelect,
    AtCheckboxTitleSubtitle,
    AtTabCard,
    IReactSelectOptionObject,
    RadioFormInput,
} from "@atman/design-system";
import { BaseForm, BaseModal, FormGroupSection, ModalButtons } from "../../components";
import { Col, Row } from "reactstrap";
import { action, observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";
import React from "react";
import autobind from "autobind-decorator";

export interface IAddExistingUserFormProps extends IAppContext {
    userInfoStore?: UserInfoStore;
    userStore?: UserStore;
    modalStore?: ModalStore;
    clientStore?: ClientStore;
}

@inject(GlobalStores.userInfoStore, GlobalStores.userStore, GlobalStores.modalStore, GlobalStores.clientStore)
@observer
class AddExistingUserFormComp extends BaseForm<IAddExistingUserFormProps, {}> {
    @observable public selectedUserId?: IReactSelectOptionObject;
    @observable public baseRole: Role;
    @observable public additionalRoles: Role[] = [];
    @observable public assignableRoles: IAssignableRoles<Role> = {
        baseRoles: [],
        additionalRoles: [],
        additionalRolesByBaseRole: {},
    };

    async componentDidMount() {
        await this.loadAssignableRoles();
    }

    @action.bound
    loadAssignableRoles = async () => {
        const { modalStore, userInfoStore, userStore, scope } = this.props;

        const externalUsers = modalStore!.childProps && (modalStore!.childProps!.externalUsers as boolean);
        const clientId = modalStore!.childProps && (modalStore!.childProps!.clientId as string);

        let result: IAssignableRoles | undefined;

        switch (scope) {
            case AppScope.Partner:
                result = await userStore!.getAssignableRoles(
                    userInfoStore!.partnerId,
                    externalUsers ? clientId : undefined,
                );
                break;
            default:
                throw new UnhandledScopeError(scope);
        }

        if (!result) {
            return;
        }

        const resultInEnum: IAssignableRoles<Role> = {
            baseRoles: result.baseRoles.map((x) => Role[x]),
            additionalRoles: result.additionalRoles.map((x) => Role[x]),
            additionalRolesByBaseRole: {},
        };

        runInAction(() => {
            this.assignableRoles = resultInEnum;
            this.baseRole = Role[result!.baseRoles[0]];
        });
    };

    @autobind
    async _onSave() {
        const { modalStore, userStore } = this.props;

        if (!this.selectedUserId) {
            return;
        }

        const clientId = modalStore!.childProps && (modalStore!.childProps!.clientId as string);

        if (!clientId) {
            return;
        }

        await userStore!.addAdminFromExistingUser(
            this.selectedUserId.value,
            clientId,
            this.baseRole,
            this.additionalRoles,
        );

        if (!userStore!.hasErrored) {
            modalStore!.toggleModal();
        }
    }

    validateForm(): boolean {
        return !!this.selectedUserId && !!this.baseRole;
    }

    @action.bound
    onAdditionalRolesChange(event: React.FormEvent<HTMLInputElement>) {
        const selectedRole = Number((event.target as any).value) as Role;
        const index = this.additionalRoles.indexOf(selectedRole);

        if (index >= 0) {
            this.additionalRoles.splice(index, 1);
        } else {
            this.additionalRoles.push(selectedRole);
        }
    }
    @action.bound
    onBaseRolesChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value = (event as any).target.value;

        this.baseRole = Number(value);
    };

    @action.bound
    loadUsersOptions = async (searchTerm: string) => {
        const { modalStore } = this.props;

        return await this.loadAsyncSelectOptions("loadUsers", async () => {
            const clientId = modalStore!.childProps && (modalStore!.childProps!.clientId as string);
            const results = await UsersApi.searchPartnerClientUsers(searchTerm, clientId);

            return results.map((x) => ({ value: x.id, label: x.label }));
        });
    };

    onUserSelectionChange = (options) => {
        this.selectedUserId = options;
    };

    render() {
        const { scope, userStore } = this.props;

        return (
            <BaseModal modalTitle={"partnerApp.addExistingUser".localize()} id="AddExistingUserForm">
                <FormGroupSection sectionTitle={"partnerApp.userSelection".localize()} id={"user-selection-form"}>
                    <Row>
                        <Col sm={6}>
                            <AtAsyncSelect
                                label={"partnerApp.selectUser".localize()}
                                loadOptions={this.loadUsersOptions}
                                value={this.selectedUserId}
                                onChange={this.onUserSelectionChange}
                                placeholder={"partnerApp.userSelectionAutoCompletePlaceholder".localize()}
                                cacheOptions
                                defaultOptions
                            />
                        </Col>
                    </Row>
                </FormGroupSection>
                <FormGroupSection sectionTitle={"global.userRoles".localize()} id={"user-roles-form"}>
                    <Row className="base-roles-selection">
                        {this.assignableRoles.baseRoles.map((x, i) => (
                            <Col sm={6} key={`role-${x}`}>
                                <RadioFormInput
                                    fieldName={`baseRoles-${i}`}
                                    value={x}
                                    onChange={this.onBaseRolesChange}
                                    checked={this.baseRole === x}
                                    valueText={`global.role.${Role[Number(x)].toCamel()}`.localize()}
                                    formText={`global.roleDescription.${Role[Number(x)]}`.localize()}
                                    key={i}
                                />
                            </Col>
                        ))}
                    </Row>
                    <AtTabCard cardTitle={"global.additionalPermissions".localize()}>
                        <div className="additional-roles-selection">
                            {this.assignableRoles.additionalRoles.map((x, i) => (
                                <AtCheckboxTitleSubtitle
                                    id={`role-checkbox-${Role[x]}`}
                                    title={`global.role.${Role[x].toCamel()}`.localize()}
                                    subtitle={`global.roleDescription.${Role[x]}`.localize()}
                                    value={x}
                                    checked={!!this.additionalRoles.find((role) => role === x)}
                                    onChange={this.onAdditionalRolesChange}
                                    key={i}
                                />
                            ))}
                        </div>
                    </AtTabCard>
                    {scope !== AppScope.Client ? (
                        <ModalButtons
                            saveAction={this._onSave}
                            saveLabel={"global.inviteUser".localize()}
                            isLoading={userStore!.isLoading}
                        />
                    ) : null}
                </FormGroupSection>
            </BaseModal>
        );
    }
}

const AddExistingUserForm = withAppContext(AddExistingUserFormComp);

export { AddExistingUserForm };
