import "./index.less";
import * as React from "react";
import { AtCollapse } from "@atman/design-system";
import {
    CompDevStrategicObjectiveResult,
    CompDevSubCompetenciesStrategicObjectiveResult,
    CompDevSubCompetencyResult,
    PrintModeEnum,
} from "@atman/business";
import { IObjectiveWithCompetency } from "@atman/business/lib/models/CompetencyDevelopment/IObjectiveWithCompetency";
import { observer } from "mobx-react";

export interface ICompDevActionPlanObjectiveRowProps {
    objective: IObjectiveWithCompetency<
        CompDevSubCompetenciesStrategicObjectiveResult | CompDevStrategicObjectiveResult
    >;
    startOpen?: boolean;
    printMode?: PrintModeEnum;
}

export const CompDevActionPlanObjectiveRow: React.FC<ICompDevActionPlanObjectiveRowProps> = observer((props) => {
    const { objective, startOpen, printMode, children } = props;

    const renderToggleRow = () => {
        return (
            <div className="objective-row-header">
                asdasasd
                <div className="objective-description">
                    {objective.data instanceof CompDevSubCompetencyResult
                        ? objective.data.strategicObjectives
                        : objective.data.description}
                </div>
            </div>
        );
    };

    return (
        <AtCollapse
            className="CompDevActionPlanObjectiveRow"
            chevronSize="1x"
            defaultOpen={startOpen || printMode !== undefined}
            toggleRowContent={renderToggleRow()}
            collapseContentOffset={printMode === undefined}
        >
            {children}
        </AtCollapse>
    );
});
