import "./index.less";
import * as React from "react";
import {
    AtBadge,
    AtContainer,
    AtParagraph,
    AtTitle,
    IAtContainerProps,
    Sizing,
    tint,
} from "@atman/design-system/lib/internal";
import { ReportPageHeader, StylesContext } from "@atman/ui-kit";

export interface ISummaryCardProps extends IAtContainerProps {
    title: string;
    description: string;
    summaryLogo: JSX.Element;
    qualityTitle?: string;
    tags?: string[];
    isPrint?: boolean;
    color?: string;
    context?: StylesContext;
    bannerSvg?: string;
    stylesNarrative?: string;
}

export const SummaryCard: React.FC<ISummaryCardProps> = (props) => {
    const {
        title,
        tags,
        description,
        isPrint = false,
        color,
        qualityTitle,
        bannerSvg,
        stylesNarrative,
        ...othersProps
    } = props;

    const displayTags = tags?.map((tag, index) => (
        <AtBadge key={index} size="lg" customColor={color && tint(color, 75)}>
            {tag}
        </AtBadge>
    ));

    return (
        <AtContainer className="SummaryCard" padding={Sizing.paddings.md} {...othersProps}>
            <div className="header">
                {bannerSvg && <ReportPageHeader size={"lg"} imageUrl={bannerSvg} description={stylesNarrative} />}
            </div>

            {isPrint
                ? description.split("\n").map((x) => <AtParagraph key={x}>{x}</AtParagraph>)
                : description && (
                      <div className="bottomSection">
                          <div>
                              <AtTitle title={title} headingType={4} />
                          </div>
                          <ul>
                              {description.split("\n").map((x) => (
                                  <li key={x}>{x}</li>
                              ))}
                          </ul>
                      </div>
                  )}

            <div className="summaryCardHeaderContentWrapper inTeamsDesign">
                <AtTitle title={qualityTitle} headingType={4} />
                {displayTags && <div className="tagsContainer">{displayTags}</div>}
            </div>
        </AtContainer>
    );
};
