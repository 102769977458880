import "./AtDownChevronMenuButton.less";
import * as React from "react";
import { AtMenuButton, IAtMenuButtonProps } from "..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { inject, observer } from "mobx-react";

export interface IAtDownArrowMenuButtonProps extends IAtMenuButtonProps {
    label: string;
    icon?: IconProp;
}

@inject()
@observer
export class AtDownChevronMenuButton extends React.Component<IAtDownArrowMenuButtonProps, {}> {
    render() {
        const { label, icon, className, ...otherProps } = this.props;

        return (
            <AtMenuButton
                className={`AtDownChevronMenuButton ${className ?? ""}`}
                customButtonContent={
                    <>
                        {icon && (
                            <span className="icon">
                                <FontAwesomeIcon icon={icon} />
                            </span>
                        )}
                        <span className="label">{label}</span>
                        <span className="chevron">
                            <FontAwesomeIcon icon={["fas", "chevron-down"]} />
                        </span>
                    </>
                }
                {...otherProps}
            />
        );
    }
}
