import "./index.less";
import * as React from "react";
import { AtCheckboxTitleSubtitle, AtTitle } from "@atman/design-system";
import { observer } from "mobx-react";

export interface ICompDevRequiredAssessmentsSelectorProps {
    optionalManagerAssessment: boolean | undefined;
    optionalSelfAssessment: boolean | undefined;
    onOptionalManagerAssessmentChange: () => void;
    onOptionalSelfAssessmentChange: () => void;
}

export const CompDevRequiredAssessmentsSelector: React.FC<ICompDevRequiredAssessmentsSelectorProps> = observer(
    (props) => {
        const {
            optionalManagerAssessment = true,
            optionalSelfAssessment = true,
            onOptionalManagerAssessmentChange,
            onOptionalSelfAssessmentChange,
        } = props;

        return (
            <div className="CompDevRequiredAssessmentsSelector">
                <AtTitle
                    title={"clientApp.competencyDevelopment.requiredAssessments.title".localize()}
                    headingType={3}
                />
                <AtCheckboxTitleSubtitle
                    id="cbManagerAssessment"
                    title={"clientApp.competencyDevelopment.requiredAssessments.managerAssessment.title".localize()}
                    subtitle={"clientApp.competencyDevelopment.requiredAssessments.managerAssessment.description".localize()}
                    size="md"
                    checked={!optionalManagerAssessment}
                    onChange={onOptionalManagerAssessmentChange}
                />

                <AtCheckboxTitleSubtitle
                    id="cbSelfAssessment"
                    title={"clientApp.competencyDevelopment.requiredAssessments.selfAssessment.title".localize()}
                    subtitle={"clientApp.competencyDevelopment.requiredAssessments.selfAssessment.description".localize()}
                    size="md"
                    checked={!optionalSelfAssessment}
                    onChange={onOptionalSelfAssessmentChange}
                />
            </div>
        );
    },
);
