import { i18n } from "@lingui/core";

/**
 * @deprecated
 */
export class Localizer {
    static localizeForLanguage(resourceKey: string, languageCode: string) {
        // TODO: Find a way to use languageCode
        return i18n._(resourceKey);
    }
}
