import * as React from "react";
import { CompetencyDevelopmentColumnType, getSelectionProps } from "../../../../../..";
import { ITableHeaderProps, SortDirection, TableHeader } from "../../../../../../../..";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";

export interface ISpreadTableHeaderColProps {
    sortElement?: CompetencyDevelopmentColumnType;
    sortDirection?: SortDirection;
    onSortChange?: (element: CompetencyDevelopmentColumnType) => void;
}

export const SpreadTableHeaderCol: React.FC<ISpreadTableHeaderColProps> = observer((props) => {
    const { sortElement, sortDirection, onSortChange } = props;

    const selectionProps: Pick<ITableHeaderProps, "sortDirection" | "isActive" | "onClick"> | undefined =
        getSelectionProps("spread", sortElement, sortDirection, onSortChange);

    return (
        <TableHeader
            className="spread-col"
            {...selectionProps}
            tooltipContent={
                <div className="textSizeMedium">{t({ id: "competencyDevelopment.review.tooltips.spread" })}</div>
            }
        >
            {"competencyDevelopment.global.spread".localize()}
        </TableHeader>
    );
});
