import "./index.less";
import * as React from "react";
import {
    AtIcon,
    CircleContainer,
    DefaultComponentProps,
    ExtraAndDefaultSizes,
    InitialsAvatar,
    XXL,
    tint,
} from "../../../internal";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

export interface IAtAvatarProps extends DefaultComponentProps {
    pictureUri?: string;
    avatarText?: string | (string | undefined)[];
    displayIconInsteadOfAvatar?: boolean;
    size?: ExtraAndDefaultSizes | XXL;
    active?: boolean;
    borderColor?: string;
    backgroundColor?: string;
}

export const AtAvatar: React.FC<IAtAvatarProps> = (props) => {
    const {
        pictureUri,
        avatarText,
        displayIconInsteadOfAvatar = false,
        size = "sm",
        active = false,
        className,
        borderColor,
        backgroundColor,
        ...otherProps
    } = props;

    const getClassNames: () => string = () => {
        let classNames = `AtAvatar avatar-${size}`;

        if (className) {
            classNames += ` ${className}`;
        }

        return classNames;
    };

    const customBackgroundColor = React.useMemo(() => {
        if (!backgroundColor) {
            return undefined;
        }

        return tint(backgroundColor, 45);
    }, [backgroundColor]);

    const getAvatarContent: () => JSX.Element = () => {
        if (pictureUri) {
            return <img src={pictureUri} />;
        } else if (displayIconInsteadOfAvatar) {
            let iconSize: SizeProp;
            switch (size) {
                case "xs":
                    iconSize = "xs";
                    break;
                case "sm":
                    iconSize = "sm";
                    break;
                case "md":
                    iconSize = "1x";
                    break;
                case "lg":
                    iconSize = "lg";
                    break;
                case "xl":
                    iconSize = "2x";
                    break;
                case "xxl":
                    iconSize = "3x";
                    break;
            }
            return <AtIcon icon={["far", "user-circle"]} size={iconSize} {...otherProps} />;
        } else {
            return <InitialsAvatar text={avatarText} active={active} size={size} bgColor={customBackgroundColor} />;
        }
    };

    return (
        <CircleContainer borderColor={borderColor} className={getClassNames()} {...otherProps}>
            {getAvatarContent()}
        </CircleContainer>
    );
};
