import "./AtCustomizableProperty.less";
import * as React from "react";
import { Label } from "reactstrap";

export interface IAtCustomizablePropertyProps {
    label?: string;
    value: React.ReactNode;
}

export const AtCustomizableProperty: React.FC<IAtCustomizablePropertyProps> = (props) => {
    const { label, value } = props;

    return (
        <div className={`AtCustomizableProperty ${label === undefined ? "no-label" : ""}`}>
            {label !== undefined ? <Label className={"property-label"}>{label}</Label> : null}
            <div className={`property-value-container ${typeof value === "string" ? "string-property" : ""}`}>
                {value}
            </div>
        </div>
    );
};
