import "./index.less";
import * as React from "react";
import { AtPieChart, AtText, AtTitle, ChartDataEntry } from "@atman/design-system";
import { AtStyleSvgs } from "..";
import { AtmanIcon } from "../AtmanIcon";
import { observer } from "mobx-react";

export interface IAtStylesPieViewProps {
    data: ChartDataEntry[];
    subtitle?: string;
    isInPrintMode?: boolean;
}

export const AtStylesPieView: React.FC<IAtStylesPieViewProps> = observer((props) => {
    const { data, subtitle, isInPrintMode } = props;
    return (
        <div id="AtStylesPieView">
            {subtitle && <AtText className="graphSubtitle"> {subtitle} </AtText>}
            <div className="chart-container">
                <AtPieChart data={data.map(({ label, ...other }) => other)} />
            </div>
            <div className="legend-container">
                <div className="legend-items-list">
                    {data.map((x) => (
                        <div className={`style-legend-item ${x.uniqueIdentifiableKey}`} key={x.id}>
                            <div className="left-section">
                                {!isInPrintMode && <AtmanIcon color={x.color} />}
                                <AtStyleSvgs uniqueIdentifiableKey={x.uniqueIdentifiableKey} />
                            </div>
                            <div className="right-section">
                                <AtTitle
                                    title={x.value < 1 ? "< 1%" : `${x.value.toFixed(0)}%`}
                                    className="score-percentage"
                                    noMargin
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});
