import "./index.less";
import { AtButton, DefaultComponentProps } from "@atman/design-system";
import { BaseResponsiveComponent } from "../../../BaseResponsiveComponent";
import { observer } from "mobx-react";
import React from "react";

export interface IAssessmentWithFooterContainerProps extends DefaultComponentProps {
    btnAction: () => void;
    btnLabel: string;
    isLoading: boolean;
    footerContent?: JSX.Element;
}

@observer
export class AssessmentWithFooterContainer extends BaseResponsiveComponent<IAssessmentWithFooterContainerProps, {}> {
    render(): JSX.Element {
        const { children, btnAction, btnLabel, isLoading, footerContent, className, id, style } = this.props;

        let footerContentClasses: string = "footer-content";

        if (this.isDefault) {
            footerContentClasses += ` d-flex align-items-center justify-content-${footerContent ? "between" : "end"}`;
        }

        return (
            <div className={`AssessmentWithFooterContainer ${className ?? ""}`} id={id} style={style}>
                <div className="scrollable-container">
                    <div className="assessment-container">{children}</div>
                </div>
                <div className="competency-development-footer">
                    <div className={footerContentClasses}>
                        {footerContent && <div>{footerContent}</div>}
                        <AtButton
                            onClick={btnAction}
                            color={"primary"}
                            size={this.isMobile ? "xl" : "lg"}
                            isLoading={isLoading}
                        >
                            {btnLabel}
                        </AtButton>
                    </div>
                </div>
            </div>
        );
    }
}
