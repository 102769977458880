import "./index.less";
import {
    AppScope,
    ClientStore,
    GlobalStores,
    IAppContext,
    IProductConsumptionModeScheduleEntryModel,
    ModalStore,
    ModalTypes,
    PartnerStore,
    ProductConsumptionMode,
    UnhandledScopeError,
} from "@atman/business";
import { AtButton, AtLink, AtTabCard } from "@atman/design-system";
import { Col, Container, Row } from "reactstrap";
import { DeleteConfirmationAlert } from "../Alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { UsageModel } from "@atman/business";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";
import React from "react";
import autobind from "autobind-decorator";
import moment from "moment";

export interface IConsumptionSchedulingFormProps extends IAppContext {
    ownerId: string;
    usageModel: UsageModel;
    modalStore?: ModalStore;
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
}

type ConsumptionSchedulingView = "active" | "scheduled" | "ended";

@inject(GlobalStores.modalStore, GlobalStores.clientStore, GlobalStores.partnerStore)
@observer
class ConsumptionSchedulingFormComp extends React.Component<IConsumptionSchedulingFormProps, {}> {
    @observable public activeConsumptionSchedulingView: ConsumptionSchedulingView = "active";

    @action.bound
    onFilterChanged = (view: ConsumptionSchedulingView) => {
        this.activeConsumptionSchedulingView = view;
    };

    @autobind
    async openDeleteScheduleEntryConfirmationAlert(entryId: string) {
        // TODO @cvincent - 2019-09-05: Change for new CustomDeleteAlert();
        new DeleteConfirmationAlert(async () => {
            switch (this.props.scope) {
                case AppScope.Partner:
                    await this.props.clientStore!.removeClientConsumptionScheduleEntry(this.props.ownerId, entryId);
                    break;
                case AppScope.Supplier:
                    await this.props.partnerStore!.removePartnerConsumptionScheduleEntry(this.props.ownerId, entryId);
                    break;
                default:
                    throw new UnhandledScopeError(this.props.scope);
            }
        }, "global.deleteScheduleEntryConfirmationAlertText".localize()).fire();
    }

    @autobind
    async endScheduleEntry(entryId: string) {
        const { usageModel } = this.props;

        const entry = usageModel.productConsumptionModeSchedule.find((x) => x.entryId === entryId);

        if (!entry) {
            return;
        }

        const model = {
            entryId: entryId,
            consumptionMode: entry.consumptionMode,
            effectiveDate: entry.effectiveDate,
            endDate: moment().startOf("D").subtract(1, "day").toISOString(true),
            expectedConsumption: entry.expectedConsumption,
        } as IProductConsumptionModeScheduleEntryModel;

        switch (this.props.scope) {
            case AppScope.Partner:
                await this.props.clientStore!.updateClientConsumptionScheduleEntry(this.props.ownerId, model);
                break;
            case AppScope.Supplier:
                await this.props.partnerStore!.updatePartnerConsumptionScheduleEntry(this.props.ownerId, model);
                break;
            default:
                throw new Error(`Unhandled scope for action: ${this.props.scope}`);
        }
    }

    private getStatusForScheduleEntry = (entry: IProductConsumptionModeScheduleEntryModel) => {
        const now = moment();

        if (now.isAfter(entry.effectiveDate) && (!entry.endDate || now.isBefore(entry.endDate))) {
            return "active";
        } else if (now.isBefore(entry.effectiveDate)) {
            return "scheduled";
        } else if (now.isAfter(entry.endDate)) {
            return "ended";
        }

        return "";
    };

    render() {
        const { modalStore, usageModel, ownerId } = this.props;

        const now = moment();
        let filteredResults: IProductConsumptionModeScheduleEntryModel[] = [];

        switch (this.activeConsumptionSchedulingView) {
            case "active":
                filteredResults = usageModel.productConsumptionModeSchedule.filter(
                    (x) => now.isAfter(x.effectiveDate) && (!x.endDate || now.isBefore(x.endDate)),
                );
                break;
            case "scheduled":
                filteredResults = usageModel.productConsumptionModeSchedule.filter((x) =>
                    now.isBefore(x.effectiveDate),
                );
                break;
            case "ended":
                filteredResults = usageModel.productConsumptionModeSchedule.filter((x) => now.isAfter(x.endDate));
                break;
        }

        return (
            <AtTabCard
                cardTitle={"global.planDuration".localize()}
                id={"ConsumptionSchedulingForm"}
                className={"consumption-scheduling-section"}
            >
                <div className="filters">
                    <span
                        className={`filter active-filter ${
                            this.activeConsumptionSchedulingView === "active" ? "selected" : ""
                        }`}
                        onClick={() => this.onFilterChanged("active")}
                    >
                        {"global.active".localize()}
                    </span>
                    <span
                        className={`filter scheduled-filter ${
                            this.activeConsumptionSchedulingView === "scheduled" ? "selected" : ""
                        }`}
                        onClick={() => this.onFilterChanged("scheduled")}
                    >
                        {"global.scheduled".localize()}
                    </span>
                    <span
                        className={`filter ended-filter ${
                            this.activeConsumptionSchedulingView === "ended" ? "selected" : ""
                        }`}
                        onClick={() => this.onFilterChanged("ended")}
                    >
                        {"global.ended".localize()}
                    </span>
                </div>
                <Container fluid className={"consumption-scheduling-table"}>
                    <Row className={"header-row"} noGutters>
                        <Col className={"table-header status-header"} sm={1}>
                            {"global.status".localize()}
                        </Col>
                        <Col className={"table-header consumption-type-header"} sm={2}>
                            {"global.plan".localize()}
                        </Col>
                        <Col className={"table-header expected-consumption-header"} sm={3}>
                            {"global.expectedUsage".localize()}
                        </Col>
                        <Col className={"table-header effective-date-header"} sm={2}>
                            {"global.effectiveDate".localize()}
                        </Col>
                        <Col className={"table-header end-date-header"} sm={2}>
                            {"global.endDate".localize()}
                        </Col>
                        <Col className={"table-header actions-header"} sm={2} />
                    </Row>
                    {filteredResults.map((x, i) => (
                        <Row className={"entry-row"} noGutters key={i}>
                            <Col className={"status"} sm={1}>
                                {`global.${this.getStatusForScheduleEntry(x)}`.localize()}
                            </Col>
                            <Col className={"consumption-type"} sm={2}>
                                {`global.productConsumptionModes.${ProductConsumptionMode[
                                    x.consumptionMode
                                ].toCamel()}`.localize()}
                            </Col>
                            <Col className={"expected-consumption"} sm={3}>
                                {x.expectedConsumption}
                            </Col>
                            <Col className={"effective-date"} sm={2}>
                                {moment.parseZone(x.effectiveDate).format("YYYY-MM-DD")}
                            </Col>
                            <Col className={"end-date"} sm={2}>
                                {x.endDate ? moment.parseZone(x.endDate).format("YYYY-MM-DD") : ""}
                            </Col>
                            <Col className={"actions"} sm={2}>
                                {this.getStatusForScheduleEntry(x) !== "ended" ? (
                                    <AtLink
                                        color={"secondary"}
                                        className="scheduling-form-edit-link"
                                        onClick={() =>
                                            modalStore!.openModal(ModalTypes.EditConsumptionScheduling, {
                                                entryId: x.entryId,
                                                ownerId,
                                            })
                                        }
                                    >
                                        {"global.edit".localize()}
                                    </AtLink>
                                ) : null}
                                {this.getStatusForScheduleEntry(x) === "scheduled" ? (
                                    <AtLink onClick={() => this.openDeleteScheduleEntryConfirmationAlert(x.entryId)}>
                                        <FontAwesomeIcon icon={["far", "trash"] as IconProp} />
                                    </AtLink>
                                ) : null}
                                {/*{*/}
                                {/*    this.getStatusForScheduleEntry(x) === "active"*/}
                                {/*        ? (*/}
                                {/*            <AtLink color={"secondary"} onClick={() => this.endScheduleEntry(x.entryId)}>*/}
                                {/*                { 'endNow'.localize() }*/}
                                {/*            </a>*/}
                                {/*        )*/}
                                {/*        : null*/}
                                {/*}*/}
                            </Col>
                        </Row>
                    ))}
                </Container>
                <div className="button-container">
                    <AtButton
                        color={"primary"}
                        onClick={() => modalStore!.openModal(ModalTypes.NewConsumptionScheduling, { ownerId })}
                    >
                        {"global.scheduleNew".localize()}
                    </AtButton>
                </div>
            </AtTabCard>
        );
    }
}

const ConsumptionSchedulingForm = withAppContext(ConsumptionSchedulingFormComp);

export { ConsumptionSchedulingForm };
