import "./index.less";
import { AtTitleSubtitle } from "../../../internal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export interface IToastContentContainerProps {
    onHide: () => void;
    title: string;
    subtitle?: string;
}

export const ToastContentContainer: React.FC<IToastContentContainerProps> = (props) => {
    const { onHide, title, subtitle, ...otherProps } = props;

    return (
        <div className="ToastContentContainer" {...otherProps}>
            <div className="content">
                <AtTitleSubtitle title={title} subtitle={subtitle} size="md" />
            </div>
            <div className="close-icon-container" onClick={onHide}>
                <FontAwesomeIcon icon={["far", "times"]} />
            </div>
        </div>
    );
};
