import "./index.less";
import { AtButton, AtCollapsibleCard, AtParagraph, AtSubtitle, AtText, AtTitle, Sizing } from "@atman/design-system";
import { AtStyleSvgs } from "../AtStyleSvgs";
import { AtStyleTeamMemberCard } from "./AtStyleTeamMemberCard";
import { AtStylesDefinitionStore, AtStylesTeamResult, GlobalStores, IAtStyleDefinition, Team } from "@atman/business";
import { DominantStyleRepartition } from "../DominantStyleRepartition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import React, { useMemo, useState } from "react";

export interface IAtStyleTeamDistribution {
    teamResult: AtStylesTeamResult;
    stylesDefinitions: IAtStyleDefinition[];
    team: Team;
    isInPrintMode?: boolean;
    atStylesDefinitionStore?: AtStylesDefinitionStore;
}

export const AtStyleTeamDistribution: React.FC<IAtStyleTeamDistribution> = inject(GlobalStores.atStylesDefinitionStore)(
    observer((props) => {
        const { teamResult, stylesDefinitions, team, isInPrintMode = false, ...otherProps } = props;

        const [highlightedId, setHighlightedId] = useState<string>();

        const stylesAndCounts = useMemo(() => {
            const results = stylesDefinitions
                .map((x) => ({
                    style: x,
                    count: teamResult.memberResults.sum((m) => (m.dominantStyleId === x.id ? 1 : 0)),
                }))
                .sort((a, b) => a.count - b.count);

            results.reverse();

            return {
                totalCount: Math.max(...results.map((x) => x.count)),
                results,
            };
        }, [stylesDefinitions, teamResult.memberResults]);

        const detailedMemberResults = useMemo(() => {
            const teamMembers = [...(team.members ?? []), ...(team.leads ?? [])];

            return stylesAndCounts.results.map((res) => {
                const membersInfo = teamResult.memberResults
                    .filter((x) => x.dominantStyleId === res.style.id)
                    .map((x) => {
                        const memberDetails = teamMembers.find((m) => m.candidateProId === x.candidateProId);

                        return {
                            result: x,
                            details: memberDetails,
                        };
                    });

                return {
                    styleId: res.style.id,
                    orderedMemberInfos: membersInfo.sort((a, b) => {
                        if (!a.details || !b.details) {
                            return 1;
                        }

                        if (a.details.lastName < b.details.lastName) {
                            return -1;
                        }
                        if (a.details.lastName > b.details.lastName) {
                            return 1;
                        }
                        return 0;
                    }),
                };
            });
        }, [stylesAndCounts.results, team.leads, team.members, teamResult.memberResults]);

        const topContent: JSX.Element = (
            <div className="styleDistributionTopContent">
                {isInPrintMode ? (
                    <AtTitle title={t({ id: "global.atmanStyles.teamDistributionTitle" })} headingType={1} />
                ) : (
                    <AtTitle
                        title={t({
                            id: "global.dominantContainerTitle",
                        })}
                        headingType={3}
                    />
                )}
            </div>
        );

        const handleHighlightClick = (id: string): void => {
            if (id === highlightedId) {
                setHighlightedId(undefined);
            } else {
                setHighlightedId(id);
            }
        };

        return (
            <AtCollapsibleCard
                className="AtStyleTeamDistribution"
                customLeftHeaderContent={topContent}
                bodyPadding={Sizing.paddings.collapsibleHeader}
                defaultCollapseOpenState
                hideChevron
                {...otherProps}
            >
                <AtSubtitle
                    className="dominantStyleSubtitle"
                    subtitle={t({ id: "global.dominantRepartitionDescription" })}
                />

                <DominantStyleRepartition stylesAndCounts={stylesAndCounts} isInPrintMode={isInPrintMode} />
                {!isInPrintMode && (
                    <div className="distributionHighlightText">
                        <span className="icon-container">{<FontAwesomeIcon icon={["far", "highlighter"]} />} </span>
                        <AtText>{t({ id: "global.atmanStyles.teamDistributionHighlightText" })}</AtText>
                    </div>
                )}
                <div className="columnsContainer">
                    {stylesAndCounts.results.map(({ style, count }) => (
                        <div className="column" key={style.id}>
                            <span className="columnHeader">
                                <AtButton
                                    id={style.id}
                                    icon={["far", "highlighter"]}
                                    color="ghost"
                                    onClick={() => handleHighlightClick(style.id)}
                                    active={highlightedId === style.id}
                                />
                                <AtStyleSvgs uniqueIdentifiableKey={style.uniqueIdentifiableKey} />
                                <AtParagraph className="column-title">
                                    {count} {t({ id: "global.members" })}
                                </AtParagraph>
                            </span>

                            {detailedMemberResults
                                .find((x) => x.styleId === style.id)
                                ?.orderedMemberInfos.map(({ details, result }) => {
                                    if (!details) {
                                        return null;
                                    }

                                    return (
                                        <AtStyleTeamMemberCard
                                            key={result.candidateProId}
                                            stylesDefinitions={stylesDefinitions}
                                            isInPrintMode={isInPrintMode}
                                            highlightedStyleId={highlightedId}
                                            stylesResult={result}
                                            candidateInfo={details}
                                            isLead={
                                                team.leads?.some((x) => x.candidateProId === result.candidateProId) ??
                                                false
                                            }
                                        />
                                    );
                                })}
                        </div>
                    ))}
                </div>
            </AtCollapsibleCard>
        );
    }),
);
