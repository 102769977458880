import "./index.less";
import * as React from "react";
import { AtButton } from "@atman/design-system";
import { GlobalStores, ModalStore } from "@atman/business";
import { inject } from "mobx-react";

export interface IModalButtonsProps {
    saveAction: () => void;
    customCancelAction?: () => void;
    saveLabel?: string;
    cancelLabel?: string;
    modalStore?: ModalStore;
    disabledSaveButton?: boolean;
    isLoading?: boolean;
}

const ModalButtonsComp: React.FC<IModalButtonsProps> = (props: IModalButtonsProps) => {
    const {
        saveAction,
        customCancelAction,
        isLoading = false,
        disabledSaveButton = false,
        saveLabel = "global.buttons.labels.save".localize(),
        cancelLabel = "global.buttons.labels.cancel".localize(),
        modalStore,
    } = props;

    return (
        <div className="ModalButtons">
            <AtButton color={"secondary"} onClick={customCancelAction || modalStore!.toggleModal}>
                {cancelLabel}
            </AtButton>
            <AtButton onClick={saveAction} disabled={disabledSaveButton} isLoading={isLoading}>
                {saveLabel}
            </AtButton>
        </div>
    );
};

const ModalButtons = inject(GlobalStores.modalStore)(ModalButtonsComp);

export { ModalButtons };
