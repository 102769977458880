import { AtmanCoPlatformsEnum } from "../../global/components/ProductSelector";
import { Moment } from "moment";
import moment from "moment";

export interface ISystemStateInfo {
    platforms: string[];
    title: Record<string, string>;
    subtitle: Record<string, string>;
    startDate: string;
    endDate: string;
    helpCenterUrl?: string;
}

export class SystemStateInfo {
    public readonly platforms: AtmanCoPlatformsEnum[];
    public readonly title: Record<string, string>;
    public readonly subtitle: Record<string, string>;
    public readonly startDate: Moment;
    public readonly endDate: Moment;
    public readonly helpCenterUrl: string | undefined;

    constructor(json: ISystemStateInfo) {
        this.platforms = json.platforms.map((x) => AtmanCoPlatformsEnum[x]);
        this.title = json.title;
        this.subtitle = json.subtitle;
        this.startDate = moment(json.startDate);
        this.endDate = moment(json.endDate);
        this.helpCenterUrl = json.helpCenterUrl;
    }
}
