import "./index.less";
import * as React from "react";
import { AtAvatar, DefaultSizes, IAtAvatarProps, XL, XXL } from "../../../internal";

export interface IAtPersonAvatarProps extends Omit<IAtAvatarProps, "avatarText"> {
    firstName?: string;
    lastName?: string;
    email?: string;
    pictureFormatFunc?: (pictureUrl: string, size: DefaultSizes | XL | XXL) => string;
}

/**
 * Represents the avatar of a person
 */
export const AtPersonAvatar: React.FC<IAtPersonAvatarProps> = (props: IAtPersonAvatarProps) => {
    const { firstName, lastName, email, pictureUri, size, pictureFormatFunc, ...otherProps } = props;

    const avatarText = firstName || lastName ? [firstName, lastName] : email;

    let pictureSize: DefaultSizes;

    switch (size) {
        case "xs":
        case "sm":
        case "md":
            pictureSize = "sm";
            break;
        case "lg":
            pictureSize = "md";
            break;
        case "xl":
        case "xxl":
            pictureSize = "lg";
            break;
        default:
            pictureSize = "md";
    }

    return (
        <AtAvatar
            className="AtPersonAvatar"
            pictureUri={
                pictureUri ? (pictureFormatFunc ? pictureFormatFunc(pictureUri, pictureSize) : pictureUri) : undefined
            }
            avatarText={avatarText}
            size={size}
            {...otherProps}
        />
    );
};
