import "./AtTabBarItem.less";
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { NavItem, NavLink } from "reactstrap";
import { inject, observer } from "mobx-react";

export type IconPlacement = "left" | "right";

export interface IAtTabBarItemProps {
    name: string;
    onClick?: () => void;
    selected?: boolean;
    iconName?: IconProp;
    iconPlacement?: IconPlacement;
    disabled?: boolean;
    className?: string;
}

export const AtTabBarItem: React.FC<IAtTabBarItemProps> = inject()(
    observer((props: IAtTabBarItemProps) => {
        const { name, selected, iconName, iconPlacement = "left", className, disabled, onClick } = props;

        const iconElement = iconName && (
            <span className={`icon-container icon-placement-${iconPlacement}`}>
                <FontAwesomeIcon icon={iconName} />
            </span>
        );

        let classNames = "AtTabBarItem";
        if (className) {
            classNames = `${classNames} ${className}`;
        }
        if (selected) {
            classNames = `${classNames} active`;
        }
        if (disabled) {
            classNames = `${classNames} disabled`;
        }

        return (
            <NavItem className={classNames}>
                <NavLink onClick={onClick}>
                    {iconPlacement === "left" && iconElement}
                    <span className="name">{name}</span>
                    {iconPlacement === "right" && iconElement}
                </NavLink>
                {selected && <div className="active-indicator" />}
            </NavItem>
        );
    }),
);
