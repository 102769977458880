import "./SubStepProgressionComponent.less";
import * as React from "react";
import { Colors } from "../../../../../styles/variables/colors";
import { Progress } from "reactstrap";

export interface ISubStepProgressionComponentProps {
    currentSubStep: number;
    totalSubStepsCount: number;
    formatSubStepProgressLabel?: (current: number, total: number) => React.ReactNode;
}

export const SubStepProgressionComponent: React.FC<ISubStepProgressionComponentProps> = (props) => {
    const { currentSubStep, totalSubStepsCount, formatSubStepProgressLabel } = props;

    const completedStep = currentSubStep - 1;

    return (
        <div className="SubStepProgressionComponent">
            <div className="sub-step-progress-label">
                {formatSubStepProgressLabel?.(completedStep, totalSubStepsCount) ??
                    `${completedStep} ${"global.outOf".localize()} ${totalSubStepsCount} ${"global.completed".localize()}`}
            </div>
            <Progress value={completedStep} max={totalSubStepsCount} color={Colors.primaryBase} />
        </div>
    );
};
