import "./DimensionGroupScore.less";
import { AtTitle } from "@atman/design-system";
import { DistributionData, ScoreDisplayType } from "@atman/business";
import { ScoreInfo } from "../../ScoreInfo";
import React from "react";

export interface IDimensionGroupScoreProps {
    title?: string;
    dimensionNames: string[];
    distributionData?: DistributionData; // Scores for team distribution (currently unused)
    scores?: Dictionary<string, number> | undefined; // Scores for a single candidate
}

export const DimensionGroupScore: React.FC<IDimensionGroupScoreProps> = (props) => {
    const { title, dimensionNames, distributionData, scores } = props;

    const getScore: () => number | undefined = () => {
        if (distributionData) {
            return distributionData.getAverageForDimensions(dimensionNames);
        } else if (scores) {
            const averageValues: number[] = [];

            for (const dimensionName of dimensionNames) {
                if (!scores.hasOwnProperty(dimensionName)) {
                    continue;
                }

                averageValues.push(scores[dimensionName]);
            }

            if (!averageValues.any()) {
                return undefined;
            }

            return averageValues.sum((x) => x) / averageValues.length;
        }

        return;
    };

    return (
        <div className="DimensionGroupScore">
            <div className="average-container">
                <ScoreInfo
                    scoreDisplayType={ScoreDisplayType.Stanine}
                    score={getScore()}
                    isScoreAnAverage={dimensionNames.length > 1}
                    useAlternativeColors
                />
                <AtTitle title={title} headingType={4} />
            </div>
        </div>
    );
};
