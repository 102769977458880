import "./index.less";
import { AtButton, AtCard, AtCheckableCard, AtLabelTitle, AtParagraph, AtSubsection } from "@atman/design-system";
import {
    CompDevApplication,
    CompDevLearningResource,
    CompetencyDevelopmentLearningResourceType,
    getEnumValues,
} from "@atman/business";
import { CustomTextInput } from "../../CustomTextInput";
import { LearningResource, LearningResourceFilterContainer } from "../";
import { Trans, t } from "@lingui/macro";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

type ActionPlanObjectiveType = "leverage" | "improve";

export interface IApplicationsLearningResourcesContainerProps {
    title: string;
    context: ActionPlanObjectiveType;
    parentId: string;
    applications?: CompDevApplication[];
    learningResources?: CompDevLearningResource[];
    selectedApplicationIds?: string[];
    selectedLearningResourceIds?: string[];
    applicationNotes?: string;
    learningResourceNotes?: string;
    onApplicationChange?: (id: string) => void;
    onLearningResourceChange?: (id: string) => void;
    onApplicationNotesChange?: (value: string) => void;
    onLearningResourceNotesChange?: (value: string) => void;
}

export const ApplicationsLearningResourcesContainer: React.FC<IApplicationsLearningResourcesContainerProps> = observer(
    (props) => {
        const {
            title,
            context,
            applications,
            learningResources,
            selectedApplicationIds = [],
            selectedLearningResourceIds = [],
            learningResourceNotes = "",
            applicationNotes = "",
            parentId,
            onApplicationChange,
            onLearningResourceChange,
            onApplicationNotesChange,
            onLearningResourceNotesChange,
        } = props;

        const [selectedLearningResourceTypes, setSelectedLearningResourceTypes] = React.useState<
            (CompetencyDevelopmentLearningResourceType | "all")[]
        >(["all"]);

        const [showApplicationNote, setShowApplicationNote] = useState(false);
        const [showLearningResourceNote, setShowLearningResourceNote] = useState(false);

        useEffect(() => {
            if (applicationNotes.length > 0) {
                setShowApplicationNote(true);
            }
        }, [applicationNotes.length]);

        useEffect(() => {
            if (learningResourceNotes.length > 0) {
                setShowLearningResourceNote(true);
            }
        }, [learningResourceNotes.length]);

        const filteredLearningResources: CompDevLearningResource[] = React.useMemo(() => {
            if (!learningResources) {
                return [];
            }

            if (selectedLearningResourceTypes.includes("all")) {
                return learningResources;
            }

            return learningResources.filter((x) =>
                selectedLearningResourceTypes.includes(x.type as CompetencyDevelopmentLearningResourceType),
            );
        }, [learningResources, selectedLearningResourceTypes]);

        const filters: CompetencyDevelopmentLearningResourceType[] = React.useMemo(() => {
            const allFilters = getEnumValues<CompetencyDevelopmentLearningResourceType>(
                CompetencyDevelopmentLearningResourceType,
            );

            return allFilters.filter((x) => learningResources?.some((y) => y.type === x));
        }, [learningResources]);

        return (
            <div className="ApplicationsLearningResourcesContainer">
                <AtLabelTitle label={"competencyDevelopment.global.objective".localize()} title={title} />

                {applications && applications.length > 0 && (
                    <AtSubsection
                        title={`competencyDevelopment.actionPlan.${context}Section.objectiveApplications`.localize()}
                        subtitle={(onApplicationChange
                            ? `competencyDevelopment.actionPlan.${context}Section.objectiveApplications.description.editable`
                            : `competencyDevelopment.actionPlan.${context}Section.objectiveApplications.description.readOnly`
                        ).localize()}
                        className="applications-container"
                    >
                        {applications.map((x) => {
                            const topContent: JSX.Element = (
                                <div>
                                    {x.description?.split("\n").map((y) => (
                                        <AtParagraph key={`paragraph-${y}`}>{y}</AtParagraph>
                                    ))}
                                </div>
                            );

                            return onApplicationChange ? (
                                <AtCheckableCard
                                    id={x.id}
                                    checked={selectedApplicationIds.includes(x.id)}
                                    onContainerClick={() => onApplicationChange(x.id)}
                                    topContent={topContent}
                                />
                            ) : (
                                <>
                                    <AtCard topContent={topContent} />
                                </>
                            );
                        })}
                        {onApplicationNotesChange ? (
                            <div className="notes-input-container">
                                {showApplicationNote ? (
                                    <CustomTextInput
                                        className="note-text-input"
                                        fieldName={"applicationNotes"}
                                        label={t({
                                            id: "competencyDevelopment.actionPlan.additionalNotes.title",
                                            message: "Additional Notes",
                                        })}
                                        value={applicationNotes}
                                        onChange={(e) => onApplicationNotesChange(e.currentTarget.value)}
                                        type={"textarea"}
                                        rows={3}
                                    />
                                ) : (
                                    <AtButton
                                        color={"secondary"}
                                        size={"md"}
                                        icon={["far", "comment-dots"]}
                                        onClick={() => setShowApplicationNote(true)}
                                    >
                                        <Trans id="competencyDevelopment.applicationNotes.addNotes">Add notes</Trans>
                                    </AtButton>
                                )}
                            </div>
                        ) : applicationNotes ? (
                            <AtCard
                                title={t({
                                    id: "competencyDevelopment.actionPlan.additionalNotes.title",
                                    message: "Additional Notes",
                                })}
                            >
                                {applicationNotes.split("\n").map((x, i) => (
                                    <div key={`observable-behavior-note-${i}`}>{x}</div>
                                ))}
                            </AtCard>
                        ) : null}
                    </AtSubsection>
                )}

                {learningResources && learningResources.length > 0 && (
                    <AtSubsection
                        title={"competencyDevelopment.learningResources".localize()}
                        subtitle={(onLearningResourceChange
                            ? "competencyDevelopment.learningResources.description.editable"
                            : "competencyDevelopment.learningResources.description.readOnly"
                        ).localize()}
                        className="learning-resources-container"
                    >
                        <LearningResourceFilterContainer
                            filters={filters}
                            selectedFilters={selectedLearningResourceTypes}
                            onFilterClick={(selectedTypes) => setSelectedLearningResourceTypes(selectedTypes)}
                        />
                        <div className="d-flex flex-wrap align-items-start justify-content-start">
                            {filteredLearningResources.map((x) => (
                                <LearningResource
                                    key={`resource-${x.id}`}
                                    learningResource={x}
                                    parentId={parentId}
                                    checked={selectedLearningResourceIds.includes(x.id)}
                                    onClick={
                                        onLearningResourceChange ? () => onLearningResourceChange(x.id) : undefined
                                    }
                                />
                            ))}
                        </div>
                        {onLearningResourceNotesChange ? (
                            <div className="notes-input-container">
                                {showLearningResourceNote ? (
                                    <CustomTextInput
                                        className="note-text-input"
                                        fieldName={"learningResourceNotes"}
                                        label={t({
                                            id: "competencyDevelopment.actionPlan.additionalNotes.title",
                                            message: "Additional Notes",
                                        })}
                                        value={learningResourceNotes}
                                        onChange={(e) => onLearningResourceNotesChange(e.currentTarget.value)}
                                        type={"textarea"}
                                        rows={3}
                                    />
                                ) : (
                                    <AtButton
                                        color={"secondary"}
                                        size={"md"}
                                        icon={["far", "comment-dots"]}
                                        onClick={() => setShowLearningResourceNote(true)}
                                    >
                                        <Trans id="competencyDevelopment.applicationNotes.addNotes">Add notes</Trans>
                                    </AtButton>
                                )}
                            </div>
                        ) : learningResourceNotes ? (
                            <AtCard
                                title={t({
                                    id: "competencyDevelopment.actionPlan.additionalNotes.title",
                                    message: "Additional Notes",
                                })}
                            >
                                {learningResourceNotes.split("\n").map((x, i) => (
                                    <div key={`observable-behavior-note-${i}`}>{x}</div>
                                ))}
                            </AtCard>
                        ) : null}
                    </AtSubsection>
                )}
            </div>
        );
    },
);
