import "./index.less";
import * as React from "react";
import {
    BaseColors,
    Danger,
    ExtraAndDefaultSizes,
    Ghost,
    PrimaryReverse,
    Sizing,
    Tertiary,
    Typography,
} from "../../../internal";
import { Button, ButtonProps } from "reactstrap";

export type AtButtonColors = BaseColors | Tertiary | Ghost | Danger | PrimaryReverse;
export type AtButtonSizes = ExtraAndDefaultSizes;

export interface IAtButtonBaseProps extends ButtonProps {
    size?: AtButtonSizes;
    color?: AtButtonColors;
    fullWidth?: boolean;
    disabled?: boolean;
    rounded?: boolean;
}

/**
 * AtButtonBase - Button Base Component
 * Category: Atom
 *
 * @param props {IAtButtonBaseProps}
 **/
export const AtButtonBase: React.FC<IAtButtonBaseProps> = (props) => {
    const {
        size = "lg",
        color = "primary",
        fullWidth = false,
        className,
        id,
        style,
        children,
        onClick,
        disabled,
        rounded,
        ...otherProps
    } = props;

    const getClassNames: () => string = () => {
        let classNames = `AtButtonBase color-${color}`;

        if (className) {
            classNames += ` ${className}`;
        }
        if (rounded) {
            classNames += ` ${rounded}`;
        }

        if (disabled) {
            classNames += " disabled";
        }

        return classNames;
    };

    const getStyles: () => React.CSSProperties = () => {
        let styles = {};

        if (fullWidth) {
            styles = {
                ...styles,
                width: "100%",
            };
        }

        let fontSize: number;
        let padding: string;

        switch (size) {
            case "xs":
                fontSize = Typography.fontSizes.sm;
                padding = `3px ${Sizing.getPixelsPropValue("xs")}`;
                break;
            case "sm":
                fontSize = Typography.fontSizes.sm;
                padding = `5px ${Sizing.getPixelsPropValue("xs")}`;
                break;
            case "md":
                fontSize = Typography.fontSizes.md;
                padding = `${Sizing.getPixelsPropValue("xs")} 12px`;
                break;
            case "lg":
                fontSize = Typography.fontSizes.md;
                padding = "12px";
                break;
            case "xl":
                fontSize = Typography.fontSizes.md;
                padding = `${Sizing.getPixelsPropValue("sm")} 20px`;
                break;
        }

        return {
            ...styles,
            fontSize,
            padding,
            ...style,
        };
    };

    const getBootstrapColor: () => string = () => {
        switch (color) {
            case "tertiary":
                return "light";
            case "ghost":
                return "secondary";
            default:
                return color;
        }
    };

    let _onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined = undefined;

    if (onClick) {
        _onClick = (event) => {
            if (disabled) return;

            return onClick(event);
        };
    }

    return (
        <Button
            className={getClassNames()}
            style={getStyles()}
            color={getBootstrapColor()}
            id={id}
            onClick={_onClick}
            disabled={disabled}
            {...otherProps}
        >
            {children}
        </Button>
    );
};
