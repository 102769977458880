import "./AtCompetenciesAverageScore.less";
import * as React from "react";
import { AtContainer } from "@atman/design-system";
import { CompDevCompetencyResult, CompetencySource, LikertValue, ScaleHelper, getRoundedScore } from "@atman/business";
import { observer } from "mobx-react";

export interface IAtCompetenciesAverageScoreProps {
    source: CompetencySource;
    sourceTitle: string;
    competencyDetails?: CompDevCompetencyResult[];
}

export const AtCompetenciesAverageScore: React.FC<IAtCompetenciesAverageScoreProps> = observer(
    (props: IAtCompetenciesAverageScoreProps) => {
        const { competencyDetails, source, sourceTitle } = props;

        const scoreAverage = React.useMemo(() => {
            if (!competencyDetails || !competencyDetails.length) {
                return "-";
            }

            const competenciesWithScores = competencyDetails.filter(
                (x) => (x.subCompetenciesReviewAverage ?? x.subCompetenciesAverage) !== undefined,
            );

            const sum = competenciesWithScores.sum((x) => x.subCompetenciesReviewAverage ?? x.subCompetenciesAverage!);

            if (sum === 0) {
                return "-";
            }

            return getRoundedScore(sum / competenciesWithScores.length);
        }, [competencyDetails]);

        const competencySourceLabel = React.useMemo(() => {
            switch (source) {
                case CompetencySource.Specific:
                    return "global.competencies".localize();
                case CompetencySource.Job:
                    return "global.itemTypes.job".localize();
                case CompetencySource.Team:
                    return "global.itemTypes.team".localize();
                case CompetencySource.Org:
                    return "competencyDevelopment.enterpriseCulture".localize();
            }
        }, [source]);

        const scoreColorClass = React.useMemo(() => {
            if (!scoreAverage) {
                return "empty";
            }

            const scoreAverageNumber: number = Number(scoreAverage);

            if (isNaN(scoreAverageNumber)) {
                return "empty";
            }

            const colorClass: LikertValue | undefined = ScaleHelper.interpretLikertAvgScore(scoreAverageNumber);

            if (colorClass !== undefined) {
                return LikertValue[colorClass];
            }

            return "empty";
        }, [scoreAverage]);

        return (
            <AtContainer className="AtCompetenciesAverageScore">
                <div className={`score-average ${scoreColorClass}`}>{scoreAverage}</div>
                <div className="competency-source-label">{competencySourceLabel}</div>
                <div className="competency-source-title">{sourceTitle}</div>
            </AtContainer>
        );
    },
);
