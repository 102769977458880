import "./index.less";
import * as React from "react";

export interface IAtNotesProps {
    notes: string;
    prefixKey: string;
}

const AtPrintNotes: React.FC<IAtNotesProps> = (props) => {
    const { notes, prefixKey } = props;
    const regex = /(\r\n|\r|\n)/;
    const paragraph = notes.split(regex).filter((value) => !!value.trim());

    return (
        <div className="AtPrintNotes">
            {paragraph.map((value, index) => (
                <div className="AtPrintNotesParagraph" key={`${prefixKey}-${index}`}>
                    {value}
                </div>
            ))}
        </div>
    );
};

export default AtPrintNotes;
