import { BaseModel, IBaseModel } from "../base";
import { IMapEntries, observable } from "mobx";

export interface IBaseCompetencySetInput {
    readonly createdFromId?: string;
    readonly competencyIds: string[];
    readonly localizedNames: IMapEntries<string, string>;
    readonly localizedDescriptions: IMapEntries<string, string>;
}

export interface IBaseCompetencySet extends IBaseModel {
    readonly version: number;
    readonly clientId: string;
    readonly name: string;
    readonly description: string;
    readonly localizedNames: IMapEntries<string, string>;
    readonly localizedDescriptions: IMapEntries<string, string>;
    readonly competencyIds: string[];
    readonly status: CompetencySetStatus;
    readonly createdFromId?: string;
}

export abstract class BaseCompetencySet extends BaseModel {
    @observable public readonly version: number;
    @observable public readonly clientId: string;
    @observable public readonly name: string;
    @observable public readonly description: string;
    @observable public localizedNames: Map<string, string>;
    @observable public localizedDescriptions: Map<string, string>;
    @observable public competencyIds: string[];
    @observable public status: CompetencySetStatus;
    @observable public createdFromId?: string;

    protected constructor(json?: IBaseCompetencySet) {
        super(json);

        if (!json) {
            return;
        }

        this.version = json.version;
        this.clientId = json.clientId;
        this.name = json.name;
        this.description = json.description;
        this.localizedNames = new Map<string, string>(json.localizedNames);
        this.localizedDescriptions = new Map<string, string>(json.localizedDescriptions);
        this.competencyIds = json.competencyIds;
        this.status = json.status;
        this.createdFromId = json.createdFromId;
    }
}

export enum CompetencySetStatus {
    Draft = 0,
    Published = 1,
    Archived = 2,
}
