import "./index.less";
import * as React from "react";
import { Danger, DefaultComponentProps, ExtraAndDefaultSizes, Primary, Secondary, Typography } from "../../../internal";

export type AtLinkSizes = ExtraAndDefaultSizes;
export type AtLinkColors = Primary | Secondary | Danger;

export interface IAtLinkBaseProps
    extends DefaultComponentProps,
        React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    size?: AtLinkSizes;
    color?: AtLinkColors;
    disabled?: boolean;
    href?: string;
    target?: string;
    underlined?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

/**
 * AtLinkBase - Anchor Base Component
 * Category: Atom
 *
 * @param props {IAtLinkBaseProps}
 **/
export const AtLinkBase: React.FC<IAtLinkBaseProps> = (props) => {
    const {
        size = "md",
        color = "primary",
        disabled,
        className,
        id,
        children,
        style,
        onClick,
        href,
        target,
        underlined = false,
        ...otherProps
    } = props;

    const getClassNames: () => string = () => {
        let classNames = `AtLinkBase color-${color}`;

        if (className) {
            classNames += ` ${className}`;
        }

        if (disabled) {
            classNames += " disabled";
        }

        return classNames;
    };

    const getStyles: () => React.CSSProperties = () => {
        const styles: React.CSSProperties = {
            fontSize: Typography.getFontSizePropValue(size),
            ...style,
        } as any;

        if (underlined) {
            styles.textDecoration = "underline";
        }

        return styles;
    };

    let _onClick: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) | undefined = undefined;

    if (onClick) {
        _onClick = (event) => {
            if (disabled) return;

            return onClick(event);
        };
    }

    return (
        <a
            className={getClassNames()}
            style={getStyles()}
            id={id}
            href={href}
            target={target}
            onClick={_onClick}
            {...otherProps}
        >
            {children}
        </a>
    );
};
