import "./index.less";
import * as React from "react";
import {
    AssessmentResultsCalculationServiceLogic,
    DistributionViewMode,
    FitLevelEnum,
    IDistributionEntry,
    IPersonScore,
    IRangePositionInfo,
    IScorePositionInfo,
    PrintModeEnum,
    ScaleType,
} from "@atman/business";
import { Col } from "reactstrap";
import { ScoreContainer } from "../ScoreContainer";
import { ScoreRangeHighlight } from "../ScoreRangeHighlight";
import { observer } from "mobx-react";

export interface IScoreLineContainerProps {
    maxScore: number;
    scaleType: ScaleType;
    scores?: IPersonScore[];
    distributionEntries?: IDistributionEntry[];
    distributionAverage?: number;
    showDistribution?: boolean;
    distributionViewMode?: DistributionViewMode;
    separators?: Array<number>; // Array of scores where you want to put vertical separators
    rangePositionInfo?: Nullable<IRangePositionInfo>;
    fitLevel?: FitLevelEnum;
    inCol?: boolean;
    printMode?: PrintModeEnum;
    isInverted?: boolean;
    teamName?: string;
    isInFit?: boolean;
    useGreenBackgroundColor?: boolean;
    forceShowScoreCount?: boolean;
}

export const ScoreLineContainer: React.FC<IScoreLineContainerProps> = observer((props) => {
    const {
        inCol,
        isInverted,
        rangePositionInfo,
        maxScore,
        scaleType,
        scores,
        distributionAverage,
        distributionEntries,
        distributionViewMode,
        separators,
        showDistribution,
        teamName,
        printMode,
        isInFit = false,
        useGreenBackgroundColor = false,
        forceShowScoreCount = false,
        fitLevel,
    } = props;

    const renderScores = () => {
        if (!scores) {
            return null;
        }

        const scoreElementsArray: React.ReactNode[] = [];
        let currentScoreRangePosition: IScorePositionInfo | null = null;

        for (let i = 1; i <= maxScore; i++) {
            const scoreInfos = scores.filter((x) => x.score === i);
            const matchingDistributionEntry = distributionEntries?.find((x) => x.scoreKey === i);

            const scorePositionInfos = AssessmentResultsCalculationServiceLogic.getScorePositionsInfo(
                scaleType,
                maxScore,
            );
            const scorePositionInfo = scorePositionInfos[i];

            scoreElementsArray.push(
                <ScoreContainer
                    key={`${i}-scorecontainer`}
                    positionKey={i}
                    positionInfo={scorePositionInfo}
                    scoreInfos={scoreInfos}
                    distributionEntry={matchingDistributionEntry}
                    distributionViewMode={distributionViewMode}
                    showDistribution={showDistribution}
                    scaleType={scaleType}
                    isDistributionAverage={distributionAverage === i ?? false}
                    showSeparator={separators!.some((v) => v === i)}
                    teamName={teamName}
                    printMode={printMode}
                    isGradient
                    isInFit={isInFit}
                    fitLevel={fitLevel}
                    useGreenBackgroundColor={useGreenBackgroundColor}
                    forceShowScoreCount={forceShowScoreCount}
                />,
            );

            if ((showDistribution && distributionViewMode === "numbers") || forceShowScoreCount) {
                if (matchingDistributionEntry?.isRange) {
                    if (!currentScoreRangePosition) {
                        currentScoreRangePosition = {
                            left: scorePositionInfo.left,
                            width: scorePositionInfo.width,
                        };
                    } else {
                        currentScoreRangePosition.width += scorePositionInfo.width;
                    }
                }

                if (currentScoreRangePosition && (!matchingDistributionEntry?.isRange || i === maxScore)) {
                    scoreElementsArray.push(
                        <ScoreRangeHighlight
                            key={`${i}-scorerangehighlight`}
                            positionInfo={currentScoreRangePosition}
                        />,
                    );
                    currentScoreRangePosition = null;
                }
            }
        }

        return scoreElementsArray;
    };

    const content = (
        <>
            {rangePositionInfo && (
                <div
                    className={`range-container ${isInverted ? "is-inverted" : ""}`}
                    style={{
                        left: `${rangePositionInfo.min}%`,
                        right: `${rangePositionInfo.max}%`,
                        width: `${rangePositionInfo.width}%`,
                    }}
                />
            )}
            {renderScores()}
        </>
    );

    const classNames = `ScoreLineContainer scale-type-${ScaleType[scaleType].toCamel()}`;

    if (inCol) {
        return <Col className={classNames}>{content}</Col>;
    }

    return <div className={classNames}>{content}</div>;
});
