import { BaseApi } from "../base";
import { HttpMethodEnum } from "../types";
import { ICulture, ICultureInput } from "../models/Culture";
import { ReactAppSettings } from "../libs";

export class CulturesApi extends BaseApi {
    static async getCultures(): Promise<ICulture[]> {
        return this.sendApiRequestWithResult<undefined, ICulture[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCultures,
        );
    }

    static async getSystemCultures(): Promise<ICulture[]> {
        return this.sendApiRequestWithResult<undefined, ICulture[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getSystemCultures,
        );
    }

    static async getCulture(id: string): Promise<ICulture> {
        return this.sendApiRequestWithResult<undefined, ICulture>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCulture,
            undefined,
            id,
        );
    }

    static async createCulture(input: ICultureInput): Promise<ICulture> {
        return this.sendApiRequestWithResult<ICultureInput, ICulture>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.createCulture,
            input,
        );
    }

    static async updateCulture(id: string, input: ICultureInput): Promise<ICulture> {
        return this.sendApiRequestWithResult<ICultureInput, ICulture>(
            HttpMethodEnum.PUT,
            ReactAppSettings.appUrls.updateCulture,
            input,
            id,
        );
    }

    static async deleteCulture(id: string): Promise<void> {
        return this.sendApiRequest(HttpMethodEnum.DELETE, ReactAppSettings.appUrls.deleteCulture, undefined, id);
    }

    static async getCultureTeams(id: string): Promise<ICultureTeam[]> {
        return this.sendApiRequestWithResult<undefined, ICultureTeam[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCultureTeams,
            undefined,
            id,
        );
    }

    static async assignToTeams(id: string, teamIds: string[]): Promise<void> {
        const data = {
            teamIds,
        };

        return this.sendApiRequestWithResult<typeof data, void>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.assignCultureToTeams,
            data,
            id,
        );
    }
}

export class CulturesMockApi {}

export interface ICultureTeam {
    displayName: string;
    teamId: string;
}
