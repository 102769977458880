import "../index.less";
import "./index.less";
import * as React from "react";
import { AtTitle } from "@atman/design-system";
import { CompetencyDevelopmentColumnType, IPersonInfo, SubCompetencyRowData, TableHeaderRow, TableRow } from "../../..";
import { DetailedObservableBehaviorSubRow } from "../components/TableSubRow/DetailedObservableBehaviorSubRow";
import { SortDirection } from "../../../../..";
import { observer } from "mobx-react";
import { useDetectDevice } from "@atman/core";

export interface IDetailedSubCompetencyResultTableProps {
    subCompetencies: SubCompetencyRowData[];
    columns: CompetencyDevelopmentColumnType[];
    managerInfo: IPersonInfo;
    employeeInfo: IPersonInfo;
    sortElement: CompetencyDevelopmentColumnType;
    sortDirection: SortDirection;
    hiddenColumns?: CompetencyDevelopmentColumnType[];
    onSortChange: (element: CompetencyDevelopmentColumnType) => void;
}

export const DetailedSubCompetencyResultTable: React.FC<IDetailedSubCompetencyResultTableProps> = observer((props) => {
    const {
        subCompetencies,
        columns,
        managerInfo,
        employeeInfo,
        sortElement,
        hiddenColumns,
        sortDirection,
        onSortChange,
    } = props;

    const { isMobile } = useDetectDevice();

    return (
        <div className="ResultTable DetailedSubCompetencyResultTable">
            {!isMobile && (
                <TableHeaderRow
                    competencyType="subCompetency"
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    onSortChange={onSortChange}
                    managerInfo={managerInfo}
                    employeeInfo={employeeInfo}
                    columns={columns}
                    hiddenColumns={hiddenColumns}
                />
            )}
            {subCompetencies.map((x) => (
                <TableRow
                    dataRow={x.subCompetency}
                    columns={columns}
                    parentCompetencyName={x.parentCompetencyName}
                    key={x.subCompetency.id}
                    scoresColumnSet={"reviewScores"}
                    managerInfo={managerInfo}
                    employeeInfo={employeeInfo}
                    showRank={false}
                    hiddenColumns={hiddenColumns}
                    collapseContentOffset={true}
                    averageScoreDisplayMode={"light"}
                >
                    <AtTitle title={"competencyDevelopment.global.observableBehaviors".localize()} headingType={6} />
                    {x.subCompetency.observableBehaviors.map((obs) => (
                        <DetailedObservableBehaviorSubRow
                            observableBehavior={obs}
                            key={obs.id}
                            managerInfo={managerInfo}
                            employeeInfo={employeeInfo}
                            hiddenColumns={hiddenColumns}
                        />
                    ))}
                </TableRow>
            ))}
        </div>
    );
});
