import { BaseCrudStore } from "../base/BaseCrudStore";
import { CandidateStore } from "./CandidateStore";
import { IJob, IJobInput, Job } from "../models/Job";
import { JobsApi } from "../apis/JobsApi";
import { ToastProvider } from "../libs";
import { action, runInAction } from "mobx";

export class JobStore extends BaseCrudStore<IJob, Job, IJobInput> {
    @action public async assignJobToEmployees(id: string, updatedEmployeeIds: string[]) {
        await this.tryCatch(async () => {
            await JobsApi.assignToEmployees(id, updatedEmployeeIds);

            const jobIndex = this.findItemIndexById(id);

            if (jobIndex >= 0) {
                runInAction(() => {
                    this.items[jobIndex].candidatesCount = updatedEmployeeIds.length;
                });
            }

            const candidateStore = (this.rootStore as unknown as any).candidateStore as CandidateStore;
            candidateStore.updateCandidateJobs(
                updatedEmployeeIds,
                id,
                this.items?.[jobIndex]?.name ?? "clientApp.unknownJobName".localize(),
            );

            ToastProvider.success(`${this._toastPrefix}_assignment_to_employees_success`.localize());
        });
    }

    protected _modelCtor = Job;
    protected _loadItems = () => JobsApi.getJobs();
    protected _createItem = (input: IJobInput) => JobsApi.createJob(input);
    protected _updateItem = (id: string, input: IJobInput) => JobsApi.updateJob(id, input);
    protected _deleteItem = (id: string) => JobsApi.deleteJob(id);
}
