import "./index.less";
import { AtIconTooltip, DefaultComponentProps } from "@atman/design-system";
import { Label } from "reactstrap";
import { observer } from "mobx-react";
import React from "react";

export interface IAtLabelledComponentProps extends DefaultComponentProps {
    label: string;
    tooltipContent?: React.ReactNode;
}

export const AtLabelledComponent: React.FC<IAtLabelledComponentProps> = observer((props) => {
    const { id, label, children, className, tooltipContent, ...otherProps } = props;

    let elementClassName: string = "AtLabelledComponent";

    if (className) {
        elementClassName += ` ${className}`;
    }

    return (
        <div className={elementClassName} {...otherProps}>
            <Label>
                {label}
                {tooltipContent && (
                    <AtIconTooltip
                        icon={"question-circle"}
                        tooltipTargetId={id ? `${id}_tooltip` : undefined}
                        tooltipDisplayMode={"dark"}
                    >
                        {tooltipContent}
                    </AtIconTooltip>
                )}
            </Label>
            <div className={"content"}>{children}</div>
        </div>
    );
});
