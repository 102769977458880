import * as qs from "qs";
import { ApiManager, ReactAppSettings } from "../libs";
import { AuthenticationMethod, IOrganizationAuthConfig, ISamlConfiguration, ISamlInformation } from "../models";
import { BaseApi } from "../base";
import { HttpMethodEnum } from "../types";

export class SingleSignOnApi extends BaseApi {
    public static getOrganizationAuthConfig() {
        return this.sendApiRequestWithResult<null, IOrganizationAuthConfig>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getOrganizationAuthConfig,
        );
    }

    public static getSamlInformation() {
        return this.sendApiRequestWithResult<null, ISamlInformation>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getSamlInformation,
        );
    }

    public static updateSamlConfiguration(model: ISamlConfiguration) {
        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.updateSamlConfiguration,
            model,
        );
    }

    public static updateSsoAuthMethod(authMethod: AuthenticationMethod) {
        const data = {
            data: authMethod,
        };

        return this.sendApiRequestWithResult(HttpMethodEnum.POST, ReactAppSettings.appUrls.updateSsoAuthMethod, data);
    }

    public static getSamlMetadataFile() {
        const apiCall = ApiManager.get(ReactAppSettings.appUrls.getSamlMetadata, {
            responseType: "blob",
            timeout: 300000,
            paramsSerializer: (params) => qs.stringify(params),
        });

        this.downloadFile("atmanMetadata.xml", apiCall);
    }
}
