import "./AssessmentWizardStepContent.less";
import { AssessmentStepInfo, CompetencyDevelopmentAssessmentType } from "@atman/business";
import { AssessmentWizardStatementEntry } from "./components/AssessmentWizardStatementEntry";
import { AtTitle } from "@atman/design-system";
import { Col, Row } from "reactstrap";
import { Default } from "../../../../../BaseResponsiveComponent";
import { LikertLegend } from "../../../../../LikertEvaluation";
import { ValidationError } from "@atman/core";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import React from "react";

export const LIKERT_LEFT_SECTION_COLUMN_PROPS = {
    lg: 7,
    xl: 8,
};

export const LIKERT_RIGHT_SECTION_COLUMN_PROPS = {
    lg: 12 - LIKERT_LEFT_SECTION_COLUMN_PROPS.lg,
    xl: 12 - LIKERT_LEFT_SECTION_COLUMN_PROPS.xl,
};

export interface IAssessmentWizardStepContentProps {
    assessmentType: CompetencyDevelopmentAssessmentType;
    stepInfo: AssessmentStepInfo;
    scores: Map<string, number | undefined>;
    onChange: (statementId: string, score: number) => void;
    scoreValidationErrors: ValidationError[];
}

export const AssessmentWizardStepContent: React.FunctionComponent<IAssessmentWizardStepContentProps> = observer(
    (props) => {
        const {
            stepInfo: { statements, scales, customHeaderPrompt },
            scores,
            onChange,
            scoreValidationErrors,
            assessmentType,
        } = props;

        let headerLabel = "";

        switch (assessmentType) {
            case CompetencyDevelopmentAssessmentType.Self:
                headerLabel = t({
                    id: "competencyDevelopment.assessments.wizard.stepContent.headerLabels.self",
                    message:
                        "Indicate how often you demonstrate the following behaviors when the situation requires it.",
                });
                break;
            case CompetencyDevelopmentAssessmentType.Manager:
                headerLabel = t({
                    id: "competencyDevelopment.assessments.wizard.stepContent.headerLabels.manager",
                    message:
                        "Indicate how often your employee demonstrates the following behaviors when the situation requires it.",
                });
                break;
        }

        return (
            <div className="AssessmentWizardStepContent">
                <Row className="header" noGutters>
                    <Col className="left-section" {...LIKERT_LEFT_SECTION_COLUMN_PROPS}>
                        <AtTitle headingType={4} title={customHeaderPrompt ?? headerLabel} />
                    </Col>
                    <Col className="right-section" {...LIKERT_RIGHT_SECTION_COLUMN_PROPS}>
                        <Default>
                            <LikertLegend scales={scales} inCol={false} />
                        </Default>
                    </Col>
                </Row>
                <div className="statements-container">
                    {statements!.map((x, i) => (
                        <AssessmentWizardStatementEntry
                            statementInfo={x}
                            statementIndex={i}
                            leftColumnProps={LIKERT_LEFT_SECTION_COLUMN_PROPS}
                            onChange={onChange}
                            scales={scales}
                            scoreValidationErrors={scoreValidationErrors.filter((e) => e.fieldName === x.id)}
                            currentScore={scores.get(x.id) ?? x.value}
                            key={x.id}
                        />
                    ))}
                </div>
            </div>
        );
    },
);
