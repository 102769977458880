import { AnalysisTileEnum, TileType } from "../models";

export class TeamAnalysisHelper {
    public static readonly tileTypeToAnalysisTilesMapping: Map<TileType, AnalysisTileEnum> = new Map<
        TileType,
        AnalysisTileEnum
    >([
        [TileType.Competencies, AnalysisTileEnum.Competencies],
        [TileType.Job, AnalysisTileEnum.JobFit],
        [TileType.Culture, AnalysisTileEnum.CultureFit],
        [TileType.Potential, AnalysisTileEnum.Potential],
        [TileType.CompatibilityFit, AnalysisTileEnum.CompatibilityFit],
    ]);

    public static readonly analysisTilesToTileTypeMapping: Map<AnalysisTileEnum, TileType | undefined> = new Map<
        AnalysisTileEnum,
        TileType | undefined
    >([
        [AnalysisTileEnum.Distribution, undefined],
        [AnalysisTileEnum.Competencies, TileType.Competencies],
        [AnalysisTileEnum.JobFit, TileType.Job],
        [AnalysisTileEnum.CultureFit, TileType.Culture],
        [AnalysisTileEnum.Potential, TileType.Potential],
        [AnalysisTileEnum.CompatibilityFit, TileType.CompatibilityFit],
    ]);
}
