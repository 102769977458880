import { Direction, Range, getTrackBackground } from "react-range";
import React, { ReactElement } from "react";
import styles from "./styles";

interface IRangeSliderProps {
    min: number;
    max: number;
    step: number;
    rangeValues: number[];
    multipleThumbs: boolean;
    handleRangeValueChange: (values: number[]) => void;
}

export function AtRangeSlider(props: IRangeSliderProps): ReactElement {
    const { min, max, step, rangeValues, handleRangeValueChange, multipleThumbs = false } = props;
    const colors = multipleThumbs ? ["#ccc", "#3366FF", "#ccc"] : ["#3366FF", "#ccc"];
    return (
        <Range
            min={min}
            max={max}
            step={step}
            values={rangeValues}
            onChange={handleRangeValueChange}
            renderTrack={({ props, children }) => (
                <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{ ...props.style, ...styles.renderTrackFirstDiv }}
                >
                    <div
                        ref={props.ref}
                        style={{
                            height: "8px",
                            width: "100%",
                            borderRadius: "4px",
                            alignSelf: "center",
                            background: getTrackBackground({
                                values: rangeValues,
                                colors,
                                direction: Direction.Left,
                                min: min,
                                max: max,
                                rtl: true,
                            }),
                        }}
                    >
                        {children}
                    </div>
                </div>
            )}
            renderThumb={({ props, isDragged }) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        ...styles.renderThumbFirstDiv,
                    }}
                >
                    <div
                        style={{
                            ...styles.renderThumbSecondDiv,
                            backgroundColor: isDragged
                                ? styles.draggedThumbBackgroundColor
                                : styles.thumbBackgroundColor,
                        }}
                    />
                </div>
            )}
        />
    );
}
