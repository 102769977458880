import { BaseApi } from "../../base";
import { HttpMethodEnum } from "../../types";
import { IAtStylesCandidateResult, IAtStylesGroupDefinition, IAtStylesTeamResult } from "./models";
import { ReactAppSettings } from "../../libs";

export interface IAtStylesApi {
    getStylesGroupDefinitions(languageOverride?: string): Promise<IAtStylesGroupDefinition[]>;
    getStylesGroupDefinition(styleGroupId: string, languageOverride?: string): Promise<IAtStylesGroupDefinition>;
    getCandidateStylesResult(candidateProId: string, stylesGroupId: string): Promise<IAtStylesCandidateResult>;
    getCandidateStylesResults(candidateProIds: string[], stylesGroupId: string): Promise<IAtStylesCandidateResult[]>;
    getTeamStylesResult(teamId: string, stylesGroupId: string): Promise<IAtStylesTeamResult>;
}

export default class AtStylesApi extends BaseApi implements IAtStylesApi {
    getStylesGroupDefinitions(languageOverride?: string): Promise<IAtStylesGroupDefinition[]> {
        return this.sendApiRequest(HttpMethodEnum.GET, ReactAppSettings.appUrls.getStylesGroupDefinitions, {
            languageOverride,
        });
    }

    getStylesGroupDefinition(styleGroupId: string, languageOverride?: string): Promise<IAtStylesGroupDefinition> {
        return this.sendApiRequest(HttpMethodEnum.GET, ReactAppSettings.appUrls.getStylesGroupDefinition, {
            languageOverride,
            styleGroupId,
        });
    }

    getCandidateStylesResult(candidateProId: string, stylesGroupId: string): Promise<IAtStylesCandidateResult> {
        return this.sendApiRequest(HttpMethodEnum.GET, ReactAppSettings.appUrls.getCandidateStylesResult, {
            candidateProId,
            stylesGroupId,
        });
    }

    getCandidateStylesResults(candidateProIds: string[], stylesGroupId: string): Promise<IAtStylesCandidateResult[]> {
        return this.sendApiRequest(HttpMethodEnum.GET, ReactAppSettings.appUrls.getCandidateStylesResults, {
            candidateProIds,
            stylesGroupId,
        });
    }

    getTeamStylesResult(teamId: string, stylesGroupId: string): Promise<IAtStylesTeamResult> {
        return this.sendApiRequest(HttpMethodEnum.GET, ReactAppSettings.appUrls.getTeamStylesResult, {
            teamId,
            stylesGroupId,
        });
    }
}
