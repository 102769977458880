import "./index.less";
import { AtParagraph, DefaultComponentProps, HeadingType } from "../../../internal";
import { AtTitle } from "../../atoms/AtTitle";
import React from "react";
import cn from "classnames";

export interface IAtSubsectionProps extends DefaultComponentProps {
    title: string;
    subtitle?: string;
    headingType?: HeadingType;
}

export const AtSubsection: React.FC<IAtSubsectionProps> = (props) => {
    const { title, subtitle, className, children, headingType = 6, ...others } = props;

    return (
        <div className={cn("AtSubsection", className)} {...others}>
            <AtTitle title={title} headingType={headingType} />
            <AtParagraph>{subtitle}</AtParagraph>
            <div className="Atsubsection-content">{children}</div>
        </div>
    );
};
