import {
    CompetencyDevelopmentAssessmentStatus,
    CompetencyDevelopmentAssessmentType,
    CompetencyDevelopmentProcessData,
} from "@atman/business";

export const isCompetenciesToLeverageAssessmentCompleted = (
    candidateProcessData: CompetencyDevelopmentProcessData,
): boolean => {
    return (
        candidateProcessData?.assessmentsData.get(CompetencyDevelopmentAssessmentType.CompetenciesToLeverage)
            ?.status === CompetencyDevelopmentAssessmentStatus.Completed
    );
};

export const isCompetenciesToImproveAssessmentCompleted = (
    candidateProcessData: CompetencyDevelopmentProcessData,
): boolean => {
    return (
        candidateProcessData?.assessmentsData.get(CompetencyDevelopmentAssessmentType.CompetenciesToImprove)?.status ===
        CompetencyDevelopmentAssessmentStatus.Completed
    );
};

export const isActionPlanAvailable = (candidateProcessData: CompetencyDevelopmentProcessData): boolean => {
    return (
        isCompetenciesToLeverageAssessmentCompleted(candidateProcessData) ||
        isCompetenciesToImproveAssessmentCompleted(candidateProcessData)
    );
};
