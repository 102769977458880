import { IRange } from "../models";

export class RangeHelper {
    // Takes a Map<IRange, Result>, a numeric value and finds the corresponding result according to ranges
    static getRangeMapValue<T>(
        rangeMap: Map<IRange, T>,
        valToConvert,
        inclusiveMin = true,
        inclusiveMax = true,
    ): T | undefined {
        for (const [key, value] of rangeMap) {
            if (
                (inclusiveMin ? valToConvert >= key.min : valToConvert > key.min) &&
                (inclusiveMax ? valToConvert <= key.max : valToConvert < key.max)
            ) {
                return value;
            }
        }
        return undefined;
    }
}
