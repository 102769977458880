import "./index.less";
import * as React from "react";
import {
    AppScope,
    ClientStore,
    GlobalStores,
    LocalizationStore,
    PartnerStore,
    Transaction,
    TransactionStore,
    TransactionType,
    UnhandledScopeError,
} from "@atman/business";
import {
    AtButtonList,
    AtTableColumn,
    AtTableDateFilterOptions,
    AtTableInfinite,
    DateRange,
    IAtButtonProps,
    IDatePreset,
    IReactSelectOptionObject,
} from "@atman/design-system";

import { IRoutedAppContext, withRoutedAppContext } from "../../contexts";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { StaticContext } from "react-router";
import { TransactionExpandRow } from "./components/TransactionExpandRow";
import { action, computed, observable, reaction, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import autobind from "autobind-decorator";
import cn from "classnames";
import getTransactionListColumns from "./components/columns";
import moment from "moment";

export interface ITransactionsListProps
    extends IRoutedAppContext<{}, StaticContext, { ownerId?: string; transactionType?: TransactionType }> {
    subView?: boolean;
    transactionStore?: TransactionStore;
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
    localizationStore?: LocalizationStore;
    icon?: IconProp;
}

// We're hiding those 2 items since they will be displayed on the Usage/Dashboard page instead;
const transactionTypesToHide: TransactionType[] = [
    TransactionType.ProductReturned,
    TransactionType.ProductUsed,
    TransactionType.Purchase,
];

@inject(GlobalStores.transactionStore, GlobalStores.clientStore, GlobalStores.partnerStore)
@observer
class TransactionsListComp extends React.Component<ITransactionsListProps, {}> {
    @observable public transactionTypeFilter?: IReactSelectOptionObject;
    @observable public otherPartyOwnerIdFilter?: IReactSelectOptionObject;
    @observable public sortDirection?: "asc" | "desc";
    @observable public filteredTransactions: Transaction[] = [];
    @observable public isAtBottom: boolean = false;
    @observable public previousFetchOffset: number = 0;
    @observable public isLoading: boolean = false;

    @observable public dateRange: DateRange = {
        from: undefined,
        to: undefined,
    };

    protected dateReaction = reaction(
        () => this.dateRange,
        () => this.refreshTransactions(),
    );
    protected transactionTypeFilterReaction = reaction(
        () => this.transactionTypeFilter,
        () => this.refreshTransactions(),
    );
    protected otherPartyOwnerIdFilterReaction = reaction(
        () => this.otherPartyOwnerIdFilter,
        () => this.refreshTransactions(),
    );

    constructor(props: ITransactionsListProps) {
        super(props);

        if (props.scope !== AppScope.Client) {
            this.dateRange = {
                from: moment().subtract(1, "year").toDate(),
                to: moment().toDate(),
            };
        }
    }

    async componentDidMount() {
        this.filteredTransactions = this.props.transactionStore!.transactions;

        if (this.props.scope !== AppScope.Client) {
            this.dateRange = {
                from: moment().subtract(1, "year").toDate(),
                to: moment().toDate(),
            };
        }

        if (this.props.location.state) {
            if (this.props.location.state.ownerId) {
                const match = this.otherPartySelectOptions.find((x) => x.value === this.props.location.state.ownerId);

                if (match) {
                    runInAction(() => {
                        this.otherPartyOwnerIdFilter = match;
                    });
                }
            }

            if (this.props.location.state.transactionType) {
                const match = this.transactionTypeSelectOptions.find(
                    (x) => x.value === String(this.props.location.state.transactionType),
                );

                if (match) {
                    runInAction(() => {
                        this.transactionTypeFilter = match;
                    });
                }
            }
        }
    }

    @computed
    get otherPartySelectOptions(): IReactSelectOptionObject[] {
        const { scope, clientStore, partnerStore } = this.props;

        switch (scope) {
            case AppScope.Client:
                return [];
            case AppScope.Partner:
                return clientStore!.clients.map((x) => ({ label: x.name, value: x.id }));
            case AppScope.Supplier:
                return partnerStore!.partners.map((x) => ({ label: x.name, value: x.id }));
            default:
                throw new UnhandledScopeError(scope);
        }
    }

    @computed
    get transactionTypeSelectOptions(): IReactSelectOptionObject[] {
        const { scope } = this.props;

        let options = Object.keys(TransactionType)
            .filter((x) => !isNaN(Number(x)))
            .filter(
                (x) => !transactionTypesToHide.some((tt) => tt === Number(x)) || Number(x) === TransactionType.Purchase,
            );

        if (scope === AppScope.Supplier) {
            options = options.filter((x) => Number(x) !== TransactionType.Purchase);
        }

        return options.map((x) => ({ label: `global.${TransactionType[x].toCamel()}`.localize(), value: x }));
    }

    @action.bound
    private refreshTransactions = async () => {
        const { transactionStore } = this.props;
        let filteredTransactions = await transactionStore!.getTransactions(0, 15, this.sortDirection);

        if (this.props.scope !== AppScope.Client) {
            filteredTransactions = filteredTransactions.filter(
                (x) => !transactionTypesToHide.some((tt) => tt === x.transactionType),
            );
        }

        runInAction(() => {
            this.filteredTransactions = filteredTransactions;
        });
    };

    @autobind
    async handleTransactionLogExport() {
        await this.props.transactionStore!.downloadTransactionLog(this.props.transactionStore!.transactionLogFilters);
    }

    @action.bound
    renderExpandRow = (row: Transaction) => <TransactionExpandRow key={row.id} transaction={row} />;

    componentWillUnmount() {
        this.props.transactionStore!.resetFilters();
    }

    render() {
        let otherPartyOwnerIdFilterPlaceholder: string = "";
        const { subView, scope, transactionStore, localizationStore } = this.props;
        const isClientEnvironment = scope === AppScope.Client;

        switch (scope) {
            case AppScope.Partner:
                otherPartyOwnerIdFilterPlaceholder = "global.clientIdFilterPlaceholder";
                break;
            case AppScope.Supplier:
                otherPartyOwnerIdFilterPlaceholder = "supplierApp.partnerIdFilterPlaceholder";
                break;
        }

        const datePresets: IDatePreset[] = [
            {
                range: {
                    from: moment(this.dateRange.to).subtract(3, "months"),
                    to: moment(this.dateRange.to),
                },
            },
            {
                range: {
                    from: moment(this.dateRange.to).subtract(1, "year"),
                    to: moment(this.dateRange.to),
                },
            },
            {
                range: {
                    from: moment(this.dateRange.to).subtract(2, "years"),
                    to: moment(this.dateRange.to),
                },
            },
            {
                range: {
                    from: moment(this.dateRange.to).subtract(5, "years"),
                    to: moment(this.dateRange.to),
                },
            },
        ];

        const dateOptions: AtTableDateFilterOptions = {
            languageCode: localizationStore?.currentReportLocale.shortName ?? "en",
            datePresets: datePresets,
        };

        const columns: AtTableColumn<Transaction>[] = getTransactionListColumns(
            isClientEnvironment,
            scope,
            this.transactionTypeSelectOptions,
            this.otherPartySelectOptions,
            dateOptions,
            otherPartyOwnerIdFilterPlaceholder,
        );

        const buttons: IAtButtonProps[] = [
            {
                children: t({ id: "global.exportTransactionLog" }),
                onClick: this.handleTransactionLogExport,
                color: "secondary",
            },
        ];

        return (
            <div id="TransactionsList">
                {!isClientEnvironment ? <AtButtonList buttons={buttons} /> : null}

                <div className={cn("list", subView && "sub-view")}>
                    <AtTableInfinite<Transaction>
                        columns={columns}
                        fetchSize={60}
                        expandRowContent={this.renderExpandRow}
                        loadDataWithOffset={transactionStore!.searchTransactionLog}
                        showSearchBar={false}
                    />
                </div>
            </div>
        );
    }
}

const TransactionsList = withRoutedAppContext(TransactionsListComp);

export { TransactionsList };
