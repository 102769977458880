import "./index.less";
import { AtTabCard } from "@atman/design-system";
import { BaseResponsiveComponent } from "../BaseResponsiveComponent";
import { FlipProp, IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Role } from "@atman/business";
import { RouteComponentProps, withRouter } from "react-router";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";

export interface IAtTabNavigationLayoutProps extends RouteComponentProps<any> {
    navigationItems: IAtTabNavigationItem[];
    defaultActiveTab?: number;
    className?: string;
    id?: string;
}

@inject()
@observer
class AtTabNavigationLayoutComp extends BaseResponsiveComponent<IAtTabNavigationLayoutProps> {
    @computed protected get rootElementClassName(): string | undefined {
        const { className } = this.props;

        return `AtTabNavigationLayout ${className ?? ""}`;
    }

    @computed protected get rootElementId(): string | undefined {
        return this.props.id;
    }

    @observable public activeTab: number = 0;

    constructor(props: IAtTabNavigationLayoutProps) {
        super(props);

        if (props.defaultActiveTab) {
            this.activeTab = props.defaultActiveTab;
        }
    }

    @action.bound
    setActiveTab = (tabId: number) => {
        this.activeTab = tabId;
    };

    renderAll() {
        const { navigationItems } = this.props;

        return (
            <>
                <Nav tabs className="headers">
                    {navigationItems.map((x, i) => (
                        <NavItem key={i} id={x.navItemId}>
                            <NavLink
                                className={this.activeTab === i ? "active" : ""}
                                onClick={() => this.setActiveTab(i)}
                            >
                                <span className="icon-container fa-layers fa-fw">
                                    <FontAwesomeIcon icon={x.iconName} flip={x.iconFlip} />
                                </span>
                                {x.label}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                <TabContent activeTab={this.activeTab} className="content">
                    {navigationItems.map((x, i) => (
                        <TabPane tabId={i} key={i}>
                            {x.component}
                        </TabPane>
                    ))}
                </TabContent>
            </>
        );
    }
}

export interface IAtTabNavigationItem {
    iconName: IconProp;
    label: string;
    component: React.ReactNode;
    restrictedToRoles?: Role[];
    iconFlip?: FlipProp;
    navItemId?: string;
}

const AtTabNavigationLayout = withRouter(AtTabNavigationLayoutComp);
export { AtTabNavigationLayout, AtTabCard };
