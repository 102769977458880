import "./AttributeCredits.less";
import * as React from "react";
import { Alert, FormGroup, Input, Label } from "reactstrap";
import {
    AppScope,
    AttributeCreditsCart,
    CartStore,
    Client,
    ClientStore,
    GlobalStores,
    IAppContext,
    ModalStore,
    Partner,
    PartnerStore,
    ProductBalanceStore,
    ProductCodeProEnum,
    TransactionType,
    UsageModel,
} from "@atman/business";
import { AtSelect, AtSimpleSelect, IReactSelectOptionObject } from "@atman/design-system";
import { BaseForm } from "../../components/BaseForm";
import { BaseModal } from "../../components";
import { BillingModalHelpMessage } from "../components/BillingModalHelpMessage";
import { CreditsBalanceSelector } from "../components/CreditsBalanceSelector";
import { CustomTextInput } from "../../components/CustomTextInput";
import { FormStepGroupSection } from "../../components/FormStepGroupSection";
import { ModalButtons } from "../../components/ModalButtons";
import { ModalNewBalanceConfirmation } from "../components/ModalNewBalanceConfirmation";
import { StylesConfig } from "react-select";
import { action, computed, observable, reaction, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../contexts";

export interface IAttributeCreditsProps extends IAppContext {
    clientStore?: ClientStore;
    partnerStore?: PartnerStore;
    modalStore?: ModalStore;
    productBalanceStore?: ProductBalanceStore;
    cartStore?: CartStore;
}

const colorStyles: StylesConfig<any> = {
    container: (styles) => ({
        ...styles,
        width: 480,
    }),
    control: (styles) => ({
        ...styles,
        width: 480,
        padding: "2.5px 8px",
        fontFamily: "Quicksand",
        fontSize: "14px",
        marginRight: 16,
    }),
};

@inject(
    GlobalStores.clientStore,
    GlobalStores.partnerStore,
    GlobalStores.modalStore,
    GlobalStores.productBalanceStore,
    GlobalStores.cartStore,
)
@observer
class AttributeCreditsComp extends BaseForm<IAttributeCreditsProps, {}> {
    @observable public recipientId: IReactSelectOptionObject | null = null;
    @observable public recipientUsageModel?: UsageModel;
    @observable public recipientTransferableProducts: ProductCodeProEnum[] = [];
    @observable public operationType: "add" | "remove" = "add";
    @observable public transactionType: TransactionType = TransactionType.Sale;
    @observable public comments?: string;
    @observable public errorMessage: string = "";

    @computed
    get cart() {
        return this.props.cartStore!.appCarts["attributeCredits"] as AttributeCreditsCart;
    }

    recipientIdReaction = reaction(
        () => this.recipientId,
        async (recipientId) => {
            if (recipientId) {
                this.props.cartStore!.setCart("attributeCredits", {
                    ...this.cart,
                    ownerId: recipientId.value,
                } as AttributeCreditsCart);

                const usageModel = await this.props.productBalanceStore!.getUsageModelForEntityId(recipientId.value);

                if (usageModel) {
                    runInAction(() => {
                        this.recipientUsageModel = usageModel;
                        this.recipientTransferableProducts = usageModel!.productRestriction;
                    });
                }
            }
        },
    );

    componentDidMount(): void {
        this.loadRecipientId();
    }

    @computed
    get getRecipientId(): string | undefined {
        return this.props.modalStore!.childProps && (this.props.modalStore!.childProps!.recipientId as string);
    }

    @computed
    get recipient(): Client | Partner | undefined {
        const recipientId = this.getRecipientId;

        if (!recipientId) {
            return;
        }

        let recipient: any;

        switch (this.props.scope) {
            case AppScope.Partner:
                recipient = this.props.clientStore!.getClientById(recipientId);
                break;
            case AppScope.Supplier:
                recipient = this.props.partnerStore!.getPartnerById(recipientId);
                break;
            default:
                throw new Error(`Unhandled scope for action: ${this.props.scope}`);
        }

        return recipient;
    }

    @action.bound
    private loadRecipientId = () => {
        const recipient = this.recipient;

        if (recipient && this.getRecipientId) {
            this.recipientUsageModel = recipient.usageModel;
            this.recipientId = {
                value: this.getRecipientId,
                label: recipient.name,
            };
        }
    };

    @action.bound
    _onSave = async () => {
        this.errorMessage = "";

        const errorResult = await this.props.cartStore!.processAttributeProductsTransaction(
            this.operationType,
            this.transactionType,
            this.comments,
        );

        if (!this.props.cartStore!.hasErrored) {
            this.props.modalStore!.toggleModal();
        }

        if (errorResult) {
            runInAction(() => {
                this.errorMessage = errorResult;
            });
        }
    };

    validateForm(): boolean {
        return this.transactionType !== TransactionType.Other || !!this.comments;
    }

    @action.bound
    onRecipientChange(option: IReactSelectOptionObject) {
        this.recipientId = option;
    }

    @action.bound
    onOperationTypeChange(event: React.FormEvent<HTMLInputElement>) {
        const value = (event.target as any).value;

        if (this.operationType !== value) {
            switch (value) {
                case "add":
                    this.transactionType = TransactionType.Sale;
                    break;
                case "remove":
                    this.transactionType = TransactionType.Refund;
                    break;
            }
        }

        this.operationType = value;
    }

    render() {
        const { clientStore, partnerStore, cartStore, productBalanceStore, scope } = this.props;

        let selectionSectionTitle: string = "";
        let selectionLabel: string = "";
        let recipientOptions: IReactSelectOptionObject[];

        switch (scope) {
            case AppScope.Partner:
                selectionSectionTitle = "partnerApp.selectTheClient".localize();
                selectionLabel = "partnerApp.clientName".localize();
                recipientOptions = clientStore!.clients.map((x) => ({ value: x.id, label: x.name }));
                break;
            case AppScope.Supplier:
                selectionSectionTitle = "supplierApp.selectThePartner".localize();
                selectionLabel = "supplierApp.partnerName".localize();
                recipientOptions = partnerStore!.partners.map((x) => ({ value: x.id, label: x.name }));
                break;
            default:
                throw new Error(`Unhandled scope for modal: ${scope}`);
        }

        let transactionTypes: TransactionType[] = [];

        switch (this.operationType) {
            case "add":
                transactionTypes = [TransactionType.Sale, TransactionType.Promo, TransactionType.Other];
                break;
            case "remove":
                transactionTypes = [TransactionType.Refund, TransactionType.Other];
                break;
        }

        const disableSaveButton = !Object.keys(this.cart.balance).some(
            (x) => this.cart.balance[x] && this.cart.balance[x] > 0,
        );

        return (
            <BaseModal modalTitle={"global.attributeCredits".localize()} id={"AttributeCredits"}>
                <BillingModalHelpMessage />
                <FormStepGroupSection sectionTitle={selectionSectionTitle} step={1} id={"select-recipient-step"}>
                    <AtSelect
                        name={"recipientId"}
                        label={selectionLabel}
                        options={recipientOptions}
                        value={this.recipientId}
                        onChange={this.onRecipientChange}
                        placeholder={`recipientPlaceholder_${AppScope[scope]}`.localize()}
                        additionalStyles={colorStyles}
                        isDisabled={true}
                    />
                </FormStepGroupSection>
                <FormStepGroupSection
                    sectionTitle={"global.typeOfTransaction".localize()}
                    step={2}
                    id={"type-of-transaction-step"}
                >
                    <FormGroup check inline>
                        <Label check>
                            <Input
                                type={"radio"}
                                value={"add"}
                                checked={this.operationType === "add"}
                                onChange={this.onOperationTypeChange}
                            />
                            {"global.addProducts".localize()}
                        </Label>
                        <Label check>
                            <Input
                                type={"radio"}
                                value={"remove"}
                                checked={this.operationType === "remove"}
                                onChange={this.onOperationTypeChange}
                            />
                            {"global.removeProducts".localize()}
                        </Label>
                    </FormGroup>
                    <div className="transaction-type-selection">
                        <AtSimpleSelect
                            name={"transactionType"}
                            label={"global.transactionType".localize()}
                            getOptionLabel={(x) => `global.${TransactionType[x].toCamel()}`.localize()}
                            options={transactionTypes}
                            value={this.transactionType}
                            onChange={(o) => this.onAutoCompleteChange(o, "transactionType")}
                        />
                    </div>
                    <div className="comment-selection">
                        <CustomTextInput
                            fieldName={"comments"}
                            label={"global.comments".localize()}
                            value={this.comments}
                            onChange={this.onTextFieldChange}
                            type={"textarea"}
                        />
                    </div>
                </FormStepGroupSection>
                <FormStepGroupSection
                    sectionTitle={"global.selectTheProducts".localize()}
                    step={3}
                    id={"select-the-product-step"}
                >
                    <CreditsBalanceSelector
                        cartKey={"attributeCredits"}
                        transferableProducts={this.recipientTransferableProducts}
                        currentBalance={productBalanceStore!.balance}
                        recipientUsageModel={
                            this.recipientUsageModel ? new UsageModel(this.recipientUsageModel) : undefined
                        }
                    />
                </FormStepGroupSection>
                <FormStepGroupSection
                    sectionTitle={"global.confirmTransaction".localize()}
                    step={4}
                    id={"confirmation-step"}
                >
                    {this.recipientUsageModel && !productBalanceStore!.isUnlimited ? (
                        <ModalNewBalanceConfirmation
                            previousBalance={productBalanceStore!.balance}
                            selectedProductsBalance={this.cart.balance}
                            balanceOperation={this.operationType === "add" ? "debit" : "credit"}
                        />
                    ) : null}
                    <div className="error-container">
                        {this.errorMessage ? <Alert color={"danger"}>{this.errorMessage}</Alert> : null}
                    </div>
                    <ModalButtons
                        saveLabel={"global.confirmTransactionButton".localize()}
                        saveAction={this._onSave}
                        isLoading={cartStore!.isLoading}
                        disabledSaveButton={disableSaveButton}
                    />
                </FormStepGroupSection>
            </BaseModal>
        );
    }
}

const AttributeCredits = withAppContext(AttributeCreditsComp);

export { AttributeCredits };
