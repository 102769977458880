import { BaseApi } from "../base";
import { HttpMethodEnum } from "../types";
import { IJob, IJobInput } from "../models/Job";
import { ReactAppSettings } from "../libs";

export class JobsApi extends BaseApi {
    static async getJobs(): Promise<IJob[]> {
        return this.sendApiRequestWithResult<undefined, IJob[]>(HttpMethodEnum.GET, ReactAppSettings.appUrls.getJobs);
    }

    static async getSystemJobs(): Promise<IJob[]> {
        return this.sendApiRequestWithResult<undefined, IJob[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getSystemJobs,
        );
    }

    static async getJob(id: string): Promise<IJob> {
        return this.sendApiRequestWithResult<undefined, IJob>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getJob,
            undefined,
            id,
        );
    }

    static async createJob(input: IJobInput): Promise<IJob> {
        return this.sendApiRequestWithResult<IJobInput, IJob>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.createJob,
            input,
        );
    }

    static async updateJob(id: string, input: IJobInput): Promise<IJob> {
        return this.sendApiRequestWithResult<IJobInput, IJob>(
            HttpMethodEnum.PUT,
            ReactAppSettings.appUrls.updateJob,
            input,
            id,
        );
    }

    static async deleteJob(id: string): Promise<void> {
        return this.sendApiRequest(HttpMethodEnum.DELETE, ReactAppSettings.appUrls.deleteJob, undefined, id);
    }

    static async getJobCandidates(id: string): Promise<IJobEmployee[]> {
        return this.sendApiRequestWithResult<undefined, IJobEmployee[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getJobCandidates,
            undefined,
            id,
        );
    }

    static async assignToEmployees(id: string, employeeIds: string[]): Promise<void> {
        const data = {
            candidateIds: employeeIds,
        };

        return this.sendApiRequestWithResult<typeof data, void>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.assignJobToEmployees,
            data,
            id,
        );
    }
}

export interface IJobEmployee {
    candidateProId: string;
    displayName: string;
}
