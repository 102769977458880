import "./index.less";
import * as React from "react";
import { Colors } from "../../../../styles/variables/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IWizardStepDetails } from "../../index";

export interface IAtWizardHeaderProps {
    activeStep: number;
    stepDetails: IWizardStepDetails[];
    goToStep: (step?: number) => void;
    customLeftHeaderContent?: React.ReactNode;
    customRightHeaderContent?: React.ReactNode;
    allowStepClickNavigation?: boolean;
}

export const AtWizardHeader: React.FC<IAtWizardHeaderProps> = (props) => {
    const {
        activeStep,
        stepDetails,
        goToStep,
        allowStepClickNavigation = false,
        customLeftHeaderContent,
        customRightHeaderContent,
    } = props;

    const onStepHeaderElementClick = (stepNumber: number) => {
        if (!allowStepClickNavigation) {
            return;
        }

        goToStep(stepNumber);
    };

    return (
        <div className="AtWizardHeader">
            {customLeftHeaderContent && <div className="left-content">{customLeftHeaderContent}</div>}
            <div className="middle-content">
                <div className="step-header-elements-container">
                    {stepDetails.map((x, _i, array) => (
                        <div
                            className={`step-header-element ${activeStep === x.number ? "active" : ""} ${
                                allowStepClickNavigation ? "step-navigation" : ""
                            }`}
                            onClick={() => onStepHeaderElementClick(x.number)}
                            key={x.number}
                        >
                            {x.title}
                            {x.number < array.length && (
                                <FontAwesomeIcon
                                    className={"chevron-icon"}
                                    icon={["far", "chevron-right"]}
                                    color={Colors.greyShades.shade5}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            {customRightHeaderContent && <div className="right-content">{customRightHeaderContent}</div>}
        </div>
    );
};
