import "./index.less";
import * as React from "react";
import { AtBadge, AtCheckboxBase, AtCollapse, AtIcon, AtPersonAvatar, AtSubtitle, AtTitle } from "@atman/design-system";
import {
    AtScoreReviewIndicator,
    AverageScoreDisplay,
    AverageScoreDisplayMode,
    CompDevRowScoresColumnSet,
    CompetencyDevelopmentColumnType,
    Default,
    IPersonInfo,
    Mobile,
    PotentialBadge,
    isColumnDisplayed,
} from "../../../../../..";
import {
    BaseCompetencyElement,
    CompDevCompetencyResult,
    CompDevSubCompetencyResult,
    CompetencySource,
    IPersonScore,
    LikertValue,
    PrintModeEnum,
    ScaleHelper,
    ScaleMaxScore,
    ScaleType,
    getRoundedScore,
} from "@atman/business";
import { Colors } from "../../../../../../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { Scale, ScaleRow } from "../../../../../../AtGenericScales";
import { observer } from "mobx-react";
import { v4 } from "uuid";

const DOCSCALETYPE = ScaleType.Unipolar;
const MAXSCORE = ScaleMaxScore.Likert;

export interface ITableRow {
    className?: string;
    dataRow: CompDevCompetencyResult | CompDevSubCompetencyResult;
    columns: CompetencyDevelopmentColumnType[];
    hiddenColumns?: CompetencyDevelopmentColumnType[];
    scoresColumnSet?: CompDevRowScoresColumnSet;
    parentCompetencyName?: string;
    showRank?: boolean;
    averageScoreDisplayMode?: AverageScoreDisplayMode;
    disableScoreReviewIndicator?: boolean;
    managerInfo?: IPersonInfo;
    employeeInfo?: IPersonInfo;
    isSelected?: boolean;
    inError?: boolean;
    onSelectChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    disabledSelect?: boolean;
    expandOnMount?: boolean;
    collapseContentOffset?: boolean;
    printMode?: PrintModeEnum;
    showCheckIcon?: boolean;
}

export const TableRow: React.FC<ITableRow> = observer((props) => {
    const {
        className,
        dataRow,
        scoresColumnSet,
        parentCompetencyName,
        showRank,
        averageScoreDisplayMode,
        disableScoreReviewIndicator,
        managerInfo,
        employeeInfo,
        columns,
        hiddenColumns = [],
        isSelected,
        inError,
        onSelectChange,
        disabledSelect,
        expandOnMount,
        collapseContentOffset = false,
        children,
        printMode,
        showCheckIcon = false,
    } = props;

    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (expandOnMount) {
            toggleExpansion();
        }
    }, []);

    const toggleExpansion = (event?: React.MouseEvent<HTMLDivElement>) => {
        const targetClassNames = event?.currentTarget?.className;

        if (targetClassNames?.includes("AtCheckboxBase")) {
            return;
        }

        setIsExpanded(!isExpanded);
    };

    const getClasses: () => string = () => {
        let classes: string = "TableRow";

        if (className) {
            classes += ` ${className}`;
        }

        return classes;
    };

    const isCompetency: boolean = React.useMemo(() => {
        return dataRow instanceof CompDevCompetencyResult;
    }, [dataRow]);

    const dataRowAsCompetency: CompDevCompetencyResult = React.useMemo(() => {
        return dataRow as CompDevCompetencyResult;
    }, [dataRow]);

    const dataRowAsSubCompetency: CompDevSubCompetencyResult = React.useMemo(() => {
        return dataRow as CompDevSubCompetencyResult;
    }, [dataRow]);

    const dataRowAsBaseCompetencyElementResult: BaseCompetencyElement = React.useMemo(() => {
        return dataRow as BaseCompetencyElement;
    }, [dataRow]);

    const scores: {
        averageScore: number | undefined;
        reviewScore: number | undefined;
        managerScore: number | undefined;
        employeeScore: number | undefined;
    } = React.useMemo(() => {
        return isCompetency
            ? {
                  averageScore: dataRowAsCompetency.subCompetenciesAverage,
                  reviewScore: dataRowAsCompetency.subCompetenciesReviewAverage,
                  managerScore: dataRowAsCompetency.subCompetenciesManagerAverage,
                  employeeScore: dataRowAsCompetency.subCompetenciesEmployeeAverage,
              }
            : {
                  averageScore: dataRowAsSubCompetency.observableBehaviorsAverage,
                  reviewScore: dataRowAsSubCompetency.observableBehaviorsReviewAverage,
                  managerScore: dataRowAsSubCompetency.observableBehaviorsManagerAverage,
                  employeeScore: dataRowAsSubCompetency.observableBehaviorsEmployeeAverage,
              };
    }, [
        dataRowAsCompetency.subCompetenciesAverage,
        dataRowAsCompetency.subCompetenciesReviewAverage,
        dataRowAsCompetency.subCompetenciesManagerAverage,
        dataRowAsCompetency.subCompetenciesEmployeeAverage,
        dataRowAsSubCompetency.observableBehaviorsAverage,
        dataRowAsSubCompetency.observableBehaviorsReviewAverage,
        dataRowAsSubCompetency.observableBehaviorsManagerAverage,
        dataRowAsSubCompetency.observableBehaviorsEmployeeAverage,
        isCompetency,
    ]);

    const averageScoreScalePersonScore = React.useMemo(() => {
        return {
            score: scores.reviewScore ?? scores.averageScore!,
            candidateProId: "",
            candidateDisplayName: "",
            candidatePictureUri: "",
        } as IPersonScore;
    }, [scores.reviewScore, scores.averageScore]);

    const averageScoreScaleValue = React.useMemo(() => {
        return scores.reviewScore
            ? scores.reviewScore.toFixed(1)
            : scores.averageScore
            ? scores.averageScore.toFixed(1)
            : undefined;
    }, [scores.reviewScore, scores.averageScore]);

    const renderToggleRow: () => JSX.Element = () => {
        const shouldShowCheckbox: boolean = onSelectChange !== undefined && isSelected !== undefined;

        const defaultContent = (
            <>
                <div className="competency-col content-col">
                    {showCheckIcon && (
                        <AtIcon
                            icon={[isSelected ? "fas" : "far", "check-circle"]}
                            color={isSelected ? "#3366FF" : "#D6D6D6"}
                        />
                    )}
                    {shouldShowCheckbox && (
                        <AtCheckboxBase
                            checked={isSelected!}
                            onChange={onSelectChange}
                            disabled={disabledSelect}
                            fieldName={"selectedElements"}
                            id={dataRowAsBaseCompetencyElementResult.id}
                        />
                    )}
                    {printMode === undefined && (
                        <div className="chevron-container">
                            <FontAwesomeIcon icon={["far", isExpanded ? "chevron-up" : "chevron-down"]} />
                        </div>
                    )}
                    {showRank && (
                        <AtTitle
                            headingType={5}
                            className="competency-rank"
                            title={`${dataRowAsBaseCompetencyElementResult.rank}.`}
                        />
                    )}
                    <div className="competencyNameCell">
                        <AtTitle
                            headingType={5}
                            className="competency-name"
                            title={dataRowAsBaseCompetencyElementResult.name}
                        />
                        <div className="tags-container">
                            {isCompetency ? (
                                dataRowAsCompetency.competencySources.map((x, index) => (
                                    <Fragment key={x}>
                                        <AtSubtitle
                                            subtitle={`competencyDevelopment.competencySource.${CompetencySource[
                                                x
                                            ].toCamel()}`.localize()}
                                            size="sm"
                                        />
                                        {index + 1 < dataRowAsCompetency.competencySources.length && <span>•</span>}
                                    </Fragment>
                                ))
                            ) : (
                                <AtSubtitle subtitle={parentCompetencyName} size="sm" />
                            )}
                        </div>
                    </div>
                </div>
                {scoresColumnSet === "developmentPlanScores" &&
                    isCompetency &&
                    isColumnDisplayed("potential", columns, hiddenColumns) && (
                        <div className="potential-col content-col">
                            <PotentialBadge score={dataRowAsCompetency.psychometricScore} />
                        </div>
                    )}
                {isColumnDisplayed("averageScore", columns, hiddenColumns) && (
                    <div className="average-note-col content-col">
                        <AverageScoreDisplay
                            reviewScore={scores.reviewScore}
                            averageScore={scores.averageScore}
                            averageScoreDisplayMode={averageScoreDisplayMode}
                        />
                        {!!scores.averageScore! && scores.reviewScore && !disableScoreReviewIndicator && (
                            <AtScoreReviewIndicator
                                score={scores.averageScore}
                                scoreDisplayMode={"average"}
                                averageScoreDisplayMode={averageScoreDisplayMode}
                            />
                        )}
                    </div>
                )}
                {isColumnDisplayed("averageScoreScale", columns, hiddenColumns) && (
                    <div className="average-note-scale-col content-col">
                        <ScaleRow
                            key={v4()}
                            scaleType={DOCSCALETYPE}
                            valueLabel={averageScoreScaleValue}
                            showLabels={false}
                        >
                            <Scale
                                scaleType={DOCSCALETYPE}
                                scores={[averageScoreScalePersonScore]}
                                maxScore={MAXSCORE}
                                isDecimalScale={false}
                                colorMappingFunc={getScaleColorFromScore}
                            />
                        </ScaleRow>
                    </div>
                )}
                {isColumnDisplayed("employeeScore", columns, hiddenColumns) && (
                    <div className="employee-note-col content-col">{getRoundedScore(scores.employeeScore)}</div>
                )}
                {isColumnDisplayed("managerScore", columns, hiddenColumns) && (
                    <div className="manager-note-col content-col">{getRoundedScore(scores.managerScore)}</div>
                )}
                {isColumnDisplayed("spread", columns, hiddenColumns) && (
                    <div className="spread-col content-col">
                        {scores.employeeScore && scores.managerScore
                            ? `± ${Math.abs(scores.employeeScore - scores.managerScore).toFixed(1)}`
                            : "-"}
                    </div>
                )}
            </>
        );

        return (
            <>
                {printMode === undefined ? (
                    <Mobile>
                        <div className="chevron-container">
                            <FontAwesomeIcon icon={["far", isExpanded ? "chevron-up" : "chevron-down"]} />
                        </div>
                        <div className="competency-content-container">
                            <div className="competency-col content-col">
                                {shouldShowCheckbox && (
                                    <AtCheckboxBase
                                        checked={isSelected!}
                                        onChange={onSelectChange}
                                        disabled={disabledSelect}
                                        fieldName={"selectedElements"}
                                        id={dataRowAsBaseCompetencyElementResult.id}
                                    />
                                )}
                                {showRank ? (
                                    <AtTitle
                                        className="competency-title"
                                        headingType={4}
                                        title={`${dataRowAsBaseCompetencyElementResult.rank}. ${dataRowAsBaseCompetencyElementResult.name}`}
                                    />
                                ) : (
                                    <AtTitle
                                        className="competency-title"
                                        headingType={4}
                                        title={dataRowAsBaseCompetencyElementResult.name}
                                    />
                                )}
                                {!isCompetency && parentCompetencyName && (
                                    <AtBadge size={"sm"} badgeStyle={"fill-light"}>
                                        {parentCompetencyName}
                                    </AtBadge>
                                )}
                                {isCompetency &&
                                    dataRowAsCompetency.competencySources.map((x) => (
                                        <AtBadge size={"sm"} badgeStyle={"fill-light"} key={x}>
                                            {`competencyDevelopment.competencySource.${CompetencySource[
                                                x
                                            ].toCamel()}`.localize()}
                                        </AtBadge>
                                    ))}
                                {scoresColumnSet === "developmentPlanScores" &&
                                    isCompetency &&
                                    isColumnDisplayed("potential", columns, hiddenColumns) && (
                                        <div className="scores-container">
                                            <PotentialBadge score={dataRowAsCompetency.psychometricScore} />
                                        </div>
                                    )}
                            </div>
                            <div className="DetailedScoreColumns">
                                <div className="section-left">
                                    {isColumnDisplayed("employeeScore", columns, hiddenColumns) && (
                                        <div className="score-container">
                                            <AtPersonAvatar
                                                firstName={employeeInfo?.firstName}
                                                lastName={employeeInfo?.lastName}
                                                email={employeeInfo?.email}
                                                pictureUri={employeeInfo?.pictureUri}
                                            />
                                            <div className="score">{getRoundedScore(scores.employeeScore)}</div>
                                        </div>
                                    )}
                                    {isColumnDisplayed("managerScore", columns, hiddenColumns) && (
                                        <div className="score-container">
                                            <AtPersonAvatar
                                                firstName={managerInfo?.firstName}
                                                lastName={managerInfo?.lastName}
                                                email={managerInfo?.email}
                                                pictureUri={managerInfo?.pictureUri}
                                            />
                                            <div className="score">{getRoundedScore(scores.managerScore)}</div>
                                        </div>
                                    )}
                                    {isColumnDisplayed("spread", columns, hiddenColumns) && (
                                        <div className="score-container">
                                            <div className="score spread">
                                                {scores.employeeScore && scores.managerScore
                                                    ? `± ${Math.abs(scores.employeeScore - scores.managerScore).toFixed(
                                                          1,
                                                      )}`
                                                    : "-"}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {isColumnDisplayed("averageScore", columns, hiddenColumns) && (
                                    <div className="section-right">
                                        <div className={"score-container"}>
                                            <AverageScoreDisplay
                                                reviewScore={scores.reviewScore}
                                                averageScore={scores.averageScore}
                                                averageScoreDisplayMode={averageScoreDisplayMode}
                                            />
                                            {scores.averageScore &&
                                                scores.reviewScore &&
                                                !disableScoreReviewIndicator && (
                                                    <AtScoreReviewIndicator
                                                        score={scores.averageScore}
                                                        scoreDisplayMode={"average"}
                                                        averageScoreDisplayMode={averageScoreDisplayMode}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                )}
                                {isColumnDisplayed("averageScoreScale", columns, hiddenColumns) && (
                                    <div className="section-right">
                                        <div className={"score-container"}>
                                            <ScaleRow
                                                key={v4()}
                                                scaleType={DOCSCALETYPE}
                                                valueLabel={averageScoreScaleValue}
                                                showLabels={false}
                                            >
                                                <Scale
                                                    scaleType={DOCSCALETYPE}
                                                    scores={[averageScoreScalePersonScore]}
                                                    maxScore={MAXSCORE}
                                                    isDecimalScale={false}
                                                    colorMappingFunc={getScaleColorFromScore}
                                                />
                                            </ScaleRow>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Mobile>
                ) : null}
                {printMode === undefined ? <Default>{defaultContent}</Default> : defaultContent}
            </>
        );
    };

    return (
        <AtCollapse
            className={getClasses()}
            isOpen={isExpanded}
            defaultOpen={expandOnMount}
            toggleCollapse={toggleExpansion}
            toggleRowContent={renderToggleRow()}
            toggleRowSize="sm"
            toggleRowSizeHorizontal="lg"
            toggleRowClassName={`toggle-row ${inError ? "in-error" : ""}`}
            collapseClassName="table-row-content-container"
            collapseContentOffset={collapseContentOffset}
            renderChevron={false}
        >
            {children}
        </AtCollapse>
    );
});

function getScaleColorFromScore(value: number) {
    const likertVal: LikertValue | undefined = ScaleHelper.interpretLikertAvgScore(value);

    return likertVal !== undefined ? Colors.likertScaleColors.get(likertVal)! : Colors.greyShades.shade9;
}
