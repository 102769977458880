import "./index.less";
import * as React from "react";
import { AcceptOrDenyConfirmation } from "./component/AcceptOrDenyConfirmation";
import { AtButton, AtCheckbox, AtParagraph } from "@atman/design-system";
import { AtmanCoPlatformsEnum } from "../../../global/components/ProductSelector";
import { AuthStore } from "../../../data/stores/AuthStore";
import { LoginAppStores } from "../../../../index";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useHistory, useLocation } from "react-router-dom";
import { useMemo, useState } from "react";
import LoginComponentHeader from "../../../global/components/LoginComponentHeader";

export interface IAcceptOrDenyRequestAccess {
    authStore?: AuthStore;
}

export const AcceptOrDenyRequestAccess: React.FC<IAcceptOrDenyRequestAccess> = inject<LoginAppStores, any, any, any>(
    (stores) => ({
        authStore: stores.authStore,
    }),
)(
    observer((props: IAcceptOrDenyRequestAccess) => {
        const { authStore } = props;

        const history = useHistory();
        const { search } = useLocation();

        const [hasAcceptedTerm, setHasAcceptedTerm] = useState(false);
        const [hasAcceptRequest, setHasAcceptRequest] = useState(false);
        const [hasDenyRequest, setHasDenyRequest] = useState(false);
        const [isLoading, setIsLoading] = useState(false);

        const { loginAccessToken, clientName } = useMemo(() => {
            const params = new URLSearchParams(search);

            return {
                loginAccessToken: params.get("token"),
                clientName: params.get("client_name"),
            };
        }, [search]);

        if (!loginAccessToken) {
            history.push("/Unauth/Login");
            return null;
        }

        const onCheckboxChange = () => {
            setHasAcceptedTerm((canRequestAccessToCandidate) => !canRequestAccessToCandidate);
        };

        const acceptRequest = async () => {
            setIsLoading(true);
            await authStore!.acceptCandidateDataAccessRequest(loginAccessToken, {
                platform: AtmanCoPlatformsEnum.ProNA,
            });
            setHasAcceptRequest(true);
            setIsLoading(false);
        };

        const denyRequest = async () => {
            setIsLoading(true);
            await authStore!.denyCandidateDataAccessRequest(loginAccessToken, { platform: AtmanCoPlatformsEnum.ProNA });
            setHasDenyRequest(true);
            setIsLoading(false);
        };

        return (
            <div id="AcceptOrDenyRequestAccess">
                <LoginComponentHeader
                    title={t({
                        id: "loginApp.acceptOrDenyConfirmation.mainTitle",
                        message: `${clientName} wants to have access to your assessments’ results.`,
                        values: {
                            clientName,
                        },
                    })}
                    icon={["fas", "user-lock"]}
                    size="lg"
                />
                {hasAcceptRequest ? (
                    <AcceptOrDenyConfirmation
                        title={t({ id: "loginApp.acceptOrDenyConfirmation.approvedAlertTitle" })}
                        subtitle={t({
                            id: "loginApp.acceptOrDenyConfirmation.approvedAlertDescription",
                            values: { clientName },
                        })}
                        color="success"
                    />
                ) : hasDenyRequest ? (
                    <AcceptOrDenyConfirmation
                        title={t({ id: "loginApp.acceptOrDenyConfirmation.refusedAlertTitle" })}
                        subtitle={t({
                            id: "loginApp.acceptOrDenyConfirmation.refusedAlertDescription",
                            values: { clientName },
                        })}
                        color="danger"
                    />
                ) : (
                    <>
                        <div className="mainContent">
                            <AtParagraph>
                                {t({ id: "loginApp.acceptOrDenyConfirmation.mainContent", values: { clientName } })}
                            </AtParagraph>
                        </div>

                        <AtCheckbox
                            checked={hasAcceptedTerm}
                            fieldName={"understandCheckbox"}
                            id={"understandCheckboxId"}
                            value={false}
                            onChange={onCheckboxChange}
                        >
                            {t({ id: "loginApp.acceptOrDenyConfirmation.iUnderstand" })}
                        </AtCheckbox>
                        <div className="buttonGroup">
                            <AtButton
                                className="refuseButton"
                                color={"danger"}
                                size="lg"
                                fullWidth
                                type="button"
                                onClick={denyRequest}
                            >
                                {t({ id: "loginApp.deny" })}
                            </AtButton>
                            <AtButton
                                color={"primary"}
                                size="lg"
                                fullWidth
                                type="button"
                                onClick={acceptRequest}
                                disabled={!hasAcceptedTerm}
                                isLoading={isLoading}
                            >
                                {t({ id: "global.buttons.labels.confirm" })}
                            </AtButton>
                        </div>
                    </>
                )}
            </div>
        );
    }),
);
