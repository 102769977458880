import "./TransactionExpandRow.less";
import * as React from "react";
import { FormGroupSection } from "../../FormGroupSection";
import { Transaction } from "@atman/business";
import { TransactionAffectedBalanceBadge } from "./TransactionAffectedBalanceBadge";

export interface ITransactionExpandRowProps {
    transaction: Transaction;
}

export const TransactionExpandRow: React.FC<ITransactionExpandRowProps> = (props) => {
    const { transaction } = props;

    return (
        <div className="TransactionExpandRow">
            <FormGroupSection sectionTitle={"global.affectedBalance".localize()} className={"affected-balance-section"}>
                {transaction.products.map((x, i) => (
                    <TransactionAffectedBalanceBadge product={x} key={i} />
                ))}
            </FormGroupSection>
            <FormGroupSection sectionTitle={"global.notes".localize()} className={"notes-section"}>
                {transaction.comments}
            </FormGroupSection>
        </div>
    );
};
