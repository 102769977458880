import "./index.less";
import { AtButton, AtLink, AtTitle, AtTooltip } from "@atman/design-system";
import { AtmanCoPlatformsEnum } from "../../../global/components/ProductSelector";
import { AuthApi, IAuthApi } from "../../../api/AuthApi";
import { BaseStepComponent, CustomValidatedTextInput } from "@atman/ui-kit";
import { Form } from "reactstrap";
import { ILoginStepData } from "../Login";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { ToastProvider, Validator } from "@atman/business";
import { Trans, t } from "@lingui/macro";
import { action, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import LoginComponentHeader from "../../../global/components/LoginComponentHeader";
import React from "react";
import autobind from "autobind-decorator";

export interface IForgotPasswordProps
    extends RouteComponentProps<
        {},
        StaticContext,
        {
            selectedPlatform?: AtmanCoPlatformsEnum;
            email?: string;
            fromHelpCenterLogin?: boolean;
            skipSilentLogin?: boolean;
        }
    > {}

@observer
class ForgotPassword extends BaseStepComponent<IForgotPasswordProps, {}> {
    private readonly _authApi: IAuthApi;

    private _timeoutId: number | null = null;
    @observable private _canSubmitANewRequest: boolean = true;

    private readonly currentStepDataMapping: Map<number, ILoginStepData> = new Map<number, ILoginStepData>([
        [
            0,
            {
                action: {
                    label: t({ id: "global.buttons.labels.sendEmail", message: `Send Email` }),
                    event: this.onSave,
                },
            },
        ],
        [
            1,
            {
                action: {
                    label: t({ id: "loginApp.global.buttons.labels.goToLogIn", message: `Go To Log In` }),
                    event: (e) => this.goToLoginPage(e),
                },
            },
        ],
    ]);

    constructor(props) {
        super(props);

        this._authApi = new AuthApi();
    }

    @observable selectedPlatform?: AtmanCoPlatformsEnum;

    @observable email: string = "";

    componentDidMount() {
        this.loadDataFromLocationState();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        if (this._timeoutId) {
            window.clearTimeout(this._timeoutId);
        }
    }

    @action loadDataFromLocationState = () => {
        const { location } = this.props;

        this.email = location.state?.email ?? "";
        this.selectedPlatform = location.state?.selectedPlatform;
    };

    goToLoginPage = (e: React.FormEvent<any>) => {
        const { history, location } = this.props;

        e.preventDefault();

        if (location.state?.fromHelpCenterLogin) {
            history.push("/Unauth/LoginToHelpCenter", { email: this.email });
            return;
        }

        history.push("/Unauth/Login", { skipSilentLogin: true, email: this.email });
    };

    protected validateEmail(): boolean {
        const {} = this.props;

        const fieldName = "email";

        this.clearErrorForField(fieldName);

        if (!this.email) {
            this.errors.push({
                name: "ValidationError",
                message: t({ id: "global.errors.email.empty", message: "Email is empty" }),
                fieldName,
            });

            return false;
        }

        if (!Validator.validateEmail(this.email)) {
            this.errors.push({
                name: "ValidationError",
                message: t({ id: "global.errors.email.invalid", message: "Email is invalid" }),
                fieldName,
            });

            return false;
        }

        return !this.getErrorsForField(fieldName).any();
    }

    @autobind
    async onSave(e?: React.FormEvent<any>) {
        const {} = this.props;

        e?.preventDefault();

        if (!this.validateEmail()) {
            return;
        }

        this.setIsLoading(true);

        try {
            await this._authApi.requestPasswordReset(this.email, { platform: this.selectedPlatform });
        } catch (e) {
            // We skip any kind of error here since don't want to acknowledge if the email exists or not.
        }

        this.setIsLoading(false);

        ToastProvider.success(
            t({
                id: "loginApp.forgotPasswordForm.successMessages.passwordReset",
                message: `We received your password reset request`,
            }),
        );

        this.setRequestTimer();

        this.goToStep(1);
    }

    resendRequest = async () => {
        if (!this._canSubmitANewRequest) {
            return;
        }

        await this.onSave();

        this.setRequestTimer();
    };

    @action setRequestTimer = () => {
        this._canSubmitANewRequest = false;

        this._timeoutId = window.setTimeout(() => {
            runInAction(() => {
                this._canSubmitANewRequest = true;
            });
        }, 60000);
    };

    render() {
        const {} = this.props;

        const currentStepData = this.currentStepDataMapping.get(this.currentStep);

        return (
            <div id="ForgotPassword">
                <Form onSubmit={currentStepData?.action?.event}>
                    {this.currentStep === 0 && (
                        <>
                            <AtTitle
                                headingType={1}
                                title={
                                    <Trans id={"loginApp.forgotPasswordForm.tempPasswordStep.title"}>
                                        Account Recovery
                                    </Trans>
                                }
                            />

                            <CustomValidatedTextInput
                                fieldName={"email"}
                                value={this.email}
                                onChange={this.onTextFieldChange}
                                type={"email"}
                                label={t({ id: "global.inputs.labels.loginEmail", message: `Login Email` })}
                                validationErrors={this.errors}
                                containerClassName={"email-input"}
                            />
                            <LoginComponentHeader
                                title={t({
                                    id: "loginApp.forgotPasswordForm.tempPasswordStep.subtitle",
                                    message: `Get a temporary password`,
                                })}
                                icon={["far", "envelope"]}
                                size={"sm"}
                            />
                            <div className="info-container">
                                <Trans id={"loginApp.forgotPasswordForm.tempPasswordStep.helpText"}>
                                    We will send you a temporary password to the communication email you provided.
                                </Trans>
                            </div>
                        </>
                    )}
                    {this.currentStep === 1 && (
                        <>
                            <LoginComponentHeader
                                title={t({
                                    id: "loginApp.forgotPasswordForm.emailConfirmStep.subtitle",
                                    message: `Email was sent`,
                                })}
                                icon={["far", "envelope-open"]}
                            />
                            <div className="info-container">
                                <Trans id={"loginApp.forgotPasswordForm.emailConfirmStep.emailSentExplanation"}>
                                    You will receive an email with a temporary password in your inbox at the
                                    communication email you provided in a few moments.
                                </Trans>
                            </div>
                            <div id="resend-link-container">
                                <Trans id={"loginApp.forgotPasswordForm.emailConfirmStep.notReceivedResendLink"}>
                                    If you still haven&apos;t received it,{" "}
                                    <AtLink
                                        onClick={this.onSave}
                                        disabled={!this._canSubmitANewRequest}
                                        id="resend-password-reset-btn"
                                    >
                                        resend a new temporary password
                                    </AtLink>
                                    .
                                </Trans>
                                {!this._canSubmitANewRequest && (
                                    <AtTooltip target={"resend-link-container"}>
                                        <Trans
                                            id={
                                                "loginApp.forgotPasswordForm.emailConfirmStep.resendPasswordRequestBtnDisabledTooltip"
                                            }
                                        >
                                            Please wait a minute before sending a new request
                                        </Trans>
                                    </AtTooltip>
                                )}
                            </div>
                        </>
                    )}
                    {currentStepData?.action && (
                        <AtButton type={"submit"} color={"primary"} size={"xl"} fullWidth isLoading={this.isLoading}>
                            {currentStepData.action.label}
                        </AtButton>
                    )}
                </Form>
                {this.currentStep === 1 && (
                    <AtLink onClick={this.goToLoginPage}>
                        <Trans id="loginApp.global.buttons.labels.backToLogIn">Back To Log In</Trans>
                    </AtLink>
                )}
            </div>
        );
    }
}

export default withRouter(ForgotPassword);
