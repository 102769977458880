import "./UnavailableSystem.less";
import * as React from "react";
import { AtHint, AtSubtitle, AtTitleSubtitle } from "@atman/design-system";
import { LocalizationStore } from "@atman/business";
import { LoginAppStores } from "../../../../index";
import { SystemStateInfo } from "../../../data/models/SystemState";
import { Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";

export interface IUnavailableSystemProps {
    activeSystemState: SystemStateInfo;
    localizationStore?: LocalizationStore;
}

export const UnavailableSystem: React.FC<IUnavailableSystemProps> = inject((stores: LoginAppStores) => ({
    localizationStore: stores.localizationStore,
}))(
    observer((props) => {
        const { activeSystemState, localizationStore } = props;

        return (
            <div id="UnavailableSystem">
                <AtHint className="hint-container" color={"warning"}>
                    <AtTitleSubtitle
                        title={activeSystemState.title[localizationStore!.currentShortLocale]}
                        subtitle={activeSystemState.subtitle[localizationStore!.currentShortLocale]}
                        size={"xl"}
                    />
                    <AtSubtitle
                        className={"estimated-end-subtitle"}
                        subtitle={
                            <>
                                <Trans id="loginApp.unavailableSystem.estimatedEnd.subtitle">
                                    Everything should be up and running again by{" "}
                                    <span className="date-info">{activeSystemState.endDate.format("lll")}</span>
                                </Trans>
                            </>
                        }
                    />
                </AtHint>
                {activeSystemState.helpCenterUrl ? (
                    <div className="help-center-section">
                        <AtSubtitle
                            className={"help-center-link-subtitle"}
                            subtitle={
                                <>
                                    <Trans id="loginApp.unavailableSystem.helpCenterLink">
                                        Visit our <a href={activeSystemState.helpCenterUrl}>help center article</a> for
                                        more details about this downtime
                                    </Trans>
                                </>
                            }
                        />
                    </div>
                ) : null}
            </div>
        );
    }),
);
