import "./index.less";
import { AtContainer } from "../../../..";
import React from "react";
export interface IHighlightedTextProps {
    color: string;
    children: React.ReactNode;
}

export const HighlightedText: React.FC<IHighlightedTextProps> = (props) => {
    const { color, children, ...otherProps } = props;
    return (
        <AtContainer className="HighlightedText" style={{ backgroundColor: color }} {...otherProps}>
            {children}
        </AtContainer>
    );
};
