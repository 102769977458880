import { HandlePanelChangeWithAnimation, SlidingDirection } from "../..";
import { SidePanelItemDefinition } from "../../../../../SidePanelElement/SidePanelStructure";
import { SidePanelSubElementsRenderer } from "../SidePanelSubElementsRenderer";
import { observer } from "mobx-react";
import React from "react";
import cn from "classnames";

export interface ISidePanelRendererTopLevelNoChildrenProps {
    sidePanelItemDefinitions: SidePanelItemDefinition[];
    activeSidePanel: SidePanelItemDefinition | undefined;
    handlePanelChangeWithAnimation: HandlePanelChangeWithAnimation;
    slidingDirection: SlidingDirection;
    externalSideElementHighlightId?: string;
}

export const SidePanelRendererTopLevelNoChildren: React.FC<ISidePanelRendererTopLevelNoChildrenProps> = observer(
    (props) => {
        const {
            sidePanelItemDefinitions,
            slidingDirection,
            activeSidePanel,
            handlePanelChangeWithAnimation,
            externalSideElementHighlightId,
            ...otherProps
        } = props;

        return (
            <div className="SidePanelContainer rootPanel" {...otherProps}>
                <div
                    className={cn("sidePanelElementWrapper", "animate-wrapper", "animate__animated", {
                        animate__fadeInRight: slidingDirection === "forward",
                        animate__fadeInLeft: slidingDirection === "backwards",
                    })}
                >
                    <SidePanelSubElementsRenderer
                        isLoading={false}
                        subElements={sidePanelItemDefinitions}
                        activeSidePanelId={activeSidePanel?.id}
                        handlePanelChangeWithAnimation={handlePanelChangeWithAnimation}
                        isCustomRightSidePanelDisplayed // We don't have a toggle for the custom right, always display
                        externalSideElementHighlightId={externalSideElementHighlightId}
                    />
                </div>
            </div>
        );
    },
);
