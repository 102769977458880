import "./index.less";
import * as React from "react";
import { Colors } from "@atman/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalStores, LocalizationStore, getHelpCenterUrl } from "@atman/business";
import { inject, observer } from "mobx-react";

export interface IHelpCenterInfoLinkProps {
    title: string;
    href: string;
    localizationStore?: LocalizationStore;
}

export const HelpCenterInfoLink: React.FC<IHelpCenterInfoLinkProps> = inject(GlobalStores.localizationStore)(
    observer((props) => {
        const { title, href, localizationStore } = props;

        const onLinkClick = async () => {
            const helpCenterUrl = await getHelpCenterUrl(href, localizationStore!.currentFullLocale);
            window.open(helpCenterUrl, "_blank");
        };

        return (
            <div className="HelpCenterInfoLink">
                <a onClick={onLinkClick}>
                    <div className="icon-container">
                        <FontAwesomeIcon icon={["far", "book"]} color={Colors.primaryBase} size="1x" />
                    </div>
                    <span>{title}</span>
                </a>
            </div>
        );
    }),
);
