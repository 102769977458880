import { AppContextSearchScope, HttpMethodEnum, SearchAllCategory, SearchAllItemResultModel } from "../types";
import { BaseApi, DataType } from "../base";
import { ReactAppSettings } from "../libs";

export interface ISearchResult {
    items: SearchAllItemResultModel[];
    moreItems: boolean;
}

export class SearchApi extends BaseApi {
    public static async searchAllItems(queryString: string, categories: SearchAllCategory[] = []) {
        const data = {
            queryString,
            categories,
        };

        return this.sendApiRequestWithResult<typeof data, SearchAllItemResultModel[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.searchAll,
            data,
            undefined,
            DataType.QueryString,
        ).catch((error) => [] as SearchAllItemResultModel[]);
    }

    public static async getAvailableAppContexts(searchScope: AppContextSearchScope, queryString?: string) {
        const data = {
            queryString,
            searchScope,
        };

        return this.sendApiRequestWithResult<typeof data, SearchAllItemResultModel[]>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getAvailableAppContexts,
            data,
            undefined,
            DataType.QueryString,
        ).catch((error) => [] as SearchAllItemResultModel[]);
    }
}
