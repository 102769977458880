import * as qs from "qs";
import { ApiManager, ReactAppSettings } from "../libs";
import { BaseApi } from "../base";
import { ConfigurationProvider } from "../utils/ConfigurationProvider";

export class SecurityApi extends BaseApi {
    static async compareBundleVersions() {
        return ApiManager.get(`${ReactAppSettings.appUrls.getReactBundleVersion}`, {
            params: {
                appName: ConfigurationProvider.appName,
            },
            paramsSerializer: (params) => qs.stringify(params),
        })
            .then((result) => {
                if (String(result.data.bundleVersion) !== String(ConfigurationProvider.currentVersion)) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                window.location.reload();
            });
    }

    static async getIntercomUnreadCount() {
        return ApiManager.get(ReactAppSettings.appUrls.getIntercomUnreadCount).then((result) => {
            console.log(result);
        });
    }
}
