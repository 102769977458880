import "./index.less";
import {
    AnalyzedCandidate,
    AssessmentDocumentHelper,
    AssessmentType,
    AtmanAssessmentDocumentStructure,
    DimensionGroupStructure,
    DistributionData,
    DistributionViewMode,
    FitLevelEnum,
    IInterpretationData,
    IPersonScores,
    PrintModeEnum,
    ReportType,
} from "@atman/business";
import { BaseComponent } from "../../BaseComponent";
import _uniqueId from "lodash/uniqueId";

export interface IBaseAtScaleGroupProps {
    dimensionGroup: DimensionGroupStructure;
    maxScore: number;
    assessmentType: AssessmentType;
    scores?: IPersonScores[];
    distributionData?: DistributionData;
    showDistribution?: boolean;
    distributionViewMode?: DistributionViewMode;
    distributionMembers?: AnalyzedCandidate[];
    analyzedCandidateScores?: Dictionary<string, number>;
    expandedDimension?: string;
    onExpandedDimensionChange?: (dimensionName: string) => void;
    showHeader?: boolean;
    showDescription?: boolean;
    showFitIndicators?: boolean;
    currentFitLevelFilter?: FitLevelEnum | "showAll";
    interpretationDataEntries?: Map<string, IInterpretationData>;
    displayCompact?: boolean;
    printMode?: PrintModeEnum;
    documentStructure?: AtmanAssessmentDocumentStructure;
    isInTeamView?: boolean;
    reportType?: ReportType;
    forceShowScoreCount?: boolean;
    forceToHideHighLabel?: boolean;
}

export abstract class BaseAtScaleGroup<TProps> extends BaseComponent<IBaseAtScaleGroupProps & TProps, {}> {
    protected id: string;
    protected baseClassName = "BaseAtScaleGroup";

    constructor(props) {
        super(props);
        this.id = _uniqueId("ScaleGroup-");
    }

    protected getScoresForDimension = (dimensionName: string) => {
        const { scores = [] } = this.props;

        return AssessmentDocumentHelper.getScoresForDimension(scores, dimensionName);
    };
}
