import "./ConsumptionRateCardItem.less";
import * as React from "react";
import { BaseOverviewCardItem } from "./BaseOverviewCardItem";
import { ConsumptionRateProgress } from "../../../../components/ConsumptionRateProgress";
import { IProductConsumptionModeScheduleEntryModel } from "@atman/business";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface IConsumptionRateCardItemProps {
    scheduleEntry?: IProductConsumptionModeScheduleEntryModel;
    profilesCount: number;
}

export const ConsumptionRateCardItem: React.FC<IConsumptionRateCardItemProps> = (props) => {
    const { scheduleEntry, profilesCount } = props;

    return (
        <BaseOverviewCardItem
            icon={["far", "calendar-day"] as IconProp}
            cardTitle={"global.usageRate".localize()}
            className={"ConsumptionRateCardItem"}
        >
            <ConsumptionRateProgress scheduleEntry={scheduleEntry} profilesCount={profilesCount} />
        </BaseOverviewCardItem>
    );
};
