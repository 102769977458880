import "./AtQuickSearch.less";
import * as React from "react";
import {
    AppScope,
    CandidateFilterComparator,
    CandidateFilterObject,
    CandidateFilterType,
    ErrorHandler,
    GlobalStores,
    ICandidateFilter,
    ItemType,
    LocalizationStore,
    Logger,
    ModalStore,
    ModalTypes,
    ReactAppSettings,
    SearchAllItemResultModel,
    SearchApi,
    UrlFormatter,
} from "@atman/business";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IReactionDisposer, action, observable, reaction, runInAction } from "mobx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Moment } from "moment";
import { RouteComponentProps, withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { v4 } from "uuid";
import Autosuggest, { InputProps } from "react-autosuggest";

import moment from "moment";

export interface IClickActions {
    workspace?: (id: string) => void;
    tag?: (id: string) => void;
}

export interface IAtQuickSearchProps extends RouteComponentProps<any> {
    scope: AppScope;
    filterStore?: any;
    localizationStore?: LocalizationStore;
    modalStore?: ModalStore;
    clickActions?: IClickActions;
}

@inject(GlobalStores.localizationStore, GlobalStores.modalStore)
@observer
class AtQuickSearchComp extends React.Component<IAtQuickSearchProps, {}> {
    private readonly autosuggestRef: React.RefObject<Autosuggest<SearchAllItemResultModel>>;

    @observable public searchString: string = "";
    @observable public lastSearchTimestamp: Moment = moment();
    @observable public isLoading: boolean = false;
    @observable public suggestions: SearchAllItemResultModel[] = [];
    @observable public typingTimeout?: number;

    @observable public isExpanded?: boolean = false;

    searchStringReactionDisposer: IReactionDisposer = reaction(
        () => this.searchString,
        (searchString) => (this.lastSearchTimestamp = moment()),
    );

    constructor(props) {
        super(props);

        this.autosuggestRef = React.createRef();
    }

    componentWillUnmount(): void {
        this.searchStringReactionDisposer();
    }

    @action.bound
    protected loadSuggestions = async (searchValue: string) => {
        this.isLoading = true;

        try {
            if (this.typingTimeout) {
                window.clearTimeout(this.typingTimeout);
            }

            this.typingTimeout = window.setTimeout(async () => {
                const result = await SearchApi.searchAllItems(searchValue);

                runInAction(() => {
                    this.suggestions = result;
                    this.isLoading = false;
                });
            }, 300);
        } catch (e) {
            this.isLoading = false;

            ErrorHandler.handleError(e);
        }
    };

    protected getSuggestionValue = (suggestion: SearchAllItemResultModel) => {
        return suggestion.label;
    };

    protected onSuggestionSelected = (event, { suggestion }: { suggestion: SearchAllItemResultModel }) => {
        const { modalStore, history, scope, clickActions, filterStore } = this.props;

        switch (suggestion.itemType) {
            case ItemType.Candidate:
                const candidatePathInClientApp = `/People/${suggestion.id}`;

                if (scope !== AppScope.Client) {
                    const candidateUrl = `${ReactAppSettings.appUrls.clientAppUrl.replace(
                        "{id}",
                        suggestion.clientId!,
                    )}${candidatePathInClientApp}`;

                    window.open(candidateUrl, "_blank");
                } else {
                    history.push(UrlFormatter.formatReactPath(candidatePathInClientApp));
                }
                break;
            case ItemType.User:
                modalStore!.openModal(ModalTypes.UserForm, { id: suggestion.id });
                break;
            case ItemType.Workspace:
                clickActions?.workspace?.(suggestion.id);

                if (filterStore) {
                    filterStore.setFilters([
                        {
                            id: v4(),
                            object: CandidateFilterObject.Workspace,
                            comparator: CandidateFilterComparator.Is,
                            type: CandidateFilterType.Select,
                            value: suggestion.id,
                            temp: true,
                        } as ICandidateFilter,
                    ]);

                    history.push(UrlFormatter.formatReactPath("/People"));
                }

                break;
            case ItemType.Tag:
                if (clickActions && clickActions.tag) {
                    clickActions.tag(suggestion.id);
                }
                if (filterStore) {
                    filterStore.setFilters([
                        {
                            id: v4(),
                            object: CandidateFilterObject.Tag,
                            comparator: CandidateFilterComparator.Is,
                            type: CandidateFilterType.Select,
                            value: suggestion.id,
                            temp: true,
                        } as ICandidateFilter,
                    ]);

                    history.push(UrlFormatter.formatReactPath("/People"));
                }
                break;
            case ItemType.Team:
                history.push(UrlFormatter.formatReactPath(`/Teams/${suggestion.id}`));
                break;
            case ItemType.QuickLink:
                modalStore!.openModal(ModalTypes.QuickLinkForm, { itemId: suggestion.id });
                break;
            case ItemType.Client:
                const clientPathInPartnerApp = `/Clients/${suggestion.id}`;

                if (scope === AppScope.Partner) {
                    history.push(UrlFormatter.formatReactPath(clientPathInPartnerApp));
                } else {
                    window.open(
                        `${ReactAppSettings.appUrls.partnerAppUrl.replace(
                            "{id}",
                            suggestion.partnerId!,
                        )}${clientPathInPartnerApp}`,
                    );
                }
                break;
            case ItemType.Partner:
                history.push(UrlFormatter.formatReactPath(`/Partners/${suggestion.id}`));
                break;
            default:
                Logger.log("Unhandled Item Type");
        }
    };

    protected renderSuggestion = (suggestion: SearchAllItemResultModel) => {
        // let itemIcon: IconProp;
        let itemLabel = suggestion.label;

        switch (suggestion.itemType) {
            case ItemType.Candidate:
                // itemIcon = "user-circle";
                break;
            case ItemType.User:
                // itemIcon = "user";
                break;
            case ItemType.Workspace:
                // itemIcon = "warehouse";
                break;
            case ItemType.Tag:
                // itemIcon = "tag";
                break;
            case ItemType.Team:
                // itemIcon = "users";
                break;
            case ItemType.QuickLink:
                // itemIcon = "link";
                break;
            case ItemType.Client:
                // itemIcon = "building";
                break;
            case ItemType.Partner:
                // itemIcon = "sitemap";
                break;
            default:
                // itemIcon = "question-circle";
                itemLabel = "unknownItem".localize();
                break;
        }

        const itemTypeLabel = ItemType[suggestion.itemType].toCamel().localize();
        const ownerName = suggestion.clientName
            ? suggestion.clientName
            : suggestion.partnerName
            ? suggestion.partnerName
            : "";

        return (
            <span className="suggestion-container">
                <span className="type-container">
                    {/*<span className="icon-container">*/}
                    {/*    <FontAwesomeIcon icon={itemIcon} size={"lg"} />*/}
                    {/*</span>*/}
                    <span className="type">{itemTypeLabel}</span>
                </span>
                <span className="label">{itemLabel}</span>
                {this.props.scope !== AppScope.Client ? <span className="ownerName">{ownerName}</span> : null}
            </span>
        );
    };

    protected onSuggestionsFetchRequested = async ({ value }: { value: string }) => {
        await this.loadSuggestions(value);
    };

    protected shouldRenderSuggestions = (value: string) => {
        return value.trim().length > 1;
    };

    @action
    protected onChange = (event: any, { newValue }: { newValue: string }) => {
        this.searchString = newValue;
    };

    @action
    protected onSuggestionsClearRequested = () => {
        this.suggestions = [];
    };

    @action
    protected clearSearchString = () => {
        this.searchString = "";
        this.suggestions = [];
    };

    @action
    protected cancelSearch = () => {
        this.clearSearchString();
        this.isExpanded = false;
    };

    @action
    protected openSearch = () => {
        if (!this.isExpanded) {
            this.isExpanded = true;
            this.autosuggestRef.current?.input?.focus();
        }
    };

    render() {
        const { localizationStore } = this.props;

        const inputProps = {
            placeholder: "global.quickSearch.placeholder".localize(),
            value: this.searchString,
            onChange: this.onChange,
            onBlur: this.cancelSearch,
        } as InputProps<SearchAllItemResultModel>;

        return (
            localizationStore!.currentShortLocale && (
                <div id={"AtQuickSearch"} className={this.isExpanded ? "is-expanded" : "is-not-expanded"}>
                    <div
                        className="search-icon-container"
                        onClick={this.openSearch}
                        style={{ pointerEvents: this.isExpanded ? "none" : undefined }}
                    >
                        <FontAwesomeIcon className={"search-icon"} icon={["far", "search"] as IconProp} />
                    </div>
                    <div className="input-container">
                        <Autosuggest
                            suggestions={this.suggestions?.slice() ?? []}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            onSuggestionSelected={this.onSuggestionSelected}
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestion={this.renderSuggestion}
                            shouldRenderSuggestions={this.shouldRenderSuggestions}
                            inputProps={inputProps}
                            ref={this.autosuggestRef}
                        />
                        <div className="status-icon-container">
                            {this.isLoading ? (
                                <FontAwesomeIcon icon={"spinner"} spin />
                            ) : (
                                this.searchString.length > 0 && (
                                    <a onClick={this.cancelSearch}>{"global.buttons.labels.cancel".localize()}</a>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )
        );
    }
}

const AtQuickSearch = withRouter(AtQuickSearchComp);

export { AtQuickSearch, AtQuickSearchComp };
