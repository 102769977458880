import { AtDateRangePickerPopover, DateRange } from "@atman/design-system";
import { AtTableDateFilterOptions, IAtTableFilter } from "../../../types";
import { IBaseEntity } from "@atman/core";
import React, { ReactElement, useMemo } from "react";
import moment from "moment";

export interface IAtTableDateRangeFilterProps<T extends IBaseEntity> extends IAtTableFilter<T> {
    appliedFilterValue: DateRange;
    setFilterValue: (columnKey: string, dateRange: DateRange) => void;
    filterOptions: AtTableDateFilterOptions;
    hideDateTo?: boolean; // TODO @ma - Temporary fix to hide the date to field - refactor to create new filter based on one date only
}

export function AtTableDateRangeFilter<T extends IBaseEntity>(props: IAtTableDateRangeFilterProps<T>): ReactElement {
    const { appliedFilterValue, setFilterValue, filterOptions, hideDateTo = false, column, ...otherProps } = props;
    const { datePresets, languageCode = "en" } = filterOptions;

    const dateRange = useMemo(() => {
        if (appliedFilterValue) {
            return appliedFilterValue;
        }
        return {
            from: moment().subtract(1, "year").toDate(),
            to: moment().toDate(),
        };
    }, [appliedFilterValue]);

    const handleDatesChange = (dateRange: DateRange) => {
        setFilterValue(column.key, dateRange);
    };

    return (
        <AtDateRangePickerPopover
            range={dateRange}
            setRange={handleDatesChange}
            languageCode={languageCode}
            datePresets={datePresets}
            fromDate={new Date(2015, 1, 1)}
            hideDateTo={hideDateTo}
            {...otherProps}
        />
    );
}
