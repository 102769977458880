import "./index.less";
import { AtCheckbox } from "../../molecules/AtCheckbox";
import { AtText } from "../../atoms/AtText";
import { xor } from "lodash";
import React, { useEffect, useMemo } from "react";
import cn from "classnames";

interface IAtCheckboxListItems {
    parentKey: string;
    parentLabel: string;

    items: {
        label: string;
        value: string;
        disable: boolean;
    }[];
    selectedItems: string[];
    onChangeItems: (items: string[]) => void;
    checkAllInMount?: boolean;
}

export const AtCheckboxListItems: React.FC<IAtCheckboxListItems> = (props) => {
    const { parentKey, parentLabel, onChangeItems, selectedItems, items, checkAllInMount = false } = props;

    const parentStatusChecked = useMemo((): boolean => selectedItems.length > 0, [selectedItems, items]);

    const toggleAllSelectedItems = () => {
        if (
            !selectedItems.length ||
            (selectedItems.length > 0 && selectedItems.length < items.filter((item) => !item.disable).length)
        ) {
            onChangeItems(items.filter((item) => !item.disable).map((item) => item.value));
        } else {
            onChangeItems([]);
        }
    };

    useEffect(() => {
        if (checkAllInMount) {
            onChangeItems(items.filter((item) => !item.disable).map((item) => item.value));
        }
        return () => {
            onChangeItems([]);
        };
    }, [checkAllInMount]);

    return (
        <div className={cn("AtCheckboxListItems")}>
            <AtCheckbox
                checked={parentStatusChecked}
                value={parentStatusChecked}
                onChange={toggleAllSelectedItems}
                id={`parent-checkbox-list-${parentKey}`}
                fieldName={"all-members"}
            >
                <AtText>{parentLabel}</AtText>
            </AtCheckbox>
            {items.map((item) => (
                <AtCheckbox
                    key={`item-${item.value}`}
                    checked={selectedItems.includes(item.value)}
                    value={selectedItems.includes(item.value)}
                    onChange={() => onChangeItems(xor(selectedItems, [item.value]))}
                    id={`${item.value}`}
                    fieldName={`${item.value}`}
                    disabled={item.disable}
                >
                    <AtText>{item.label}</AtText>
                </AtCheckbox>
            ))}
        </div>
    );
};
