import "./EntityOverviewCard.less";
import {
    AppScope,
    Client,
    GlobalStores,
    IAppContext,
    IndustryEnum,
    Partner,
    ProRestrictionType,
    ProductConsumptionMode,
    ReactAppSettings,
    UnhandledScopeError,
    UsageModel,
    UserStore,
} from "@atman/business";
import { AtTabCard } from "@atman/design-system";
import { EntityOverviewCardLine } from "./EntityOverviewCardLine";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { inject, observer } from "mobx-react";
import { withAppContext } from "../../../../contexts";
import React from "react";
import moment from "moment";

export interface IEntityOverviewCardProps extends IAppContext {
    entity: Client | Partner;
    userStore?: UserStore;
}

@inject(GlobalStores.userStore)
@observer
class EntityOverviewCardComp extends React.Component<IEntityOverviewCardProps> {
    render() {
        const { entity, scope, userStore } = this.props;

        const activeScheduleEntry = new UsageModel(entity.usageModel).currentScheduleEntry;

        const stats: IEntityOverviewStat[] = [];

        let cardTitleKey: string;

        switch (scope) {
            case AppScope.Partner:
                // const client = entity as Client;
                cardTitleKey = "partnerApp.clientOverview";
                break;
            case AppScope.Supplier:
                const partner = entity as Partner;
                cardTitleKey = "supplierApp.partnerOverview";

                const owner = ReactAppSettings.appModel.availableSalesReps.find((x) => x.id === partner.ownerId);

                if (owner) {
                    stats.push({
                        icon: ["far", "user"] as IconProp,
                        nameKey: "global.owner",
                        value: owner.fullName,
                    });
                }

                stats.push({
                    icon: ["far", "toolbox"] as IconProp,
                    nameKey: "supplierApp.partnerType",
                    value:
                        partner.marketingInfo && partner.marketingInfo.marketingType !== undefined
                            ? `global.industries.${IndustryEnum[
                                  partner.marketingInfo.marketingType
                              ].toCamel()}`.localize()
                            : "global.unknown".localize(),
                });
                break;
            default:
                throw new UnhandledScopeError(scope);
        }

        const otherStats: IEntityOverviewStat[] = [
            {
                icon: ["far", "tachometer-alt-average"] as IconProp,
                nameKey: "global.planType",
                value: `global.productConsumptionModes.${ProductConsumptionMode[
                    activeScheduleEntry ? activeScheduleEntry.consumptionMode : ProductConsumptionMode.Blocked
                ].toCamel()}`.localize(),
            },
            {
                icon: ["far", "lock-alt"] as IconProp,
                nameKey: "global.restriction",
                value: `global.${ProRestrictionType[entity.usageModel.restrictionType].toCamel()}`.localize(),
            },
            {
                icon: ["far", "users"] as IconProp,
                nameKey: "global.numberOfUsers",
                value: userStore!.entityUsers.length || 0,
            },
            {
                icon: ["far", "calendar"] as IconProp,
                nameKey: "global.lastSeen",
                value: entity.lastSeen ? moment.parseZone(entity.lastSeen).fromNow() : "global.never".localize(),
            },
            {
                icon: ["far", "calendar"] as IconProp,
                nameKey: "global.effectiveDate",
                value: activeScheduleEntry
                    ? moment.parseZone(activeScheduleEntry.effectiveDate).format("ll")
                    : "unset".localize(),
            },
            {
                icon: ["far", "calendar"] as IconProp,
                nameKey: "global.endDate",
                value: activeScheduleEntry
                    ? moment.parseZone(activeScheduleEntry.endDate).format("ll")
                    : "unset".localize(),
            },
        ];

        stats.push(...otherStats);

        return (
            <AtTabCard cardTitle={cardTitleKey.localize()} className={"EntityOverviewCard"}>
                {stats.map((x, i) => (
                    <EntityOverviewCardLine stat={x} key={i} />
                ))}
            </AtTabCard>
        );
    }
}

export interface IEntityOverviewStat {
    icon: IconProp;
    nameKey: string;
    value: string | number;
}

const EntityOverviewCard = withAppContext(EntityOverviewCardComp);

export { EntityOverviewCard };
