import "./index.less";
import * as React from "react";
import { AtButton } from "@atman/design-system";
import { GlobalStores, UiStore } from "@atman/business";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { inject, observer } from "mobx-react";

export interface IAtFullscreenButtonProps {
    uiStore?: UiStore;
}

@inject(GlobalStores.uiStore)
@observer
export class AtFullscreenButton extends React.Component<IAtFullscreenButtonProps> {
    render() {
        const { uiStore } = this.props;

        if (uiStore!.isFullscreenSupported) {
            const fullscreenIconName: IconName = uiStore!.isFullscreen ? "compress-arrows-alt" : "expand-arrows-alt";
            return (
                <AtButton
                    className={"AtFullscreenButton"}
                    size={"md"}
                    icon={["far", fullscreenIconName]}
                    onClick={uiStore!.toggleFullscreen}
                    color={"ghost"}
                />
            );
        }

        return null;
    }
}
