import "./index.less";
import { AtButton, AtIcon, AtLink, AtSubtitle, AtTooltip } from "@atman/design-system";
import { Col, Row } from "reactstrap";
import { ColorProperty } from "csstype";
import { Colors } from "../../../../../../../../styles";
import { CompDevObservableBehaviorResult, CompetencyDevelopmentAssessmentType } from "@atman/business";
import { CustomTextInput } from "../../../../../../../CustomTextInput";
import { LikertScales } from "../../../../../../../LikertEvaluation";
import { Trans, t } from "@lingui/macro";
import { observer } from "mobx-react";
import { useDetectDevice } from "@atman/core";
import React, { useEffect, useState } from "react";
import cn from "classnames";

export interface IObservableBehaviorStatementRowProps {
    observableBehavior: CompDevObservableBehaviorResult;
    scales: string[];
    observableBehaviorScoreMap: Map<string, number | undefined>;
    observableBehaviorNoteMap: Map<string, string | undefined>;
    onScoreChange: (id: string, score: number | undefined) => void;
    onNoteChange: (id: string, note: string) => void;
}

export const ObservableBehaviorStatementRow: React.FC<IObservableBehaviorStatementRowProps> = observer((props) => {
    const {
        observableBehavior,
        scales,
        observableBehaviorScoreMap,
        observableBehaviorNoteMap,
        onScoreChange,
        onNoteChange,
    } = props;

    const getHighlightedScoresForStatement = (observableBehavior: CompDevObservableBehaviorResult) => {
        const highlightedScoresArray: Array<{ score: number; color: ColorProperty }> = [];

        const managerScore = observableBehavior.assessmentScores.get(CompetencyDevelopmentAssessmentType.Manager);

        if (managerScore) {
            highlightedScoresArray.push({
                score: managerScore,
                color: Colors.greyBase,
            });
        }

        const employeeScore = observableBehavior.assessmentScores.get(CompetencyDevelopmentAssessmentType.Self);

        if (employeeScore) {
            highlightedScoresArray.push({
                score: employeeScore,
                color: Colors.atmanOrange,
            });
        }

        return highlightedScoresArray;
    };

    const [showNotesSection, setShowNotesSection] = useState(false);
    // const [notesInputHeight, setNotesInputHeight] = useState<number | undefined>();

    useEffect(() => {
        if (observableBehaviorNoteMap.get(observableBehavior.id)) {
            setShowNotesSection(true);
        }
    }, []);

    const { isMobile } = useDetectDevice();

    const likertScalesOnClick = (e: React.FormEvent<HTMLInputElement>) => {
        onScoreChange(observableBehavior.id, Number(e.currentTarget.value));
    };

    const notesInputOnChange = (e: React.FormEvent<HTMLInputElement>) => {
        onNoteChange(observableBehavior.id, e.currentTarget.value);
        // setNotesInputHeight(e.currentTarget.scrollHeight);
    };

    const onNotesRemove = () => {
        onNoteChange(observableBehavior.id, "");
        setShowNotesSection(false);
    };

    const addNotesBtnId = `add-notes-btn-${observableBehavior.id}`;

    return (
        <Row className={cn("ObservableBehaviorStatementRow", "statement-row")} key={observableBehavior.id} noGutters>
            <Col className="left-section" md={5}>
                <div className="statement-label">{`${observableBehavior.rank}. ${observableBehavior.name}`}</div>
                {!isMobile && !showNotesSection ? (
                    <>
                        <AtButton
                            id={addNotesBtnId}
                            className="add-notes-btn"
                            icon={["far", "comment-dots"]}
                            size="md"
                            color="ghost"
                            onClick={() => setShowNotesSection(true)}
                        />
                        <AtTooltip target={addNotesBtnId}>
                            {t({ id: "competencyDevelopment.applicationNotes.addNotes" })}
                        </AtTooltip>
                    </>
                ) : null}
            </Col>
            <Col className="right-section" md={7}>
                <LikertScales
                    scaleKey={observableBehavior.id}
                    scales={scales}
                    currentValue={observableBehaviorScoreMap.get(observableBehavior.id)}
                    onClick={likertScalesOnClick}
                    onChange={() => {}}
                    highlightedScores={getHighlightedScoresForStatement(observableBehavior)}
                />
            </Col>
            {isMobile && !showNotesSection ? (
                <AtButton
                    className="add-notes-btn"
                    icon={["far", "comment-dots"]}
                    size="md"
                    color="secondary"
                    onClick={() => setShowNotesSection(true)}
                >
                    <Trans id="competencyDevelopment.applicationNotes.addNotes">Add notes</Trans>
                </AtButton>
            ) : null}
            {showNotesSection ? (
                <Col className="notes-section" md={12}>
                    <div className="notes-section-header">
                        <AtSubtitle
                            subtitle={t({
                                id: "competencyDevelopment.review.adjustmentModal.notes.subtitle",
                                message: "Comments",
                            })}
                        />
                        <AtLink className="remove-note-link" onClick={onNotesRemove} color="secondary">
                            <Trans id="global.buttons.labels.delete">Delete</Trans>
                        </AtLink>
                    </div>
                    <div className="text-area-container">
                        <AtIcon icon={["far", "comments"]} />
                        <CustomTextInput
                            className="note-text-input"
                            fieldName={"applicationNotes"}
                            value={observableBehaviorNoteMap.get(observableBehavior.id)}
                            onChange={notesInputOnChange}
                            placeholder={t({
                                id: "competencyDevelopment.review.adjustmentModal.notes.placeholder",
                                message: "Add your notes here",
                            })}
                            type={"textarea"}
                            rows={!isMobile ? 2 : 5}
                            hideLabel={true}
                            style={
                                {
                                    // overflow: "hidden",
                                    // height: notesInputHeight
                                }
                            }
                        />
                    </div>
                </Col>
            ) : null}
        </Row>
    );
});
