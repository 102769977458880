import { BaseModel, IBaseInput, IBaseModel } from "../base/BaseModel";
import { IShortUser } from "../types";

export interface IBaseTagInput extends IBaseInput {
    tagName: string;
    restrictedToUsers?: IShortUser[];
    isRestricted: boolean;
}

export interface IBaseTag extends IBaseModel {
    readonly sqlId: string;
    readonly tagName: string;
    readonly isRestricted: boolean;
    readonly restrictedToUsers: IShortUser[];
    readonly usageCount: number;
}

export abstract class BaseTag extends BaseModel implements IBaseInput {
    public sqlId: string;
    public tagName: string;
    public isRestricted: boolean;
    public restrictedToUsers: IShortUser[];
    public usageCount: number;

    protected constructor(json?: IBaseTag) {
        super(json);

        if (!json) {
            return;
        }

        this.sqlId = json.sqlId;
        this.tagName = json.tagName;
        this.isRestricted = json.isRestricted;
        this.restrictedToUsers = json.restrictedToUsers || [];
        this.usageCount = json.usageCount;
    }
}
