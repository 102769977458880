import "./index.less";
import { AtCheckboxTitleSubtitle } from "../../../../AtCheckboxTitleSubtitle";
import { IAtTableFilter } from "../../../types";
import { IBaseEntity } from "@atman/core";
import React, { ReactElement, useMemo } from "react";

interface IAtTableFilterDropdownProps<T extends IBaseEntity> extends IAtTableFilter<T> {
    values: { title: string; value: string | number }[];
    appliedFilterValue: string[];
    setFilterValue: (columnKey: string, value: string[] | number[]) => void;
}

export function AtTableMultipleSelectFilter<T extends IBaseEntity>(
    props: IAtTableFilterDropdownProps<T>,
): ReactElement {
    const { values, appliedFilterValue, setFilterValue, column, ...otherProps } = props;

    const filterItems = useMemo(() => (appliedFilterValue ? appliedFilterValue : []), [appliedFilterValue]);

    const toggleValueFromArray = (val: string) => {
        const alreadySelected = !!filterItems.find((selected) => selected === val);

        if (alreadySelected) {
            return filterItems.filter((v) => v !== val);
        } else {
            return [...filterItems, val];
        }
    };

    const handleChange = (e) => {
        const newSelectionArray = toggleValueFromArray(e.target.value);
        setFilterValue(column.key, newSelectionArray);
    };

    const isValueChecked = (v: string) => {
        if (filterItems && filterItems.length) {
            return filterItems.find((selected) => selected === v);
        }

        return null;
    };

    return (
        <div className="AtTableMultipleSelectFilter" {...otherProps}>
            <div className="options">
                {values.map((v, i) => {
                    const isChecked = isValueChecked(v.value.toString());

                    return (
                        <div className="item" key={`${v.title}_${v.value}`}>
                            <AtCheckboxTitleSubtitle
                                id={`${v.title}_${v.value}_${i}`}
                                value={v.value.toString()}
                                checked={!!isChecked}
                                title={v.title}
                                onChange={handleChange}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
