import { AtButton } from "@atman/design-system";
import { CompetencyDevelopmentAssessmentStatus, ICompetencyDevelopmentProcessAssessmentData } from "@atman/business";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import React from "react";

interface IEditAssessmentButton {
    processAssessmentData?: ICompetencyDevelopmentProcessAssessmentData;
    openAndMonitorTabFunc?: (url: string) => void;
}

export const EditAssessmentButton: React.FC<IEditAssessmentButton> = observer((props: IEditAssessmentButton) => {
    const { processAssessmentData, openAndMonitorTabFunc } = props;

    let label: string | null = null;

    if (processAssessmentData) {
        switch (processAssessmentData?.status) {
            case CompetencyDevelopmentAssessmentStatus.NotStarted:
                label = t({ id: "global.start" });
                break;
            case CompetencyDevelopmentAssessmentStatus.Started:
                label = t({ id: "global.continue" });
                break;
            case CompetencyDevelopmentAssessmentStatus.Completed:
                label = t({ id: "global.edit" });
                break;
        }

        if (label) {
            return (
                <AtButton
                    color={"secondary"}
                    onClick={() => {
                        if (openAndMonitorTabFunc) {
                            openAndMonitorTabFunc(processAssessmentData?.url);
                        }
                    }}
                >
                    {label}
                </AtButton>
            );
        }
    }

    return null;
});
