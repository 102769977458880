import { IReactSelectOptionObject } from "@atman/design-system";

export type ValueBindingType = "string" | "number";

export class ReactSelectOptions<TEnum> extends Array<IReactSelectOptionObject<TEnum | string>> {
    /**
     * Make sure to provide a valid Enum Entity to the constructor
     * @constructor
     * @param EnumEntity
     * @param valueBindingType
     * @param labelPrefix
     */
    constructor(EnumEntity: any, valueBindingType: ValueBindingType = "number", labelPrefix: string = "global") {
        super();

        const enumEntityEntries = getEnumValues(EnumEntity);

        let arrayOfElements: Array<IReactSelectOptionObject<TEnum | string>>;

        if (valueBindingType === "number") {
            arrayOfElements = enumEntityEntries.map(
                (x) =>
                    // @ts-ignore
                    ({
                        value: x,
                        label: `${labelPrefix}.${EnumEntity[x].toCamel()}`.localize(),
                    } as IReactSelectOptionObject<TEnum>),
            );
        } else {
            arrayOfElements = enumEntityEntries.map(
                (x) =>
                    ({
                        value: EnumEntity[x],
                        label: `${labelPrefix}.${EnumEntity[x].toCamel()}`.localize(),
                    } as IReactSelectOptionObject),
            );
        }

        this.push(...arrayOfElements);
    }
}

/**
 * Make sure to provide a valid Enum Entity to the function
 * @param EnumEntity
 */
export function getEnumValues<TEnum extends number | string>(EnumEntity: any): TEnum[] {
    return Object.keys(EnumEntity)
        .filter((x) => !isNaN(Number(x)))
        .map((x) => Number(x) as unknown as TEnum);
}
