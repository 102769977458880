import "./index.less";
import * as React from "react";
import { AtLink, AtTitle } from "@atman/design-system";
import { observer } from "mobx-react";
import { useDetectDevice } from "@atman/core";

export interface IAtWizardStepHeaderProps {
    title: string;
    stepNumber?: number;
    description?: string;
}

export const AtWizardStepHeader: React.FC<IAtWizardStepHeaderProps> = observer((props) => {
    const { stepNumber, title, description } = props;

    const { isMobile } = useDetectDevice();

    const [showDescription, setShowDescription] = React.useState(false);

    const toggleShowDescription = () => {
        setShowDescription(!showDescription);
    };

    const renderMobile = () => {
        return (
            <div className="AtWizardStepHeader">
                <div className="title-section">
                    {stepNumber !== undefined && <div className="step-number-container">{stepNumber}</div>}
                    <AtTitle headingType={2} title={title} />
                </div>
                {description && (
                    <div className="description-section">
                        <AtLink color="secondary" onClick={toggleShowDescription}>
                            {"global.learnMore".localize()}
                        </AtLink>
                        {showDescription && <div className="description">{description}</div>}
                    </div>
                )}
            </div>
        );
    };

    const renderDefault = () => {
        return (
            <div className="AtWizardStepHeader">
                <div className="title-section">
                    {stepNumber !== undefined && <div className="step-number-container">{stepNumber}</div>}
                    <AtTitle headingType={1} title={title} />
                </div>
                {description && <div className="description-section">{description}</div>}
            </div>
        );
    };

    return isMobile ? renderMobile() : renderDefault();
});
