import "./LikertStatement.less";
import * as React from "react";
import { BaseResponsiveComponent, Default, Mobile } from "../../BaseResponsiveComponent";
import { Col, Label, Row } from "reactstrap";
import { CustomStandaloneRadioInput } from "../../CustomStandaloneRadioInput";
import { ILikertKeyValueObject, ILocalizedLikertStatement } from "@atman/business";
import { RadioFormInput } from "@atman/design-system";
import { inject, observer } from "mobx-react";

export interface ILikertStatementProps {
    element: ILocalizedLikertStatement;
    answers: ILikertKeyValueObject[];
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    userLanguage: string;
    index: number;
    disabled?: boolean;
}

@inject()
@observer
export class LikertStatement extends BaseResponsiveComponent<ILikertStatementProps> {
    protected get rootElementClassName(): string | undefined {
        return "LikertStatement";
    }

    renderAll() {
        const { element, answers, onChange, userLanguage, index, disabled = false } = this.props;

        const key = element.key;

        const statementAnswer = answers.find((x) => x.key === key);

        let value: number | undefined;

        if (!statementAnswer) {
            value = element.score ? element.score : undefined;
        } else {
            value = statementAnswer.value;
        }

        if (!element.statements.hasOwnProperty(userLanguage)) {
            console.log(`Could not find a statement matching the provided language: ${userLanguage}`);
            return null;
        }

        return (
            <Row noGutters>
                <Col sm={6}>
                    <div className="statement">{`${index + 1}. ${element.statements[userLanguage]}`}</div>
                </Col>
                <Col sm={6}>
                    <div className="options">
                        {[1, 2, 3, 4, 5].map((x) => (
                            <React.Fragment key={x}>
                                <Default>
                                    <Label className={"radio-container"} key={x}>
                                        <CustomStandaloneRadioInput
                                            fieldName={key}
                                            id={`${key}-${x}`}
                                            value={x}
                                            checked={value === x}
                                            onChange={onChange}
                                            disabled={disabled}
                                        />
                                    </Label>
                                </Default>
                                <Mobile>
                                    <RadioFormInput
                                        fieldName={key}
                                        id={`${key}-${x}`}
                                        value={x}
                                        valueText={`global.likertLegendLabel.${x}`.localize()}
                                        onChange={onChange}
                                        checked={value === x}
                                        disabled={disabled}
                                        className={value === x ? "checked" : ""}
                                    />
                                </Mobile>
                            </React.Fragment>
                        ))}
                    </div>
                </Col>
            </Row>
        );
    }
}
