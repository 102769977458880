import "./index.less";
import {
    AtButtonBase,
    AtTooltip,
    AtTooltipDisplayMode,
    HorizontalPositions,
    IAtButtonBaseProps,
    IAtTooltipProps,
} from "../../../internal";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useUniqueId } from "@atman/core";
import React from "react";

export interface IAtButtonProps extends IAtButtonBaseProps {
    isLoading?: boolean;
    icon?: IconProp;
    iconProps?: Omit<Partial<FontAwesomeIconProps>, "icon"> & {
        highlightIcon?: boolean;
        position?: HorizontalPositions;
    };
    disabledTooltipText?: string;
    disabledTooltipProps?: Omit<Partial<IAtTooltipProps>, "target" | "displayMode">;
    toolTipDisplayMode?: AtTooltipDisplayMode;
}

/**
 * AtButton - Button Component
 * Category: Molecule
 *
 * @param props {IAtButtonProps}
 **/
export const AtButton: React.FC<IAtButtonProps> = (props) => {
    const {
        size = "lg",
        isLoading = false,
        disabled,
        className,
        id,
        style,
        children,
        icon,
        iconProps,
        disabledTooltipText,
        disabledTooltipProps,
        toolTipDisplayMode = "light",
        ...otherProps
    } = props;

    const hasIcon = !!icon || isLoading;
    const iconPosition = iconProps?.position ?? "left";

    const getClassNames: () => string = () => {
        let classNames = "AtButton";

        if (className) {
            classNames += ` ${className}`;
        }

        if (isLoading) {
            classNames += " isLoading";
        }

        if (hasIcon) {
            classNames += ` has-icon icon-position-${iconPosition}`;

            if (iconProps?.highlightIcon) {
                classNames += " highlight-icon";
            }

            if (!children) {
                classNames += " icon-only";
            }
        }

        return classNames;
    };

    const getStyles: () => React.CSSProperties = () => {
        let styles = {};

        if (hasIcon && !children) {
            let diameter: number;

            switch (size) {
                case "xs":
                    diameter = 20;
                    break;
                case "sm":
                    diameter = 24;
                    break;
                case "md":
                    diameter = 32;
                    break;
                case "lg":
                    diameter = 40;
                    break;
                case "xl":
                    diameter = 48;
                    break;
            }

            styles = {
                ...styles,
                padding: 0,
                height: diameter,
                width: diameter,
                minHeight: diameter,
                minWidth: diameter,
            };
        }

        return {
            ...styles,
            ...style,
        };
    };

    const getContent: () => React.ReactNode = () => {
        if (!hasIcon) {
            return children;
        }

        let faIconProps: Omit<FontAwesomeIconProps, "icon"> = {};

        if (iconProps) {
            const { ..._faIconProps } = iconProps;

            faIconProps = _faIconProps;
        }

        const {
            forwardedRef,
            mask,
            className,
            color,
            spin,
            pulse,
            border,
            fixedWidth,
            inverse,
            listItem,
            flip,
            size,
            pull,
            rotation,
            transform,
            symbol,
            style,
            tabIndex,
            title,
            swapOpacity,
        } = faIconProps;

        const iconProperties = {
            forwardedRef,
            mask,
            className,
            color,
            spin,
            pulse,
            border,
            fixedWidth,
            inverse,
            listItem,
            flip,
            size,
            pull,
            rotation,
            transform,
            symbol,
            style,
            tabIndex,
            title,
            swapOpacity,
        };

        const currentIcon = isLoading ? ["far", "spinner"] : (icon as any);

        const iconContainer = (
            <span className="icon-container">{<FontAwesomeIcon icon={currentIcon} {...iconProperties} />}</span>
        );

        return (
            <>
                {iconPosition === "left" && iconContainer}
                {typeof children === "string" ? <span>{children}</span> : children}
                {iconPosition === "right" && iconContainer}
            </>
        );
    };

    const { uniqueId } = useUniqueId(id);

    return (
        <>
            <AtButtonBase
                className={getClassNames()}
                style={getStyles()}
                id={disabledTooltipText ? uniqueId : id}
                disabled={isLoading || disabled}
                size={size}
                {...otherProps}
            >
                {getContent()}
            </AtButtonBase>
            {disabledTooltipText && disabled && (
                <AtTooltip
                    {...disabledTooltipProps}
                    target={uniqueId}
                    placement={disabledTooltipProps?.placement ?? "auto"}
                    displayMode={toolTipDisplayMode}
                >
                    {disabledTooltipText}
                </AtTooltip>
            )}
        </>
    );
};
