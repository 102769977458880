import "../index.less";
import "./index.less";
import * as React from "react";
import { CompDevCompetencyResult, PrintModeEnum } from "@atman/business";
import { CompetencyDevelopmentColumnType, TableHeaderRow, TableRow } from "../../..";
import { SortDirection } from "../../../../..";
import { SubCompetencyContainer } from "../components/SubCompetencyContainer";
import { observer } from "mobx-react";
import { useDetectDevice } from "@atman/core";

export interface ISummaryCompetencyResultTableProps {
    competencies: CompDevCompetencyResult[];
    columns: CompetencyDevelopmentColumnType[];
    sortElement: CompetencyDevelopmentColumnType;
    sortDirection: SortDirection;
    onSortChange: (element: CompetencyDevelopmentColumnType) => void;
    printMode?: PrintModeEnum;
}

export const SummaryCompetencyResultTable: React.FC<ISummaryCompetencyResultTableProps> = observer((props) => {
    const { competencies, columns, sortElement, sortDirection, onSortChange, printMode } = props;

    const { isMobile } = useDetectDevice();

    return (
        <div className="ResultTable SummaryCompetencyResultTable">
            {!isMobile && printMode === undefined && (
                <TableHeaderRow
                    sortElement={sortElement}
                    sortDirection={sortDirection}
                    onSortChange={onSortChange}
                    columns={columns}
                />
            )}
            {competencies.map((x) => (
                <TableRow
                    dataRow={x}
                    columns={columns}
                    key={x.id}
                    showRank={false}
                    printMode={printMode}
                    expandOnMount={printMode === PrintModeEnum.Detailed}
                >
                    {x.subCompetencies.length > 0 && (
                        <SubCompetencyContainer subCompetencyDetails={x.subCompetencies} printMode={printMode} />
                    )}
                </TableRow>
            ))}
        </div>
    );
});
