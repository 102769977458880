import "./index.less";
import * as React from "react";
import { Col, Row } from "reactstrap";
import { ScaleType } from "@atman/business";
import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import Container from "reactstrap/lib/Container";
import cn from "classnames";

export interface IAtScaleLegendProps {
    maxScore: number;
    minScore: number;
    scaleType: ScaleType;
    separators: Array<number>; // List of score values where you want to have vertical separators
    sectionLabels?: Array<string>; // List of labels to put over the scale numbers. The number of labels must match the number of separators + 1
    showScoreLabels?: boolean;
    showScaleValues?: boolean;
    useAverageLabel?: boolean;
    isInTeamView?: boolean;
    className?: string;
}

@inject()
@observer
export class AtScaleLegend extends React.Component<IAtScaleLegendProps, {}> {
    public static defaultProps = {
        minScore: 1,
        isInTeamView: false,
        useAverageLabel: false,
        separators: [2, 6],
        showScoreLabels: true,
        sectionLabels: [],
    };

    @computed
    get getColumnDefinitions(): Array<SectionDefinition> {
        const { separators, minScore, maxScore } = this.props;
        const sectionLabels = this.props.sectionLabels ?? [];

        // Create column defintions according to possible score value and subdivision with or without labels!
        const colDefs: Array<{ label: string; numbers: Array<number> }> = [];
        let sectionIndex = 0;
        let sectionNumbers: Array<number> = [];
        for (let i = minScore; i <= maxScore; i++) {
            sectionNumbers.push(i);
            if (separators.some((v) => v === i)) {
                colDefs.push({
                    label: sectionLabels.length > sectionIndex ? sectionLabels[sectionIndex] : "",
                    numbers: sectionNumbers,
                });
                sectionIndex++;
                sectionNumbers = [];
            }
        }
        colDefs.push({
            label: sectionLabels.length > sectionIndex ? sectionLabels[sectionIndex] : "",
            numbers: sectionNumbers,
        });
        return colDefs;
    }

    render() {
        const { isInTeamView, scaleType, showScoreLabels, showScaleValues, sectionLabels, className } = this.props;

        const colDefs = this.getColumnDefinitions;
        const showScoreLabelRow = showScoreLabels && sectionLabels !== null && sectionLabels!.length > 0; // We hide the score label row if there are none.
        const showScale = showScoreLabelRow || showScaleValues;

        return (
            showScale && (
                <Container
                    className={cn("AtScaleLegend", ScaleType[scaleType], { "team-view": isInTeamView }, className)}
                >
                    {/* This extra Row/Col layer is to replicate the scale's bootstrap structure, synchronizing both layouts for responsiveness. */}
                    <Row className="main-row">
                        <Col className="first-col">
                            <Row className="inner-row">
                                {colDefs.map((colDef, i) => (
                                    <Col
                                        key={`${colDef.label}_${colDef.numbers}_${i}`}
                                        className="legend-category d-flex flex-column"
                                    >
                                        {showScoreLabelRow && (
                                            <Row className="legend-category-label flex-grow-1">
                                                <Col>{colDef.label}</Col>
                                            </Row>
                                        )}
                                        {showScaleValues && (
                                            <Row className="legend-category-scores">
                                                {colDef.numbers.map((val) => (
                                                    <Col key={val}>{val}</Col>
                                                ))}
                                            </Row>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            )
        );
    }
}

interface SectionDefinition {
    label: string;
    numbers: Array<number>; /// Sub columns
}
