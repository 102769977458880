import { FitElement, IFitElement } from "./FitElement";

export interface IFitRange extends IFitElement {
    readonly enabled: boolean;
}

export class FitRange extends FitElement implements IFitRange {
    public readonly enabled: boolean;

    constructor(json: IFitRange) {
        super(json);

        this.enabled = json.enabled;
    }
}
