import "./index.less";
import * as React from "react";
import { Colors } from "../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IReportDimensionWeightLegendItemProps {}
export const ReportDimensionWeightLegendItem: React.FC<IReportDimensionWeightLegendItemProps> =
    ({}: IReportDimensionWeightLegendItemProps) => {
        return (
            <div className="ReportLegendItem ReportDimensionWeightLegendItem">
                <div className="legend-icon dimension-weight-icon">
                    <FontAwesomeIcon icon={["fal", "bullseye"]} size="xs" color={Colors.greyBase} />
                </div>
                <span className="legend-text">{"psychometrics.importanceLevel".localize()}</span>
            </div>
        );
    };
