import React from "react";

export interface IViewport {
    width: number;
    height: number;
}

export const viewportContext = React.createContext<IViewport>({ width: 0, height: 0 });

export const ViewportProvider: React.FunctionComponent = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return <viewportContext.Provider value={{ width, height }}>{children}</viewportContext.Provider>;
};

export const ViewportConsumer = viewportContext.Consumer;
