import "./index.less";
import { AtTabCard } from "@atman/design-system";
import { CardSaveButtons } from "../CardSaveButtons";
import { ClientStore, ClientType, GlobalStores, IClientMarketingInfo } from "@atman/business";
import { CustomTextInput } from "../CustomTextInput";
import { action, observable, toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import autobind from "autobind-decorator";

export interface IClientMarketingInfoFormProps {
    ownerId: string;
    marketingInfo: IClientMarketingInfo;
    clientStore?: ClientStore;
}

@inject(GlobalStores.clientStore)
@observer
export class ClientMarketingInfoForm extends React.Component<IClientMarketingInfoFormProps, {}> {
    @observable public marketingInfo: IClientMarketingInfo = {
        turnoverRate: 0,
        expectedInterviewPerHire: 0,
        expectedAnnualHiresCount: 0,
        expectedAnnualGrowth: 0,
        employeesCount: 0,
        clientType: ClientType.Default,
    };

    componentDidMount(): void {
        this.resetMarketingInfo();
    }

    @autobind
    async saveMarketingInfo() {
        await this.props.clientStore!.updateClientMarketingInfo(this.props.ownerId, this.marketingInfo);
    }

    @action.bound
    resetMarketingInfo = () => {
        if (this.props.marketingInfo) {
            this.marketingInfo = {
                turnoverRate: this.props.marketingInfo.turnoverRate,
                expectedInterviewPerHire: this.props.marketingInfo.expectedInterviewPerHire,
                expectedAnnualHiresCount: this.props.marketingInfo.expectedAnnualHiresCount,
                expectedAnnualGrowth: this.props.marketingInfo.expectedAnnualGrowth,
                employeesCount: this.props.marketingInfo.employeesCount,
                clientType: this.props.marketingInfo.clientType,
            };
        } else {
            this.marketingInfo = {
                turnoverRate: 0,
                expectedInterviewPerHire: 0,
                expectedAnnualHiresCount: 0,
                expectedAnnualGrowth: 0,
                employeesCount: 0,
                clientType: ClientType.Default,
            };
        }
    };

    @action.bound
    onAdditionalInformationChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as any;

        if (toJS(this.marketingInfo).hasOwnProperty(target.id)) {
            this.marketingInfo[target.id] = target.value;
        }
    };

    render() {
        const { clientStore } = this.props;

        return (
            <AtTabCard
                cardTitle={"global.marketingInfo".localize()}
                id={"ClientMarketingInfoForm"}
                className={"client-marketing-info-form"}
            >
                <div className="inputs">
                    <CustomTextInput
                        fieldName={"employeesCount"}
                        label={"global.employeesCount".localize()}
                        value={this.marketingInfo.employeesCount}
                        onChange={this.onAdditionalInformationChange}
                    />
                    <CustomTextInput
                        fieldName={"expectedAnnualHiresCount"}
                        label={"global.expectedAnnualHiresCount".localize()}
                        value={this.marketingInfo.expectedAnnualHiresCount}
                        onChange={this.onAdditionalInformationChange}
                    />
                    <CustomTextInput
                        fieldName={"expectedInterviewPerHire"}
                        label={"global.expectedInterviewPerHire".localize()}
                        value={this.marketingInfo.expectedInterviewPerHire}
                        onChange={this.onAdditionalInformationChange}
                    />
                    <CustomTextInput
                        fieldName={"expectedAnnualGrowth"}
                        label={"global.expectedAnnualGrowth".localize()}
                        value={this.marketingInfo.expectedAnnualGrowth}
                        onChange={this.onAdditionalInformationChange}
                    />
                    <CustomTextInput
                        fieldName={"turnoverRate"}
                        label={"global.turnoverRate".localize()}
                        value={this.marketingInfo.turnoverRate}
                        onChange={this.onAdditionalInformationChange}
                    />
                </div>
                <CardSaveButtons
                    saveAction={this.saveMarketingInfo}
                    cancelAction={this.resetMarketingInfo}
                    isLoading={clientStore!.isLoading}
                />
            </AtTabCard>
        );
    }
}
